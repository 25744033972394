import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Paper, withStyles, Dialog } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import { BackButton, parseURL } from "../../../ms-utilities";

import AccountOverview from "./AccountOverview";
// import PlanList from "./PlanList";
import SoftwareLicenseList from "./SoftwareLicenseList";
// import UsageOverview from "./UsageOverviewList";
import { connect } from "react-redux";
import * as actions from "../store/billingAccountActions";
import { updateURL } from "../../../np-utilities";
import * as action from '../../../np-core/Auth/store/authActions';


// import PrePaidList from "./PrePaidList";

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        paddingRight: theme.spacing(6),
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            paddingRight: 0,
        },

    },
    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden"
    },
    logo: {
        width: '250px',
        margin: '10px'
    },
});

const TabContainer = props => {
    return (
        <Typography component="div" dir={props.dir} style={{ padding: 3 * 3 }}>
            {props.children}
        </Typography>
    );
};
const getTabValue = () => {
    let urlArray = window.location.href.split('#');
    return urlArray.length > 1 ? Number(urlArray[1]) : 0;
};


const getSearchParams = () => {
    let searchParams = parseURL(window.location.search);
    return { AccountID: searchParams ? searchParams.billingAccountID : null }
};

const Index = props => {
    const { billingAccount, softwareLicenses, activeCustomer } = props;
    const { classes, theme, onLoadAccount, editBillingAccount, onListSoftwareLicenses, onListUsers } = props;
    const [tabValue, setTabValue] = useState(getTabValue());
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            await onLoadAccount(getSearchParams(), { ActiveCustomerID: activeCustomer });
            let data = await onListUsers({ ActiveCustomerID: activeCustomer });
            setUsers(data);
            setLoading(false)
        };
        fetchData();
    }, [onLoadAccount, onListUsers, activeCustomer]);

    const handleChange = (event, value) => {
        updateURL(value);
        setTabValue(value);
    };

    const handleChangeIndex = index => {
        updateURL(index);
        setTabValue(index);
    };

    return (
        <Paper className={classes.root}>
            {BackButton()}
            <Tabs
                value={tabValue}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                {props.hasPermission('account_overview') ? <Tab label="Account info" /> : null}
                {/* {billingAccount && billingAccount.BillingMode === 'postpaid' && props.hasPermission('postpaid_plan_list') ?
                    <Tab label="Contract" />
                    : null}
                {billingAccount && billingAccount.BillingMode === 'prepaid' && props.hasPermission('prepaid_plan_list') ?
                    <Tab label="Pre-paid" />
                    : null} */}
                {props.hasPermission('license_list') ? <Tab label="Software licenses" /> : null}
                {/* {props.hasPermission('action_license_list') ? <Tab label="Usage" /> : null} */}
            </Tabs>
            <SwipeableViews
                index={tabValue}
                onChangeIndex={handleChangeIndex}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            >
                <TabContainer>
                    <AccountOverview activeCustomer={activeCustomer} loading={props.account.editing}
                        hasPermission={props.hasPermission}
                        billingAccount={billingAccount}
                        editBillingAccount={editBillingAccount}
                        users={users} />
                </TabContainer>
                {/* <TabContainer>
                    {billingAccount && billingAccount.BillingMode === 'postpaid' ?
                        <PlanList
                            activeCustomer={activeCustomer}
                            onAssignPlans={props.onAssignPlans}
                            onListPlans={props.onListPlans}
                            extraFilterParams={getSearchParams()}
                            classes={classes}
                            accountPlans={accountPlans}
                            loading={accountPlans.loading}
                            columnsState={props.columns}
                            saveColumnsFilter={props.saveColumnsFilter}
                            hasPermission={props.hasPermission}
                            onAccountPlansList={onAccountPlansList}
                            hasAtLeastOnePermission={props.hasAtLeastOnePermission}
                            billingAccount={props.account}
                            removePlans={props.removePlans}
                        /> :
                        <PrePaidList
                            activeCustomer={activeCustomer}
                            columnsState={props.columns}
                            saveColumnsFilter={props.saveColumnsFilter}
                            onListPrePaidPlans={onListPrePaidPlans}
                            onFetchPrePaid={props.onFetchPrePaid}
                            onCreatePrePaidPlan={props.onPrepaidPlanCreate}
                            onUpdatePrePaid={props.onUpdatePrePaid}
                            onDeletePrepaid={props.onDeletePrepaid}
                            extraFilterParams={getSearchParams()}
                            classes={classes}
                            prepaids={prepaids}
                            loading={prepaids.loading}
                            hasPermission={props.hasPermission}
                            hasAtLeastOnePermission={props.hasAtLeastOnePermission}
                        />
                    }
                </TabContainer> */}
                <TabContainer>
                    <SoftwareLicenseList
                        activeCustomer={activeCustomer}
                        extraFilterParams={getSearchParams()}
                        onListSoftwareLicenses={onListSoftwareLicenses}
                        loading={softwareLicenses.loading}
                        softwareLicenses={softwareLicenses}
                        hasPermission={props.hasPermission}
                        columnsState={props.columns}
                        saveColumnsFilter={props.saveColumnsFilter}
                        hasAtLeastOnePermission={props.hasAtLeastOnePermission}
                        listLicensePrototypes={props.listLicensePrototypes}
                        onCreateSoftwareLicense={props.onCreateSoftwareLicense}
                        onDeleteSoftwareLicense={props.onDeleteSoftwareLicense}
                        onFetchSoftwareLicense={props.onFetchSoftwareLicense}
                        onUpdateSoftwareLicense={props.onUpdateSoftwareLicense}
                    />
                </TabContainer>
                {/* <TabContainer>
                    <UsageOverview
                        activeCustomer={activeCustomer}
                        classes={classes}
                        onListActionLicenses={onListActionLicenses}
                        loading={actionLicenses.loading}
                        actionLicenses={actionLicenses}
                        columnsState={props.columns}
                        saveColumnsFilter={props.saveColumnsFilter}
                        hasPermission={props.hasPermission}
                        hasAtLeastOnePermission={props.hasAtLeastOnePermission}
                        billingAccount={billingAccount}
                    />
                </TabContainer> */}
            </SwipeableViews>
            <Dialog disableEscapeKeyDown={false} open={loading}
                PaperProps={{
                    classes: {
                        root: classes.paper
                    }
                }}
            >
                <img alt="App logo loading" className={classes.logo}
                    src={process.env.PUBLIC_URL + '/assets/images/app-logo-loading.gif'} />
            </Dialog>
        </Paper>
    );
};

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        account: state.billing.accounts,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        billingAccount: state.billing.accounts ? state.billing.accounts.loaded : null,
        accountPlans: state.billing.accounts ? state.billing.accounts.accountPlans : null,
        prepaids: state.billing.accounts ? state.billing.accounts.prepaids : null,
        softwareLicenses: state.billing.accounts ? state.billing.accounts.softwareLicenses : null,
        actionLicenses: state.billing.accounts ? state.billing.accounts.actionLicenses : null,
        hasPermission: permission => state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'),
        hasAtLeastOnePermission: permission => true
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),
        onLoadAccount: params => dispatch(actions.loadBillingAccount(params)),
        editBillingAccount: params => dispatch(actions.editBillingAccount(params)),
        onAccountPlansList: params => dispatch(actions.onAccountPlansList(params)),
        onListSoftwareLicenses: params => dispatch(actions.onListSoftwareLicenses(params)),
        onListActionLicenses: params => dispatch(actions.onListActionLicenses(params)),
        onAssignPlans: billingAccount => dispatch(actions.assignPlans(billingAccount)),
        onListPlans: (id) => dispatch(actions.listPlans(id)),
        listLicensePrototypes: (id) => dispatch(actions.listLicensePrototypes(id)),
        removePlans: params => dispatch(actions.removePlans(params)),
        onCreateSoftwareLicense: params => dispatch(actions.onSoftwareLicenseCreate(params)),
        onDeleteSoftwareLicense: params => dispatch(actions.onDeleteSoftwareLicense(params)),
        onUpdateSoftwareLicense: params => dispatch(actions.onUpdateSoftwareLicense(params)),
        onFetchSoftwareLicense: params => dispatch(actions.onFetchSoftwareLicense(params)),
        onListPrePaidPlans: params => dispatch(actions.onListPrePaidPlans(params)),
        onPrepaidPlanCreate: params => dispatch(actions.onPrepaidPlanCreate(params)),
        onDeletePrepaid: params => dispatch(actions.onDeletePrepaid(params)),
        onFetchPrePaid: params => dispatch(actions.onFetchPrePaid(params)),
        onUpdatePrePaid: params => dispatch(actions.onUpdatePrePaid(params)),
        onListUsers: (id) => dispatch(actions.listUsers(id))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Index));