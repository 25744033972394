import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import NPDatatable from '../../np-components/Datatable';
import { Paper, Tooltip, IconButton, Checkbox } from '@material-ui/core';
import useListFilter from '../../np-components/Datatable/listFilter';
import { Edit, Delete } from '@material-ui/icons';
import { isSuperUser } from '../../np-utilities';
import { formatDate, parseURL, BackButton } from '../../ms-utilities';
import { CustomChip } from '../../ms-components';

const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#d50000" : "#1b2a33",
        '&:hover': {
            backgroundColor: "#757575",
        },
    },
    chipActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    chipPrepared: {
        color: '#af9900',
        marginTop: 8.5,
        fontSize: 15
    },
    chipInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    media: {
        width: 50,
        height: 50
    },
    paletteButtons: {
        backgroundColor: isSuperUser() ? theme.palette.secondary.main : theme.palette.primary.main,
        color: 'white',
        margin: theme.spacing()
    },
});

const updateFilter = props => {
    const { listFilter } = props;
    let searchParams = parseURL(window.location.search);
    return { ...listFilter, ...searchParams };
}

const BillingPlanList = props => {
    const { classes, billingPlans, onFetchBillingPlans, columnsState, activeCustomer } = props;
    const billingPlansCount = billingPlans.count;
    const billingPlansList = billingPlans.list;
    let tableID = 'BillingPlan';
    let visible = columnsState && columnsState[tableID];

    const [extraFilterInfo, setExtraFilterInfo] = useState({ showDeleted: false });

    const columns = [
        {
            displayName: 'Action',
            backendName: 'Action',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: 'Name',
            backendName: 'Name',
            options: {
                align: 'left',
                sortable: false,
                searchable: true,
                visible: visible ? visible['Name'] : true,
            }
        },
        {
            displayName: 'Description',
            backendName: 'Description',
            options: {
                align: 'left',
                sortable: false,
                searchable: true,
                visible: visible ? visible['Description'] : true,
            }
        },
        {
            displayName: 'Type',
            backendName: 'Type',
            options: {
                align: 'left',
                sortable: false,
                searchable: true,
                visible: visible ? visible['Type'] : true,
            }
        },
        {
            displayName: 'Status',
            backendName: 'Active',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Active'] : true,
            }
        },
        {
            displayName: 'Activation date',
            backendName: 'ActivationDate',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['ActivationDate'] : true,
            }
        },
        {
            displayName: 'Expires at',
            backendName: 'ExpiresAt',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['ExpiresAt'] : true,
            }
        },
        {
            displayName: 'Deleted',
            backendName: 'Deleted',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Deleted'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => {
        if (column.backendName !== 'Action') backendNames.push(column.backendName);
        return null;
    })
    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });

    if (!props.hasAtLeastOnePermission(['billing_plan_create', 'billing_plan_delete', 'billing_plan_load'])) {
        columns.splice(0, 1);
    }

    useEffect(() => {
        if (listFilter.filter) {
            if (!listFilter.filter.q) {
                listFilter.filter.q = {};
            }
            if (!listFilter.filter.q.and) {
                listFilter.filter.q.and = {};
            }
            listFilter.filter.q = {
                ...listFilter.filter.q, ...extraFilterInfo,
                and: updateFilter({ listFilter: listFilter.filter.q.and })
            };
            onFetchBillingPlans({ ...listFilter.filter, ActiveCustomerID: activeCustomer });
        }
    }, [extraFilterInfo, billingPlans.created, billingPlans.deleted, billingPlans.bulkDeleted, billingPlans.edited, listFilter.filter, onFetchBillingPlans, activeCustomer]);

    const createActions = (billingPlanID) => {
        return <>
            {
                props.hasPermission('billing_plan_load') ? <Tooltip title="Edit billing plan">
                    <IconButton value={billingPlanID} aria-label="Edit billingPlan" onClick={props.handleEdit}>
                        <Edit />
                    </IconButton>
                </Tooltip> : <div></div>
            }
            {
                props.hasPermission('billing_plan_delete') ?
                    <Tooltip title="Delete billing plan">
                        <IconButton value={billingPlanID} aria-label="Delete billing plan"
                            onClick={props.handleDelete}>
                            <Delete />
                        </IconButton>
                    </Tooltip> : <div></div>
            }
        </>
    };

    const getFormattedStatus = (id, status) => {
        switch (status) {
            case 'active':
                return <CustomChip key={`${id}`} className={classes['chipActive']} value={'Active'} />;
            case 'inactive':
                return <CustomChip key={`${id}`} className={classes['chipInactive']} value={'Inactive'} />;
            case 'prepared':
                return <CustomChip key={`${id}`} className={classes['chipPrepared']} value={'Prepared'} />;
            default:
                return '';
        }
    };

    const handleExtraFilterChange = event => {
        if (event.currentTarget.value === 'showDeleted') {
            setExtraFilterInfo({ ...extraFilterInfo, showDeleted: !extraFilterInfo.showDeleted });
        }
    };

    const customFilterToolbar = () => {
        return <>
            <Checkbox checked={extraFilterInfo.showDeleted}
                value={'showDeleted'}
                onChange={handleExtraFilterChange} /> Show deleted
        </>
    };

    const tableOptions = {
        isSelectable: false,
        tableSearch: false,
        tableID: 'BillingPlan',
        saveColumn: true,
        selectOptions: {
            printing: false,
            generatingReport: false,
            deleting: props.hasPermission('billing_plan_delete'),
            creating: props.hasPermission('billing_plan_create')
        },
        rowsPerPage: 20,
        count: billingPlansCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'Billing plans',
        noMatch: "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return props.handleBulkDelete(IDs);
        },
        onSaveFilter: (columns) => {
            let objToSendColumn = {}
            let objColumns = {}
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible
                return objColumns
            })
            objToSendColumn[`${tableID}`] = objColumns
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }
    };



    let counter = 0;
    let billingPlansData = billingPlansList.map(({ RowID, PlanID, Name, Description, Type, Status, ActivationDate, ExpiresAt, Configuration, Deleted }) => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = PlanID;
        returnObj.visible = true;
        returnObj.values = [
            createActions(RowID),
            Name,
            Description,
            Type,
            getFormattedStatus(PlanID, Status),
            formatDate(ActivationDate),
            ExpiresAt ? formatDate(ExpiresAt) : 'Expiration date not set',
            Deleted === true ? 'Yes' : 'No'
        ];

        if (!props.hasAtLeastOnePermission(['billing_plan_create', 'billing_plan_delete', 'billing_plan_load'])) {
            returnObj.values.splice(0, 1);
        }

        counter++;
        return returnObj;
    });
    return (
        <Paper>
            {BackButton()}
            <NPDatatable handleCreate={props.handleCreate}
                customStyles={classes}
                data={billingPlansData}
                columns={columns}
                options={tableOptions}
                customFilterToolbar={customFilterToolbar}
                innerHeight={400}
            />
        </Paper>
    );
}

export default withStyles(styles)(BillingPlanList);