import restCrudReducer from '../../../np-crud/store/restCrudReducer';
import * as actionTypes from './actionTypes';

const [crudInitialState, crudReducer] = restCrudReducer({
    pluginName: 'BILLING',
    entityName: 'ACCOUNT',
});

const initialState = {
    ...crudInitialState,
    deletedBillingAccount: {},
    actionLicenses: {
        list: [],
        count: 0,
        loading: false
    },
    softwareLicenses: {
        list: [],
        count: 0,
        loading: false,
        creating: false,
        created: null,
        updating: false,
        updated: null,
        loaded: null
    },
    accountPlans: {
        list: [],
        count: 0,
        loading: false
    },
    prepaids: {
        list: [],
        count: 0,
        loading: false,
        creating: false,
        created: null,
        updating: false,
        updated: null,
        loaded: null
    },
    deleting: false,
    saving: false
};
const reducer = (state = initialState, action) => {
    // Handle CRUD actions
    const newState = crudReducer(state, action);
    // If CRUD reducer handled the action
    if (newState !== null) {
        return newState;
    }

    // Handle other actions
    switch (action.type) {
        case 'CORE|LOGOUT_SUCCESS':
        case 'CORE|CORE_SET_ACTIVE_CUSTOMER_SUCCESS':
            return initialState;
        case actionTypes.BILLING_ACTION_LICENSE_LIST_START:
            return {...state, actionLicenses: {...state.actionLicenses, loading: action.loading}};
        case actionTypes.BILLING_ACTION_LICENSE_LIST_START_SUCCESS:
            return {...state, actionLicenses: {...action.actionLicenses, loading: action.loading}};
        case actionTypes.BILLING_ACTION_LICENSE_LIST_FAIL:
            return {...state, actionLicenses: {...state.actionLicenses, loading: action.loading}};



        case actionTypes.BILLING_SOFTWARE_LICENSE_LIST_START:
            return {...state, softwareLicenses: {...state.softwareLicenses, loading: action.loading}};
        case actionTypes.BILLING_SOFTWARE_LICENSE_LIST_SUCCESS:
            return {...state, softwareLicenses: {...action.softwareLicenses, loading: action.loading}};
        case actionTypes.BILLING_SOFTWARE_LICENSE_LIST_FAIL:
            return {...state, softwareLicenses: {...state.softwareLicenses, loading: action.loading}};

        case actionTypes.BILLING_ACCOUNT_PLANS_LIST_START:
            return {...state, accountPlans: {...state.accountPlans, loading: action.loading}};
        case actionTypes.BILLING_ACCOUNT_PLANS_LIST_SUCCESS:
            return {...state, accountPlans: {...action.accountPlans, loading: action.loading}};
        case actionTypes.BILLING_ACCOUNT_PLANS_LIST_FAIL:
            return {...state, accountPlans: {...state.accountPlans, loading: action.loading}};
        case actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_CREATE_START:
            return {...state, softwareLicenses: {...state.softwareLicenses, creating: action.creating}};
        case actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_CREATE_SUCCESS:
            return {
                ...state,
                softwareLicenses: {...state.softwareLicenses, creating: action.creating, created: action.created}
            };
        case actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_CREATE_FAIL:
            return {...state, softwareLicenses: {...state.softwareLicenses, creating: action.creating}};
        case actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_DELETE_START:
            return {...state, softwareLicenses: {...state.softwareLicenses, deleting: action.deleting}};
        case actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_DELETE_SUCCESS:
            return {
                ...state,
                softwareLicenses: {...state.softwareLicenses, deleting: action.deleting, deleted: action.deleted}
            };
        case actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_DELETE_FAIL:
            return {...state, softwareLicenses: {...state.softwareLicenses, deleting: action.deleting}};

        case actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_LOAD_START:
            return {...state, softwareLicenses: state.softwareLicenses};
        case actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_LOAD_SUCCESS:
            return {
                ...state,
                softwareLicenses: {...state.softwareLicenses, loaded: action.loaded}
            };
        case actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_LOAD_FAIL:
            return {...state, softwareLicenses: state.softwareLicenses};

        case actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_UPDATE_START:
            return {...state, softwareLicenses: {...state.softwareLicenses, updating: action.updating}};
        case actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_UPDATE_SUCCESS:
            return {
                ...state,
                softwareLicenses: {...state.softwareLicenses, updated: action.updated, updating: action.updating}
            };
        case actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_UPDATE_FAIL:
            return {...state, softwareLicenses: {...state.softwareLicenses, updating: action.updating}};
        case actionTypes.BILLING_PREPAID_LIST_START:
            return {...state, prepaids: {...state.prepaids, loading: action.loading}};
        case actionTypes.BILLING_PREPAID_LIST_SUCCESS:
            return {...state, prepaids: {...action.prepaids, loading: action.loading}};
        case actionTypes.BILLING_PREPAID_LIST_FAIL:
            return {...state, prepaids: {...state.prepaids, loading: action.loading}};
        case actionTypes.BILLING_ACCOUNT_PREPAID_CREATE_START:
            return {...state, prepaids: {...state.prepaids, creating: action.creating}};
        case actionTypes.BILLING_ACCOUNT_PREPAID_CREATE_SUCCESS:
            return {
                ...state,
                prepaids: {...state.prepaids, creating: action.creating, created: action.created}
            };
        case actionTypes.BILLING_ACCOUNT_PREPAID_CREATE_FAIL:
            return {...state, prepaids: {...state.prepaids, creating: action.creating}};
        case actionTypes.BILLING_ACCOUNT_PREPAID_DELETE_START:
            return {...state, prepaids: {...state.prepaids, deleting: action.deleting}};
        case actionTypes.BILLING_ACCOUNT_PREPAID_DELETE_SUCCESS:
            return {
                ...state,
                prepaids: {...state.prepaids, deleting: action.deleting, deleted: action.deleted}
            };
        case actionTypes.BILLING_ACCOUNT_PREPAID_DELETE_FAIL:
            return {...state, prepaids: {...state.prepaids, deleting: action.deleting}};
        case actionTypes.BILLING_ACCOUNT_PREPAID_LOAD_START:
            return {...state, prepaids: {...state.prepaids}};
        case actionTypes.BILLING_ACCOUNT_PREPAID_LOAD_SUCCESS:
            return {
                ...state,
                prepaids: {...state.prepaids, loaded: action.loaded}
            };
        case actionTypes.BILLING_ACCOUNT_PREPAID_LOAD_FAIL:
            return {...state, prepaids: {...state.prepaids}};

        case actionTypes.BILLING_ACCOUNT_PREPAID_UPDATE_START:
            return {...state, prepaids: {...state.prepaids, updating: action.updating}};
        case actionTypes.BILLING_ACCOUNT_PREPAID_UPDATE_SUCCESS:
            return {
                ...state,
                prepaids: {...state.prepaids, updated: action.updated, updating: action.updating}
            };
        case actionTypes.BILLING_ACCOUNT_PREPAID_UPDATE_FAIL:
            return {...state, prepaids: {...state.prepaids, updating: action.updating}};

        default:
            return state;
    }
};

export default reducer;
