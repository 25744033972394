import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {combineReducers} from 'redux';

// Reducers
import trainingReducer from './Training/store/trainingReducer';

// Components
import TrainingList from './Training';

// Routes
export const routes = basePath =>
    (
        <Routes>
            <Route path={basePath} element={<TrainingList/>}/>
        </Routes>
    );

export const reducers = combineReducers({
    trainings: trainingReducer
});