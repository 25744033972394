import restCrudActions from '../../../np-crud/store/restCrudActions'
import * as actionTypes from "./actionTypes";
import { getAxiosInstance } from "../../../np-rest/rest";
import moment from 'moment';
import config from '../../../../config/config_default';
import { enqueueSnackbar } from "../../../np-dashboard/Notifier/store/notifierActions";

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: 'applicant',
    pluginName: 'APPLICANT',
    entityName: 'APPLICANT',
    fetchSuccessMessage: '',
    loadSuccessMessage: ''
});

// Fetch Applicant action creators
export const fetchApplicants = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    }
};

// Create Applicant  action creators
export const createApplicant = applicant => {
    return async dispatch => {
        await crudActions.create(dispatch, applicant);
    };
};

// Delete Applicant  action creators
export const bulkDeleteApplicant = applicantIDs => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, applicantIDs);
    };
};

// Delete Applicant  action creators
export const deleteApplicant = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};


// Edit applicant  action creators
export const editApplicant = applicant => {
    return async dispatch => {
        await crudActions.edit(dispatch, applicant);
    };
};

// Load applicant action creators
export const loadApplicant = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

export const onApplicantExport = payload => {
    return async dispatch => {
        try {
            const token = localStorage.getItem('CORE|AUTH|TOKEN');
            const body = { ...payload, Token: JSON.parse(token).token };
            const res = await fetch(`${config.backend_url}/applicant/export`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            });
            if (res.status === 200) {
                dispatch(
                    enqueueSnackbar({
                        message: 'Export succeeded.',
                        options: {
                            variant: 'success'
                        }
                    })
                );
                let fileName = `applicants_${moment().valueOf()}.csv`;
                res.headers.forEach((value, key) => {
                    if (key === 'filename') {
                        fileName = value;
                    }
                });
                return { blob: await res.blob(), fileName }
            } else {
                const jsonResponse = await res.json();
                dispatch(
                    enqueueSnackbar({
                        message: `${jsonResponse.message ? jsonResponse.message : res.message}`,
                        options: {
                            variant: 'warning'
                        }
                    })
                );
            }
            return null;
        } catch (error) {
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return null;
        }
    };
};

// list account software licenses
export const applicantDatasetsStart = () => {
    return {
        type: actionTypes.APPLICANT_TRAINING_RESULT_START,
        loading: true,
        datasets: []
    };
};
export const applicantDatasetsSuccess = applicantDatasets => {
    return {
        type: actionTypes.APPLICANT_TRAINING_RESULT_SUCCESS,
        loading: false,
        datasets: applicantDatasets
    };
};
export const applicantDatasetsFail = () => {
    return {
        type: actionTypes.APPLICANT_TRAINING_RESULT_FAIL,
        loading: false,
        datasets: []
    };
};

export const onApplicantDatasetsCalculate = applicantInfo => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(applicantDatasetsStart());
            let res = await rest.post('/applicant/get-training-result', applicantInfo);
            if (res.data && res.data.succeeded) {
                dispatch(applicantDatasetsSuccess(Object.values(res.data.result.data)));
                return res.data.result.data;
            }
            else {
                dispatch(applicantDatasetsSuccess([]));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : res.message}`,
                        options: {
                            variant: 'warning'
                        }
                    }));
                return {};
            }
        } catch (error) {
            dispatch(applicantDatasetsFail());
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return {};
        }
    };
};

export const saveManualEnteredResults = manualEnteredResults => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/applicant/save-manual-entered-results', manualEnteredResults);
            if (res.data && res.data.succeeded) {
                dispatch(
                    enqueueSnackbar({
                        message: 'Results saved.',
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return;
            } else {
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : res.message ? res.message : 'Something went wrong. Contact administrator.'}`,
                        options: {
                            variant: 'error'
                        }
                    })
                );
                return {};
            }
        } catch (error) {
            dispatch(
                enqueueSnackbar({
                    message: 'Something went wrong. Contact administrator.',
                    options: {
                        variant: 'error'
                    }
                })
            );
            return {};
        }
    };
};
export const applicantSuccess = () => {
    return {
        type: actionTypes.APPLICANT_ACC,
    };
};

export const activateApplicant = params => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/applicant/activate', { ApplicantID: params.ApplicantID, ActiveCustomerID: params.ActiveCustomerID });
            if (res.data && res.data.succeeded) {
                dispatch(
                    applicantSuccess()
                );
                dispatch(
                    enqueueSnackbar({
                        message: 'Applicant activated successfully.',
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return true;
            }
            else {
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : res.message ? res.message : 'Applicant not activated.'}`,
                        options: {
                            variant: 'warning'
                        }
                    }));
                return false;

            }
        } catch (error) {
            dispatch(
                enqueueSnackbar({
                    message: 'Applicant not activated.',
                    options: {
                        variant: 'warning'
                    }
                })
            );
            return false;

        }
    };
};

export const getRawData = (ApplicantID, ActiveCustomerID) => {
    let token = localStorage.getItem('CORE|AUTH|TOKEN');
    if (token) {
        token = JSON.parse(token).token;
    }
    return async dispatch => {
        return fetch(`${config.backend_domain}:${config.backend_port}/applicant/get-raw-data?ApplicantID=${ApplicantID}&ActiveCustomerID=${ActiveCustomerID}&Token=${token}`, {
            method: "GET",
        }).then(async response => {
            let fileName = `${moment().valueOf()}.txt`;
            response.headers.forEach((value, key) => {
                if (key === 'filename') {
                    fileName = value;
                }
            });
            if (response.status === 200) {
                return { blob: await response.blob(), fileName: fileName }
            }
            return null;
        });
    }
};

export const getApplicantTrainingReport = (ApplicantID, ActiveCustomerID) => {
    let token = localStorage.getItem('CORE|AUTH|TOKEN');
    if (token) {
        token = JSON.parse(token).token;
    }
    return async dispatch => {
        return fetch(`${config.backend_domain}:${config.backend_port}/applicant/generate_training_report?ApplicantID=${ApplicantID}&ActiveCustomerID=${ActiveCustomerID}&Token=${token}`, {
            method: "GET",
        }).then(async response => {
            let fileName = `${moment().valueOf()}.txt`;
            response.headers.forEach((value, key) => {
                if (key === 'filename') {
                    fileName = value;
                }
            });
            if (response.status === 200) {
                return { blob: await response.blob(), fileName: fileName }
            }
            return null;
        });
    }
};