import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route } from 'react-router';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import MultiSelect from '../../np-components/MultiSelect/MultiSelect';

import * as actions from './store/userActions';
import * as roleActions from '../Roles/store/roleActions';
import * as permissionActions from '../Permissions/store/permissionActions';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2)
    }
});

// Role List Component
const UserAccessEdit = props => {
    // Get style classes and redux mapped props
    const {
        classes,
        roles,
        userID,
        userRoles,
        onFetchExcludedPermissions,
        permissions,
        onFetchIncludedPermissions,
        onFetchAllUserPermissions,
        includedPermissions,
        excludedPermissions,
        allUserPermissions,
        onFetchUserRoles,
        onFetchRoles,
        onFetchPermissions
    } = props;

    // All roles
    const [allRoles, setAllRoles] = useState([]);
    // All permisions
    const [allPermissionsIncluded, setAllPermissionsIncluded] = useState([]);

    const [allPermissionsExcluded, setAllPermissionsExcluded] = useState([]);

    // Selected roles data
    const [selectedRoles, setSelectedRoles] = useState([]);

    // Selected included permissions
    const [selectedIncludedPermissions, setSelectedIncludedPermissions] = useState([]);

    // Selected included permissions
    const [selectedExcludedPermissions, setSelectedExcludedPermissions] = useState([]);

    //Disabled select selected permissions
    const [allPermissionsSelected, setAllPermissionsSelected] = useState([]);
    const [allPermissionsSelect, setAllPermissionsSelect] = useState([]);

    const [redirect, setRedirect] = useState(false);

    const [disabled, setDisabled] = useState(true);

    // Init the redux store on mount and on unmount
    useEffect(() => {
        //loading user roles
        onFetchUserRoles({ UserID: userID });
        //loading all roles
        onFetchRoles({});
        //loading all permissions
        onFetchPermissions({});
        //loading included permissions
        onFetchIncludedPermissions({ UserID: userID });
        //loading all user permissions
        onFetchAllUserPermissions({ UserID: userID });
        //loading excleded permissions
        onFetchExcludedPermissions({ UserID: userID });
        return () => {
            //Cleanup redux store
            // props.onFetchInit();
        };
    }, [onFetchUserRoles, onFetchRoles, onFetchPermissions, onFetchIncludedPermissions, userID, onFetchAllUserPermissions, onFetchExcludedPermissions]);

    //Setting up all permissions for disabled select
    useEffect(() => {
        setInitialDisabledSelect(allUserPermissions);
    }, [allUserPermissions]);

    //Setting up initial data for select
    const setInitialDisabledSelect = allUserPermissions => {
        const selectedOptions = [];

        allUserPermissions.forEach(permission => {
            selectedOptions.push({ label: permission });
        });

        setAllPermissionsSelected(selectedOptions);
    };

    //Setting up included permissions for users
    useEffect(() => {
        setInitialIncludedPermissions(permissions, includedPermissions);
    }, [permissions, includedPermissions]);

    //Setting up initial data for select
    const setInitialIncludedPermissions = (permissions, includedPermissions) => {
        const selectOptions = [];
        const selectedOptions = [];

        permissions.forEach(permission => {
            let flag = false;
            includedPermissions.forEach(includedPermission => {
                if (includedPermission.PermissionID === permission.PermissionID) {
                    flag = true;
                }
            });
            if (flag) {
                selectedOptions.push(permission.PermissionID);
            }

            selectOptions.push({ label: permission.Name, value: permission.PermissionID });
        });

        setAllPermissionsIncluded(selectOptions);
        setSelectedIncludedPermissions(selectedOptions);
    };

    //Setting up excluded permissions for users
    useEffect(() => {
        setInitialExcludedPermissions(permissions, excludedPermissions);
    }, [permissions, excludedPermissions]);

    //Setting up initial data for select
    const setInitialExcludedPermissions = (permissions, excludedPermissions) => {
        const selectOptions = [];
        const selectedOptions = [];

        permissions.forEach(permission => {
            let flag = false;
            excludedPermissions.forEach(excludedPermission => {
                if (excludedPermission.PermissionID === permission.PermissionID) {
                    flag = true;
                }
            });
            if (flag) {
                selectedOptions.push(permission.PermissionID);
            }
            selectOptions.push({ label: permission.Name, value: permission.PermissionID });
        });

        setAllPermissionsExcluded(selectOptions);
        setSelectedExcludedPermissions(selectedOptions);
    };

    useEffect(() => {
        //Setting up initial data for select
        const setInitialData = (roles, userRoles) => {
            const selectOptions = [];
            const selectedOptions = [];
            const rolesForPermissionsIds = [];

            roles.forEach(role => {
                let flag = false;
                userRoles.forEach(userRole => {
                    if (userRole.RoleID === role.RoleID) {
                        flag = true;
                    }
                });
                if (flag) {
                    selectedOptions.push(role.RoleID);
                    rolesForPermissionsIds.push(role.RoleID);
                }
                selectOptions.push({ label: role.Name, value: role.RoleID });
            });
            setAllRoles(selectOptions);
            setSelectedRoles(selectedOptions);

            const setDisabledPermissions = roleIDs => {
                const allPermissionsSelected = [];
                const allPermissions = [];

                for (var role of roles) {
                    if (roleIDs.includes(role.RoleID)) {
                        for (var permission of role.Permissions) {
                            if (!allPermissionsSelected.includes(permission.PermissionID)) {
                                allPermissionsSelected.push(permission.PermissionID);
                                allPermissions.push({ label: permission.Name, value: permission.PermissionID });
                            }
                        }
                    }
                }
                setAllPermissionsSelected(allPermissionsSelected);
                setAllPermissionsSelect(allPermissions);
            };
            setDisabledPermissions(rolesForPermissionsIds);
        };
        setInitialData(roles, userRoles);
    }, [roles, userRoles]);

    const handleSubmit = async () => {
        await saveRoles();
        await saveIncluded();
        await saveExcluded();

        setDisabled(true);
    };

    const saveRoles = async () => {
        //Prapare data for saving roles
        const RoleIDs = [];
        selectedRoles.forEach(role => {
            RoleIDs.push(role);
        });

        const data = {
            RoleIDs: RoleIDs,
            UserID: userID
        };

        await props.onBulkSaveRoles(data);
    };

    const saveIncluded = async () => {
        //Prapare data for saving included permissions
        const PermissionIDs = [];
        selectedIncludedPermissions.forEach(permission => {
            PermissionIDs.push(parseInt(permission));
        });

        const data = {
            PermissionIDs: PermissionIDs,
            UserID: userID
        };

        await props.onSaveIncludedPermissions(data);
    };

    const saveExcluded = async () => {
        //Prapare data for saving excluded permissions
        const PermissionIDs = [];
        selectedExcludedPermissions.forEach(permission => {
            PermissionIDs.push(parseInt(permission));
        });

        const data = {
            PermissionIDs: PermissionIDs,
            UserID: userID
        };

        await props.onSaveExcludedPermissions(data);
    };

    const handleCancel = () => {
        setRedirect(true);
    };

    const getRolesSelectData = (...params) => {
        const allPermissionsSelected = [];
        const allPermissions = [];

        for (var role of roles) {
            if (params[0].includes(role.RoleID)) {
                for (var permission of role.Permissions) {
                    if (!allPermissionsSelected.includes(permission.PermissionID)) {
                        allPermissionsSelected.push(permission.PermissionID);
                        allPermissions.push({ label: permission.Name, value: permission.PermissionID });
                    }
                }
            }
        }
        setSelectedRoles(params[0]);
        setAllPermissionsSelected(allPermissionsSelected);
        setAllPermissionsSelect(allPermissions);
        setDisabled(false);
    };

    const getIncludedPermissionsSelectData = (...params) => {
        setSelectedIncludedPermissions(params[0]);
        let modifiedSelectedExcludedPermissions = [...selectedExcludedPermissions];
        //Checking if the value is selected in excluded permissions
        for (var el of modifiedSelectedExcludedPermissions) {
            var flag = false;
            for (var param of params[1]) {
                if (param.value === el) {
                    flag = true;
                }
            }
            if (flag) {
                //Deleting unneccessary permission to prevent confusion
                modifiedSelectedExcludedPermissions.splice(modifiedSelectedExcludedPermissions.indexOf(el), 1);
            }
        }
        setSelectedExcludedPermissions(modifiedSelectedExcludedPermissions);
        setDisabled(false);
    };

    const getExcludedPermissionsSelectData = (...params) => {
        setSelectedExcludedPermissions(params[0]);
        //Checking if the value is selected in included permissions
        let modifiedSelectedIncludedPermissions = [...selectedIncludedPermissions];
        for (var el of modifiedSelectedIncludedPermissions) {
            var flag = false;
            for (var param of params[1]) {
                if (param.value === el) {
                    flag = true;
                }
            }

            if (flag) {
                //Deleting unneccessary permission to prevent confusion
                modifiedSelectedIncludedPermissions.splice(modifiedSelectedIncludedPermissions.indexOf(el), 1);
            }
        }

        setSelectedIncludedPermissions(modifiedSelectedIncludedPermissions);
        setDisabled(false);
    };

    if (redirect) {
        return <Route path="*" element={<Navigate to="/users" />} />;
    }
    return (
        <div className={classes.root}>
            <MultiSelect
                customLabel="User roles"
                selectOptions={allRoles}
                selectedOptions={selectedRoles}
                placeholder={'Choose roles'}
                name={'fitlers'}
                fullWidth={true}
                margin="dense"
                isMulti={true}
                getMultiSelectData={getRolesSelectData}
            />
            <br />
            <MultiSelect
                key={'sad'}
                customLabel="User persmision"
                selectOptions={allPermissionsSelect}
                selectedOptions={allPermissionsSelected}
                placeholder={'Choose persmision'}
                name={'fitlers'}
                fullWidth={true}
                margin="dense"
                isMulti={true}
                isDisabled={true}
            />
            <br />
            <MultiSelect
                customLabel="Included permissions"
                selectOptions={allPermissionsIncluded}
                selectedOptions={selectedIncludedPermissions}
                placeholder={'Choose included permissions'}
                name={'fitlers'}
                fullWidth={true}
                margin="dense"
                isMulti={true}
                getMultiSelectData={getIncludedPermissionsSelectData}
            />
            <br />
            <MultiSelect
                fullWidth={true}
                margin="dense"
                isMulti={true}
                customLabel="Excluded permissions"
                selectOptions={allPermissionsExcluded}
                selectedOptions={selectedExcludedPermissions}
                placeholder={'Choose excluded permissions'}
                name={'fitlers'}
                getMultiSelectData={getExcludedPermissionsSelectData}
            />
            <br />
            <div style={{ textAlign: 'right' }}>
                <Button onClick={handleCancel} variant="contained">
                    Cancel
                </Button>
                <Button disabled={disabled} onClick={handleSubmit} variant="contained" color="primary">
                    Save
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        hasEditPermission: state.core.login.permissions.includes('*') || state.core.login.permissions.includes('core_role_edit'),
        roles: state.core.role.list,
        permissions: state.core.permission.list,
        includedPermissions: state.core.user.includedPermissions,
        excludedPermissions: state.core.user.excludedPermissions,
        userRoles: state.core.user.userRoles,
        allUserPermissions: state.core.user.allUserPermissions
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchUserRoles: userId => dispatch(actions.fetchUserRoles(userId)),
        onFetchRoles: () => dispatch(roleActions.fetchRoles()),
        onBulkSaveRoles: data => dispatch(actions.bulkSaveUserRoles(data)),
        onFetchIncludedPermissions: userId => dispatch(actions.fetchIncludedPermissions(userId)),
        onFetchPermissions: () => dispatch(permissionActions.fetchPermissions()),
        onSaveIncludedPermissions: data => dispatch(actions.saveIncludedPermissions(data)),
        onFetchExcludedPermissions: userId => dispatch(actions.fetchExcludedPermissions(userId)),
        onSaveExcludedPermissions: data => dispatch(actions.saveExcludedPermissions(data)),
        onFetchAllUserPermissions: userId => dispatch(actions.fetchAllUserPermissions(userId))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(UserAccessEdit));
