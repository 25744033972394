import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route } from 'react-router';
import { Grid, Paper, withStyles } from '@material-ui/core';

import * as actions from './store/customerActions';
import Form from '../../np-form/Form';
import * as formUtility from '../../np-form/utility';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,

        padding: theme.spacing(2),
        display: 'flex',
        flexWrap: 'wrap'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center'
    }
});
const CustomerCreate = props => {
    const [customerForm, setCustomerForm] = useState({
        name: {
            elementType: 'input',
            elementConfig: {
                fullWidth: true ,
                margin: 'dense',
                type: 'text',
                placeholder: 'Customer name',
                label: 'Customer name'
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        }
    });

    const [formIsValid, setFormIsValid] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const { classes, hasEditPermission } = props;

    const submitHandler = event => {
        event.preventDefault();

        // Prepare the form data
        const formData = formUtility.getFormData(customerForm);

        const customerData = {
            Name: formData.name
        };

        props.onCreateCustomer(customerData);

        setRedirect(true);
    };

    // Input change handler
    const inputChangedHandler = (event, inputIdentifier) => {
        // Get default new state based on the input change event
        const updatedState = formUtility.getNewStateOnInputChange(event, inputIdentifier, customerForm);
        setCustomerForm(updatedState.myForm);
        setFormIsValid(updatedState.formIsValid);
    };

    const cancelHandler = () => {
        setRedirect(true);
    };
    if (redirect) {
        return <Route path="*" element={<Navigate to="/customers" />} />;
    }

    // Render form
    return hasEditPermission ? (
        <div className={classes.root}>
            <Grid container spacing={10} justify="center" direction="row" alignItems="center">
                <Grid item lg={6} md={8} sm={10} xs={12}>
                    <Paper className={classes.paper}>
                        <Form
                            inputChangedHandler={inputChangedHandler}
                            submitHandler={submitHandler}
                            form={customerForm}
                            formIsValid={formIsValid}
                            buttonText={'Save'}
                            cancelHandler={cancelHandler}
                            buttonTextCancel="Cancel"
                            formTitle={'Enter data to create new customer'}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    ) : null;
};

const mapStateToProps = state => {
    return {
        hasEditPermission: state.core.login.permissions.includes('*') || state.core.login.permissions.includes('core_customer_edit'),
        createdCustomer: state.core.customer.created,
        creating: state.core.customer.creating
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCreateCustomer: customerData => dispatch(actions.createCustomer(customerData))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(CustomerCreate));
