import React, { useState, useEffect } from 'react';

import Form from '../../np-form/Form';
import { Dialog, withStyles } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    },
    button: {
        color: 'white'
    }
});

const updateFormState = props => {
    let { trainings, modules, sites } = props;
    modules = modules.map(module => {
        return { value: module.ModuleID.toString(), displayValue: module.Name }
    });

    trainings = trainings.map(training => {
        return { value: training.TrainingID.toString(), displayValue: training.Name }
    });
    sites = sites.map(site => {
        return { value: site.SiteID.toString(), displayValue: site.Name }
    })
    return {
        SiteID: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Site',
                className: null,
                margin: "dense",
                fullWidth: true,
                options: sites ? sites : []
            },
            formHelperText: "Please select Site for training",
            value: sites instanceof (Array) && sites.length > 0 ? sites[0].value : null,
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        TrainingID: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Training',
                className: null,
                margin: "dense",
                fullWidth: true,
                options: trainings ? trainings : []
            },
            formHelperText: "Please select training",
            value: trainings && trainings.length > 0 ? trainings[0].value : null,
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        ModuleID: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Module',
                className: null,
                margin: "dense",
                fullWidth: true,
                options: modules ? modules : []
            },
            formHelperText: "Please select module for training",
            value: modules && modules.length > 0 ? modules[0].value : null,
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        // Fields hidden for now, will be shown later
        // ConditionType: {
        //     elementType: 'input',
        //     elementConfig: {
        //         type: 'text',
        //         placeholder: 'Condition Type',
        //         label: 'Condition Type',
        //         className: classes && classes.inputs ? classes.inputs : null,
        //         margin: "dense",
        //         fullWidth: true,
        //         autoFocus: true

        //     },
        //     value: null,
        //     validation: {
        //         required: false
        //     },
        //     valid: false,
        //     touched: false
        // },
        // ConditionParams: {
        //     elementType: 'input',
        //     elementConfig: {
        //         type: 'text',
        //         placeholder: 'Condition Params',
        //         label: 'Condition Params',
        //         className: classes && classes.inputs ? classes.inputs : null,
        //         margin: "dense",
        //         fullWidth: true,
        //         autoFocus: true

        //     },
        //     value: null,
        //     validation: {
        //         required: false
        //     },
        //     valid: false,
        //     touched: false
        // },
        // ConditionValue: {
        //     elementType: 'input',
        //     elementConfig: {
        //         type: 'number',
        //         placeholder: 'Condition Value',
        //         label: 'Condition Value',
        //         className: classes && classes.inputs ? classes.inputs : null,
        //         margin: "dense",
        //         fullWidth: true,
        //         autoFocus: true

        //     },
        //     value: null,
        //     validation: {
        //         isNumber: true,
        //         required: false
        //     },
        //     valid: false,
        //     touched: false
        // },
        Skippable: {
            elementType: 'checkbox',
            elementConfig: {
                type: 'checkbox',
                label: null,
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [{ ID: 'checkboxID', Checked: false, Name: 'Skip Module' }]
            },
            formHelperText: 'Should the module be skipped? (Check for Yes)',
            value: false,
            validation: {
                required: false
            },
            valid: true,
            touched: true
        }
    };
};

const TrainingModulesCreate = props => {
    const { open, classes, loading, activeCustomer } = props;
    const { handleOpen, createTrainingModule } = props;

    const [formIsValid, setFormIsValid] = useState(false);
    const [trainingForm, setTrainingForm] = useState(updateFormState({ ...props }));

    useEffect(() => {
        setTrainingForm(updateFormState({ ...props }));
    }, [props]);


    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, trainingForm);
        setTrainingForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const checkboxChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnCheckboxChange(event, inputIdentifier, trainingForm);
        setTrainingForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const submitHandler = async event => {
        event.preventDefault();
        const formData = formUtility.getFormData(trainingForm);

        createTrainingModule({ ...formData, ActiveCustomerID: activeCustomer });
        handleOpen();
        setTrainingForm({});
        setFormIsValid(false);
    };

    const cancelHandler = () => {
        setTrainingForm({});
        setFormIsValid(false);
        handleOpen();
    };

    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            // onClose={handleOpen}
            fullWidth={true}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleOpen();
                }
            }}
        >
            <Form
                formIsValid={formIsValid}
                classes={classes}
                form={trainingForm}
                formTitle="Create module training relation"
                buttonText="Save"
                buttonTextCancel="Cancel"
                submitHandler={submitHandler}
                cancelHandler={cancelHandler}
                checkboxChangedHandler={checkboxChangedHandler}
                inputChangedHandler={inputChangedHandler}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                submitButtonStyle={formIsValid && !loading ? { color: 'white' } : null}
                cancelButtonDisabled={loading}
                submitButtonDisabled={loading}
            />
        </Dialog>
    )
};

export default withStyles(styles)(TrainingModulesCreate);