import restCrudActions from '../../../np-crud/store/restCrudActions'
import { getAxiosInstance } from '../../../np-rest/rest';
import * as actionTypes from './actionTypes';
import { enqueueSnackbar } from '../../../np-dashboard/Notifier/store/notifierActions';

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: '/billing/account',
    pluginName: 'BILLING',
    entityName: 'ACCOUNT',
    fetchSuccessMessage: '',
    loadSuccessMessage: ''
});

// Fetch billingAccount action creators
export const fetchBillingAccountInit = () => {
    return crudActions.fetchInit();
};
export const fetchBillingAccountStart = () => {
    return crudActions.fetchStart();
};
export const fetchBillingAccountSuccess = () => {
    return crudActions.fetchSuccess();
};
export const fetchBillingAccountFail = () => {
    return crudActions.fetchFail();
};
export const fetchBillingAccount = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
};

// Load billingAccount action creators
export const loadBillingAccountInit = () => {
    return crudActions.loadInit();
};
export const loadBillingAccountStart = () => {
    return crudActions.loadStart();
};
export const loadBillingAccountSuccess = loadedBillingAccount => {
    return crudActions.loadSuccess(loadedBillingAccount);
};
export const loadBillingAccountFail = error => {
    return crudActions.loadFail(error);
};
export const loadBillingAccount = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

// Create billingAccount  action creators
export const createBillingAccountInit = () => {
    return crudActions.createInit();
};
export const createBillingAccountStart = () => {
    return crudActions.createStart();
};
export const createBillingAccountSuccess = createdBillingAccount => {
    return crudActions.createSuccess(createdBillingAccount);
};
export const createBillingAccountFail = error => {
    return crudActions.createFail(error);
};
export const createBillingAccount = billingAccount => {
    return async dispatch => {
        await crudActions.create(dispatch, billingAccount);
    };
};

// Edit billingAccount  action creators
export const editBillingAccountInit = () => {
    return crudActions.editInit();
};
export const editBillingAccountStart = () => {
    return crudActions.editStart();
};
export const editBillingAccountSuccess = editedBillingAccount => {
    return crudActions.editSuccess(editedBillingAccount);
};
export const editBillingAccountFail = error => {
    return crudActions.editFail(error);
};
export const editBillingAccount = billingAccount => {
    return async dispatch => {
        await crudActions.edit(dispatch, billingAccount);
    };
};

// Bulk delete billingAccount action creators
export const bulkDeleteBillingAccountsInit = () => {
    return crudActions.bulkDeleteInit();
};
export const bulkDeleteBillingAccountsStart = () => {
    return crudActions.bulkDeleteStart();
};
export const bulkDeleteBillingAccountsSuccess = bulkDeleted => {
    return crudActions.bulkDeleteSuccess(bulkDeleted);
};
export const bulkDeleteBillingAccountsFail = error => {
    return crudActions.bulkDeleteFail(error);
};
export const bulkDeleteBillingAccounts = billingAccountIds => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, billingAccountIds);
    };
};

// Delete billingAccount  action creators
export const deleteBillingAccountInit = () => {
    return crudActions.deleteInit();
};
export const deleteBillingAccountStart = () => {
    return crudActions.deleteStart();
};
export const deleteBillingAccountSuccess = deletedBillingAccount => {
    return crudActions.deleteSuccess(deletedBillingAccount);
};
export const deleteBillingAccountFail = error => {
    return crudActions.deleteFail(error);
};
export const deleteBillingAccount = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};

// list account action licenses
export const actionLicensesStart = () => {
    return {
        type: actionTypes.BILLING_ACTION_LICENSE_LIST_START,
        loading: true
    };
};
export const actionLicensesSuccess = actionLicenses => {
    return {
        type: actionTypes.BILLING_ACTION_LICENSE_LIST_START_SUCCESS,
        loading: false,
        actionLicenses: actionLicenses
    };
};
export const actionLicensesFail = () => {
    return {
        type: actionTypes.BILLING_ACTION_LICENSE_LIST_FAIL,
        loading: false
    };
};

// list account software licenses
export const softwareLicensesStart = () => {
    return {
        type: actionTypes.BILLING_SOFTWARE_LICENSE_LIST_START,
        loading: true
    };
};
export const softwareLicensesSuccess = softwareLicenses => {
    return {
        type: actionTypes.BILLING_SOFTWARE_LICENSE_LIST_SUCCESS,
        loading: false,
        softwareLicenses: softwareLicenses
    };
};
export const softwareLicensesFail = () => {
    return {
        type: actionTypes.BILLING_SOFTWARE_LICENSE_LIST_FAIL,
        loading: false
    };
};
// create account software licenses
export const softwareLicenseCreateStart = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_CREATE_START,
        creating: true
    };
};
export const softwareLicenseCreateSuccess = softwareLicense => {
    return {
        type: actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_CREATE_SUCCESS,
        creating: false,
        created: softwareLicense
    };
};
export const softwareLicenseCreateFail = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_CREATE_FAIL,
        creating: false
    };
};

// delete account software licenses
export const softwareLicenseDeleteStart = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_DELETE_START,
        deleting: true
    };
};
export const softwareLicenseDeleteSuccess = softwareLicense => {
    return {
        type: actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_DELETE_SUCCESS,
        deleting: false,
        deleted: softwareLicense
    };
};
export const softwareLicenseDeleteFail = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_DELETE_FAIL,
        deleting: false
    };
};

// load account software licenses
export const softwareLicenseLoadStart = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_LOAD_START
    };
};
export const softwareLicenseLoadSuccess = softwareLicense => {
    return {
        type: actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_LOAD_SUCCESS,
        loaded: softwareLicense
    };
};
export const softwareLicenseLoadFail = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_LOAD_FAIL
    };
};

// update account software licenses
export const softwareLicenseUpdateStart = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_UPDATE_START,
        updating: true
    };
};
export const softwareLicenseUpdateSuccess = softwareLicense => {
    return {
        type: actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_UPDATE_SUCCESS,
        updated: softwareLicense,
        updating: false
    };
};
export const softwareLicenseUpdateFail = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_SOFTWARE_LICENSE_UPDATE_FAIL,
        updating: false
    };
};

// list account plans
export const accountPlansListStart = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_PLANS_LIST_START,
        loading: true
    };
};
export const accountPlansListSuccess = accountPlans => {
    return {
        type: actionTypes.BILLING_ACCOUNT_PLANS_LIST_SUCCESS,
        loading: false,
        accountPlans: accountPlans
    };
};
export const accountPlansListFail = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_PLANS_LIST_FAIL,
        loading: false
    };
};

// list prepaids
export const prepaidListStart = () => {
    return {
        type: actionTypes.BILLING_PREPAID_LIST_START,
        loading: true
    };
};
export const prepaidListSuccess = prepaidList => {
    return {
        type: actionTypes.BILLING_PREPAID_LIST_SUCCESS,
        loading: false,
        prepaids: prepaidList
    };
};
export const prepaidListFail = () => {
    return {
        type: actionTypes.BILLING_PREPAID_LIST_FAIL,
        loading: false
    };
};
// create prepaid plan
export const prepaidCreateStart = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_PREPAID_CREATE_START,
        creating: true
    };
};
export const prepaidCreateSuccess = prepaid => {
    return {
        type: actionTypes.BILLING_ACCOUNT_PREPAID_CREATE_SUCCESS,
        creating: false,
        created: prepaid
    };
};
export const prepaidCreateFail = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_PREPAID_CREATE_FAIL,
        creating: false
    };
};

// delete prepaid plan
export const prepaidDeleteStart = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_PREPAID_DELETE_START,
        deleting: true
    };
};
export const prepaidDeleteSuccess = prepaid => {
    return {
        type: actionTypes.BILLING_ACCOUNT_PREPAID_DELETE_SUCCESS,
        deleting: false,
        deleted: prepaid
    };
};
export const prepaidDeleteFail = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_PREPAID_DELETE_FAIL,
        deleting: false
    };
};

// load prepaid plan
export const prepaidLoadStart = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_PREPAID_LOAD_START
    };
};
export const prepaidLoadSuccess = prepaid => {
    return {
        type: actionTypes.BILLING_ACCOUNT_PREPAID_LOAD_SUCCESS,
        loaded: prepaid
    };
};
export const prepaidLoadFail = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_PREPAID_LOAD_FAIL
    };
};

// update prepaid plan
export const prepaidUpdateStart = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_PREPAID_UPDATE_START,
        updating: true
    };
};
export const prepaidUpdateSuccess = prepaid => {
    return {
        type: actionTypes.BILLING_ACCOUNT_PREPAID_UPDATE_SUCCESS,
        updated: prepaid,
        updating: false
    };
};
export const prepaidUpdateFail = () => {
    return {
        type: actionTypes.BILLING_ACCOUNT_PREPAID_UPDATE_FAIL,
        updating: false
    };
};

export const listCustomers = () => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/customers/list', { forList: true });
            if (res.data && res.data.succeeded) {
                const customers = [];
                for (var customer of res.data.result.data) {
                    const Address = customer.Address ? JSON.parse(customer.Address) : {};
                    customers.push({
                        value: customer.CustomerID,
                        displayValue: customer.Name,
                        City: Address.City,
                        Country: Address.Country,
                        PostCode: Address.PostCode,
                        StreetAddress: Address.StreetAddress
                    });
                }
                return customers;
            } else {
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : res.message ? res.message : 'Applicant not activated.'}`,
                        options: {
                            variant: 'warning'
                        }
                    }));
                return [];
            }
        } catch (error) {
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
        }
    };
};

export const listUsers = (id) => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/users/list', { forList: true, ...id, Status: 1, Deleted: false });
            if (res.data && res.data.succeeded) {
                const users = [];
                for (var user of res.data.result.data) {
                    users.push({
                        value: user.UserID,
                        displayValue: `${user.FirstName} ${user.LastName} (${user.Username})`
                    });
                }
                return users;
            }
            else {
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : res.message ? res.message : 'Applicant not activated.'}`,
                        options: {
                            variant: 'warning'
                        }
                    }));
                return [];
            }
        } catch (error) {
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return [];

        }
    };
};

export const listLicensePrototypes = (id) => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/license-prototype/list', { forList: true, ...id });
            if (res.data && res.data.succeeded) {
                const licensePrototypes = [];
                for (var licensePrototype of res.data.result.data) {
                    licensePrototypes.push({
                        value: licensePrototype.LicensePrototypeID,
                        displayValue: licensePrototype.Name
                    });
                }
                return licensePrototypes;
            }
            else {
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : res.message ? res.message : 'Applicant not activated.'}`,
                        options: {
                            variant: 'warning'
                        }
                    }));
                return [];
            }
        } catch (error) {
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return [];
        }
    };
};
export const listPlans = (id) => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/billing/plan/list', { forList: true, ...id });
            if (res.data && res.data.succeeded) {
                const plans = [];
                for (var plan of res.data.result.data) {
                    if (!['active', 'prepared'].includes(plan.Status)) {
                        continue;
                    }
                    plans.push({ value: plan.RowID, label: plan.Name });
                }
                return plans;
            }
            else {
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : res.message}`,
                        options: {
                            variant: 'warning'
                        }
                    }));
                return [];
            }
        }
        catch (error) {
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return [];
        }
    };
};

export const onAccountPlansList = billingAccount => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(accountPlansListStart());
            let res = await rest.post('/billing/account/load-plans', billingAccount);
            if (res.data && res.data.succeeded) {
                dispatch(accountPlansListSuccess({
                    list: res.data.result.data,
                    count: -1
                }));
                return res.data.result.data;
            }
            else {
                dispatch(accountPlansListFail());

                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : res.message}`,
                        options: {
                            variant: 'warning'
                        }
                    }));
                return {};
            }
        } catch (error) {
            dispatch(accountPlansListFail());
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return {};
        }
    };
};


export const onListSoftwareLicenses = billingAccount => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(softwareLicensesStart());
            let countRes = await rest.post('/license/count', billingAccount);
            let res = await rest.post('/license/list', billingAccount);
            if (res.data && res.data.succeeded && countRes.data && countRes.data.succeeded) {
                dispatch(softwareLicensesSuccess({
                    list: res.data.result.data,
                    count: countRes.data.result.data
                }));
                return res.data.result.data;
            }
            else {
                dispatch(softwareLicensesFail());

                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : res.message}`,
                        options: {
                            variant: 'warning'
                        }
                    }));
                return {};
            }
        } catch (error) {
            dispatch(softwareLicensesFail());
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return {};
        }
    };
};

export const onUpdateSoftwareLicense = softwareLicense => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(softwareLicenseUpdateStart());
            let res = await rest.post('/license/save', softwareLicense);
            if (res.data && res.data.succeeded) {
                dispatch(softwareLicenseUpdateSuccess({
                    updated: res.data.result.data
                }));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data.message}`,
                        options: {
                            variant: 'success'
                        }
                    }))

                return res.data.result.data;
            }
            else {
                dispatch(softwareLicenseUpdateFail());
                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'warning'
                    }
                }))
                return {};
            }
        }
        catch (error) {
            dispatch(softwareLicenseUpdateFail());
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return {};
        }
    };
};

export const onFetchSoftwareLicense = softwareLicense => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(softwareLicenseLoadStart());
            let res = await rest.post('/license/load', softwareLicense);
            if (res.data && res.data.succeeded) {
                dispatch(softwareLicenseLoadSuccess(res.data.result.data));
                return res.data.result.data;
            }
            else {
                dispatch(softwareLicenseLoadFail());

                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'warning'
                    }
                }))
                return {};
            }

        } catch (error) {
            dispatch(softwareLicenseLoadFail());
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return {};
        }
    };
};

export const onDeleteSoftwareLicense = softwareLicense => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(softwareLicenseDeleteStart());
            let res = await rest.post('/license/delete', softwareLicense);
            if (res.data && res.data.succeeded) {
                dispatch(softwareLicenseDeleteSuccess({
                    softwareLicense: res.data.result.data
                }));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data.message}`,
                        options: {
                            variant: 'success'
                        }
                    }))

                return res.data.result.data;
            } else {
                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'warning'
                    }
                }))
                dispatch(softwareLicenseDeleteFail());
                return {};
            }
        } catch (error) {
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return {};
        }
    };
};

export const onSoftwareLicenseCreate = softwareLicense => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(softwareLicenseCreateStart());
            let res = await rest.post('/license/create', softwareLicense);
            if (res.data && res.data.succeeded) {
                dispatch(softwareLicenseCreateSuccess({
                    softwareLicense: res.data.result.data
                }));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data.message}`,
                        options: {
                            variant: 'success'
                        }
                    }))

                return res.data.result.data;
            }
            else {
                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'warning'
                    }
                }))
                dispatch(softwareLicenseCreateFail());
                return {};
            }

        } catch (error) {
            dispatch(softwareLicenseCreateFail());
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return {};
        }
    };
};

export const onListActionLicenses = billingAccount => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(actionLicensesStart());
            let countRes = await rest.post('/action-license/count', billingAccount);
            let res = await rest.post('/action-license/list', billingAccount);
            if (res.data && res.data.succeeded && countRes.data && countRes.data.succeeded) {
                dispatch(actionLicensesSuccess({
                    list: res.data.result.data,
                    count: countRes.data.result.data
                }));
                return res.data.result.data;
            }
            else {
                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'warning'
                    }
                }))
                dispatch(actionLicensesFail());
                return {};
            }

        } catch (error) {
            dispatch(actionLicensesFail());
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return {};
        }
    };
};

export const assignPlans = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(editBillingAccountInit());
        try {
            dispatch(editBillingAccountStart());
            let res = await rest.post('/billing/account/assign-plans', data);
            if (res.data && res.data.succeeded) {
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data.message}`,
                        options: {
                            variant: 'success'
                        }
                    }))
                const plans = [];
                for (var plan of res.data.result.data) {
                    plans.push({ value: plan.PlanID, displayValue: plan.Name });
                }
                dispatch(editBillingAccountSuccess(res.data.result.data));
                return plans;
            }
            else {
                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'warning'
                    }
                }))
                return [];
            }


        } catch (error) {
            dispatch(editBillingAccountFail(error));
            dispatch(
                enqueueSnackbar({
                    message: `${error.message}`,
                    options: {
                        variant: 'error'
                    }
                }));
            return [];
        }
    };
};

export const removePlans = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(editBillingAccountInit());
        try {
            dispatch(editBillingAccountStart());
            let res = await rest.post('/billing/account/remove-plans', data);
            if (res.data && res.data.succeeded) {
                dispatch(editBillingAccountSuccess({}));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data.message}`,
                        options: {
                            variant: 'success'
                        }
                    }))
            }
            else {
                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'warning'
                    }
                }))

                return [];
            }
        } catch (error) {
            dispatch(editBillingAccountFail(error));

            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }))

            return [];
        }
    };
};

/*****************************************************
 *               PREPAID PLAN ACTIONS                *
 *****************************************************/
export const onListPrePaidPlans = prepaidPlan => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(prepaidListStart());
            let countRes = await rest.post('/billing/pre-paid/count', prepaidPlan);
            let res = await rest.post('/billing/pre-paid/list', prepaidPlan);
            if (res.data && res.data.succeeded && countRes.data && countRes.data.succeeded) {
                dispatch(prepaidListSuccess({
                    list: res.data.result.data,
                    count: countRes.data.result.data
                }));
                return res.data.result.data;
            }
            else {
                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'warning'
                    }
                }))
                dispatch(prepaidListFail());

                return {};
            }
        } catch (error) {
            dispatch(prepaidListFail());
            dispatch(
                enqueueSnackbar({
                    message: `${error.message}`,
                    options: {
                        variant: 'error'
                    }
                }));
            return {};
        }
    };
};

export const onUpdatePrePaid = prepaid => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(prepaidUpdateStart());
            let res = await rest.post('/billing/pre-paid/save', prepaid);
            if (res.data && res.data.succeeded) {
                dispatch(prepaidUpdateSuccess({
                    updated: res.data.result.data
                }));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data.message}`,
                        options: {
                            variant: 'success'
                        }
                    }))
                return res.data.result.data;
            }
            else {
                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'warning'
                    }
                }))
                dispatch(prepaidUpdateFail());

                return {};
            }
        } catch (error) {
            dispatch(prepaidUpdateFail());
            dispatch(
                enqueueSnackbar({
                    message: `${error.message}`,
                    options: {
                        variant: 'error'
                    }
                }));
            return {};
        }
    };
};

export const onFetchPrePaid = prepaid => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(prepaidLoadStart());
            let res = await rest.post('/billing/pre-paid/load', prepaid);
            if (res.data && res.data.succeeded) {
                dispatch(prepaidLoadSuccess(res.data.result.data));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data.message}`,
                        options: {
                            variant: 'success'
                        }
                    }))
                return res.data.result.data;
            }
            else {
                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'warning'
                    }
                }))
                dispatch(prepaidLoadFail());

                return {};
            }
        } catch (error) {
            dispatch(prepaidLoadFail());
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return {};
        }
    };
};

export const onDeletePrepaid = prepaid => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(prepaidDeleteStart());
            let res = await rest.post('/billing/pre-paid/delete', prepaid);
            if (res.data && res.data.succeeded) {
                dispatch(prepaidDeleteSuccess({
                    prepaid: res.data.result.data
                }));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data.message}`,
                        options: {
                            variant: 'success'
                        }
                    }))
                return res.data.result.data;
            }
            else {
                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'warning'
                    }
                }))
                dispatch(prepaidDeleteFail());

                return {};
            }
        } catch (error) {
            dispatch(prepaidDeleteFail());
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return {};
        }
    };
};

export const onPrepaidPlanCreate = prepaid => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(prepaidCreateStart());
            let res = await rest.post('/billing/pre-paid/create', prepaid);
            if (res.data && res.data.succeeded) {
                dispatch(prepaidCreateSuccess({
                    prepaid: res.data.result.data
                }));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data.message}`,
                        options: {
                            variant: 'success'
                        }
                    }))
                return res.data.result.data;
            }
            else {
                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'warning'
                    }
                }))
                dispatch(prepaidCreateFail());

                return {};
            }
        } catch (error) {
            dispatch(prepaidCreateFail());
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return {};
        }
    };
};
/*****************************************************
 *            Delivery notes                *
 *****************************************************/

// list prepaids
export const deliveryNotesListStart = () => {
    return {
        type: actionTypes.BILLING_PREPAID_LIST_START,
        loading: true
    };
};
export const deliveryNotesListSuccess = deliveryNotesList => {
    return {
        type: actionTypes.BILLING_PREPAID_LIST_SUCCESS,
        loading: false,
        deliveryNotes: deliveryNotesList
    };
};
export const deliveryNotesListFail = () => {
    return {
        type: actionTypes.BILLING_PREPAID_LIST_FAIL,
        loading: false
    };
};
export const onListDeliveryNotes = deliveryNotesPlan => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(deliveryNotesListStart());
            let countRes = await rest.post('/billing/delivery_notes/count', deliveryNotesPlan);
            let res = await rest.post('/billing/delivery_notes/list', deliveryNotesPlan);
            if (res.data && res.data.succeeded && countRes.data && countRes.data.succeeded) {
                dispatch(deliveryNotesListSuccess({
                    list: res.data.result.data,
                    count: countRes.data.result.data
                }));
                return res.data.result.data;
            }
            else {
                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'warning'
                    }
                }))
                dispatch(deliveryNotesListFail());

                return {};
            }
        } catch (error) {
            dispatch(deliveryNotesListFail());
            dispatch(
                enqueueSnackbar({
                    message: `${error && error.message ? error.message : 'Cannot create binary image'}`,
                    options: {
                        variant: 'error'
                    }
                }));
            return {};
        }
    };
};


export const cleanState = () => {
    return ({
        type: 'CLEAN_STATE_ACCOUNT',
    });
}