import React from 'react';
import { withStyles, Button, MenuItem, Typography, Select } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';

/**
 * Rows per page options
 * Rows per page
 * Data Count
 * Page
 * onChangePage
 * onChangeRowsPerPage
 */
const styles = theme => ({
    pagination: { width: '100%' },
    inputs: {
        height: 1,
        fontSize: '15px'
    },
    text: {
        paddingTop: 9,
        paddingRight: 3,
        paddingLeft: 3,
        [theme.breakpoints.down('md')]: {
            paddingRight: 0,
            paddingLeft: 0
        }
    },
    bootstrapRoot: {
        'label + &': {
            marginTop: theme.spacing(5)
        }
    },
    bootstrapInput: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 14,
        width: '50px',
        padding: '10px 0px 10px 3px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Arial'
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: `0 0 0 0.2rem ${theme.palette.secondary.main}`
        }
    },
    submit: {
        textTransform: 'none',
        color: theme.palette.buttonText.default,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    cancel: {
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.buttonText.default
        }
    },
});

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(5)
        }
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 14,
        width: '40px',
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Arial'
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: `0 0 0 0.2rem ${theme.palette.secondary.main}`
        }
    }
}))(InputBase);

const TablePagination = props => {
    const { classes } = props;
    const { rowsPerPageOptions, rowsPerPage, translations,tableData, setButton, handleLazyLoadingButton, button } = props;
    const { options, page } = props;
    const { onChangePage, onChangeRowsPerPage } = props;
    const handleChangePage = event => {
        return onChangePage(event.currentTarget.value - 1);
    };
    const handleChangeRowsPerPage = rowsPerPage => {
        return onChangeRowsPerPage(rowsPerPage);
    };
    const handleChangeLazyLoading = key => {
        switch (key) {
            case 10:
                setButton(10)
                handleLazyLoadingButton(10)
                return;
            case 20:
                setButton(20)
                handleLazyLoadingButton(20)
                return;
            case 50:
                setButton(50)
                handleLazyLoadingButton(50)
                return;
            default:
                setButton(50);
                return
        }
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'row-reverse', padding: 5, width: '97.5%' }}>
            {props.lazyLoading ? ( <> <Button disabled={!tableData  }size={'small'} className={button === 50 && tableData? classes.submit : classes.cancel} onClick={() => handleChangeLazyLoading(50)} >
                +50
                </Button>

                <Button disabled={!tableData }size={'small'} className={button === 20 && tableData? classes.submit : classes.cancel} onClick={() => handleChangeLazyLoading(20)} >
                    +20
                </Button>
                <Button disabled={!tableData  }size={'small'} className={button === 10 && tableData? classes.submit : classes.cancel} onClick={() => handleChangeLazyLoading(10)} >
                    +10
                </Button><p>{translations ?  translations['load_more'] || 'Load more' : 'Load more'}:</p>   </>) : (<>


                    <Button
                        variant="text"
                        disabled={(page === Math.floor(options.count / rowsPerPage) && options.count !== rowsPerPage) || rowsPerPage >= options.count || rowsPerPage === -1}
                        onClick={() => {
                            return onChangePage(page + 1);
                        }}
                    >
                        {'>'}
                    </Button>
                    <Select
                        style={{ paddingRight: 4 }}
                        value={rowsPerPage}
                        onChange={event => {
                            return handleChangeRowsPerPage(event.target.value);
                        }}
                        input={<BootstrapInput name="rowsPerPage" id="rows_per_page_select" />}
                    >
                        {rowsPerPageOptions.map(option => (
                            <MenuItem key={`rows_per_page_option_${option}`} value={typeof option === 'string' ? -1 : option}>
                                {isNaN(Number(option)) ? translations ? translations['all'] || 'All' : 'All' : option}
                            </MenuItem>
                        ))}
                    </Select>
                    <Typography className={classes.text}>
                        {translations ? translations['to'] || 'to' : 'to'} {rowsPerPage !== -1 && options.count > rowsPerPage ? (options.count > rowsPerPage * (page + 1) ? rowsPerPage * (page + 1) : options.count) : options.count} {translations ? translations['of'] || 'of' : 'of'}  {options.count}
                    </Typography>
                    <InputBase
                        id="page-number"
                        value={Number(page) + 1}
                        onChange={event => {
                            if (!isNaN(Number(event.currentTarget.value))) {
                                if (event.currentTarget.value >= 1 && event.currentTarget.value <= Math.round(options.count / rowsPerPage) + 1 && rowsPerPage < options.count) {
                                    return handleChangePage(event);
                                }
                            }
                        }}
                        variant="outlined"
                        type="number"
                        input={<BootstrapInput name="pageNumber" id="page" />}
                        min={1}
                        max={options.count > rowsPerPage ? Math.round(options.count / rowsPerPage) + 1 : 1}
                        classes={{
                            root: classes.bootstrapRoot,
                            input: classes.bootstrapInput
                        }}
                    />
                    <Typography className={classes.text}>{translations ? translations['page'] || 'Page' : 'Page'}</Typography>
                    <Button
                        variant="text"
                        disabled={page < 1}
                        onClick={() => {
                            return onChangePage(page - 1);
                        }}
                    >
                        {'<'}
                    </Button>



                </>
                )}

        </div>
    );
};
export default withStyles(styles, { withTheme: true })(TablePagination);
