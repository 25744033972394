import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import Chat from "@material-ui/icons/Chat";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import SearchIcon from "@material-ui/icons/Search";
import { ArrowBack, Info, Help } from "@material-ui/icons";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Typography, Button, CircularProgress } from "@material-ui/core";
import History from "@material-ui/icons/History";
import CloudDownload from "@material-ui/icons/CloudDownload";
import LinearProgress from "@material-ui/core/LinearProgress";

import Sidebar from "../../np-dashboard/Sidebar/Sidebar";
import Notifier from "../../np-dashboard/Notifier/Notifier";
import CountdownTimer from "../../np-components/CountdownTimer/CountdownTimer";

// const drawerWidthOpened = 300;
const drawerWidthClosed = 60;

const styles = (theme) => ({
    root: {
        width: "100%",
    },
    grow: {
        flexGrow: 1,
    },
    sidebarContainer: {
        flex: 1,
    },
    mainContainer: {
        float: "right",
        width: `calc(100% - ${240}px)`,
        transition: theme.transitions.create(["width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    mainContainerBig: {
        width: `calc(100% - ${drawerWidthClosed}px) !important`,
    },
    menuButton: {
        color: "#313e48",
        marginLeft: -12,
        marginRight: 20,
    },
    history: {
        marginLeft: "150px",
        marginRight: "-162px",
        [theme.breakpoints.down("lg")]: {
            marginLeft: "150px",
            marginRight: "-160px",
        },
        [theme.breakpoints.down("md")]: {
            marginRight: "-30px",
            marginLeft: theme.spacing(5),
            width: "auto",
        },
        [theme.breakpoints.down("sm")]: {
            marginRight: 0,
            marginLeft: 0,
            width: "auto",
        },
    },
    hide: {
        display: "none",
    },
    appBar: {
        backgroundColor: "#f2f2f2",
        color: "#313e48",
    },
    logo: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
            width: "145px",
        },
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.85),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 1),
        },
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(20),
        width: "50%",
        [theme.breakpoints.down("lg")]: {
            marginRight: theme.spacing(),
            marginLeft: theme.spacing(20),
            width: "50%",
        },
        [theme.breakpoints.down("md")]: {
            marginRight: theme.spacing(),
            marginLeft: theme.spacing(3),
            width: "auto",
        },
        [theme.breakpoints.down("sm")]: {
            marginRight: theme.spacing(),
            marginLeft: theme.spacing(),
            width: "auto",
        },
    },
    help: {
        textDecoration: 'none'
    },
    searchIcon: {
        width: theme.spacing() * 9,
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputRoot: {
        color: "inherit",
        width: "100%",
    },
    inputInput: {
        paddingTop: theme.spacing(),
        paddingRight: theme.spacing(),
        paddingBottom: theme.spacing(),
        paddingLeft: theme.spacing(10),
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "100%",
        },
        [theme.breakpoints.down("md")]: {
            paddingTop: theme.spacing(),
            paddingRight: theme.spacing(),
            paddingBottom: theme.spacing(),
            paddingLeft: theme.spacing(6),
        },
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    menuInfoItem: {
        backgroundColor: theme.palette.primary.main,
    },
    navLink: {
        textDecoration: "none",
        color: "black",
        opacity: 0.87,
    },
});

var id = null;
const listDownloading = (data) => {
    let array = [];
    for (var key in data) {
        if (data.hasOwnProperty(key)) {
            const element = data[key];
            if (element.downloadProgress !== element.maxSizeDownload && element.snackBar) {
                array.push(element);
            }
        }
    }
    return array.map((a) => (
        <div key={`${a.name}-${a.downloadProgress}`}>
            {" "}
            <MenuItem key={`${a.name}-${a.downloadProgress}`}>
                {a.downloadProgress && a.downloadProgress > 0 && a.downloadProgress < 100
                    ? `${a.name} ${a.currentSizeDownload} /  ${a.maxSizeDownload}MB  (${a.downloadProgress}%) `
                    : undefined}
            </MenuItem>
            {a.downloadProgress && a.downloadProgress > 0 && a.downloadProgress < 100 ? (
                <LinearProgress variant="determinate" value={a.downloadProgress} />
            ) : undefined}
        </div>
    ));
};

const Dashboard = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [anchorElDownload, setAnchorElDonwload] = useState(null);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    const handleDownloadClose = () => {
        setAnchorElDonwload(null);
        handleMobileMenuClose();
    };

    useEffect(() => {
        const checkWindowSize = () => {
            setAnchorEl(null);
            setMobileMoreAnchorEl(null);
            if (window.innerWidth < 990) {
                setSidebarOpen(false);
            } else {
                setSidebarOpen(true);
            }
        };
        window.addEventListener("resize", checkWindowSize);
        return () => {
            clearInterval(id);
            window.removeEventListener("resize", checkWindowSize);
        };
    }, []);

    const [sidebarOpen, setSidebarOpen] = useState(!(window.innerWidth < 990));

    const { classes, permissions, children, user, activeCustomer, appBarOptions, countdownTimer, translations } = props;
    let { sidebarRoutes } = props;
    const isMenuDownloadOpen = Boolean(anchorElDownload);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuOpen = (event) => {
        setSidebarOpen(false);
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleSidebarToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleHoverSidebarToggle = (param) => {
        setSidebarOpen(param);
    };

    // Filters the routes from the sidebarRoutes where the user does not have permissions
    const filterSidebarRoutes = (sidebarRoutes, permissions) => {
        // SiteWideAdmin can do everything
        if (permissions.includes("*")) {
            return sidebarRoutes;
        }

        // TODO check this logic
        // Commented by mmihic, logic is totally wrong
        // if (permissions.length === 0) {
        //     let routes = [sidebarRoutes[0]];
        //     return routes;
        // }

        // Filter out components if the needed permissions are not provided
        sidebarRoutes = sidebarRoutes.filter((sidebarRoute) => {
            let hasPermission = true;
            if (sidebarRoute.permissions) {
                hasPermission = sidebarRoute.permissions.some((permission) => permissions.includes(permission));
            }
            if(sidebarRoute.permissions.length === 0)
                hasPermission = true;
            return hasPermission;
        });

        // Filter out sub-components if the needed permissions are not provided
        sidebarRoutes.forEach((sidebarRoute) => {
            if (sidebarRoute.subComponents) {
                sidebarRoute.subComponents = sidebarRoute.subComponents.filter((subComponent) => {
                    let hasPermission = true;
                    if (subComponent && subComponent.permissions) {
                        // Get operation how to check the permissions
                        const operation = subComponent.operation ? subComponent.operation : "AND";
                        if (operation.toUpperCase() === "OR") {
                            hasPermission = subComponent.permissions.some((permission) => permissions.includes(permission));
                        } else {
                            hasPermission = subComponent.permissions.every((permission) => permissions.includes(permission));
                        }
                    }
                    return hasPermission;
                });
            }
        });

        return sidebarRoutes;
    };

    sidebarRoutes = filterSidebarRoutes(sidebarRoutes, permissions);

    return (
        <div className={classes.root}>
            <div className={classes.sidebarContainer}>
                <Sidebar
                    routes={sidebarRoutes}
                    handleSidebarToggle={handleSidebarToggle}
                    handleHoverSidebarToggle={handleHoverSidebarToggle}
                    open={sidebarOpen}
                    className={classes.sideBar}
                    sidebarStyle={props.sidebarStyle}
                />
            </div>
            <div
                className={classNames(props.dashboardStyle ? props.dashboardStyle : classes.mainContainer, {
                    [classes.mainContainerBig]: !sidebarOpen,
                })}
            >
                <AppBar position="sticky" className={classes.appBar}>
                    <Toolbar>
                        <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer" onClick={handleSidebarToggle}>
                            {sidebarOpen ? (
                                props.sideBarOpenCustomIcon ? (
                                    props.sideBarOpenCustomIcon
                                ) : (
                                        <ArrowBack />
                                    )
                            ) : props.sideBarCloseCustomIcon ? (
                                props.sideBarCloseCustomIcon
                            ) : (
                                        <MenuIcon />
                                    )}
                        </IconButton>
                        <img
                            alt="App Logo"
                            className={classes.logo}
                            src={props.logoPath ? process.env.PUBLIC_URL + props.logoPath : process.env.PUBLIC_URL + "/assets/images/app-logo.png"}
                        />
                        {!appBarOptions.search || window.location.href.includes("/search") || !props.hasLastSearch ? null : (
                            <Tooltip title={translations["get_last_search"] || "Get last search"} className={classes.history}>
                                <IconButton aria-label="Get last search" value={props.id} onClick={props.lastSearchHandler}>
                                    <History></History>
                                </IconButton>
                            </Tooltip>
                        )}
                        {!appBarOptions.search ? null : (
                            <div
                                id={"np_global_search_div"}
                                className={classes.search}
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        let searchInput = document.getElementById("np_global_search").value;
                                        if (!searchInput || searchInput.length < 4) {
                                            return props.sendMessage("Search input require min 4 characters.", "warning");
                                        }
                                        return props.handleNPGlobalSearch(searchInput);
                                    }
                                }}
                            >
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder={`${translations["search"] || "Search"}… `}
                                    id={`np_global_search`}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                />
                            </div>
                        )}
                        {!appBarOptions.search ? null : (
                            <IconButton aria-haspopup="true" onClick={props.handleNPGlobalSearchInfo} color="inherit">
                                <Info />
                            </IconButton>
                        )}

                        {!appBarOptions.liveChat ? null : (
                            <Tooltip title={translations["contact_live_chat"] || "Contact/Live Chat"}>
                                <IconButton color="inherit" onClick={() => {
                                    window.chatButton.onClick();
                                }}>
                                    <Chat />
                                </IconButton>
                            </Tooltip>
                        )}

                        {!appBarOptions.help ? null : (
                            <Tooltip title={translations["help_desk"] || "Help desk"}>
                                <IconButton target="_blank" href="https://twentoo.ladesk.com/" color="inherit">
                                    <Help />
                                </IconButton>
                            </Tooltip>
                        )}
                        {!appBarOptions.download ? null : (
                            <div style={{ width: appBarOptions.search ? `26%` : "80%", float: "right" }}>
                                <Tooltip placement="bottom" style={{ float: "right" }} title={translations["download_data "] || "Download data"}>
                                    <IconButton
                                        disabled={props.downloadData ? !props.downloadData.snackBar : true}
                                        onClick={(event) => {
                                            return setAnchorElDonwload(event.currentTarget);
                                        }}
                                        aria-haspopup="true"
                                        component="div"
                                        color="inherit"

                                    >
                                        <CloudDownload style={{ color: props.downloadData && props.downloadData.snackBar ? "#7dc5e2" : "inherit" }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                        <div className={classes.grow} />
                        {countdownTimer ? (
                            <CountdownTimer
                                useDialog={countdownTimer.useDialog}
                                counterValue={countdownTimer.counterValue}
                                remainingSeconds={countdownTimer.remainingSeconds}
                                confirmRemainingSeconds={countdownTimer.confirmRemainingSeconds}
                                onCancel={props.handleLogout}
                                onConfirm={props.handleExtendSession}
                            />
                        ) : null}

                        {props.handleReboot && props.rebootNeeded ? (
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ textTransform: "none", marginLeft: 20, width: 200 }}
                                onClick={props.handleReboot}
                            >
                                Reboot
                            </Button>
                        ) : null}

                        {props.handlePxeRecongifure && props.pxeReconfigureNeeded ? (
                            <Button
                                disabled={props.pxeReconfigureDisabled}
                                variant="contained"
                                color="primary"
                                style={{ textTransform: "none", marginLeft: 20, width: 200 }}
                                onClick={props.handlePxeRecongifure}
                            >
                                {props.pxeReconfigureDisabled ? <CircularProgress size={24} /> : "Reconfigure PXE"}
                            </Button>
                        ) : null}

                        {props.handleNetworkReconfigure && props.networkReconfigureNeeded ? (
                            <Button
                                disabled={props.networkReconfigureDisabled}
                                variant="contained"
                                color="primary"
                                style={{ textTransform: "none", width: 200, marginLeft: 20 }}
                                onClick={props.handleNetworkReconfigure}
                            >
                                {props.networkReconfigureDisabled ? <CircularProgress size={24} /> : "Reconfigure Network"}
                            </Button>
                        ) : null}

                        {props.user ? (
                            <div className={classes.sectionDesktop}>
                                <Button
                                    style={{ textTransform: "none" }}
                                    onMouseOver={(event) => {
                                        return setAnchorEl(event.currentTarget);
                                    }}
                                    className={classes.accountLogoName}
                                >
                                    {typeof activeCustomer === "object" && activeCustomer !== null && Object.keys(activeCustomer).length > 0
                                        ? `${activeCustomer ? activeCustomer.Name : ""} ${user && user.Username ? ` / ${user.Username}` : ""}`
                                        : `${user && user.Username ? `${user.Username}` : ""}`}
                                </Button>
                            </div>
                        ) : null}
                        {props.user ? (
                            <IconButton aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit" className={classes.sectionMobile}>
                                <MoreIcon />
                            </IconButton>
                        ) : null}
                    </Toolbar>
                </AppBar>

                {/** MOBILE MENU */}
                <Menu
                    anchorEl={mobileMoreAnchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={isMobileMenuOpen}
                    onClose={handleMenuClose}
                >
                    <MenuItem disabled selected className={classes.menuInfoItem}>
                        <Typography>
                            {typeof activeCustomer === "object" && activeCustomer !== null && Object.keys(activeCustomer).length
                                ? `${activeCustomer ? activeCustomer.Name : ""} ${user ? ` / ${user.Username}` : ""}`
                                : `${user ? `${user.Username}` : ""}`}
                        </Typography>
                    </MenuItem>
                    {appBarOptions.myProfile ? (
                        <NavLink className={classes.navLink} to="/myProfile" key={"myProfile"}>
                            <MenuItem>{translations ? translations["my_profile"] || "My profile" : "My profile"}</MenuItem>
                        </NavLink>
                    ) : null}
                    {typeof activeCustomer === "object" && activeCustomer !== null ? (
                        <NavLink className={classes.navLink} to="/changeActiveCustomer" key={"changeActiveCustomer"}>
                            <MenuItem>{translations ? translations["change_active_customer"] || "Change active customer" : "Change active customer"}</MenuItem>
                        </NavLink>
                    ) : null}
                    <MenuItem onClick={props.handleLogout}>{translations ? translations["logout"] || "Logout" : "Logout"}</MenuItem>
                </Menu>
                {/** MENU */}
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        onMouseLeave: handleMenuClose,
                    }}
                >
                    {appBarOptions.myProfile ? (
                        <NavLink className={classes.navLink} to="/myProfile" key={"myProfile"}>
                            <MenuItem>{translations ? translations["my_profile"] || "My profile" : "My profile"}</MenuItem>
                        </NavLink>
                    ) : null}
                    {!appBarOptions.disableActiveCustomer && typeof activeCustomer === "object" && activeCustomer !== null ? (
                        <NavLink className={classes.navLink} to="/changeActiveCustomer" key={"changeActiveCustomer"}>
                            <MenuItem>{translations ? translations["change_active_customer"] || "Change active customer" : "Change active customer"}</MenuItem>
                        </NavLink>
                    ) : null}
                    {props.supportSessionActive ? (
                        <div>
                            <MenuItem onClick={props.endSupportSession}>End support session</MenuItem>
                            <MenuItem onClick={props.handleLogout}>{translations ? translations["logout"] || "Logout" : "Logout"}</MenuItem>
                        </div>
                    ) : (
                            <MenuItem onClick={props.handleLogout}>{translations ? translations["logout"] || "Logout" : "Logout"}</MenuItem>
                        )}
                </Menu>
                {/** MENU DOWNLOAD */}
                {!appBarOptions.download ? null : (
                    <Menu
                        anchorEl={anchorElDownload}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                        open={isMenuDownloadOpen}
                        onClose={handleDownloadClose}
                        MenuListProps={{
                            onMouseLeave: handleDownloadClose,
                        }}
                    >
                        {listDownloading(props.downloadData ? props.downloadData.currentDownloadData : {})}
                    </Menu>
                )}
                {/* Render children components if there is any*/}
                {children}
            </div>
            <Notifier />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        permissions: state && state.core && state.core.login ? state.core.login.permissions : [],
        user: state && state.core && state.core.login ? state.core.login.user : null,
        activeCustomer: state && state.core && state.core.login ? state.core.login.activeCustomer : null,
        downloadData: state.download && state.download.download ? state.download.download : {},
    };
};

export default connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(Dashboard));
