import { parseURL } from "../../np-utilities";

const getQueryFromObject = obj => {
    let newQuery = '';
    for (var o in obj) {
        if (!obj[o]) {
            continue;
        }
        if (newQuery.length === 0) {
            newQuery += `?${o}=${obj[o]}`;
            continue;
        }
        newQuery += `&${o}=${obj[o]}`;
    }
    return newQuery;
}
export const createOrAppendUrl = async obj => {
    let url = parseURL(window.location.href);
    if (!url) {
        url = {};
    }
    url = { ...url, ...obj }

    let newUrl = window.location.href.split('?')[0];
    let query = getQueryFromObject(url);
    window.history.pushState({ path: `${newUrl}?${query}` }, '', `${newUrl}${query}`);
}