import restCrudReducer from '../../../np-crud/store/restCrudReducer';

const [crudInitialState, crudReducer] = restCrudReducer({
    pluginName: 'SITE',
    entityName: 'SITE',
});

const initialState = {
    ...crudInitialState,
    deletedSite: {},
    deleting: false,
    saving: false
};
const reducer = (state = initialState, action) => {
    // Handle CRUD actions
    const newState = crudReducer(state, action);
    // If CRUD reducer handled the action
    if (newState !== null) {
        return newState;
    }

    // Handle other actions
    switch (action.type) {
        case 'CORE|LOGOUT_SUCCESS':
        case 'CORE|CORE_SET_ACTIVE_CUSTOMER_SUCCESS':
            return initialState;
        default:
            return state;
    }
};

export default reducer;
