import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Paper, Tooltip, IconButton } from '@material-ui/core';
import { FindInPage, Delete, Edit, CloudDownload, VerifiedUser, AssignmentReturned } from '@material-ui/icons';
import useListFilter from '../../np-components/Datatable/listFilter';
import NPDatatable from '../../np-components/Datatable';
import { CustomChip } from '../../ms-components';
import { isSuperUser } from '../../np-utilities';
import { parseURL, formatDateWithSite } from "../../ms-utilities";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import moment from 'moment';
import { downloadFile } from '../../np-utilities';

const styles = theme => ({
    applicantActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    applicantInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    applicantAssessment: {
        color: '#ff9800',
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#d50000" : "#1b2a33",
        '&:hover': {
            backgroundColor: "#757575",
        },
    }
});
const updateFilterParams = props => {
    const { listFilter } = props;
    let searchParams = parseURL(window.location.search);
    return { ...listFilter, ...searchParams };
}

const ApplicantsList = props => {
    const { classes, applicants, onFetchApplicantsList, columnsState, activeCustomer, handleDeleteApplicant, handleApplicantEdit, getTrainingReport, activateApplicant, getRawData, onApplicantExport } = props;
    const applicantsCount = applicants.count;
    const applicantsList = applicants.list;
    let tableID = 'ApplicantList';
    let visible = columnsState && columnsState[`${tableID}`];
    const navigate = useNavigate();

    const initialDateRangeValues = {
        From: moment().subtract(1, 'years').set({
            hours: 0,
            minutes: 0,
            seconds: 0,
        }),
        To: moment().set({
            hours: 23,
            minutes: 59,
            seconds: 59,
        }),
    };

    const [dateRangeValue, setDateRangeValue] = useState({ From: null, To: null });

    const [exportRequestPayload, setExportRequestPayload] = useState({});
    
    const [redirectOptions, setRedirectOptions] = useState({ redirect: false, applicantID: null, redirectPath: null });
    const applicantDetailsHandler = event => {
        let applicantID = event.currentTarget.value;
        setRedirectOptions({
            redirect: true,
            applicantID: applicantID,
            redirectPath: `/applicants/details?applicantID=${applicantID}`
        });
        navigate(redirectOptions.redirectPath)
    };

    const columns = [
        {
            displayName: 'Actions',
            backendName: 'Actions',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Actions'] : true,
            }
        },
        {
            displayName: 'First name',
            backendName: 'FirstName',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['FirstName'] : true,
            }
        },
        {
            displayName: 'Last name',
            backendName: 'LastName',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['LastName'] : true,
            }
        },
        {
            displayName: 'Created At',
            backendName: 'CreatedAt',
            options: {
                align: 'left',
                sortable: true,
                searchable: false,
                visible: visible ? visible['CreatedAt'] : true,
            }
        },
        {
            displayName: 'Status',
            backendName: 'Status',
            options: {
                align: 'left',
                sortable: true,
                searchable: false,
                visible: visible ? visible['Status'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => {
        if (column.backendName !== 'Action') backendNames.push(column.backendName);
        return null;
    });
    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });

    const createActions = (applicantID, status) => {
        return <>
            {
                props.hasPermission('applicant_load') ?

                    <Tooltip title="Applicant details">
                        <IconButton value={applicantID} aria-label="Applicant details"
                            onClick={applicantDetailsHandler}>
                            <FindInPage />
                        </IconButton>
                    </Tooltip>
                    : null
            }
            {
                props.hasPermission('applicant_load') ?

                    <Tooltip title="Edit applicant">
                        <IconButton value={applicantID} aria-label="Edit applicant"
                            onClick={handleApplicantEdit}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    : null
            }
            {
                props.hasPermission('applicant_delete') ?

                    <Tooltip title="Delete applicant">
                        <IconButton value={applicantID} aria-label="Delete applicant"
                            onClick={handleDeleteApplicant}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    : null
            }
            {
                props.hasPermission('applicant_generate_training_report') ?

                    <Tooltip title="Download training report">
                        <IconButton value={applicantID} aria-label="Download training report"
                            onClick={getTrainingReport}>
                            <CloudDownload />
                        </IconButton>
                    </Tooltip>
                    : null
            }
            {
                props.hasPermission('applicant_activate') && status !== 'Active' ?

                    <Tooltip title="Activate applicant">
                        <IconButton value={applicantID} aria-label="Activate applicant"
                            onClick={activateApplicant}>
                            <VerifiedUser />
                        </IconButton>
                    </Tooltip>
                    : null
            }
            {
                props.hasPermission('applicant_get_raw_data') ?

                    <Tooltip title="Download raw data">
                        <IconButton value={applicantID} aria-label="Download raw data"
                            onClick={getRawData}>
                            <AssignmentReturned />
                        </IconButton>
                    </Tooltip>
                    : null
            }
        </>
    };

    useEffect(() => {
        // fetch calls
        if (listFilter.filter) {
            if (!listFilter.filter.q) {
                listFilter.filter.q = {};
            }
            if (!listFilter.filter.q.and) {
                listFilter.filter.q.and = {};
            }
            listFilter.filter.q = { ...listFilter.filter.q, From: dateRangeValue?.From, To: dateRangeValue?.To, and: updateFilterParams({ listFilter: listFilter.filter.q.and }) };

            setExportRequestPayload({ q: listFilter.filter.q, order: listFilter.filter.order });
            onFetchApplicantsList({ ...listFilter.filter, ActiveCustomerID: activeCustomer });
        }
    }, [applicants.created, applicants.active, applicants.deleted, applicants.bulkDeleted, applicants.edited, onFetchApplicantsList, listFilter.filter, activeCustomer, dateRangeValue]);

    if (redirectOptions.redirect) {
        return <Routes><Route path="*" element={<Navigate to={redirectOptions.redirectPath} />} /></Routes>;
    }

    const handleExport = async applicantIDs => {
        let payload = {};

        if (applicantIDs?.length) {
            payload = { applicantIDs }
        } else {
            payload = { ...exportRequestPayload };
        }

        const data = await onApplicantExport({ ...payload, ActiveCustomerID: activeCustomer});
        if (data) {
            return downloadFile(data.blob, data.fileName);
        }
    };

    const tableOptions = {
        isSelectable: true,
        selectOptions: { exporting: true },
        tableID: tableID,
        saveColumn: true,
        page: 0,
        rowsPerPage: 20,
        count: applicantsCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'Applicants',
        noMatch: "Sorry, no matching records found",
        dateRangeFilter: true,
        initialDateRangeValues,
        dateRangeValue,
        setDateRangeValue,
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return props.handleBulkDelete(IDs);
        },
        onExport: IDs => {
            return handleExport(IDs);
        },
        onSaveFilter: (columns) => {
            let objToSendColumn = {};
            let objColumns = {};
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible;
                return objColumns
            });
            objToSendColumn[`${tableID}`] = objColumns;
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }

    };

    let counter = 0;
    let applicantsData = applicantsList.map(applicant => {
        let status = applicant.Status;
        switch (status) {
            case 'active':
                status = 'Active';
                break;
            case 'inactive':
                status = 'Inactive';
                break;
            case 'assessment':
                status = 'Assessment';
                break;
            default: status = 'Inactive';
        }
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = applicant.UserID;
        returnObj.visible = true;
        returnObj.values = [
            createActions(applicant.UserID, status), applicant.FirstName, applicant.LastName,
            formatDateWithSite(applicant.CreatedAt, applicant["Site.Key"]),
            <CustomChip key='applicantChipStatus'
                className={classes[`applicant${status}`]}
                value={status} />
        ];

        counter++;
        return returnObj;
    });

    return (
        <Paper>
            <NPDatatable handleCreate={props.handleCreate} customStyles={classes} data={applicantsData}
                columns={columns} options={tableOptions} />
        </Paper>
    );
};

export default (withStyles(styles)(ApplicantsList));