import React, { useState, useEffect } from 'react';

import Form from '../../np-form/Form';
import { withStyles, Dialog } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});

const updateFormState = props => {
    const { classes, msqtTool, translations } = props;

    const form = {
        ToolName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations["name"] || 'Name',
                label: translations["name"] || 'Name',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: msqtTool ? msqtTool.ToolName : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Status: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Status',
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [
                    { value: 'test', displayValue: translations['test'] || 'Test' },
                    { value: 'release', displayValue: translations['release'] || 'Release' },
                    { value: 'demo', displayValue: translations['demo'] || 'Demo' }]
            },
            formHelperText: translations['image_status'] || "Please select image status",
            value: msqtTool ? msqtTool.Status : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        ForAllCustomers: {
            elementType: 'checkbox',
            elementConfig: {
                type: 'checkbox',
                label: null,
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [{ ID: 'checkboxID', Checked: msqtTool && msqtTool.CustomerID === null ? true : false, Name: translations['for_all_customers'] || 'For all customers' }]
            },
            value: msqtTool && msqtTool.CustomerID === null ? true : false,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        }
    };
    return form;
}

const MSQTToolEdit = props => {
    const { classes, open, msqtTool, activeCustomer, translations } = props;

    const [importForm, setImportForm] = useState(updateFormState({ ...props }));
    const [formIsValid, setFormIsValid] = useState(false);

    useEffect(() => {
        setImportForm(updateFormState({ classes, msqtTool, translations }));
    }, [classes, msqtTool, translations]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, importForm);
        setImportForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    }

    const checkboxChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnCheckboxChange(event, inputIdentifier, importForm);
        setImportForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    }

    const submitHandler = async  event => {
        event.preventDefault();
        const formData = formUtility.getFormData(importForm);
        formData['MSQTToolID'] = msqtTool.MSQTToolID;
        await props.editMSQTTool({ ...formData, ActiveCustomerID: activeCustomer });
        props.handleEdit();
    }

    const cancelHandler = () => {
        props.handleEdit();
    }

    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            disableEnforceFocus={true}
            fullWidth={true}
            // onClose={cancelHandler}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    cancelHandler();
                }
            }}
        >
            <Form
                formIsValid={formIsValid}
                classes={classes}
                form={importForm}
                formTitle={translations['edit_msqt_tool'] || "Edit  msqt tool"}
                buttonText={props.hasPermission('msqt_tool_save') ? translations['save'] || "Save" : false}
                buttonTextCancel={translations['cancel'] || "Cancel"}
                cancelHandler={cancelHandler}
                submitHandler={submitHandler}
                inputChangedHandler={inputChangedHandler}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                checkboxChangedHandler={checkboxChangedHandler}
            />
        </Dialog>
    )
}

export default withStyles(styles, { withTheme: true })(MSQTToolEdit);