import restCrudActions from '../../../np-crud/store/restCrudActions'

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: 'training-modules',
    pluginName: 'TRAINING_MODULES',
    entityName: 'TRAINING_MODULES',
    fetchSuccessMessage: '',
    loadSuccessMessage: '',
});

// Fetch TrainingModule action creators
export const fetchTrainingModules = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
};

// Create TrainingModule  action creators
export const createTrainingModule = TrainingModule => {
    return async dispatch => {
        await crudActions.create(dispatch, TrainingModule);
    };
};

// Edit TrainingModule  action creators
export const editTrainingModule = setting => {
    return async dispatch => {
        await crudActions.edit(dispatch, setting);
    };
};

// Load TrainingModule action creators
export const loadTrainingModule = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

// Delete TrainingModule  action creators
export const bulkDeleteTrainingModule = trainingIDs => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, trainingIDs);
    };
};

// Delete TrainingModule  action creators
export const deleteTrainingModule = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};