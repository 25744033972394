import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ConfirmationDialog from '../../np-components/ConfirmationDialog/ConfirmationDialog';
import moment from 'moment';

var id = null;
var startExtension = false;

const styles = theme => ({
    countdown_container: {
        color: theme.palette.buttonText.default,
        backgroundColor: theme.palette.secondary.main,
        padding: 5,
        paddingRight: 20,
        paddingLeft: 20,
        borderRadius: 20,
        border: '1px solid black',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center'
    },
    text: { fontSize: 15 },
    time: { textAlign: 'center', fontSize: 20 }
});

const CountdownTimer = props => {
    const { classes, counterValue, onCancel, onConfirm, useDialog, remainingSeconds, confirmRemainingSeconds } = props;
    const [confirmDialog, setConfirmDialog] = useState({ open: false, confirmed: false, logout: false });
    const [countdown, setCountdown] = useState(null);

    useEffect(() => {
        if (!counterValue) {
            const handleCountdownTimerSet = expires => {
                let now = moment()
                    .add(5, 'minutes')
                    .format('YYYY-MM-DD HH:mm:ss');
                let countdownValue = moment.utc(moment(expires).diff(moment(now))).format('HH:mm:ss');
                let displayValue = moment.utc(moment(expires).diff(moment(now))).format('mm:ss');
                let secondsLeft = moment.duration(countdownValue).asSeconds();
                //If there is 5 minutes until session expires, open dialog to prompt the user
                if (secondsLeft <= confirmRemainingSeconds) {
                    handleOpenDialog();
                }
                //If time runs out, logout the user
                if (secondsLeft <= 5) {
                    onCancel();
                }
                //Do not display countdown timer over 55 minutes left
                if (!confirmDialog.open) setCountdown(secondsLeft >= remainingSeconds ? null : displayValue);
            };
            const startTimer = time => {
                id = setInterval(function () {
                    if (time) handleCountdownTimerSet(time);
                }, 1000);
            };
            startTimer(
                moment()
                    .add(60, 'minutes')
                    .format('YYYY-MM-DD HH:mm:ss')
            );
        }
        return () => {
            clearInterval(id);
            id = null;
        };
    }, [counterValue, confirmRemainingSeconds, remainingSeconds, onCancel, confirmDialog.open]);

    useEffect(() => {
        if (counterValue) {
            const handleCountdownTimerSet = expires => {
                let now = moment()
                    .add(5, 'minutes')
                    .format('YYYY-MM-DD HH:mm:ss');
                let countdownValue = moment.utc(moment(expires).diff(moment(now))).format('HH:mm:ss');
                let displayValue = moment.utc(moment(expires).diff(moment(now))).format('mm:ss');
                let secondsLeft = moment.duration(countdownValue).asSeconds();

                //If there is 5 minutes until session expires, open dialog to prompt the user
                if (secondsLeft <= confirmRemainingSeconds) {
                    handleOpenDialog();
                    startExtension = true;
                }
                //If time runs out, logout the user
                if (secondsLeft <= 5) {
                    clearInterval(id);
                    id = null;
                    onCancel();
                }

                //Do not display countdown timer over 55 minutes left
                setCountdown(secondsLeft > remainingSeconds ? null : displayValue);
            };
            const startTimer = time => {
                //first always clear interval
                id = null;
                clearInterval(id);
                //start new interval
                id = setInterval(function () {
                    if (time) {
                        handleCountdownTimerSet(time);
                    }
                }, 1000);
            };

            startTimer(counterValue);
        }
    }, [counterValue, confirmRemainingSeconds, remainingSeconds, onCancel, confirmDialog.open]);

    useEffect(() => {
        if (confirmDialog.confirmed) {
            if (!confirmDialog.open && startExtension) {
                startExtension = false;
                clearInterval(id);
                id = null;
                onConfirm('Session extended successfully!');
            }
        } else if (!confirmDialog.confirmed) {
            if (confirmDialog.logout) {
                clearInterval(id);
                id = null;
                onCancel();
            }
        }
    }, [confirmDialog.confirmed, onCancel, confirmDialog.open, onConfirm, confirmDialog.logout]);

    const handleOpenDialog = () => {
        setConfirmDialog({ open: true });
    };

    return (
        <div>
            {countdown && !confirmDialog.open && !startExtension && id ? (
                <div className={classes.countdown_container}>
                    <div className={classes.text}> Session expires in: </div>
                    <div className={classes.time}> {countdown} </div>
                </div>
            ) : null}
            {useDialog ? (
                <ConfirmationDialog
                    isOpen={confirmDialog.open}
                    title={`Time remaining: ${countdown}`}
                    contentText={`Your session is about to finish, do you want to continue?`}
                    onYes={() => setConfirmDialog({ open: false, confirmed: true })}
                    onCancel={() => setConfirmDialog({ open: false, confirmed: false, logout: true })}
                    textCancel="Logout"
                    textYes="Continue"
                />
            ) : null}
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(CountdownTimer);
