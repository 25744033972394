import { useState, useEffect } from 'react';

const UseListFilter = props => {
    // Options - check possible default parameters from the props
    const [options, setOptions] = useState({
        page: props.page ? parseInt(props.page) : 0,
        rowsPerPage: props.rowsPerPage ? parseInt(props.rowsPerPage) : 10,
        filterList: props.filterList ? props.filterList : [],
        searchText: props.searchText ? props.searchText : '',
        sortOrder: props.sortOrder ? props.sortOrder : '',
        sortColumn: props.sortColumn ? props.sortColumn : '',
        columnsSearch: props.columnsSearch ? props.columnsSearch : false,
        searchColumns: props.searchColumns ? props.searchColumns : [],
        lazyLoading: props.lazyLoading ? props.lazyLoading : false,
    });
    // Filter for backend calls
    const [filter, setFilter] = useState(null);

    // If there were changes in the options, the filter will be updated
    useEffect(() => {
        // Update the filter
        const updateFilter = () => {
            let filter = {
                limit: options.rowsPerPage,
                offset: options.rowsPerPage * options.page,
                order: [],
                q: null
            };

            if (options.lazyLoading) {
                filter.limit = options.rowsPerPage;
                filter.offset = props.tableLength;
            }


            // Set order
            if (options.sortOrder !== '' && options.sortColumn !== '') {
                filter.order = [{ field: options.sortColumn, direction: options.sortOrder }];
            }

            // Set query for table search
            if (options.searchText && options.searchText !== '') {
                const or = [];
                options.searchColumns.forEach(searchColumn => {
                    var orItem = {};
                    orItem[searchColumn] = { like: '%' + options.searchText + '%' }
                    or.push(orItem);
                });

                if (!filter.q) {
                    filter.q = {};
                }

                filter.q.or = or;
            }

            // Set query for column search
            if (options.columnsSearch) {
                if (!filter.q) {
                    filter.q = {};
                }
                filter.q.and = options.columnsSearch;
            }

            setFilter(filter);
        }
        // Update with some delay. This helps to avoid to much change events e.g. during typing a search text
        updateFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    const changePage = page => {
        setOptions(
            {
                ...options,
                page: page
            }
        );
    };

    const loadMoreButton = page => {
        setOptions(
            {
                ...options,
                rowsPerPage: page.data,
                tableData: page.tableData,
                lazyLoading: true
            }
        );
    };

    const loadMore = page => {

        setOptions(
            {
                ...options,
                page: page,
                lazyLoading: true

            }
        );
    };

    const changeRowsPerPage = rowsPerPage => {
        setOptions({
            ...options,
            page: 0,
            rowsPerPage: rowsPerPage
        });
    };

    const handleFilter = filterList => {
        setOptions({
            ...options,
            page: 0,
            filterList: filterList
        });
    };

    const changeSearchText = searchText => {
        setOptions({
            ...options,
            page: 0,
            searchText: searchText
        });
    };

    const changeColumnSearch = columnsSearch => {
        setOptions({
            ...options,
            page: 0,
            columnsSearch: columnsSearch
        })
    };

    const handleSort = (column, order) => {
        setOptions({
            ...options,
            page: 0,
            sortColumn: column,
            sortOrder: order === 'ascending' ? 'asc' : 'desc'
        });
    };

    const onTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page);
                break;
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage);
                break;
            case 'filterChange':
                handleFilter(tableState.filterList);
                break;
            case 'search':
                changeSearchText(tableState);
                break;
            case 'columnSearch':
                changeColumnSearch(tableState.searchColumn);
                break;
            case 'sortColumn':
                handleSort(tableState.column, tableState.order);
                break;
            case 'lazyLoad':
                loadMore(tableState.page);
                break;
            case 'lazyLoadButton':
                loadMoreButton(tableState);
                break;
            default:
                console.log(`Event (${action}) is not supported`);
                break;
        }
    }

    return {
        options: options,
        filter: filter,
        onTableChange: onTableChange,
        changePage: changePage,
        changeRowsPerPage: changeRowsPerPage,
        handleFilter: handleFilter,
        changeSearchText: changeSearchText,
        changeColumnSearch: changeColumnSearch,
        handleSort: handleSort
    }
}

export default UseListFilter;