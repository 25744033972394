import restCrudActions from '../../../np-crud/store/restCrudActions'

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: 'modules',
    pluginName: 'MODULE',
    entityName: 'MODULE',
    fetchSuccessMessage: '',
    loadSuccessMessage: '',
});

// Fetch Module action creators
export const fetchModules = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
};

// Create Module  action creators
export const createModule = Module => {
    return async dispatch => {
        await crudActions.create(dispatch, Module);
    };
};

// Edit Module  action creators
export const editModule = setting => {
    return async dispatch => {
        await crudActions.edit(dispatch, setting);
    };
};

// Load Module action creators
export const loadModule = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

// Delete Module  action creators
export const bulkDeleteModule = moduleIDs => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, moduleIDs);
    };
};

// Delete Module  action creators
export const deleteModule = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};