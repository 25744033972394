import restCrudReducer from '../../../np-crud/store/restCrudReducer';

const [crudInitialState, crudReducer] = restCrudReducer({
    pluginName: 'PROBLEM|REPORT',
    entityName: 'PROBLEM|REPORT'
});

const initialState = {
    ...crudInitialState,
    deletedSite: {},
    deleting: false,
    saving: false,
    downloadProgress: 0,
    progress: null,
    currentDownloadData: {},
    snackBar: false

};
const updateDownloadProgress = (state, action) => {
    return { ...state, snackBar: action.snackBar, downloadProgress: action.downloadProgress, currentDownloadData: { ...state.currentDownloadData, [action.data.ImageID]: { downloadProgress: action.downloadProgress, currentSizeDownload: action.currentSize, maxSizeDownload: action.maxSize, name: action.name } } };
};

const reducer = (state = initialState, action) => {
    // Handle CRUD actions
    const newState = crudReducer(state, action);
    // If CRUD reducer handled the action
    if (newState !== null) {
        return newState;
    }

    // Handle other actions
    switch (action.type) {
        case 'DOWNLOAD|DOWNLOAD_PROGRESS':
            return updateDownloadProgress(state, action);
        case 'CORE|LOGOUT_SUCCESS':
        case 'CORE|CORE_SET_ACTIVE_CUSTOMER_SUCCESS':
        default:
            return state;
    }
};

export default reducer;
