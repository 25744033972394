import React, { useState, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import NPDatatable from '../../np-components/Datatable';
import { Paper, Tooltip, IconButton } from '@material-ui/core';
import useListFilter from '../../np-components/Datatable/listFilter';
import { CustomChip } from '../../ms-components';
import { isSuperUser } from '../../np-utilities';
import { FindInPage } from '@material-ui/icons';

const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#d50000" : "#1b2a33",
        '&:hover': {
            backgroundColor: "#757575",
        },
    },
    chipCustomerActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    chipCustomerInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    }
});

const CustomerList = props => {
    const { classes, customers, onFetchCustomers, columnsState, activeCustomer } = props;
    const customersCount = customers.count;
    const customersList = customers.list;
    const navigate = useNavigate();
    const [redirectOptions, setRedirectOptions] = useState({ redirect: false, customerID: 0, redirectPath: '' });
    let tableID = 'Customer';
    let visible = columnsState && columnsState[tableID];

    const customerHandler = event => {
        let customerID = event.currentTarget.value;
        setRedirectOptions({ redirect: true, customerID: customerID, redirectPath: `/customers/details?customerID=${customerID}` });
        navigate(redirectOptions.redirectPath);
    };

    const columns = [
        {
            displayName: 'Action',
            backendName: 'Action',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: 'Name',
            backendName: 'Name',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Name'] : true,
            }
        },
        {
            displayName: 'Status',
            backendName: 'Status',
            options: {
                align: 'left',
                sortable: true,
                visible: visible ? visible['Status'] : true,
            }
        },
        {
            displayName: 'Password complexity',
            backendName: 'PasswordComplexity',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['PasswordComplexity'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => { if (column.backendName !== 'Action') backendNames.push(column.backendName); return null; })
    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });
    if (!props.hasPermission('customer_load')) {
        columns.splice(0, 1);
    }

    const createActions = customerID => {
        return <>
            {
                props.hasPermission('customer_load') ?
                    <Tooltip title="Customer details">
                        <IconButton value={customerID} aria-label="Customer details" onClick={customerHandler}>
                            <FindInPage />
                        </IconButton>
                    </Tooltip>
                    : null
            }
        </>
    };
    // Fetch Calls if the filter was changed or after delete
    useEffect(() => {
        // fetch calls
        if (listFilter.filter) {
            onFetchCustomers({ ...listFilter.filter, ActiveCustomerID: activeCustomer });
        }
    }, [listFilter.filter, customers.created, customers.deleted, activeCustomer, customers.edited, onFetchCustomers]);

    if (redirectOptions.redirect) {
        return <Routes><Route path="*" element={<Navigate to={redirectOptions.redirectPath} />} /></Routes>;
    }

    const tableOptions = {
        isSelectable: false,
        tableSearch: false,
        tableID: 'Customer',
        saveColumn: true,
        selectOptions: { printing: false, generatingReport: false, deleting: false, creating: props.hasPermission('customer_create') ? true : false },
        rowsPerPage: 20,
        page: 0,
        count: customersCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'Customers',
        noMatch: "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onSaveFilter: (columns) => {
            let objToSendColumn = {}
            let objColumns = {}
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible
                return objColumns
            })
            objToSendColumn[`${tableID}`] = objColumns
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }
    };



    let counter = 0;
    let customerData = customersList.map(customer => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = customer.CustomerID;
        returnObj.visible = true;
        returnObj.values = [createActions(customer.CustomerID), customer.Name, (<CustomChip customChipKey={customer.CustomerID} className={classes[`chipCustomer${customer.Status === 1 ? 'Active' : 'Inactive'}`]} value={customer.Status === 1 ? 'Active' : 'Inactive'} />), customer.PasswordComplexity];
        if (!props.hasPermission('customer_load')) {
            returnObj.values.splice(0, 1);
        }

        counter++;
        return returnObj;
    });

    return (
        <Paper>
            <NPDatatable customStyles={classes} handleCreate={() => setRedirectOptions({ redirect: true, customerID: null, redirectPath: `/customers/create` })} data={customerData} columns={columns} options={tableOptions} />
        </Paper>
    );
}

export default (withStyles(styles)(CustomerList));