import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {combineReducers} from 'redux';

// Components
import BillingAccount from './BillingAccount';
import BillingAccountOverview from './BillingAccount/Details';
import BillingPlan from './BillingPlan';

// Reducers
import billingAccountReducer from './BillingAccount/store/billingAccountReducer';
import billingPlanReducer from './BillingPlan/store/billingPlanReducer';

// Routes
export const routes = (basePath, permissions) =>
    <Routes>
        {permissions.includes('*') ? <Route path={basePath + '/accounts'} element={<BillingAccount/>}/> : null}
        {permissions.includes('account_overview') || permissions.includes('*') ?
            <Route path={basePath + '/account-overview'} element={<BillingAccountOverview/>}/> : null}
        {permissions.includes('*') ? <Route path={basePath + '/plans'} element={<BillingPlan/>}/> : null}
    </Routes>;


export const subComponents = [
    {name: 'Account', path: '/accounts', permissions: ['billing_account']},
    {name: 'Plan', path: '/plans', permissions: ['billing_plan']}
];

export const reducers = combineReducers({
    accounts: billingAccountReducer,
    plans: billingPlanReducer
});