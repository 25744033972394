import React from "react";
import { connect } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import * as actions from "../../np-core/Auth/store/authActions";
import LoginForm from "./LoginForm";
import  * as Yup from "yup";

const styles = theme => ({
    main: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: 400,
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
    },
    logo: {
        width: "250px",
        margin: "10px"
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing()
    },
    submit: {
        marginTop: theme.spacing(3)
    },
    error: {
        border: "1px solid red"
    }
});

const Login = props => {
    const { classes, onLogin, reload, translations, socket } = props;
    const initialValues= {
           Username: "",
           Password:"",
           language:"en"
    };
    const formSettings={
        Validation:{
            Username: Yup.string().required("Required"),
            Password: Yup.string().required("Required"),
            language: Yup.string().required("required")
        },
        ButtonSubmit:{
           Title: translations["login"] || "LOGIN",
           Color: "primary"  
        },
    };
    const appTranslations = [
        {code:"en", displayName:"English"},
        {code:"de",displayName:"German"}
    ];
    const handleSubmit = formData => {
        formData['ClientType']="web_ui";
        formData['ClientVersion']=1.99;

        onLogin(formData, reload);
    };

    const handleLanguageChange = e => {
       e.preventDefault();
       if (socket) {
            //loginFormSaved = { ...formState.loginForm };
           socket.emit("getTranslations", { LanguageKey: e.target.value });
       }
        localStorage.setItem("LANGUAGE", e.target.value);
    };

    return (
        <main className={classes.main}>
            <CssBaseline />
            <Paper className={classes.paper}>
                <img
                    alt="App logo"
                    className={classes.logo}
                    src={props.logoPath ? process.env.PUBLIC_URL + props.logoPath : process.env.PUBLIC_URL + "/assets/images/app-logo.png"}
                />
                <Typography component="h1" variant="h6">
                    {translations["login"] || "Login"}
                </Typography>

                <div className={classes.form}>
                    <LoginForm
                     initialValues={initialValues}
                     formSettings={formSettings}
                     translations={translations}
                     appTranslations={appTranslations}
                     handleSubmit={handleSubmit}
                     handleLanguageChange={handleLanguageChange}
                    />
                </div>
              
            </Paper>
        </main>
    );
};

const mapStateToProps = state => {
    return {
        translations: state.translate ? state.translate.translations.activeTranslations || {} : {}
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLogin: (loginData, reload) => dispatch(actions.login(loginData, reload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));