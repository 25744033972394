import React, { useState, useEffect, Suspense } from 'react';
import { Tabs, Tab, Paper, withStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { parseURL, BackButton } from '../../../ms-utilities';
import * as actions from '../store/userActions';
import { enqueueSnackbar } from '../../../np-dashboard/Notifier/store/notifierActions';
import { updateURL } from '../../../np-utilities';

const RoleForCustomer = React.lazy(async () => await import('./RoleForCustomer'));
const RoleExcluded = React.lazy(async () => await import('./RoleExcluded'));
const UserEdit = React.lazy(async () => await import('./UserEdit'));
const ChoseCustomers = React.lazy(async () => await import('./ChoseCustomers'));

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        paddingRight: theme.spacing(6),
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            paddingRight: 0,
        },
    },
});

const TabContainer = props => {
    return (
        <Typography component="div" dir={props.dir} style={{ padding: 3 * 3 }}>
            {props.children}
        </Typography>
    );
};
const getTabValue = () => {
    let urlArray = window.location.href.split('#');
    return urlArray.length > 1 ? Number(urlArray[1]) : 0;
};

const UserComponents = props => {
    const { classes, theme, activeCustomer, translations, onLoadUser, users, onFetchCustomers, saveCustomers, isSuperUser } = props;
    let searchParams = parseURL(window.location.search);
    const [tabValue, setTabValue] = useState(getTabValue());
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState(false);

    const customersSelect = users && users.loaded && users.loaded.Customers ? users.loaded.Customers.map(id => id.CustomerID) : [];

    useEffect(() => {
        const fetchData = async () => {
            await onLoadUser({ UserID: searchParams.userID, ActiveCustomerID: activeCustomer });
            const customers = await onFetchCustomers({ ActiveCustomerID: activeCustomer });
            setCustomers(customers)
        }
        fetchData();
        return () => { };
    }, [activeCustomer, searchParams.userID, onLoadUser, onFetchCustomers, users.edited, customer]);

    const handleChange = (event, value) => {
        if (value === 2 && customersSelect.length === 0) {
            updateURL(1);
            setTabValue(1);
        }
        else {
            updateURL(value);
            setTabValue(value);
        }
    };
    const handleChangeIndex = index => {
        if (index === 2 && customersSelect.length === 0) {
            updateURL(1);
            setTabValue(1);
        }
        else {
            updateURL(index);
            setTabValue(index);
        }
    };
    return (
        <Paper className={classes.root}>
            {BackButton(translations)}
            {!isSuperUser ? <Tabs
                value={tabValue}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                {props.hasPermission('user_load') ? <Tab label={translations["user"] || "User "} /> : null}
                {props.hasPermission('user_load') ? <Tab label={translations["access_control"] || "Access control"} /> : null}
            </Tabs>
                :
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    {props.hasPermission('user_load') ? <Tab label={translations["user"] || "User "} /> : null}
                    {props.hasPermission('user_load') ? <Tab label={translations["customers"] || "Customers "} /> : null}

                    {props.hasPermission('user_load') ? <Tab label={translations["access_control"] || "Access control"} /> : null}
                </Tabs>
            }
            {!isSuperUser ?
                <SwipeableViews
                    index={tabValue}
                    onChangeIndex={handleChangeIndex}
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                >
                    {props.hasPermission('user_load') ? <TabContainer dir={theme.direction}><Suspense fallback={<div><CircularProgress /></div>}><UserEdit onEditUser={props.onEditUser} translations={translations} user={users.loaded} activeCustomer={activeCustomer} hasPermission={props.hasPermission} /></Suspense></TabContainer> : null}
                    {props.hasPermission('user_load') ? <TabContainer dir={theme.direction}><Suspense fallback={<div><CircularProgress /></div>}><RoleExcluded updatePermission={props.updatePermission} tabValue={tabValue} roleID={users.loaded} onLoadRoles={props.onLoadRoles} onEditUser={props.onEditUser} translations={translations} activeCustomer={activeCustomer} fetchPermission={props.fetchPermission} /></Suspense></TabContainer> : null}
                </SwipeableViews>
                :
                <SwipeableViews
                    index={tabValue}
                    onChangeIndex={handleChangeIndex}
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                >
                    {props.hasPermission('user_load') ? <TabContainer dir={theme.direction}><Suspense fallback={<div><CircularProgress /></div>}><UserEdit onEditUser={props.onEditUser} translations={translations} user={users.loaded} activeCustomer={activeCustomer} hasPermission={props.hasPermission} /></Suspense></TabContainer> : null}
                    {props.hasPermission('user_load') ? <TabContainer dir={theme.direction}><Suspense fallback={<div><CircularProgress /></div>}><ChoseCustomers setCustomer={setCustomer} customer={customer} UserID={searchParams.userID} saveCustomers={saveCustomers} setCustomers={setCustomers} customers={customers} setTabValue={setTabValue} updateURL={updateURL} onFetchCustomers={onFetchCustomers} tabValue={tabValue} translations={translations} user={users.loaded} activeCustomer={activeCustomer} /></Suspense></TabContainer> : null}
                    {props.hasPermission('user_load') ? <TabContainer dir={theme.direction}><Suspense fallback={<div><CircularProgress /></div>}><RoleForCustomer customers={customers} updatePermission={props.updatePermission} tabValue={tabValue} roleID={users.loaded} onLoadRoles={props.onLoadRoles} onEditUser={props.onEditUser} translations={translations} user={users.loaded} activeCustomer={activeCustomer} fetchPermission={props.fetchPermission} /></Suspense></TabContainer> : null}

                </SwipeableViews>
            }
        </Paper>
    );
}
const mapStateToProps = state => {
    return {
        users: state.user.users,
        isSuperUser: state.core.login.permissions.includes('*'),
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        translations: state.translate && state.translate.translations ? state.translate.translations.activeTranslations || {} : {},
        hasPermission: permission => { return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'); },
    };
};


const mapDispatchToProps = dispatch => {
    return {
        saveCustomers: data => dispatch(actions.saveCustomers(data)),
        updatePermission: data => dispatch(actions.updatePermission(data)),
        onLoadRoles: user => dispatch(actions.loadRoles(user)),
        fetchPermission: user => dispatch(actions.fetchPermission(user)),
        onFetchCustomers: (id) => dispatch(actions.fetchCustomers(id)),
        onEditUser: user => dispatch(actions.editUser(user)),
        onLoadUser: user => dispatch(actions.loadUser(user)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(UserComponents));