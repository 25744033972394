export const getFormData = myForm => {
    const formData = {};
    for (var formElementIdentifier in myForm) {
        formData[formElementIdentifier] = myForm[formElementIdentifier].value;
    }
    return formData;
};

export const getNewStateOnInputChange = (event, inputIdentifier, myForm) => {
    const updatedFormElement = {
        ...myForm[inputIdentifier],
        value: event.target.value,
        valid: checkValidity(event.target.value, myForm[inputIdentifier].validation),
        touched: true
    };
    const updatedMyForm = {
        ...myForm,
        [inputIdentifier]: updatedFormElement
    };

    let formIsValid = true;
    for (var i in updatedMyForm) {
        formIsValid = updatedMyForm[i].valid && formIsValid;
    }

    return { myForm: updatedMyForm, formIsValid: formIsValid };
};
export const getNewStateOnAutoCompleteChange = (value, inputIdentifier, myForm) => {
    const updatedFormElement = {
        ...myForm[inputIdentifier],
        value: value,
        valid: checkValidity(value, myForm[inputIdentifier].validation),
        touched: true
    };
    const updatedMyForm = {
        ...myForm,
        [inputIdentifier]: updatedFormElement
    };

    let formIsValid = true;
    for (var i in updatedMyForm) {
        formIsValid = updatedMyForm[i].valid && formIsValid;
    }

    return { myForm: updatedMyForm, formIsValid: formIsValid };
};

export const getNewStateOnCheckboxChange = (event, inputIdentifier, myForm) => {
    const name = event.target.name;
    const value = event.target.checked;

    let elements = { ...myForm[inputIdentifier] };

    let newOptions = [];

    for (var is of elements.elementConfig.options) {
        if (is.Name === name) {
            is.Checked = value;
        }
        newOptions.push(is);
    }

    const updatedFormElement = {
        ...myForm[inputIdentifier],
        value: value,
        elementConfig: {
            options: newOptions,
            label: elements.elementConfig.label
        },
        valid: true,
        touched: true
    };
    const updatedMyForm = {
        ...myForm,
        [inputIdentifier]: updatedFormElement
    };

    let formIsValid = true;
    for (var i in updatedMyForm) {
        formIsValid = updatedMyForm[i].valid === true ? true : false && formIsValid;
    }

    return { myForm: updatedMyForm, formIsValid: formIsValid };
};

export const getNewStateOnMultiSelectChange = (event, inputIdentifier, myForm) => {
    const updatedFormElement = {
        ...myForm[inputIdentifier],
        value: event,
        valid: true,
        touched: true
    };

    const updatedMyForm = {
        ...myForm,
        [inputIdentifier]: updatedFormElement
    };
    let formIsValid = true;
    for (var i in updatedMyForm) {
        formIsValid = updatedMyForm[i].valid && formIsValid;
    }

    return { myForm: updatedMyForm, formIsValid: formIsValid };
};

export const getNewStateOnEditorChange = (event, inputIdentifier, myForm) => {
    const updatedFormElement = {
        ...myForm[inputIdentifier],
        value: event,
        valid: true,
        touched: true
    };
    const updatedMyForm = {
        ...myForm,
        [inputIdentifier]: updatedFormElement
    };

    let formIsValid = true;
    for (var i in updatedMyForm) {
        formIsValid = updatedMyForm[i].valid && formIsValid;
    }

    return { myForm: updatedMyForm, formIsValid: formIsValid };
};

export const getNewStateOnDateChange = (event, inputIdentifier, myForm) => {
    const updatedFormElement = {
        ...myForm[inputIdentifier],
        value: event ? new Date(event) : null,
        valid: true,
        touched: true
    };

    const updatedMyForm = {
        ...myForm,
        [inputIdentifier]: updatedFormElement
    };

    let formIsValid = true;
    for (var i in updatedMyForm) {
        formIsValid = updatedMyForm[i].valid && formIsValid;
    }

    return { myForm: updatedMyForm, formIsValid: formIsValid };
}

export const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return true;
    }

    if (rules.required) {
        value = value ? value.toString() : '';
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid;
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid;

        if (isDefined(rules.min)) {
            isValid = rules.min <= Number(value) && isValid;
        }

        if (isDefined(rules.max)) {
            isValid = rules.max >= Number(value) && isValid;
        }
    }

    return isValid;
};

const isDefined = (field) => {
    return field !== null && field !== undefined;
};