import React from 'react';
import Button from '@material-ui/core/Button';

import Input from './Input';

import { withStyles } from '@material-ui/core';

import './Form.scss';

const styles = theme => ({
    root: {
        flexGrow: 1,
        paddingTop: theme.spacing(2)
    },
    validMessage: {
        color: 'red'
    },
    form: {},
    button: {},
    date: {},
    submit: {
        width: "120px",
        marginTop: "10px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.buttonText.default,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
    cancel: {
        width: "120px",
        marginTop: "10px",
        marginRight: "10px",
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.buttonText.default,

        }
    },
});

const Form = props => {
    const { classes } = props;
    const formElementsArray = [];
    for (var key in props.form) {
        formElementsArray.push({
            id: key,
            config: props.form[key]
        });
    }
    const [showPassword, setShowPassword] = React.useState( false);


    const handleClickShowPassword = (key) => {
        setShowPassword(!showPassword);
      };
      
      
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

    return (
        <div className={classes.root}>
            <form className={classes.form}>
                {props.formTitle ? <h3> {props.formTitle} </h3> : null}
                <span className={classes.validMessage}>{props.validationMessage}</span>
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        changed={event => props.inputChangedHandler(event, formElement.id)}
                        checkboxChanged={event => props.checkboxChangedHandler(event, formElement.id)}
                        dateChanged={event => props.dateChangedHandler(event, formElement.id)}
                        autoChanged={event => props.autoChangedHandler(event, formElement.id)}
                        label={formElement.config.elementConfig.label}
                        getData={props.getData}
                        getMultiSelectData={(ids, data) => props.getMultiSelectData(ids, data, formElement.id)}
                        getDateRange={props.getDateRange}
                        handleClickShowPassword={handleClickShowPassword}
                        handleMouseDownPassword={handleMouseDownPassword}
                        showPassword={showPassword}
                        formHelperText={formElement.config.formHelperText}
                        formHelperTextBottom={formElement.config.formHelperTextBottom}
                        getImageEditorValue={props.getImageEditorValue}
                        inputLabel={formElement.config.inputLabel}
                        rightButton={formElement.config.rightButton}
                        onClick={formElement.config.onClick}
                        text={formElement.config.text}
                        disabled={formElement.config.disabled}
                        style={formElement.config.style}
                        custom={props.custom}
                        editorToolbarHidden={formElement.config.elementConfig.editorToolbarHidden}
                        editorToolbarHiddenTextarea={formElement.config.elementConfig.editorToolbarHiddenTextarea}
                        toolbarCustom={formElement.config.elementConfig.toolbarCustom}
                    />
                ))}
                <div style={{ textAlign: 'right', ...props.buttonsStyle }}>
                    {props.buttonTextCancel ? (
                        <Button
                            style={
                                props.cancelButtonStyle
                                    ? { marginTop: '10px', ...props.cancelButtonStyle }
                                    : {
                                        width: '120px',
                                        marginTop: '10px',
                                        marginRight: '10px'
                                    }
                            }
                            className={classes.cancel}
                            onClick={props.cancelHandler}
                            variant="contained"
                            disabled={props.cancelButtonDisabled}
                            color={props.cancelButtonColor || 'default'}
                        >
                            {props.buttonTextCancel}
                        </Button>
                    ) : null}
                    {props.buttonText ? (
                        <Button
                            type="submit"
                            className={classes.submit}
                            style={
                                props.submitButtonStyle
                                    ? {
                                        marginTop: '10px',
                                        width: '120px',
                                        ...props.submitButtonStyle
                                    }
                                    : {
                                        marginTop: '10px',
                                        width: '120px'
                                    }
                            }
                            onClick={props.submitHandler}
                            disabled={!props.formIsValid || props.submitButtonDisabled}
                            variant="contained"
                            color={'primary'}
                        >
                            {props.buttonText}
                        </Button>
                    ) : null}
                </div>
            </form>
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(Form);
