import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import { withStyles, FormControl, InputLabel, Select, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, MenuItem } from '@material-ui/core';
import MultiSelect from '../../../../np-components/MultiSelect/MultiSelect';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment'
import { Navigate, Route, Routes } from 'react-router-dom';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },
    formControl: {
        width: '100%'
    }
});

const UserPermissionAccessCreate = props => {
    const {
        classes,
        onLoadRoles, translations,
        activeCustomer, fetchPermission, activeStep, userFormValue, createUser, setActiveStep
    } = props;

    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState(1);
    const [permissions, setPermissions] = useState([]);
    const [allPermissionIncludeSelected, setAllPermissionIncludeSelected] = useState([]);
    const [allPermissionIncluded, setAllPermissionIncluded] = useState([]);
    const [selectedExcludedPermissions, setSelectedExcludedPermissions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (activeStep === 1) {
                let roleData = await onLoadRoles({ ActiveCustomerID: activeCustomer });
                setRoles(roleData);
                setRole(roleData.length > 0 ? roleData[0].value : 1)
            }
        }
        fetchData();
        return () => {
        };
    }, [activeCustomer, onLoadRoles, activeStep]);

    useEffect(() => {
        const fetchData = async () => {
            if (activeStep === 1) {
                let permissionDataAll = await fetchPermission({ ActiveCustomerID: activeCustomer });
                let permissionDataRole = await fetchPermission({ ActiveCustomerID: activeCustomer, RoleID: role });
                let yFilter = permissionDataRole.map(itemY => { return itemY.value; });
                let filteredX = permissionDataAll.filter(itemX => !yFilter.includes(itemX.value));
                setPermissions(permissionDataRole);
                setAllPermissionIncluded(filteredX)
                setAllPermissionIncludeSelected([])
                setSelectedExcludedPermissions([])
            }
        }
        fetchData();
        return () => {
        };
    }, [activeCustomer, fetchPermission, role, activeStep]);

    const handleChange = (event) => {
        setRole(event.target.value)
    };
    const [redirectOptions, setRedirectOptions] = useState({ redirect: false, productID: 0, redirectPath: '' });
    const handleSubmit = (event) => {
        event.preventDefault();
        userFormValue.Status = userFormValue.Status === 'active' ? 1 : 0;
        userFormValue.PasswordExpires = userFormValue.PasswordExpires ? moment().utc() : null;
        delete userFormValue.RepeatPassword;
        createUser({
            UserDetails: { ...userFormValue },
            ActiveCustomerID: activeCustomer,
            RoleID: role,
            IncludedPermissions: allPermissionIncludeSelected.map(id => ({ CustomerID: activeCustomer, PermissionID: id, Mode: 'included' })),
            ExcludedPermissions: selectedExcludedPermissions.map(id => ({ CustomerID: activeCustomer, PermissionID: id, Mode: 'excluded' }))
        })
        setAllPermissionIncludeSelected([])
        setSelectedExcludedPermissions([])
        setRedirectOptions({ redirect: true, redirectPath: `/users` });
    };
    const getExcludedPermissionsSelectData = (...params) => {
        setSelectedExcludedPermissions(params[0])
    };

    const getIncludedPermissionsSelectData = (...params) => {
        setAllPermissionIncludeSelected(params[0]);
    };
    if (redirectOptions.redirect) {
        return <Routes><Route path="*" element={<Navigate to={redirectOptions.redirectPath} />} /></Routes>;
    }

    return (
        <div className={classes.root}>
            <FormControl margin="dense" className={classes.formControl}>
                <InputLabel margin="dense">   {translations["role"] || 'Role'}</InputLabel>
                <Select
                    labelId="demo-mutiple-name-label"
                    id="demo-mutiple-name"
                    value={role}
                    fullWidth={true}
                    onChange={(event) => handleChange(event)}
                >
                    {roles.map(rol => {
                        return (
                            <MenuItem key={`roles_${rol.value}`} value={rol.value} >{rol.displayValue}</MenuItem >
                        )
                    })}
                </Select>
                <ExpansionPanel style={{ marginTop: '10px' }}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>   {translations["permission_for_role"] || 'Permission for this role'}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ display: 'block' }}>
                        {permissions.map(permission => {
                            return (
                                <p style={{ display: 'inline-block' }} key={`roles_${permission.label}`}  >
                                    {permission.label}{' | '}
                                </p>
                            )
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </FormControl>
            <MultiSelect
                customLabel={translations["included_permissions"] || "Included permissions"}
                selectOptions={allPermissionIncluded}
                selectedOptions={allPermissionIncludeSelected}
                placeholder={translations["chose_included_permissions"] || 'Choose included permissions'}
                name={'filters'}
                fullWidth={true}
                margin="dense"
                isMulti={true}
                getMultiSelectData={getIncludedPermissionsSelectData}
            />
            <br />
            <MultiSelect
                fullWidth={true}
                margin="dense"
                isMulti={true}
                customLabel={translations["excluded_permissions"] || "Excluded permissions"}
                selectOptions={permissions}
                selectedOptions={selectedExcludedPermissions}
                placeholder={translations["chose_excluded_permissions"] || 'Choose excluded permissions'}
                name={'filters'}
                getMultiSelectData={getExcludedPermissionsSelectData}
            />
            <br />
            <div style={{ textAlign: 'right' }}>
                <Button onClick={() => setActiveStep(0)} style={{ width: '120px', margin: 10, color: 'white' }} variant="contained" >
                    {translations["back"] || 'Back'}
                </Button>
                <Button onClick={handleSubmit} style={{ width: '120px', margin: 10, color: 'white' }} variant="contained" color="primary">
                    {translations["save"] || 'save'}
                </Button>
            </div>
        </div >
    );
};

export default withStyles(styles)(UserPermissionAccessCreate);
