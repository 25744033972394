import React, { useState } from 'react';
import { withStyles, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';

import ProblemReportList from './ProblemReportList';
import * as actions from './store/problemReportActions';
import ProblemReportDelete from './ProblemReportDelete';
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';
import * as action from '../../np-core/Auth/store/authActions';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
    progressBarr: {
        width: '100%',
        height: 5
    }
});

const ProblemReport = props => {
    const { classes, problemReports, activeCustomer } = props;
    const fetching = problemReports.fetching;

    const [deleteModalState, setDeleteModalState] = useState({
        open: false,
        problemReportID: null,
        problemReportIDs: [],
        forBulk: false
    });



    const handleDelete = async event => {
        if (!event) {
            setDeleteModalState({ open: false, problemReportID: null, problemReportIDs: [], forBulk: false });
            return;
        }
        const problemReportID = event.currentTarget.value;
        setDeleteModalState({ ...deleteModalState, open: true, problemReportID: problemReportID });
    };

    const handleBulkDelete = problemReportIDS => {
        setDeleteModalState({ ...deleteModalState, open: true, problemReportIDs: problemReportIDS, forBulk: true });
    };


    return (
        <div className={classes.root}>
            <div className={classes.progressBarr}>
                {fetching ? <LinearProgress color="primary" /> : null}
            </div>
            {props.hasPermission('problem_report_list') ?
                <ProblemReportList activeCustomer={activeCustomer} columnsState={props.columns}
                    saveColumnsFilter={props.saveColumnsFilter}
                    onDownload={props.onDownload}
                    activeExternalServiceAvailable={props.activeExternalServiceAvailable}
                    hasAtLeastOnePermission={props.hasAtLeastOnePermission}
                    hasPermission={props.hasPermission} handleBulkDelete={handleBulkDelete}
                    problemReports={problemReports} onFetchProblemReports={props.onFetchProblemReports}
                    handleDelete={handleDelete}
                /> : null}

            {props.hasPermission('problem_report_delete') ?
                <ProblemReportDelete activeCustomer={activeCustomer} entityName={'Problem report'} handleDelete={handleDelete}
                    deleteModalState={deleteModalState} onDeleteProblemReport={props.onDeleteProblemReport}
                    bulkDeleteProblemReports={props.onBulkDeleteProblemReports} /> : null}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        problemReports: state.problemReports.problemReports,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        hasPermission: permission => {
            return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*');
        },
        hasAtLeastOnePermission: permissions => {
            return state.core.login.permissions.some(permission => permissions.includes(permission)) || state.core.login.permissions.includes('*');
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onDownload: (data, customerID, name) => dispatch(actions.downloadFile(data, customerID, name)),
        onFetchProblemReports: filter => dispatch(actions.fetchProblemReports(filter)),
        onCreateProblemReport: problemReport => dispatch(actions.createProblemReport(problemReport)),
        onFetchProblemReport: problemReport => dispatch(actions.loadProblemReport(problemReport)),
        onEditProblemReport: problemReport => dispatch(actions.editProblemReport(problemReport)),
        onDeleteProblemReport: problemReport => dispatch(actions.deleteProblemReport(problemReport)),
        onBulkDeleteProblemReports: problemReport => dispatch(actions.bulkDeleteProblemReport(problemReport)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ProblemReport));