import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, withStyles } from '@material-ui/core';

const styles = theme => ({
    buttonDanger: {
        color: '#eceff1',
        backgroundColor: '#f44336'
    },
    buttonSafe: {
        color: '#eceff1',
        backgroundColor: '#4caf50'
    }
});
const BinaryImageTypeDelete = props => {
    let { classes, deleteModalState, entityName, activeCustomer, translations } = props;

    const handleCloseSafe = () => {
        props.handleDelete();
    }

    const handleCloseDanger = async () => {
        await props.deleteBinaryImage({ BinaryImageID: deleteModalState.imageID, ActiveCustomerID: activeCustomer });
        props.handleDelete();
    }

    return (
        <Dialog
            open={deleteModalState.open}
            // onClose={handleCloseSafe}
            aria-labelledby="form-dialog-title"
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            fullWidth={true}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleCloseSafe();
                }
            }}
        >
            <DialogTitle id="form-dialog-title">{deleteModalState.forBulk ? translations['bulk_delete'] || 'Bulk delete' : translations['delete'] || 'Delete'} {entityName}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {translations['carefully_deleting_something'] || 'Be careful when deleting something.'}<br />
                    {translations['sure_want_delete'] || `Are you sure you want to delete `} {deleteModalState.forBulk ? translations['those'] || 'these' : translations['this'] || 'this'} {` ${entityName}`}{deleteModalState.forBulk ? `s'` : `'s`} {translations['data'] || 'data?'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseSafe} variant="contained" className={classes.buttonSafe}>
                    {translations['no'] || 'No'}
                </Button>
                <Button onClick={handleCloseDanger} variant="contained" className={classes.buttonDanger}>
                    {translations['yes'] || 'Yes'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withStyles(styles)(BinaryImageTypeDelete);