import React, { useEffect, useState } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import { InputLabel, Select, ExpansionPanel, FormControl, ExpansionPanelSummary, ExpansionPanelDetails, Typography, MenuItem } from '@material-ui/core';
import MultiSelect from '../../../../np-components/MultiSelect/MultiSelect';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';
import { isSuperUser } from '../../../../np-utilities';
import { Navigate, Route, Routes } from 'react-router-dom';

const styles = theme => ({
    userActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    userInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: "#7dc5e2",
        '&:hover': {
            backgroundColor: "#757575",
        },
    }
});

function getSteps(stepsContent) {
    return stepsContent.map(step => step.label)
}
function getStepContent(props) {
    const { translations, getExcludedPermissionsSelectData, getIncludedPermissionsSelectData, label, roles, setDefaultRole, role, permissions, allPermissionIncludeSelected, allPermissionIncluded, selectedExcludedPermissions } = props

    return (<div >
        <FormControl margin="dense" style={{ width: '100%' }}>

            <InputLabel margin="dense">{translations["role"] || 'Role'}</InputLabel>
            <Select
                labelId="demo-mutiple-name-label"
                id="demo-mutiple-name"
                value={role[`${label}`] ? role[`${label}`] : 2}
                fullWidth={true}
                onChange={(event) => setDefaultRole(event.target.value, label)}

            >
                {roles.map(rol => {
                    return (
                        <MenuItem key={`roles_${rol.value}`} value={rol.value} >{rol.displayValue}</MenuItem >
                    )
                })}

            </Select>
        </FormControl>

        <ExpansionPanel style={{ marginTop: '10px' }}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography> {translations["permission_for_role"] || 'Permission for this role'} </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ display: 'block' }}>

                {permissions.map(permission => {
                    return (
                        <p style={{ display: 'inline-block' }} key={`roles_${permission.label}`}  >
                            {permission.label}{' | '}
                        </p>
                    )
                })}
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <MultiSelect
            customLabel={translations["included_permissions"] || "Included permissions"}
            selectOptions={allPermissionIncluded}
            selectedOptions={allPermissionIncludeSelected}
            placeholder={translations["chose_included_permissions"] || 'Choose included permissions'}
            name={'filters'}
            fullWidth={true}
            margin="dense"
            isMulti={true}
            getMultiSelectData={getIncludedPermissionsSelectData}
        />
        <br />
        <MultiSelect
            fullWidth={true}
            margin="dense"
            isMulti={true}
            customLabel={translations["excluded_permissions"] || "Excluded permissions"}
            selectOptions={permissions}
            selectedOptions={selectedExcludedPermissions}
            placeholder={translations["chose_excluded_permissions"] || 'Choose excluded permissions'}
            name={'filters'}
            getMultiSelectData={getExcludedPermissionsSelectData}
        />
    </div>)
}

const RoleForCustomer = props => {
    const { classes, translations, customers, customersSelect, activeCustomer, userFormValue, onLoadRoles, fetchPermission } = props;
    let stepsContent = customers.filter(itemX => customersSelect.includes(itemX.value));
    const [activeStep, setActiveStep] = React.useState(0);
    const [activeLabel, setActiveLabel] = React.useState({ label: '' });
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState(1);
    const [data, setData] = useState({});
    const [permissions, setPermissions] = useState([]);
    const [allPermissionIncludeSelected, setAllPermissionIncludeSelected] = useState([]);
    const [allPermissionIncluded, setAllPermissionIncluded] = useState([]);
    const [selectedExcludedPermissions, setSelectedExcludedPermissions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let roleData = await onLoadRoles({ ActiveCustomerID: activeCustomer });
            setRoles(roleData);
        }
        fetchData();
        return () => {
        };
    }, [activeCustomer, onLoadRoles, activeStep]);

    const setDefaultRole = (event, label) => {
        setActiveLabel({ label: label })
        setRole({ ...role, [label]: event })
    }

    const getExcludedPermissionsSelectData = (...params) => {
        setSelectedExcludedPermissions(params[0])
    };

    const getIncludedPermissionsSelectData = (...params) => {
        setAllPermissionIncludeSelected(params[0]);
    };

    useEffect(() => {
        const fetchData = async () => {
            let permissionDataAll = await fetchPermission({ ActiveCustomerID: activeCustomer });
            let permissionDataRole = await fetchPermission({ ActiveCustomerID: activeCustomer, RoleID: role[activeLabel['label']] ? role[activeLabel['label']] : 1 });
            let yFilter = permissionDataRole.map(itemY => { return itemY.value; });
            let filteredX = permissionDataAll.filter(itemX => !yFilter.includes(itemX.value));
            setPermissions(permissionDataRole);
            setAllPermissionIncluded(filteredX)
            setAllPermissionIncludeSelected([])
            setSelectedExcludedPermissions([])
        }
        fetchData();
        return () => {
        };
    }, [activeCustomer, fetchPermission, role, activeStep, activeLabel]);
    const steps = getSteps(stepsContent);

    function getStepsContent(step, label) {
        switch (step) {
            default:
                return getStepContent({ translations, getExcludedPermissionsSelectData: getExcludedPermissionsSelectData, getIncludedPermissionsSelectData: getIncludedPermissionsSelectData, label: label, roles: roles, setDefaultRole: setDefaultRole, role: role, permissions: permissions, allPermissionIncludeSelected: allPermissionIncludeSelected, allPermissionIncluded: allPermissionIncluded, selectedExcludedPermissions: selectedExcludedPermissions });
        }
    }

    const handleNext = (label) => {
        let stepsContent = customers.filter(itemX => label.includes(itemX.label));
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setData({
            ...data,
            [label]: {
                RoleID: { CustomerID: stepsContent[0].value, RoleID: role[activeLabel['label']] ? role[activeLabel['label']] : 1 },
                IncludePermission: allPermissionIncludeSelected.map(id => ({ CustomerID: stepsContent[0].value, PermissionID: id, Mode: 'included' })),
                ExcludedPermissions: selectedExcludedPermissions.map(id => ({ CustomerID: stepsContent[0].value, PermissionID: id, Mode: 'excluded' }))
            }
        })
        setAllPermissionIncludeSelected([])
        setSelectedExcludedPermissions([])
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
        setAllPermissionIncludeSelected([])
        setSelectedExcludedPermissions([])
    };
    const [redirectOptions, setRedirectOptions] = useState({ redirect: false, productID: 0, redirectPath: '' });

    const handleFinish = () => {
        const roleID = []
        const includePermissions = []
        const excludedPermissions = []
        for (var value of Object.values(data)) {
            roleID.push(value['RoleID'])
            includePermissions.push(...value['IncludePermission'])
            excludedPermissions.push(...value['ExcludedPermissions'])
        }
        props.onCreateUser({ ExcludedPermissions: excludedPermissions, IncludedPermissions: includePermissions, UserDetails: { ...userFormValue }, Customers: customersSelect, Roles: roleID, ActiveCustomerID: activeCustomer })
        setRedirectOptions({ redirect: true, redirectPath: `/users` });
    };

    if (redirectOptions.redirect) {
        return <Routes><Route path="*" element={<Navigate to={redirectOptions.redirectPath} />} /></Routes>;
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            {getStepsContent(index, label)}
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        {translations["back"] || 'Back'}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color={isSuperUser() ? 'secondary' : 'primary'}
                                        onClick={() => handleNext(label)}
                                        className={classes.button}
                                    >
                                        {translations["next"] || 'Next'}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            <div style={{ float: 'right', marginTop: '-70px' }}>
                {activeStep === steps.length && (
                    <Paper square elevation={0} className={classes.resetContainer}>
                        <Typography>                    {translations["steps completed please"] || 'All steps completed. Please click finish to save changes'}                </Typography>
                        <Button color={isSuperUser() ? 'secondary' : 'primary'} variant="contained" onClick={handleFinish}
                            style={{ width: '120px', margin: 10 }}
                        >
                            {activeStep === steps.length - 1 ? translations['save'] || 'Save' : translations['finish'] || 'Finish'}
                        </Button>
                    </Paper>
                )}
            </div>
        </div>
    );
}
export default withStyles(styles)(RoleForCustomer);
