import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {combineReducers} from 'redux';

// Reducers
import trainingModulesReducer from './TrainingModules/store/trainingModulesReducer';

// Components
import TrainingModulesList from './TrainingModules';

// Routes
export const routes = basePath =>
    (
        <Routes>
            <Route path={basePath}  element={<TrainingModulesList/>}/>
        </Routes>
    );

export const reducers = combineReducers({
    trainingModules: trainingModulesReducer
});