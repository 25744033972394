import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route } from 'react-router';
import { Grid, Paper, withStyles } from '@material-ui/core';

import Form from '../../np-form/Form';
import * as formUtility from '../../np-form/utility';

import * as actions from './store/permissionActions';
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,

        padding: theme.spacing(2),
        display: 'flex',
        flexWrap: 'wrap'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center'
    }
});
const PermissionEdit = props => {
    const [permissionsForm, setPermissionsForm] = useState({});
    const [formIsValid, setFormIsValid] = useState(false);

    const { hasEditPermission, loadedPermission, editedPermission, classes, onLoadPermission, onLoadInit, onEditInit } = props;
    const permissionId = props.match.params.id;

    useEffect(() => {
        // Load the permission
        onLoadPermission({ PermissionID: permissionId });
        // Init the redux store on cleanup
        return () => {
            onLoadInit();
            onEditInit();
        };
    }, [onLoadPermission, onLoadInit, onEditInit, permissionId]);

    useEffect(() => {
        if (loadedPermission !== null) {
            const permissionForm = {
                name: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        fullWidth: true ,
                        margin: 'dense',
                        placeholder: 'Name'
                    },
                    value: loadedPermission.Name,

                    validation: {
                        required: true
                    },
                    valid: true,
                    touched: false
                }
            };
            setPermissionsForm(permissionForm);
            setFormIsValid(true);
        }
    }, [loadedPermission]);

    const submitHandler = event => {
        event.preventDefault();

        // Prepare the form data
        const formData = formUtility.getFormData(permissionsForm);

        const permissionsId = props.match.params.id;
        const permisionData = {
            PermissionID: permissionsId,
            Name: formData.name
        };

        props.onEditPermission(permisionData);
    };

    // Input change handler
    const inputChangedHandler = (event, inputIdentifier) => {
        // Get default new state based on the input change event
        const updatedState = formUtility.getNewStateOnInputChange(event, inputIdentifier, permissionsForm);
        setPermissionsForm(updatedState.myForm);
        setFormIsValid(updatedState.formIsValid);
    };
    const [redirect, setRedirect] = useState(false);
    const cancelHandler = () => {
        setRedirect(true);
    };

    if (editedPermission !== null || redirect) {
        // Prepare redirect URL
        let path = props.match.url; // .../permission/:id/edit
        let pathArray = path.split('/');
        pathArray.pop(); // remove edit
        pathArray.pop(); // remove :id
        path = pathArray.join('/'); // .../permission
        // Navigate to the ../permission
        return <Route path="*" element={<Navigate to={path} />} />;
    }

    // Render form
    return hasEditPermission ? (
        <div className={classes.root}>
            <Grid container spacing={10} justify="center" direction="row" alignItems="center">
                <Grid item lg={6} md={8} sm={8} xs={12}>
                    <Paper className={classes.paper}>
                        <Form
                            cancelHandler={cancelHandler}
                            buttonTextCancel="Cancel"
                            inputChangedHandler={inputChangedHandler}
                            submitHandler={submitHandler}
                            form={permissionsForm}
                            formIsValid={formIsValid}
                            buttonText="Save"
                            formTitle="Edit Permission"
                        />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    ) : null;
};

const mapStateToProps = state => {
    return {
        hasEditPermission: state.core.login.permissions.includes('*') || state.core.login.permissions.includes('permissions_edits'),
        loadedPermission: state.core.permission.loaded,
        editedPermission: state.core.permission.edited
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadInit: () => dispatch(actions.loadPermissionsInit()),
        onEditInit: () => dispatch(actions.editPermissionsInit()),
        onLoadPermission: id => dispatch(actions.loadPermissions(id)),
        onEditPermission: permissions => dispatch(actions.editPermissions(permissions))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(PermissionEdit));
