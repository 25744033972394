import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import { connect } from "react-redux";

import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Collapse } from "@material-ui/core";

import "./style/Sidebar.scss";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";


const styles = theme => ({
    "@global": {
        "*::-webkit-scrollbar": {
            width: "0.85em"
        },
        "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)"
        },
        "*::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid slategrey"
        }
    },
    selectedItem: {
        background: theme.palette.primary.light,
        color: theme.palette.buttonText.default
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36
    },
    hide: {
        display: "none"
    },
    drawer: {
        width: 240,
        flexShrink: 0,
        whiteSpace: "nowrap",
        background: "red"
    },
    drawerOpen: {
        backgroundColor: theme.palette.primary.sidebar, // change color depend on role (super admin, others)
        width: 240,
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        backgroundColor: theme.palette.primary.sidebar, // change color depend on role (super admin, others)
        color: "white",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
        width: 60,
        [theme.breakpoints.up("sm")]: {
            width: 60
        }
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    letterIcon: {
        width: 50,
        color: "white"
    },
    listItems: {
        color: "white",
        fontSize: "small"
    },
    subText: {
        fontFamily: "Arial",
        color: "azure",
        fontSize: 14
    },
    letterIconText: {
        fontFamily: "Arial",
        fontSize: 14,
        paddingLeft: 10
    }
});

const newMenuOpen = routes => {
    const url = window.location.href.split("?")[0];
    const urlSplit = url.split("/");
    let obj = {};
    if (routes.length > 0 && Array.isArray(routes)) {
        routes.map(({ subComponents, name }) =>
            subComponents !== null && subComponents.map(path => path !== null && path.path.split("/")[1]).some(path => urlSplit.includes(path))
                ? (obj[`${name}`] = true)
                : (obj[`${name}`] = false)
        );
    }

    return obj;
};
let fromAction = false;
const Sidebar = props => {
    const { routes } = props;
    const [menuOpen, setMenuOpen] = useState({});
    const [hover, setHover] = useState(false);
    const [timerID, setTimerID] = useState();

    const { classes } = props;

    useEffect(() => {
        setMenuOpen(newMenuOpen(routes));
    }, [routes]);

    const toggleSubComponents = (name, isAction) => {
        fromAction = isAction;
        const newMenuOpen = {};
        newMenuOpen[name] = !menuOpen[name];
        setMenuOpen(newMenuOpen);
    };

    const drawerEnter = () => {
        if (props.open === false && hover === false) {
            const timerID = setTimeout(() => {
                props.handleHoverSidebarToggle(true);
                setHover(true);
            }, 1000);
            setTimerID(timerID);
        }
    };

    const drawerLeave = () => {
        if (timerID) {
            clearTimeout(timerID);
            setTimerID(null);
            if (hover) {
                props.handleHoverSidebarToggle(false);
            }
            setHover(false);
        }
    };

    const links = (
        <List>
            {props.routes.map((prop, key) =>
                prop.subComponents != null ? (
                    <div key={key}>
                        <ListItem onClick={() => toggleSubComponents(prop.name, true)} button>
                            <ListItemIcon className={classes.letterIcon}>
                                <>
                                    {" "}
                                    {typeof prop.icon === "string" ? <Icon>{prop.icon}</Icon> : <prop.icon />}{" "}
                                    {!props.open ? (
                                        !menuOpen[prop.name] ? (
                                            <ArrowDropDown style={{ color: "white" }} />
                                        ) : (
                                                <ArrowDropUp style={{ color: "white" }} />
                                            )
                                    ) : (
                                            <></>
                                        )}
                                </>
                            </ListItemIcon>
                            <ListItemText className={classes.listItems} primary={prop.name} disableTypography={true} />{" "}
                            {!menuOpen[prop.name] ? <ArrowDropDown style={{ color: "white" }} /> : <ArrowDropUp style={{ color: "white" }} />}
                        </ListItem>
                        <Collapse in={menuOpen[prop.name]}>
                            <List component="div" disablePadding>
                                {prop.subComponents.map(value => {
                                    if (!value) {
                                        return null;
                                    }
                                    if (window.location.href.includes(value.path) && !menuOpen[prop.name] && !fromAction) {
                                        toggleSubComponents(prop.name, false);
                                    }
                                    return (
                                        <NavLink className="navlinkStyle" to={prop.basePath + value.path} key={value.name}>
                                            <ListItem
                                                classes={{
                                                    selected: classes.selectedItem
                                                }}
                                                key={value.name} button selected={window.location.href.includes(value.path)} onClick={() => toggleSubComponents(value.name, false)}>
                                                <ListItemIcon className={classes.letterIcon}>
                                                    <div className={classes.letterIconText}>
                                                        {value.name.split(" ").map(val => {
                                                            return val[0];
                                                        })}
                                                    </div>
                                                </ListItemIcon>
                                                <ListItemText className={classes.subText} primary={value.name} disableTypography={true}></ListItemText>
                                            </ListItem>
                                        </NavLink>
                                    );
                                })}
                            </List>
                        </Collapse>
                    </div>
                ) : (
                        <div key={key}>
                            <NavLink className="navlinkStyle" to={prop.path} key={key}>
                                <ListItem
                                    classes={{
                                        selected: classes.selectedItem
                                    }}
                                    button onClick={() => toggleSubComponents(prop.name, true)} selected={window.location.href.includes(prop.path)}>
                                    <ListItemIcon className={classes.letterIcon}>
                                        {typeof prop.icon === "string" ? <Icon>{prop.icon}</Icon> : <prop.icon />}
                                    </ListItemIcon>
                                    <ListItemText className={classes.subText} primary={prop.name} disableTypography={true} />
                                </ListItem>
                            </NavLink>
                        </div>
                    )
            )}
        </List>
    );

    return (
        <Drawer
            onMouseEnter={drawerEnter}
            onMouseLeave={drawerLeave}
            variant="permanent"
            anchor="left"
            open={props.open}
            ModalProps={{
                keepMounted: true
            }}
            className={classNames(props.sidebarStyle && props.sidebarStyle.drawer ? props.sidebarStyle.drawer : classes.drawer, {
                [props.sidebarStyle && props.sidebarStyle.drawerOpen ? props.sidebarStyle.drawerOpen : classes.drawerOpen]: props.open,
                [props.sidebarStyle && props.sidebarStyle.drawerClose ? props.sidebarStyle.drawerClose : classes.drawerClose]: !props.open
            })}
            classes={{
                paper: classNames({
                    [props.sidebarStyle && props.sidebarStyle.drawerOpen ? props.sidebarStyle.drawerOpen : classes.drawerOpen]: props.open,
                    [props.sidebarStyle && props.sidebarStyle.drawerClose ? props.sidebarStyle.drawerClose : classes.drawerClose]: !props.open
                })
            }}
        >
            <div>{links}</div>
        </Drawer>
    );
};

const mapStateToProps = state => {
    return {
        isSuperUser: state && state.core && state.core.login && state.core.login.permissions ? state.core.login.permissions.includes("*") : false
    };
};

export default connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(Sidebar));
