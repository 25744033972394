import moment from 'moment';
import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import timezone from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

export const formatDate = date => {
    if (date) {

            return moment(date).format('DD.MM.YYYY HH:mm:ss');
    };
    return '';
};

export const formatDateWithSite = (date, siteKey) =>{
    if (date) {
        if(siteKey === 'JRZ'){
            return `${timezone.utc(date).tz('America/Chihuahua').format('DD.MM.YYYY HH:mm:ss zz')}`;
        };

        if(siteKey === 'QRO'){
           return `${timezone.utc(date).tz('America/Los_Angeles').format('DD.MM.YYYY HH:mm:ss zz')}`;
        };

        if(siteKey === 'GK'){
            return `${timezone.utc(date).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm:ss zz')}`;
        };

        return `${moment.utc(date).format('DD.MM.YYYY HH:mm:ss')} (GMT)`;

    };
    return '';
}

export const jsonPrettyPrint = jsonData => {
    let parsedObject = jsonData ? JSON.parse(jsonData) : {};
    return Object.keys(parsedObject).map(key => {
        return <p key={`${key}_${Math.random() * 100}`} style={{ padding: 0, margin: 0 }}>{`${key}: ${parsedObject[key]}`}</p>;
    });
};
export const hasPermission = (userPermissions, permission) => {
    return userPermissions.includes("*") || userPermissions.includes(permission);
};
export const hasAtLeastOnePermission = (userPermissions, requiredPermissions) => {
    return userPermissions.includes('*') || requiredPermissions.some(requiredPermission => userPermissions.includes(requiredPermission));
};
export const parseURL = url => {
    let query = url.split('?');
    if (query.length === 1) {
        return null;
    }
    let queryData = query[1];
    if (queryData.includes('&')) {
        queryData = [...queryData.split('&')];
    } else {
        queryData = [queryData]
    }

    let obj = {};
    for (let data of queryData) {
        let splitByEqual = data.split('=');
        obj[splitByEqual[0]] = splitByEqual[1];
    }
    return obj;
};

export const includes = (permissions, includedPermissions) => {
    return includedPermissions.includes(permissions);
};

export const downloadFile = (data, fileName) => {
    const objectURL = URL.createObjectURL(data);
    const downloadElement = document.createElement('a');
    downloadElement.href = objectURL;
    downloadElement.download = `${fileName}`;
    return downloadElement.click();
};

export const CreateHistory = () => {
    const navigate = useNavigate();
    let pathName = window.location.pathname;
    let search = window.location.search;
    let backRoute = `${pathName}${search ? search : ''}`;
    navigate(backRoute);

};

export const BackButton = () => {
    const navigate = useNavigate();
    return <Tooltip title="Go back">
        <IconButton aria-label="Go back" onClick={() => { navigate(-1) }}>
            <ArrowBack />
        </IconButton>
    </Tooltip>
};

export const exampleValues = `
<div dir="ltr">
Examples:
<p class="MsoNormal" style="font-size:11pt;font-family:Calibri,sans-serif"><b><span lang="EN-US" style="font-size:10.5pt;font-family:&quot;Segoe UI&quot;,sans-serif;color:rgb(125,197,227);letter-spacing:-0.05pt">Installation</b></p>
<p class="MsoNormal" style="font-size:11pt;font-family:Calibri,sans-serif">For a more smooth installation process we sign the Installer with a SSL certificate.</p>
<p class="MsoNormal" style="font-size:11pt;font-family:Calibri,sans-serif"><b><span lang="EN-US" style="font-size:10.5pt;font-family:&quot;Segoe UI&quot;,sans-serif;color:rgb(125,197,227);letter-spacing:-0.05pt">Erasure</b></p>
<p class="MsoNormal" style="font-size:11pt;font-family:Calibri,sans-serif">The erasure process was improved to handle more easily multiple parallel iOS erasures.</p>
<p class="MsoNormal" style="font-size:11pt;font-family:Calibri,sans-serif"><b><span lang="EN-US" style="font-size:10.5pt;font-family:&quot;Segoe UI&quot;,sans-serif;color:rgb(125,197,227);letter-spacing:-0.05pt">Notifications</b><b><span lang="EN-US" style="font-size:10.5pt;font-family:&quot;Segoe UI&quot;,sans-serif;color:rgb(229,35,30);letter-spacing:-0.05pt"></b></p>
<p class="MsoNormal" style="font-size:11pt;font-family:Calibri,sans-serif">The notification system was revamped to provide easy access to all notifications.</p>
<p class="MsoNormal" style="font-size:11pt;font-family:Calibri,sans-serif"><b><span lang="EN-US" style="font-size:10.5pt;font-family:&quot;Segoe UI&quot;,sans-serif;color:rgb(125,197,227);letter-spacing:-0.05pt">Proxy Support</b></p>
<p class="MsoNormal" style="font-size:11pt;font-family:Calibri,sans-serif">To provide full flexibility many proxy settings where implemented.</p>
<p class="MsoNormal" style="font-size:11pt;font-family:Calibri,sans-serif">Additionally proxy support can be granular activated for Firmware-Download and/or Factory Reset.</p></div>
`;