import restCrudActions from '../../../np-crud/store/restCrudActions'

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: 'environments',
    pluginName: 'ENVIRONMENT',
    entityName: 'ENVIRONMENT',
    fetchSuccessMessage: '',
    loadSuccessMessage: '',
});

// Fetch Environment action creators
export const fetchEnvironments = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
};

// Create Environment  action creators
export const createEnvironment = Environment => {
    return async dispatch => {
        await crudActions.create(dispatch, Environment);
    };
};

// Edit Environment  action creators
export const editEnvironment = setting => {
    return async dispatch => {
        await crudActions.edit(dispatch, setting);
    };
};

// Load Environment action creators
export const loadEnvironment = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

// Delete Environment  action creators
export const bulkDeleteEnvironment = environmentIDs => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, environmentIDs);
    };
};

// Delete Environment  action creators
export const deleteEnvironment = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};