import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { combineReducers } from 'redux';

import Roles from './Roles';
import RolesCreate from './Roles/RoleCreate';
import RoleEdit from './Roles/RoleEdit'

// Reducers
import rolesReducer from './Roles/store/roleReducer';

// Routes
export const routes = (basePath, permissions) =>
    <Routes>
        {<Route path={basePath} element={<Roles/>} />}
        {<Route path={basePath + '/create'} element={<RolesCreate/>} />}
        {<Route path={basePath + '/:id/edit'} element={<RoleEdit/>} />}

    </Routes>


export const reducers = combineReducers({
    roles: rolesReducer
});