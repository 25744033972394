import React, { useState, useEffect } from 'react';

import Form from '../../np-form/Form';
import { Dialog, withStyles } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});

const usedFor = [
    { value: 'cloud', label: 'Cloud access' },
    { value: 'printer_agent', label: 'Printer agent' },
    { value: 'chat_app', label: 'Securaze chat application' },
    { value: 'on_premise_server', label: 'On premise server' }
];

const updateFormState = props => {
    const { licensePrototype, classes, permissions } = props;

    const configuration = licensePrototype && licensePrototype.Configuration && typeof licensePrototype.Configuration === 'string' ? JSON.parse(licensePrototype.Configuration) : null;

    return {
        Name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Name',
                label: 'Name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                autoFocus: true

            },
            value: licensePrototype ? licensePrototype.Name : '',
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        Hardware: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Hardware',
                label: 'Hardware',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: configuration ? configuration.Hardware : '',
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        UsedFor: {
            elementType: 'multi-select',
            elementConfig: {
                type: 'multi-select',
                isMulti: true,
                closeMenuOnSelect: true,
                components: animatedComponents,
                placeholder: 'Please choose usage',
                label: 'Please choose usage',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                options: usedFor,
                selected: configuration && configuration.UsedFor ? configuration.UsedFor : [],
                maxElements: 10
            },
            formHelperText: 'Please select what will the license be used for',
            value: configuration && configuration.UsedFor ? configuration.UsedFor : [],
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Permissions: {
            elementType: 'multi-select',
            elementConfig: {
                type: 'multi-select',
                isMulti: true,
                closeMenuOnSelect: true,
                components: animatedComponents,
                placeholder: 'Please select permissions for license',
                label: 'Please select permissions for license',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                options: permissions ? permissions : [],
                selected: configuration && configuration.Permissions ? configuration.Permissions : [],
                maxElements: 10
            },
            formHelperText: 'Please select permissions for license',
            value: configuration && configuration.Permissions ? configuration.Permissions : [],
            validation: {
                required: true
            },
            valid: true,
            touched: true
        }
    };
};

const LicensePrototypeEdit = props => {
    const { open, classes, loading, licensePrototype, handleEdit, onEditLicensePrototype, permissions, activeCustomer } = props;

    const [licensePrototypeForm, setLicensePrototypeForm] = useState(updateFormState({
        licensePrototype,
        classes,
        permissions
    }));
    const [formIsValid, setFormIsValid] = useState(false);

    useEffect(() => {
        setLicensePrototypeForm(updateFormState({ licensePrototype, classes, permissions }));
    }, [licensePrototype, classes, permissions]);


    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, licensePrototypeForm);
        setLicensePrototypeForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const dateChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnDateChange(event, inputIdentifier, licensePrototypeForm);
        setLicensePrototypeForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const getMultiSelectData = (data, _selectedObj, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnMultiSelectChange(data, inputIdentifier, licensePrototypeForm);
        setLicensePrototypeForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const submitHandler = async event => {
        event.preventDefault();

        const formData = formUtility.getFormData(licensePrototypeForm);

        const configuration = JSON.stringify({
            UsedFor: formData.UsedFor,
            Permissions: formData.Permissions,
            Hardware: formData.Hardware
        });

        delete formData.UsedFor;
        delete formData.Permissions;
        delete formData.Hardware;

        formData['LicensePrototypeID'] = licensePrototype.LicensePrototypeID;
        await onEditLicensePrototype({ ...formData, Configuration: configuration, ActiveCustomerID: activeCustomer });

        setLicensePrototypeForm(updateFormState({}));
        setFormIsValid(false);
        handleEdit();
    };

    const cancelHandler = () => {
        setLicensePrototypeForm(updateFormState({}));
        setFormIsValid(false);
        handleEdit();
    };

    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            fullWidth={true}
            // onClose={cancelHandler}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    cancelHandler();
                }
            }}
        >
            <Form
                formIsValid={formIsValid}
                classes={classes}
                form={licensePrototypeForm}
                formTitle={`Edit license prototype`}
                buttonText={props.hasPermission('license_prototype_save') ? "Save" : null}
                buttonTextCancel="Cancel"
                submitHandler={submitHandler}
                cancelHandler={cancelHandler}
                inputChangedHandler={inputChangedHandler}
                dateChangedHandler={dateChangedHandler}
                getMultiSelectData={getMultiSelectData}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                cancelButtonDisabled={loading}
            />
        </Dialog>
    )
};

export default withStyles(styles)(LicensePrototypeEdit);