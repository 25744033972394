import React, { useState } from 'react';
import { withStyles, Typography, Button, Link, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { connect } from 'react-redux';

import UserActivityList from './UserActivityList';
import { hasPermission, formatDate } from '../../ms-utilities';
import { isSuperUser } from '../../np-utilities';
import moment from 'moment';
import { RemoveCircleOutline, More } from '@material-ui/icons';
import * as actions from './store/activityActions';
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
    "@global": {
        "[class*='statusActive']": {
            color: theme.palette.success.dark
        },
        "[class*='statusInactive']": {
            color: theme.palette.error.dark
        },
        "[class*='button']": {
            textTransform: 'none',
            textDecoration: 'none'
        }
    },
    button: {
        textTransform: 'none',
        textDecoration: 'none'
    },
    statusActive: {
        color: theme.palette.success.dark
    },
    statusInactive: {
        color: theme.palette.error.dark
    }
});

const UserActivity = props => {
    const { classes, translations, permissions, sendMessage, columns } = props;
    const [userAgentDialog, setUserAgentDialog] = useState({ open: false, sessionDialog: false });
    let visible = columns && columns['UserActivity'];

    let tableColumns = [
        {
            title: translations["username"] || 'Username', field: 'Username',
            hidden: visible ? visible['Username'] : false,
            render: rowData => {
                // Underline stays if we overwrite it with textDecoration under classes
                // if needed use style={{ textDecoration: 'none' }}
                return <Button className={classes.button} color="primary" variant="outlined" component={Link} href={`/users/details?userID=${rowData.UserID}`}><Typography variant={'caption'}>{rowData &&  rowData.User && rowData.User.Username ? rowData.User.Username: '-'}</Typography></Button>;
            },

        },
        {
            title: translations["login_credential"] || 'Login credential', field: 'LoginCredential',
            hidden: visible ? visible['LoginCredential'] : false,
            render: rowData => {
                return <Typography variant={'caption'}>{rowData.LoginCredential}</Typography>;
            },

        },
        {
            title: translations["created_at"] || 'Created at', field: 'CreatedAt',
            hidden: visible ? visible['CreatedAt'] : false,
            type: 'datetime',
            render: rowData => {
                return <Typography variant={'caption'}>{formatDate(rowData.CreatedAt)}</Typography>;
            }
        },
        {
            title: translations["last_activity"] || 'Last activity', field: 'LastActivity',
            hidden: visible ? visible['LastActivity'] : false,
            type: 'datetime',
            defaultFilter: moment().subtract(1, 'days'),
            render: rowData => {
                return <Typography variant={'caption'}>{formatDate(rowData.LastActivity)}</Typography>;
            }
        },
        {
            title: translations["expires_at"] || 'Expires at', field: 'ExpiresAt',
            hidden: visible ? visible['ExpiresAt'] : false,
            type: 'datetime',
            render: rowData => {
                return <Typography variant={'caption'}>{formatDate(rowData.ExpiresAt)}</Typography>;
            }
        },
        {
            title: translations["status"] || 'Status', field: 'Status',
            hidden: visible ? visible['Status'] : false,
            lookup: { 'active': 'Active', 'inactive': 'Inactive' },
            defaultFilter: ['active'],
            render: rowData => {
                return <Typography variant={'caption'} className={rowData.Status === 'active' ? classes.statusActive : classes.statusInactive}>{rowData.Status === 'active' ? 'Active' : 'Inactive'}</Typography>;
            }
        },
        {
            title: translations["client_type"] || 'Client type', field: 'ClientType',
            hidden: visible ? visible['ClientType'] : false,
            lookup: { 'workplace': 'Workplace', 'mobile': 'Mobile', 'workplace_legacy': 'Workplace legacy', 'display': 'Display', 'web_ui': 'Web UI', 'mobile_app': 'Mobile app' },
            defaultFilter: ['web_ui', 'workplace', 'mobile', 'workplace_legacy', 'display', 'mobile_app'],
            render: rowData => {
                return <Typography variant={'caption'}>{rowData.ClientType}</Typography>;
            }
        },
        {
            title: translations["client_version"] || 'Client version', field: 'ClientVersion',
            hidden: visible ? visible['ClientVersion'] : false,
        },

        {
            title: translations["logged_with"] || 'Logged with', field: 'LoggedWith',
            hidden: visible ? visible['LoggedWith'] : false,
            lookup: { 'username': 'Username', 'email': 'Email' },
            defaultFilter: ['username', 'email'],
            render: rowData => {
                return <Typography variant={'caption'}>{rowData.LoggedWith}</Typography>;
            }
        },
        {
            title: translations["ip"] || 'IP', field: 'HardwareIP',
            hidden: visible ? visible['HardwareIP'] : false,
            render: rowData => {
                return <Typography variant={'caption'}>{rowData.HardwareIP}</Typography>;
            }
        },
    ];

    const handleRemoveSession = async (event, removeData) => {
        if (!event) {
            setUserAgentDialog({ open: false });
            return;
        }
        setUserAgentDialog({ ...userAgentDialog, sessionDialog: true, SessionID: removeData.SessionID , user: removeData && removeData.User && removeData.User.Username ? removeData.User.Username  : ''  });
    };

    const tableActions = [
        rowData => ({
            icon: RemoveCircleOutline,
            tooltip: rowData.Status === 'inactive' ? null : translations['remove_session'] || 'Remove session',
            onClick: (event, rowData) => handleRemoveSession(event, rowData),
            disabled: rowData.Status === 'inactive',
            hidden: !isSuperUser()
        }),
        rowData => ({
            icon: More,
            hidden: !isSuperUser(),
            tooltip: translations['user_agent_details'] || 'User agent details',
            onClick: (event, rowData) => {
                try {
                    let userAgentInfo = JSON.parse(rowData.UserAgentInformation);
                    if (!userAgentInfo) {
                        throw new Error('No user agent information');
                    }
                    setUserAgentDialog({ ...userAgentDialog, open: true, details: userAgentInfo });
                } catch (error) {
                    sendMessage('No user agent information', 'info');
                }
            },
        })
    ]

    let localization = {
        pagination: {
            labelDisplayedRows: translations["from_to_of_count"] || '{from}-{to} of {count}',
            firstTooltip: translations['first_page'] || "First page",
            previousTooltip: translations['previous_page'] || "Previous page",
            nextTooltip: translations['next_page'] || "Next page",
            lastTooltip: translations['last_page'] || "Last page",
            labelRowsSelect: translations['rows'] || 'rows'
        },
        body: {
            emptyDataSourceMessage: translations['table_no_match'] || "Sorry, no matching records found"
        }
    };

    if (!isSuperUser()) {
        tableColumns.splice(2, 3);
        tableColumns.shift();
    }

    return (
        <div className={classes.root}>
            {hasPermission(permissions, 'user_session_list') ?
                <UserActivityList localization={localization} handleRemoveSession={handleRemoveSession} translations={translations} userAgentDialog={userAgentDialog} tableColumns={tableColumns} tableActions={tableActions} permissions={permissions} />
                : null}
            {userAgentDialog.open ?
                <Dialog
                    id="user-agent-dialog-title"
                    open={userAgentDialog.open}
                    // disableBackdropClick={false}
                    disableEscapeKeyDown={false}
                    disableEnforceFocus={false}
                    fullWidth={true}
                    // onClose={() => setUserAgentDialog({ open: false })}
                    onClose={(_, reason) => {
                        if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                            setUserAgentDialog({ open: false });
                        }
                    }}
                >
                    <DialogTitle id="user-agent-dialog-title">User agent information</DialogTitle>
                    <DialogContent dividers>
                        <Typography variant={'caption'} gutterBottom><b>Browser: </b> {userAgentDialog.details.browser}</Typography><br />
                        <Typography variant={'caption'} gutterBottom><b>Browser version: </b> {userAgentDialog.details.browserVersion}</Typography><br />
                        <Typography variant={'caption'} gutterBottom><b>OS: </b> {userAgentDialog.details.os}</Typography><br />
                        <Typography variant={'caption'} gutterBottom><b>Platform: </b> {userAgentDialog.details.platform}</Typography><br />
                    </DialogContent>
                </Dialog>

                : null}
        </div >
    );
}

const mapStateToProps = state => {
    return {
        permissions: state.core.login.permissions,
        translations: state.translate && state.translate.translations ? state.translate.translations.activeTranslations || {} : {},
        activeCustomerID: state.core.login.activeCustomer.CustomerID,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeSession: queryParams => dispatch(actions.removeSession(queryParams)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(UserActivity));