import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import NPDatatable from '../../np-components/Datatable';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid, withStyles, Paper, LinearProgress } from '@material-ui/core';
import ConfirmationDialog from '../../np-components/ConfirmationDialog/ConfirmationDialog';
import EditIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import { Navigate, Route } from 'react-router';
import { NavLink } from 'react-router-dom';
import queryParser from 'query-string-parser';
import useListFilter from '../../np-crud/listFilter';
import IconButton from '@material-ui/core/IconButton';

import * as actions from './store/permissionActions';

const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        '&:hover': {
            backgroundColor: '#757575'
        }
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },
    iconHover: {
        '&:hover': {
            color: '#99ccff'
        }
    }
});

// Premissions List Component
const PermissionList = props => {
    // Get  style classes and redux mapped props
    const {
        classes,
        onBulkDeletePermissions,
        onBulkDeleteInit,
        hasEditPermission,
        permissionCount,
        onDeletePermissions,
        permissionList,
        loading,
        onFetchInit,
        onFetchPermissions,
        onDeleteInit
    } = props;

    // List Filter
    const urlOptions = queryParser.fromQuery(props.location.search);
    const listFilter = useListFilter({ searchColumns: ['Name'], ...urlOptions });

    // Delete dialog state
    const [deleteDialog, setDeleteDialog] = useState({ open: false, confirmed: false });
    // Data to be deleted
    const [deleteData, setDeleteData] = useState({});
    // Bulk delete dialog state
    const [bulkDeleteDialog, setBulkDeleteDialog] = useState({ open: false, confirmed: false });
    // Bulk data to be deleted
    const [bulkDeleteData, setBulkDeleteData] = useState({});

    const [redirectCreate, setRedirectCreate] = useState({ redirect: false, UserID: 0, redirectPath: '' });

    // Init the redux store on mount and on unmount

    useEffect(() => {
        onFetchInit();
        return () => {
            //Cleanup redux store

            onFetchInit();
        };
    }, [onFetchInit]);

    // Fetch permissions if the filter was changed or after delete
    useEffect(() => {
        if (listFilter.filter) {
            onFetchPermissions(listFilter.filter);
        }
    }, [listFilter.filter, onFetchPermissions]);

    useEffect(() => {
        const query = queryParser.toQuery(listFilter.options);
        props.history.push('?' + query);
    }, [listFilter.options, props.history]);

    useEffect(() => {
        const deletePermissions = async () => {
            if (deleteData !== {}) {
                // Run delete
                await onDeletePermissions(deleteData);
                // Init state
                setDeleteDialog({ open: false, confirmed: false });
                // Fetch Permissions
                await onFetchPermissions(listFilter.filter);
                return setDeleteData({});
            }
        };
        if (deleteDialog.confirmed) {
            deletePermissions();
            return () => {
                // Cleanup redux store
                onDeleteInit();
            };
        }
    }, [deleteData, deleteDialog.confirmed, listFilter.filter, onDeletePermissions, onFetchPermissions, onDeleteInit]);

    // Bulk delete Permissions

    // Bulk delete Permissions if dialog confirmed
    useEffect(() => {
        const bulkDeletePermission = async () => {
            if (bulkDeleteData !== {}) {
                // Run bulk delete
                await onBulkDeletePermissions(bulkDeleteData);
                // Init bulk delete state
                // Hide dialog
                setBulkDeleteDialog({ open: false, confirmed: false });
                // Fetch Permissions
                await onFetchPermissions(listFilter.filter);
                return setBulkDeleteData({});
            }
        };
        if (bulkDeleteDialog.confirmed) {
            // Bulk delete Permissions
            bulkDeletePermission();
            return () => {
                // Cleanup redux store
                onBulkDeleteInit();
            };
        }
    }, [bulkDeleteData, bulkDeleteDialog.confirmed, listFilter.filter, onBulkDeleteInit, onBulkDeletePermissions, onFetchPermissions]);

    // Delete handler
    const handleDelete = PermissionID => {
        // Set data to be deleted
        setDeleteData({ PermissionID: PermissionID });
        // Show delete dialog
        setDeleteDialog({ open: true, confirmed: false });
    };

    // Bulk delete handler
    const handleBulkDelete = ids => {
        //Set bulk delete data
        setBulkDeleteData({ PermissionIDs: ids });
        // Show bulk delete dialog
        setBulkDeleteDialog({ open: true, confirmed: false });
    };

    const createActions = permission => {
        return (
            <>
                {hasEditPermission ? (
                    <div>
                        <Tooltip title={'Edit'}>
                            <IconButton>
                                <NavLink className="navlinkStyle" to={props.match.path + '/' + permission.PermissionID + '/edit'}>
                                    <EditIcon variant="contained" color="primary" fontSize="small" />
                                </NavLink>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={'Delete'}>
                            <IconButton onClick={() => handleDelete(permission.PermissionID)} variant="contained" color="secondary" fontSize="small">
                                <DeleteIcon style={{ color: '#D71C1C' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                ) : null}
            </>
        );
    };

    let counter = 0;
    let permissionListData = permissionList.map(permission => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = permission.PermissionID;
        returnObj.visible = true;
        returnObj.values = [createActions(permission), permission.PermissionID, permission.Name];
        counter++;
        return returnObj;
    });

    const columns = [
        {
            displayName: 'Actions',
            backendName: 'Actions',
            options: {
                align: 'left',
                visible: true
            }
        },
        {
            displayName: 'Permission ID',
            backendName: 'PermissionID',
            options: {
                align: 'left',
                sortable: true,
                visible: true
            }
        },
        {
            displayName: 'Name',
            backendName: 'Name',
            options: {
                align: 'right',
                sortable: true,
                visible: true
            }
        }
    ];

    const tableOptions = {
        isSelectable: hasEditPermission,
        tableSearch: true,
        selectOptions: {
            printing: false,
            generatingReport: false,
            deleting: hasEditPermission,
            creating: hasEditPermission
        },
        rowsPerPage: 10,
        count: permissionCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'Permission list',
        noMatch: 'Sorry, no matching records found',
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return handleBulkDelete(IDs);
        }
    };

    if (redirectCreate.redirect) {
        return <Route path="*" element={<Navigate to={redirectCreate.redirectPath} />} />;
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={10} justify="center" direction="row" alignItems="center">
                <Grid className={classes.item} item xs={12} md={11} sm={12} lg={10}>
                    {loading ? <LinearProgress color="secondary" /> : null}
                    <Paper>
                        <NPDatatable
                            handleCreate={() => {
                                setRedirectCreate({ redirect: true, redirectPath: `/permissions/create` });
                            }}
                            customStyles={classes}
                            data={permissionListData}
                            columns={columns}
                            options={tableOptions}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <ConfirmationDialog
                isOpen={deleteDialog.open}
                title="Delete permission"
                contentText="Are you sure to delete permission?"
                onYes={() => setDeleteDialog({ open: false, confirmed: true })}
                onCancel={() => setDeleteDialog({ open: false, confirmed: false })}
                textYes="Delete"
            />
            <ConfirmationDialog
                isOpen={bulkDeleteDialog.open}
                title="Delete all permissions"
                contentText="Are you sure to delete all selected permissions?"
                onYes={() => setBulkDeleteDialog({ open: false, confirmed: true })}
                onCancel={() => setBulkDeleteDialog({ open: false, confirmed: false })}
                textYes="Delete"
            />
        </div>
    );
};

const mapStateToProps = state => {
    return {
        permissionCount: state.core.permission.count,
        permissionList: state.core.permission.list,
        hasEditPermission: state.core.login.permissions.includes('*') || state.core.login.permissions.includes('core_permission_edit'),
        deletedPermisions: state.core.permission.deleted,
        loading: state.core.permission.fetching
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchInit: () => dispatch(actions.fetchPermissionsInit()),
        onFetchPermissions: filter => dispatch(actions.fetchPermissions(filter)),
        onDeleteInit: () => dispatch(actions.deletePermissionsInit()),
        onBulkDeleteInit: () => dispatch(actions.bulkDeletePermissionsInit()),
        onDeletePermissions: permission => dispatch(actions.deletePermissions(permission)),
        onBulkDeletePermissions: permissionIds => dispatch(actions.bulkDeletePermissions(permissionIds))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(PermissionList));
