import React, { useState } from 'react';
import Form from '../../../../np-form/Form';
import { withStyles } from '@material-ui/core';
import * as formUtility from '../../../../np-form/utility';
import moment from 'moment'
import { isSuperUser } from '../../../../np-utilities';
import { Navigate, Route, Routes } from 'react-router-dom';

const styles = theme => ({
    root: {

        padding: '6px',
        width: '91%',
        paddingLeft: '5%'
    }
});

const AdminCreate = props => {
    const { classes, translations, setActiveStep, setCustomers, userFormValid, activeCustomer, setUserFormValid, setUserFormValue, userFormValue } = props;
    const firstForm = {
        FirstName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['first_name'] || 'First name',
                label: translations['first_name'] || 'First name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: userFormValue['FirstName'] || '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        LastName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['last_name'] || 'Last name',
                label: translations['last_name'] || 'Last name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: userFormValue['LastName'] || '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        Username: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['username'] || 'Username',
                label: translations['username'] || 'Username',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: userFormValue['Username'] || '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        Email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: translations['e_mail'] || 'E-Mail',
                label: translations['e_mail'] || 'E-Mail',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: userFormValue['Email'] || '',
            validation: {
                required: true,
                isEmail: true
            },
            valid: true,
            touched: true
        },
        PhoneNumber: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['phone_number'] || 'Phone number',
                className: classes && classes.inputs ? classes.inputs : null,
                label: translations['phone_number'] || 'Phone number',
                error: false,
                fullWidth: true
            },
            value: userFormValue['PhoneNumber'] || '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: translations['password'] || 'Password',
                label: translations['password'] || 'Password',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: userFormValue['Password'] || '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        RepeatPassword: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: translations['password_repeat'] || 'Password repeat ',
                label: translations['password_repeat'] || 'Password repeat ',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                error: false,
                fullWidth: true
            },
            value: userFormValue['RepeatPassword'] || '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        Status: {
            elementType: 'select_v2',
            elementConfig: {
                id: "userStatus",
                fullWidth: true,
                options: [{ value: 'active', displayValue: translations['active'] || 'Active' }, { value: 'inactive', displayValue: translations['inactive'] || 'Inactive' }]
            },
            value: userFormValue['Status'] || 'active',
            validation: {},
            valid: true,
            formHelperText: translations['please_select_status'] || 'Please select status'
        },
        PasswordExpires: {
            elementType: 'checkbox',
            elementConfig: {
                type: 'checkbox',
                label: null,
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [{ ID: 'checkboxID', Checked: false, Name: translations['user_to_change'] || "Force user to change password" }]
            },
            value: userFormValue['PasswordExpires'] || false,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        }
    };
    const [userForm, setUserForm] = useState(firstForm);
    const [superAdmin, setSuperAdmin] = useState(false);

    const userFormInputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, userForm);
        setUserForm(updateState.myForm);
        if (updateState.myForm.RepeatPassword.value !== updateState.myForm.Password.value && (updateState.myForm.RepeatPassword.value !== '' || updateState.myForm.Password.value !== '')) {
            updateState.formIsValid = false;
        }

        if (inputIdentifier === 'Password' && updateState.myForm.RepeatPassword.value !== updateState.myForm.Password.value) {
            updateState.formIsValid = false;
        }
        if (inputIdentifier === 'Email') {
            setUserForm({ ...updateState.myForm, Email: { ...updateState.myForm.Email, elementConfig: { ...updateState.myForm.Email.elementConfig, error: !updateState.myForm.Email.valid } } })
        }
        if (inputIdentifier === 'RepeatPassword') {
            setUserForm({ ...updateState.myForm, RepeatPassword: { ...updateState.myForm.RepeatPassword, elementConfig: { ...updateState.myForm.RepeatPassword.elementConfig, error: updateState.myForm.RepeatPassword.value !== updateState.myForm.Password.value } } })
        }
        setUserFormValid(updateState.formIsValid);
    };
    const checkboxChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnCheckboxChange(event, inputIdentifier, userForm);
        if (inputIdentifier === 'UserSuperAdmin') {
            setSuperAdmin(updateState.myForm.UserSuperAdmin.value)
        }
        setUserForm(updateState.myForm);
    }
    const [redirectOptions, setRedirectOptions] = useState({ redirect: false, productID: 0, redirectPath: '' });

    const submitHandler = async event => {
        event.preventDefault();
        const formUser = formUtility.getFormData(userForm);
        formUser.Status = formUser.Status === 'active' ? 1 : 0;
        formUser.PasswordExpires = formUser.PasswordExpires ? moment().utc() : null;
        delete formUser.RepeatPassword;
        delete formUser.UserSuperAdmin;
        if (superAdmin) {
            props.onCreateUser({ UserDetails: { ...formUser }, SuperUser: true, ActiveCustomerID: activeCustomer })
            setRedirectOptions({ redirect: true, redirectPath: `/users` });
        }
        else {
            setUserFormValue(formUser)
            let customer = await props.onFetchCustomers({ ActiveCustomerID: activeCustomer });
            setCustomers(customer)
            setActiveStep(1)
        }
    };
    if (redirectOptions.redirect) {
        return <Routes><Route path="*" element={<Navigate to={redirectOptions.redirectPath} />} /></Routes>;
    }

    return (
        <Form
            formIsValid={userFormValid}
            classes={classes}
            form={userForm}
            inputChangedHandler={userFormInputChangedHandler}
            checkboxChangedHandler={checkboxChangedHandler}
            submitHandler={submitHandler}
            submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
            buttonText={superAdmin ? translations["save"] || 'save' : translations["next"] || 'next'}
            submitButtonStyle={userFormValid ? { color: 'white' } : null}
        />
    )
}

export default withStyles(styles)(AdminCreate);




