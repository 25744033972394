import React, { useState, useEffect } from 'react';

import Form from '../../np-form/Form';
import { Dialog, withStyles } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';

import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});

const updateFormState = props => {
    const { plans, billingAccount } = props;
    return {
        PlanIDs: {
            elementType: 'multi-select',
            elementConfig: {
                isMulti: true,
                closeMenuOnSelect: true,
                components: animatedComponents,
                type: 'multi-select',
                placeholder: 'Select account plans',
                label: 'Select account plans',
                margin: "dense",
                fullWidth: true,
                options: plans ? plans : [],
                selected: billingAccount && billingAccount[`accountPlans`] ? billingAccount[`accountPlans`].list.map(plan => plan.PlanRowID) : [],
            },
            formHelperText: "Please select account plans",
            value: billingAccount && billingAccount[`accountPlans`] ? billingAccount[`accountPlans`].list.map(plan => plan.PlanRowID) : [],
            validation: {
                required: true
            },
            valid: true,
            touched: true
        }
    }
};

const BillingAccountPlansEdit = props => {
    const { open, plans, billingAccount, classes, activeCustomer } = props;

    const { handlePlansEdit, assignPlans, modifyPlansState } = props;
    const [billingAccountForm, setBillingAccountForm] = useState(updateFormState({ plans, billingAccount }));

    useEffect(() => {
        setBillingAccountForm(updateFormState({ plans, billingAccount }));
    }, [plans, billingAccount]);

    const [formIsValid, setFormIsValid] = useState(false);

    const getMultiSelectData = data => {
        const updateState = formUtility.getNewStateOnMultiSelectChange(data, 'PlanIDs', billingAccountForm);
        setBillingAccountForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, billingAccountForm);
        setBillingAccountForm(updateState.myForm);
    }


    const submitHandler = async event => {
        event.preventDefault();
        const formData = formUtility.getFormData(billingAccountForm);
        formData['AccountID'] = !billingAccount.AccountID ? modifyPlansState ? modifyPlansState.billingAccountID : null : billingAccount.AccountID;
        await assignPlans({ ...formData, ActiveCustomerID: activeCustomer });
        await handlePlansEdit({ ActiveCustomerID: activeCustomer });
        setBillingAccountForm(updateFormState({}));
        setFormIsValid(false);
    };

    const cancelHandler = () => {
        setBillingAccountForm(updateFormState({}));
        setFormIsValid(false);
        handlePlansEdit();
    };

    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            fullWidth={true}
            // onClose={cancelHandler}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    cancelHandler();
                }
            }}
        >
            <Form
                formIsValid={formIsValid}
                classes={classes}
                form={billingAccountForm}
                formTitle="Update account plans"
                getMultiSelectData={getMultiSelectData}
                submitHandler={submitHandler}
                cancelHandler={cancelHandler}
                inputChangedHandler={inputChangedHandler}
                buttonTextCancel={'Cancel'}
                buttonText={props.hasPermission('billing_account_assign_plans_save') ? "Save" : null}
                submitButtonStyle={formIsValid ? { color: 'white' } : null}
            />
        </Dialog>
    )
};

export default withStyles(styles)(BillingAccountPlansEdit);