import React, { useState, useEffect } from 'react';
import { Grid, Select, Input, withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import FormControl from '@material-ui/core/FormControl';

import * as actions from './store/loggersActions';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2)
    }
});

const LogLevel = props => {
    const { classes, loadedPlugins, onLoadPlugins, logLevels, onSavePluginLevel, hasLoggersPermission } = props;

    // List of plugins, one item: {name: 'Plugin name', level: 'debug'}
    const [plugins, setPlugins] = useState([]);

    useEffect(() => {
        //Loading plugins on loading of the page
        onLoadPlugins({});
    }, [onLoadPlugins]);

    useEffect(() => {
        if (loadedPlugins && loadedPlugins.length > 0) {
            setPlugins(loadedPlugins);
        }
    }, [loadedPlugins]);

    const handleChange = name => event => {
        // Update local plugin state
        const newPlugins = [...plugins];
        newPlugins.forEach(newPlugin => {
            if (newPlugin.name === name) {
                newPlugin.level = event.target.value;
            }
        });
        onSavePluginLevel({ PluginName: name, Level: event.target.value });
        setPlugins(newPlugins);
    };

    return hasLoggersPermission ? (
        <div className={classes.root}>
            <Grid container spacing={10} justify="center" direction="row" alignItems="center">
                <Grid className={classes.item} item lg={7} md={7} sm={9} xs={10}>
                    <Paper style={{ width: '100%', overflowX: 'auto' }}>
                        <Table key={1} style={{ minWidth: 700 }}>
                            <TableHead>
                                <TableRow key={2}>
                                    <TableCell>Plugin Name</TableCell>
                                    <TableCell align="right">Level</TableCell>
                                    <TableCell align="right">Setings</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {plugins.map(plugin => (
                                    <TableRow key={plugin.name}>
                                        <TableCell>{plugin.name}</TableCell>
                                        <TableCell align="right">{plugin.level}</TableCell>
                                        <TableCell align="right" key={plugin}>
                                            <FormControl>
                                                <Select
                                                    value={plugin.level}
                                                    onChange={handleChange(plugin.name, plugin.level)}
                                                    inputProps={{
                                                        name: 'name',
                                                        id: 'demo-controlled-open-select'
                                                    }}
                                                    input={<Input name="agent" id="agent-helper" />}
                                                >
                                                    {logLevels.map(val => (
                                                        <MenuItem key={val} value={val} style={{ width: '200px' }}>
                                                            {val}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    ) : null;
};

const mapStateToProps = state => {
    return {
        hasLoggersPermission: state.core.login.permissions.includes('core_loggers_view') || state.core.login.permissions.includes('*'),
        loadedPlugins: state.core.loggers.plugins,
        logLevels: state.core.loggers.logLevels
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadPlugins: data => dispatch(actions.loadPlugins(data)),
        onSavePluginLevel: data => dispatch(actions.savePluginLevel(data))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(LogLevel));
