import * as actionTypes from './actionTypes';

const initialState = {
    notifications: []
};

const enqueueSnackbar = (state, action) => {
    // Set default anchor origin if not defined
    if (!action.notification.options.anchorOrigin) {
        action.notification.options.anchorOrigin = { vertical: 'bottom', horizontal: 'right' }

    }
    return {
        ...state,
        notifications: [
            ...state.notifications,
            {
                ...action.notification
            }
        ]
    };
};

const removeSnackbar = (state, action) => {
    return {
        ...state,
        notifications: state.notifications.filter(notification => notification.key !== action.key)
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // Fetching user
        case actionTypes.ENQUEUE_SNACKBAR:
            return enqueueSnackbar(state, action);
        case actionTypes.REMOVE_SNACKBAR:
            return removeSnackbar(state, action);

        default:
            return state;
    }
};

export default reducer;
