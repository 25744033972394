import React, { useState, useEffect } from 'react';

import Form from '../../np-form/Form';
import { Dialog, withStyles } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});

const updateFromState = props => {
    const { classes, applicant } = props;
    return {
        FirstName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'First name',
                label: 'First name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                autoFocus: true
            },
            value: applicant ? applicant.FirstName : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        LastName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Last name',
                label: 'Last name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                autoFocus: true

            },
            value: applicant ? applicant.LastName : null,
            validation: {
                required: true
            },
            valid: true,
            touched: true
        }
    }
};

const ApplicantEdit = props => {
    const { open, classes, loading, applicant, activeCustomer } = props;

    const { editApplicant, handleEdit } = props;
    const [applicantForm, setApplicantForm] = useState(updateFromState({ ...props }));
    const [formIsValid, setFormIsValid] = useState(false);

    useEffect(() => {
        setApplicantForm(updateFromState({ ...props }));
    }, [props]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, applicantForm);
        setApplicantForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const submitHandler = event => {
        event.preventDefault();
        const formData = formUtility.getFormData(applicantForm);
        formData['ApplicantID'] = applicant.UserID;
        editApplicant({ ...formData, ActiveCustomerID: activeCustomer });
        handleEdit();
        setApplicantForm({});
        setFormIsValid(false);
    };

    const cancelHandler = () => {
        setApplicantForm({});
        setFormIsValid(false);
        handleEdit();
    };

    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            fullWidth={true}
            // onClose={cancelHandler}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    cancelHandler();
                }
            }}
        >
            <Form
                classes={classes}
                form={applicantForm}
                formTitle={`Edit applicant - ${applicant ? applicant.FirstName : ''}`}
                buttonText={props.hasPermission('applicant_save') ? "Save" : null}
                buttonTextCancel="Cancel"
                submitHandler={submitHandler}
                cancelHandler={cancelHandler}
                inputChangedHandler={inputChangedHandler}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                submitButtonStyle={formIsValid && !loading ? { color: 'white' } : null}
                cancelButtonDisabled={loading}
                formIsValid={formIsValid}
            />
        </Dialog>
    )
};

export default withStyles(styles)(ApplicantEdit);