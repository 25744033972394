import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import NPDatatable from '../../np-components/Datatable';
import { Tooltip, IconButton, LinearProgress, CircularProgress, Paper } from '@material-ui/core';
import useListFilter from '../../np-components/Datatable/listFilter';
import { isSuperUser, formatDate } from '../../np-utilities';
import { CloudDownload, Delete, Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import * as action from '../BinaryImages/store/binaryImageActions';

import * as actions from './store/msqtToolsActions';
import MSQTToolCreate from './MSQTToolCreate';
import { downloadFile } from '../../ms-utilities';
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';
import MSQTToolEdit from './MSQTToolEdit';

const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#e62440" : "#7dc5e2",
        '&:hover': {
            backgroundColor: "#757575",
        },
    },
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
    progressBarr: {
        width: '100%',
        height: 5
    }
});

const MSQTTools = props => {
    const { classes, tools, onFetchMSQTTools, columnsState, activeCustomer, translations } = props;
    const toolsCount = tools.count;
    const toolsList = tools.list;
    const fetching = tools.fetching;
    let tableID = 'MSQTTool';
    let visible = columnsState && columnsState[tableID];

    const columns = [
        {
            displayName: translations["action"] || 'Action',
            backendName: 'Action',
            options: {
                align: 'left',
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: translations["name"] || 'Name',
            backendName: 'Name',
            options: {
                align: 'left',
                visible: visible ? visible['Name'] : true,
            }
        },
        {
            displayName: translations["tool_type"] || 'Tool type',
            backendName: 'Status',
            options: {
                align: 'left',
                visible: visible ? visible['Status'] : true,
            }
        },
        {
            displayName: translations["created_at"] || 'Created at',
            backendName: 'CreatedAt',
            options: {
                align: 'left',
                visible: visible ? visible['CreatedAt'] : true,
            }
        },
        {
            displayName: translations["created_by"] || 'Created by',
            backendName: 'CreatedBy',
            options: {
                align: 'left',
                visible: visible ? visible['CreatedBy'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => { if (column.backendName !== 'Action') backendNames.push(column.backendName); return null; })
    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });
    if (!props.hasPermission('msqt_tool_load') && !props.hasPermission('msqt_tool_delete') && !props.hasPermission('msqt_tool_download')) {
        columns.splice(0, 1);
    }
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const handleCreateModalOpen = () => {
        setCreateModalOpen(!createModalOpen);
    }

    const [editModalState, setEditModalState] = useState({ open: false });

    const handleEditModal = async event => {
        if (!event) {
            setEditModalState({ open: false, msqtTool: null });
            return;
        }

        await props.onLoadMSQTTool({ MSQTToolID: event.currentTarget.value, ActiveCustomerID: activeCustomer });
        setEditModalState({ open: true });
    }

    const [downloading, setDownloading] = useState({});

    const downloadTool = async (toolID, name) => {
        setDownloading({ [toolID]: true });
        let toolData = await props.onDownload(toolID, activeCustomer, name);
        if (toolData) {
            setDownloading({ [toolID]: false });
            return downloadFile(toolData, name);
        }
        setDownloading({ [toolID]: false });
        props.sendMessage(translations["could_not_find_tool"] || 'Could not find tool!', 'error');
    };

    const deleteTool = async event => {
        let toolID = event.currentTarget.value;
        await props.deleteMSQTTool({ MSQTToolID: toolID, ActiveCustomerID: activeCustomer });
    }

    const createActions = (toolID, name) => {
        return <>
            {props.hasPermission('msqt_tool_load') ?
                <Tooltip title={translations['edit_msqt_tool'] || "Edit msqt tool"}>
                    <IconButton value={toolID} aria-label={translations['edit_msqt_tool'] || "Edit msqt tool"} onClick={handleEditModal}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                : null}
            {props.hasPermission('msqt_tool_download') ?
                <Tooltip title={downloading[toolID] ? `${translations['loading'] || "Loading"}..` : translations['download_tool'] || "Download tool"}>
                    <IconButton value={toolID} aria-label={downloading[toolID] ? `${translations['loading'] || "Loading"}..` : translations['download_tool'] || "Download tool"} onClick={() => { if (downloading[toolID]) return null; else return downloadTool(toolID, `${name}`) }}>
                        {downloading[toolID] ? <CircularProgress variant='static' value={tools.downloadProgress} size={25} /> : <CloudDownload />}
                    </IconButton>
                </Tooltip>
                : null}
            {props.hasPermission('msqt_tool_delete') ?
                <Tooltip title={translations['delete_tool'] || "Delete tool"}>
                    <IconButton value={toolID} aria-label={translations['delete_tool'] || "Delete tool"} onClick={deleteTool}>
                        <Delete />
                    </IconButton>
                </Tooltip>
                : null}
        </>
    };

    useEffect(() => {
        if (listFilter.filter) {
            onFetchMSQTTools({ ...listFilter.filter, ActiveCustomerID: activeCustomer });
        }
    }, [activeCustomer, listFilter.filter, onFetchMSQTTools, tools.created, tools.deleted, tools.edited]);

    const tableOptions = {
        isSelectable: props.hasPermission('msqt_tool_delete'),
        tableSearch: false,
        tableID: 'MSQTTool',
        saveColumn: true,
        selectOptions: { createButtonName: translations['create_new'] || 'Create new', printing: false, generatingReport: false, deleting: props.hasPermission('msqt_tool_delete'), creating: props.hasPermission('msqt_tool_create') },
        rowsPerPage: 20,
        page: 0,
        count: toolsCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: translations["msqt_tool"] || 'MSQT Tools',
        noMatch: translations['table_no_match'] || "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onSaveFilter: (columns) => {
            let objToSendColumn = {}
            let objColumns = {}
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible
                return objColumns
            })
            objToSendColumn[`${tableID}`] = objColumns
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }
    };


    let counter = 0;
    let msqtTools = toolsList.map(tool => {
        let status = null;
        switch (tool.Status) {
            case 'test': status = 'Test'; break;
            case 'demo': status = 'Demo'; break;
            case 'release': status = 'Release'; break;
            default: status = 'Test'; break;
        }
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = tool.MSQTToolID;
        returnObj.visible = true;
        returnObj.values = [createActions(tool.MSQTToolID, tool.OriginalFileName), tool.ToolName, status, formatDate(tool.CreatedAt), tool.User.Username];
        if (!props.hasPermission('msqt_tool_load') && !props.hasPermission('msqt_tool_delete') && !props.hasPermission('msqt_tool_download')) {
            returnObj.values.splice(0, 1);
        }

        counter++;
        return returnObj;
    });

    return (
        <div className={classes.root}>
            <div className={classes.progressBarr}>
                {fetching && props.hasPermission('msqt_tool_list') ? <LinearProgress color="secondary" /> : null}
            </div>
            {props.hasPermission('msqt_tool_list') ?
                <Paper>
                    <NPDatatable translations={translations} customStyles={classes} handleCreate={handleCreateModalOpen} data={msqtTools} columns={columns} options={tableOptions} /></Paper>
                : null}
            {props.hasPermission('msqt_tool_create') ? <MSQTToolCreate translations={translations} activeCustomer={activeCustomer} createNewMSQTTool={props.createNewMSQTTool} sendMessage={props.sendMessage} open={createModalOpen} handleOpen={handleCreateModalOpen} /> : null}
            {props.hasPermission('msqt_tool_load') ? <MSQTToolEdit translations={translations} activeCustomer={activeCustomer} hasPermission={props.hasPermission} editMSQTTool={props.editMSQTTool} open={editModalState.open} handleEdit={handleEditModal} msqtTool={tools.loaded} /> : null}

        </div>
    );
}

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        translations: state.translate && state.translate.translations ? state.translate.translations.activeTranslations || {} : {},
        hasPermission: permission => state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'),
        tools: state.exportImport.msqtTools,
    };
};

const mapDispatchToProps = dispatch => {
    return {

        onFetchMSQTTools: filter => dispatch(actions.fetchMSQTTools(filter)),
        onLoadMSQTTool: filter => dispatch(actions.loadMSQTTool(filter)),
        createNewMSQTTool: data => dispatch(actions.createNewMSQTTool(data)),
        deleteMSQTTool: data => dispatch(actions.deleteMSQTTool(data)),
        onDownload: (data, id, name) => dispatch(action.downloadFileTool(data, id, name)),
        editMSQTTool: data => dispatch(actions.editMSQTTool(data)),

        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(MSQTTools));