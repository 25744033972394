import { getAxiosInstance } from "../../../np-rest/rest";
import restCrudActions from '../../../np-crud/store/restCrudActions'
import config from "../../../../config/config_default";
import { enqueueSnackbar } from '../../../np-dashboard/Notifier/store/notifierActions';

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: 'msqt-tools',
    pluginName: 'IMPORT_EXPORT',
    entityName: 'MSQT_TOOLS',
    fetchSuccessMessage: '',
    loadSuccessMessage: '',
});

const updateDownloadProgress = progress => {
    return {
        type: 'IMPORT_EXPORT|MSQT_TOOLS|DOWNLOAD_PROGRESS',
        downloadProgress: progress
    };
};

// Create MSQTTool  action creators
export const createMSQTToolInit = () => {
    return crudActions.createInit();
};
export const createMSQTToolStart = () => {
    return crudActions.createStart();
};
export const createMSQTToolSuccess = createdMSQTTool => {
    return crudActions.createSuccess(createdMSQTTool);
};
export const createMSQTToolFail = error => {
    return crudActions.createFail(error);
};
export const createMSQTTool = MSQTTool => {
    return async dispatch => {
        await crudActions.create(dispatch, MSQTTool);
    };
};


// Fetch msqt action creators
export const fetchMSQTToolInit = () => {
    return crudActions.fetchInit();
};
export const fetchMSQTToolStart = () => {
    return crudActions.fetchStart();
};
export const fetchMSQTToolSuccess = () => {
    return crudActions.fetchSuccess();
};
export const fetchMSQTToolFail = () => {
    return crudActions.fetchFail();
};
export const fetchMSQTTools = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
};

// Load MSQTTool action creators
export const loadMSQTToolInit = () => {
    return crudActions.loadInit();
};
export const loadMSQTToolStart = () => {
    return crudActions.loadStart();
};
export const loadMSQTToolSuccess = loadedMSQTTool => {
    return crudActions.loadSuccess(loadedMSQTTool);
};
export const loadMSQTToolFail = error => {
    return crudActions.loadFail(error);
};
export const loadMSQTTool = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

// Delete MSQTTool action creators
export const deleteMSQTToolInit = () => { return crudActions.deleteInit(); }
export const deleteMSQTToolStart = () => { return crudActions.deleteStart(); }
export const deleteMSQTToolSuccess = deletedMSQTTool => { return crudActions.deleteSuccess(deletedMSQTTool); }
export const deleteMSQTToolFail = error => { return crudActions.deleteFail(error); }
export const deleteMSQTTool = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    }
}

export const createNewMSQTTool = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(createMSQTToolInit());
        try {
            dispatch(createMSQTToolStart());
            let res = await rest.post('msqt-tools/create', data);
            if (res.data.status === 200) {
                dispatch(createMSQTToolSuccess());

                dispatch(
                    enqueueSnackbar({
                        message: `${res && res.data && res.data.message ? res.data.message : 'Success '}`,
                        options: {
                            variant: 'success'
                        }
                    }));
                return res.data.status === 200;

            }
            else {
                dispatch(createMSQTToolFail());

                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : res.message}`,
                        options: {
                            variant: 'warning'
                        }
                    }));
                return false;
            }
        } catch (error) {
            dispatch(createMSQTToolFail());
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return false;
        }
    }
}

// Edit MSQTTool  action creators
export const editMSQTToolInit = () => {
    return crudActions.editInit();
};
export const editMSQTToolStart = () => {
    return crudActions.editStart();
};
export const editMSQTToolSuccess = editedMSQTTool => {
    return crudActions.editSuccess(editedMSQTTool);
};
export const editMSQTToolFail = error => {
    return crudActions.editFail(error);
};
export const editMSQTTool = MSQTTool => {
    return async dispatch => {
        await crudActions.edit(dispatch, MSQTTool);
    };
};

// Custom functions 
export const downloadFile = (ToolID, id) => {
    let token = localStorage.getItem('CORE|AUTH|TOKEN');
    if (token) {
        token = JSON.parse(token).token;
    }
    return async dispatch => {
        return fetch(`${config.backend_domain}:${config.backend_port}/msqt-tools/download?MSQTToolID=${ToolID}&ActiveCustomerID=${id}&Token=${token}`, {
            method: "GET",
        }).then(async response => {
            if (response.status === 200) {
                const reader = response.body.getReader();

                const contentLength = +response.headers.get('fileSize');
                let receivedLength = 0;
                let chunks = [];
                while (true) {
                    const { done, value } = await reader.read();

                    if (done) {
                        break;
                    }
                    chunks.push(value);
                    receivedLength += value.length;
                    dispatch(updateDownloadProgress(Math.floor(receivedLength / contentLength * 100)));
                }
                return new Blob(chunks);
            }
            return null;
        });
    }
}