import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';

import NPDatatable from '../../np-components/Datatable';
import {Paper, Tooltip, IconButton} from '@material-ui/core';
import useListFilter from '../../np-components/Datatable/listFilter';
import {Edit, Delete} from '@material-ui/icons';
import {isSuperUser} from '../../np-utilities';
import {parseURL, BackButton} from '../../ms-utilities';

const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#d50000" : "#1b2a33",
        '&:hover': {
            backgroundColor: "#757575",
        },
    },
    chipLanguageActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    chipLanguageInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    media: {
        width: 50,
        height: 50
    }
});

const updateFilterParams = props => {
    const {listFilter} = props;
    let searchParams = parseURL(window.location.search);
    return {...listFilter, ...searchParams};
};

const LanguageList = props => {
    const {classes, languages, onFetchLanguages, columnsState, activeCustomer} = props;
    const languagesCount = languages.count;
    const languagesList = languages.list;

    let tableID = 'Language';
    let visible = columnsState && columnsState[tableID];

    const columns = [
        {
            displayName: 'Action',
            backendName: 'Action',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: 'Name',
            backendName: 'Name',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Name'] : true,
            }
        },
        {
            displayName: 'Key',
            backendName: 'Key',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Description'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => {
        if (column.backendName !== 'Action') backendNames.push(column.backendName);
        return null;
    });
    const listFilter = useListFilter({rowsPerPage: 20, searchColumns: backendNames});

    if (!props.hasAtLeastOnePermission(['language_create', 'language_load', 'language_save', 'language_delete'])) {
        columns.splice(0, 1);
    }

    useEffect(() => {
        if (listFilter.filter) {
            if (!listFilter.filter.q) {
                listFilter.filter.q = {};
            }
            if (!listFilter.filter.q.and) {
                listFilter.filter.q.and = {};
            }
            listFilter.filter.q = {
                ...listFilter.filter.q,
                and: updateFilterParams({listFilter: listFilter.filter.q.and})
            };
            onFetchLanguages({...listFilter.filter, ActiveCustomerID: activeCustomer});
        }
    }, [languages.created, activeCustomer, languages.deleted, languages.bulkDeleted, languages.edited, listFilter.filter, onFetchLanguages]);


    const createActions = languageID => {
        return <>
            {
                props.hasPermission('language_save') ? <Tooltip title="Edit language">
                    <IconButton value={languageID} aria-label="Edit language" onClick={props.handleEdit}>
                        <Edit/>
                    </IconButton>
                </Tooltip> : <div></div>
            }
            {
                props.hasPermission('language_delete') ?
                    <Tooltip title="Delete language">
                        <IconButton value={languageID} aria-label="Delete language" onClick={props.handleDelete}>
                            <Delete/>
                        </IconButton>
                    </Tooltip> : <div></div>
            }
        </>
    };


    const tableOptions = {
        isSelectable: props.hasPermission('language_delete'),
        tableSearch: false,
        tableID: tableID,
        saveColumn: true,
        selectOptions: {
            printing: false,
            generatingReport: false,
            deleting: props.hasPermission('language_delete'),
            creating: props.hasPermission('language_create')
        },
        rowsPerPage: 20,
        count: languagesCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'Languages',
        noMatch: "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return props.handleBulkDelete(IDs);
        },
        onSaveFilter: (columns) => {
            let objToSendColumn = {};
            let objColumns = {};
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible;
                return objColumns
            });
            objToSendColumn[`${tableID}`] = objColumns;
            props.saveColumnsFilter({...objToSendColumn, ActiveCustomerID: activeCustomer})
        }
    };


    let counter = 0;
    let languagesData = languagesList.map(language => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = language.LanguageID;
        returnObj.visible = true;
        returnObj.values = [
            createActions(language.LanguageID),
            language.Name,
            language.Key,
        ];
        if (!props.hasAtLeastOnePermission(['language_create', 'language_load', 'language_delete', 'language_delete'])) {
            returnObj.values.splice(0, 1);
        }
        counter++;
        return returnObj;
    });

    return (
        <Paper>
            {BackButton()}
            <NPDatatable handleCreate={props.handleCreate} customStyles={classes} data={languagesData} columns={columns}
                         options={tableOptions}/>
        </Paper>
    );
}

export default withStyles(styles)(LanguageList);