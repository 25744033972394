/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useReducer } from 'react';

import Form from '../../np-form/Form';
import { withStyles, Paper, CircularProgress, Dialog, Typography, DialogContent } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';
import { connect } from 'react-redux';
import * as actions from './store/binaryImageActions';
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';
import { BackButton } from '../../ms-utilities';
import { useNavigate } from 'react-router-dom';
import { exampleValues } from '../../ms-utilities';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    }
});

const updateFormState = props => {
    const { classes, handleUpload, fileName, binaryImageType, translations } = props;
    const form = {
        MajorVersion: {
            elementType: "input",
            elementConfig: {
                type: "number",
                placeholder: translations["major_version"] || "Major version",
                label: translations["major_version"] || "Major version",
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: "",
            validation: {
                required: true,
                isNumber: true
            },
            valid: false,
            touched: true
        },
        MinorVersion: {
            elementType: "input",
            elementConfig: {
                type: "number",
                placeholder: translations["minor_version"] || "Minor version",
                label: translations["minor_version"] || "Minor version",
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: "",
            validation: {
                required: true,
                isNumber: true
            },
            valid: false,
            touched: true
        },
        BuildNumber: {
            elementType: "input",
            elementConfig: {
                type: "number",
                placeholder: translations["build_number"] || "Build number",
                label: translations["build_number"] || "Build number",
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: "",
            validation: {
                required: true,
                isNumber: true
            },
            valid: false,
            touched: true
        },
        BinaryImageTypeID: {
            elementType: "select_v2",
            elementConfig: {
                type: "select",
                label: translations["binary_image_type"] || "Binary Image Type",
                className: null,
                margin: "dense",
                fullWidth: true,
                options: binaryImageType ? [...binaryImageType] : []
            },
            inputLabel: translations["binary_image_type_select"] || "Please select binary image type",
            value: binaryImageType && binaryImageType.length > 0 ? binaryImageType[0].value : "",
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        Name: {
            elementType: "input",
            elementConfig: {
                type: "text",
                placeholder: translations["name"] || "Name",
                label: translations["name"] || "Name",
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: "",
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        BuildType: {
            elementType: "select_v2",
            elementConfig: {
                type: "select",
                label: translations["build_type"] || "Build type",
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [
                    { value: "beta", displayValue: translations["beta"] || "Beta" },
                    { value: "prod", displayValue: translations["production"] || "Production" }
                ]
            },
            inputLabel: translations["build_type_form_text_helper"] || "Please choose build type",
            value: "beta",
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        Public: {
            elementType: "select_v2",
            elementConfig: {
                type: "select",
                label: translations["public"] || "Public",
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [
                    { value: "yes", displayValue: translations["yes"] || "Yes" },
                    { value: "no", displayValue: translations["no"] || "No" }
                ]
            },
            inputLabel: translations["is_this_public_image"] || "Is this public binary image?",
            value: "yes",
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        Active: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: translations['active'] || 'Active',
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [
                    { value: 'active', displayValue: translations['active'] || 'Active' },
                    { value: 'inactive', displayValue: 'Inactive' }
                ]
            },
            inputLabel: translations['is_this_active_image'] || "Is this active binary image?",
            value: 'active',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        ReleaseNotes: {
            elementType: "editor",
            elementConfig: {
                type: "text",
                placeholder: "Release notes",
                label: "Release notes",
                editorToolbarHiddenTextarea: true,
                exampleValues: exampleValues
            },
            value: "",
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        BinaryFile: {
            elementType: "file",
            elementConfig: {
                ID: "binaryFile",
                accept: "*",
                style: {},
                type: "file",
                handleUpload: handleUpload,
                fileName: fileName,
                text: translations["choose_file"] || "Choose file"
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true
        }
    };
    return form;
};
const BinaryImageCreate = props => {
    const { classes, activeCustomer, binaryImageType, onClearState, translations, onFetchBinaryImageTypes, images } = props;
    const navigate = useNavigate();
    const [fileToSend, setFileToSend] = useState({});
    const [fileName, setFilename] = useState("");

    const [redirectOptions, setRedirectOptions] = useState({ redirect: false, redirectPath: "" });

    const handleUpload = event => {
        event.preventDefault();
        let file = event.target.files[0];
        if (!file) {
            file = {};
        }
        if (file.size > 5368709120) {
            file = {};
            return props.sendMessage(translations['choose_binary_size'] || 'Please choose binary file smaller than 5GB', 'warning')
        }
        setFileToSend(file);
        setFilename(file.name);
    };

    const [formIsValid, setFormIsValid] = useState(false);
    const [importForm, dispatch] = useReducer(
        binaryImageReducer,
        updateFormState({ ...props, fileName: fileName, handleUpload: handleUpload })
    );

    useEffect(() => {
        const fetchBinaryImageTypes = async () => {
            await onFetchBinaryImageTypes(activeCustomer);
        };
        fetchBinaryImageTypes();
        return () => {
            onClearState()
        };
    }, [onFetchBinaryImageTypes, activeCustomer]);

    useEffect(() => {
        dispatch({ type: "form", form: updateFormState({ ...props, handleUpload: handleUpload }) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [binaryImageType]);

    useEffect(() => {
        if (fileName) {
            dispatch({ type: "filename", fileName: fileName });
        }
    }, [fileName]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, importForm);
        dispatch({ type: "form", form: updateState.myForm });
        setFormIsValid(updateState.formIsValid);
        if (updateState?.myForm?.BinaryImageTypeID?.value === "") {
            setFormIsValid(false);
        }
    };
    const getData = data => {
        const updatedState = formUtility.getNewStateOnEditorChange(data, "ReleaseNotes", importForm);
        // Set the new state
        dispatch({ type: "form", form: updatedState.myForm });
        setFormIsValid(updatedState.formIsValid);
    };

    const checkboxChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnCheckboxChange(event, inputIdentifier, importForm);
        dispatch({ type: "form", form: updateState.myForm });
        setFormIsValid(updateState.formIsValid);
    };

    const submitHandler = async event => {
        event.preventDefault();
        const formData = formUtility.getFormData(importForm);
        if (!fileName) {
            return props.sendMessage(translations["choose_binary"] || "Please choose binary file.", "warning");
        }

        const data = new FormData();
        data.append("File", fileToSend);
        data.append("FileName", fileName);
        data.append("MajorVersion", formData.MajorVersion);
        data.append("MinorVersion", formData.MinorVersion);
        data.append("BuildNumber", formData.BuildNumber);
        data.append("BinaryImageTypeID", formData.BinaryImageTypeID);
        data.append("Name", formData.Name);
        data.append("BuildType", formData.BuildType);
        data.append("Public", formData.Public === "yes" ? true : false);
        data.append('Active', formData.Active === 'active' ? true : false);
        data.append("ReleaseNotes", formData.ReleaseNotes);
        data.append("ActiveCustomerID", activeCustomer);
        let token = localStorage.getItem("CORE|AUTH|TOKEN");
        if (token) {
            token = JSON.parse(token).token;
        }
        data.append("Token", token);
        await props.createNewBinaryImage(data);

        setRedirectOptions({ redirect: true, redirectPath: `/downloads/binary-images` });
    };
    const cancelHandler = () => {
        navigate(-1);
    };
    

    useEffect(()=>{
        if (redirectOptions.redirect) {
            navigate(redirectOptions.redirectPath);
        }
    },[redirectOptions])

    return (
        <div className={classes.root}>
            <Paper>
                {BackButton(translations)}
                <Form
                    formIsValid={formIsValid}
                    classes={classes}
                    form={importForm}
                    getData={getData}
                    buttonsStyle={{
                        paddingBottom: "5px",
                        marginTop: "2px"
                    }}
                    formTitle={translations["create_new_software"] || "Create new software"}
                    buttonText={translations["create"] || "Create"}
                    buttonTextCancel={translations["cancel"] || "Cancel"}
                    cancelHandler={cancelHandler}
                    submitHandler={submitHandler}
                    inputChangedHandler={inputChangedHandler}
                    submitButtonColor={isSuperUser() ? "secondary" : "primary"}
                    checkboxChangedHandler={checkboxChangedHandler}
                />
            </Paper>
            <Dialog 
                style={{minWidth: 'max-content'}}
                // disableBackdropClick={true}
                disableEscapeKeyDown={false}
                open={images.snackStart}
            >
                <DialogContent style={{display:'grid', justifyContent:'center', alignItems:'center'}}>
                    <CircularProgress
                        variant="determinate"
                        color="secondary"
                        style={{ marginBottom: "10px", padding: '50px', paddingBottom: '20px' }}
                        size={200}
                        value={images.progress ? images.progress : 0}
                    />
                    <Typography
                        style={{ marginTop: "5px", color: "#9197a3", position: "absolute", top: "40%", right: "0", textAlign: "center", left: "15px" }}
                        variant="h4"
                    >
                        {images.progress ? images.progress : 0}%
                    </Typography>
                    <Typography style={{ marginTop: "5px", color: "#9197a3", fontSize: 'small'}}>
                        Please do not refresh this page. Uploading {images.loadSize ? images.loadSize : 0} / {images.maxSize ? images.maxSize : 0}MB
                    </Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const binaryImageReducer = (state, action) => {
    switch (action.type) {
        case "filename":
            return { ...state, BinaryFile: { ...state.BinaryFile, elementConfig: { ...state.BinaryFile.elementConfig, fileName: action.fileName } } };
        case "form":
            return action.form;
        default:
            throw new Error({ message: "Something went wrong. Please contact administrator." });
    }
};

const mapStateToProps = state => {
    return {
        images: state.exportImport.binaryImages,
        binaryImageType: state.exportImport.binaryImages ? state.exportImport.binaryImages.binaryImageTypes : {},
        isSuperUser: state.core.login.permissions.includes("*"),
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        translations: state.translate && state.translate.translations ? state.translate.translations.activeTranslations || {} : {},
        hasPermission: permission => {
            return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes("*");
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchBinaryImageTypes: id => dispatch(actions.listBinaryImageTypes(id)),
        createNewBinaryImage: data => dispatch(actions.createNewBinaryImage(data)),
        onClearState: () => dispatch(actions.ClearStateCreate()),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(BinaryImageCreate));
