import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/userActions';
import { NavLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import SupervisorIcon from '@material-ui/icons/SupervisorAccount';
import Keyicon from '@material-ui/icons/VpnKey';
import IconButton from '@material-ui/core/IconButton';
import NPDatatable from '../../../np-components/Datatable';
import { Navigate, Route } from 'react-router';
import queryParser from 'query-string-parser';
import ConfirmationDialog from '../../../np-components/ConfirmationDialog/ConfirmationDialog';
import useListFilter from '../../../np-crud/listFilter';
import { Grid, withStyles, Paper, LinearProgress } from '@material-ui/core';

import * as authActions from '../../Auth/store/authActions';
// import './UserList.scss';

const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        '&:hover': {
            backgroundColor: '#757575'
        }
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },
    iconHover: {
        '&:hover': {
            color: '#99ccff'
        }
    }
});

// User List Component
const UserList = props => {
    const { userList, userCount, classes, hasEditPermission, onStartSupportSession, hasSupportPermission, loading, onFetchUsers, history, onDeleteUser, onBulkDelete } = props;

    // List Filter
    const urlOptions = queryParser.fromQuery(props.location.search);
    const listFilter = useListFilter({ searchColumns: ['FirstName', 'LastName', 'Username', 'Email'], ...urlOptions });

    // Delete dialog state
    const [deleteDialog, setDeleteDialog] = useState({ open: false, confirmed: false });
    // Data to be deleted
    const [deleteData, setDeleteData] = useState({});
    // Bulk delete dialog state
    const [bulkDeleteDialog, setBulkDeleteDialog] = useState({ open: false, confirmed: false });
    // Bulk data to be deleted
    const [bulkDeleteData, setBulkDeleteData] = useState({});

    const [redirectCreate, setRedirectCreate] = useState({ redirect: false, UserID: 0, redirectPath: '' });

    useEffect(() => {
        if (listFilter.filter) {
            onFetchUsers(listFilter.filter);
        }
    }, [listFilter.filter, onFetchUsers]);

    useEffect(() => {
        const query = queryParser.toQuery(listFilter.options);
        history.push('?' + query);
    }, [listFilter.options, history]);

    // Delete user if dialog confirmed
    useEffect(() => {
        const deleteUser = async () => {
            // Run delete
            if (deleteData !== {}) {

                await onDeleteUser(deleteData)


                // Init state
                setDeleteDialog({ open: false, confirmed: false });
                // Fetch issues
                await onFetchUsers(listFilter.filter)
                return setDeleteData({});
            }
        };

        if (deleteDialog.confirmed) {
            deleteUser();
        }

    }, [deleteDialog.confirmed, onDeleteUser, onFetchUsers, listFilter.filter, deleteData]);



    // Bulk delete users if dialog confirmed
    useEffect(() => {

        const bulkDeleteUsers = async () => {
            if (bulkDeleteData !== {}) {

                // Run bulk delete
                await onBulkDelete(bulkDeleteData);
                // Init bulk delete state
                // Hide dialog
                setBulkDeleteDialog({ open: false, confirmed: false });
                // Fetch issues
                await onFetchUsers(listFilter.filter);
                return setBulkDeleteData({});
            }
        };

        if (bulkDeleteDialog.confirmed) {
            // Bulk delete issues
            bulkDeleteUsers();
        }
    }, [bulkDeleteDialog.confirmed, onFetchUsers, onBulkDelete, bulkDeleteData, listFilter.filter]);

    // Bulk delete users


    // Delete handler
    const handleDelete = UserID => {
        // Set data to be deleted
        setDeleteData({ UserID: parseInt(UserID) });
        // Show delete dialog
        setDeleteDialog({ open: true, confirmed: false });
    };

    // Bulk delete handler
    const handleBulkDelete = ids => {
        setBulkDeleteData({ UserIDs: ids });
        // Show bulk delete dialog
        setBulkDeleteDialog({ open: true, confirmed: false });
    };

    const handleSupportSession = id => {
        onStartSupportSession({ UserId: id });
    };

    const createActions = user => {
        return (
            <>
                {hasEditPermission ? (
                    <div>
                        {hasSupportPermission ? (
                            <Tooltip title={'Start support session'}>
                                <IconButton
                                    onClick={() => handleSupportSession(user.UserID)}
                                    style={{ color: '#D71C1C' }}
                                    fontSize="small"
                                    variant="contained"
                                    color="secondary"
                                >
                                    <SupervisorIcon variant="contained" color="primary" fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        ) : null}

                        <Tooltip title={'Edit'}>  
                                <IconButton>
                                <NavLink
                                className="navlinkStyle"
                                to={{
                                    pathname: props.match.path + '/' + user.UserID + '/edit',
                                    state: {
                                        page: listFilter.options.page,
                                        filter: listFilter.filter   } }}>
                                    <EditIcon variant="contained" color="primary" fontSize="small" />
                                    </NavLink>
                                </IconButton>
                        </Tooltip>
                        <Tooltip title={'Change password'}>
                                <IconButton>
                                <NavLink className="navlinkStyle" to={props.match.path + '/' + user.UserID + '/password/edit'}>
                                    <Keyicon variant="contained" style={{ color: '#CEC81E' }} fontSize="small" />
                                 </NavLink>
                                </IconButton>

                        </Tooltip>
                        <Tooltip title={'Delete'}>
                            <IconButton
                                onClick={() => handleDelete(user.UserID)}
                                style={{ color: '#D71C1C' }}
                                fontSize="small"
                                variant="contained"
                                color="secondary"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                ) : null}
            </>
        );
    };

    let counter = 0;
    let userListData = userList.map(user => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = user.UserID;
        returnObj.visible = true;
        returnObj.values = [
            createActions(user),
            user.FirstName ? user.FirstName : '',
            user.LastName ? user.LastName : '',
            user.Username ? user.Username : '',
            user.Email ? user.Email : ''
        ];
        counter++;
        return returnObj;
    });

    const columns = [
        {
            displayName: 'Actions',
            backendName: 'Actions',
            options: {
                align: 'left',
                visible: true
            }
        },
        {
            displayName: 'First name',
            backendName: 'FirstName',
            options: {
                align: 'left',
                sortable: true,
                visible: true
            }
        },
        {
            displayName: 'Last name',
            backendName: 'LastName',
            options: {
                align: 'right',
                sortable: true,
                visible: true
            }
        },
        {
            displayName: 'Username',
            backendName: 'Username',
            options: {
                align: 'left',
                sortable: true,
                visible: true
            }
        },
        {
            displayName: 'Email',
            backendName: 'Email',
            options: {
                align: 'left',
                sortable: true,
                visible: true
            }
        }
    ];

    const tableOptions = {
        isSelectable: hasEditPermission,
        tableSearch: true,
        selectOptions: {
            printing: false,
            generatingReport: false,
            deleting: hasEditPermission,
            creating: hasEditPermission
        },
        rowsPerPage: 10,
        count: userCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'User list',
        noMatch: 'Sorry, no matching records found',
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return handleBulkDelete(IDs);
        }
    };

    if (redirectCreate.redirect) {
        return <Route path="*" element={<Navigate to={redirectCreate.redirectPath} />} />;
    }

    // Render
    return (
        <div className={classes.root}>
            <Grid container spacing={10} justify="center" direction="row" alignItems="center">
                <Grid className={classes.item} item xs={12} md={11} sm={12} lg={10}>
                    {loading ? <LinearProgress color="secondary" /> : null}
                    <Paper>
                        <NPDatatable
                            handleCreate={() => {
                                setRedirectCreate({ redirect: true, redirectPath: `/users/create` });
                            }}
                            customStyles={classes}
                            data={userListData}
                            columns={columns}
                            options={tableOptions}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <ConfirmationDialog
                isOpen={deleteDialog.open}
                title="Delete role"
                contentText="Are you sure to delete this user?"
                onYes={() => setDeleteDialog({ open: false, confirmed: true })}
                onCancel={() => setDeleteDialog({ open: false, confirmed: false })}
                textYes="Delete"
            />
            <ConfirmationDialog
                isOpen={bulkDeleteDialog.open}
                title="Delete all roles"
                contentText="Are you sure to delete all selected users?"
                onYes={() => setBulkDeleteDialog({ open: false, confirmed: true })}
                onCancel={() => setBulkDeleteDialog({ open: false, confirmed: false })}
                textYes="Delete"
            />
        </div>
    );
};

const mapStateToProps = state => {
    return {
        hasEditPermission: state.core.login.permissions.includes('*') || state.core.login.permissions.includes('core_user_edit'),
        userCount: state.core.user.count,
        userList: state.core.user.list,
        loading: state.core.user.fetching,
        snackbar: state.snackbar,
        hasSupportPermission: state.core.login.permissions.includes('*')
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchUsers: filter => dispatch(actions.fetchUsers(filter)),
        onDeleteUser: data => dispatch(actions.deleteUser(data)),
        onBulkDelete: data => dispatch(actions.bulkDeleteUsers(data)),
        onStartSupportSession: data => dispatch(authActions.startSupportSession(data))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(UserList));
