import React, { useState, useEffect } from 'react';

import Form from '../../np-form/Form';
import { Dialog, withStyles } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});

const updateFormState = props => {
    const { binaryImageType, classes, translations } = props;
    const form = {
        Key: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['key'] || 'Key',
                label: translations['key'] || 'Key',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                autoFocus: true
            },
            value: binaryImageType ? binaryImageType.Key : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['name'] || 'Name',
                label: translations['name'] || 'Name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: binaryImageType ? binaryImageType.Name : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        }
    };

    return form;
}
const BinaryImageTypeEdit = props => {
    const { open, classes, binaryImageType, activeCustomer, translations } = props;

    const [binaryImageTypeForm, setBinaryImageTypeForm] = useState(updateFormState({ ...props }));
    const [formIsValid, setFormIsValid] = useState(false);

    useEffect(() => {
        setBinaryImageTypeForm(updateFormState({ ...props }));
    }, [props]);



    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, binaryImageTypeForm);
        setBinaryImageTypeForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    }

    const submitHandler = event => {
        event.preventDefault();
        const formData = formUtility.getFormData(binaryImageTypeForm);
        formData['BinaryImageTypeID'] = binaryImageType.BinaryImageTypeID;
        setBinaryImageTypeForm(updateFormState({ classes, translations }));
        setFormIsValid(false);
        props.editBinaryImageType({ ...formData, ActiveCustomerID: activeCustomer });
        props.handleEdit();
    }

    const cancelHandler = () => {
        props.handleEdit();
        setBinaryImageTypeForm(updateFormState({ classes, translations }));
        setFormIsValid(false);

    }
    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            fullWidth={true}
            // onClose={cancelHandler}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    cancelHandler();
                }
            }}
        >
            <Form
                formIsValid={formIsValid}
                classes={classes}
                form={binaryImageTypeForm}
                formTitle={translations['edit_binary_image_type'] || 'Edit binary image type'}
                buttonText={props.hasPermission('binary_image_type_save') ? translations['save'] || "Save" : null}
                buttonTextCancel={translations['cancel'] || "Cancel"}
                submitHandler={submitHandler}
                cancelHandler={cancelHandler}
                inputChangedHandler={inputChangedHandler}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                submitButtonStyle={formIsValid ? { color: 'white' } : null}

            />
        </Dialog>
    )
}


export default withStyles(styles)(BinaryImageTypeEdit);