import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route } from 'react-router';
import { Grid, Paper, withStyles } from '@material-ui/core';

import * as actions from './store/userActions';
import Form from '../../np-form/Form';
import * as formUtility from '../../np-form/utility';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,

        padding: theme.spacing(2),
        display: 'flex',
        flexWrap: 'wrap'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center'
    },
    form: {
        padding: theme.spacing(1)
    }
});
const UserEditPassword = props => {
    const { loaded, classes, onLoadUser } = props;
    const [UserID, setUserID] = useState({});
    const [matching, setMatching] = useState('');
    const [userForm, setUserForm] = useState({});
    const [formIsValid, setFormIsValid] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const userId = props.match.params.id;

    useEffect(() => {
        onLoadUser({ UserID: userId });
        setUserID(userId);
    }, [userId, onLoadUser]);

    useEffect(() => {
        if (loaded !== null) {
            setUserForm({
                newPassword: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'password',
                        placeholder: 'New Password',
                        label: 'New password'
                    },
                    value: '',
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                },
                confirmPassword: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'password',
                        placeholder: 'Confirm Password',
                        label: 'Confirm password'
                    },
                    value: '',
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                }
            });

            setFormIsValid(false);
            setMatching(true);
        }
    }, [loaded]);

    const submitHandler = event => {
        event.preventDefault();
        // Prepare the form data
        const formData = formUtility.getFormData(userForm);

        const userData = {
            UserID: UserID,
            Password: formData.newPassword
        };

        props.onEditUser(userData);
        setRedirect(true);
    };
    const cancelHandler = () => {
        setRedirect(true);
    };

    // Input change handler
    const inputChangedHandler = (event, inputIdentifier) => {
        // Get default new state based on the input change event
        const updatedState = formUtility.getNewStateOnInputChange(event, inputIdentifier, userForm);
        const newPassword = updatedState.myForm.newPassword.value;
        const confirmPassword = updatedState.myForm.confirmPassword.value;
        const matching = newPassword === confirmPassword;

        setUserForm(updatedState.myForm);
        setFormIsValid(updatedState.formIsValid && matching ? true : false);
        setMatching(matching);
    };

    if (redirect) {
        return <Route path="*" element={<Navigate to="/users" />} />;
    }

    // Render form
    return (
        <div className={classes.root}>
            <Grid container spacing={10} justify="center" direction="row" alignItems="center">
                <Grid item lg={4} md={5} sm={7} xs={10}>
                    <Paper className={classes.paper}>
                        <Form
                            validationMessage={matching ? '' : 'Passwords not matching!'}
                            inputChangedHandler={inputChangedHandler}
                            submitHandler={submitHandler}
                            form={userForm}
                            custom={classes.form}
                            formIsValid={formIsValid}
                            buttonText={'Save'}
                            buttonTextCancel={'Cancel'}
                            formTitle={'Change Password'}
                            cancelHandler={cancelHandler}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        loaded: state.core.user.loadedUser
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadUser: id => dispatch(actions.loadUser(id)),
        onEditUser: userData => dispatch(actions.editUser(userData))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(UserEditPassword));
