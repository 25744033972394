import * as actionTypes from './actionTypes';

export const enqueueSnackbar = notification => {
    if (localStorage.getItem('CORE|AUTH|TOKEN')) {
        return {
            type: actionTypes.ENQUEUE_SNACKBAR,
            notification: {
                key: new Date().getTime() + Math.random(),
                ...notification
            }
        }
    }
    return { type: '' };
}

export const enqueueSnackbarOnLogin = notification => {
    return {
        type: actionTypes.ENQUEUE_SNACKBAR,
        notification: {
            key: new Date().getTime() + Math.random(),
            ...notification
        }
    }

}

export const removeSnackbar = key => ({
    type: actionTypes.REMOVE_SNACKBAR,
    key
});
