import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {combineReducers} from 'redux';

// Reducers
import siteReducer from './Site/store/siteReducer';

// Components
import SiteListv2 from './Site';

// Routes
export const routes = basePath =>
    (
        <Routes>
            <Route path={basePath} element={<SiteListv2/>}/>
        </Routes>
    );

export const reducers = combineReducers({
    sites: siteReducer
});