import React, {useState, useEffect} from 'react';

import Form from '../../np-form/Form';
import {Dialog, withStyles} from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import {isSuperUser} from '../../np-utilities';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});

const updateFromState = props => {
    const {classes, language} = props;

    return {
        Name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Name',
                label: 'Name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                autoFocus: true
            },
            value: language ? language.Name : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Key: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Key',
                label: 'Key',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: language ? language.Key : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        }
    }
};

const LanguageEdit = props => {
    const {open, classes, loading, language, activeCustomer} = props;

    const {editLanguage, handleEdit} = props;
    const [LanguageForm, setLanguageForm] = useState(updateFromState({...props}));
    const [formIsValid, setFormIsValid] = useState(false);

    useEffect(() => {
        setLanguageForm(updateFromState({...props}));
    }, [props]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, LanguageForm);
        setLanguageForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const submitHandler = event => {
        event.preventDefault();
        const formData = formUtility.getFormData(LanguageForm);
        formData['LanguageID'] = language.LanguageID;

        editLanguage({...formData, ActiveCustomerID: activeCustomer});
        handleEdit();
        setLanguageForm({});
        setFormIsValid(false);
    };

    const cancelHandler = () => {
        setLanguageForm({});
        setFormIsValid(false);
        handleEdit();
    };

    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            fullWidth={true}
            // onClose={cancelHandler}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    cancelHandler();
                }
            }}
        >
            <Form
                classes={classes}
                form={LanguageForm}
                formTitle={`Edit language - ${language ? language.Name : ''}`}
                buttonText={props.hasPermission('language_save') ? "Save" : null}
                buttonTextCancel="Cancel"
                submitHandler={submitHandler}
                cancelHandler={cancelHandler}
                inputChangedHandler={inputChangedHandler}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                submitButtonStyle={formIsValid && !loading ? {color: 'white'} : null}
                cancelButtonDisabled={loading}
                formIsValid={formIsValid}
            />
        </Dialog>
    )
};

export default withStyles(styles)(LanguageEdit);