import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    withStyles
} from '@material-ui/core';

const styles = _theme => ({
    buttonDanger: {
        color: '#eceff1',
        backgroundColor: '#f44336'
    },
    buttonSafe: {
        color: '#eceff1',
        backgroundColor: '#4caf50'
    },
    root: {}, fab: {}, chipActive: {}, chipInactive: {}, paletteButtons: {}, media: {}
});

const SoftwareLicenseDelete = props => {
    let { classes, deleteModalState, entityName, handleDelete, activeCustomer } = props;

    const handleCloseSafe = () => {
        handleDelete();
    };

    const handleCloseDanger = async () => {
        if (deleteModalState.forBulk) {
            await props.onDeleteSoftwareLicense({ LicenseIDs: deleteModalState.softwareLicenseIDs, ActiveCustomerID: activeCustomer });
            handleDelete();
            return;
        }
        await props.onDeleteSoftwareLicense({ LicenseID: deleteModalState.softwareLicenseID, ActiveCustomerID: activeCustomer });
        handleDelete();
    };

    return (
        <Dialog
            open={deleteModalState.open}
            // onClose={handleCloseSafe}
            aria-labelledby="form-dialog-title"
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            fullWidth={true}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleCloseSafe();
                }
            }}
        >
            <DialogTitle id="form-dialog-title">Delete {entityName}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Be careful when deleting something.<br />
                    Are you sure you want to delete this {entityName} data?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseSafe} variant="contained" className={classes.buttonSafe}>
                    No
                </Button>
                <Button onClick={handleCloseDanger} variant="contained" className={classes.buttonDanger}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(SoftwareLicenseDelete);