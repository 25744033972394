import React, { useState, useEffect } from 'react';

import Form from '../../np-form/Form';
import { Dialog, withStyles, Stepper, StepLabel, Step, Button } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';
import moment from "moment";

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});

const currencies = [
    { value: 'eur', displayValue: '€ (EUR)' },
    { value: 'usd', displayValue: '$ (USD)' },
    { value: 'cad', displayValue: '$ (CAD)' },
    { value: 'rsd', displayValue: 'DIN (RSD)' },
    { value: 'bam', displayValue: 'KM (BAM)' }];

const planStatuses = [{ value: 'active', displayValue: 'Active' }, { value: 'inactive', displayValue: 'Inactive' }];
const planTypes = [{ value: 'default', displayValue: 'Default' }, { value: 'discount', displayValue: 'Discount' }];

const updateFormState = props => {
    const { classes } = props;
    return {
        Name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Plan name',
                label: 'Plan name',
                className: classes ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                autoFocus: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Description: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Description',
                label: 'Description',
                className: classes ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                multiline: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Type: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Plan type',
                margin: "dense",
                fullWidth: true,
                options: planTypes ? [...planTypes] : []
            },
            formHelperText: "Please select plan type",
            value: planTypes && planTypes.length > 0 ? planTypes[0].value : '',
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        Status: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Billing account status',
                margin: "dense",
                fullWidth: true,
                options: planStatuses ? [...planStatuses] : []
            },
            formHelperText: "Please select billing account status",
            value: 'active',
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        ActivationDate: {
            elementType: 'datePicker',
            elementConfig: {
                type: 'datePicker',
                label: 'Activation date',
                margin: "dense",
                fullWidth: true,
                placeholder: 'Please select the account activation day (default is today)',
                disablePast: true,
                clearable: true
            },
            value: moment.now(),
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        ExpiresAt: {
            elementType: 'datePicker',
            elementConfig: {
                type: 'datePicker',
                label: 'No expiration date',
                margin: "dense",
                fullWidth: true,
                placeholder: 'No expiration date',
                disablePast: true,
                clearable: true,
            },
            formHelperTextBottom: 'Please select the expiration date (leave empty if the plan does not have an expiration date)',
            value: null,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        MinimalUsage: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Minimal usage',
                label: 'Minimal usage',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: 100,
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        IncludedUsage: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Included monthly usage',
                label: 'Included monthly usage',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: 100000,
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        AllowedOveruse: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Allowed credit usage per month (leave empty if there is no limit)',
                label: 'Allowed credit usage per month (leave empty if there is no limit)',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: null,
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        MaxAllowedUsers: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Max allowed users',
                label: 'Max allowed users',
                className: classes ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                min: 0
            },
            value: 5,
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        MonthlyPrice: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Monthly price',
                label: 'Monthly price',
                className: classes ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                min: 0
            },
            value: '0',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Currency: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Currency',
                margin: "dense",
                fullWidth: true,
                options: currencies ? [...currencies] : []
            },
            formHelperText: "Please select price currency",
            value: currencies && currencies.length > 0 ? currencies[0].value : '',
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
    };
};

const BillingPlanCreate = props => {
    const { open, classes, activeCustomer } = props;
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Create plan', 'Price list'];

    const [billingPlanForm, setBillingPlanForm] = useState(updateFormState({ classes }));

    useEffect(() => {
        setBillingPlanForm(updateFormState({ classes }));
    }, [classes]);

    const [formIsValid, setFormIsValid] = useState(false);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, billingPlanForm);
        setBillingPlanForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    }

    const dateChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnDateChange(event, inputIdentifier, billingPlanForm);
        setBillingPlanForm(updateState.myForm);
    }


    // Second form
    const secondForm = {
        Mobile: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Mobile',
                label: 'Mobile',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true,
                autoFocus: true
            },
            formHelperText: "Please enter how much will the mobile erasure cost",
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        PC: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'PC',
                label: 'PC',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            formHelperText: "Please enter how much will the pc erasure cost",
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Storage: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Storage',
                label: 'Storage',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            formHelperText: "Please enter how much will the storage erasure cost",
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },

    };

    const [priceForm, setPriceForm] = useState(secondForm);
    const [priceFormValid, setPriceFormValid] = useState(false);

    const userInfoFormInputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, priceForm);
        setPriceForm(updateState.myForm);

        setPriceFormValid(updateState.formIsValid);

    };


    const submitHandler = async event => {
        event.preventDefault();
        const formData = formUtility.getFormData(billingPlanForm);
        const formStorageData = formUtility.getFormData(priceForm);

        const Configuration = {
            MonthlyPrice: formData.MonthlyPrice,
            Currency: formData.Currency,
            MaxAllowedUsers: formData.MaxAllowedUsers,
            AllowedOveruse: formData.AllowedOveruse,
            IncludedUsage: formData.IncludedUsage,
            MinimalUsage: formData.MinimalUsage
        };

        delete formData.Price;
        delete formData.Currency;
        delete formData.MaxAllowedUsers;
        delete formData.AllowedOveruse;
        delete formData.IncludedUsage;
        delete formData.MinimalUsage;

        await props.createBillingPlan({ ...formData, PriceList: JSON.stringify(formStorageData), Configuration: JSON.stringify(Configuration), ActiveCustomerID: activeCustomer });

        setActiveStep(0);
        setBillingPlanForm(updateFormState({}));
        setFormIsValid(false);
        setPriceForm(secondForm)
        props.handleOpen();
    }

    const getStepContent = stepIndex => {
        switch (stepIndex) {
            case 0:
                return (
                    <Form
                        formIsValid={formIsValid}
                        classes={classes}
                        form={billingPlanForm}
                        inputChangedHandler={inputChangedHandler}
                        dateChangedHandler={dateChangedHandler}
                    />
                );
            case 1:
                return (<Form
                    formIsValid={priceFormValid}
                    classes={classes}
                    form={priceForm}
                    inputChangedHandler={userInfoFormInputChangedHandler}
                />);
            default:
                return 'Unknown stepIndex';
        }
    };

    const cancelHandler = () => {
        setActiveStep(0);
        setBillingPlanForm(updateFormState({}));
        setFormIsValid(false);
        setPriceForm(secondForm)
        props.handleOpen();
    }
    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };
    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            fullWidth={true}
            // onClose={cancelHandler}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    cancelHandler();
                }
            }}
        >
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {

                    return (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div>                {getStepContent(activeStep)}
                <div style={{ float: 'right' }}>
                    <Button style={{ width: '120px', margin: 10 }}
                        variant='contained' onClick={cancelHandler}>Cancel</Button>

                    <Button
                        style={{ width: '120px', margin: 10, display: activeStep === 0 ? 'none' : null }}
                        variant='contained'
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        color={isSuperUser() ? 'secondary' : 'primary'}>
                        Back
                    </Button>
                    <Button color={isSuperUser() ? 'secondary' : 'primary'}
                        disabled={activeStep === steps.length - 1 ? !priceFormValid : !formIsValid}
                        variant="contained" onClick={activeStep === steps.length - 1 ? submitHandler : handleNext}
                        style={{ width: '120px', margin: 10, display: activeStep === 2 ? 'none' : null }}
                    >
                        {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                    </Button>

                </div>
            </div>

        </Dialog>
    )
}

export default withStyles(styles)(BillingPlanCreate);