import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {combineReducers} from 'redux';

// Components
import LicensePrototype from './LicensePrototype';

// Reducers
import licensePrototypeReducer from './LicensePrototype/store/licensePrototypeReducer';

const hasPermission = (permission, permissions) => {
    return permissions.includes(permission) || permissions.includes('*');
};

// Routes
export const routes = (basePath, permissions) =>
    <Routes>
        {hasPermission('license_prototype', permissions) ?
            <Route path={`${basePath}/*`} element={<LicensePrototype/>}/> : null}
    </Routes>;


export const reducers = combineReducers({
    licensePrototypes: licensePrototypeReducer
});