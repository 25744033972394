import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { combineReducers } from 'redux';


import Permission from './Permission';
// import PermissionEdit from '../np-core/Permissions/PermisionEdit';
// import PermissionCreate from '../np-core/Permissions/PermissionCreate';
// // Reducers
import permissionReducer from './Permission/store/permissionReducer';


// Routes
export const routes = (basePath, permissions) =>
    permissions.includes('*') ?
        <Routes>

            {<Route path={basePath + '/permissions'} element={<Permission/>} />}{' '}
            {/* {<Route path={'/permissions/create'} element={PermissionCreate} />}
            {<Route path={'/permissions/:id/edit'} element={PermissionEdit} />} */}
        </Routes> : null;


export const subComponents = [
    // { name: 'Access control', path: '/accessControls', permissions: ['*'] },
    { name: "Permissions", path: "/permissions", permissions: ["*"] }

];

export const reducers = combineReducers({
    permission: permissionReducer
});