import React, { useState, useEffect } from 'react';
import { Dialog, withStyles } from '@material-ui/core';
import Form from '../../np-form/Form';
import * as formUtility from '../../np-form/utility';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});
const PermissionEdit = props => {
    const [permissionsForm, setPermissionsForm] = useState({});
    const [formIsValid, setFormIsValid] = useState(false);

    const { permission, open, activeCustomer, classes } = props;
    const loadedPermission = permission.loaded


    useEffect(() => {
        if (loadedPermission !== null) {
            const permissionForm = {
                Name: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        fullWidth: true,
                        margin: 'dense',
                        placeholder: 'Name'
                    },
                    value: loadedPermission.Name,

                    validation: {
                        required: true
                    },
                    valid: true,
                    touched: false
                },
                Key: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        fullWidth: true,
                        margin: 'dense',
                        placeholder: 'Key'
                    },
                    value: loadedPermission.Key,

                    validation: {
                        required: true
                    },
                    valid: true,
                    touched: false
                },
                Module: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        fullWidth: true,
                        margin: 'dense',
                        placeholder: 'Module'
                    },
                    value: loadedPermission.Module,

                    validation: {
                        required: true
                    },
                    valid: true,
                    touched: false
                }
            };
            setPermissionsForm(permissionForm);
            setFormIsValid(true);
        }
    }, [loadedPermission]);

    const submitHandler = event => {
        event.preventDefault();
        // Prepare the form data
        const formData = formUtility.getFormData(permissionsForm);
        formData['PermissionID'] = permission.loaded.PermissionID
        props.editPermission({ ...formData, ActiveCustomerID: activeCustomer });

        props.handleEdit();
    };

    // Input change handler
    const inputChangedHandler = (event, inputIdentifier) => {
        // Get default new state based on the input change event
        const updatedState = formUtility.getNewStateOnInputChange(event, inputIdentifier, permissionsForm);
        setPermissionsForm(updatedState.myForm);
        setFormIsValid(updatedState.formIsValid);
    };

    const cancelHandler = () => {
        setPermissionsForm(({}));
        setFormIsValid(false);
        props.handleEdit();
    };

    // Render form
    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            disableEnforceFocus={true}
            fullWidth={true}
            // onClose={cancelHandler}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    cancelHandler();
                }
            }}
        >
            <Form
                cancelHandler={cancelHandler}
                buttonTextCancel="Cancel"
                inputChangedHandler={inputChangedHandler}
                submitHandler={submitHandler}
                form={permissionsForm}
                classes={classes}
                formIsValid={formIsValid}
                buttonText="Save"
                formTitle="Edit Permission"
            />
        </Dialog>
    );
};


export default withStyles(styles)(PermissionEdit);
