import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography, Button, Fab } from "@material-ui/core";
import { formatDate } from "../../../ms-utilities";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { isSuperUser } from '../../../np-utilities';
import CreateAccount from '../../../ms-billing/BillingAccount/BillingAccountCreate'
import BillingAccountDelete from '../../../ms-billing/BillingAccount/BillingAccountDelete'
import AddIcon from '@material-ui/icons/Add';
import { CustomChip } from '../../../ms-components';
import ModalSuspend from './SuspendAndRecoverAccount'


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 340,
        width: 300,
    },
    control: {
        padding: theme.spacing(2),
    },
    typography: {
        textAlign: 'left',
        display: 'list-item',
        marginLeft: '20px'
    },
    chipActive: {
        color: '#4caf50',

    },
    chipPrepared: {
        color: '#af9900',
    },
    chipInactive: {
        color: '#b71c1c',

    },
    buttonStyleActive: {
        width: '120px',
        margin: 10,
        bottom: '10px',
        color: '#eceff1',
        backgroundColor: '#ff5722'
    },
    buttonStyleInActive: {
        width: '120px',
        margin: 10,
        bottom: '10px',
        color: '#eceff1',
        backgroundColor: '#4caf50'
    },
    buttonStyleDelete: {
        width: '120px',
        margin: 10,
        bottom: '10px',
        color: '#eceff1',
        backgroundColor: '#f44336'
    },
    customDiv: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#e7e7e7'

        }
    },

}));

const AccountSection = props => {
    const { accounts, onSuspend, onRecover, activeCustomer, currentUserID } = props;
    const [accountModalState, setAccountModalState] = useState({ AccountID: null, open: false, recover: false });
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const classes = useStyles();
    const navigate = useNavigate();

    const [deleteModalState, setDeleteModalState] = useState({
        open: false,
        billingAccountID: null,
        billingAccountIDs: [],
        forBulk: false
    });

    const [redirectOptions, setRedirectOptions] = useState({ redirect: false, billingAccountID: 0, redirectPath: '' });

    const handleSuspend = billingAccountID => {
        setAccountModalState({ ...accountModalState, AccountID: billingAccountID, open: true })
    };

    const handleRecover = billingAccountID => {
        setAccountModalState({ AccountID: billingAccountID, open: true, recover: true })
    };


    const goToAccountOverview = billingAccountID => {
        setRedirectOptions({
            redirect: true,
            billingAccountID: billingAccountID,
            redirectPath: `/billing/account-overview?billingAccountID=${billingAccountID}`
        });
        if (redirectOptions.redirect) {
            navigate(redirectOptions.redirectPath);
        }
    };


    const handleDelete = async ID => {
        if (!ID) {
            setDeleteModalState({ open: false, billingAccountID: null, billingAccountIDs: [], forBulk: false });
            return;
        }
        const billingAccountID = ID;
        setDeleteModalState({ ...deleteModalState, open: true, billingAccountID: billingAccountID });
    };

    const handleOpen = async () => {
        let userData = await props.onListUsers({ ActiveCustomerID: activeCustomer });
        setUsers(userData);
        setOpen(!open);
    };

    const handleOpenRecover = () => {
        setAccountModalState({ AccountID: null, open: false, recover: false })

    };


    if (redirectOptions.redirect) {
        return <Routes><Route path="*" element={<Navigate to={redirectOptions.redirectPath} />} /></Routes>;
    }

    const getFormattedStatus = (id, status) => {
        switch (status) {
            case 'active':
                return <CustomChip key={`${id}`} className={classes['chipActive']} value={'Active'} />;
            case 'inactive':
                return <CustomChip key={`${id}`} className={classes['chipInactive']} value={'Inactive'} />;
            case 'prepared':
                return <CustomChip key={`${id}`} className={classes['chipPrepared']} value={'Prepared'} />;
            default:
                return '';
        }
    };

    const getButtonStatus = (id, status) => {
        switch (status) {
            case 'active':
                return props.hasPermission('billing_account_suspend') ?
                    <Button className={classes.buttonStyleActive} variant='contained'
                        onClick={() => handleSuspend(id)}>Suspend</Button> : null;
            case 'inactive':
                return props.hasPermission('billing_account_recover') ?
                    <Button className={classes.buttonStyleInActive} variant='contained'
                        onClick={() => handleRecover(id)}>Recover</Button> : null;
            case 'prepared':
                return props.hasPermission('billing_account_suspend') ?
                    <Button className={classes.buttonStyleActive} variant='contained'
                        onClick={() => handleSuspend(id)}>Suspend</Button> : null;
            default:
                return props.hasPermission('billing_account_suspend') ?
                    <Button className={classes.buttonStyleActive} variant='contained'
                        onClick={() => handleSuspend(id)}>Suspend</Button> : null;
        }
    };


    return <div style={{ padding: '30px' }}>
        {props.hasPermission('billing_account_create') ? <Fab
            variant="extended"
            size="small"
            style={{ marginBottom: '10px' }}
            color={isSuperUser() ? 'secondary' : 'primary'}
            aria-label="Add"
            className={null}
            onClick={() => handleOpen()}
        >
            <AddIcon />
            <Typography style={{ fontSize: 12, color: 'white' }}>
                {'Create new'}
            </Typography>
        </Fab> : null}

        <Grid container className={classes.root} spacing={5}>
            {Array.isArray(accounts) && accounts.length < 1 ?
                <p style={{ marginTop: '15px', margin: '10px' }}> Sorry, no accounts available </p> : null}
            {Array.isArray(accounts) && accounts.map(account => (!account.Deleted ?
                <Grid container key={account.AccountID} item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={3} direction={'column'}>
                    <Paper className={classes.paper}>
                        <div className={classes.customDiv} onClick={() => goToAccountOverview(account.AccountID)}
                            style={{
                                width: '100%',
                                height: props.hasPermission('billing_account_delete') || props.hasPermission('billing_account_suspend') || props.hasPermission('billing_account_recover') ? '85%' : '100%'
                            }}>
                            <Typography className={classes.typography}
                                variant={"caption"}>{account.BillingMode === 'prepaid' ? 'Prepaid' : 'Postpaid'}
                            </Typography><br />
                            <Typography className={classes.typography} variant={"caption"}>Created at
                                    - {formatDate(account.CreatedAt)} </Typography> <br></br>
                            <Typography className={classes.typography} variant={"caption"}>Activation
                                    date - {formatDate(account.ActivationDate)} </Typography> <br></br>
                            <Typography className={classes.typography} variant={"caption"}>Billing
                                    day - Every {account.BillingDay}. in month </Typography> <br></br>
                            <Typography className={classes.typography} variant={"caption"}>Expires at
                                    - {formatDate(account.ExpiresAt)} </Typography> <br></br>
                            <Typography className={classes.typography}
                                variant={"caption"}> {getFormattedStatus(account.AccountID, account.Status)}</Typography><br></br>
                        </div>
                        {props.hasPermission('billing_account_delete') || props.hasPermission('billing_account_suspend') || props.hasPermission('billing_account_recover') ?
                            <div style={{ width: '100%' }}>
                                {props.hasPermission('billing_account_delete') ?
                                    <Button className={classes.buttonStyleDelete} variant='contained'
                                        onClick={() => handleDelete(account.AccountID)}>
                                        Delete
                                        </Button> : <></>}
                                {getButtonStatus(account.AccountID, account.Status)}
                            </div>
                            : null}
                    </Paper>
                </Grid> : null
            ))}
            {props.hasPermission('billing_account_create') ?
                <CreateAccount activeCustomer={activeCustomer} users={users} handleOpen={handleOpen} CustomerID={props.CustomerID} open={open} currentUserID ={currentUserID}
                    setOpen={setOpen}
                    createBillingAccount={props.createBillingAccount} /> : null}
            <BillingAccountDelete activeCustomer={activeCustomer}
                entityName={'Billing Account'} handleDelete={handleDelete}
                deleteModalState={deleteModalState}
                deleteBillingAccount={props.onDeleteBillingAccount} />
            <ModalSuspend activeCustomer={activeCustomer} handleOpen={handleOpenRecover} accountModalState={accountModalState} onRecover={onRecover}
                onSuspend={onSuspend} />
        </Grid>
    </div>
};

export default AccountSection;