import React, { useState } from 'react';
import { withStyles, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actions from './store/userActions';
import UserList from './Users/UserList';
import UserDelete from './Users/UserDelete';
import AdminList from './Admin/AdminList';
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';
import * as action from '../../np-core/Auth/store/authActions';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
});

const User = props => {
    const { classes, users, activeCustomer } = props;
    const fetching = users.fetching;
    const [deleteModalState, setDeleteModalState] = useState({ open: false, userID: null, userIDs: [], forBulk: false });

    const handleDelete = async event => {
        if (!event) {
            setDeleteModalState({ open: false, userID: null, userIDs: [], forBulk: false });
            return;
        }
        const userID = event.currentTarget.value;

        if (userID === `${props.currentUserID}`) {
            props.sendMessage('Cannot delete logged in user.', 'warning');
            return;
        }
        setDeleteModalState({ ...deleteModalState, open: true, userID: userID });
    };

    const handleBulkDelete = userIDs => {
        if (userIDs.includes(`${props.currentUserID}`)) {
            userIDs.splice(userIDs.indexOf(`${props.currentUserID}`, 1));
            props.sendMessage('Cannot delete logged in user.', 'warning');
        }
        if (userIDs.length > 0) {
            setDeleteModalState({ ...deleteModalState, open: true, userIDs: userIDs, forBulk: true });
        }
    };

    return (
        props.isSuperUser ?
            <div>
                <div className={classes.progressBarr}>
                    {fetching ? <LinearProgress color="primary" /> : null}
                </div>
                {props.isSuperUser ? <AdminList activeCustomer={activeCustomer} handleDelete={handleDelete} columnsState={props.columns} saveColumnsFilter={props.saveColumnsFilter} users={users} hasPermission={props.hasPermission} hasAtLeastOnePermission={props.hasAtLeastOnePermission} onFetchUsersList={props.onFetchUsersList} /> : null}
                {props.isSuperUser ? <UserDelete activeCustomer={activeCustomer} entityName={'User'} handleDelete={handleDelete} deleteModalState={deleteModalState} onDeleteUser={props.onDeleteUser} bulkDeleteUser={props.onBulkDeleteUser} /> : null}

            </div>
            :
            <div className={classes.root}>
                <div className={classes.progressBarr}>
                    {fetching ? <LinearProgress color="primary" /> : null}
                </div>
                {props.hasPermission('user_list') ? <UserList activeCustomer={activeCustomer} columnsState={props.columns} saveColumnsFilter={props.saveColumnsFilter} users={users} handleDelete={handleDelete} hasPermission={props.hasPermission} hasAtLeastOnePermission={props.hasAtLeastOnePermission} handleBulkDelete={handleBulkDelete} onFetchUsersList={props.onFetchUsersList} /> : null}
                {props.hasPermission('user_delete') ? <UserDelete activeCustomer={activeCustomer} entityName={'User'} handleDelete={handleDelete} deleteModalState={deleteModalState} onDeleteUser={props.onDeleteUser} bulkDeleteUser={props.onBulkDeleteUser} /> : null}
            </div>
    );
}
const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        users: state.user.users,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        hasPermission: permission => { return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'); },
        hasAtLeastOnePermission: permissions => { return state.core.login.permissions.some(permission => permissions.includes(permission)) || state.core.login.permissions.includes('*'); },
        isSuperUser: state.core.login.permissions.includes('*'),
        currentUserID: state.core.login.user.UserID
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onFetchUsersList: filter => dispatch(actions.fetchUsers(filter)),
        onCreateUser: user => dispatch(actions.createUser(user)),
        onEditUser: user => dispatch(actions.editUser(user)),
        onLoadUser: user => dispatch(actions.loadUser(user)),
        onBulkDeleteUser: user => dispatch(actions.bulkDeleteUser(user)),
        onDeleteUser: user => dispatch(actions.deleteUser(user)),
        onFetchRoles: (id) => dispatch(actions.loadRoles(id)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),
        onFetchCustomers: (id) => dispatch(actions.fetchCustomers(id)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(User));
