import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Tooltip, IconButton } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';

import NPDatatable from '../../np-components/Datatable';
import useListFilter from '../../np-components/Datatable/listFilter';
import { isSuperUser } from '../../np-utilities';

const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#e62440" : "#7dc5e2",
        '&:hover': {
            backgroundColor: "#757575",
        },
    }
});

const BinaryImageTypeList = props => {
    const { classes, binaryImageType, onFetchBinaryImageTypes, columnsState, activeCustomer, translations } = props;
    const binaryImageTypeList = binaryImageType.list;
    const binaryImageTypeCount = binaryImageType.count;
    let tableID = 'BinaryImageType';
    let visible = columnsState && columnsState[tableID];

    const columns = [
        {
            displayName: translations["action"] || 'Action',
            backendName: 'Action',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: translations["key"] || 'Key',
            backendName: 'Key',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Key'] : true,
            }
        },
        {
            displayName: translations["name"] || 'Name',
            backendName: 'Name',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Name'] : true,
            }
        },
    ];

    let backendNames = [];
    columns.map(column => { if (column.backendName !== 'Action') backendNames.push(column.backendName); return null; })
    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });

    useEffect(() => {
        if (listFilter.filter) {
            onFetchBinaryImageTypes({ ...listFilter.filter, ActiveCustomerID: activeCustomer });
        }
    }, [binaryImageType.created, binaryImageType.deleted, binaryImageType.bulkDeleted, binaryImageType.edited, onFetchBinaryImageTypes, listFilter.filter, activeCustomer]);

    const createActions = (binaryImageTypeID) => {
        return <>
            {
                (props.hasPermission('binary_image_type_load')) ? <Tooltip title={translations["edit_binary_image_type"] || "Edit binary image type"}>
                    <IconButton value={binaryImageTypeID} aria-label={translations["edit_binary_image_type"] || "Edit binary image type"} onClick={props.handleEdit}>
                        <Edit />
                    </IconButton>
                </Tooltip> : <div></div>
            }
            {
                (props.hasPermission('binary_image_type_delete')) ?
                    <Tooltip title={translations["delete_binary_image_type"] || "Delete binary image type"}>
                        <IconButton value={binaryImageTypeID} aria-label={translations["delete_binary_image_type"] || "Delete binary image type"} onClick={props.handleDelete}>
                            <Delete />
                        </IconButton>
                    </Tooltip> : <div></div>
            }
        </>
    };

    const tableOptions = {
        isSelectable: props.hasPermission('binary_image_type_bulk_delete'),
        tableSearch: false,
        tableID: tableID,
        saveColumn: true,
        selectOptions: { createButtonName: translations['create_new'] || 'Create new', printing: false, generatingReport: false, deleting: props.hasPermission('binary_image_type_delete'), creating: props.hasPermission('binary_image_type_create') },
        rowsPerPage: 20,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        count: binaryImageTypeCount,
        tableTitle: translations["binary_image_type"] || 'Binary image type',
        noMatch: translations['table_no_match'] || "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return props.handleBulkDelete(IDs);
        },
        onSaveFilter: (columns) => {
            let objToSendColumn = {}
            let objColumns = {}
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible
                return objColumns
            })
            objToSendColumn[`${tableID}`] = objColumns
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }
    };


    let counter = 0;
    let binaryImageTypeData = binaryImageTypeList.map(binaryImageType => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = binaryImageType.BinaryImageTypeID;
        returnObj.visible = true;
        returnObj.disableSelecting = binaryImageType.System && !props.hasPermission('*');
        returnObj.values = [createActions(binaryImageType.BinaryImageTypeID), binaryImageType.Key, binaryImageType.Name];

        counter++;
        return returnObj;
    });
    return (
        <Paper>
            <NPDatatable translations={translations} handleCreate={props.handleCreate} customStyles={classes} data={binaryImageTypeData} columns={columns} options={tableOptions} />
        </Paper>
    );
}

export default withStyles(styles)(BinaryImageTypeList);