import restCrudActions from '../../../np-crud/store/restCrudActions'
import { getAxiosInstance } from "../../../np-rest/rest";
import { enqueueSnackbar } from "../../../np-dashboard/Notifier/store/notifierActions";

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: 'trainings',
    pluginName: 'TRAINING',
    entityName: 'TRAINING',
    fetchSuccessMessage: '',
    loadSuccessMessage: '',
    createFailedUseBackendMessage: true,
    deleteFailedUseBackendMessage: true,
    loadFailedUseBackendMessage: true,
    editFailedUseBackendMessage: true,
    bulkDeleteFailedUseBackendMessage: true
});

// Fetch Training action creators
export const fetchTrainings = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
};

// Create Training  action creators
export const createTraining = Training => {
    return async dispatch => {
        await crudActions.create(dispatch, Training);
    };
};

// Edit Training  action creators
export const editTraining = setting => {
    return async dispatch => {
        await crudActions.edit(dispatch, setting);
    };
};

// Load Training action creators
export const loadTraining = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

// Delete Training  action creators
export const bulkDeleteTraining = trainingIDs => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, trainingIDs);
    };
};

// Delete Training  action creators
export const deleteTraining = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};

export const listTrainingModules = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/training/list-modules', data);
            if (res.data && res.data.succeeded) {
                const trainingModules = [];
                const { Modules } = res.data.result.data;
                for (let trainingModule of Modules) {
                    trainingModules.push({
                        value: trainingModule.ModuleID,
                        label: trainingModule.Name,
                        moduleData: { ...trainingModule }
                    });
                }
                return trainingModules;
            } else {

                dispatch(enqueueSnackbar({
                    message: `${res && res.data ? res.data.message : res && res.message ? res.message : "Cannot list permission"}`,
                    options: {
                        variant: 'warning'
                    }
                }));

                return [];
            }
        } catch (error) {
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return [];
        }
    };
};