import React from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actions from '../User/store/userActions';
import ExpiredUserPassword from './ExpiredUserPassword'


const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
});

const User = props => {
    const { classes, onSavePassword, activeCustomer, translations } = props;
    return (
        <div className={classes.root}>
            <ExpiredUserPassword translations={translations} savePassword={onSavePassword} userID={props.UserID} activeCustomer={activeCustomer} ></ExpiredUserPassword>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer && state.core.login.activeCustomer.CustomerID ? state.core.login.activeCustomer.CustomerID : null,
        UserID: state.core.login.user && state.core.login.user.UserID ? state.core.login.user.UserID : null,
        translations: state.translate && state.translate.translations ? state.translate.translations.activeTranslations || {} : {},


    };
};
const mapDispatchToProps = dispatch => {
    return {
        onSavePassword: (data) => dispatch(actions.setNewPassword(data)),

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(User));
