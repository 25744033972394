import * as actionTypes from './actionTypes';
import restCrudReducer from '../../../np-crud/store/restCrudReducer'

const [crudInitialState, crudReducer] = restCrudReducer({
    pluginName: 'CORE',
    entityName: 'User'
});

const initialState = {
    ...crudInitialState,
    deletedUser: {},
    deleting: false,
    userRoles: [],
    saving: false,
    includedPermissions: [],
    excludedPermissions: [],
    allUserPermissions: [],
    userSetting: []
};
const reducer = (state = initialState, action) => {
    // Handle CRUD actions
    const newState = crudReducer(state, action);

    // If CRUD reducer handeled the action
    if (newState !== null) {
        return newState;
    }

    // Handle other actions
    switch (action.type) {
        //fetching user roles
        case actionTypes.FETCH_USER_ROLES_START:
            return fetchUserRolesStart(state, action);
        case actionTypes.FETCH_USER_ROLES_SUCCESS:
            return fetchUserRolesSuccess(state, action);
        case actionTypes.FETCH_USER_ROLES_FAIL:
            return fetchUserRolesFail(state, action);

        //saving user roles
        case actionTypes.BULK_SAVE_USER_ROLES_START:
            return bulkSaveUserRolesStart(state, action);
        case actionTypes.BULK_SAVE_USER_ROLES_SUCCESS:
            return bulkSaveUserRolesSuccess(state, action);
        case actionTypes.BULK_SAVE_USER_ROLES_FAIL:
            return bulkSaveUserRolesFail(state, action);

        //fetching included permissions 
        case actionTypes.FETCH_INCLUDED_PERMISSIONS_START:
            return fetchingIncludedPermissionsStart(state, action);
        case actionTypes.FETCH_INCLUDED_PERMISSIONS_SUCCESS:
            return fetchingIncludedPermissionsSuccess(state, action);
        case actionTypes.FETCH_INCLUDED_PERMISSIONS_FAIL:
            return fetchingIncludedPermissionsFail(state, action);

        //saving included permissions 
        case actionTypes.BULK_SAVE_INCLUDED_PERMISSIONS_START:
            return bulkSaveIncludedPermissionsStart(state, action);
        case actionTypes.BULK_SAVE_INCLUDED_PERMISSIONS_SUCCESS:
            return bulkSaveIncludedPermissionsSuccess(state, action);
        case actionTypes.BULK_SAVE_INCLUDED_PERMISSIONS_FAIL:
            return bulkSaveIncludedPermissionsFail(state, action);

        //fetching excluded permissions 
        case actionTypes.FETCH_EXCLUDED_PERMISSIONS_START:
            return fetchingExcludedPermissionsStart(state, action);
        case actionTypes.FETCH_EXCLUDED_PERMISSIONS_SUCCESS:
            return fetchingExcludedPermissionsSuccess(state, action);
        case actionTypes.FETCH_EXCLUDED_PERMISSIONS_FAIL:
            return fetchingExcludedPermissionsFail(state, action);

        //saving excluded permissions 
        case actionTypes.BULK_SAVE_EXCLUDED_PERMISSIONS_START:
            return bulkSaveExcludedPermissionsStart(state, action);
        case actionTypes.BULK_SAVE_EXCLUDED_PERMISSIONS_SUCCESS:
            return bulkSaveExcludedPermissionsSuccess(state, action);
        case actionTypes.BULK_SAVE_EXCLUDED_PERMISSIONS_FAIL:
            return bulkSaveExcludedPermissionsFail(state, action);

        //Fetching all user permissions 
        case actionTypes.FETCH_ALL_USER_PERMISSIONS_START:
            return fetchAllUserPermissionsStart(state, action);
        case actionTypes.FETCH_ALL_USER_PERMISSIONS_SUCCESS:
            return fetchAllUserPermissionsSuccess(state, action);
        case actionTypes.FETCH_ALL_USER_PERMISSIONS_FAIL:
            return fetchAllUserPermissionsFail(state, action);

        //Fetching  user setting 
        case actionTypes.FETCH_USER_SETTINGS_START:
            return fetchSettingsStart(state, action);
        case actionTypes.FETCH_USER_SETTINGS_SUCCESS:
            return fetchSettingsSuccess(state, action);
        case actionTypes.FETCH_USER_SETTINGS_FAIL:
            return fetchSettingsFail(state, action);
        default:
            return state;
    }

};


//Roles and permissions
const fetchUserRolesStart = (state) => {
    return {
        ...state, loading: true
    };
};

const fetchUserRolesSuccess = (state, action) => {
    return {
        ...state,
        userRoles: action.userRoles,
        loading: false
    };
};

const fetchUserRolesFail = state => {
    return {
        ...state,
        loading: false
    };
};

//Bulk save user roles and permissions
const bulkSaveUserRolesStart = (state, action) => {
    return {
        ...state, saving: true
    };
};

const bulkSaveUserRolesSuccess = (state, action) => {
    return {
        ...state, saving: false
    };
};

const bulkSaveUserRolesFail = (state, action) => {
    return {
        ...state,
        saving: false
    };
};


//Fetching included permissions
const fetchingIncludedPermissionsStart = (state) => {
    return {
        ...state, loading: true
    };
};

const fetchingIncludedPermissionsSuccess = (state, action) => {
    return {
        ...state, loading: false,
        includedPermissions: action.includedPermissions
    };
};

const fetchingIncludedPermissionsFail = (state) => {
    return {
        ...state,
        loading: false
    };
};

//Saving included permissions
const bulkSaveIncludedPermissionsStart = (state) => {
    return {
        ...state, saving: true
    };
};

const bulkSaveIncludedPermissionsSuccess = (state) => {
    return {
        ...state, saving: false
    };
};

const bulkSaveIncludedPermissionsFail = (state) => {
    return {
        ...state,
        saving: false
    };
};


//Fetching excluded permissions
const fetchingExcludedPermissionsStart = (state) => {
    return {
        ...state, loading: true
    };
};

const fetchingExcludedPermissionsSuccess = (state, action) => {
    return {
        ...state, loading: false,
        excludedPermissions: action.excludedPermissions
    };
};

const fetchingExcludedPermissionsFail = (state) => {
    return {
        ...state,
        loading: false
    };
};

//Saving excluded permissions
const bulkSaveExcludedPermissionsStart = (state) => {
    return {
        ...state, saving: true
    };
};

const bulkSaveExcludedPermissionsSuccess = (state) => {
    return {
        ...state, saving: false
    };
};

const bulkSaveExcludedPermissionsFail = (state) => {
    return {
        ...state,
        saving: false
    };
};

//Fetching all user permissions
const fetchAllUserPermissionsStart = (state) => {

    return {
        ...state, loading: true
    };
};

const fetchAllUserPermissionsSuccess = (state, action) => {
    return {
        ...state, loading: false,
        allUserPermissions: action.allUserPermissions
    };
};

const fetchAllUserPermissionsFail = (state) => {
    return {
        ...state,
        loading: false
    };
};

//Fetching all user permissions
const fetchSettingsStart = (state) => {
    return {
        ...state, loading: true
    };
};

const fetchSettingsSuccess = (state, action) => {

    return {
        ...state, loading: false,
        userSetting: action.allUserPermissions
    };
};

const fetchSettingsFail = (state) => {
    return {
        ...state,
        loading: false
    };
};



export default reducer;
