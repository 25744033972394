import React, { useState , useEffect } from 'react';
import { Tabs, Tab, Paper, withStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import * as actions from './store/profileActions';
import * as action from '../ms-users/User/store/userActions';

import { enqueueSnackbar } from '../np-dashboard/Notifier/store/notifierActions';
import UserInformation from './UserEdit';


const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        paddingRight: theme.spacing(6),
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            paddingRight: 0,
        },
        paper: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden"
        },
        logo: {
            width: '250px',
            margin: '10px'
        },
    },
});

const TabContainer = props => {
    return (
        <Typography component="div" dir={props.dir} style={{ padding: 3 * 3 }}>
            {props.children}
        </Typography>
    );
};

const ProfileView = props => {
    const { classes, theme , activeCustomer , loggedUser} = props;
    const [tabValue, setTabValue] = useState(0);
    const [user, setUser] = useState(loggedUser);
    useEffect(() => {
        const fetchData = async () => {
            let data = await props.loadUser({ ActiveCustomerID: activeCustomer , UserID:loggedUser.UserID });
            setUser(data)
        }
        fetchData();
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChange = (event, value) => {
        setTabValue(value);
    };

    const handleChangeIndex = index => {
        setTabValue(index);
    };

    return (
        <Paper className={classes.root}>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab label="Personal information" />
            </Tabs>
            <SwipeableViews
                index={tabValue}
                onChangeIndex={handleChangeIndex}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            >
                <TabContainer><UserInformation loadUser={props.loadUser} activeCustomer={activeCustomer} saveUser={props.saveUser} userInfo={user} setUser={setUser} sendMessage={props.sendMessage} /></TabContainer>
            </SwipeableViews>
        </Paper>
    );
};

const mapStateToProps = state => {
    return {
        loggedUser: state.core.login.user,
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        userSettings: state.core.user.userSetting
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveUser: data => dispatch(actions.saveUser(data)),
        loadUser: data => dispatch(action.loadUserReturnData(data)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ProfileView));