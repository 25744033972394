import React, { useState, useEffect } from 'react';
import Form from '../../np-form/Form';
import { Paper, withStyles } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';
import makeAnimated from 'react-select/animated';
import { BackButton } from '../../ms-utilities';
import * as actions from './store/roleActions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
const animatedComponents = makeAnimated();

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        paddingRight: theme.spacing(6),
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            paddingRight: 0,
        },
    }
});

const updateFirstFormState = props => {
    const { classes, translations, permissions } = props;
    const { permissionList, loading } = permissions;
    // First form
    const firstForm = {
        Name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['name'] || 'Name',
                label: translations['name'] || 'Name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        FancyName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['fancy_name'] || 'Fancy name',
                label: translations['fancy_name'] || 'Fancy name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        Type: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['type'] || 'Type',
                label: translations['type'] || 'Type',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            touched: false
        },
        Module: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['module'] || 'Module',
                label: translations['module'] || 'Module',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: false,
                isNumeric: true
            },
            valid: true,
            touched: false
        },
        Locked: {
            elementType: 'checkbox',
            elementConfig: {
                type: 'checkbox',
                label: null,
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [{ ID: 'checkboxID', Checked: false, Name: translations['locked'] || 'Locked' }]
            },
            value: false,
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        Permissions: {
            elementType: 'multi-select',
            elementConfig: {
                isMulti: true,
                closeMenuOnSelect: false,
                components: animatedComponents,
                isLoading: loading,
                type: 'text',
                placeholder: translations['permission'] || 'Permission',
                label: translations['choose_permissions'] || 'Choose permissions',
                options: permissionList ? permissionList : [],
                selected: []
            },
            value: [],
            formHelperText: translations['choose_permission_role'] || 'Please select permissions for role',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        }
    };
    return firstForm;
}

const RoleCreate = props => {
    const { classes, activeCustomer, translations, onLoadPermission } = props;
    const [RoleFormValid, setRoleFormValid] = useState(false);
    const [permissions, setPermissions] = useState({ permissionList: [], loading: true });
    const [RoleForm, setRoleForm] = useState(updateFirstFormState({ ...props, permissions }));
    const [redirect, setRedirect] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setRoleForm(updateFirstFormState({ ...props, permissions }));
    }, [props, permissions]);

    useEffect(() => {
        const setPermissionToMultiSelect = async () => {
            let data = await onLoadPermission({ ActiveCustomerID: activeCustomer });
            setPermissions({ permissionList: data, loading: false });
        }
        setPermissionToMultiSelect()
    }, [onLoadPermission, activeCustomer]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, RoleForm);
        setRoleForm(updateState.myForm);
        setRoleFormValid(updateState.formIsValid)
    };

    const getNewStateOnMultiSelectChange = (event) => {
        const updateState = formUtility.getNewStateOnMultiSelectChange(event, 'Permissions', RoleForm);
        setRoleForm(updateState.myForm);
        setRoleFormValid(updateState.formIsValid)
    }

    const checkboxChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnCheckboxChange(event, inputIdentifier, RoleForm);
        setRoleForm(updateState.myForm);
    }
    const submitHandler = event => {
        event.preventDefault();
        const formRole = formUtility.getFormData(RoleForm);
        formRole['Locked'] = formRole['Locked'] ? 1 : 0
        formRole['ActiveCustomerID'] = activeCustomer

        if (isSuperUser()) {
            delete formRole['ActiveCustomerID']
        }
        props.createRole({ ...formRole });
        setRoleForm(updateFirstFormState({ translations, permissions }));
        setRoleFormValid(false);
        setRedirect(true)
    };

    if (redirect) {
        navigate('/roles')
        // return <Routes><Route path="*" element={<Navigate to="/roles" />} /></Routes>;
    }
    return (
        <Paper className={classes.root}>
            {BackButton(translations)}
            <Form
                formIsValid={RoleFormValid}
                classes={classes}
                form={RoleForm}
                getMultiSelectData={getNewStateOnMultiSelectChange}
                checkboxChangedHandler={checkboxChangedHandler}
                inputChangedHandler={inputChangedHandler}
                formTitle={translations['create_role'] || 'Create Role'}
                buttonText={translations['save'] || "Save"}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                submitHandler={submitHandler}
            />
        </Paper>
    )
}

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        hasEditPermission: state.core.login.permissions.includes('*') || state.core.login.permissions.includes('core_products_edit'),
        translations: state.translate && state.translate.translations ? state.translate.translations.activeTranslations || {} : {}
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createRole: data => dispatch(actions.createRole(data)),
        onLoadPermission: (id) => dispatch(actions.loadPermission(id))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(RoleCreate));
