import { enqueueSnackbar } from '../np-dashboard/Notifier/store/notifierActions'
import { getAxiosInstance } from '../np-rest/rest';
import { formatMessage } from '../np-utilities';

const fetchRequest = props => {

    const { endpoint, filter, start, success, failed, successMessage = '', failedMessage = '', lazyLoad = false, crudActions = false } = props;
    // use the default rest instance
    const rest = getAxiosInstance();

    const fetchItems = async dispatch => {

        // Dispatch actions
        dispatch(start());
        try {
            let res = lazyLoad ? { data: { succeeded: true } } : await rest.post(endpoint + '/count', filter);
            if (res.data.succeeded) {
                const itemCount = lazyLoad ? null : res.data.result.data;
                res = await rest.post(endpoint + '/list', filter);

                if (res.data.succeeded) {
                    const fetchedItemList = res.data.result.data;
                    dispatch(success(itemCount, fetchedItemList, lazyLoad, crudActions, filter));
                    if (successMessage !== '') {
                        dispatch(enqueueSnackbar({
                            message: formatMessage(successMessage),
                            options: {
                                variant: 'success'
                            }
                        }));
                    }
                } else {
                    dispatch(failed(res.error));
                    if (failedMessage !== '') {
                        dispatch(enqueueSnackbar({
                            message: formatMessage(failedMessage),
                            options: {
                                variant: 'error'
                            }
                        }));
                    }
                }
            } else {
                dispatch(failed(res.error));
                if (failedMessage !== '') {
                    dispatch(enqueueSnackbar({
                        message: formatMessage(failedMessage),
                        options: {
                            variant: 'error'
                        }
                    }));
                }
            }
        } catch (err) {
            dispatch(failed(err));
            if (failedMessage !== '') {
                dispatch(enqueueSnackbar({
                    message: formatMessage(failedMessage),
                    options: {
                        variant: 'error'
                    }
                }));
            }
        }
        return () => { }
    };

    return [fetchItems]
}

export default fetchRequest;