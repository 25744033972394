import restCrudActions from '../../../np-crud/store/restCrudActions';
import { getAxiosInstance } from '../../../np-rest/rest';
import { enqueueSnackbar } from '../../../np-dashboard/Notifier/store/notifierActions';

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: 'license-prototype',
    pluginName: 'LICENSE',
    entityName: 'LICENSE_PROTOTYPE',
    fetchSuccessMessage: '',
    loadSuccessMessage: '',
});

// Fetch License prototype action creators
export const fetchLicensePrototypeInit = () => {
    return crudActions.fetchInit();
};
export const fetchLicensePrototypeStart = () => {
    return crudActions.fetchStart();
};
export const fetchLicensePrototypeSuccess = () => {
    return crudActions.fetchSuccess();
};
export const fetchLicensePrototypeFail = () => {
    return crudActions.fetchFail();
};
export const fetchLicensePrototype = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    }
}

// Create License  prototype action creators
export const createLicensePrototypeInit = () => {
    return crudActions.createInit();
};
export const createLicensePrototypeStart = () => {
    return crudActions.createStart();
};
export const createLicensePrototypeSuccess = createdLicensePrototype => {
    return crudActions.createSuccess(createdLicensePrototype);
};
export const createLicensePrototypeFail = error => {
    return crudActions.createFail(error);
};
export const createLicensePrototype = License => {
    return async dispatch => {
        await crudActions.create(dispatch, License);
    };
};

// Delete License prototype action creators
export const deleteLicensePrototypeInit = () => {
    return crudActions.deleteInit();
};
export const deleteLicensePrototypeStart = () => {
    return crudActions.deleteStart();
};
export const deleteLicensePrototypeSuccess = deletedLicensePrototype => {
    return crudActions.deleteSuccess(deletedLicensePrototype);
};
export const deleteLicensePrototypeFail = error => {
    return crudActions.deleteFail(error);
};
export const deleteLicensePrototype = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};

// Edit License prototype action creators
export const editLicensePrototypeInit = () => {
    return crudActions.editInit();
};
export const editLicensePrototypeStart = () => {
    return crudActions.editStart();
};
export const editLicensePrototypeSuccess = editedLicensePrototype => {
    return crudActions.editSuccess(editedLicensePrototype);
};
export const editLicensePrototypeFail = error => {
    return crudActions.editFail(error);
};
export const editLicensePrototype = setting => {
    return async dispatch => {
        await crudActions.edit(dispatch, setting);
    };
};

// Load License prototype action creators
export const loadLicensePrototypeInit = () => {
    return crudActions.loadInit();
};
export const loadLicensePrototypeStart = () => {
    return crudActions.loadStart();
};
export const loadLicensePrototypeSuccess = loadedLicensePrototype => {
    return crudActions.loadSuccess(loadedLicensePrototype);
};
export const loadLicensePrototypeFail = error => {
    return crudActions.loadFail(error);
};
export const loadLicensePrototype = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

export const listPermissions = (id) => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/permissions/list', { forList: true, ...id });
            if (res.data && res.data.succeeded) {
                const permissions = [];
                for (var permission of res.data.result.data) {
                    permissions.push({ value: permission.PermissionKey, label: permission.PermissionName });
                }
                return permissions;
            }
            else {
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : res.message}`,
                        options: {
                            variant: 'warning'
                        }
                    }));
                return [];
            }
        } catch (error) {
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return [];
        }
    };
};