import React, { useState } from 'react';
import { withStyles, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';

import BillingPlanList from './BillingPlanList';
import * as actions from './store/billingPlanActions';
import BillingPlanCreate from './BillingPlanCreate';
import BillingPlanEdit from './BillingPlanEdit';
import BillingPlanDelete from './BillingPlanDelete';
import * as action from '../../np-core/Auth/store/authActions';

import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
    progressBarr: {
        width: '100%',
        height: 5
    }
});

const BillingPlan = props => {
    const { classes, billingPlans, activeCustomer } = props;
    const fetching = billingPlans.fetching;

    const [addModalState, setAddModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState({ open: false, billingPlanID: null, billingPlanIDs: [], forBulk: false });

    const handleOpen = async () => {
        setAddModalState(!addModalState);
    };

    const handleEdit = async event => {
        if (!event) {
            setEditModalState(!editModalState);
            return;
        }

        const billingPlanID = event.currentTarget.value;
        await props.onFetchBillingPlan({ RowID: billingPlanID, ActiveCustomerID: activeCustomer });
        setEditModalState(!editModalState);
    };

    const handleDelete = async event => {
        if (!event) {
            setDeleteModalState({ open: false, billingPlanID: null, billingPlanIDs: [], forBulk: false });
            return;
        }
        const billingPlanID = event.currentTarget.value;
        setDeleteModalState({ ...deleteModalState, open: true, billingPlanID: billingPlanID });
    };

    const handleBulkDelete = billingPlanIDS => {
        setDeleteModalState({ ...deleteModalState, open: true, billingPlanIDs: billingPlanIDS, forBulk: true });
    };

    return (
        <div className={classes.root}>
            <div className={classes.progressBarr}>
                {fetching ? <LinearProgress color="primary" /> : null}
            </div>
            {props.hasPermission('billing_plan_list') ? <BillingPlanList activeCustomer={activeCustomer} columnsState={props.columns} saveColumnsFilter={props.saveColumnsFilter} hasAtLeastOnePermission={props.hasAtLeastOnePermission} hasPermission={props.hasPermission} handleBulkDelete={handleBulkDelete} billingPlans={billingPlans} onFetchBillingPlans={props.onFetchBillingPlans} handleEdit={handleEdit} handleDelete={handleDelete} handleCreate={handleOpen} /> : null}
            {props.hasPermission('billing_plan_create') ? <BillingPlanCreate activeCustomer={activeCustomer} loading={billingPlans.creating} handleOpen={handleOpen} open={addModalState} createBillingPlan={props.onCreateBillingPlan} /> : null}
            {props.hasPermission('billing_plan_load') ? <BillingPlanEdit activeCustomer={activeCustomer} handleEdit={handleEdit} open={editModalState} hasPermission={props.hasPermission} loading={billingPlans.saving} billingPlan={billingPlans.loaded} editBillingPlan={props.onEditBillingPlan} /> : null}
            {props.hasPermission('billing_plan_delete') ? <BillingPlanDelete activeCustomer={activeCustomer} entityName={'Billing Plan'} handleDelete={handleDelete} deleteModalState={deleteModalState} bulkDeleteBillingPlans={props.onBulkDeleteBillingPlans} deleteBillingPlan={props.onDeleteBillingPlan} /> : null}
        </div >
    );
}

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        billingPlans: state.billing.plans,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        hasPermission: permission => { return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'); },
        hasAtLeastOnePermission: permissions => { return state.core.login.permissions.some(permission => permissions.includes(permission)) || state.core.login.permissions.includes('*'); }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchBillingPlans: filter => dispatch(actions.fetchBillingPlan(filter)),
        onCreateBillingPlan: billingPlan => dispatch(actions.createBillingPlan(billingPlan)),
        onFetchBillingPlan: billingPlan => dispatch(actions.loadBillingPlan(billingPlan)),
        onEditBillingPlan: billingPlan => dispatch(actions.editBillingPlan(billingPlan)),
        onDeleteBillingPlan: billingPlan => dispatch(actions.deleteBillingPlan(billingPlan)),
        onBulkDeleteBillingPlans: billingPlan => dispatch(actions.bulkDeleteBillingPlans(billingPlan)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),

        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(BillingPlan));