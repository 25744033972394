import React, { useEffect, useState } from "react";
import { Button, Grid, withStyles } from "@material-ui/core";
import moment from "moment";
import * as formUtility from "../../../np-form/utility";
import Form from "../../../np-form/Form";
import { isSuperUser } from "../../../np-utilities";

const styles = theme => ({
    root: {
        width: '100%',
        height: 'auto',
    }
});

const billingModes = [{ value: 'prepaid', displayValue: 'Prepaid' }, { value: 'postpaid', displayValue: 'Contract' }];
const accountStatuses = [
    { value: 'active', displayValue: 'Active' },
    { value: 'prepared', displayValue: 'Prepared' },
    { value: 'inactive', displayValue: 'Inactive' },
    { value: 'expired', displayValue: 'Expired' }];

const termsOfPayment = [
    { value: 'dor', displayValue: 'Due on Receipt' },
    { value: 'pia', displayValue: 'Payment in Advance' },
    { value: 'eom', displayValue: 'End of Month' },
    { value: 'cod', displayValue: 'Cash on Delivery' },
    { value: 'net7', displayValue: '7 days after invoice date' },
    { value: 'net10', displayValue: '10 days after invoice date' },
    { value: 'net15', displayValue: '15 days after invoice date' },
    { value: 'net30', displayValue: '30 days after invoice date' },
    { value: 'net60', displayValue: '60 days after invoice date' },
];

const languages = [
    { value: 'en', displayValue: 'English' },
    { value: 'de', displayValue: 'German' },
    { value: 'sr', displayValue: 'Serbian' }
];

const updateFormState = props => {
    const { billingAccount, classes, users } = props;
    const { Customer } = billingAccount ? billingAccount : {};
    const address = billingAccount && billingAccount.Address ? JSON.parse(billingAccount.Address) : null;
    const communication = billingAccount && billingAccount.Communication ? JSON.parse(billingAccount.Communication) : null;
    const accountValue = billingAccount && users ? users.filter(displayValue => { return billingAccount.AccountantID === displayValue.value })[0] : null

    return {
        BillingMode: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Billing mode',
                margin: "dense",
                fullWidth: true,
                options: billingModes ? [...billingModes] : [],
                disabled: true
            },
            formHelperText: "Please select billing mode",
            value: billingAccount?.BillingMode ?? '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Status: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Billing account status',
                margin: "dense",
                fullWidth: true,
                options: accountStatuses ? [...accountStatuses] : []
            },
            formHelperText: "Please select billing account status",
            value: billingAccount?.Status ?? 'active',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        ActivationDate: {
            elementType: 'datePicker',
            elementConfig: {
                type: 'datePicker',
                label: 'Activation date',
                margin: "dense",
                fullWidth: true,
                placeholder: 'Please select account activation date (default is now)',
                clearable: true,
                disabled: true
            },
            value: billingAccount?.ActivationDate ?? null,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        ExpiresAt: {
            elementType: 'datePicker',
            elementConfig: {
                type: 'datePicker',
                label: 'Expires at',
                margin: "dense",
                fullWidth: true,
                placeholder: 'Please select the expiration date',
                disablePast: true,
                clearable: true
            },
            value: billingAccount?.ExpiresAt ?? moment().add(1, 'year'),
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        BillingDay: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                label: 'Billing day',
                margin: "dense",
                fullWidth: true,
                placeholder: 'Please select the account billing day',
                inputProps: { min: "1", max: "28" }
            },
            value: billingAccount?.BillingDay ?? 1,
            validation: {
                required: true,
                isNumeric: true,
                min: 1,
                max: 28
            },
            valid: true,
            touched: true
        },
        CustomerOrder: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Customer order',
                label: 'Customer order',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: billingAccount?.CustomerOrder ?? '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        VAT: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'VAT Number',
                label: 'VAT Number',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: billingAccount?.VAT ?? '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        TaxClassification: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Tax Classification',
                label: 'Tax Classification',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: billingAccount?.TaxClassification ?? '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        TermsOfPayment: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Terms Of Payment',
                margin: "dense",
                fullWidth: true,
                options: termsOfPayment ? [...termsOfPayment] : []
            },
            formHelperText: "Please select terms of payment",
            value: billingAccount?.TermsOfPayment ?? '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Accountant: {
            elementType: 'autocomplete',
            elementConfig: {
                type: 'select',
                label: 'User',
                margin: "dense",
                fullWidth: true,
                options: users ? [...users] : []
            },
            formHelperText: "Please select accountant",
            value: accountValue ?? null,
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Language: {
            elementType: 'autocomplete',
            elementConfig: {
                type: 'select',
                label: 'Communication language',
                margin: "dense",
                fullWidth: true,
                options: languages ? [...languages] : []
            },
            formHelperText: "Please select language",
            value: communication?.Language ?? '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        PhoneNumber1: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Phone number',
                label: 'Phone number',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: communication?.PhoneNumber1 ?? '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Email: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Email',
                label: 'Email',
                margin: "dense",
                fullWidth: true,
            },
            value: communication?.Email ?? '',
            validation: {
                required: false,
                isEmail: true
            },
            valid: true,
            touched: true
        },
        CustomerName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Customer Name',
                label: 'Customer Name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                disabled: true
            },
            value: Customer?.Name ?? '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        StreetAddress: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'StreetAddress',
                label: 'StreetAddress',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: address?.StreetAddress ?? '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        PostCode: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Post code',
                label: 'Post code',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: address?.PostCode ?? '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        City: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'City',
                label: 'City',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: address?.City ?? '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Country: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Country',
                label: 'Country',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: address?.Country ?? '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
    };
};

const AccountOverview = props => {
    const { classes, billingAccount, loading, users, activeCustomer } = props;

    // Functions
    const { onLoadAccount, editBillingAccount, hasPermission } = props;

    const [billingAccountForm, setBillingAccountForm] = useState(updateFormState({ classes, billingAccount, users }));
    const [formIsValid, setFormIsValid] = useState(false);

    useEffect(() => {
        setBillingAccountForm(updateFormState({ classes, billingAccount, users }));
    }, [onLoadAccount, billingAccount, classes, users]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, billingAccountForm);
        setBillingAccountForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };
    const autoChangedHandler = (event, inputIdentifier) => {
        let updateState = formUtility.getNewStateOnAutoCompleteChange(event, inputIdentifier, billingAccountForm);
        setBillingAccountForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const dateChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnDateChange(event, inputIdentifier, billingAccountForm);
        setBillingAccountForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const submitHandler = async event => {
        event.preventDefault();
        const formData = formUtility.getFormData(billingAccountForm);
        formData['AccountID'] = billingAccount.AccountID;
        const Address = {
            StreetAddress: formData.StreetAddress,
            City: formData.City,
            PostCode: formData.PostCode,
            Country: formData.Country
        };

        const Communication = {
            Language: formData.Language,
            PhoneNumber1: formData.PhoneNumber1,
            Email: formData.Email
        };
        formData['Accountant'] = formData?.['Accountant']?.value ?? '';
        formData['Address'] = JSON.stringify(Address);
        formData['Communication'] = JSON.stringify(Communication);

        await editBillingAccount({ ...formData, ActiveCustomerID: activeCustomer });
        setFormIsValid(false);
    };

    return <Grid container spacing={0}>
        <Grid item xs={12}>
            <Form
                formIsValid={formIsValid}
                classes={classes}
                autoChangedHandler={autoChangedHandler}
                form={billingAccountForm}
                inputChangedHandler={inputChangedHandler}
                dateChangedHandler={dateChangedHandler}
            />
            {hasPermission('account_overview_save') ?
                <Button style={{ width: '100%' }} disabled={!formIsValid || loading} variant='contained'
                    onClick={submitHandler} fullWidth={true}
                    color={isSuperUser() ? "secondary" : "primary"}>Save</Button> : null}

        </Grid>
    </Grid>
};

export default withStyles(styles, { withTheme: true })(AccountOverview);