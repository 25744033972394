import restCrudReducer from '../../../np-crud/store/restCrudReducer';
import * as actionTypes from "./actionTypes";

const [crudInitialState, crudReducer] = restCrudReducer({
    pluginName: 'APPLICANT',
    entityName: 'APPLICANT',
});

const initialState = {
    ...crudInitialState,
    deletedApplicant: {},
    deleting: false,
    saving: false,
    datasets: [],
    active: false,
    datasetsLoading: false

};
const reducer = (state = initialState, action) => {
    // Handle CRUD actions
    const newState = crudReducer(state, action);
    // If CRUD reducer handled the action
    if (newState !== null) {
        return newState;
    }

    // Handle other actions
    switch (action.type) {
        case 'CORE|LOGOUT_SUCCESS':
        case 'CORE|CORE_SET_ACTIVE_CUSTOMER_SUCCESS':
            return initialState;
        case actionTypes.APPLICANT_TRAINING_RESULT_START:
            return { ...state, datasets: action.datasets, datasetsLoading: true };
        case actionTypes.APPLICANT_TRAINING_RESULT_SUCCESS:
            return { ...state, datasets: action.datasets, datasetsLoading: false };
        case actionTypes.APPLICANT_TRAINING_RESULT_FAIL:
            return { ...state, datasets: action.datasets, datasetsLoading: false };
        case actionTypes.APPLICANT_ACC:
            return { ...state, active: !action.active };
        default:
            return state;
    }
};

export default reducer;