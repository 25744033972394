import * as actionTypes from "./actionTypes";

// Get initial values from localStorage
let initialToken = null;
let initialPermissions = [];
let initialCustomers = [];
let initialActiveCustomer = null;
let initialLoggedUser = null;
let initialSupportSession = null;
let activeExternalServiceAvailable = false;
let userSettings = {};
let passwordComplexity = null;
let passwordExpired = false;
try {
    initialToken = JSON.parse(localStorage.getItem("CORE|AUTH|TOKEN")) ? JSON.parse(localStorage.getItem("CORE|AUTH|TOKEN")).token : null;
    passwordComplexity = localStorage.getItem("CORE|AUTH|PASSWORD|COMPLEXITY") ? localStorage.getItem("CORE|AUTH|PASSWORD|COMPLEXITY") : null;
    passwordExpired = localStorage.getItem("CORE|AUTH|PASSWORD|EXPIRED") ? localStorage.getItem("CORE|AUTH|PASSWORD|EXPIRED") === 'true' : null;
    initialPermissions = JSON.parse(localStorage.getItem("CORE|AUTH|PERMISSIONS")) ? JSON.parse(localStorage.getItem("CORE|AUTH|PERMISSIONS")).permissions : [];
    initialCustomers = JSON.parse(localStorage.getItem("CORE|AUTH|CUSTOMERS")) ? JSON.parse(localStorage.getItem("CORE|AUTH|CUSTOMERS")).customers : [];
    initialActiveCustomer = JSON.parse(localStorage.getItem("CORE|AUTH|ACTIVE|CUSTOMER"))
        ? JSON.parse(localStorage.getItem("CORE|AUTH|ACTIVE|CUSTOMER")).activeCustomer
        : null;
    initialLoggedUser = JSON.parse(localStorage.getItem("CORE|AUTH|LOGGED|USER")) ? JSON.parse(localStorage.getItem("CORE|AUTH|LOGGED|USER")).user : null;
    initialSupportSession = JSON.parse(localStorage.getItem("CORE|AUTH|SUPPORT|SESSION|ACTIVE"))
        ? JSON.parse(localStorage.getItem("CORE|AUTH|SUPPORT|SESSION|ACTIVE")).supportSession
        : null;
    activeExternalServiceAvailable = JSON.parse(localStorage.getItem("CORE|AUTH|EXTERNAL|SETTINGS"))
        ? JSON.parse(localStorage.getItem("CORE|AUTH|EXTERNAL|SETTINGS")).activeExternalServiceAvailable
        : null;
    userSettings = JSON.parse(localStorage.getItem("CORE|AUTH|USER|SETTINGS"))
        ? JSON.parse(localStorage.getItem("CORE|AUTH|USER|SETTINGS")).userSettings
        : null;
} catch (e) {
    // missing data in local storage
}

// Initial state filled with values from the localStorage
const initialState = {
    token: initialToken,
    permissions: initialPermissions,
    customers: initialCustomers,
    activeCustomer: initialActiveCustomer,
    user: initialLoggedUser,
    loginInProgress: false,
    logoutInProgress: false,
    supportSessionActive: initialSupportSession,
    activeExternalServiceAvailable: activeExternalServiceAvailable,
    userSettings: userSettings,
    expiresAt: null,
    passwordComplexity: passwordComplexity,
    passwordExpired: passwordExpired
};
const loginStart = (state, action) => {
    return { ...state, loginInProgress: true };
};

const loginSuccess = (state, action) => {
    // Store the token and permissions items to the localStorage for multi-tab usage or for site reload
    localStorage.setItem("CORE|AUTH|TOKEN", JSON.stringify({ token: action.token }));
    localStorage.setItem("CORE|AUTH|PERMISSIONS", JSON.stringify({ permissions: action.permissions }));
    localStorage.setItem("CORE|AUTH|CUSTOMERS", JSON.stringify({ customers: action.customers }));
    localStorage.setItem("CORE|AUTH|ACTIVE|CUSTOMER", JSON.stringify({ activeCustomer: action.activeCustomer }));
    localStorage.setItem("CORE|AUTH|LOGGED|USER", JSON.stringify({ user: action.user }));
    localStorage.setItem("CORE|AUTH|EXTERNAL|SETTINGS", JSON.stringify({ activeExternalServiceAvailable: action.activeExternalServiceAvailable }));
    localStorage.setItem("CORE|AUTH|USER|SETTINGS", JSON.stringify({ userSettings: action.userSettings }));
    localStorage.setItem("CORE|AUTH|PASSWORD|COMPLEXITY", action.passwordComplexity);
    localStorage.setItem("CORE|AUTH|PASSWORD|EXPIRED", action.passwordExpired);

    return {
        ...state,
        token: action.token,
        permissions: action.permissions,
        customers: action.customers,
        activeCustomer: action.activeCustomer,
        user: action.user,
        loginInProgress: false,
        activeExternalServiceAvailable: action.activeExternalServiceAvailable,
        userSettings: action.userSettings,
        expiresAt: action.expiresAt,
        passwordExpired: action.passwordExpired,
        passwordComplexity: action.passwordComplexity
    };
};

const loginFail = (state, action) => {
    return {
        ...state,
        loginInProgress: false
    };
};

const logoutStart = (state, action) => {
    return { ...state, logoutInProgress: true };
};

const logoutFail = (state, action) => {
    return { ...state, logoutInProgress: false };
};

const logoutSuccess = (state, action) => {
    // Remove everything from the localStorage
    localStorage.clear();
    return {
        ...initialState,
        token: null,
        permissions: [],
        activeCustomer: {},
        user: {},
        logoutInProgress: false,
        expiresAt: null
    };
};

const setPasswordStart = (state, action) => {
    return {
        ...state
    };
};

const setPasswordSuccess = (state, action) => {
    localStorage.setItem(
        "CORE|AUTH|LOGGED|USER",
        JSON.stringify({
            user: {
                ...state.user,
                PasswordExpires: action.password
            }
        })
    );

    return {
        ...state,
        user: { ...state.user, PasswordExpires: action.password }
    };
};

const setPasswordFail = (state, action) => {
    const oldActiveCustomer = JSON.parse(localStorage.getItem("CORE|AUTH|ACTIVE|CUSTOMER")) ? JSON.parse(localStorage.getItem("CORE|AUTH|ACTIVE|CUSTOMER")).activeCustomer : null;
    return {
        ...state,
        activeCustomer: oldActiveCustomer
    };
};

const setActiveCustomerStart = (state, action) => {
    return {
        ...state
    };
};

const setActiveCustomerSuccess = (state, action) => {
    localStorage.setItem("CORE|AUTH|ACTIVE|CUSTOMER", JSON.stringify({ activeCustomer: action.activeCustomer }));
    localStorage.setItem("CORE|AUTH|PERMISSIONS", JSON.stringify({ permissions: action.permissions }));
    localStorage.setItem("CORE|AUTH|EXTERNAL|SETTINGS", JSON.stringify({ activeExternalServiceAvailable: action.activeExternalServiceAvailable }));

    return {
        ...state,
        activeCustomer: action.activeCustomer,
        permissions: action.permissions,
        activeExternalServiceAvailable: action.activeExternalServiceAvailable
    };
};

const setActiveCustomerFail = (state, action) => {
    const oldActiveCustomer = JSON.parse(localStorage.getItem("CORE|AUTH|ACTIVE|CUSTOMER")).activeCustomer;
    return {
        ...state,
        activeCustomer: oldActiveCustomer
    };
};

const changeLoggedUserStart = (state, action) => {
    return {
        ...state
    };
};

const changeLoggedUserSuccess = (state, action) => {
    localStorage.setItem("CORE|AUTH|LOGGED|USER", JSON.stringify({ user: action.user }));
    return {
        ...state,
        user: action.user
    };
};

const changeLoggedUserFail = (state, action) => {
    return {
        ...state
    };
};

//Start support sessions
const supportSessionStart = state => {
    return {
        ...state,
        loading: true
    };
};

const supportSessionSuccess = (state, action) => {
    localStorage.setItem("CORE|AUTH|PERMISSIONS", JSON.stringify({ permissions: action.permissions }));
    localStorage.setItem("CORE|AUTH|CUSTOMERS", JSON.stringify({ customers: action.customers }));
    localStorage.setItem("CORE|AUTH|LOGGED|USER", JSON.stringify({ user: action.user }));
    localStorage.setItem("CORE|AUTH|SUPPORT|SESSION|ACTIVE", JSON.stringify({ supportSession: true }));
    return {
        ...state,
        permissions: action.permissions,
        customers: action.customers,
        user: action.user,
        loading: false,
        supportSessionActive: true
    };
};

const supportSessionFail = state => {
    return {
        ...state,
        loading: false
    };
};

//End support session
const endSupportSessionStart = state => {
    return {
        ...state,
        loading: true
    };
};

const endSupportSessionSuccess = (state, action) => {
    localStorage.setItem("CORE|AUTH|PERMISSIONS", JSON.stringify({ permissions: action.permissions }));
    localStorage.setItem("CORE|AUTH|CUSTOMERS", JSON.stringify({ customers: action.customers }));
    localStorage.setItem("CORE|AUTH|LOGGED|USER", JSON.stringify({ user: action.user }));
    localStorage.setItem("CORE|AUTH|SUPPORT|SESSION|ACTIVE", JSON.stringify({ supportSession: false }));
    return {
        ...state,
        permissions: action.permissions,
        customers: action.customers,
        user: action.user,
        loading: false,
        supportSessionActive: false
    };
};

const endSupportSessionFail = state => {
    return {
        ...state
    };
};
const changeColumnsFilterSettingsStart = state => {
    return {
        ...state
    };
};

const changeColumnsFilterSettingsSuccess = (state, action) => {
    let existingColumns = state.userSettings && typeof state.userSettings.Columns === "object" && state.userSettings !== null ? state.userSettings.Columns : {};
    localStorage.setItem(
        "CORE|AUTH|USER|SETTINGS",
        JSON.stringify({
            userSettings: {
                ...state.userSettings,
                Columns: { ...existingColumns, ...action.columns }
            }
        })
    );

    return {
        ...state,
        userSettings: { ...state.userSettings, Columns: { ...existingColumns, ...action.columns } }
    };
};

const changeColumnsFilterSettingsFail = state => {
    return {
        ...state,
        loading: false
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CORE_LOGIN_START:
            return loginStart(state, action);
        case actionTypes.CORE_LOGIN_SUCCESS:
            return loginSuccess(state, action);
        case actionTypes.CORE_LOGIN_FAIL:
            return loginFail(state, action);
        case actionTypes.CORE_LOGOUT_START:
            return logoutStart(state, action);
        case actionTypes.CORE_LOGOUT_SUCCESS:
            return logoutSuccess(state, action);
        case actionTypes.CORE_LOGOUT_FAIL:
            return logoutFail(state, action);
        case " USER|USER_PASSWORD_CHANGE_START":
            return setPasswordStart(state, action);
        case "USER|USER_PASSWORD_CHANGE_SUCCESS":
            return setPasswordSuccess(state, action);
        case "USER|USER_PASSWORD_CHANGE_FAIL":
            return setPasswordFail(state, action);
        case actionTypes.CORE_SET_ACTIVE_CUSTOMER_START:
            return setActiveCustomerStart(state, action);
        case actionTypes.CORE_SET_ACTIVE_CUSTOMER_SUCCESS:
            return setActiveCustomerSuccess(state, action);
        case actionTypes.CORE_SET_ACTIVE_CUSTOMER_FAIL:
            return setActiveCustomerFail(state, action);
        case actionTypes.BEGIN_SUPPORT_SESSION_START:
            return supportSessionStart(state, action);
        case actionTypes.BEGIN_SUPPORT_SESSION_SUCCESS:
            return supportSessionSuccess(state, action);
        case actionTypes.BEGIN_SUPPORT_SESSION_FAIL:
            return supportSessionFail(state, action);

        case actionTypes.END_SUPPORT_SESSION_START:
            return endSupportSessionStart(state, action);
        case actionTypes.END_SUPPORT_SESSION_SUCCESS:
            return endSupportSessionSuccess(state, action);
        case actionTypes.END_SUPPORT_SESSION_FAIL:
            return endSupportSessionFail(state, action);

        case actionTypes.CHANGE_LOGGED_USER_INFORMATION_START:
            return changeLoggedUserStart(state, action);
        case actionTypes.CHANGE_LOGGED_USER_INFORMATION_SUCCESS:
            return changeLoggedUserSuccess(state, action);
        case actionTypes.CHANGE_LOGGED_USER_INFORMATION_FAIL:
            return changeLoggedUserFail(state, action);
        //Fetching  user setting
        case actionTypes.CORE_AUTH_SAVE_FILTER_START:
            return changeColumnsFilterSettingsStart(state, action);
        case actionTypes.CORE_AUTH_SAVE_FILTER_SUCCESS:
            return changeColumnsFilterSettingsSuccess(state, action);
        case actionTypes.CORE_AUTH_SAVE_FILTER_FAIL:
            return changeColumnsFilterSettingsFail(state, action);

        default:
            return state;
    }
};

export default reducer;
