import React, { useState } from "react";
import * as actions from "./store/permissionActions";
import { connect } from "react-redux";
import { withStyles, LinearProgress } from "@material-ui/core";
import PermissionList from "./PermissionList";
import PermissionCreate from "./PermissionCreate";
import PermissionDelete from "./PermissionDelete";
import PermissionEdit from "./PermissionEdit";
import * as action from "../../np-core/Auth/store/authActions";
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';
import { downloadFile } from '../../np-utilities';
import moment from 'moment';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: "auto",
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down("sm")]: {
            padding: 0,
            margin: 0,
            width: "100%"
        }
    }
});

const Permission = props => {
    const { translations, classes, activeCustomer, permission } = props;
    const fetching = permission.fetching;
    const [addModalState, setAddModalState] = useState({ open: false, customers: [] });
    const [editModalState, setEditModalState] = useState({ open: false, customers: [] });
    const [deleteModalState, setDeleteModalState] = useState({ PermissionID: null, PermissionIDs: [], open: false, forBulk: false });

    const handleCreate = async event => {
        if (!event) {
            setAddModalState({ open: false, customers: [] });
            return;
        }
        setAddModalState({ open: true });
    };

    const handleDelete = async event => {
        if (!event) {
            setDeleteModalState({ ...deleteModalState, open: false });
            return;
        }
        setDeleteModalState({ ...deleteModalState, PermissionID: event.currentTarget.value, open: true });
    };

    const handleExport = async event => {
        let permissionData = await props.onExport({ ActiveCustomerID: activeCustomer });
        if (permissionData) {
            return downloadFile(permissionData, `permission_data_${moment().valueOf()}.xlsx`, { ActiveCustomerID: activeCustomer });
        }
        props.sendMessage('File not found!', 'error');
    }

    const handleEdit = async event => {
        if (!event) {
            setEditModalState({ open: false, customers: [] });
            return;
        }
        let translationId = event.currentTarget.value;
        await props.loadPermission({ PermissionID: translationId, ActiveCustomerID: activeCustomer });
        setEditModalState({ open: true });
    };

    return (
        <>
            {props.hasPermission("translate_list") ? (
                <div>
                    <div className={classes.progressBarr}>{fetching ? <LinearProgress color="secondary" /> : null}</div>
                    <PermissionList
                        uploadPermission={props.uploadPermissions}
                        sendMessage={props.sendMessage}
                        permission={permission}
                        handleExport={handleExport}
                        activeCustomer={activeCustomer}
                        columnsState={props.columns}
                        saveColumnsFilter={props.saveColumnsFilter}
                        translations={props.translations}
                        fetchPermissions={props.fetchPermissions}
                        hasPermission={props.hasPermission}
                        hasAtLeastOnePermission={props.hasAtLeastOnePermission}
                        handleCreate={handleCreate}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                    />
                </div>
            ) : null}
            {props.hasPermission("translate_create") ? (
                <PermissionCreate
                    activeCustomer={activeCustomer}
                    open={addModalState.open}
                    handleOpen={handleCreate}
                    createPermission={props.createPermission}
                    translations={translations}
                />
            ) : null}
            {props.hasPermission("translate_delete") ? (
                <PermissionDelete
                    activeCustomer={activeCustomer}
                    entityName={"Permission"}
                    translations={props.translations}
                    handleDelete={handleDelete}
                    deleteModalState={deleteModalState}
                    onDeletePermission={props.deletePermission}
                />
            ) : null}
            {props.hasPermission("translation_load") ? (
                <PermissionEdit
                    activeCustomer={activeCustomer}
                    handleEdit={handleEdit}
                    open={editModalState.open}
                    permission={permission}
                    translations={props.translations}
                    editPermission={props.editPermission}
                />
            ) : null}
        </>
    );
};

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        translations: state.translate && state.translate.translations ? state.translate.translations.activeTranslations || {} : {},
        permission: state.admin.permission,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        hasPermission: permission => { return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'); },
        hasAtLeastOnePermission: permissions => { return state.core.login.permissions.some(permission => permissions.includes(permission)) || state.core.login.permissions.includes('*'); }

    };
};
const mapDispatchToProps = dispatch => {
    return {
        fetchPermissions: filterParams => dispatch(actions.fetchPermissions(filterParams)),
        createPermission: data => dispatch(actions.createPermissions(data)),
        deletePermission: filterParams => dispatch(actions.deletePermissions(filterParams)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),
        uploadPermissions: data => dispatch(actions.uploadPermissions(data)),
        onExport: (customerID) => dispatch(actions.exportPermission(customerID)),
        loadPermission: filterParams => dispatch(actions.loadPermission(filterParams)),
        editPermission: data => dispatch(actions.editPermissions(data)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Permission));
