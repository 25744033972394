import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { withStyles , MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import {
    LocationCity as CustomersIcon,
    PlayArrow as TrainingIcon,
    MyLocation as SiteIcon,
    LibraryBooks as ModuleIcon,
    People as UsersIcon,
    Security as AdminIcon,
    Code,
    MonetizationOn as BillingIcon,
    Language as LanguageIcon,
    Explore as EnvironmentIcon,
    Build as BuildIcon,
    AssignmentInd as ApplicantIcon,
    Score as ScoreCriteriaIcon,
    SupervisedUserCircle as RolesIcon,
    CloudDownload as DownloadIcon,
    BugReport as ProblemIcon,
    // HowToReg as UserActivityIcon
    // Commute as CommuteIcon
} from '@material-ui/icons';
import { connect } from 'react-redux';
import Notifier from './modules/np-dashboard/Notifier/Notifier';
import Dashboard from './modules/np-dashboard/Dashboard/Dashboard'
import Login from './modules/ms-users/Login';
import * as authActions from './modules/np-core/Auth/store/authActions';
import ChangeActiveCustomerForm from './modules/np-dashboard/CustomerBar';
// Custom msqt components
import { routes as profileRoutes } from './modules/ms-profile';
import { subComponents as adminSubComponents, routes as adminRoutes } from './modules/ms-admin';
import { subComponents as billingSubComponents, routes as billingRoutes } from './modules/ms-billing';
import { isSuperUser } from './modules/np-utilities';
import PasswordExpired from "./modules/ms-users/ExpiredUserPassword/";

import { routes as customerRoutes } from './modules/ms-customer';
import { routes as licensePrototypeRoutes } from './modules/ms-licensePrototype';
import { routes as userRoutes } from './modules/ms-users';
import { routes as trainingRoutes } from './modules/ms-training';
import { routes as moduleRoutes } from './modules/ms-module';
import { routes as environmentRoutes } from './modules/ms-environment';
import { routes as languageRoutes } from './modules/ms-language';
import { routes as trainingModulesRoutes } from './modules/ms-trainingModules';
import { routes as applicantRoutes } from './modules/ms-applicant';
import { routes as siteRoutes } from './modules/ms-site';
import { routes as rolesRoutes } from "./modules/ms-roles";
// import { routes as userActivityRoutes } from './modules/ms-userActivity'

import { subComponents as downloadSubComponents, routes as downloadRoutes } from "./modules/ms-download";
import { routes as problemReportRoutes } from './modules/ms-problemReports';
import { adminTheme, normalUserTheme } from './theme'


import { routes as scoreCriteriaRoutes } from './modules/ms-scoreCriteria';

import { enqueueSnackbar } from './modules/np-dashboard/Notifier/store/notifierActions';

const styles = theme => ({

    logo: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            width: '50px'
        }
    },
    root: {
        display: 'block',
        position: 'relative',
        height: '100%',
        top: 0,
        marginTop: '-8px',
        marginLeft: '-6px'
    }, 
});

const customLoginForm = () => {
    return <Login loginPasswordValid={true} buttonStyle={{
        variant: 'contained',
        color: 'primary',
        style: { color: 'white', marginTop: '5px' },
        fullWidth: true
    }} />;
};

const customPasswordForm = () => {
    return <PasswordExpired />;
};
const App = (props) => {
    const { classes, isAuthenticated, onLogout, permissions, passwordExpired } = props;
    const theme = createTheme(isSuperUser() ? adminTheme : normalUserTheme);
    const LicenseBuildIcon = () => {
        return <><BuildIcon /></>
    };

    let sidebarRoutes = [
        {
            name: "Download",
            icon: DownloadIcon,
            permissions: ["download"],
            basePath: "/downloads",
            subComponents: downloadSubComponents()
        },
        {
            name: 'Site',
            icon: SiteIcon,
            permissions: ['site'],
            path: '/sites',
            subComponents: null
        },
        {
            name: 'Score criteria',
            icon: ScoreCriteriaIcon,
            permissions: ['score_criteria_list'],
            path: '/score-criteria',
            subComponents: null
        },
        {
            name: 'Training',
            icon: TrainingIcon,
            permissions: ['training'],
            path: '/trainings',
            subComponents: null
        },
        {
            name: 'Module',
            icon: ModuleIcon,
            permissions: ['module'],
            path: '/modules',
            subComponents: null
        },
        {
            name: 'Training modules',
            icon: ModuleIcon,
            permissions: ['training_modules'],
            path: '/training-modules',
            subComponents: null
        },
        {
            name: 'Environment',
            icon: EnvironmentIcon,
            permissions: ['environment'],
            path: '/environments',
            subComponents: null
        },
        {
            name: 'Language',
            icon: LanguageIcon,
            permissions: ['language'],
            path: '/languages',
            subComponents: null
        },
        {
            name: 'Applicants',
            icon: ApplicantIcon,
            permissions: ['applicant'],
            path: '/applicants',
            subComponents: null
        },
        {
            name: 'Customers',
            icon: CustomersIcon,
            permissions: ['customer'],
            path: '/customers',
            subComponents: null
        },
        {
            name: "Roles",
            icon: RolesIcon,
            permissions: ["role"],
            path: "/roles",
            subComponents: null
        },
        {
            name: 'Users',
            icon: UsersIcon,
            permissions: ['user'],
            path: '/users',
            subComponents: null
        },
        {
            name: 'Billing info',
            icon: BillingIcon,
            permissions: ['billing_info'],
            basePath: '/billing',
            subComponents: billingSubComponents
        },
        {
            name: "License prototype",
            icon: LicenseBuildIcon,
            permissions: ["license_prototype"],
            path: "/license-prototype",
            subComponents: null
        },
        {
            name: 'Problem',
            icon: ProblemIcon,
            permissions: ['problem_report'],
            path: '/problem',
            subComponents: null
        },
        {
            name: 'Admin',
            icon: AdminIcon,
            permissions: ['*'],
            basePath: '/admin',
            subComponents: adminSubComponents
        },
    
    ];

    console.warn = () => {};

    const handleLogout = () => {
        return onLogout(null, props.activeCustomer);
    };


    return (
    !isAuthenticated ? 
    
    (
        <>
        <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
            <Routes>
                {/* login route */}
                <Route path="/login/*" element={customLoginForm()} />
                {/* unauthenticated users are redirected to login route */}
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
            <Notifier />
        </div>
        </MuiThemeProvider>
    </>
    ) : passwordExpired ? (
        <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
            <Routes>
                <Route path="*" element={<Navigate to="/change-password" />} />
                <Route path="/change-password/*"  element={customPasswordForm()} />
            </Routes>
            <Notifier />
        </div>
        </MuiThemeProvider>
    ) : (
        <>
        <MuiThemeProvider theme={theme}>

                <div className={classes.root}>
                    <Dashboard sendMessage={props.sendMessage}
                        translations={{}}
                        sidebarRoutes={sidebarRoutes}
                        downloadData={props.download}

                        classes={{
                            logo: classes.logo,
                        }}
                        appBarOptions={{ myProfile: true, search: false, download: true }}
                        handleLogout={handleLogout} sideBarOpenCustomIcon={<Code />} sideBarCloseCustomIcon={<Code />}>
                        <Routes>
                            <Route path="/login" element={<Navigate to="/changeActiveCustomer" />} />
                            <Route path="changeActiveCustomer" element={<ChangeActiveCustomerForm/>} />
                            <Route path="/logout/*" element={() => {
                                onLogout(null, props.activeCustomer);
                                return null;
                            }} />
                        </Routes>
                        {profileRoutes('/myProfile', permissions)}
                        {userRoutes('/users', permissions)}
                        {licensePrototypeRoutes('/license-prototype', permissions)}
                        {customerRoutes('/customers', permissions)}
                        {adminRoutes('/admin', permissions)}
                        {billingRoutes('/billing', permissions)}
                        {trainingRoutes('/trainings', permissions)}
                        {trainingModulesRoutes('/training-modules', permissions)}
                        {moduleRoutes('/modules', permissions)}
                        {environmentRoutes('/environments', permissions)}
                        {languageRoutes('/languages', permissions)}
                        {rolesRoutes("/roles", permissions)}
                        {problemReportRoutes("/problem", permissions)}


                        {applicantRoutes('/applicants', permissions)}
                        {siteRoutes('/sites', permissions)}
                        {scoreCriteriaRoutes('/score-criteria', permissions)}
                        {downloadRoutes('/downloads', permissions)}
                        {/* {userActivityRoutes('/userActivity', permissions)} */}

                    </Dashboard>
                </div>
                </MuiThemeProvider>
    </>))  
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.core.login.token !== null,
        permissions: state.core.login.permissions,
        activeCustomer: state.core.login.activeCustomer ? state.core.login.activeCustomer.CustomerID : null,
        hasPermission: permission => state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'),
        translations: {},
        passwordExpired: state.core.login.user && state.core.login.user.PasswordExpires ? state.core.login.user.PasswordExpires !== null : null,

        download: state.download && state.download.download ? state.download.download : {},
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLogout: (data, activeCustomerID) => dispatch(authActions.logout(data, activeCustomerID)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(App));
