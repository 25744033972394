import React from 'react';

import classes from './Input.scss';
import {
    TextField,
    Select,
    MenuItem,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    Button,
    InputLabel,
    Input,
    InputAdornment,
    IconButton,
    FormControl
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import { DateFormatInput } from 'material-ui-next-pickers';
import { KeyboardDatePicker } from '@material-ui/pickers';
import MultiSelect from '../np-components/MultiSelect/MultiSelect';
import DraftEditor from '../np-components/DraftEditor/DraftEditor';
import ImageEditor from '../np-components/ImageEditor/ImageEditor';
import Image from 'material-ui-image';
import {DropzoneArea} from 'material-ui-dropzone'


const InputForm = props => {
    let inputElement = null;
    const inputClasses = [classes.InputElement];
    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Invalid);
    }
  

    switch (props.elementType) {
        case 'input':
            inputElement = (
                <>
                    <TextField
                        className={inputClasses.join(' ')}
                        {...props.elementConfig}
                        value={props.value !== undefined ? props.value : ''}
                        onChange={props.changed}
                        label={props.label}
                    />
                    {props.formHelperText ? <FormHelperText style={{ marginTop: '4px' }}>{props.formHelperText}</FormHelperText> : null}

                    {props.rightButton ? (
                        <>
                            {' '}
                            <Button style={{ marginTop: 20 }} onClick={props.rightButton.onClick} value={props.rightButton.value}>
                                X
                            </Button>
                            <br />{' '}
                        </>
                    ) : null}
                </>
            );
            break;

        case 'checkbox':
            inputElement = (
                <div>
                    {props.label ? <FormLabel component="legend">{props.label}</FormLabel> : null}
                    <FormGroup>
                        {props.elementConfig.options.map(option => (
                            <FormControlLabel
                                key={option.ID}
                                control={
                                    <Checkbox
                                        name={option.Name}
                                        color={'primary'}
                                        disabled={props.elementConfig.disabled}
                                        checked={option.Checked}
                                        onChange={props.checkboxChanged}
                                    />
                                }
                                label={option.Name}
                            />
                        ))}
                    </FormGroup>
                    {props.formHelperText ? <FormHelperText style={{ marginTop: '4px' }}>{props.formHelperText}</FormHelperText> : null}
                </div>
            );
            break;
        case 'select':
            inputElement = (
                <div style={props.style}>
                    <FormControl style={{ width: '100%' }} disabled={props.disabled}>
                        {props.text ? <FormHelperText>{props.text}</FormHelperText> : null}
                        <Select
                            className={inputClasses.join(' ')}
                            {...props.elementConfig}
                            key={`select_${props.elementConfig.id}`}
                            id={`select_${props.elementConfig.id}`}
                            value={props.value ? props.value : ''}
                            onChange={props.changed}
                        >
                            {props.elementConfig.options.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.displayValue}
                                </MenuItem>
                            ))}
                        </Select>
                        {props.formHelperText ? <FormHelperText>{props.formHelperText}</FormHelperText> : null}
                    </FormControl>
                </div>
            );
            break;
        case 'select_v2':
            inputElement = (
                <>
                    <FormControl style={{ width: '100%' }} disabled={props.disabled} margin="dense">
                        {props.text ? <p> {props.text} </p> : null}
                        {props.inputLabel ? (
                            <InputLabel shrink htmlFor="select-multiple">
                                {props.inputLabel}
                            </InputLabel>
                        ) : null}
                        <Select {...props.elementConfig} className={inputClasses.join(' ')} value={props.value ? props.value : ''} onChange={props.changed}>
                            {props.elementConfig.options.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.displayValue}
                                </MenuItem>
                            ))}
                        </Select>
                        {props.formHelperText ? <FormHelperText>{props.formHelperText}</FormHelperText> : null}
                    </FormControl>
                </>
            );
            break;
        case 'autocomplete':
            inputElement = (
                <>
                    <FormControl style={{ width: '100%' }} disabled={props.disabled} margin="dense">
                        {props.text ? <p> {props.text} </p> : null}
                        <Autocomplete
                            id="combo-box-demo"
                            options={props.elementConfig.options}
                            value={props.value}
                            onChange={(event, newValue) => {
                                props.autoChanged(newValue);
                            }}
                            getOptionLabel={option => (typeof option.displayValue === 'string' ? option.displayValue : '')}
                            renderInput={params => <TextField {...params} label={props.elementConfig.label} margin="normal" fullWidth />}
                        />
                        {props.formHelperText ? <FormHelperText>{props.formHelperText}</FormHelperText> : null}
                    </FormControl>
                </>
            );
            break;
        case 'multi-select':
            inputElement = (
                <>
                    {props.formHelperText ? <FormHelperText>{props.formHelperText}</FormHelperText> : null}

                    <MultiSelect
                        {...props.elementConfig}
                        selectOptions={props.elementConfig.options}
                        selectedOptions={props.elementConfig.selected}
                        placeholder={props.elementConfig.label}
                        name={props.elementConfig.name}
                        getMultiSelectData={props.getMultiSelectData}
                    />
                </>
            );
            break;

        case 'editor':
            inputElement = (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {props.formHelperText ? <FormHelperText>{props.formHelperText}</FormHelperText> : null}
                    <DraftEditor disabled={props.disabled} toolbarCustom={props.toolbarCustom} editorToolbarHiddenTextarea={props.editorToolbarHiddenTextarea} editorToolbarHidden={props.editorToolbarHidden} getData={props.getData} value={props.value ? props.value : ''} />
                    {props.elementConfig.exampleValues ? <DraftEditor disabled={true} readOnly={true} editorToolbarHidden={true} editorToolbarHiddenTextarea={true} value={props.elementConfig.exampleValues ? props.elementConfig.exampleValues : ''} /> : null}
                </div>
            );

            break;

        case 'datePicker':
            inputElement = (
                <>
                
                    {props.formHelperText ? <FormHelperText>{props.formHelperText}</FormHelperText> : null}
                    <KeyboardDatePicker
                        clearable
                        className={inputClasses.join(' ')}
                        onChange={props.dateChanged}
                        format={'DD.MM.YYYY'}
                        {...props.elementConfig}
                        value={props.value}
                    />
                    {props.formHelperTextBottom ? <FormHelperText>{props.formHelperTextBottom}</FormHelperText> : null}
                </>
            );
            break;
        case 'datePickerTheme':
            inputElement = (
                <>
                    {props.formHelperText ? <FormHelperText>{props.formHelperText}</FormHelperText> : null}
                    <KeyboardDatePicker
                        clearable
                        className={inputClasses.join(' ')}
                        onChange={props.dateChanged}
                        format={'DD.MM.YYYY'}
                        {...props.elementConfig}
                        value={props.value}
                    />
                    {props.formHelperTextBottom ? <FormHelperText>{props.formHelperTextBottom}</FormHelperText> : null}
                </>
            );
            break;

        // case 'inlineDatepicker':
        //     inputElement = (
        //         <InlineDatePicker
        //             className={inputClasses.join(' ')}
        //             {...props.elementConfig}
        //             value={props.value ? props.value : ''}
        //             onChange={props.changed}
        //             label={props.label}
        //         />
        //     );
        //     break;

        // case 'dateFormatInput':
        //     inputElement = (
        //         <DateFormatInput
        //             className={inputClasses.join(' ')}
        //             {...props.elementConfig}
        //             value={props.value ? props.value : ''}
        //             onChange={props.changed}
        //             label={props.label}
        //         />
        //     );
        //     break;
        case 'file':
            let accept = props.elementConfig.accept ? props.elementConfig.accept : '';
            let style = props.elementConfig.style ? props.elementConfig.style : { marginTop: 200 };
            inputElement = props.elementConfig.dragDrop ?(
                <>
                <DropzoneArea
                filesLimit={1}
                maxFileSize={5368709120}
                showAlerts={true}
                showPreviewsInDropzone={true}
                    dropzoneText= {props.elementConfig.text ? props.elementConfig.text : 'Drag and drop an image file here or click'}
                    onChange={props.elementConfig.handleUpload}
                    
                    getFileRemovedMessage={(fileName)=>{ 
                      const text= props.elementConfig.translations ? (
                            `${props.elementConfig.translations['file'] || 'File'} ${fileName} ${props.elementConfig.translations['removed'] || 'removed'}. `
                        ) : `File ${fileName} removed.`

                        return text;
                     }}
                     getFileAddedMessage={(fileName)=>{ 
                        const text= props.elementConfig.translations ? (
                              `${props.elementConfig.translations['file'] || 'File'} ${fileName} ${props.elementConfig.translations['successfully_added'] || 'successfully added'}. `
                          ) : `File ${fileName} successfully added.`
  
                          return text;
                       }}
                       getFileLimitExceedMessage ={(filesLimit)=>{ 
                        const text= props.elementConfig.translations ? (
                              `${props.elementConfig.translations['maximum_allowed_number'] || 'Maximum allowed number of files exceeded. Only'} ${filesLimit} ${props.elementConfig.translations['allowed'] || 'allowed'}. `
                          ) : `Maximum allowed number of files exceeded. Only ${filesLimit} allowed`
  
                          return text;
                       }}
                       getDropRejectMessage={(rejectedFile)=>{ 
                           
                        const text= props.elementConfig.translations ? (
                              `${props.elementConfig.translations['File'] || 'File'} ${props.elementConfig.translations['was_rejected'] || 'was rejected'}. `
                          ) : `File was rejected.`
  
                          return text;
                       }}
                    acceptedFiles={accept[0] === '*'?[] :accept }
                />
                </>
            )
            :
            (
                <>
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        accept={accept}
                        id={props.elementConfig.ID ? props.elementConfig.ID : 'file-element-id'}
                        onChange={props.elementConfig.handleUpload}
                    />
                    <label htmlFor={props.elementConfig.ID ? props.elementConfig.ID : 'file-element-id'}>
                        {props.formHelperText ? <FormHelperText style={{ marginBottom: '4px' }}>{props.formHelperText}</FormHelperText> : null}
                        <Button
                            variant="outlined"
                            component="span"
                            fullWidth={props.elementConfig.fullWidth ? props.elementConfig.fullWidth : true}
                            style={style}
                        >
                            {props.elementConfig.text ? props.elementConfig.text : 'Upload'}
                        </Button>
                        <br />
                    </label>
                    {props.elementConfig.fileName}
                </>
            );
            break;
            case 'password':
                inputElement = (
                    <>
        <FormControl  style={{ width: '100%' }} >
        <InputLabel htmlFor="standard-adornment-password">{props.label}</InputLabel>
            <Input
              id="standard-adornment-password"
              type={props.showPassword? 'text' : 'password'}
              value={props.value !== undefined ? props.value : ''}
              onChange={props.changed}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>props.handleClickShowPassword('password')}
                    onMouseDown={props.handleMouseDownPassword}
                  >
                    {props.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
                    </>
                );
                break;

           
        case 'previewImage':
            inputElement = (
                <>
                    <div style={{ width: '100%', height: '100%', textAlign: 'center', justifyContent: 'center' }}>
                        {props.formHelperText && props.value !== '' && props.value !== undefined ? (
                            <FormHelperText>{props.formHelperText}</FormHelperText>
                        ) : null}
                        {props.value === '' || props.value === undefined ? (
                            props.elementConfig.translations ? (
                                props.elementConfig.translations['image_will_be_displayed'] || 'Image will be displayed here'
                            ) : (
                                    'Image will be displayed here'
                                )
                        ) : props.elementConfig.editor ? (
                            <div>
                                <ImageEditor saveImage={props.getImageEditorValue} src={props.value} {...props.elementConfig.editor} />
                            </div>
                        ) : (
                            <Image
                            src={props.value ? props.value : ''}
                            aspectRatio={(16/9)}
                            disableSpinner
                        />    
                           )}
                    </div>
                </>
            );
            break;
        default:
            inputElement = (
                <TextField className={inputClasses.join(' ')} {...props.elementConfig} value={props.value ? props.value : ''} onChange={props.changed} />
            );
    }

    return (
        <div className={props.custom ? props.custom : classes.Input}>
            {/* <label className={classes.Label}>{props.label}</label> */}
            {inputElement}
        </div>
    );
};

export default InputForm;
