import React, { useState } from 'react';
import { withStyles, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';

import EnvironmentList from './EnvironmentList';
import * as actions from './store/environmentActions';
import EnvironmentCreate from './EnvironmentCreate';
import EnvironmentEdit from './EnvironmentEdit';
import EnvironmentDelete from './EnvironmentDelete';
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';
import * as action from '../../np-core/Auth/store/authActions';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
    progressBarr: {
        width: '100%',
        height: 5
    }
});

const Environment = props => {
    const { classes, environments, activeCustomer } = props;
    const fetching = environments.fetching;

    const [addModalState, setAddModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState({ open: false, environmentID: null, environmentIDs: [], forBulk: false });

    const handleOpen = () => {
        setAddModalState(!addModalState);
    };

    const handleEdit = async event => {
        if (!event) {
            setEditModalState(!editModalState);
            return;
        }

        const environmentID = event.currentTarget.value;
        await props.onFetchEnvironment({ EnvironmentID: environmentID, ActiveCustomerID: activeCustomer });
        setEditModalState(!editModalState);
    };

    const handleDelete = async event => {
        if (!event) {
            setDeleteModalState({ open: false, environmentID: null, environmentIDs: [], forBulk: false });
            return;
        }
        const environmentID = event.currentTarget.value;
        setDeleteModalState({ ...deleteModalState, open: true, environmentID: environmentID });
    };

    const handleBulkDelete = environmentIDS => {
        setDeleteModalState({ ...deleteModalState, open: true, environmentIDs: environmentIDS, forBulk: true });
    };

    return (
        <div className={classes.root}>
            <div className={classes.progressBarr}>
                {fetching ? <LinearProgress color="primary" /> : null}
            </div>
            {props.hasPermission('environment_list') ? <EnvironmentList activeCustomer={activeCustomer} columnsState={props.columns} saveColumnsFilter={props.saveColumnsFilter} activeExternalServiceAvailable={props.activeExternalServiceAvailable} hasAtLeastOnePermission={props.hasAtLeastOnePermission} hasPermission={props.hasPermission} handleBulkDelete={handleBulkDelete} environments={environments} onFetchEnvironments={props.onFetchEnvironments} handleEdit={handleEdit} handleDelete={handleDelete} handleCreate={handleOpen} /> : null}
            {props.hasPermission('environment_create') ? <EnvironmentCreate activeCustomer={activeCustomer} loading={environments.creating} handleOpen={handleOpen} open={addModalState} createEnvironment={props.onCreateEnvironment} /> : null}
            {props.hasPermission('environment_load') ? <EnvironmentEdit activeCustomer={activeCustomer} handleEdit={handleEdit} open={editModalState} hasPermission={props.hasPermission} loading={environments.saving} environment={environments.loaded} editEnvironment={props.onEditEnvironment} /> : null}
            {props.hasPermission('environment_delete') ? <EnvironmentDelete activeCustomer={activeCustomer} entityName={'Environment'} handleDelete={handleDelete} deleteModalState={deleteModalState} deleteEnvironment={props.onDeleteEnvironment} bulkDeleteEnvironments={props.onBulkDeleteEnvironments} /> : null}
        </div >
    );
};

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        environments: state.environment.environments,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        hasPermission: permission => { return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'); },
        hasAtLeastOnePermission: permissions => { return state.core.login.permissions.some(permission => permissions.includes(permission)) || state.core.login.permissions.includes('*'); }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchEnvironments: filter => dispatch(actions.fetchEnvironments(filter)),
        onCreateEnvironment: environment => dispatch(actions.createEnvironment(environment)),
        onFetchEnvironment: environment => dispatch(actions.loadEnvironment(environment)),
        onEditEnvironment: environment => dispatch(actions.editEnvironment(environment)),
        onDeleteEnvironment: environment => dispatch(actions.deleteEnvironment(environment)),
        onBulkDeleteEnvironments: environment => dispatch(actions.bulkDeleteEnvironment(environment)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Environment));