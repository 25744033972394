import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Paper, withStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import CustomerEdit from './CustomerEdit'
import { parseURL, BackButton } from '../../../ms-utilities';
import * as actions from '../store/customerActions';
import AccountSection from './AccountSection';
import * as billingActions from '../../../ms-billing/BillingAccount/store/billingAccountActions';
import { enqueueSnackbar } from "../../../np-dashboard/Notifier/store/notifierActions";
import { updateURL } from "../../../np-utilities";
import * as action from '../../../np-core/Auth/store/authActions';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        paddingRight: theme.spacing(6),
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            paddingRight: 0,
        },
        paper: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden"
        },
        logo: {
            width: '250px',
            margin: '10px'
        },
    },
});

const TabContainer = props => {
    return (
        <Typography component="div" dir={props.dir} style={{ padding: 3 * 3 }}>
            {props.children}
        </Typography>
    );
};

const getTabValue = () => {
    let urlArray = window.location.href.split('#');
    return urlArray.length > 1 ? Number(urlArray[1]) : 0;
};

const CustomerDetails = props => {
    const { classes, theme, customers, activeCustomer, onLoadCustomer } = props;
    const [tabValue, setTabValue] = useState(getTabValue());
    const [loading, setLoading] = useState(false);

    let searchParams = parseURL(window.location.search);

    useEffect(() => {
        setLoading(customers.loading || customers.editing);
    }, [customers.loading, customers.editing]);


    useEffect(() => {
        onLoadCustomer({ CustomerID: searchParams.customerID, ActiveCustomerID: activeCustomer });
    }, [customers.account.loading, activeCustomer, customers.edited, onLoadCustomer, searchParams.customerID, props.account.creating, props.account.deleting]);

    const handleChange = (event, value) => {
        updateURL(value);
        setTabValue(value);
    };

    const handleChangeIndex = index => {
        updateURL(index);
        setTabValue(index);
    };

    return (
        <Paper className={classes.root}>
            {BackButton()}
            <Tabs
                value={tabValue}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                {props.hasPermission('customer_load') ? <Tab label="Details" /> : null}
                {props.hasPermission('billing_account_list') ? <Tab label="Accounts" /> : null}
            </Tabs>
            <SwipeableViews
                index={tabValue}
                onChangeIndex={handleChangeIndex}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            >
                <TabContainer>
                    <CustomerEdit activeCustomer={activeCustomer} hasPermission={props.hasPermission}
                        customerID={searchParams.customerID}
                        fetchCustomers={props.onFetchCustomers} customer={customers.loaded}
                        loading={loading} onEditCustomer={props.onEditCustomer}
                        onLoadCustomer={props.onLoadCustomer} /> </TabContainer>
                <TabContainer>
                    <AccountSection activeCustomer={activeCustomer}
                        onDeleteBillingAccount={props.onDeleteBillingAccount}
                        CustomerID={searchParams.customerID}
                        onSuspend={props.onSuspend}
                        onRecover={props.onRecover}
                        onListUsers={props.onListUsers}
                        createBillingAccount={props.onCreateBillingAccount}
                        hasPermission={props.hasPermission}
                        currentUserID={props.currentUserID}
                        accounts={customers.loaded ? customers.loaded.Accounts : []} /></TabContainer>
            </SwipeableViews>
        </Paper>
    );
};

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        customers: state.customer.customers,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        account: state.billing.accounts,
        currentUserID: state.core.login.user.UserID,
        hasPermission: permission => {
            return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*');
        },
        hasAtLeastOnePermission: permissions => {
            return state.core.login.permissions.some(permission => permissions.includes(permission)) || state.core.login.permissions.includes('*');
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadCustomer: customerID => dispatch(actions.loadCustomer(customerID)),
        onFetchCustomers: customerID => dispatch(actions.fetchCustomers(customerID)),
        onEditCustomer: customerID => dispatch(actions.editCustomer(customerID)),
        onRecover: billingAccountID => dispatch(actions.recoverBillingPlan(billingAccountID)),
        onSuspend: billingAccountID => dispatch(actions.suspendBillingPlan(billingAccountID)),
        onCreateBillingAccount: billingAccount => dispatch(billingActions.createBillingAccount(billingAccount)),
        onDeleteBillingAccount: billingAccount => dispatch(billingActions.deleteBillingAccount(billingAccount)),
        onListUsers: (id) => dispatch(billingActions.listUsers(id)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(CustomerDetails));