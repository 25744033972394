export const CORE_LOGIN_START = 'CORE_LOGIN_START';
export const CORE_LOGIN_SUCCESS = 'CORE|LOGIN_SUCCESS';
export const CORE_LOGIN_FAIL = 'CORE|LOGIN_FAIL';

export const CORE_LOGOUT_START = 'CORE|LOGOUT_START';
export const CORE_LOGOUT_SUCCESS = 'CORE|LOGOUT_SUCCESS';
export const CORE_LOGOUT_FAIL = 'CORE|LOGOUT_FAIL';

export const CORE_SET_ACTIVE_CUSTOMER_START = 'CORE|CORE_SET_ACTIVE_CUSTOMER_START';
export const CORE_SET_ACTIVE_CUSTOMER_SUCCESS = 'CORE|CORE_SET_ACTIVE_CUSTOMER_SUCCESS';
export const CORE_SET_ACTIVE_CUSTOMER_FAIL = 'CORE|CORE_SET_ACTIVE_CUSTOMER_FAIL';

export const BEGIN_SUPPORT_SESSION_START = 'CORE|BEGIN_SUPPORT_SESSION_START';
export const BEGIN_SUPPORT_SESSION_SUCCESS = 'CORE|BEGIN_SUPPORT_SESSION_SUCCESS';
export const BEGIN_SUPPORT_SESSION_FAIL = 'CORE|BEGIN_SUPPORT_SESSION_FAIL';

export const END_SUPPORT_SESSION_START = 'CORE|END_SUPPORT_SESSION_START';
export const END_SUPPORT_SESSION_SUCCESS = 'CORE|END_SUPPORT_SESSION_SUCCESS';
export const END_SUPPORT_SESSION_FAIL = 'CORE|END_SUPPORT_SESSION_FAIL';

export const CHANGE_LOGGED_USER_INFORMATION_START = 'CORE|CHANGE_LOGGED_USER_INFORMATION_START';
export const CHANGE_LOGGED_USER_INFORMATION_SUCCESS = 'CORE|CHANGE_LOGGED_USER_INFORMATION_SUCCESS';
export const CHANGE_LOGGED_USER_INFORMATION_FAIL = 'CORE|CHANGE_LOGGED_USER_INFORMATION_FAIL';

export const EXTEND_SESSION_START = 'CORE|EXTEND_SESSION_START';
export const EXTEND_SESSION_SUCCESS = 'CORE|EXTEND_SESSION_SUCCESS';
export const EXTEND_SESSION_FAIL = 'CORE|EXTEND_SESSION_FAIL';

export const CORE_AUTH_SAVE_FILTER_START = 'CORE|SAVE_COLUMNS_FILTER_START'
export const CORE_AUTH_SAVE_FILTER_SUCCESS = 'CORE|SAVE_COLUMNS_FILTER_SUCCESS'
export const CORE_AUTH_SAVE_FILTER_FAIL = 'CORE|SAVE_COLUMNS_FILTER_FAIL' 