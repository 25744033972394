import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import NPDatatable from '../../np-components/Datatable';
import { Paper, Tooltip, IconButton, Checkbox, Button } from '@material-ui/core';
import useListFilter from '../../np-components/Datatable/listFilter';
import { Edit, Delete, AccountBalance } from '@material-ui/icons';
import { isSuperUser } from '../../np-utilities';
import { formatDate, parseURL, BackButton } from '../../ms-utilities';
import { CustomChip } from '../../ms-components';
import { useNavigate } from "react-router-dom";

const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#d50000" : "#1b2a33",
        '&:hover': {
            backgroundColor: "#757575",
        },
    },
    chipActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    chipPrepared: {
        color: '#af9900',
        marginTop: 8.5,
        fontSize: 15
    },
    chipInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    media: {
        width: 50,
        height: 50
    },
    paletteButtons: {
        color: isSuperUser() ? theme.palette.secondary.main : theme.palette.primary.main,
        margin: theme.spacing(),
        fontSize: '13px',
        width: '100%'
    },
});

const updateFilter = props => {
    const { listFilter } = props;
    let searchParams = parseURL(window.location.search);
    return { ...listFilter, ...searchParams };
};

const BillingAccountList = props => {
    const { classes, billingAccounts, onFetchBillingAccounts, columnsState, activeCustomer } = props;
    const billingAccountsCount = billingAccounts.count;
    const billingAccountsList = billingAccounts.list;

    const [extraFilterInfo, setExtraFilterInfo] = useState({ showDeleted: false });
    const [redirectOptions, setRedirectOptions] = useState({ redirect: false, billingAccountID: 0, redirectPath: '' });
    let tableID = 'BillingAccountID';
    let visible = columnsState && columnsState[tableID];

    const navigate = useNavigate();

    const columns = [
        {
            displayName: 'Action',
            backendName: 'Action',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: 'Customer name',
            backendName: 'CustomerName',
            options: {
                align: 'left',
                sortable: false,
                searchable: true,
                visible: visible ? visible['CustomerName'] : true,
            }
        },
        {
            displayName: 'Billing mode',
            backendName: 'BillingMode',
            options: {
                align: 'left',
                sortable: false,
                searchable: true,
                visible: visible ? visible['BillingMode'] : true,
            }
        },
        {
            displayName: 'Activation date',
            backendName: 'ActivationDate',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['ActivationDate'] : true,
            }
        },
        {
            displayName: 'Billing day',
            backendName: 'BillingDay',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['BillingDay'] : true,
            }
        },
        {
            displayName: 'Expires at',
            backendName: 'ExpiresAt',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['ExpiresAt'] : true,
            }
        },
        {
            displayName: 'Status',
            backendName: 'Active',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Active'] : true,
            }
        },
        {
            displayName: 'Deleted',
            backendName: 'Deleted',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Deleted'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => {
        if (column.backendName !== 'Action') backendNames.push(column.backendName);
        return null;
    })
    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });

    if (!props.hasAtLeastOnePermission(['billing_account_create', 'billing_account_delete', 'billing_account_load', 'billing_account_assign_plans'])) {
        columns.splice(0, 1);
    }

    useEffect(() => {
        if (listFilter.filter) {
            if (!listFilter.filter.q) {
                listFilter.filter.q = {};
            }
            if (!listFilter.filter.q.and) {
                listFilter.filter.q.and = {};
            }
            listFilter.filter.q = {
                ...listFilter.filter.q, ...extraFilterInfo,
                and: updateFilter({ listFilter: listFilter.filter.q.and })
            };
            onFetchBillingAccounts({ ...listFilter.filter, ActiveCustomerID: activeCustomer });
        }
    }, [extraFilterInfo, billingAccounts.created, billingAccounts.deleted, billingAccounts.bulkDeleted, billingAccounts.edited, listFilter.filter, onFetchBillingAccounts, activeCustomer]);

   

    const goToAccountOverview = billingAccountID => {
        // const billingAccountID = event.currentTarget.value;
        setRedirectOptions({
            redirect: true,
            billingAccountID: billingAccountID,
            redirectPath: `/billing/account-overview?billingAccountID=${billingAccountID}`
        });
    };
    if (redirectOptions.redirect) {
       navigate(redirectOptions.redirectPath);
    }
    const createActions = (billingAccountID, deleted, planType) => {
        return <>
            {
                props.hasPermission('billing_account_load') ? <Tooltip title="Go to account overview">
                    <IconButton value={billingAccountID} aria-label="Go to account overview"
                        onClick={()=>{goToAccountOverview(billingAccountID)}}>
                        <Edit />
                    </IconButton>
                </Tooltip> : <div></div>
            }
            {
                props.hasPermission('billing_account_delete') && !deleted ?
                    <Tooltip title="Delete billing account">
                        <IconButton value={billingAccountID} aria-label="Delete billing account"
                            onClick={props.handleDelete}>
                            <Delete />
                        </IconButton>
                    </Tooltip> : null
            }
            {planType === 'postpaid' ?
                props.hasPermission('billing_account_assign_plans') ? <Tooltip title="Modify plans">
                    <IconButton value={billingAccountID} aria-label="Modify plans" onClick={props.handlePlansEdit}>
                        <AccountBalance />
                    </IconButton>
                </Tooltip> : null : null
            }
        </>
    };

    const getFormattedStatus = (id, status) => {
        switch (status) {
            case 'active':
                return <CustomChip key={`${id}`} className={classes['chipActive']} value={'Active'} />;
            case 'inactive':
                return <CustomChip key={`${id}`} className={classes['chipInactive']} value={'Inactive'} />;
            case 'prepared':
                return <CustomChip key={`${id}`} className={classes['chipPrepared']} value={'Prepared'} />;
            default:
                return '';
        }
    }

    const handleExtraFilterChange = event => {
        if (event.currentTarget.value === 'showDeleted') {
            setExtraFilterInfo({ ...extraFilterInfo, showDeleted: !extraFilterInfo.showDeleted });
        }
    };

    const customFilterToolbar = () => {
        return <>
            <Checkbox checked={extraFilterInfo.showDeleted}
                value={'showDeleted'}
                onChange={handleExtraFilterChange} /> Show deleted
        </>
    };

   

    const tableOptions = {
        isSelectable: false,
        tableID: tableID,
        saveColumn: true,
        tableSearch: false,
        selectOptions: {
            printing: false,
            generatingReport: false,
            deleting: props.hasPermission('billing_account_delete'),
            creating: props.hasPermission('billing_account_create')
        },
        rowsPerPage: 20,
        count: billingAccountsCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'Billing accounts',
        noMatch: "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return props.handleBulkDelete(IDs);
        },
        onSaveFilter: (columns) => {
            let objToSendColumn = {}
            let objColumns = {}
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible
                return objColumns
            })
            objToSendColumn[`${tableID}`] = objColumns
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }
    };

    const redirectToCustomer = id => {
        setRedirectOptions({
            redirect: true,
            customerID: id,
            redirectPath: `/customers/details?customerID=${id}`
        });
    }

    const getFormattedBillingMode = BillingMode => {
        switch (BillingMode) {
            case 'prepaid':
                return 'Prepaid';
            case 'postpaid':
                return 'Contract';
            default:
                return 'Unknown';
        }
        ;
    }

    let counter = 0;
    let billingAccountsData = billingAccountsList.map(({ AccountID, Status, BillingMode, BillingDay, Customer, Deleted, ActivationDate, ExpiresAt }) => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = AccountID;
        returnObj.visible = true;
        returnObj.values = [
            createActions(AccountID, Deleted, BillingMode),
            <Button key={Customer ? Customer.CustomerID : ''} className={classes.paletteButtons} size='small'
                onClick={() => redirectToCustomer(Customer ? Customer.CustomerID : '')}> {Customer ? Customer.Name : ''}</Button>,
            getFormattedBillingMode(BillingMode),
            formatDate(ActivationDate),
            `On every ${BillingDay}.`,
            formatDate(ExpiresAt),
            getFormattedStatus(AccountID, Status),
            Deleted === true ? 'Yes' : 'No'
        ];

        if (!props.hasAtLeastOnePermission(['billing_account_create', 'billing_account_delete', 'billing_account_load', 'billing_account_assign_plans'])) {
            returnObj.values.splice(0, 1);
        }

        counter++;
        return returnObj;
    });
    return (
        <Paper>
            {BackButton()}
            <NPDatatable handleCreate={props.handleCreate}
                customStyles={classes}
                data={billingAccountsData}
                columns={columns}
                options={tableOptions}
                customFilterToolbar={customFilterToolbar}
                innerHeight={400}
            />
        </Paper>
    );
}

export default withStyles(styles)(BillingAccountList);