import React, { useState, useEffect } from 'react';
import Form from '../../../np-form/Form';
import { Dialog, withStyles } from '@material-ui/core';
import * as formUtility from '../../../np-form/utility';
import { isSuperUser } from '../../../np-utilities';
import moment from "moment";

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        minHeight: '500px',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});

const status = [{ value: 'active', displayValue: 'Active' }, { value: 'inactive', displayValue: 'Inactive' }];

const updateFormState = props => {
    const { classes, licensePrototypes } = props;

    return {
        Name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Name',
                label: 'Name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                autoFocus: true

            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        SecretKey: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Secret key',
                label: 'Secret key',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        Status: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Billing plan status',
                margin: "dense",
                fullWidth: true,
                options: status ? [...status] : []
            },
            formHelperText: "Please select software license status",
            value: 'active',
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        ExpiresAt: {
            elementType: 'datePicker',
            elementConfig: {
                type: 'datePicker',
                label: 'No expiration date',
                margin: "dense",
                fullWidth: true,
                placeholder: 'No expiration date',
                disablePast: true,
                clearable: true,
            },
            formHelperTextBottom: 'Please select expiration date (leave empty if the plan does not have expiration date)',
            value: moment().add(1, 'year'),
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        LicensePrototypeID: {
            elementType: 'select_v2',
            inputLabel: 'License prototype',
            elementConfig: {
                type: 'select',
                label: 'License prototype',
                className: classes ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                options: licensePrototypes ? licensePrototypes : []
            },
            formHelperText: "Please select license prototype",
            value: licensePrototypes && licensePrototypes[0] ? licensePrototypes[0].value : null,
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
    };
};

const SoftwareLicenseCreate = props => {
    const { handleOpen, open, classes, loading, onCreateSoftwareLicense, licensePrototypes, activeCustomer } = props;

    const [softwareLicenseForm, setSoftwareLicenseForm] = useState(updateFormState({ classes, licensePrototypes }));
    const [formIsValid, setFormIsValid] = useState(false);

    useEffect(() => {
        setSoftwareLicenseForm(updateFormState({ classes, licensePrototypes }));
    }, [classes, licensePrototypes]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, softwareLicenseForm);
        setSoftwareLicenseForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const dateChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnDateChange(event, inputIdentifier, softwareLicenseForm);
        setSoftwareLicenseForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const getMultiSelectData = (data, _selectedObject, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnMultiSelectChange(data, inputIdentifier, softwareLicenseForm);
        setSoftwareLicenseForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const submitHandler = async event => {
        event.preventDefault();
        const formData = formUtility.getFormData(softwareLicenseForm);
        formData['AccountID'] = props.AccountID;
        await onCreateSoftwareLicense({ ...formData, ActiveCustomerID: activeCustomer });

        setSoftwareLicenseForm(updateFormState({}));
        setFormIsValid(false);
        handleOpen();
    };

    const cancelHandler = () => {
        setSoftwareLicenseForm(updateFormState({}));
        setFormIsValid(false);
        handleOpen();
    };

    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            fullWidth={true}
            // onClose={cancelHandler}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    cancelHandler();
                }
            }}
        >
            <Form
                formIsValid={formIsValid}
                classes={classes}
                form={softwareLicenseForm}
                formTitle={`Create software license`}
                buttonText={props.hasPermission('software_license_create') ? "Create" : null}
                buttonTextCancel="Cancel"
                submitHandler={submitHandler}
                cancelHandler={cancelHandler}
                inputChangedHandler={inputChangedHandler}
                dateChangedHandler={dateChangedHandler}
                getMultiSelectData={getMultiSelectData}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                cancelButtonDisabled={loading}
            />
        </Dialog>
    )
};
export default withStyles(styles)(SoftwareLicenseCreate);