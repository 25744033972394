import React, { useState } from 'react';
import { withStyles, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';

import TrainingModuleList from './TrainingModulesList';
import * as actions from './store/trainingModulesActions';
import TrainingModuleCreate from './TrainingModulesCreate';
import TrainingModuleEdit from './TrainingModulesEdit';
import TrainingModuleDelete from './TrainingModulesDelete';
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';
import * as action from '../../np-core/Auth/store/authActions';
import * as moduleActions from '../../ms-module/Module/store/moduleActions';
import * as trainingActions from '../../ms-training/Training/store/trainingActions';
import * as siteActions from '../../ms-site/Site/store/siteActions';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
    progressBarr: {
        width: '100%',
        height: 5
    }
});

const TrainingModule = props => {
    const { classes, trainingModules, activeCustomer, modules, trainings, sites } = props;
    const fetching = trainingModules.fetching;

    const [addModalState, setAddModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState({
        open: false,
        trainingID: null,
        trainingIDs: [],
        forBulk: false
    });

    const handleOpen = async () => {
        if (!addModalState.open) {
            await props.listModules({ forList: true, ActiveCustomerID: activeCustomer });
            await props.listTrainings({ forList: true, ActiveCustomerID: activeCustomer });
            await props.listSites({ forList: true, ActiveCustomerID: activeCustomer });
            setAddModalState(!addModalState);
            return;
        }
        setAddModalState(!addModalState);
    };

    const handleEdit = async event => {
        if (!event) {
            setEditModalState(!editModalState);
            return;
        }

        const trainingID = event.currentTarget.value;
        await props.onFetchTrainingModule({ TrainingModulesID: trainingID, ActiveCustomerID: activeCustomer });
        await props.listModules({ forList: true, ActiveCustomerID: activeCustomer });
        await props.listTrainings({ forList: true, ActiveCustomerID: activeCustomer });
        await props.listSites({ forList: true, ActiveCustomerID: activeCustomer });
        setEditModalState(!editModalState);
    };

    const handleDelete = async event => {
        if (!event) {
            setDeleteModalState({ open: false, trainingID: null, trainingIDs: [], forBulk: false });
            return;
        }
        const trainingID = event.currentTarget.value;
        setDeleteModalState({ ...deleteModalState, open: true, trainingID: trainingID });
    };

    const handleBulkDelete = trainingIDS => {
        setDeleteModalState({ ...deleteModalState, open: true, trainingIDs: trainingIDS, forBulk: true });
    };

    return (
        <div className={classes.root}>
            {props.hasPermission('training_modules_list') ?
                <>
                    <div className={classes.progressBarr}>
                        {fetching ? <LinearProgress color="primary" /> : null}
                    </div>
                    <TrainingModuleList activeCustomer={activeCustomer} columnsState={props.columns}
                        saveColumnsFilter={props.saveColumnsFilter}
                        activeExternalServiceAvailable={props.activeExternalServiceAvailable}
                        hasAtLeastOnePermission={props.hasAtLeastOnePermission}
                        hasPermission={props.hasPermission} handleBulkDelete={handleBulkDelete}
                        trainingModules={trainingModules}
                        onFetchTrainingModules={props.onFetchTrainingModules} handleEdit={handleEdit}
                        handleDelete={handleDelete} handleCreate={handleOpen}
                    /></> : null}

            {props.hasPermission('training_modules_create') ?
                <TrainingModuleCreate activeCustomer={activeCustomer} loading={trainingModules.creating}
                    handleOpen={handleOpen}
                    open={addModalState}
                    modules={modules ? modules.list : []}
                    trainings={trainings ? trainings.list : []}
                    sites={sites ? sites.list : []}
                    createTrainingModule={props.onCreateTrainingModule} /> : null}
            {props.hasPermission('training_modules_load') ?
                <TrainingModuleEdit activeCustomer={activeCustomer} handleEdit={handleEdit} open={editModalState}
                    hasPermission={props.hasPermission} loading={trainingModules.saving}
                    training={trainingModules.loaded}
                    editTrainingModule={props.onEditTrainingModule}
                    modules={modules ? modules.list : []}
                    trainings={trainings ? trainings.list : []}
                    sites={sites ? sites.list : []}
                /> : null}
            {props.hasPermission('training_modules_delete') ?
                <TrainingModuleDelete activeCustomer={activeCustomer} entityName={'Training Module Relation'}
                    handleDelete={handleDelete}
                    deleteModalState={deleteModalState}
                    deleteTrainingModule={props.onDeleteTrainingModule}
                    bulkDeleteTrainingModules={props.onBulkDeleteTrainingModules} /> : null}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        trainingModules: state.trainingModule.trainingModules,
        modules: state.module.modules,
        trainings: state.training.trainings,
        sites: state.site.sites,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        hasPermission: permission => {
            return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*');
        },
        hasAtLeastOnePermission: permissions => {
            return permissions.some(permission => state.core.login.permissions.includes(permission)) || state.core.login.permissions.includes('*');
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchTrainingModules: filter => dispatch(actions.fetchTrainingModules(filter)),
        onCreateTrainingModule: training => dispatch(actions.createTrainingModule(training)),
        onFetchTrainingModule: training => dispatch(actions.loadTrainingModule(training)),
        onEditTrainingModule: training => dispatch(actions.editTrainingModule(training)),
        onDeleteTrainingModule: training => dispatch(actions.deleteTrainingModule(training)),
        onBulkDeleteTrainingModules: training => dispatch(actions.bulkDeleteTrainingModule(training)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),
        listModules: data => dispatch(moduleActions.fetchModules(data)),
        listTrainings: data => dispatch(trainingActions.fetchTrainings(data)),
        listSites: data => dispatch(siteActions.fetchSites(data)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(TrainingModule));