import { enqueueSnackbar } from '../np-dashboard/Notifier/store/notifierActions'
import { getAxiosInstance } from './rest';
import { formatMessage } from '../np-utilities';

// Rest call wrapped with Start -> Success or Failed (SSF) redux calls
const ssfRest = props => {

    const { endpoint, start = null, success = null, failed = null, successMessage = '', failedMessage = '', readSuccessMessageFromBackend, readFailMessageFromBackend } = props;
    const rest = getAxiosInstance();

    const SSFRest = async (dispatch, item) => {

        // START
        if (start !== null) {
            dispatch(start());
        }
        try {
            let res = await rest.post(endpoint, item);
            if (res.data.succeeded) {
                const data = res.data.result.data;
                // SUCCESS
                if (success !== null) {
                    dispatch(success(data));
                }
                if (successMessage !== '') {
                    dispatch(enqueueSnackbar({
                        message: readSuccessMessageFromBackend ? res.data.message : formatMessage(successMessage),
                        options: {
                            variant: 'success'
                        }
                    }));
                }
            } else {
                // FAILED
                if (failed !== null) {
                    dispatch(failed(res.error));
                }
                if (failedMessage !== '') {
                    dispatch(enqueueSnackbar({
                        message: readFailMessageFromBackend ? res.data.message : formatMessage(failedMessage),
                        options: {
                            variant: 'error'
                        }
                    }));
                }
            }
        } catch (err) {
            // FAILED
            if (failed !== null) {
                dispatch(failed(err));
            }
            if (failedMessage !== '') {
                dispatch(enqueueSnackbar({
                    message: formatMessage(failedMessage),
                    options: {
                        variant: 'error'
                    }
                }));
            }
        }
    };

    return [SSFRest]
}

export default ssfRest;