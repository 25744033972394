import React, { useState } from 'react';
import classNames from 'classnames';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import DeleteIcon from '@material-ui/icons/Delete';
import CompareArrows from '@material-ui/icons/CompareArrows';

import Bookmarks from '@material-ui/icons/Bookmarks';
import FilterListIcon from '@material-ui/icons/FilterList';
import { withStyles } from '@material-ui/core/styles';
import { Toolbar, Typography, Tooltip, IconButton, Paper, Fab, CircularProgress } from '@material-ui/core';
import FilterDialog from './FilterDialog';
import TableSearch from './TableSearch';
import { Search, Print, NoteAdd, CloudDownload } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import DateRange from '../../ms-components/DateRange';
import moment from 'moment';

const toolbarStyles = theme => ({
    root: {
        marginRight: 0,
        paddingRight: 0
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    actions: {
        flex: '1 1 auto',
        textAlign: 'right'
    },
    selectedActions: {
        display: 'flex',
        flex: '1 1 auto',
        color: theme.palette.text.secondary
    },
    title: {
        display: 'flex',
        flex: '0 0 auto',
        width: 'auto'
    },
    selectedRoot: {
        backgroundColor: theme.palette.background.default,
        flex: '1 1 99%',
        display: 'flex',
        height: '65px',
        position: 'sticky',
        zIndex: 120,
        justifyContent: 'space-between'
    },
    selectedTitle: {
        paddingLeft: '26px',
        top: '50%',
        position: 'relative',
        transform: 'translateY(-50%)'
    },
    button: {
        width: '60%',
        marginLeft: '20%',
        marginBottom: '5px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.buttonText.default,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.buttonText.default

        }
    },
    dateRangeWrapper: {
        paddingLeft: '1rem',
        display: 'flex',
    },
});

const TableToolbar = props => {
    const { numSelected, tableName, classes, columns, tableSearch, options, customStyles, translations } = props;
    const [dialogOpen, setDialogOpen] = useState(false);

    const [tableSearchActive, setTableSearchActive] = useState(false);

    const onSaveColumnFilter = (columns) => {

        options.onSaveFilter(columns)

        setDialogOpen(false);
    };
    const handleDialogClose = () => {

        setDialogOpen(false);
    };
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleSearchOpen = () => {
        if (tableSearchActive === true) {
            props.handleTableSearch('');
        }
        setTableSearchActive(!tableSearchActive);
    };

    return (
        <>
            {numSelected > 0 ? (
                <Paper className={classes.selectedRoot}>
                    <div>
                        <Typography variant="subtitle1" className={classes.selectedTitle}>
                            {numSelected} {translations ? translations['row_selected'] || 'row(s) selected' : 'row(s) selected'}
                        </Typography>
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.selectedActions}>
                        {options.selectOptions && options.selectOptions.deleting ? (
                            <Tooltip title={options.selectOptions.bulkText ? options.selectOptions.bulkText : translations ? translations['delete'] || 'Delete' : 'Delete'}>
                                <IconButton
                                    aria-label={options.selectOptions.bulkText ? options.selectOptions.bulkText : translations ? translations['delete'] || 'Delete' : 'Delete'}
                                    onClick={
                                        props.bulkDelete
                                            ? props.bulkDelete
                                            : () => {
                                                console.log('[Error]: Function not implemented yet!');
                                            }
                                    }
                                >
                                    {options.selectOptions.bulkIcon ? options.selectOptions.bulkIcon : <DeleteIcon />}
                                </IconButton>
                            </Tooltip>
                        ) : null}
                                {options.selectOptions && options.selectOptions.compare ? (
                            <Tooltip title={options.selectOptions.compare ? options.selectOptions.compare : translations ? translations['compare'] || 'Compare' : 'Compare'}>
                                <IconButton
                                    aria-label={options.selectOptions.compare ? options.selectOptions.compare : translations ? translations['compare'] || 'Compare' : 'Compare'}
                                component='div'
                                disabled={numSelected !== 2}
                                    onClick={
                                        props.bulkCompare
                                            ? props.bulkCompare
                                            : () => {
                                                console.log('[Error]: Function not implemented yet!');
                                            }
                                    }
                                >
                                    {options.selectOptions.bulkIcon ? options.selectOptions.bulkIcon : <CompareArrows />}
                                </IconButton>
                            </Tooltip>
                        ) : null}
                        {options.selectOptions && options.selectOptions.archiving ? (
                            <Tooltip title={options.selectOptions.bulkText ? options.selectOptions.bulkText : translations ? translations['archive'] || 'Archive' : 'Archive'}>
                                <IconButton
                                    aria-label={options.selectOptions.bulkText ? options.selectOptions.bulkText : translations ? translations['archive'] || 'Archive' : 'Archive'}
                                    onClick={
                                        props.bulkArchive
                                            ? props.bulkArchive
                                            : () => {
                                                console.log('[Error]: Function not implemented yet!');
                                            }
                                    }
                                >
                                    {options.selectOptions.bulkIcon ? options.selectOptions.bulkIcon : <Bookmarks />}
                                </IconButton>
                            </Tooltip>
                        ) : null}
                        {options.selectOptions && options.selectOptions.printing ? (
                            <Tooltip title={translations ? translations['print_label'] || 'Print label' : "Print label"}>
                                <IconButton
                                    aria-label={translations ? translations['print_label'] || 'Print label' : "Print label"}
                                    onClick={
                                        props.bulkPrintLabel
                                            ? props.bulkPrintLabel
                                            : () => {
                                                console.log('[Error]: Function not implemented yet!');
                                            }
                                    }
                                >
                                    <Print />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                        {options.selectOptions && options.selectOptions.generatingReport ? (
                            <Tooltip title={options.selectOptions.generateReportLabel? options.selectOptions.generateReportLabel:translations ? translations['generate_report_erasure'] || "Generate erasure report" : "Generate erasure report"}>
                                <IconButton
                                    aria-label={translations ? translations['generate_report_erasure'] || "Generate erasure report" : "Generate erasure report"}
                                    onClick={
                                        props.bulkGenerateReport
                                            ? props.bulkGenerateReport
                                            : () => {
                                                console.log('[Error]: Function not implemented yet!');
                                            }
                                    }
                                >
                                    <NoteAdd />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                        {options.selectOptions && options.selectOptions.bulkDownload ? (
                            <Tooltip title={translations ? translations['bulk_download'] || "Bulk download" : "Bulk download"}>
                                <IconButton
                                    aria-label={translations ? translations['bulk_download'] || "Bulk download" : "Bulk download"}
                                    onClick={
                                        props.bulkDownload
                                            ? props.bulkDownload
                                            : () => {
                                                console.log('[Error]: Function not implemented yet!');
                                            }
                                    }
                                >
                                    <CloudDownload />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </div>
                </Paper>
            ) : (
                <Toolbar
                    className={classNames(classes.root, {
                        [classes.highlight]: numSelected > 0
                    })}
                >
                    <div className={classes.title}>
                        {tableSearchActive ? (
                            <TableSearch handleTableSearch={props.handleTableSearch} handleSearchClose={handleSearchOpen} />
                        ) : (
                                <>
                                    <Typography variant="h6" id="tableTitle">
                                        {tableName}
                                    </Typography>
                                    {options.selectOptions && options.selectOptions.creating ? (
                                        <div style={{ display: "flex" }}>
                                            <Fab
                                                variant="extended"
                                                size="small"
                                                color="primary"
                                                aria-label="Add"
                                                disabled={options.selectOptions.createLoading || options.selectOptions.editLoading}
                                                className={customStyles ? customStyles.fab : null}
                                                onClick={
                                                    props.handleCreate
                                                        ? props.handleCreate
                                                        : () => {
                                                            console.log('[Error]:Function not implemented!');
                                                        }
                                                }
                                            >

                                                {options.selectOptions.createLoading ? <CircularProgress size={24} /> : <AddIcon />}
                                                {!options.selectOptions.createLoading ? <Typography style={{ fontSize: 12, color: "white" }}>
                                                    {options.selectOptions.createButtonName ? options.selectOptions.createButtonName : 'Create new'}
                                                </Typography> : null}

                                            </Fab>
                                        </div>


                                    ) : (
                                            <></>
                                        )}
                                </>
                            )}
                    </div>
                    <div className={classes.actions}>
                        {tableSearch ? (
                            <Tooltip title={translations ? translations['search_table'] || "Search table" : "Search table"}>
                                <IconButton aria-label={translations ? translations['search_table'] || "Search table" : "Search table"} onClick={handleSearchOpen}>
                                    <Search />
                                </IconButton>
                            </Tooltip>
                        ) : (
                                <></>
                            )}
                        <Tooltip title={translations ? translations['filter_columns'] || "Filter columns" : "Filter columns"}>
                            <IconButton aria-label={translations ? translations['filter_columns'] || "Filter columns" : "Filter columns"} onClick={handleDialogOpen}>
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <FilterDialog closeFilter={options.closeFilter} columns={columns} classes={classes} translations={translations} saveColumn={options.saveColumn} onSaveFilter={onSaveColumnFilter} open={dialogOpen} handleClose={handleDialogClose} handleFilterCheckboxClick={props.handleFilterCheckboxClick} />
                </Toolbar>
            )}
            {options.dateRangeFilter && options.dateRangeValue ? (
                <div className={classes.dateRangeWrapper}>
                    <DateRange
                        initialValues={options.initialDateRangeValues}
                        value={options.dateRangeValue}
                        setValue={options.setDateRangeValue}
                        minDate={moment().subtract(10, 'years')}
                        translations={translations}
                    />
                    {options.selectOptions && options.selectOptions.exporting ? (
                        <div>
                            <Tooltip
                                title={
                                    options.selectOptions.bulkText
                                        ? options.selectOptions.bulkText
                                        : translations
                                        ? translations['export'] || 'Export'
                                        : 'Export'
                                }
                            >
                                <IconButton
                                    aria-label={
                                        options.selectOptions.bulkText
                                            ? options.selectOptions.bulkText
                                            : translations
                                            ? translations['export'] || 'Export'
                                            : 'Export'
                                    }
                                    onClick={
                                        props.bulkExport
                                            ? props.bulkExport
                                            : () => {
                                                  console.log('[Error]: Function not implemented yet!');
                                              }
                                    }
                                >
                                    {options.selectOptions.bulkIcon ? (
                                        options.selectOptions.bulkIcon
                                    ) : (
                                        <CloudDownload />
                                    )}
                                </IconButton>
                            </Tooltip>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </>
    );
};

export default withStyles(toolbarStyles, { withTheme: true })(TableToolbar);
