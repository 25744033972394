import restCrudReducer from '../../../np-crud/store/restCrudReducer';

const [crudInitialState, crudReducer] = restCrudReducer({
    pluginName: 'CORE',
    entityName: 'Permissions'
});

const initialState = {
    ...crudInitialState
};
const reducer = (state = initialState, action) => {
    // Handle CRUD actions
    const newState = crudReducer(state, action);
    // If CRUD reducer handeled the action
    if (newState !== null) {
        return newState;
    }

    // Handle other actions
    switch (action.type) {
        default:
            return state;
    }
};

export default reducer;
