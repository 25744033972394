import React, { useState } from 'react';
import { withStyles, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';

import ModuleList from './ModuleList';
import * as actions from './store/moduleActions';
import ModuleCreate from './ModuleCreate';
import ModuleEdit from './ModuleEdit';
import ModuleDelete from './ModuleDelete';
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';
import * as action from '../../np-core/Auth/store/authActions';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
    progressBarr: {
        width: '100%',
        height: 5
    }
});

const Module = props => {
    const { classes, modules, activeCustomer } = props;
    const fetching = modules.fetching;

    const [addModalState, setAddModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState({ open: false, moduleID: null, moduleIDs: [], forBulk: false });

    const handleOpen = () => {
        setAddModalState(!addModalState);
    };

    const handleEdit = async event => {
        if (!event) {
            setEditModalState(!editModalState);
            return;
        }

        const moduleID = event.currentTarget.value;
        await props.onFetchModule({ ModuleID: moduleID, ActiveCustomerID: activeCustomer });
        setEditModalState(!editModalState);
    };

    const handleDelete = async event => {
        if (!event) {
            setDeleteModalState({ open: false, moduleID: null, moduleIDs: [], forBulk: false });
            return;
        }
        const moduleID = event.currentTarget.value;
        setDeleteModalState({ ...deleteModalState, open: true, moduleID: moduleID });
    };

    const handleBulkDelete = moduleIDS => {
        setDeleteModalState({ ...deleteModalState, open: true, moduleIDs: moduleIDS, forBulk: true });
    };

    return (
        <div className={classes.root}>
            <div className={classes.progressBarr}>
                {fetching ? <LinearProgress color="primary" /> : null}
            </div>
            {props.hasPermission('module_list') ? <ModuleList activeCustomer={activeCustomer} columnsState={props.columns} saveColumnsFilter={props.saveColumnsFilter} activeExternalServiceAvailable={props.activeExternalServiceAvailable} hasAtLeastOnePermission={props.hasAtLeastOnePermission} hasPermission={props.hasPermission} handleBulkDelete={handleBulkDelete} modules={modules} onFetchModules={props.onFetchModules} handleEdit={handleEdit} handleDelete={handleDelete} handleCreate={handleOpen} /> : null}
            {props.hasPermission('module_create') ? <ModuleCreate activeCustomer={activeCustomer} loading={modules.creating} handleOpen={handleOpen} open={addModalState} createModule={props.onCreateModule} /> : null}
            {props.hasPermission('module_load') ? <ModuleEdit activeCustomer={activeCustomer} handleEdit={handleEdit} open={editModalState} hasPermission={props.hasPermission} loading={modules.saving} module={modules.loaded} editModule={props.onEditModule} /> : null}
            {props.hasPermission('module_delete') ? <ModuleDelete activeCustomer={activeCustomer} entityName={'Module'} handleDelete={handleDelete} deleteModalState={deleteModalState} deleteModule={props.onDeleteModule} bulkDeleteModules={props.onBulkDeleteModules} /> : null}
        </div >
    );
};

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        modules: state.module.modules,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        hasPermission: permission => { return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'); },
        hasAtLeastOnePermission: permissions => { return state.core.login.permissions.some(permission => permissions.includes(permission)) || state.core.login.permissions.includes('*'); }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchModules: filter => dispatch(actions.fetchModules(filter)),
        onCreateModule: module => dispatch(actions.createModule(module)),
        onFetchModule: module => dispatch(actions.loadModule(module)),
        onEditModule: module => dispatch(actions.editModule(module)),
        onDeleteModule: module => dispatch(actions.deleteModule(module)),
        onBulkDeleteModules: module => dispatch(actions.bulkDeleteModule(module)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Module));