import React, { useEffect, useState } from 'react';
import { withStyles  } from '@material-ui/core';
import { isSuperUser } from '../../np-utilities';
import MaterialTable from 'material-table';

let pageSize = 5;

const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#d50000" : "#1b2a33",
        '&:hover': {
            backgroundColor: "#757575",
        },
    },
    chipSiteActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    chipSiteInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    media: {
        width: 50,
        height: 50
    }
});

const filterQuery = filters => {
    let obj = {};
    for (var filter of filters) {
        obj[filter.column.field] = filter.value
    }
    return obj;
};

const SiteList2 = props => {

    const { classes, onFetchSites,sites, activeCustomer  ,tableRef , tableColumns , actions, saveColumnsFilter} = props;
    const [columnObject] = useState(tableColumns);

    const fetchRemoteData = async (queryParams) => {
        props.startFetching();
        queryParams['ActiveCustomerID'] = activeCustomer;
        let q = filterQuery(queryParams.filters);
        queryParams['q'] = {};
        queryParams['q']['and'] = q;


        try {
            let remoteData = await onFetchSites(queryParams);
            let dataToSend = remoteData.data.map(site => {
                return site
            });
            props.endFetching();

            return { data: dataToSend, page: queryParams.page, pageSize: queryParams.pageSize, totalCount: remoteData.count };

        } catch (error) {
            console.log(error);
        }
    };
    const columnHidden = newColumnState => {
        let objToSendColumn = {}
        let objColumns = {}
        columnObject.map(column => {
            objColumns[`${column.field}`] = column.hidden
            return objColumns
        })
        objToSendColumn[`PickupOrder`] = objColumns
        saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        return newColumnState
    }
    
    const [tableOptions, setTableOptions] = useState({
        filtering: true,
        sorting: true,
        search: false,
        minBodyHeight: 450,
        maxBodyHeight: window.innerHeight - 210,
        pageSizeOptions: [5, 20, 50, 100],
        exportButton: false,
        columnsButton: false,
        pageSize: pageSize ? pageSize : 5,
        debounceInterval: 1000,
        selection: true
    });
    const handleResize = () => {
        setTableOptions({
            ...tableOptions, pageSize: pageSize,
            maxBodyHeight: window.innerHeight - 205, minBodyHeight: window.innerHeight - 205 < 300 ? 300 : window.innerHeight - 205
        });
    };

    const hanResize = (size) => {
        setTableOptions({
            ...tableOptions, pageSize: size,
            maxBodyHeight: window.innerHeight - 205, minBodyHeight: window.innerHeight - 205 < 300 ? 300 : window.innerHeight - 205
        });
        pageSize = size;
    }
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            pageSize = 5;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classes]);

    return (
        <MaterialTable 
            title="User Sites"

            tableRef={tableRef}
            onChangeRowsPerPage={(size) => hanResize(size)}
            handleCreate={props.handleCreate}
            handleDelete={props.handleDelete}
            onChangeColumnHidden={columnHidden}
            isLoading={sites.fetching}

            handleEdit={props.handleEdit}
            columns={columnObject}
            data={fetchRemoteData}
            actions={actions}
            options={tableOptions}
            >

        </MaterialTable>
    )

}

export default withStyles(styles)(SiteList2);