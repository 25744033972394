import { getAxiosInstance } from "../../../np-rest/rest";
import restCrudActions from '../../../np-crud/store/restCrudActions'
import config from "../../../../config/config_default";
import { enqueueSnackbar } from '../../../np-dashboard/Notifier/store/notifierActions';

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: 'binary-images',
    pluginName: 'IMPORT_EXPORT',
    entityName: 'BINARY_IMAGE',
    fetchSuccessMessage: '',
    loadSuccessMessage: '',
    editSuccessUseBackendMessage: true

});

const updateDownloadProgress = progress => {

    return {
        type: 'IMPORT_EXPORT|BINARY_IMAGE|DOWNLOAD_PROGRESS',
        downloadProgress: progress.downloadProgress,
        maxSize: progress.maxSize,
        currentSize: progress.currentSize,
        snackBar: progress.snackBar,
        data: progress,
        name: progress.name
    };
};

const listAllBinaryImageTypes = data => {
    return {
        type: 'IMPORT_EXPORT|BINARY_IMAGE_TYPE|LIST',
        binaryImageTypes: data
    };
};
// Create BinaryImage  action creators
export const createBinaryImageInit = () => {
    return crudActions.createInit();
};
export const createBinaryImageStart = () => {
    return crudActions.createStart();
};
export const createBinaryImageSuccess = createdBinaryImage => {
    return crudActions.createSuccess(createdBinaryImage);
};
export const createBinaryImageFail = error => {
    return crudActions.createFail(error);
};
export const createBinaryImage = BinaryImage => {
    return async dispatch => {
        await crudActions.create(dispatch, BinaryImage);
    };
};


// Fetch securaze action creators
export const fetchBinaryImageInit = () => {
    return crudActions.fetchInit();
};
export const fetchBinaryImageStart = () => {
    return crudActions.fetchStart();
};
export const fetchBinaryImageSuccess = () => {
    return crudActions.fetchSuccess();
};
export const fetchBinaryImageFail = () => {
    return crudActions.fetchFail();
};
export const fetchBinaryImages = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
};

// Load BinaryImage action creators
export const loadBinaryImageInit = () => {
    return crudActions.loadInit();
};
export const loadBinaryImageStart = () => {
    return crudActions.loadStart();
};
export const loadBinaryImageSuccess = loadedBinaryImage => {
    return crudActions.loadSuccess(loadedBinaryImage);
};
export const loadBinaryImageFail = error => {
    return crudActions.loadFail(error);
};
export const loadBinaryImage = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

// Delete BinaryImage action creators
export const deleteBinaryImageInit = () => { return crudActions.deleteInit(); }
export const deleteBinaryImageStart = () => { return crudActions.deleteStart(); }
export const deleteBinaryImageSuccess = deletedBinaryImage => { return crudActions.deleteSuccess(deletedBinaryImage); }
export const deleteBinaryImageFail = error => { return crudActions.deleteFail(error); }
export const deleteBinaryImage = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    }
}

export const uploadProgress = (data) => {
    return {
        type: 'SOFTWARE|PROGRESS',
        progress: data.progress,
        loadSize: data.loadSize,
        maxSize: data.maxSize,
        snackStart: data.snackStart
    };
};

export const createNewBinaryImage = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(createBinaryImageInit());
        dispatch(uploadProgress({ progress: null, loadSize: null, maxSize: null, snackStart: true }))

        try {
            const config = {
                onUploadProgress: progressEvent => {
                    dispatch(uploadProgress({ progress: Math.round((progressEvent.loaded * 100) / data.get('File').size), loadSize: Math.round((progressEvent.loaded / 1048576)), maxSize: Math.round((progressEvent.total / 1048576)), snackStart: true }))
                }
            }
            dispatch(createBinaryImageStart());
            let res = await rest.post('binary-images/create', data, config);
            if (res.status === 200) {
                dispatch(createBinaryImageSuccess());
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : 'Create binary image was successful '}`,
                        options: {
                            variant: 'success'
                        }
                    }));
                return;
            }
            else {
                dispatch(uploadProgress({ progress: 0, loadSize: 0, maxSize: 0, snackStart: true }))
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : res.message}`,
                        options: {
                            variant: 'warning'
                        }
                    }));
                dispatch(createBinaryImageFail());
                return;
            }
        } catch (error) {
            dispatch(uploadProgress({ progress: 0, loadSize: 0, maxSize: 0, snackStart: true }))
            dispatch(
                enqueueSnackbar({
                    message: `${error && error.message ? error.message : 'Cannot create binary image'}`,
                    options: {
                        variant: 'error'
                    }
                }));
            dispatch(createBinaryImageFail());
            return false;
        }
    }
}

// custom functions
export const listBinaryImageTypes = (id) => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/binary-image-type/list', { forList: true, ActiveCustomerID: id });
            if (res.data && res.data.succeeded) {
                const binaryImageTypes = [];
                for (var binaryImageType of res.data.result.data) {
                    binaryImageTypes.push({ value: binaryImageType.BinaryImageTypeID, displayValue: `${binaryImageType.Name} (${binaryImageType.Key})` });
                }
                dispatch(listAllBinaryImageTypes(binaryImageTypes));
                return binaryImageTypes;
            }
            else {
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : res.message}`,
                        options: {
                            variant: 'warning'
                        }
                    }));
                return [];
            }

        } catch (error) {
            return [];
        }
    };
}

// Edit BinaryImage  action creators
export const editBinaryImageInit = () => {
    return crudActions.editInit();
};
export const editBinaryImageStart = () => {
    return crudActions.editStart();
};
export const editBinaryImageSuccess = editedBinaryImage => {
    return crudActions.editSuccess(editedBinaryImage);
};
export const editBinaryImageFail = error => {
    return crudActions.editFail(error);
};
export const editBinaryImage = BinaryImage => {
    return async dispatch => {
        await crudActions.edit(dispatch, BinaryImage);
    };
};

// Custom functions 
export const downloadFile = (ImageID, customerID, name) => {
    let token = localStorage.getItem('CORE|AUTH|TOKEN');
    if (token) {
        token = JSON.parse(token).token;
    }
    return async dispatch => {
        return fetch(`${config.backend_domain}:${config.backend_port}/binary-images/download?BinaryImageID=${ImageID}&ActiveCustomerID=${customerID.ActiveCustomerID}&Token=${token}`, {
            method: "GET",
        }).then(async response => {
            try {
                if (response.status === 200) {
                    const reader = response.body.getReader();
                    const contentLength = +response.headers.get('fileSize');
                    let receivedLength = 0;
                    let chunks = [];
                    let percentage = 0;
                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) {
                            dispatch(updateDownloadProgress({ downloadProgress: 0, maxSize: 0, currentSize: 0, snackBar: false }));

                            break;
                        }
                        chunks.push(value);

                        receivedLength += value.length;
                        if (percentage + 1 === Math.floor(receivedLength / contentLength * 100)) {
                            percentage += 1;
                            dispatch(updateDownloadProgress({ downloadProgress: Math.floor(receivedLength / contentLength * 100), maxSize: Math.floor(contentLength / 1048576), currentSize: Math.floor(receivedLength / 1048576), snackBar: true, name: name, ImageID: ImageID }));
                        }
                    }
                    return new Blob(chunks);
                }
                else {
                    dispatch(enqueueSnackbar({
                        message: `Download failed`,
                        options: {
                            variant: 'warning'
                        }
                    }))

                    return null;
                }
            } catch (error) {
                dispatch(updateDownloadProgress({ downloadProgress: 0, maxSize: 0, currentSize: 0, snackBar: false, name: name, ImageID: ImageID }));
                dispatch(
                    enqueueSnackbar({
                        message: `${error && error.message ? error.message : 'Download failed '}`,
                        options: {
                            variant: 'error'
                        }
                    }));
            }
        });
    }
}
// Custom functions 
export const downloadFileTool = (ToolID, id, name) => {
    let token = localStorage.getItem('CORE|AUTH|TOKEN');
    if (token) {
        token = JSON.parse(token).token;
    }
    return async dispatch => {
        return fetch(`${config.backend_domain}:${config.backend_port}/securaze-tools/download?SecurazeToolID=${ToolID}&ActiveCustomerID=${id}&Token=${token}`, {
            method: "GET",
        }).then(async response => {
            try {

                if (response.status === 200) {
                    const reader = response.body.getReader();

                    const contentLength = +response.headers.get('fileSize');
                    let receivedLength = 0;
                    let chunks = [];
                    while (true) {
                        const { done, value } = await reader.read();

                        if (done) {
                            break;
                        }
                        chunks.push(value);
                        receivedLength += value.length;

                        dispatch(updateDownloadProgress({ downloadProgress: Math.floor(receivedLength / contentLength * 100), maxSize: Math.floor(contentLength / 1048576), currentSize: Math.floor(receivedLength / 1048576), snackBar: true, name: name, ImageID: ToolID }));

                    }
                    return new Blob(chunks);
                }
                return null;

            } catch (error) {
                dispatch(updateDownloadProgress({ downloadProgress: 0, maxSize: 0, currentSize: 0, snackBar: false, name: name, ImageID: ToolID }));
                dispatch(
                    enqueueSnackbar({
                        message: `${error && error.message ? error.message : 'Download failed '}`,
                        options: {
                            variant: 'error'
                        }
                    }));
            }
        });
    }
}

export const sendPBillingPlansStart = () => {
    return {
        type: 'SOFTWARE|RECOVER_BILLING_START',
        loading: true
    };
};
export const sendPBillingPlansSuccess = () => {
    return {
        type: 'SOFTWARE|RECOVER_BILLING_SUCCESS',
        loading: false,
    };
};
export const sendPBillingPlansFail = () => {
    return {
        type: 'SOFTWARE|RECOVER_BILLING_FAIL',
        loading: false
    };
};


export const sendReleaseNotes = (id) => {

    const rest = getAxiosInstance();
    let token = localStorage.getItem('CORE|AUTH|TOKEN');
    if (token) {
        token = JSON.parse(token).token;
    }
    id['Token'] = token
    return async dispatch => {
        try {
            dispatch(sendPBillingPlansStart())

            let res = await rest.post(`/binary-images/send-release-notes`, id);
            if (res.data.status === 200) {
                dispatch(sendPBillingPlansSuccess())

                dispatch(
                    enqueueSnackbar({
                        message: `${res && res.data && res.data.message ? res.data.message : 'Success '}`,
                        options: {
                            variant: 'success'
                        }
                    }));
                return res.data.status === 200;

            }
            else {
                dispatch(sendPBillingPlansFail())

                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : res.message}`,
                        options: {
                            variant: 'warning'
                        }
                    }));
                return false;
            }
        } catch (error) {
            dispatch(sendPBillingPlansFail())

            dispatch(
                enqueueSnackbar({
                    message: `${error && error.message ? error.message : 'Send failed '}`,
                    options: {
                        variant: 'error'
                    }
                }));
            return false;
        }
    };
}
export const ClearState = (data) => {
    return ({
        type: 'CLEAR_STATE',
    });
}

export const ClearStateCreate = (data) => {
    return ({
        type: 'CLEAR_STATE_CREATE',
    });
}
