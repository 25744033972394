import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import { Paper, Tooltip, IconButton, Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import { Edit, Delete, CloudDownload, CloudUpload } from "@material-ui/icons";
import useListFilter from "../../np-components/Datatable/listFilter";
import NPDatatable from "../../np-components/Datatable";
import { isSuperUser } from "../../np-utilities";
import Form from '../../np-form/Form';
import { formatDate, parseURL } from '../../ms-utilities';

const styles = theme => ({
    userActive: {
        color: "#4caf50",
        marginTop: 8.5,
        fontSize: 15
    },
    userInactive: {
        color: "#b71c1c",
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    fab: {
        textTransform: "none",
        color: "white",
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#e62440" : "#7dc5e2",
        "&:hover": {
            backgroundColor: "#757575"
        }
    }
});
const updateFormState = props => {
    const { fileName, handleFileUpload } = props;
    const form = {
        DependentSettings: {
            elementType: 'file',
            elementConfig: {
                ID: "Translation",
                accept: '.xlsx',
                style: {},
                type: 'file',
                handleUpload: handleFileUpload,
                fileName: fileName,
                text: 'upload'

            },
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
    }
    return form;
}

const updateFilter = props => {
    const { listFilter } = props;
    let searchParams = parseURL(window.location.search);
    return { ...listFilter, ...searchParams };
}
const PermissionsList = props => {
    const { classes, permission, fetchPermissions, translations, columnsState, activeCustomer } = props;
    const permissionCount = permission.count;
    const permissionList = permission.list;


    const [fileToSend, setFileToSend] = useState({});
    const [fileName, setFilename] = useState('');

    const handleFileUpload = event => {
        event.preventDefault();
        let file = event.target.files[0];
        if (!file) {
            file = {};
        }
        setFileToSend(file);
        setFilename(file.name);
    }

    const [form, setForm] = useState(updateFormState({ handleFileUpload, fileName }));

    useEffect(() => {
        setForm(updateFormState({ handleFileUpload, fileName }));
    }, [fileName]);

    const submitHandler = async event => {
        event.preventDefault();
        if (!fileName || !fileName.includes('.xlsx') || !fileName.includes('.xls')) {
            return props.sendMessage('Please choose XLSX file', 'warning')
        }
        setFileToSend({});
        setFilename('');

        const data = new FormData();
        data.append('File', fileToSend);
        data.append('ActiveCustomerID', activeCustomer);

        let token = localStorage.getItem('CORE|AUTH|TOKEN');
        if (token) {
            token = JSON.parse(token).token;
        }
        setPermissionUpload({ open: false })
        data.append('Token', token);
        await props.uploadPermission(data);
        let string = ' '

        setForm(updateFormState({ handleFileUpload, string }))
        fetchPermissions({ ...listFilter.filter, ActiveCustomerID: activeCustomer });


    }
    let tableID = "Permission";
    let visible = columnsState && columnsState[tableID];

    const columns = [
        {
            displayName: "Actions",
            backendName: "Action",
            options: {
                align: "left",
                sortable: false,
                searchable: false,
                visible: visible ? visible["Action"] : true
            }
        },
        {
            displayName: "Key",
            backendName: "Key",
            options: {
                align: "left",
                sortable: true,
                searchable: true,
                visible: visible ? visible["Key"] : true
            }
        },
        {
            displayName: 'Name',
            backendName: 'Name',
            options: {
                align: 'left',
                sortable: true,
                visible: true,
                searchable: true

            }
        },
        {
            displayName: 'Module',
            backendName: 'Module',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: true
            }
        },
        {
            displayName: 'Locked',
            backendName: 'Locked',
            options: {
                align: 'left',
                sortable: false,
                visible: true
            }
        },
        {
            displayName: 'Created at',
            backendName: 'CreatedAt',
            options: {
                align: 'left',
                sortable: false,
                visible: true
            }
        }
    ];

    let backendNames = [];
    columns.map(column => {
        if (column.backendName !== "Action") backendNames.push(column.backendName);
        return null;
    });

    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });
    if (!props.hasAtLeastOnePermission(["permission_load", "permission_delete"])) {
        columns.splice(0, 1);
    }
    const createActions = permissionID => {
        return (
            <>
                {props.hasPermission("permission_load") ? (
                    <Tooltip title="Edit permission ">
                        <IconButton value={permissionID} onClick={props.handleEdit} aria-label="Edit permission">
                            <Edit />
                        </IconButton>
                    </Tooltip>
                ) : null}
                {props.hasPermission("permission_delete") ? (
                    <Tooltip title="Delete permission ">
                        <IconButton value={permissionID} aria-label="Delete permission" onClick={props.handleDelete}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </>
        );
    };

    useEffect(() => {
        // fetch calls
        if (listFilter.filter) {
            if (!listFilter.filter.q) {
                listFilter.filter.q = {};
            }
            if (!listFilter.filter.q.and) {
                listFilter.filter.q.and = {};
            }
            listFilter.filter.q = {
                ...listFilter.filter.q,
                and: updateFilter({ listFilter: listFilter.filter.q.and })
            };
            fetchPermissions({ ...listFilter.filter, ActiveCustomerID: activeCustomer });
        }

    }, [permission.created, permission.deleted, permission.bulkDeleted, activeCustomer, permission.edited, fetchPermissions, listFilter.filter]);
    const [permissionUpload, setPermissionUpload] = useState({ open: false });

    const permissionCloseImport = () => {
        setPermissionUpload({ open: false })
        let string = ' '

        setForm(updateFormState({ handleFileUpload, string }))

    };

    const customFilterToolbar = () => {
        return <>
            {props.hasPermission("import_permissions") ? (

                <Tooltip title={"Import permission"}>
                    <IconButton aria-label={"Import permission"} value='csv'
                        onClick={() => setPermissionUpload({ open: true })}>
                        <CloudUpload />
                    </IconButton>
                </Tooltip>
            ) : null}
            {props.hasPermission("export_permissions") ? (

                <Tooltip title={"Export permission"}>
                    <IconButton aria-label={"Export permission"} value='csv' onClick={props.handleExport}>
                        < CloudDownload />
                    </IconButton>
                </Tooltip>
            ) : null}

        </>
    };

    const tableOptions = {
        isSelectable: false,
        tableSearch: false,
        tableID: tableID,
        saveColumn: true,
        page: 0,
        selectOptions: { createButtonName: translations['create_new'] || 'Create new', printing: false, generatingReport: false, creating: props.hasPermission("translate_create") ? true : false, deleting: false },
        rowsPerPage: 20,
        count: permissionCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, "All"],
        tableTitle: translations['permissions'] || "Permissions",
        noMatch: translations['table_no_match'] || "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onSaveFilter: columns => {
            let objToSendColumn = {};
            let objColumns = {};
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible;
                return objColumns;
            });
            objToSendColumn[`${tableID}`] = objColumns;
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer });
        }
    };

    let counter = 0;
    let permissionData = permissionList.map(permission => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = permission.PermissionID;
        returnObj.visible = true;
        returnObj.values = [
            createActions(permission.PermissionID),
            permission && permission.Key ? permission.Key : '',
            permission && permission.Name ? permission.Name : '',
            permission && permission.Module ? permission.Module : '-',
            permission && permission.Locked === 1 ? translations['yes'] || 'Yes' : translations['no'] || 'No',
            permission && permission.CreatedAt ? formatDate(permission.CreatedAt) : '-'
        ];
        if (!props.hasAtLeastOnePermission(["permission_load", "permission_delete"])) {
            returnObj.values.splice(0, 1);
        }
        counter++;
        return returnObj;
    });

    return (<>
        <Paper>
            <NPDatatable customFilterToolbar={customFilterToolbar} translations={translations} handleCreate={props.handleCreate} customStyles={classes} data={permissionData} columns={columns} options={tableOptions} />
        </Paper>
        <Dialog
            open={permissionUpload.open}
            // onClose={() => permissionCloseImport()}
            aria-labelledby="Import permission data"
            // disableBackdropClick={false}
            disableEscapeKeyDown={false}
            disableEnforceFocus={true}
            fullWidth={true}
            onClose={(e, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    permissionCloseImport(e);
                }
            }}
        >
            <DialogContent>
                <DialogContentText>
                    {'Supported format .xlsx'}.<br />
                </DialogContentText>
                <Form
                    formIsValid={true}
                    classes={null}
                    form={form}
                    formTitle={"Import permission"}
                    buttonText={translations['import'] || "Import"}
                    submitHandler={submitHandler}
                    submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                />
            </DialogContent>
        </Dialog>

    </>
    );
};

export default withStyles(styles)(PermissionsList);
