import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, withStyles } from '@material-ui/core';

const styles = theme => ({
    buttonDanger: {
        color: theme.palette.buttonText.default,
        backgroundColor: theme.palette.error.dark
    },
    buttonSafe: {
        color: theme.palette.buttonText.default,
        backgroundColor: theme.palette.success.dark
    }
});
const UserActivityRemoveSession = props => {
    let { classes, userActivityModalState, translations,  } = props;

    const handleCloseSafe = () => {
        props.handleRemoveSession();
    }
   
    return (
        <Dialog
            open={userActivityModalState.sessionDialog}
            // onClose={handleCloseSafe}
            aria-labelledby="form-dialog-title"
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            fullWidth={true}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleCloseSafe();
                }
            }}
        >
            <DialogTitle id="form-dialog-title">{ translations['remove_session'] || 'Remove session'} {userActivityModalState.user}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {translations['carefully_remove_session'] || 'Be carefully when remove session.'}<br />
                    {translations['sure_want_remove'] || `Are you sure you want to remove this session `} ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseSafe} variant="contained" className={classes.buttonSafe}>
                    {translations['no'] || 'No'}
                </Button>
                <Button onClick={props.handleCloseDanger} variant="contained" className={classes.buttonDanger}>
                    {translations['yes'] || 'Yes'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withStyles(styles, { withTheme: true })(UserActivityRemoveSession);