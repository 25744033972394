import React, { useState } from 'react';
import { withStyles, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actions from './store/applicantActions';
import ApplicantList from './ApplicantList';
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';
import * as action from '../../np-core/Auth/store/authActions';
import ApplicantDelete from "./ApplicantDelete";
import ApplicantEdit from "./ApplicantEdit";
import { downloadFile } from '../../np-utilities';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        overflow: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%',
            overflow: 'auto'
        }
    },
});

const Applicant = props => {
    const { classes, applicants, activeCustomer } = props;
    const fetching = applicants.fetching;

    const [deleteModalState, setDeleteModalState] = useState({ open: false, UserID: null, UserIDs: [], forBulk: false });
    const [editModalState, setEditModalState] = useState(false);

    const handleDelete = async event => {
        if (!event) {
            setDeleteModalState({ open: false, UserID: null, UserIDs: [], forBulk: false });
            return;
        }
        const userID = event.currentTarget.value;
        setDeleteModalState({ ...deleteModalState, open: true, UserID: userID });
    };

    const handleEdit = async event => {
        if (!event) {
            setEditModalState(!editModalState);
            return;
        }

        const applicantID = event.currentTarget.value;
        await props.onLoadApplicant({ ApplicantID: applicantID, ActiveCustomerID: activeCustomer });
        setEditModalState(!editModalState);
    };

    const getTrainingReport = async event => {
        const applicantID = event.currentTarget.value;
        let data = await props.getApplicantTrainingReport(applicantID, activeCustomer);
        if (data) {
            return downloadFile(data.blob, decodeURIComponent(data.fileName));
        }
        props.sendMessage('Could not find training session for applicant.', 'warning');
    };

    const getRawData = async event => {
        const applicantID = event.currentTarget.value;

        let data = await props.getRawData(applicantID, activeCustomer);
        if (data) {
            return downloadFile(data.blob, decodeURIComponent(data.fileName));
        }
        props.sendMessage('Could not find training session for applicant.', 'warning');
    };

    const activateApplicant = async event => {
        const applicantID = event.currentTarget.value;
        await props.activateApplicant(applicantID, activeCustomer);
    };

    return (
        <div className={classes.root}>
            <div className={classes.progressBarr}>
                {fetching ? <LinearProgress color="primary" /> : null}
            </div>
            {props.hasPermission('applicant_list') ?
                <ApplicantList activeCustomer={activeCustomer} columnsState={props.columns}
                    saveColumnsFilter={props.saveColumnsFilter} applicants={applicants}
                    hasPermission={props.hasPermission}
                    hasAtLeastOnePermission={props.hasAtLeastOnePermission}
                    onFetchApplicantsList={props.onFetchApplicantsList}
                    onApplicantExport={props.onApplicantExport}
                    handleDeleteApplicant={handleDelete}
                    handleApplicantEdit={handleEdit}
                    getTrainingReport={getTrainingReport}
                    activateApplicant={activateApplicant}
                    getRawData={getRawData}
                /> : null}
            {props.hasPermission('applicant_delete') ?
                <ApplicantDelete activeCustomer={activeCustomer} entityName={'Applicant'}
                    handleDelete={handleDelete}
                    deleteModalState={deleteModalState} onDeleteApplicant={props.onDeleteApplicant}
                /> : null}
            {props.hasPermission('applicant_load') ?
                <ApplicantEdit activeCustomer={activeCustomer} handleEdit={handleEdit} open={editModalState}
                    hasPermission={props.hasPermission} loading={applicants.saving}
                    applicant={applicants.loaded}
                    editApplicant={props.editApplicant} /> : null}

        </div>
    );
};

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        applicants: state.applicant.applicants,
        columns: state.core.login.applicantSettings ? state.core.login.applicantSettings.Columns : null,
        hasPermission: permission => {
            return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*');
        },
        hasAtLeastOnePermission: permissions => {
            return state.core.login.permissions.some(permission => permissions.includes(permission)) || state.core.login.permissions.includes('*');
        },
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onFetchApplicantsList: filter => dispatch(actions.fetchApplicants(filter)),
        onApplicantExport: payload => dispatch(actions.onApplicantExport(payload)),
        onDeleteApplicant: filter => dispatch(actions.deleteApplicant(filter)),
        editApplicant: filter => dispatch(actions.editApplicant(filter)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),
        onLoadApplicant: applicantID => dispatch(actions.loadApplicant(applicantID)),
        getApplicantTrainingReport: (applicantID, customerID) => dispatch(actions.getApplicantTrainingReport(applicantID, customerID)),
        getRawData: (applicantID, customerID) => dispatch(actions.getRawData(applicantID, customerID)),
        activateApplicant: (applicantID, customerID) => dispatch(actions.activateApplicant({ ApplicantID: applicantID, ActiveCustomerID: customerID })),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Applicant));
