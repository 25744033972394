import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from './store/actions';

import { Paper, List, ListItem, ListItemSecondaryAction, ListItemText, Typography, TextField } from '@material-ui/core';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    },
    active: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    itemText: {
        width: '10%'
    }
});
let columnSearchTimeout = null;

const ChangeActiveCustomerForm = props => {
    const { classes, customers, activeCustomer, onFetchCustomers, activeCustomerID, translations, securaze } = props;
    const [customersList, setCustomersList] = useState({ customersList: [], customerFilter: [], values: '' })
    const [values, setValues] = useState('')

    useEffect(() => {
        const fetchCustomers = async () => {
            let customers = await onFetchCustomers({ ActiveCustomerID: activeCustomerID, listInactive: true })
            setCustomersList({ customersList: customers, customerFilter: customers })
        }
        fetchCustomers()
    }, [activeCustomerID, onFetchCustomers])


    const Chip = text => {
        return (
            <ListItemText
                disableTypography
                primary={<Typography type="body2" style={{ color: '#4caf50' }}>{text}</Typography>}
            />);
    };
    const handleChange = event => {
        let found = []
        let valueLowerCase = event.target.value.toLowerCase()
        setValues(event.target.value);
        if (customers.length >= customersList.customersList.length && !securaze) {
            for (var filterCustomer of customers) {
                let customerLower = filterCustomer.Name.toLowerCase()
                if (customerLower.includes(valueLowerCase))
                    found.push(filterCustomer)
            }
        }
        else {
            for (var customer of customersList.customersList) {
                let customerLower = customer.Name.toLowerCase()
                if (customerLower.includes(valueLowerCase))
                    found.push(customer)
            }
        }
        if (columnSearchTimeout) {
            clearTimeout(columnSearchTimeout);
        }
        columnSearchTimeout = setTimeout(async () => {
            await setCustomersList({ ...customersList, customerFilter: found });
        }, 600);
    };

    return (
        <Paper className={classes.root}>

            {/* <Typography variant='h5'>{translations ? translations['change_active_customer'] || 'Change active customer' : 'Change active customer'}:</Typography> */}
            <TextField
                id="outlined-name"
                label={translations['filter_customer'] || "Filter customer by name"}
                className={classes.textField}
                value={values}
                style={{ marginLeft: '1pc' }}
                helperText={translations['please_change_active_customer'] || "Please change active customer"}
                onChange={handleChange}
                margin="normal"
            />

            <List dense>
                {values.length > 0 && !securaze ? customersList.customerFilter.map(customer => (
                    <ListItem key={customer.CustomerID} button onClick={activeCustomer && activeCustomer.CustomerID === customer.CustomerID ? () => { return null } : () => { return props.onChangeActiveCustomer(customer.CustomerID) }}>
                        <ListItemText primary={customer.Name} />
                        <ListItemSecondaryAction>
                            {activeCustomer && activeCustomer.CustomerID === customer.CustomerID ? Chip('Active') : ''}
                        </ListItemSecondaryAction>
                    </ListItem>
                )) :

                    customers.length >= customersList.length && !securaze ? customers.map(customer => (
                        <ListItem key={customer.CustomerID} button onClick={activeCustomer && activeCustomer.CustomerID === customer.CustomerID ? () => { return null } : () => { return props.onChangeActiveCustomer(customer.CustomerID) }}>
                            <ListItemText primary={customer.Name} />
                            <ListItemSecondaryAction>
                                {activeCustomer && activeCustomer.CustomerID === customer.CustomerID ? Chip('Active') : ''}
                            </ListItemSecondaryAction>
                        </ListItem>
                    )) : customersList.customerFilter.map(customer => (
                        < ListItem key={customer.CustomerID} disabled={customer.Selectable !== undefined ? !customer.Selectable : false} button onClick={activeCustomer && activeCustomer.CustomerID === customer.CustomerID ? () => { return null } : () => { return props.onChangeActiveCustomer(customer.CustomerID) }}>
                            <ListItemText className={classes.itemText} primary={customer.Name} />
                            <ListItemText primary={customer.Reason} />
                            <ListItemSecondaryAction>
                                {activeCustomer && activeCustomer.CustomerID === customer.CustomerID ? Chip('Active') : ''}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
            </List>
        </Paper >

    );
};

const mapStateToProps = state => {
    return {
        activeCustomerID: state.core.login.activeCustomer ? state.core.login.activeCustomer.CustomerID : null,
        customers: state.core.login.customers,
        activeCustomer: state.core.login.activeCustomer,
        translations: state.translate && state.translate.translations ? state.translate.translations.activeTranslations || {} : {},

    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeActiveCustomer: filter => dispatch(actions.changeActiveCustomer(filter)),
        onFetchCustomers: (id) => dispatch(actions.loadCustomers(id)),

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ChangeActiveCustomerForm));