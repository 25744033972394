import restCrudActions from '../../../np-crud/store/restCrudActions';
import { getAxiosInstance } from '../../../np-rest/rest';
import { enqueueSnackbar } from "../../../np-dashboard/Notifier/store/notifierActions";

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: 'roles',
    pluginName: 'ROLE',
    entityName: 'ROLE',
    fetchSuccessMessage: '',
    loadSuccessMessage: ''
});

// Fetch Role action creators
export const fetchRoleInit = () => { return crudActions.fetchInit(); }
export const fetchRoleStart = () => { return crudActions.fetchStart(); }
export const fetchRoleSuccess = () => { return crudActions.fetchSuccess(); }
export const fetchRoleFail = () => { return crudActions.fetchFail(); }
export const fetchRoles = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    }

}// Create Role  action creators
export const createRoleInit = () => { return crudActions.createInit(); };
export const createRoleStart = () => { return crudActions.createStart(); };
export const createRoleSuccess = createdRole => { return crudActions.createSuccess(createdRole); };
export const createRoleFail = error => { return crudActions.createFail(error); };
export const createRole = vehicle => {
    return async dispatch => {
        await crudActions.create(dispatch, vehicle);
    };
};

// Delete Role  action creators

export const bulkDeleteRoleInit = () => { return crudActions.bulkDeleteInit(); };
export const bulkDeleteRoleStart = () => { return crudActions.bulkDeleteStart(); };
export const bulkDeleteRoleSuccess = bulkDeleted => { return crudActions.bulkDeleteSuccess(bulkDeleted); };
export const bulkDeleteRoleFail = error => { return crudActions.bulkDeleteFail(error); };
export const bulkDeleteRole = vehicleIDs => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, vehicleIDs);
    };
};

// Delete Role  action creators
export const deleteRoleInit = () => { return crudActions.deleteInit(); };
export const deleteRoleStart = () => { return crudActions.deleteStart(); };
export const deleteRoleSuccess = deletedRole => { return crudActions.deleteSuccess(deletedRole); };
export const deleteRoleFail = error => { return crudActions.deleteFail(error); };
export const deleteRole = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};


// Edit vehicle  action creators
export const editRoleInit = () => { return crudActions.editInit(); };
export const editRoleStart = () => { return crudActions.editStart(); };
export const editRoleSuccess = editedRole => { return crudActions.editSuccess(editedRole); };
export const editRoleFail = error => { return crudActions.editFail(error); };
export const editRole = vehicle => {
    return async dispatch => {
        await crudActions.edit(dispatch, vehicle);
    };
};

// Load vehicle action creators
export const loadRoleInit = () => { return crudActions.loadInit(); };
export const loadRoleStart = () => { return crudActions.loadStart(); };
export const loadRoleSuccess = loadedRole => { return crudActions.loadSuccess(loadedRole); };
export const loadRoleFail = error => { return crudActions.loadFail(error); };
export const loadRole = id => {
    return async dispatch => {

        await crudActions.load(dispatch, id);
    };
};


export const loadPermission = (id) => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/permissions/list', { forList: true, ...id });
            if (res.data && res.data.succeeded) {
                let permissions = [];

                for (var permission of res.data.result.data) {
                    permissions.push({ value: permission.PermissionID, label: `${permission.Name}` });
                }
                return permissions;
            } else {

                dispatch(enqueueSnackbar({
                    message: `${res && res.data ? res.data.message : res && res.message ? res.message : "Cannot list delivery notes  "}`,
                    options: {
                        variant: 'warning'
                    }
                }));

                return [];
            }
        } catch (error) {
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
        }
    };
}
