import React, { useState } from 'react';
import { Stepper, StepLabel, Step, Paper, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import { BackButton } from '../../../ms-utilities';
import * as actions from '../store/userActions';

import { enqueueSnackbar } from '../../../np-dashboard/Notifier/store/notifierActions';

import RoleExcluded from './NormalUser/RoleCreate';
import UserCreate from './NormalUser/UserCreate';

import ChoseCustomer from './AdminUser/ChoseCustomers';
import AdminCreate from './AdminUser/AdminCreate';
import RoleForCustomer from './AdminUser/RoleForCustomer';




const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        paddingRight: theme.spacing(6),
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            paddingRight: 0,
        },
    },
});

const UserComponent = props => {
    const { classes, activeCustomer, translations, isSuperUser, onFetchCustomers } = props;
    const [userFormValid, setUserFormValid] = useState(false);
    const [userFormValue, setUserFormValue] = useState({});
    const steps = [`${translations["user"] || "User "}`, `${translations["access_control"] || "Access control"}`];
    const stepsAdmin = [`${translations["user"] || "User "}`, `${translations["chose_customer"] || "Chose customer"}`, `${translations["access_control"] || "Access control"}`];
    const [customers, setCustomers] = useState([]);

    const [customersSelect, setCustomersSelect] = useState([]);

    const [activeStep, setActiveStep] = useState(0);
    const [activeStepAdmin, setActiveStepAdmin] = useState(0);

    const getStepContentNormalUser = stepIndex => {
        switch (stepIndex) {
            case 0:
                return (<UserCreate userFormValid={userFormValid} userFormValue={userFormValue} setActiveStep={setActiveStep} setUserFormValid={setUserFormValid} translations={translations} activeCustomer={activeCustomer} setUserFormValue={setUserFormValue} hasPermission={props.hasPermission} />
                );
            case 1:
                return (<RoleExcluded createUser={props.onCreateUser} setActiveStep={setActiveStep} onLoadRoles={props.onLoadRoles} userFormValue={userFormValue} translations={translations} activeStep={activeStep} activeCustomer={activeCustomer} fetchPermission={props.fetchPermission} />);
            default:
                return 'Unknown stepIndex';
        }
    }

    const getStepContentAdminUser = stepIndex => {
        switch (stepIndex) {
            case 0:
                return (<AdminCreate onCreateUser={props.onCreateUser} userFormValid={userFormValid} setCustomers={setCustomers} onFetchCustomers={onFetchCustomers} userFormValue={userFormValue} setActiveStep={setActiveStepAdmin} setUserFormValid={setUserFormValid} translations={translations} activeCustomer={activeCustomer} setUserFormValue={setUserFormValue} hasPermission={props.hasPermission} />
                );
            case 1:
                return (<ChoseCustomer translations={translations} setCustomersSelect={setCustomersSelect} createUser={props.onCreateUser} customer={customers} setActiveStep={setActiveStepAdmin} onLoadRoles={props.onLoadRoles} />);
            case 2:
                return (<RoleForCustomer onCreateUser={props.onCreateUser} userFormValue={userFormValue} customers={customers} customersSelect={customersSelect} setActiveStep={setActiveStep} onLoadRoles={props.onLoadRoles} translations={translations} activeStep={activeStep} activeCustomer={activeCustomer} fetchPermission={props.fetchPermission} />);
            default:
                return 'Unknown stepIndex';
        }
    }
    return (
        <Paper className={classes.root}>
            {BackButton(translations)}
            {!isSuperUser ?
                <>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {getStepContentNormalUser(activeStep)}
                    </div>
                </>
                :
                <>
                    <Stepper activeStep={activeStepAdmin} alternativeLabel>
                        {stepsAdmin.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {getStepContentAdminUser(activeStepAdmin)}
                    </div>
                </>
            }
        </Paper>
    );
}

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        translations: state.translate && state.translate.translations ? state.translate.translations.activeTranslations || {} : {},
        hasPermission: permission => { return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'); },
        isSuperUser: state.core.login.permissions.includes('*'),

    };
};


const mapDispatchToProps = dispatch => {
    return {
        onCreateUser: user => dispatch(actions.createUser(user)),
        onLoadRoles: user => dispatch(actions.loadRoles(user)),
        onFetchCustomers: (id) => dispatch(actions.fetchCustomers(id)),

        fetchPermission: user => dispatch(actions.fetchPermission(user)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(UserComponent));