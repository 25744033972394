export const BILLING_ACTION_LICENSE_LIST_START = 'BILLING_ACCOUNT|ACTION_LICENSE_LIST_START';
export const BILLING_ACTION_LICENSE_LIST_START_SUCCESS = 'BILLING_ACCOUNT|ACTION_LICENSE_LIST_SUCCESS';
export const BILLING_ACTION_LICENSE_LIST_FAIL = 'BILLING_ACCOUNT|ACTION_LICENSE_LIST_FAIL';

export const BILLING_SOFTWARE_LICENSE_LIST_START = 'BILLING_ACCOUNT|SOFTWARE_LICENSE_LIST_START';
export const BILLING_SOFTWARE_LICENSE_LIST_SUCCESS = 'BILLING_ACCOUNT|SOFTWARE_LICENSE_LIST_SUCCESS';
export const BILLING_SOFTWARE_LICENSE_LIST_FAIL = 'BILLING_ACCOUNT|SOFTWARE_LICENSE_LIST_FAIL';

export const BILLING_ACCOUNT_PLANS_LIST_START = 'BILLING_ACCOUNT|PLANS_LIST_START';
export const BILLING_ACCOUNT_PLANS_LIST_SUCCESS = 'BILLING_ACCOUNT|PLANS_LIST_SUCCESS';
export const BILLING_ACCOUNT_PLANS_LIST_FAIL = 'BILLING_ACCOUNT|PLANS_LIST_FAIL';

export const BILLING_ACCOUNT_SOFTWARE_LICENSE_CREATE_START = 'BILLING_ACCOUNT|SOFTWARE_LICENSE_CREATE_START';
export const BILLING_ACCOUNT_SOFTWARE_LICENSE_CREATE_SUCCESS = 'BILLING_ACCOUNT|SOFTWARE_LICENSE_CREATE_SUCCESS';
export const BILLING_ACCOUNT_SOFTWARE_LICENSE_CREATE_FAIL = 'BILLING_ACCOUNT|SOFTWARE_LICENSE_CREATE_FAIL';

export const BILLING_ACCOUNT_SOFTWARE_LICENSE_DELETE_START = 'BILLING_ACCOUNT|SOFTWARE_LICENSE_DELETE_START';
export const BILLING_ACCOUNT_SOFTWARE_LICENSE_DELETE_SUCCESS = 'BILLING_ACCOUNT|SOFTWARE_LICENSE_DELETE_SUCCESS';
export const BILLING_ACCOUNT_SOFTWARE_LICENSE_DELETE_FAIL = 'BILLING_ACCOUNT|SOFTWARE_LICENSE_DELETE_FAIL';

export const BILLING_ACCOUNT_SOFTWARE_LICENSE_LOAD_START = 'BILLING_ACCOUNT|SOFTWARE_LICENSE_LOAD_START';
export const BILLING_ACCOUNT_SOFTWARE_LICENSE_LOAD_SUCCESS = 'BILLING_ACCOUNT|SOFTWARE_LICENSE_LOAD_SUCCESS';
export const BILLING_ACCOUNT_SOFTWARE_LICENSE_LOAD_FAIL = 'BILLING_ACCOUNT|SOFTWARE_LICENSE_LOAD_FAIL';

export const BILLING_ACCOUNT_SOFTWARE_LICENSE_UPDATE_START = 'BILLING_ACCOUNT|SOFTWARE_LICENSE_UPDATE_START';
export const BILLING_ACCOUNT_SOFTWARE_LICENSE_UPDATE_SUCCESS = 'BILLING_ACCOUNT|SOFTWARE_LICENSE_UPDATE_SUCCESS';
export const BILLING_ACCOUNT_SOFTWARE_LICENSE_UPDATE_FAIL = 'BILLING_ACCOUNT|SOFTWARE_LICENSE_UPDATE_FAIL';

export const BILLING_PREPAID_LIST_START = 'BILLING_ACCOUNT|PREPAID_LIST_START';
export const BILLING_PREPAID_LIST_SUCCESS = 'BILLING_ACCOUNT|PREPAID_LIST_SUCCESS';
export const BILLING_PREPAID_LIST_FAIL = 'BILLING_ACCOUNT|PREPAID_LIST_FAIL';

export const BILLING_ACCOUNT_PREPAID_CREATE_START = 'BILLING_ACCOUNT|PREPAID_CREATE_START';
export const BILLING_ACCOUNT_PREPAID_CREATE_SUCCESS = 'BILLING_ACCOUNT|PREPAID_CREATE_SUCCESS';
export const BILLING_ACCOUNT_PREPAID_CREATE_FAIL = 'BILLING_ACCOUNT|PREPAID_CREATE_FAIL';

export const BILLING_ACCOUNT_PREPAID_DELETE_START = 'BILLING_ACCOUNT|PREPAID_DELETE_START';
export const BILLING_ACCOUNT_PREPAID_DELETE_SUCCESS = 'BILLING_ACCOUNT|PREPAID_DELETE_SUCCESS';
export const BILLING_ACCOUNT_PREPAID_DELETE_FAIL = 'BILLING_ACCOUNT|PREPAID_DELETE_FAIL';

export const BILLING_ACCOUNT_PREPAID_LOAD_START = 'BILLING_ACCOUNT|PREPAID_LOAD_START';
export const BILLING_ACCOUNT_PREPAID_LOAD_SUCCESS = 'BILLING_ACCOUNT|PREPAID_LOAD_SUCCESS';
export const BILLING_ACCOUNT_PREPAID_LOAD_FAIL = 'BILLING_ACCOUNT|PREPAID_LOAD_FAIL';

export const BILLING_ACCOUNT_PREPAID_UPDATE_START = 'BILLING_ACCOUNT|PREPAID_UPDATE_START';
export const BILLING_ACCOUNT_PREPAID_UPDATE_SUCCESS = 'BILLING_ACCOUNT|PREPAID_UPDATE_SUCCESS';
export const BILLING_ACCOUNT_PREPAID_UPDATE_FAIL = 'BILLING_ACCOUNT|PREPAID_UPDATE_FAIL';