/**
 * Hovers will use secondary theme
 * Somehow we need detect user settings if they prefer dark or light theme
 * Default is light theme
 */
let lightTheme = {
    text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.38)",
    },
    action: {
        active: "rgba(0, 0, 0, 0.54)",
        hover: "rgba(0, 0, 0, 0.04)",
        selected: "rgba(0, 0, 0, 0.08)",
        disabled: "rgba(0, 0, 0, 0.26)",
        disableBackground: "rgba(0, 0, 0, 0.12)"
    },
    background: {
        default: "#fafafa",
        paper: "#fff"
    },
    buttonText: {
        default: "#fafafa"
    },
    divider: "rgba(0, 0, 0, 0.12)"
};
// let darkTheme = {
//     text: {
//         primary: "#fff",
//         secondary: "rgba(255, 255, 255, 0.7)",
//         disabled: "rgba(255, 255, 255, 0.5)",
//     },
//     action: {
//         active: "#fff",
//         hover: "rgba(255, 255, 255, 0.08)",
//         selected: "rgba(255, 255, 255, 0.16)",
//         disabled: "rgba(255, 255, 255, 0.3)",
//         disableBackground: "rgba(255, 255, 255, 0.12)"
//     },
//     background: {
//         default: "#303030",
//         paper: "#424242"
//     },
//     buttonText: {
//         default: "#fafafa"
//     },
//     divider: "rgba(255, 255, 255, 0.12)"
// };
export const informationColors = {
    error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f"
    },
    warning: {
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00"
    },
    info: {
        light: "rgba(0, 0, 0, 0.08)",
        main: "rgba(0, 0, 0, 0.26)",
        dark: "rgba(0, 0, 0, 0.54)"
    },
    success: {
        light: "#81c784",
        main: "#4caf50",
        dark: "#388e3c"
    },
    customInProgress: {
        light: '#bdbdbd',
        main: '#9e9e9e',
        dark: '#757575',
    }
};
export const adminTheme = {
    palette: {
        primary: {
            main: "#d50000",
            sidebar: '#d50000',
            light: "background: linear-gradient(90deg, rgba(69,66,126,1) 0%, rgba(7,130,230,1) 0%, rgba(125,197,227,1) 0%, rgba(3,169,244,1) 0%, rgba(3,169,244,1) 100%, rgba(137,155,162,1) 100%);"
        },
        secondary: {
            main: "#c93a3a"
        },
        ...lightTheme,
        ...informationColors
    }
}
export const normalUserTheme = {
    palette: {
        primary: {
            main: "#1b2a33",
            sidebar: '#1b2a33',
            light: "linear-gradient(90deg, rgba(69,66,126,1) 0%, rgba(67,80,52,1) 0%, rgba(3,169,244,1) 0%, rgba(3,169,244,1) 0%, rgba(125,197,227,1) 0%);"
        },
        secondary: {
            main: "#384f5c"
        },
        ...lightTheme, // Here we should decide if we use dark or light theme
        ...informationColors
    }
}