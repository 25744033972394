import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';

import NPDatatable from '../../np-components/Datatable';
import { Paper, Tooltip, IconButton } from '@material-ui/core';
import useListFilter from '../../np-components/Datatable/listFilter';
import { Edit, Delete} from '@material-ui/icons';
import { isSuperUser } from '../../np-utilities';
import { parseURL, BackButton } from '../../ms-utilities';

const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#d50000" : "#1b2a33",
        '&:hover': {
            backgroundColor: "#757575",
        },
    },
    chipModuleActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    chipModuleInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    media: {
        width: 50,
        height: 50
    }
});

const updateFilterParams = props => {
    const { listFilter } = props;
    let searchParams = parseURL(window.location.search);
    return { ...listFilter, ...searchParams };
};

const ModuleList = props => {
    const { classes, modules, onFetchModules, columnsState, activeCustomer } = props;
    const modulesCount = modules.count;
    const modulesList = modules.list;

    let tableID = 'Module';
    let visible = columnsState && columnsState[tableID];

    const columns = [
        {
            displayName: 'Action',
            backendName: 'Action',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: 'Name',
            backendName: 'Name',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Name'] : true,
            }
        },
        {
            displayName: 'Description',
            backendName: 'Description',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Description'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => { if (column.backendName !== 'Action') backendNames.push(column.backendName); return null; });
    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });

    if (!props.hasAtLeastOnePermission(['module_create', 'module_load', 'module_save', 'module_delete'])) {
        columns.splice(0, 1);
    }

    useEffect(() => {
        if (listFilter.filter) {
            if (!listFilter.filter.q) {
                listFilter.filter.q = {};
            }
            if (!listFilter.filter.q.and) {
                listFilter.filter.q.and = {};
            }
            listFilter.filter.q = { ...listFilter.filter.q, and: updateFilterParams({ listFilter: listFilter.filter.q.and }) };
            onFetchModules({ ...listFilter.filter, ActiveCustomerID: activeCustomer });
        }
    }, [modules.created, activeCustomer, modules.deleted, modules.bulkDeleted, modules.edited, listFilter.filter, onFetchModules]);


    const createActions = moduleID => {
        return <>
            {
                props.hasPermission('module_save') ? <Tooltip title="Edit module">
                    <IconButton value={moduleID} aria-label="Edit module" onClick={props.handleEdit}>
                        <Edit />
                    </IconButton>
                </Tooltip> : <div></div>
            }
            {
                props.hasPermission('module_delete') ?
                    <Tooltip title="Delete module">
                        <IconButton value={moduleID} aria-label="Delete module" onClick={props.handleDelete}>
                            <Delete />
                        </IconButton>
                    </Tooltip> : <div></div>
            }
        </>
    };


    const tableOptions = {
        isSelectable: props.hasPermission('module_delete'),
        tableSearch: false,
        tableID: tableID,
        saveColumn: true,
        selectOptions: { printing: false, generatingReport: false, deleting: props.hasPermission('module_delete'), creating: props.hasPermission('module_create') },
        rowsPerPage: 20,
        count: modulesCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'Modules',
        noMatch: "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return props.handleBulkDelete(IDs);
        },
        onSaveFilter: (columns) => {
            let objToSendColumn = {};
            let objColumns = {};
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible;
                return objColumns
            });
            objToSendColumn[`${tableID}`] = objColumns;
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }
    };


    let counter = 0;
    let modulesData = modulesList.map(module => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = module.ModuleID;
        returnObj.visible = true;
        returnObj.values = [
            createActions(module.ModuleID),
            module.Name,
            module.Description,
        ];
        if (!props.hasAtLeastOnePermission(['module_create', 'module_load', 'module_delete', 'module_delete'])) {
            returnObj.values.splice(0, 1);
        }
        counter++;
        return returnObj;
    });

    return (
        <Paper>
            {BackButton()}
            <NPDatatable handleCreate={props.handleCreate} customStyles={classes} data={modulesData} columns={columns} options={tableOptions} />
        </Paper>
    );
}

export default withStyles(styles)(ModuleList);