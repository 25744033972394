import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { combineReducers } from 'redux';

import BinaryImageType from './BinaryImageType';
import MSQTTool from './MSQTTool';
import BinaryImages from './BinaryImages';

import binaryImagesReducer from './BinaryImages/store/binaryImagesReducer';
import binaryImageTypeReducer from './BinaryImageType/store/binaryImageTypeReducer';
import msqtToolsReducer from './MSQTTool/store/msqtToolsReducer';
import BinaryImagesCreate from './BinaryImages/BinaryImageCreate';
import BinaryImagesEdit from './BinaryImages/BinaryImageEdit';

export const routes = (basePath) => (
    < Routes >
        {< Route path={basePath + '/binaryImageType'} element={<BinaryImageType/>} />}
        {< Route path={basePath + '/msqtTool'} element={<MSQTTool/>} />}
        {< Route path={basePath + '/binary-images'} element={<BinaryImages/>} />}
        {< Route path={basePath + '/binary-images/create'} element={<BinaryImagesCreate/>} />}
        {< Route path={basePath + '/binary-images/edit'} element={<BinaryImagesEdit/>} />}
    </Routes >
);

export const subComponents = () => [
    { name: "Binary image type", path: '/binaryImageType', permissions: ['binary_image_type'] },
    { name: "Binary Images", path: '/binary-images', permissions: ['binary_image'] },
    { name: "MSQT tool", path: '/msqtTool', permissions: ['msqt_tool'] },
];

export const reducers = combineReducers({
    binaryImageType: binaryImageTypeReducer,
    msqtTools: msqtToolsReducer,
    binaryImages: binaryImagesReducer
});