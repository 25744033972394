import React, { useState } from 'react';

import Form from '../../np-form/Form';
import { Dialog, withStyles } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});

const BinaryImageTypeCreate = props => {
    const { open, classes, loading, activeCustomer, translations } = props;

    const form = {
        Key: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['key'] || 'Key',
                label: translations['key'] || 'Key',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                autoFocus: true

            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        Name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['name'] || 'Name',
                label: translations['name'] || 'Name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        }
    };

    const [binaryImageTypeForm, setBinaryImageTypeForm] = useState(form);
    const [formIsValid, setFormIsValid] = useState(false);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, binaryImageTypeForm);
        setBinaryImageTypeForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    }

    const submitHandler = event => {
        event.preventDefault();
        const formData = formUtility.getFormData(binaryImageTypeForm);
        setBinaryImageTypeForm(form);
        setFormIsValid(false);
        props.createBinaryImageType({ ...formData, ActiveCustomerID: activeCustomer });
        props.handleOpen();
    }

    const cancelHandler = () => {
        props.handleOpen();
    }
    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            fullWidth={true}
            // onClose={props.handleOpen}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    props.handleOpen();
                }
            }}
        >
            <Form
                formIsValid={formIsValid}
                classes={classes}
                form={binaryImageTypeForm}
                formTitle={translations['create_binary_image_type'] || "Create binary image type "}
                buttonText={translations['save'] || "Save"}
                buttonTextCancel={translations['cancel'] || "Cancel"}
                submitHandler={submitHandler}
                cancelHandler={cancelHandler}
                inputChangedHandler={inputChangedHandler}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                cancelButtonDisabled={loading}
                submitButtonDisabled={loading}
                submitButtonStyle={formIsValid && !loading ? { color: 'white' } : null}
            />
        </Dialog>
    )
}

export default withStyles(styles)(BinaryImageTypeCreate);