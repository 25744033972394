import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {combineReducers} from 'redux';

// Reducers
import environmentReducer from './Environment/store/environmentReducer';

// Components
import EnvironmentList from './Environment';

// Routes
export const routes = basePath =>
    (
        <Routes>
            <Route path={`${basePath}/*`} element={<EnvironmentList/>}/>
        </Routes>
    );

export const reducers = combineReducers({
    environments: environmentReducer
});