import restCrudActions from '../../../np-crud/store/restCrudActions'

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: '/billing/plan',
    pluginName: 'BILLING',
    entityName: 'PLAN',
    fetchSuccessMessage: '',
    loadSuccessMessage: ''
});

// Fetch billingPlan action creators
export const fetchBillingPlanInit = () => {
    return crudActions.fetchInit();
};
export const fetchBillingPlanStart = () => {
    return crudActions.fetchStart();
};
export const fetchBillingPlanSuccess = () => {
    return crudActions.fetchSuccess();
};
export const fetchBillingPlanFail = () => {
    return crudActions.fetchFail();
};
export const fetchBillingPlan = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
};

// Load billingPlan action creators
export const loadBillingPlanInit = () => {
    return crudActions.loadInit();
};
export const loadBillingPlanStart = () => {
    return crudActions.loadStart();
};
export const loadBillingPlanSuccess = loadedBillingPlan => {
    return crudActions.loadSuccess(loadedBillingPlan);
};
export const loadBillingPlanFail = error => {
    return crudActions.loadFail(error);
};
export const loadBillingPlan = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

// Create billingPlan  action creators
export const createBillingPlanInit = () => {
    return crudActions.createInit();
};
export const createBillingPlanStart = () => {
    return crudActions.createStart();
};
export const createBillingPlanSuccess = createdBillingPlan => {
    return crudActions.createSuccess(createdBillingPlan);
};
export const createBillingPlanFail = error => {
    return crudActions.createFail(error);
};
export const createBillingPlan = billingPlan => {
    return async dispatch => {
        await crudActions.create(dispatch, billingPlan);
    };
};

// Edit billingPlan  action creators
export const editBillingPlanInit = () => {
    return crudActions.editInit();
};
export const editBillingPlanStart = () => {
    return crudActions.editStart();
};
export const editBillingPlanSuccess = editedBillingPlan => {
    return crudActions.editSuccess(editedBillingPlan);
};
export const editBillingPlanFail = error => {
    return crudActions.editFail(error);
};
export const editBillingPlan = billingPlan => {
    return async dispatch => {
        await crudActions.edit(dispatch, billingPlan);
    };
};

// Bulk delete billingPlan action creators
export const bulkDeleteBillingPlansInit = () => {
    return crudActions.bulkDeleteInit();
};
export const bulkDeleteBillingPlansStart = () => {
    return crudActions.bulkDeleteStart();
};
export const bulkDeleteBillingPlansSuccess = bulkDeleted => {
    return crudActions.bulkDeleteSuccess(bulkDeleted);
};
export const bulkDeleteBillingPlansFail = error => {
    return crudActions.bulkDeleteFail(error);
};
export const bulkDeleteBillingPlans = billingPlanIds => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, billingPlanIds);
    };
};

// Delete billingPlan  action creators
export const deleteBillingPlanInit = () => {
    return crudActions.deleteInit();
};
export const deleteBillingPlanStart = () => {
    return crudActions.deleteStart();
};
export const deleteBillingPlanSuccess = deletedBillingPlan => {
    return crudActions.deleteSuccess(deletedBillingPlan);
};
export const deleteBillingPlanFail = error => {
    return crudActions.deleteFail(error);
};
export const deleteBillingPlan = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};