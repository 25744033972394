import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import NPDatatable from '../../../np-components/Datatable';
import { Tooltip, IconButton, LinearProgress } from '@material-ui/core';
import useListFilter from '../../../np-components/Datatable/listFilter';
import { Edit, Delete, VpnKey } from '@material-ui/icons';
import { copyToClipBoard, isSuperUser } from '../../../np-utilities';
import { formatDate, parseURL } from '../../../ms-utilities';
import { CustomChip } from '../../../ms-components';
import SoftwareLicenseDelete from "./SoftwareLicenseDelete";
import SoftwareLicenseCreate from "./SoftwareLicenseCreate";
import SoftwareLicenseEdit from "./SoftwareLicenseEdit";

const styles = theme => ({
    root: {},
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#d50000" : "#1b2a33",
        '&:hover': {
            backgroundColor: "#757575",
        },
    },
    chipActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    chipInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    media: {
        width: 50,
        height: 50
    },
    paletteButtons: {
        backgroundColor: isSuperUser() ? theme.palette.secondary.main : theme.palette.primary.main,
        color: 'white',
        margin: theme.spacing()
    },
});

const updateFilter = props => {
    const { listFilter } = props;
    let searchParams = parseURL(window.location.search);
    return { ...listFilter, ...searchParams };
};

const SoftwareLicenseList = props => {
    const { classes, softwareLicenses, onListSoftwareLicenses, extraFilterParams, columnsState, activeCustomer } = props;
    const softwareLicenseList = softwareLicenses.list ? softwareLicenses.list : [];

    const [extraFilterInfo] = useState({
        showDeleted: false,
        AccountID: extraFilterParams.AccountID
    });
    let tableID = 'SoftwareLicense';
    let visible = columnsState && columnsState[tableID];


    const columns = [
        {
            displayName: 'Action',
            backendName: 'Action',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: 'Name',
            backendName: 'Name',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Name'] : true,
            }
        },
        {
            displayName: 'Secret key',
            backendName: 'SecretKey',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['SecretKey'] : true,
            }
        },
        {
            displayName: 'ApiKey',
            backendName: 'ApiKey',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['ApiKey'] : true,
            }
        },
        {
            displayName: 'Status',
            backendName: 'Active',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Active'] : true,
            }
        },
        {
            displayName: 'Expires at',
            backendName: 'ExpiresAt',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['ExpiresAt'] : true,
            }
        },
        {
            displayName: 'Deleted',
            backendName: 'Deleted',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Deleted'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => {
        if (column.backendName !== 'Action') backendNames.push(column.backendName);
        return null;
    });

    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });

    if (!props.hasAtLeastOnePermission(['license_prototype_create', 'license_prototype_delete', 'license_prototype_load'])) {
        columns.splice(0, 1);
    }

    useEffect(() => {
        if (listFilter.filter) {
            if (!listFilter.filter.q) {
                listFilter.filter.q = {};
            }
            if (!listFilter.filter.q.and) {
                listFilter.filter.q.and = {};
            }
            listFilter.filter.q = {
                ...listFilter.filter.q, ...extraFilterInfo,
                and: updateFilter({ listFilter: listFilter.filter.q.and })
            };
            onListSoftwareLicenses({ ...listFilter.filter, ...extraFilterInfo, ActiveCustomerID: activeCustomer });
        }
    }, [extraFilterInfo, softwareLicenses.created, softwareLicenses.deleted, softwareLicenses.bulkDeleted, softwareLicenses.updated, listFilter.filter, onListSoftwareLicenses, activeCustomer]);

    const [addModalState, setAddModalState] = useState(false);
    const [licensePrototypes, setLicensePrototypes] = useState([]);

    const handleOpen = async event => {
        if (!event) {
            setAddModalState(!addModalState);
            return;
        }
        let data = await props.listLicensePrototypes({ ActiveCustomerID: activeCustomer });
        setLicensePrototypes(data);
        setAddModalState(!addModalState);
    };

    const [editModalState, setEditModalState] = useState(false);
    const handleEdit = async event => {
        if (!event) {
            setEditModalState(!editModalState);
            return;
        }
        const LicenseID = event.currentTarget.value;
        await props.onFetchSoftwareLicense({ LicenseID: LicenseID, ActiveCustomerID: activeCustomer });
        let data = await props.listLicensePrototypes({ ActiveCustomerID: activeCustomer });
        setLicensePrototypes(data);
        setEditModalState(!editModalState);
    };

    const [deleteModalState, setDeleteModalState] = useState({
        open: false,
        softwareLicenseID: null,
        softwareLicenseIDs: [],
        forBulk: false
    });
    const handleDelete = async event => {
        if (!event) {
            setDeleteModalState({ open: false, softwareLicenseID: null, softwareLicenseIDs: [], forBulk: false });
            return;
        }
        const softwareLicenseID = event.currentTarget.value;
        setDeleteModalState({ ...deleteModalState, open: true, softwareLicenseID: softwareLicenseID });
    };

    const handleBulkDelete = softwareLicenseIDS => {
        setDeleteModalState({ ...deleteModalState, open: true, softwareLicenseIDs: softwareLicenseIDS, forBulk: true });
    };

    const createActions = (softwareLicenseID, deleted) => {
        return <>
            {
                props.hasPermission('license_prototype_load') ? <Tooltip title="Edit software license">
                    <IconButton value={softwareLicenseID} aria-label="Edit software license" onClick={handleEdit}>
                        <Edit />
                    </IconButton>
                </Tooltip> : <div></div>
            }
            {
                props.hasPermission('license_prototype_delete') && !deleted ?
                    <Tooltip title="Delete software license">
                        <IconButton value={softwareLicenseID} aria-label="Delete software license"
                            onClick={handleDelete}>
                            <Delete />
                        </IconButton>
                    </Tooltip> : <div></div>
            }
        </>
    };

    const getFormattedStatus = (id, status = '0') => {
        if (status === 'active' || status.toString() === '1') {
            return <CustomChip key={`${id}`} className={classes['chipActive']} value={'Active'} />;
        }
        if (status === 'inactive' || status.toString() === '0') {
            return <CustomChip key={`${id}`} className={classes['chipInactive']} value={'Inactive'} />;
        }
        return '-';
    };

    const tableOptions = {
        isSelectable: false,
        tableSearch: false,
        tableID: 'SoftwareLicense',
        saveColumn: true,
        selectOptions: {
            printing: false,
            generatingReport: false,
            deleting: props.hasPermission('license_prototype_delete'),
            creating: props.hasPermission('license_prototype_create')
        },
        tableTitle: 'Software licenses',
        disablePagination: true,
        noMatch: "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return handleBulkDelete(IDs);
        },
        onSaveFilter: (columns) => {
            let objToSendColumn = {}
            let objColumns = {}
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible
                return objColumns
            })
            objToSendColumn[`${tableID}`] = objColumns
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }
    };


    let counter = 0;
    let softwareLicensesData = softwareLicenseList.map(({ LicenseID, Name, SecretKey, ApiKey, Status, ExpiresAt, Deleted }) => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = LicenseID;
        returnObj.visible = true;
        returnObj.values = [
            createActions(LicenseID, Deleted),
            Name,
            SecretKey,
            <Tooltip title="Copy to clipboard">
                <IconButton aria-label="Copy to clipboard"
                    onClick={() => {
                        return copyToClipBoard(ApiKey)
                    }}>
                    <VpnKey />
                </IconButton>
            </Tooltip>,
            getFormattedStatus(LicenseID, Status),
            ExpiresAt ? formatDate(ExpiresAt) : 'Expiration date not set',
            Deleted === true ? 'Yes' : 'No'
        ];

        if (!props.hasAtLeastOnePermission(['license_prototype_create', 'license_prototype_delete', 'license_prototype_load'])) {
            returnObj.values.splice(0, 1);
        }

        counter++;
        return returnObj;
    });

    return <>
        <div
            className={classes.progressBarr}>
            {softwareLicenses.loading ? <LinearProgress color="primary" /> : null}
        </div>
        <NPDatatable handleCreate={handleOpen}
            data={softwareLicensesData}
            columns={columns}
            options={tableOptions}
        />
        {props.hasPermission('license_prototype_delete') ?
            <SoftwareLicenseDelete
                deleteModalState={deleteModalState}
                handleDelete={handleDelete}
                activeCustomer={activeCustomer}
                entityName={'Software license'}
                classes={classes}
                onDeleteSoftwareLicense={props.onDeleteSoftwareLicense}
            /> : null}
        {props.hasPermission('license_prototype_create') ?
            <SoftwareLicenseCreate
                AccountID={extraFilterParams.AccountID}
                handleOpen={handleOpen}
                open={addModalState}
                activeCustomer={activeCustomer}
                loading={softwareLicenses.loading}
                onCreateSoftwareLicense={props.onCreateSoftwareLicense}
                licensePrototypes={licensePrototypes}
                hasPermission={props.hasPermission}
            /> : null}
        {props.hasPermission('license_prototype_load') ?
            <SoftwareLicenseEdit
                AccountID={extraFilterParams.AccountID}
                handleOpen={handleEdit}
                open={editModalState}
                activeCustomer={activeCustomer}
                loading={softwareLicenses.loading}
                onUpdateSoftwareLicense={props.onUpdateSoftwareLicense}
                softwareLicense={softwareLicenses.loaded}
                licensePrototypes={licensePrototypes}
                hasPermission={props.hasPermission}
            /> : null}
    </>
};

export default withStyles(styles)(SoftwareLicenseList);