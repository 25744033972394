import { useState, useEffect } from 'react';

const UseListFilter = props => {
    // Options - check possible default parameters from the props
    const [options, setOptions] = useState({
        page: props.page ? parseInt(props.page) : 0,
        rowsPerPage: props.rowsPerPage ? parseInt(props.rowsPerPage) : 10,
        filterList: props.filterList ? props.filterList : [],
        searchText: props.searchText ? props.searchText : '',
        sortOrder: props.sortOrder ? props.sortOrder : '',
        sortColumn: props.sortColumn ? props.sortColumn : '',
        searchColumns: props.searchColumns ? props.searchColumns : []
    });
    // Filter for backend calls
    const [filter, setFilter] = useState(null);


    // If there were changes in the options, the filter will be updated
    useEffect(() => {
        // Update the filter
        const updateFilter = () => {
            // set limit and offset
            let filter = {
                limit: options.rowsPerPage,
                offset: options.rowsPerPage * options.page,
                order: [],
                q: null
            };

            // Set order
            if (options.sortOrder !== '' && options.sortColumn !== '') {
                filter.order = [{ field: options.sortColumn, direction: options.sortOrder }];
            }

            // Set query
            if (options.searchText && options.searchText !== '') {
                const or = [];
                options.searchColumns.forEach(searchColumn => {
                    var orItem = {};
                    orItem[searchColumn] = { like: '%' + options.searchText + '%' };
                    or.push(orItem);
                });

                filter.q = { or: or };
            }

            setFilter(filter);
        };
        // Update with some delay. This helps to avoid to much change events e.g. during typing a search text
        const timer = setTimeout(() => {
            updateFilter();
        }, 600);
        return () => {
            clearTimeout(timer);
        };
    }, [options]);

    const changePage = page => {
        setOptions({
            ...options,
            page: page
        });
    };

    const changeRowsPerPage = rowsPerPage => {
        setOptions({
            ...options,
            page: 0,
            rowsPerPage: rowsPerPage
        });
    };

    const handleFilter = filterList => {
        setOptions({
            ...options,
            page: 0,
            filterList: filterList
        });
    };

    const changeSearchText = searchText => {
        setOptions({
            ...options,
            page: 0,
            searchText: searchText
        });
    };

    const handleSort = (column, order) => {
        setOptions({
            ...options,
            page: 0,
            sortColumn: column,
            sortOrder: order === 'ascending' ? 'asc' : 'desc'
        });
    };

    const onTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page);
                break;
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage);
                break;
            case 'filterChange':
                handleFilter(tableState.filterList);
                break;
            case 'search':
                changeSearchText(tableState.searchText);
                break;
            case 'sortColumn':
                handleSort(tableState.column, tableState.order);
                break;
            default:
                break;
        }
    };

    return {
        // options
        options: options,
        // filter
        filter: filter,
        // datatable change helper functions
        onTableChange: onTableChange,
        changePage: changePage,
        changeRowsPerPage: changeRowsPerPage,
        handleFilter: handleFilter,
        changeSearchText: changeSearchText,
        handleSort: handleSort
    };
};

export default UseListFilter;
