import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Paper, Tooltip, IconButton, Dialog, Typography, Checkbox } from '@material-ui/core';
import { FindInPage, Info, Delete } from '@material-ui/icons';
import useListFilter from '../../../np-components/Datatable/listFilter';
import NPDatatable from '../../../np-components/Datatable';
import { CustomChip } from '../../../ms-components';
import { isSuperUser } from '../../../np-utilities';
import { parseURL } from '../../../ms-utilities';
import { Navigate, Route, Routes } from 'react-router-dom';

const styles = theme => ({
    userActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    userInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#d50000" : "#1b2a33",
        '&:hover': {
            backgroundColor: "#757575",
        },
    },

});

const updateFilterParams = props => {
    const { listFilter } = props;
    let searchParams = parseURL(window.location.search);
    return { ...listFilter, ...searchParams };
}

const UsersAdminList = props => {
    const { classes, users, onFetchUsersList, columnsState, activeCustomer } = props;
    const usersCount = users.count;
    const usersList = users.list;
    const [open, setOpen] = useState(false);
    let tableID = 'AdminList';
    let visible = columnsState && columnsState[`${tableID}`]
    const [redirectOptions, setRedirectOptions] = useState({ redirect: false, productID: 0, redirectPath: '' });

    const columns = [
        {
            displayName: 'Actions',
            backendName: 'Actions',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: 'First name',
            backendName: 'FirstName',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['FirstName'] : true,
            }
        },
        {
            displayName: 'Last name',
            backendName: 'LastName',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['LastName'] : true,
            }
        },
        {
            displayName: 'Username',
            backendName: 'Username',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Username'] : true,
            }
        },
        {
            displayName: 'Email',
            backendName: 'Email',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Email'] : true,
            }
        },
        {
            displayName: 'Status',
            backendName: 'Status',
            options: {
                align: 'left',
                sortable: true,
                searchable: false,
                visible: visible ? visible['Status'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => {
        if (column.backendName !== 'Action') backendNames.push(column.backendName);
        return null;
    })
    if (!props.hasAtLeastOnePermission(['user_create', 'user_load', 'user_delete'])) {
        columns.splice(0, 1);
    }
    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });
    const detailsUser = async (userID) => {
        setRedirectOptions({ redirect: true, userID: userID, redirectPath: `/users/details?userID=${userID}` });
    };

    const createActions = (userID, data, username, deleted) => {
        return <>
            {
                <Tooltip title={"User details"}>
                    <IconButton value={userID} onClick={() => detailsUser(userID)} aria-label={"User details"}  >
                        <FindInPage />
                    </IconButton>
                </Tooltip>
            }
            {
                <Tooltip title={"See roles for customer "}>
                    <IconButton value={userID} onClick={() => openInfo(data, username)} aria-label={"See roles for customer "} >
                        <Info />
                    </IconButton>
                </Tooltip>
            }
            {!deleted ?
                <Tooltip title={"Delete user"}>
                    <IconButton value={userID} aria-label={"Delete user"} onClick={props.handleDelete}>
                        <Delete />
                    </IconButton>
                </Tooltip>
                : null}
        </>
    };
    const [data, setData] = useState([])
    const [name, setName] = useState('')
    const openInfo = (data, username) => {
        let roleArray = []
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                const element = data[key];
                roleArray.push(`${element.Customer.Name}  -  ${element.Role.Name} `)
            }
        }
        setName(`${username}`)
        setData(roleArray)
        setOpen(true)
    }
    const [userDeleted, setUserDeleted] = useState(false);

    useEffect(() => {
        // fetch calls
        if (listFilter.filter) {
            if (!listFilter.filter.q) {
                listFilter.filter.q = {};
            }
            if (!listFilter.filter.q.and) {
                listFilter.filter.q.and = {};
            }


            listFilter.filter.q = { ...listFilter.filter.q, and: updateFilterParams({ listFilter: listFilter.filter.q.and }) };
            onFetchUsersList({ ...listFilter.filter, showDeleted: userDeleted, ActiveCustomerID: activeCustomer });
        }
    }, [listFilter.filter, onFetchUsersList, activeCustomer, users.creating, users.creating, users.deleting, userDeleted]);
    const customFilterToolbar = () => {
        return <>
            <Checkbox checked={userDeleted} value={'showDeleted'} onChange={() => setUserDeleted(!userDeleted)} /> {'Show deleted'}
        </>
    };
    const tableOptions = {
        isSelectable: false,
        tableSearch: false,
        tableID: tableID,
        saveColumn: true,
        page: 0,
        selectOptions: { printing: false, generatingReport: false, creating: true, deleting: false },
        rowsPerPage: 20,
        count: usersCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'Users',
        noMatch: "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onSaveFilter: (columns) => {
            let objToSendColumn = {}
            let objColumns = {}
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible
                return objColumns
            })
            objToSendColumn[`${tableID}`] = objColumns
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }
    };

    let counter = 0;
    let usersData = usersList.map(user => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = user.UserID
        returnObj.visible = true;
        returnObj.values = [
            createActions(user.UserID, user.UserRoles, user.Username, user.Deleted), user.FirstName, user.LastName, user.Username, user.Email,
            <CustomChip key='userChipStatus' className={Number(user.Status) === 1 ? classes.userActive : classes.userInactive} value={Number(user.Status) === 1 ? 'Active' : 'Inactive'} />

        ]
        if (!props.hasAtLeastOnePermission(['user_create', 'user_load', 'user_delete'])) {
            returnObj.values.splice(0, 1);
        }
        counter++;
        return returnObj;
    });
    const handleCreate = () => {
        setRedirectOptions({ redirect: true, redirectPath: `/users/create` });
    };
    if (redirectOptions.redirect) {
        return <Routes><Route path="*" element={<Navigate to={redirectOptions.redirectPath} />} /></Routes>;
    }

    return (
        <div>
            <Paper>
                <NPDatatable handleCreate={handleCreate} customFilterToolbar={customFilterToolbar} customStyles={classes} data={usersData} columns={columns}
                    options={tableOptions} />
            </Paper>
            <Dialog
                open={open}
                disableEscapeKeyDown={false}
                disableEnforceFocus={false}
                fullWidth={true}
                onClose={()=>{setOpen(false)}}
            >
                <div style={{ marginLeft: '12px', marginTop: '6px' }}><h2>Role information for {name} </h2></div>
                <div style={{ minHeight: ' 100px', minWidth: '100px', float: 'center', margin: '15px' }}>{data.map(role =>
                    <Typography key={role}> {role}<br></br></Typography>)}</div>
            </Dialog>
        </div>
    );
}

export default (withStyles(styles)(UsersAdminList));