import { Typography, TextField, Checkbox, Tooltip, TableSortLabel } from "@material-ui/core";
import React from 'react';

const TableHeader = props => {
    const { options, classes, columns, translations } = props;
    return (
        <thead>
            <tr key={`row_head_checkbox`}>
                {/** IF ROW IS SELECTABLE DISPLAY THIS CELL */}
                {options.isSelectable ?
                    <th className={classes.checkboxTh}>
                        <Checkbox
                            indeterminate={props.numSelected > 0 && props.numSelected < props.rowCount}
                            checked={props.numSelected > 0 && props.numSelected === props.rowCount}
                            onChange={props.onSelectAllClick}
                            color='primary'
                        />
                    </th>
                    : null}
                {/** END */}
                {columns.map(
                    column => (
                        column.options.visible ?
                            column.backendName === 'Action' || columns.indexOf(column) === 0 ?
                                <th
                                    key={`row_head_${column.backendName}_${columns.indexOf(column)}`}
                                    className={classes.actionTh}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        {/**IF COLUMNS IS SEARCHABLE SHOW INPUT FIELD, OTHERWISE SHOW JUST FIELD NAME */}
                                        {
                                            column.options.searchable ?
                                                <TextField
                                                    id={`search_${column.backendName}_${options.tableID}`}
                                                    type="search"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    label={column.displayName}
                                                    placeholder={column.displayName}
                                                    onChange={props.handleColumnSearch}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                :
                                                <Typography className={classes.columnHeaderStyle}>
                                                    {column.displayName}
                                                </Typography>
                                        }
                                        {/**END */}
                                        {/** IF COLUMN IS SORTABLE SHOW SORT */}
                                        {
                                            column.options.sortable ?
                                                <span
                                                    role="button"
                                                    onClick={props.handleSort}
                                                    id={`sort_${column.backendName}_${typeof column.options.order === 'string' && column.options.order.toLowerCase() === 'desc' ? 'asc' : 'desc'}`}
                                                >
                                                    <Tooltip
                                                        title={translations ? translations["sort"] || "Sort" : "Sort"}
                                                        placement={'bottom-end'}
                                                        key={`tooltip_${column.backendName}`}
                                                    >
                                                        <TableSortLabel
                                                            active={true}
                                                            direction={typeof column.options.order === 'string' && column.options.order.toLowerCase() === 'desc' ? 'asc' : 'desc'}
                                                        >
                                                        </TableSortLabel>

                                                    </Tooltip>
                                                </span>
                                                : null
                                        }
                                    </div>
                                </th>
                                :
                                <th
                                    key={`row_head_${column.backendName}_${columns.indexOf(column)}`}
                                    className={classes.headerTh}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        {/**IF COLUMNS IS SEARCHABLE SHOW INPUT FIELD, OTHERWISE SHOW JUST FIELD NAME */}
                                        {
                                            column.options.searchable ?
                                                <TextField
                                                    id={`search_${column.backendName}_${options.tableID}`}
                                                    type="search"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    label={column.displayName}
                                                    placeholder={column.displayName}
                                                    onChange={props.handleColumnSearch}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                :
                                                <Typography className={classes.columnHeaderStyle}>
                                                    {column.displayName}
                                                </Typography>
                                        }
                                        {/**END */}
                                        {/** IF COLUMN IS SORTABLE SHOW SORT */}
                                        {
                                            column.options.sortable ?
                                                <span
                                                    role="button"
                                                    onClick={props.handleSort}
                                                    id={`sort_${column.backendName}_${typeof column.options.order === 'string' && column.options.order.toLowerCase() === 'desc' ? 'asc' : 'desc'}`}
                                                >
                                                    <Tooltip
                                                        title={translations ? translations["sort"] || "Sort" : "Sort"}
                                                        placement={'bottom-end'}
                                                        key={`tooltip_${column.backendName}`}
                                                    >
                                                        <TableSortLabel
                                                            active={true}
                                                            direction={typeof column.options.order === 'string' && column.options.order.toLowerCase() === 'desc' ? 'asc' : 'desc'}
                                                        >
                                                        </TableSortLabel>

                                                    </Tooltip>
                                                </span>
                                                : null
                                        }
                                    </div>
                                </th>
                            : null
                    )
                )}
            </tr>

        </thead>
    );
}

export default TableHeader;