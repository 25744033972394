export const FETCH_INIT = 'FETCH_INIT';
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAIL = 'FETCH_FAIL';

export const CREATE_INIT = 'CREATE_INIT';
export const CREATE_START = 'CREATE_START';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAIL = 'CREATE_FAIL';

export const DELETE_INIT = 'DELETE_INIT';
export const DELETE_START = 'DELETE_START';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAIL = 'DELETE_FAIL';

export const LOAD_INIT = 'LOAD_INIT';
export const LOAD_START = 'LOAD_START';
export const LOAD_SUCCESS = 'LOAD_SUCCESS';
export const LOAD_FAIL = 'CORE|LOAD_FAIL';

export const EDIT_INIT = 'EDIT_INIT';
export const EDIT_START = 'EDIT_START';
export const EDIT_SUCCESS = 'EDIT_SUCCESS';
export const EDIT_FAIL = 'EDIT_FAIL';

export const BULK_DELETE_INIT = 'BULK_DELETE_INIT';
export const BULK_DELETE_START = 'BULK_DELETE_START';
export const BULK_DELETE_SUCCESS = 'BULK_DELETE_SUCCESS';
export const BULK_DELETE_FAIL = 'BULK_DELETE_FAIL';
