import restCrudReducer from '../../../np-crud/store/restCrudReducer';

const [crudInitialState, crudReducer] = restCrudReducer({
    pluginName: 'IMPORT_EXPORT',
    entityName: 'MSQT_TOOLS',
});

const initialState = {
    ...crudInitialState,
    deleteMSQTTool: {},
    downloadProgress: 0,
    deleting: false,
    saving: false
};

const updateDownloadProgress = (state, action) => {
    return { ...state, downloadProgress: action.downloadProgress };
};

const reducer = (state = initialState, action) => {
    // Handle CRUD actions
    const newState = crudReducer(state, action);
    // If CRUD reducer handled the action
    if (newState !== null) {
        return newState;
    }

    // Handle other actions
    switch (action.type) {
        case 'CORE|LOGOUT_SUCCESS':
        case 'CORE|CORE_SET_ACTIVE_CUSTOMER_SUCCESS':
            return initialState;
        case 'IMPORT_EXPORT|MSQT_TOOLS|DOWNLOAD_PROGRESS':
            return updateDownloadProgress(state, action);
        default:
            return state;
    }
};

export default reducer;
