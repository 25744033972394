import React, { useState, useEffect } from 'react';
import Form from '../../../np-form/Form';
import { Paper, withStyles, Stepper, StepLabel, Step, Button, Tooltip } from '@material-ui/core';
import * as formUtility from '../../../np-form/utility';
import { isSuperUser } from '../../../np-utilities';
import Info from '@material-ui/icons/Info';
import moment from 'moment';
import * as action from '../../../ms-billing/BillingAccount/store/billingAccountActions';
import * as actions from '../../Customer/store/customerActions';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});

const updateFormStateOne = (customers, users, currentUserID, translations) => {
    const billingModes = [{ value: 'prepaid', displayValue: translations['prepaid'] || 'Prepaid' }, { value: 'postpaid', displayValue: translations['contract'] || 'Contract' }];
    const accountStatuses = [
        { value: 'active', displayValue: translations['active'] || 'Active' },
        { value: 'prepared', displayValue: translations['prepared'] || 'Prepared' },
        { value: 'inactive', displayValue: translations['inactive'] || 'Inactive' },
        { value: 'expired', displayValue: translations['expired'] || 'Expired' }];
    const termsOfPayment = [
        { value: 'dor', displayValue: 'Due on Receipt' },
        { value: 'pia', displayValue: 'Payment in advance' },
        { value: 'eom', displayValue: 'End of Month' },
        { value: 'cod', displayValue: 'Cash on Delivery' },
        { value: 'net7', displayValue: '7 days after invoice date' },
        { value: 'net10', displayValue: '10 days after invoice date' },
        { value: 'net15', displayValue: '15 days after invoice date' },
        { value: 'net30', displayValue: '30 days after invoice date' },
        { value: 'net60', displayValue: '60 days after invoice date' },
    ];

    const languages = [
        { value: 'en', displayValue: translations['english'] || 'English' },
        { value: 'de', displayValue: translations['german'] || 'German' },
        { value: 'sr', displayValue: translations['serbian'] || 'Serbian' }
    ];
    const accountValue = currentUserID && users ? users.filter(displayValue => { return currentUserID === displayValue.value })[0] : null
    return {
        BillingMode: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: translations['billing_mode'] || 'Billing mode',
                margin: "dense",
                fullWidth: true,
                options: billingModes ? [...billingModes] : []
            },
            formHelperText: translations['select_billing_mode'] || "Please select billing mode",
            value: billingModes && billingModes.length > 0 ? billingModes[0].value : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Status: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: translations['billing_account_status'] || 'Billing account status',
                margin: "dense",
                fullWidth: true,
                options: accountStatuses ? [...accountStatuses] : []
            },
            formHelperText: translations['billing_account_status_text'] || "Please select billing account status",
            value: accountStatuses && accountStatuses.length > 0 ? accountStatuses[0].value : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        ActivationDate: {
            elementType: 'datePickerTheme',
            elementConfig: {
                type: 'datePicker',
                label: translations['activation_date'] || 'Activation date',
                margin: "dense",
                fullWidth: true,
                placeholder: translations['activation_date_text'] || 'Please select the account activation day (default is today)',
                disablePast: true,
                clearable: true
            },
            value: moment.now(),
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        ExpiresAt: {
            elementType: 'datePickerTheme',
            elementConfig: {
                type: 'datePicker',
                label: translations['expires_at'] || 'Expires at',
                margin: "dense",
                fullWidth: true,
                placeholder: translations['expires_at_text'] || 'Please select the expiration date',
                disablePast: true,
                clearable: true,
            },
            value: moment().add(1, 'years'),
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        BillingDay: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                label: translations['billing_day'] || 'Billing day',
                margin: "dense",
                fullWidth: true,
                placeholder: translations['billing_day_text'] || 'Please select on the account billing day',
                inputProps: { min: "1", max: "28" }
            },
            value: 1,
            validation: {
                required: true,
                isNumeric: true,
                min: 1,
                max: 28
            },
            valid: true,
            touched: false
        },
        VAT: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['vat_number'] || 'VAT Number',
                label: translations['vat_number'] || 'VAT Number',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        TaxClassification: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['tax_classification'] || 'Tax Classification',
                label: translations['tax_classification'] || 'Tax Classification',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        TermsOfPayment: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: translations['terms_of_payment'] || 'Terms Of Payment',
                margin: "dense",
                fullWidth: true,
                options: termsOfPayment ? [...termsOfPayment] : []
            },
            formHelperText: translations['terms_of_payment_text'] || "Please select the terms of payment",
            value: termsOfPayment ? termsOfPayment[0].value : null,
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        Accountant: {
            elementType: 'autocomplete',
            elementConfig: {
                type: 'select',
                label: translations['accountant'] || 'Accountant',
                margin: "dense",
                fullWidth: true,
                options: users ? [...users] : []
            },
            formHelperText: translations['select_account'] || "Please select accountant",
            value: accountValue ? accountValue : '',
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        Language: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: translations['communication_language'] || 'Communication language',
                margin: "dense",
                fullWidth: true,
                options: languages ? [...languages] : []
            },
            formHelperText: translations['select_language'] || "Please select language",
            value: languages ? languages[0].value : '',
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        PhoneNumber1: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['phone_number'] || 'Phone number',
                label: translations['phone_number'] || 'Phone number',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Email: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['email'] || 'Email',
                label: translations['email'] || 'Email',
                margin: "dense",
                fullWidth: true,
            },
            value: '',
            validation: {
                required: false,
                isEmail: true
            },
            valid: true,
            touched: true
        },
        CustomerAddress: {
            elementType: 'checkbox',
            elementConfig: {
                type: 'checkbox',
                label: null,
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [{
                    ID: 'checkboxID',
                    Checked: false,
                    Name: translations['same_as_customer_address'] || 'Delivery address same as customer address? (Checked - Yes)'
                }]
            },
            value: false,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        StreetAddress: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['street_address'] || 'Street Address',
                label: translations['street_address'] || 'Street Address',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: customers && customers.length > 0 ? customers[0].StreetAddress : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        PostCode: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['post_code'] || 'Post code',
                label: translations['post_code'] || 'Post code',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: customers && customers.length > 0 ? customers[0].PostCode : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        City: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['city'] || 'City',
                label: translations['city'] || 'City',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: customers && customers.length > 0 ? customers[0].City : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Country: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['country'] || 'Country',
                label: translations['country'] || 'Country',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: customers && customers.length > 0 ? customers[0].Country : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
    };
};

const CustomerCreate = props => {
    const { classes, onListUsers, onListCustomers, currentUserID, activeCustomer, translations } = props;
    const steps = [translations['create_customer'] || 'Create customer', translations['create_account'] || 'Create account', translations['create_user'] || 'Create user'];
    const [customers, setCustomer] = useState([]);
    const [redirectOptions, setRedirectOptions] = useState({ redirect: false, redirectPath: '' });
    const [activeStep, setActiveStep] = useState(0);
    const [without, setWithout] = useState(false);
    const [skipped, setSkipped] = React.useState(new Set());
    const [users, setUsers] = useState([]);

    const renderToolTip = (key = 'securaze_basic') => {
        let formHelperText = null;
        switch (key) {
            case 'securaze_basic':
                formHelperText = <div><p>{translations['customer_passwords_text_0'] || 'Passwords must be at least 3 characters in length'}</p></div>;
                break;
            case 'securaze_standard':
                formHelperText =
                    <div><p>{translations['customer_passwords_text_1'] || 'Passwords must be at least 5 characters in length'}</p><p>
                        {translations['customer_passwords_text_2'] || "Passwords must have at least one upper case"}</p>
                        <p>{translations['customer_passwords_text_3'] || 'Passwords must have at least one lower case'}</p><p>{translations['customer_passwords_text_4'] || "Passwords must have at leastone numeric"}</p></div>;
                break;
            case 'microsoft':
                formHelperText =
                    <div><p>{translations['customer_passwords_text_5'] || 'Passwords must be at least 8 characters in length'}</p>
                        <p>{translations['customer_passwords_text_3'] || 'Passwords must have at least one lower case'}</p><p>
                            {translations['customer_passwords_text_4'] || "Passwords must have at least one numeric"}</p><p>  {translations['customer_passwords_text_2'] || "Passwords must have at least one upper case"}</p><p>
                            {translations['customer_passwords_text_6'] || 'Passwords must have at least one lower special character'}</p><p>{translations['customer_passwords_text_7'] || 'Password should not contain user or customer name'}</p></div>;
                break;
            case 'nist':
                formHelperText =
                    <div><p>{translations['customer_passwords_text_5'] || 'Passwords must be at least 8 characters in length'}</p>
                        <p>{translations['customer_passwords_text_2'] || "Passwords must have at least one upper case"}</p><p>{translations['customer_passwords_text_3'] || 'Passwords must have at least one lower case'}</p><p>{translations['customer_passwords_text_4'] || "Passwords must have at leastone numeric"}</p><p> {translations['customer_passwords_text_2'] || "Passwords must have at least one upper case"}</p>
                        <p>{translations['customer_passwords_text_8'] || "Passwords must have at least one lower special character"}</p><p>{translations['customer_passwords_text_7'] || 'Password should not contain user or customer name'}</p>
                        <p>{translations['customer_passwords_text_9'] || 'Passwords must not contain repetitive characters'}</p></div>;
                break;
            default:
                formHelperText = <div><p>{translations['customer_passwords_text_0'] || 'Passwords must be at least 3 characters in length'}</p></div>;
                break;
        }
        return <Tooltip title={formHelperText} placement='right-start'>
            <Info />
        </Tooltip>
    };

    // First form
    const firstForm = {
        Name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['name'] || 'Name',
                label: translations['name'] || 'Name',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true,
                autoFocus: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        StreetAddress: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['street_address'] || 'Street Address',
                label: translations['street_address'] || 'Street Address',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        PostCode: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['post_code'] || 'Post code',
                label: translations['post_code'] || 'Post code',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        City: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['city'] || 'City',
                label: translations['city'] || 'City',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        Country: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['country'] || 'Country',
                label: translations['country'] || 'Country',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        Status: {
            elementType: 'select_v2',
            inputLabel: 'Status',
            elementConfig: {
                type: 'select',
                label: translations['status'] || 'Status',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true,
                options: [{ value: 'active', displayValue: translations['active'] || 'Active' }, { value: 'inactive', displayValue: translations['inactive'] || 'Inactive' }]
            },
            formHelperText: translations['select_status'] || "Please select customer status",
            value: 'active',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        PasswordComplexity: {
            elementType: 'select_v2',
            inputLabel: translations['password_complexity'] || 'Password complexity',
            elementConfig: {
                type: 'select',
                label: 'PasswordComplexity',
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [{ value: 'securaze_basic', displayValue: `SECURAZE ${translations['basic'] || 'basic'}` },
                {
                    value: 'securaze_standard',
                    displayValue: `SECURAZE ${translations['standard'] || 'standard'}`
                }, { value: 'microsoft', displayValue: 'Microsoft' }, {
                    value: 'nist',
                    displayValue: translations['national_institute'] || 'National Institute of Standards and Technology'
                }]
            },
            value: 'securaze_basic',
            formHelperText: renderToolTip(),
            validation: {
                required: true
            },
            valid: true,
            touched: true
        }
    };

    const [customerForm, setCustomerForm] = useState(firstForm);
    const [customerFormValid, setCustomerFormValid] = useState(false);

    const customerFormInputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, customerForm);
        setCustomerForm({
            ...updateState.myForm,
            PasswordComplexity: {
                ...updateState.myForm.PasswordComplexity,
                formHelperText: renderToolTip(updateState.myForm.PasswordComplexity.value)
            }
        });
        setCustomerFormValid(updateState.formIsValid)
    };

    // Second form
    const secondForm = {
        FirstName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['name'] || 'Name',
                label: translations['name'] || 'Name',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true,
                autoFocus: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        LastName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['last_name'] || 'Last name',
                label: translations['last_name'] || 'Last name',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        Username: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['username'] || 'Username',
                label: translations['username'] || 'Username',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        Email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: translations['e_mail'] || 'E-Mail',
                className: classes.inputs,
                label: translations['e_mail'] || 'E-Mail',
                error: false,
                fullWidth: true
            },
            value: '',
            validation: {
                required: true,
                isEmail: true
            },
            valid: true,
            touched: true

        },
        PhoneNumber: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['phone_number'] || 'Phone number',
                className: classes && classes.inputs ? classes.inputs : null,
                label: translations['phone_number'] || 'Phone number',
                error: false,
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: translations['password'] || 'Password',
                label: translations['password'] || 'Password',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        RepeatPassword: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: translations['password_repeat'] || 'Password repeat',
                label: translations['password_repeat'] || 'Password repeat',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                error: false,
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        UserStatus: {
            elementType: 'select_v2',
            elementConfig: {
                fullWidth: true,
                options: [{ value: 'active', displayValue: translations['active'] || 'Active' }, { value: 'inactive', displayValue: translations['inactive'] || 'Inactive' }]
            },
            value: 'active',
            validation: {},
            valid: true,
            formHelperText: translations['please_select_status'] || 'Please select status'
        },
    };

    const [userForm, setUserForm] = useState(secondForm);
    const [userFormValid, setUserFormValid] = useState(false);
    const [validMail, setValidMail] = useState(false);

    const userInfoFormInputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, userForm);
        setUserForm(updateState.myForm);
        if (inputIdentifier === 'Email') {
            setUserForm({
                ...updateState.myForm,
                Email: {
                    ...updateState.myForm.Email,
                    elementConfig: { ...updateState.myForm.Email.elementConfig, error: !updateState.myForm.Email.valid }
                }
            });
            setUserForm({ ...updateState.myForm, Email: { ...updateState.myForm.Email, elementConfig: { ...updateState.myForm.Email.elementConfig, error: !updateState.myForm.Email.valid && updateState.myForm.Email.value.length > 0 } } })
            setValidMail(true)
        }

        if (inputIdentifier === 'RepeatPassword') {
            setUserForm({ ...updateState.myForm, RepeatPassword: { ...updateState.myForm.RepeatPassword, elementConfig: { ...updateState.myForm.RepeatPassword.elementConfig, error: updateState.myForm.RepeatPassword.value !== updateState.myForm.Password.value } } })
        }
        setUserFormValid(updateState.formIsValid && !(updateState.myForm.RepeatPassword.value !== updateState.myForm.Password.value));

        setUserFormValid(updateState.formIsValid);
        if (validMail && updateState.myForm.Email.value.length === 0) {
            setUserFormValid(true);
        }
        if (updateState.myForm.Password.value.length === 0 || updateState.myForm.FirstName.value.length === 0 || updateState.myForm.Username.value.length === 0 || updateState.myForm.LastName.value.length === 0) {
            setUserFormValid(false);
        }
    };

    // third form

    const [billingAccountForm, setBillingAccountForm] = useState(updateFormStateOne(customers, users, currentUserID, translations));
    const [billingAccountFormValid, setBillingAccountFormValid] = useState(false);

    useEffect(() => {
        setBillingAccountForm(updateFormStateOne(customers, users, currentUserID, translations))
    }, [customers, users, currentUserID, translations]);

    const autoChangedHandler = (event, inputIdentifier) => {
        let updateState = formUtility.getNewStateOnAutoCompleteChange(event, inputIdentifier, billingAccountForm);
        setBillingAccountForm(updateState.myForm);
        setBillingAccountFormValid(updateState.formIsValid);
    };

    const inputChangedHandlerBillingForm = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, billingAccountForm);
        setBillingAccountForm(updateState.myForm);

        setBillingAccountFormValid(updateState.formIsValid);
        if (inputIdentifier === 'Email') {
            setBillingAccountForm({
                ...updateState.myForm,
                Email: {
                    ...updateState.myForm.Email,
                    elementConfig: { ...updateState.myForm.Email.elementConfig, error: !updateState.myForm.Email.valid }
                }
            });
            setBillingAccountForm({ ...updateState.myForm, Email: { ...updateState.myForm.Email, elementConfig: { ...updateState.myForm.Email.elementConfig, error: !updateState.myForm.Email.valid && updateState.myForm.Email.value.length > 0 } } })
        }
        if (updateState.myForm.Email.value.length === 0) {
            setBillingAccountFormValid(true);
        }
        setBillingAccountFormValid(updateState.formIsValid);

    };

    const checkboxChangedHandler = (event, inputIdentifier) => {
        let updateState = formUtility.getNewStateOnCheckboxChange(event, inputIdentifier, billingAccountForm);
        const formData = formUtility.getFormData(updateState.myForm);
        const address = formUtility.getFormData(customerForm);
        if (formData.CustomerAddress) {
            updateState.myForm.City['value'] = address.City;
            updateState.myForm.PostCode['value'] = address.PostCode;
            updateState.myForm.StreetAddress['value'] = address.StreetAddress;
            updateState.myForm.Country['value'] = address.Country;
            updateState.myForm.City.elementConfig['disabled'] = true;
            updateState.myForm.PostCode.elementConfig['disabled'] = true;
            updateState.myForm.StreetAddress.elementConfig['disabled'] = true;
            updateState.myForm.Country.elementConfig['disabled'] = true;
        } else {
            updateState.myForm.City.elementConfig['disabled'] = false;
            updateState.myForm.PostCode.elementConfig['disabled'] = false;
            updateState.myForm.StreetAddress.elementConfig['disabled'] = false;
            updateState.myForm.Country.elementConfig['disabled'] = false;
        }
        setBillingAccountForm(updateState.myForm);
    };

    const dateChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnDateChange(event, inputIdentifier, billingAccountForm);
        setBillingAccountForm(updateState.myForm);
    };

    useEffect(() => {
        const fetchData = async () => {

            let userData = await onListUsers({ ActiveCustomerID: activeCustomer });
            let data = await onListCustomers({ ActiveCustomerID: activeCustomer });
            setUsers(userData);
            setCustomer(data);
        }

        fetchData();
    }, [activeCustomer, onListUsers, onListCustomers]);


    //Submit all form
    const submitHandler = async event => {
        event.preventDefault();
        const formCustomer = formUtility.getFormData(customerForm);
        const formUser = formUtility.getFormData(userForm);
        const formBilling = formUtility.getFormData(billingAccountForm);

        delete formCustomer['Assign'];
        const Address = {
            StreetAddress: formCustomer.StreetAddress,
            City: formCustomer.City,
            PostCode: formCustomer.PostCode,
            Country: formCustomer.Country
        };
        const AddressAccount = {
            StreetAddress: formBilling.StreetAddress,
            City: formBilling.City,
            PostCode: formBilling.PostCode,
            Country: formBilling.Country
        };
        formBilling['Address'] = AddressAccount

        formCustomer.Status = formCustomer.Status === 'active' ? 1 : 0;
        formUser.UserStatus = formUser.UserStatus === 'active' ? 1 : 0;
        formBilling['Accountant'] = formBilling['Accountant'] ? formBilling['Accountant'].value : null

        formCustomer['Address'] = JSON.stringify(Address);

        await props.onCreateCustomer({ ...formCustomer, ...formUser, Account: { ...formBilling }, 'CreateUser': true, ActiveCustomerID: activeCustomer });
        setRedirectOptions({ redirect: true, redirectPath: `/customers` });



        setCustomerForm(firstForm);
        setCustomerFormValid(false);
        setUserForm(secondForm);
        setUserFormValid(false);
        setBillingAccountForm(updateFormStateOne(customers, users, currentUserID, translations));
        setActiveStep(0);
        setWithout(false)
    };

    //Submit only customer 

    const skipSaveHandler = async event => {
        event.preventDefault();
        const formCustomer = formUtility.getFormData(customerForm);
        const Address = {
            StreetAddress: formCustomer.StreetAddress,
            City: formCustomer.City,
            PostCode: formCustomer.PostCode,
            Country: formCustomer.Country
        };
        formCustomer.Status = formCustomer.Status === 'active' ? 1 : 0;
        formCustomer['Address'] = JSON.stringify(Address);

        await props.onCreateCustomer({ ...formCustomer, 'CreateUser': false });
        setRedirectOptions({ redirect: true, redirectPath: `/customers` });

        setCustomerForm(firstForm);
        setCustomerFormValid(false);
        setUserForm(secondForm);
        setUserFormValid(false);
        setBillingAccountForm(updateFormStateOne(customers, users, currentUserID, translations));
        setActiveStep(0);
        setWithout(false)
    };

    //Submit  customer  and account
    const skipWithoutUserHandler = async event => {
        event.preventDefault();
        let formCustomer = formUtility.getFormData(customerForm);
        let formAccount = formUtility.getFormData(billingAccountForm);

        const Address = {
            StreetAddress: formCustomer.StreetAddress,
            City: formCustomer.City,
            PostCode: formCustomer.PostCode,
            Country: formCustomer.Country
        };

        formCustomer.Status = formCustomer.Status === 'active' ? 1 : 0;
        formCustomer['Address'] = JSON.stringify(Address);
        formAccount['Accountant'] = formAccount['Accountant'] ? formAccount['Accountant'].value : null

        delete formCustomer.StreetAddress;
        delete formCustomer.PostCode;
        delete formCustomer.Country;
        delete formCustomer.City;

        await props.onCreateCustomer({ ...formCustomer, Account: { ...formAccount }, 'CreateUser': false });
        setRedirectOptions({ redirect: true, redirectPath: `/customers` });

        setActiveStep(0);
        setCustomerForm(firstForm);
        setCustomerFormValid(false);
        setUserForm(secondForm);
        setUserFormValid(false);
        setBillingAccountForm(updateFormStateOne(customers, users, currentUserID, translations));
        setWithout(false)

    };

    //Submit  customer  and user
    const skipWithoutHandler = async event => {
        event.preventDefault();
        setActiveStep(0);
        const formUser = formUtility.getFormData(userForm);
        let formCustomer = formUtility.getFormData(customerForm);

        const Address = {
            StreetAddress: formCustomer.StreetAddress,
            City: formCustomer.City,
            PostCode: formCustomer.PostCode,
            Country: formCustomer.Country
        };
        formCustomer.Status = formCustomer.Status === 'active' ? 1 : 0;
        formCustomer['Address'] = JSON.stringify(Address);

        delete formCustomer.StreetAddress;
        delete formCustomer.PostCode;
        delete formCustomer.Country;
        delete formCustomer.City;

        await props.onCreateCustomer({ ...formCustomer, ...formUser, 'CreateUser': true });
        setRedirectOptions({ redirect: true, redirectPath: `/customers` });

        setCustomerForm(firstForm);
        setCustomerFormValid(false);
        setUserForm(secondForm);
        setUserFormValid(false);
        setBillingAccountForm(updateFormStateOne(customers, users, currentUserID, translations));
        setWithout(false)

    };

    //skipped step

    const isStepSkipped = step => {
        return skipped.has(step);
    };

    const isStepOptional = step => {
        return step === 1;
    };

    const skipHandler = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("");
        }
        setWithout(true)
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(prevSkipped => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    // step content
    const getStepContent = stepIndex => {

        switch (stepIndex) {
            case 0:
                return (
                    <Form
                        formIsValid={customerFormValid}
                        classes={classes}
                        form={customerForm}
                        inputChangedHandler={customerFormInputChangedHandler}

                    />);
            case 1:
                return (<Form
                    formIsValid={billingAccountFormValid}
                    classes={classes}
                    autoChangedHandler={autoChangedHandler}
                    form={billingAccountForm}
                    inputChangedHandler={inputChangedHandlerBillingForm}
                    dateChangedHandler={dateChangedHandler}
                    checkboxChangedHandler={checkboxChangedHandler}


                />);
            case 2:
                return (<Form
                    formIsValid={userFormValid}
                    classes={classes}
                    form={userForm}
                    inputChangedHandler={userInfoFormInputChangedHandler}
                />);
            default:
                return 'Unknown stepIndex';
        }
    };

    const handleBack = () => {
        setActiveStep(!without ? activeStep - 1 : 0);
        setWithout(false)
    };

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const cancelHandler = () => {
        setCustomerForm(firstForm);
        setCustomerFormValid(false);
        setUserForm(secondForm);
        setUserFormValid(false);
        setActiveStep(0);
        setRedirectOptions({ redirect: true, redirectPath: `/customers` });

    };
    if (redirectOptions.redirect) {
        return <Routes><Route path="*" element={<Navigate to={redirectOptions.redirectPath} />} /></Routes>;
    }


    return (
        <Paper>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {
                    const stepProps = {};
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                {getStepContent(activeStep)}
                <div style={{ float: 'right' }}>
                    <Button style={{ width: activeStep === 1 ? '100px' : '120px', margin: 10 }}
                        variant='contained' onClick={cancelHandler}>{translations['cancel'] || 'Cancel'}</Button>

                    <Button
                        style={{ width: activeStep === 1 ? '92px' : '120px', margin: 10, display: activeStep === 0 ? 'none' : null }}
                        variant='contained'
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        color={isSuperUser() ? 'secondary' : 'primary'}>
                        {translations['back'] || "Back"}
                    </Button>

                    <Button style={{ width: activeStep === 1 ? '104px' : '120px', margin: 10, display: activeStep !== 1 ? 'none' : null }}
                        color={isSuperUser() ? 'secondary' : 'primary'}
                        variant='contained' onClick={skipHandler}>{translations['skip'] || 'Skip'}</Button>

                    <Button color={isSuperUser() ? 'secondary' : 'primary'}
                        disabled={activeStep === 0 ? !customerFormValid : !billingAccountFormValid}
                        variant="contained" onClick={handleNext}
                        style={{ width: activeStep === 1 ? '90px' : '120px', margin: 10, display: activeStep === 2 ? 'none' : null }}
                    >
                        {activeStep === steps.length - 1 ? translations['save'] || 'Save' : translations['next'] || 'Next'}
                    </Button>
                    <Button
                        style={{ width: activeStep === 1 ? '90px' : '120px', margin: 10 }}
                        variant='contained'
                        disabled={activeStep === 0 ? !customerFormValid : activeStep === 1 ? !billingAccountFormValid : !userFormValid}
                        onClick={activeStep === 0 ? skipSaveHandler : activeStep === 1 ? skipWithoutUserHandler : without ? skipWithoutHandler : submitHandler}
                        color={isSuperUser() ? 'secondary' : 'primary'}>
                        {activeStep === 0 ? translations['finish'] || 'Finish' : translations['finish'] || 'Finish'}
                    </Button>
                </div>
            </div>
        </Paper>
    )
};

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        translations: state.translate && state.translate.translations ? state.translate.translations.activeTranslations || {} : {},
        hasPermission: permission => { return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'); },
        isSuperUser: state.core.login.permissions.includes('*'),
        currentUserID: state.core && state.core.login && state.core.login.user ? state.core.login.user.UserID : '',
        customer: state.customer.customers,

    };
};


const mapDispatchToProps = dispatch => {
    return {
        onCreateCustomer: data => dispatch(actions.createCustomer(data)),
        onListPlans: (data) => dispatch(actions.loadPlans(data)),
        onListUsers: (id) => dispatch(action.listUsers(id)),
        onListCustomers: () => dispatch(action.listCustomers()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(CustomerCreate));



