import React, { useState, useEffect } from 'react';
import Form from '../../../np-form/Form';
import { withStyles, Tooltip, Button, Dialog } from '@material-ui/core';
import * as formUtility from '../../../np-form/utility';
import { isSuperUser } from '../../../np-utilities';
import Info from '@material-ui/icons/Info';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    },
    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden"
    },
    logo: {
        width: '250px',
        margin: '10px'
    },
});

const updateFormState = props => {
    const { customer, classes, renderToolTip } = props;
    const address = customer && customer.Address ? JSON.parse(customer.Address) : null;
    return {
        Name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Name',
                label: 'Name',
                className: classes && classes.inputs ? classes.inputs : '',
                margin: "dense",
                fullWidth: true
            },
            value: customer ? customer.Name : '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        StreetAddress: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Street Address',
                label: 'Street Address',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: address ? address.StreetAddress : '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        PostCode: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Post code',
                label: 'Post code',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: address ? address.PostCode : '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        City: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'City',
                label: 'City',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: address ? address.City : '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        Country: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Country',
                label: 'Country',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: address ? address.Country : '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        Status: {
            elementType: 'select_v2',
            inputLabel: 'Status',
            elementConfig: {
                type: 'select',
                label: 'Status',
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [{ value: 'active', displayValue: 'Active' }, { value: 'inactive', displayValue: 'Inactive' }]
            },
            formHelperText: "Please select customer status",
            value: customer && customer.Status === 1 ? 'active' : 'inactive',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        PasswordComplexity: {
            elementType: 'select_v2',
            inputLabel: 'Password complexity',
            elementConfig: {
                type: 'select',
                label: 'PasswordComplexity',
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [
                    { value: 'msqt_basic', displayValue: 'MSQT basic' },
                    { value: 'msqt_standard', displayValue: 'MSQT standard' },
                    { value: 'microsoft', displayValue: 'Microsoft' },
                    { value: 'nist', displayValue: 'National Institute of Standards and Technology' }
                ]
            },
            value: customer ? customer.PasswordComplexity : 'msqt_basic',
            formHelperText: renderToolTip(),
            validation: {
                required: true
            },
            valid: true,
            touched: true
        }
    };
};

const CustomerEdit = props => {
    const { classes, customer, loading, onLoadCustomer, activeCustomer, customerID } = props;

    useEffect(() => {
        onLoadCustomer({ CustomerID: customerID, ActiveCustomerID: activeCustomer });
    }, [customerID, onLoadCustomer, activeCustomer]);

    const renderToolTip = (key = 'msqt_basic') => {
        let formHelperText = null;
        switch (key) {
            case 'msqt_basic':
                formHelperText = <div><p>Passwords must be at least 3 characters in length</p></div>;
                break;
            case 'msqt_standard':
                formHelperText =
                    <div><p>Passwords must be at least 5 characters in length</p><p>Passwords must have at least one
                        upper case</p><p>Passwords must have at least one lower case</p><p>Passwords must have at least
                        one numeric</p></div>;
                break;
            case 'microsoft':
                formHelperText =
                    <div><p>Passwords must be at least 8 characters in length</p><p>Passwords must have at least one
                        upper case</p><p>Passwords must have at least one lower case</p><p>Passwords must have at least
                        one numeric</p><p>Passwords must have at least one upper case</p><p>Passwords must have at least
                        one lower special character</p><p>Password should not contain user or customer name</p></div>;
                break;
            case 'nist':
                formHelperText =
                    <div><p>Passwords must be at least 8 characters in length</p><p>Passwords must have at least one
                        upper case</p><p>Passwords must have at least one lower case</p><p>Passwords must have at least
                        one numeric</p><p>Passwords must have at least one upper case</p><p>Passwords must have at least
                        one lower special character</p><p>Password should not contain user or customer name</p>
                        <p>Passwords must not contain repetitive characters</p></div>;
                break;
            default:
                formHelperText = <div><p>Passwords must be at least 3 characters in length</p></div>;
                break;
        }
        return <Tooltip title={formHelperText} placement='right-start'>
            <Info />
        </Tooltip>
    };

    const [customerForm, setCustomerForm] = useState(updateFormState({ ...props, renderToolTip }));
    const [customerFormValid, setCustomerFormValid] = useState(false);

    const customerFormInputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, customerForm);
        setCustomerForm({
            ...updateState.myForm,
            PasswordComplexity: {
                ...updateState.myForm.PasswordComplexity,
                formHelperText: renderToolTip(updateState.myForm.PasswordComplexity.value)
            }
        });
        setCustomerFormValid(true)
    };

    useEffect(() => {
        setCustomerForm(updateFormState({ ...props, renderToolTip }));
    }, [props]);

    const submitHandler = event => {
        event.preventDefault();
        const formCustomer = formUtility.getFormData(customerForm);
        formCustomer['CustomerID'] = customer.CustomerID.toString();

        const Address = {
            StreetAddress: formCustomer.StreetAddress,
            City: formCustomer.City,
            PostCode: formCustomer.PostCode,
            Country: formCustomer.Country
        };

        formCustomer.Status = formCustomer.Status === 'active' ? 1 : 0;
        formCustomer['Address'] = JSON.stringify(Address);

        delete formCustomer.StreetAddress;
        delete formCustomer.PostCode;
        delete formCustomer.Country;
        delete formCustomer.City;

        props.onEditCustomer({ ...formCustomer, ActiveCustomerID: activeCustomer });
    };

    return (
        <div>
            <Dialog disableEscapeKeyDown={false} open={loading}
                PaperProps={{
                    classes: {
                        root: classes.paper
                    }
                }}
            >
                <img alt="App logo loading" className={classes.logo}
                    src={process.env.PUBLIC_URL + '/assets/images/app-logo-loading.gif'} />
            </Dialog>
            <Form
                formIsValid={props.hasPermission('customer_save') ? customerFormValid : false}
                classes={null}
                form={customerForm}
                inputChangedHandler={customerFormInputChangedHandler}

            />
            {props.hasPermission('customer_save') ?
                <Button style={{ float: 'right' }} disabled={!customerFormValid} variant='contained'
                    onClick={submitHandler} fullWidth={true}
                    color={isSuperUser() ? "secondary" : "primary"}>Save</Button> : null}
        </div>
    )
};

export default withStyles(styles)(CustomerEdit);

