import React, { useState } from 'react';
import { withStyles, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';

import BinaryImageTypeList from './BinaryImageTypeList';
import * as actions from './store/binaryImageTypeAction';
import BinaryImageTypeCrete from './BinaryImageTypeCreate';
import BinaryImageTypeEdit from './BinaryImageTypeEdit';
import BinaryImageTypeDelete from './BinaryImageTypeDelete';
import * as action from '../../np-core/Auth/store/authActions';


const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
    progressBarr: {
        width: '100%',
        height: 5
    }
});

const BinaryImageType = props => {
    const { classes, binaryImageType, activeCustomer, translations } = props;
    const fetching = binaryImageType.fetching;

    const [addModalState, setAddModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState({ open: false, binaryImageTypeID: null, binaryImageTypeIDs: [], forBulk: false });

    const handleOpen = () => {
        setAddModalState(!addModalState);
    };

    const handleEdit = async event => {
        if (!event) {
            setEditModalState(!editModalState);
            return;
        }

        const binaryImageTypeID = event.currentTarget.value;
        await props.onFetchBinaryImageType({ BinaryImageTypeID: binaryImageTypeID, ActiveCustomerID: activeCustomer });
        setEditModalState(!editModalState);
    };

    const handleDelete = async event => {
        if (!event) {
            setDeleteModalState({ open: false, binaryImageTypeID: null, binaryImageTypeIDs: [], forBulk: false });
            return;
        }
        const binaryImageTypeID = event.currentTarget.value;
        setDeleteModalState({ ...deleteModalState, open: true, binaryImageTypeID: binaryImageTypeID });
    };

    const handleBulkDelete = binaryImageTypeIDs => {
        setDeleteModalState({ ...deleteModalState, open: true, binaryImageTypeIDs: binaryImageTypeIDs, forBulk: true });
    }

    return (
        <div className={classes.root}>
            <div className={classes.progressBarr}>
                {fetching ? <LinearProgress color="secondary" /> : null}
            </div>
            {props.hasPermission('binary_image_type_list') ? <BinaryImageTypeList translations={translations} activeCustomer={activeCustomer} columnsState={props.columns} saveColumnsFilter={props.saveColumnsFilter} hasAtLeastOnePermission={props.hasAtLeastOnePermission} hasPermission={props.hasPermission} handleBulkDelete={handleBulkDelete} binaryImageType={binaryImageType} onFetchBinaryImageTypes={props.onFetchBinaryImageTypes} handleEdit={handleEdit} handleDelete={handleDelete} handleCreate={handleOpen} /> : null}
            {props.hasPermission('binary_image_type_create') ? <BinaryImageTypeCrete translations={translations} activeCustomer={activeCustomer} loading={binaryImageType.creating} handleOpen={handleOpen} open={addModalState} createBinaryImageType={props.onCreateBinaryImageType} /> : null}
            {props.hasPermission('binary_image_type_load') ? <BinaryImageTypeEdit translations={translations} activeCustomer={activeCustomer} handleEdit={handleEdit} hasPermission={props.hasPermission} open={editModalState} loading={binaryImageType.saving} binaryImageType={binaryImageType.loaded} editBinaryImageType={props.onEditBinaryImageType} /> : null}
            {props.hasPermission('binary_image_type_delete') ? <BinaryImageTypeDelete translations={translations} activeCustomer={activeCustomer} entityName={'Binary image type'} handleDelete={handleDelete} deleteModalState={deleteModalState} deleteBinaryImageType={props.onDeleteBinaryImageType} bulkDeleteBinaryImageTypes={props.onBulkDeleteBinaryImageType} /> : null}
        </div >
    );
}

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        binaryImageType: state.exportImport.binaryImageType,
        translations: state.translate && state.translate.translations ? state.translate.translations.activeTranslations || {} : {},
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        hasPermission: permission => state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchBinaryImageTypes: filter => dispatch(actions.fetchBinaryImageType(filter)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),
        onCreateBinaryImageType: binaryImageType => dispatch(actions.createBinaryImageType(binaryImageType)),
        onFetchBinaryImageType: binaryImageType => dispatch(actions.loadBinaryImageType(binaryImageType)),
        onEditBinaryImageType: binaryImageType => dispatch(actions.editBinaryImageType(binaryImageType)),
        onDeleteBinaryImageType: binaryImageType => dispatch(actions.deleteBinaryImageType(binaryImageType)),
        onBulkDeleteBinaryImageType: binaryImageType => dispatch(actions.bulkDeleteBinaryImageTypes(binaryImageType))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(BinaryImageType));