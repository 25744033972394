import restCrudActions from '../../../np-crud/store/restCrudActions';

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: '/customers',
    pluginName: 'CORE',
    entityName: 'Customer',
    fetchSuccessMessage: '',
    // fetchFailedMessage = 'Cannot fetch ' + entityName,
    // createSuccesMessage = entityName + ' created successfully',
    // createFailedMessage = 'Cannot create ' + entityName,
    // deleteSuccessMessage = entityName + ' deleted successfully',
    // deleteFailedMessage = 'Cannot delete ' + entityName,
    loadSuccessMessage: ''
    // loadFailedMessage = 'Cannot load ' + entityName,
    // editSucessMessage = entityName + ' edited successfully',
    // editFailedMessage = 'Cannot edit ' + entityName,
    // bulkDeleteSuccessMessage = entityName + ' deleted successfully',
    // bulkDeleteFailedMessage = 'Cannot bulk delete ' + entityName
});

// Fetch customers action creators
export const fetchCustomersInit = () => {
    return crudActions.fetchInit();
};
export const fetchCustomersStart = () => {
    return crudActions.fetchStart();
};
export const fetchCustomersSuccess = () => {
    return crudActions.fetchSuccess();
};
export const fetchCustomersFail = () => {
    return crudActions.fetchFail();
};
export const fetchCustomers = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
};

// Load customer action creators
export const loadCustomerInit = () => {
    return crudActions.loadInit();
};
export const loadCustomerStart = () => {
    return crudActions.loadStart();
};
export const loadCustomerSuccess = loadedCustomer => {
    return crudActions.loadSuccess(loadedCustomer);
};
export const loadCustomerFail = error => {
    return crudActions.loadFail(error);
};
export const loadCustomer = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

// Create customer action creators
export const createCustomerInit = () => {
    return crudActions.createInit();
};
export const createCustomerStart = () => {
    return crudActions.createStart();
};
export const createCustomerSuccess = createdCustomer => {
    return crudActions.createSuccess(createdCustomer);
};
export const createCustomerFail = error => {
    return crudActions.createFail(error);
};
export const createCustomer = customer => {
    return async dispatch => {
        await crudActions.create(dispatch, customer);
    };
};

// Edit customer action creators
export const editCustomerInit = () => {
    return crudActions.editInit();
};
export const editCustomerStart = () => {
    return crudActions.editStart();
};
export const editCustomerSuccess = editedCustomer => {
    return crudActions.editSuccess(editedCustomer);
};
export const editCustomerFail = error => {
    return crudActions.editFail(error);
};
export const editCustomer = customer => {
    return async dispatch => {
        await crudActions.edit(dispatch, customer);
    };
};

// Bulk delete customers action creators
export const bulkDeleteCustomersInit = () => {
    return crudActions.bulkDeleteInit();
};
export const bulkDeleteCustomersStart = () => {
    return crudActions.bulkDeleteStart();
};
export const bulkDeleteCustomersSuccess = bulkDeleted => {
    return crudActions.bulkDeleteSuccess(bulkDeleted);
};
export const bulkDeleteCustomersFail = error => {
    return crudActions.bulkDeleteFail(error);
};
export const bulkDeleteCustomers = customerIDs => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, customerIDs);
    };
};

// Delete customer action creators
export const deleteCustomerInit = () => {
    return crudActions.deleteInit();
};
export const deleteCustomerStart = () => {
    return crudActions.deleteStart();
};
export const deleteCustomerSuccess = deletedCustomer => {
    return crudActions.deleteSuccess(deletedCustomer);
};
export const deleteCustomerFail = error => {
    return crudActions.deleteFail(error);
};
export const deleteCustomer = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};
