import React, { useState, useEffect } from 'react';

import Form from '../../np-form/Form';
import { Dialog, withStyles, Stepper, StepLabel, Step, Button } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    },
    button: {
        color: 'white'
    }
});

const updateFormState = props => {
    const { classes } = props;
    return {
        Name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Name',
                label: 'Name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                autoFocus: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        Key: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Key',
                label: 'Key',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        DefaultLanguage: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Default language',
                label: 'Default language',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        Pause: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Pause',
                label: 'Pause',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        Sounds: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Sounds',
                label: 'Sounds',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        ContactPerson: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Contact person',
                label: 'Contact person',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        UserDeleteTimeout: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Users who failed assessment anonymize timeout (hours)',
                label: 'Users who failed assessment anonymize timeout (hours)',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        ActiveUserDeleteTimeout: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Users who passed assessment anonymize timeout (hours)',
                label: 'Users who passed assessment anonymize timeout (hours)',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: 2160,
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
    };
};

const SiteCreate = props => {
    const { open, classes, activeCustomer, tableRef } = props;
    const { handleOpen, createSite } = props;
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Create Site', 'Determine Score Threshold'];
    const [formIsValid, setFormIsValid] = useState(false);
    const [siteForm, setSiteForm] = useState(updateFormState({ ...props }));

    useEffect(() => {
        setSiteForm(updateFormState({ ...props }));
    }, [props]);


    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, siteForm);
        setSiteForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const secondForm = {
        VitalityScoreThreshold: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Score threshold for Vitality)',
                label: 'Score threshold for Vitality',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },

        AccuracyScoreThreshold: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Score threshold for Accuracy',
                label: 'Score threshold for Accuracy',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },

        AttentionScoreThreshold: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Score threshold for Attention',
                label: 'Score threshold for Attention',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },

        SpeedScoreThreshold: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Score threshold for Speed',
                label: 'Score threshold for Speed',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        MemoryScoreThreshold: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Score threshold for Memory',
                label: 'Score threshold for Memory',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },

        LogicScoreThreshold: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Score threshold for Logic',
                label: 'Score threshold for Logic',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },

    };

    const [scoreThresholdForm, setScoreThresholdForm] = useState(secondForm);
    const [scoreThresholdFormValid, setScoreThresholdFormValid] = useState(false);

    const scoreThresholdFormInputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, scoreThresholdForm);
        setScoreThresholdForm(updateState.myForm);
        setScoreThresholdFormValid(updateState.formIsValid);
    };

    const submitHandler = event => {
        event.preventDefault();
        const formData = formUtility.getFormData(siteForm);
        const scoreThresholdFormData = formUtility.getFormData(scoreThresholdForm);
        const scoreThreshold = JSON.stringify({
            Vitality: scoreThresholdFormData.VitalityScoreThreshold,
            Accuracy: scoreThresholdFormData.AccuracyScoreThreshold,
            Attention: scoreThresholdFormData.AttentionScoreThreshold,
            Speed: scoreThresholdFormData.SpeedScoreThreshold,
            Memory: scoreThresholdFormData.MemoryScoreThreshold,
            Logic: scoreThresholdFormData.LogicScoreThreshold
        });
        tableRef.current && tableRef.current.onQueryChange();
        createSite({ ...formData, ScoreThreshold: scoreThreshold, ActiveCustomerID: activeCustomer });

        setActiveStep(0);
        setSiteForm({});
        setFormIsValid(false);
        setScoreThresholdForm(secondForm);
        handleOpen();

    };

    const getStepContent = stepIndex => {
        switch (stepIndex) {
            case 0:
                return (
                    <Form
                        formIsValid={formIsValid}
                        classes={classes}
                        form={siteForm}
                        inputChangedHandler={inputChangedHandler}
                    />
                );
            case 1:
                return (<Form
                    formIsValid={scoreThresholdFormValid}
                    classes={classes}
                    form={scoreThresholdForm}
                    inputChangedHandler={scoreThresholdFormInputChangedHandler}
                />);
            default:
                return 'Unknown stepIndex';
        }
    };

    const cancelHandler = () => {
        setActiveStep(0);
        setSiteForm({});
        setFormIsValid(false);
        setScoreThresholdForm(secondForm);
        handleOpen();
    };
    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };
    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            // onClose={cancelHandler}
            fullWidth={true}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    cancelHandler();
                }
            }}
        >
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {

                    return (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div>                {getStepContent(activeStep)}
                <div style={{ float: 'right' }}>
                    <Button style={{ width: '120px', margin: 10 }}
                        variant='contained' onClick={cancelHandler}>Cancel</Button>

                    <Button
                        style={{ width: '120px', margin: 10, display: activeStep === 0 ? 'none' : null }}
                        variant='contained'
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        color={isSuperUser() ? 'secondary' : 'primary'}>
                        Back
                    </Button>
                    <Button color={isSuperUser() ? 'secondary' : 'primary'}
                        disabled={activeStep === steps.length - 1 ? !scoreThresholdFormValid : !formIsValid}
                        variant="contained" onClick={activeStep === steps.length - 1 ? submitHandler : handleNext}
                        style={{ width: '120px', margin: 10, display: activeStep === 2 ? 'none' : null }}
                    >
                        {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                    </Button>

                </div>
            </div>
        </Dialog>
    )
};

export default withStyles(styles)(SiteCreate);