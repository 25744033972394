import restCrudActions from '../../../np-crud/store/restCrudActions'
import { getAxiosInstance } from '../../../np-rest/rest';
import * as actionTypes from './actionTypes';
import { enqueueSnackbar } from '../../../np-dashboard/Notifier/store/notifierActions';

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: 'customers',
    pluginName: 'CUSTOMER',
    entityName: 'CUSTOMER',
    fetchSuccessMessage: '',
    loadSuccessMessage: '',
    createFailedUseBackendMessage: true,
    createSuccessUseBackendMessage: true
});

// Fetch Customer action creators
export const fetchCustomers = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
};

// Create Customer  action creators
export const createCustomer = Customer => {
    return async dispatch => {
        await crudActions.create(dispatch, Customer);
    };
};

// Edit Customer  action creators
export const editCustomer = setting => {
    return async dispatch => {
        await crudActions.edit(dispatch, setting);
    };
};

// Load Customer action creators
export const loadCustomer = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

export const loadPlans = (customerID) => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/billing/plan/list', { forList: true, ...customerID });
            if (res.data && res.data.succeeded) {
                const plans = [];
                for (var plan of res.data.result.data) {
                    plans.push({ value: plan.PlanID, label: plan.Name });
                }
                return plans;
            }
            else {
                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'warning'
                    }
                }))

                return [];
            }
        } catch (error) {
            dispatch(
                enqueueSnackbar({
                    message: `${error.message}`,
                    options: {
                        variant: 'error'
                    }
                }));
            return [];
        }
    };
}
export const recoverPBillingPlansStart = () => {
    return {
        type: actionTypes.ACCOUNT_RECOVER_START,
        loading: true
    };
};
export const recoverPBillingPlansSuccess = () => {
    return {
        type: actionTypes.ACCOUNT_RECOVER_SUCCESS,
        loading: false,
    };
};
export const recoverPBillingPlansFail = () => {
    return {
        type: actionTypes.ACCOUNT_RECOVER_FAIL,
        loading: false
    };
};

export const recoverBillingPlan = billingAccount => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(recoverPBillingPlansStart())
            let res = await rest.post('/billing/account/recover', billingAccount);
            if (res.data.status === 200) {
                dispatch(recoverPBillingPlansSuccess())
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data.message}`,
                        options: {
                            variant: 'success'
                        }
                    }))

                return res.data.status === 200;
            }
            else {
                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'warning'
                    }
                }))
                dispatch(recoverPBillingPlansFail());

                return false;
            }

        } catch (error) {
            dispatch(recoverPBillingPlansFail());
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return false;
        }
    };
};

export const suspendPBillingPlansStart = () => {
    return {
        type: actionTypes.ACCOUNT_SUSPEND_START,
        loading: true
    };
};
export const suspendPBillingPlansSuccess = () => {
    return {
        type: actionTypes.ACCOUNT_SUSPEND_SUCCESS,
        loading: false,
    };
};
export const suspendPBillingPlansFail = () => {
    return {
        type: actionTypes.ACCOUNT_SUSPEND_FAIL,
        loading: false
    };
};

export const suspendBillingPlan = billingAccount => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(suspendPBillingPlansStart())
            let res = await rest.post('/billing/account/suspend', billingAccount);
            if (res.data.status === 200) {
                dispatch(suspendPBillingPlansSuccess())
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data.message}`,
                        options: {
                            variant: 'success'
                        }
                    }))
                return res.data.status === 200;
            }
            else {
                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'warning'
                    }
                }))
                dispatch(suspendPBillingPlansFail());

                return false;
            }
        } catch (error) {
            dispatch(suspendPBillingPlansFail());
            dispatch(
                enqueueSnackbar({
                    message: `${error.message}`,
                    options: {
                        variant: 'success'
                    }
                }));
            return false;
        }
    };
};