import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import io from 'socket.io-client';


import {SnackbarProvider} from 'notistack';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import App from './App';

import {unregister} from './serviceWorker';

// Redux store
import store from './store';
// Configuration
import config from "./config/config_default";
// For creating Rest calls with Axios
import {createInstance as createRestInstance} from './modules/np-rest/rest'

// Wrap everything into an async function to be able to use await
const index = async () => {
   
    unregister();

    // Create Axios instance for REST calls
    // Note: it is possible to create multiple Rest instances by providing the name property.
    // In our case the name property is not provided, so the default '_DEFAULT_' will be used
    // In this way the components can use the axios instance with const rest = getAxiosInstance();
    // For named instances the components should use getAxiosInstance(name); This concept makes possible to use multiple Rest endpoints on different backend server
    await createRestInstance({store: store, baseURL: config.backend_url})
    const socket = io(`${config.backend_domain}:${config.backend_port}`, {transports: ['websocket']});
    socket.on('connect', function () {
        let token = localStorage.getItem('CORE|AUTH|TOKEN');
        if (token) {
            socket.emit('userConnected', {token: JSON.parse(token).token})
        }
        socket.on('tokenValidation', message => {
            if (!message.valid) {
                localStorage.clear();
                window.location = '/login';
            }
        });
    });
    // Set default locale - the mui picker components use this default locale, see the MuiPickersUtilProvider bellow
    const locale = 'de';

    // Render React DOM
    ReactDOM.render(
            <Provider store={store}>
                <BrowserRouter >
                {/* <Router navigator={History}> */}
                    <SnackbarProvider>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={locale} moment={moment}>
                            <App/>
                        </MuiPickersUtilsProvider>
                    </SnackbarProvider>
                {/* </Router> */}
                    </BrowserRouter>
            </Provider>
        ,
        document.getElementById('root')
    );

    // Register service worker
};

// Start the async index
index();