import React, { useState, useEffect, useReducer } from 'react';

import Form from '../../np-form/Form';
import { withStyles, Dialog } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});

const updateFormState = props => {
    const { classes, handleUpload, fileName, translations } = props;
    const form = {
        ToolName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations["name"] || 'Name',
                label: translations["name"] || 'Name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        Status: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: translations["status"] || 'Status',
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [
                    { value: 'test', displayValue: translations['test'] || 'Test' },
                    { value: 'release', displayValue: translations['release'] || 'Release' },
                    { value: 'demo', displayValue: translations['demo'] || 'Demo' }]
            },
            formHelperText: translations['image_status'] || "Please select image status",
            value: 'test',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        ToolFile: {
            elementType: 'file',
            elementConfig: {
                ID: "msqtToolID",
                accept: '.exe,.zip',
                style: {},
                type: 'file',
                handleUpload: handleUpload,
                fileName: fileName,
                text: translations['upload'] || 'upload'

            },
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        ForAllCustomers: {
            elementType: 'checkbox',
            elementConfig: {
                type: 'checkbox',
                label: null,
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [{ ID: 'checkboxID', Checked: false, Name: translations['for_all_customers'] || 'For all customers' }]
            },
            value: false,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        }
    };
    return form;
}
const MSQTToolCreate = props => {
    const { classes, open, activeCustomer, translations } = props;

    const [fileToSend, setFileToSend] = useState({});
    const [fileName, setFilename] = useState('');
    const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    const handleUpload = event => {
        event.preventDefault();
        let file = event.target.files[0];
        if (!file) {
            file = {};
        }
        setFileToSend(file);
        setFilename(file.name);
    }

    const [importForm, dispatch] = useReducer(msqtToolReducer, updateFormState({
        ...props,
        fileName: fileName,
        handleUpload: handleUpload
    }));
    const [formIsValid, setFormIsValid] = useState(false);

    useEffect(() => {
        dispatch({ type: "form", form: updateFormState({ ...props, handleUpload: handleUpload }) });
    }, [props]);

    useEffect(() => {
        if (fileName) {
            dispatch({ type: "filename", fileName: fileName });
        }
    }, [fileName]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, importForm);
        dispatch({ type: "form", form: updateState.myForm });
        setFormIsValid(updateState.formIsValid);
    }

    const checkboxChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnCheckboxChange(event, inputIdentifier, importForm);
        dispatch({ type: "form", form: updateState.myForm });
        setFormIsValid(updateState.formIsValid);
    }

    const submitHandler = async event => {
        event.preventDefault();
        const formData = formUtility.getFormData(importForm);
        if (!fileName || (!fileName.includes('.zip') && !fileName.includes('.exe'))) {
            return props.sendMessage(translations['choose_file'] || 'Please choose iso file.', 'warning')
        }

        setFileToSend({});
        setFilename('');
        dispatch({ type: "form", form: {} });
        setFormIsValid(false);

        const data = new FormData();
        data.append('File', fileToSend);
        data.append('FileName', fileName);
        data.append('Status', formData.Status);
        data.append('ToolName', formData.ToolName);
        data.append('ForAllCustomers', formData.ForAllCustomers === true ? 1 : 0);
        data.append('ActiveCustomerID', activeCustomer);

        let token = localStorage.getItem('CORE|AUTH|TOKEN');
        if (token) {
            token = JSON.parse(token).token;
        }

        data.append('Token', token);
        setCancelButtonDisabled(true);
        setSubmitButtonDisabled(true);
        await props.createNewMSQTTool(data);
        setCancelButtonDisabled(false);
        setSubmitButtonDisabled(false);
        await props.handleOpen();
    }

    const cancelHandler = () => {
        props.handleOpen();
    }

    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={cancelButtonDisabled}
            disableEnforceFocus={true}
            fullWidth={true}
            // onClose={cancelHandler}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    cancelHandler();
                }
            }}
        >
            <Form
                formIsValid={formIsValid}
                classes={classes}
                form={importForm}
                formTitle={translations['create_new_msqt_tool'] || "Create new msqt tool"}
                buttonText={translations['create'] || "Create"}
                buttonTextCancel={translations['cancel'] || "Cancel"}
                cancelHandler={cancelHandler}
                submitHandler={submitHandler}
                inputChangedHandler={inputChangedHandler}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                submitButtonDisabled={submitButtonDisabled}
                cancelButtonDisabled={cancelButtonDisabled}
                checkboxChangedHandler={checkboxChangedHandler}
            />
        </Dialog>
    )
}

const msqtToolReducer = (state, action) => {
    switch (action.type) {
        case 'filename':
            return {
                ...state,
                ToolFile: {
                    ...state.ToolFile,
                    elementConfig: { ...state.ToolFile.elementConfig, fileName: action.fileName }
                }
            };
        case 'form':
            return action.form;
        default:
            throw new Error({ message: 'Something went wrong. Please contact administrator.' });
    }
}

export default withStyles(styles)(MSQTToolCreate);