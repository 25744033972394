import React from 'react';
import { withStyles, Grid, Switch } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';

const styles = () => ({
    cancel: {
        width: '200px',
        marginRight: '4%',
    },
    container: {
        width: 'auto',
    },
});

const DateRange = (props) => {
    const { classes, value, setValue, minDate, initialValues, translations } = props;

    const toggleDateFromHandler = () => {
        if (value?.From) {
            setValue({ ...value, From: null });
        } else {
            setValue({ ...value, From: initialValues.From });
        }
    };

    const toggleDateToHandler = () => {
        if (value?.To) {
            setValue({ ...value, To: null });
        } else {
            setValue({ ...value, To: initialValues.To });
        }
    };

    const filter = (name, date) => {
        if (!date || (date && !date.isValid())) {
            return;
        }

        setValue({ ...value, [name]: date });
    };

    return (
        <Grid container direction="row" className={classes.container}>
            <Grid item>
                <Switch
                    checked={!!value.From}
                    onChange={toggleDateFromHandler}
                    inputProps={{
                        'aria-label': value.From
                            ? translations?.['disable'] || 'Disable'
                            : translations?.['enable'] || 'Enable',
                    }}
                />
                <DatePicker
                    name={'From'}
                    onBlur={null}
                    error={false}
                    disabled={!value.From}
                    maxDate={value.To || moment()}
                    minDate={minDate}
                    onChange={(date) => filter('From', date)}
                    value={value.From || initialValues.From || moment().subtract(1, 'years')}
                    margin={'dense'}
                    emptyLabel={translations?.['please_select_date_from'] || 'Please select date from'}
                    label={translations?.['please_select_date_from'] || 'Please select date from'}
                    className={classes.cancel}
                    fullWidth={true}
                    format={'DD.MM.YYYY'}
                />
            </Grid>
            <Grid item>
                <Switch
                    checked={!!value.To}
                    onChange={toggleDateToHandler}
                    inputProps={{
                        'aria-label': value.To
                            ? translations?.['disable'] || 'Disable'
                            : translations?.['enable'] || 'Enable',
                    }}
                />
                <DatePicker
                    name={'To'}
                    onBlur={null}
                    error={false}
                    disabled={!value.To}
                    maxDate={moment()}
                    minDate={value.From ?? minDate}
                    onChange={(date) => filter('To', date)}
                    value={value.To || initialValues.To || moment()}
                    margin={'dense'}
                    emptyLabel={translations?.['please_select_date_to'] || 'Please select date to'}
                    label={translations?.['please_select_date_to'] || 'Please select date to'}
                    className={classes.cancel}
                    fullWidth={true}
                    format={'DD.MM.YYYY'}
                />
            </Grid>
        </Grid>
    );
};

export default withStyles(styles, { withTheme: true })(DateRange);
