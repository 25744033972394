import * as actionTypes from './actionTypes';

import ssfRest from '../../../np-rest/ssfRest';

const loadPluginsStart = () => {
    return {
        type: actionTypes.LOAD_PLUGINS_START
    };
};

const loadPluginsSuccess = pluginsList => {
    const plugins = [];

    pluginsList.forEach(async pluginData => {
        plugins.push({
            name: pluginData.PluginName,
            level: pluginData.Level
        });
    });
    return {
        type: actionTypes.LOAD_PLUGINS_SUCCESS,
        plugins: plugins
    };
};

const loadPluginsFail = error => {
    return {
        type: actionTypes.LOAD_PLUGINS_FAIL,
        error: error
    };
};

export const loadPlugins = data => {
    const [load] = ssfRest({
        endpoint: '/loggers/list',
        start: loadPluginsStart,
        success: loadPluginsSuccess,
        failed: loadPluginsFail,
        successMessage: '',
        failedMessage: 'Cannot load plugins'
    });

    return async dispatch => {
        await load(dispatch, data);
    };
};

const savePluginLevelStart = () => {
    return {
        type: actionTypes.SAVE_PLUGIN_LEVEL_START
    };
};

const savePluginLevelSuccess = () => {
    return {
        type: actionTypes.SAVE_PLUGIN_LEVEL_SUCCESS
    };
};

const savePluginLevelFail = error => {
    return {
        type: actionTypes.SAVE_PLUGIN_LEVEL_FAIL,
        error: error
    };
};

export const savePluginLevel = data => {
    const [save] = ssfRest({
        endpoint: '/loggers/save',
        start: savePluginLevelStart,
        success: savePluginLevelSuccess,
        failed: savePluginLevelFail,
        successMessage: 'Plugin level saved successfully',
        failedMessage: 'Cannot save plugin level'
    });

    return async dispatch => {
        await save(dispatch, data);
    };
};
