import restCrudReducer from '../../../np-crud/store/restCrudReducer';
import * as actionTypes from './actionTypes';

const [crudInitialState, crudReducer] = restCrudReducer({
    pluginName: 'CUSTOMER',
    entityName: 'CUSTOMER',
});

const initialState = {
    ...crudInitialState,
    deletedCustomer: {},
    deleting: false,
    saving: false,
    account: {
        loading: false,
    }
};
const reducer = (state = initialState, action) => {
    // Handle CRUD actions
    const newState = crudReducer(state, action);
    // If CRUD reducer handled the action
    if (newState !== null) {
        return newState;
    }

    // Handle other actions
    switch (action.type) {
        case 'HANDLE_BASEBOARD':
            return action.baseboard;
        case 'CORE|LOGOUT_SUCCESS':
        case 'CORE|CORE_SET_ACTIVE_CUSTOMER_SUCCESS':
            return initialState;
        case actionTypes.ACCOUNT_RECOVER_START:
            return { ...state, account: { loading: action.loading } };
        case actionTypes.ACCOUNT_RECOVER_SUCCESS:
            return { ...state, account: { loading: action.loading } };
        case actionTypes.ACCOUNT_RECOVER_FAIL:
            return { ...state, account: { loading: action.loading } };
        case actionTypes.ACCOUNT_SUSPEND_START:
            return { ...state, account: { loading: action.loading } };
        case actionTypes.ACCOUNT_SUSPEND_SUCCESS:
            return { ...state, account: { loading: action.loading } };
        case actionTypes.ACCOUNT_SUSPEND_FAIL:
            return { ...state, account: { loading: action.loading } };

        default:
            return state;
    }
};

export default reducer;
