import React from 'react';
import {Routes, Route} from 'react-router-dom';

// Components
import MyProfile from './ProfileView';

// Routes
export const routes = (basePath) =>
    <Routes>
        {<Route path={`${basePath}`} element={<MyProfile/>}/>}
    </Routes>;
