import React, {useState} from 'react';
import {withStyles, LinearProgress} from '@material-ui/core';
import {connect} from 'react-redux';

import TrainingList from './TrainingList';
import * as actions from './store/trainingActions';
import TrainingCreate from './TrainingCreate';
import TrainingEdit from './TrainingEdit';
import TrainingDelete from './TrainingDelete';
import {enqueueSnackbar} from '../../np-dashboard/Notifier/store/notifierActions';
import * as action from '../../np-core/Auth/store/authActions';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
    progressBarr: {
        width: '100%',
        height: 5
    }
});

const Training = props => {
    const {classes, trainings, activeCustomer} = props;
    const fetching = trainings.fetching;

    const [addModalState, setAddModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState({
        open: false,
        trainingID: null,
        trainingIDs: [],
        forBulk: false
    });

    const handleOpen = () => {
        setAddModalState(!addModalState);
    };

    const handleEdit = async event => {
        if (!event) {
            setEditModalState(!editModalState);
            return;
        }

        const trainingID = event.currentTarget.value;
        await props.onFetchTraining({TrainingID: trainingID, ActiveCustomerID: activeCustomer});
        setEditModalState(!editModalState);
    };

    const handleDelete = async event => {
        if (!event) {
            setDeleteModalState({open: false, trainingID: null, trainingIDs: [], forBulk: false});
            return;
        }
        const trainingID = event.currentTarget.value;
        setDeleteModalState({...deleteModalState, open: true, trainingID: trainingID});
    };

    const handleBulkDelete = trainingIDS => {
        setDeleteModalState({...deleteModalState, open: true, trainingIDs: trainingIDS, forBulk: true});
    };

    /**
     * MODIFY MODULES
     */
    const [modifyModulesState, setModifyModulesState] = useState({open: false, modules: []});
    const handleModifyModules = async event => {
        if (!event) {
            setModifyModulesState({open: false, modules: []});
            return;
        }
        const trainingID = event.currentTarget.value;
        const modules = await props.listTrainingModules({TrainingID: trainingID, ActiveCustomerID: activeCustomer});
        setModifyModulesState({open: true, modules: modules});
    };
    /**
     * END OF MODIFY MODULES
     */

    return (
        <div className={classes.root}>
            <div className={classes.progressBarr}>
                {fetching ? <LinearProgress color="primary"/> : null}
            </div>
            {props.hasPermission('training_list') ?
                <TrainingList activeCustomer={activeCustomer} columnsState={props.columns}
                              saveColumnsFilter={props.saveColumnsFilter}
                              activeExternalServiceAvailable={props.activeExternalServiceAvailable}
                              hasAtLeastOnePermission={props.hasAtLeastOnePermission}
                              hasPermission={props.hasPermission} handleBulkDelete={handleBulkDelete}
                              trainings={trainings} onFetchTrainings={props.onFetchTrainings} handleEdit={handleEdit}
                              handleDelete={handleDelete} handleCreate={handleOpen}
                              modifyModules={handleModifyModules} modifyModulesState={modifyModulesState}/> : null}

            {props.hasPermission('training_create') ?
                <TrainingCreate activeCustomer={activeCustomer} loading={trainings.creating} handleOpen={handleOpen}
                                open={addModalState} createTraining={props.onCreateTraining}/> : null}
            {props.hasPermission('training_load') ?
                <TrainingEdit activeCustomer={activeCustomer} handleEdit={handleEdit} open={editModalState}
                              hasPermission={props.hasPermission} loading={trainings.saving} training={trainings.loaded}
                              editTraining={props.onEditTraining}/> : null}
            {props.hasPermission('training_delete') ?
                <TrainingDelete activeCustomer={activeCustomer} entityName={'Training'} handleDelete={handleDelete}
                                deleteModalState={deleteModalState} deleteTraining={props.onDeleteTraining}
                                bulkDeleteTrainings={props.onBulkDeleteTrainings}/> : null}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        trainings: state.training.trainings,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        hasPermission: permission => {
            return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*');
        },
        hasAtLeastOnePermission: permissions => {
            return state.core.login.permissions.some(permission => permissions.includes(permission)) || state.core.login.permissions.includes('*');
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchTrainings: filter => dispatch(actions.fetchTrainings(filter)),
        onCreateTraining: training => dispatch(actions.createTraining(training)),
        onFetchTraining: training => dispatch(actions.loadTraining(training)),
        onEditTraining: training => dispatch(actions.editTraining(training)),
        onDeleteTraining: training => dispatch(actions.deleteTraining(training)),
        onBulkDeleteTrainings: training => dispatch(actions.bulkDeleteTraining(training)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),
        listTrainingModules: data => dispatch(actions.listTrainingModules(data)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Training));