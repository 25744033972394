import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const ConfirmationDialog = props => (
    <Dialog open={props.isOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">{props.contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onCancel} variant="contained" fullWidth={true} autoFocus>
                {props.textCancel ? props.textCancel : 'Cancel'}
            </Button>
            <Button onClick={props.onYes} color="primary" fullWidth={true} variant="contained">
                {props.textYes ? props.textYes : 'Yes'}
            </Button>
        </DialogActions>
    </Dialog>
);

export default ConfirmationDialog;
