import React, { useState, useEffect } from 'react';
import Form from '../../np-form/Form';
import { Dialog, withStyles } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        minHeight: '500px',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});

const usedFor = [
    { value: 'cloud', label: 'Cloud access' },
    { value: 'printer_agent', label: 'Printer agent' },
    { value: 'on_premise_server', label: 'On premise server' },
    { value: 'workplace', label: 'Workplace client' }
];

const updateFormState = props => {
    const { classes, permissions } = props;

    return {
        Name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Name',
                label: 'Name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                autoFocus: true

            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false,
        },
        Hardware: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Hardware',
                label: 'Hardware',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        UsedFor: {
            elementType: 'multi-select',
            elementConfig: {
                type: 'multi-select',
                isMulti: true,
                closeMenuOnSelect: true,
                components: animatedComponents,
                placeholder: 'Please choose usage',
                label: 'Please choose usage',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                options: usedFor ? usedFor : [],
                selected: [],
                maxElements: 10
            },
            formHelperText: 'Please select what will the license be used for',
            value: [],
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Permissions: {
            elementType: 'multi-select',
            elementConfig: {
                type: 'multi-select',
                isMulti: true,
                closeMenuOnSelect: true,
                components: animatedComponents,
                placeholder: 'Please select permissions for the license',
                label: 'Please select permissions for the license',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                options: permissions ? permissions : [],
                selected: [],
                maxElements: 10
            },
            formHelperText: 'Please select permissions for the license',
            value: [],
            validation: {
                required: true
            },
            valid: true,
            touched: true
        }
    };
};

const LicensePrototypeCreate = props => {
    const { handleOpen, open, classes, loading, onCreateLicensePrototype, permissions, activeCustomer } = props;

    const [licensePrototypeForm, setLicensePrototypeForm] = useState(updateFormState({ classes, permissions }));
    const [formIsValid, setFormIsValid] = useState(false);

    useEffect(() => {
        setLicensePrototypeForm(updateFormState({ classes, permissions }));
    }, [classes, permissions]);


    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, licensePrototypeForm);
        setLicensePrototypeForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const dateChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnDateChange(event, inputIdentifier, licensePrototypeForm);
        setLicensePrototypeForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const getMultiSelectData = (data, _selectedObject, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnMultiSelectChange(data, inputIdentifier, licensePrototypeForm);
        setLicensePrototypeForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const submitHandler = async event => {
        event.preventDefault();

        const formData = formUtility.getFormData(licensePrototypeForm);

        const configuration = JSON.stringify({
            UsedFor: formData.UsedFor,
            Permissions: formData.Permissions,
            Hardware: formData.Hardware
        });

        delete formData.UsedFor;
        delete formData.Permissions;
        delete formData.Hardware;

        await onCreateLicensePrototype({ ...formData, Configuration: configuration, ActiveCustomerID: activeCustomer });

        setLicensePrototypeForm(updateFormState({}));
        setFormIsValid(false);
        handleOpen();
    };

    const cancelHandler = () => {
        setLicensePrototypeForm(updateFormState({}));
        setFormIsValid(false);
        handleOpen();
    };

    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            fullWidth={true}
            // onClose={cancelHandler}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    cancelHandler();
                }
            }}
        >
            <Form
                formIsValid={formIsValid}
                classes={classes}
                form={licensePrototypeForm}
                formTitle={`Create license prototype`}
                buttonText={props.hasPermission('license_prototype_create') ? "Create" : null}
                buttonTextCancel="Cancel"
                submitHandler={submitHandler}
                cancelHandler={cancelHandler}
                inputChangedHandler={inputChangedHandler}
                dateChangedHandler={dateChangedHandler}
                getMultiSelectData={getMultiSelectData}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                cancelButtonDisabled={loading}
            />
        </Dialog>
    )
};
export default withStyles(styles)(LicensePrototypeCreate);