import React, { useState, useEffect } from 'react';

import Form from '../../np-form/Form';
import { Paper, withStyles } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';

const styles = theme => ({
    root: {
        width: '30%',
        height: 'auto',
        padding: '2%',
        marginLeft: '29%',
        marginTop: '12%'
    },
    button: {
        color: 'white'
    }
});

const updateFormState = props => {
    const { classes, translations } = props;
    const form = {
        NewPassword: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: translations['new_password'] || 'New password',
                label: translations['new_password'] || 'New password',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        PasswordRepeat: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: translations['repeat_new_password'] || 'Repeat new password',
                label: translations['repeat_new_password'] || 'Repeat new password',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                error: false,
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },

    };
    return form;
}
const ExpiredUserPassword = props => {
    const { classes, userID, translations } = props;
    const [passwordExpiredForm, setPasswordExpiredForm] = useState(updateFormState({ ...props }));

    useEffect(() => {
        setPasswordExpiredForm(updateFormState({ ...props }));
    }, [props]);

    const [formIsValid, setFormIsValid] = useState(false);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, passwordExpiredForm);
        setPasswordExpiredForm(updateState.myForm);
        if (updateState.myForm.PasswordRepeat.value !== updateState.myForm.NewPassword.value && (updateState.myForm.PasswordRepeat.value !== '' || updateState.myForm.NewPassword.value !== '')) {
            setFormIsValid(false);
        }
        if (inputIdentifier === 'NewPassword' && updateState.myForm.PasswordRepeat.value !== updateState.myForm.NewPassword.value) {
            setFormIsValid(false);
        }
        if (updateState.myForm.PasswordRepeat.value === updateState.myForm.NewPassword.value) {
            setFormIsValid(true);

        }
        if (inputIdentifier === 'PasswordRepeat') {
            if (updateState.myForm.PasswordRepeat.value !== updateState.myForm.NewPassword.value) {
                setFormIsValid(false);
            }
            setPasswordExpiredForm({
                ...updateState.myForm,
                PasswordRepeat: {
                    ...updateState.myForm.PasswordRepeat,
                    elementConfig: {
                        ...updateState.myForm.PasswordRepeat.elementConfig,
                        error: updateState.myForm.PasswordRepeat.value !== updateState.myForm.NewPassword.value
                    }
                }
            })
        }
    }

    const submitHandler = async event => {
        event.preventDefault();
        const formData = formUtility.getFormData(passwordExpiredForm);
        delete formData['PasswordRepeat']
        await props.savePassword({ ...formData, ActiveCustomerID: props.activeCustomer, UserID: userID })
    }

    return (
        <Paper className={classes.root} >
            <Form
                formIsValid={formIsValid}
                form={passwordExpiredForm}
                formTitle={translations['enter_new_password'] || "Please, enter new password"}
                buttonText={translations['save'] || 'Save'}
                submitHandler={submitHandler}
                inputChangedHandler={inputChangedHandler}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                submitButtonStyle={formIsValid ? { color: 'white' } : null}
            />
        </Paper>
    )
}


export default withStyles(styles)(ExpiredUserPassword);


