import axios from 'axios';

const axiosInstances = [];
const defaultInstanceName = '_DEFAULT_';

const createInstance = async (props) => {
    // Get properties
    const { baseURL, name = defaultInstanceName, insertToken = true, checkUnauthorized = true, store = null } = props;

    // Create axios instance
    const axiosInstance = await axios.create({ baseURL });

    if (insertToken) {
        // Use a request interceptor for adding the Token to each request
        await axiosInstance.interceptors.request.use(
            request => {
                const currentStore = store.getState();
                const token = currentStore.core.login.token;

                if (token !== null) {
                    if (request.data == null) {
                        request.data = {};
                    }
                    request.data.Token = token;
                }
                return request;
            },
            error => {
                // Do something with request error
                return Promise.reject(error);
            }
        );
    }
    if (checkUnauthorized) {
        // Use a response interceptor for checking the unauthorized case
        await axiosInstance.interceptors.response.use(
            response => {
                // 2xx Success
                return response;
            },

            error => {
                // When we do not ger response from server
                if (!error.response) {
                    return { data: { succeeded: false, message: 'Server is unreachable', status: 503 } };
                }
                const status = error.response.status;
                // Unauthorized case
                if (status === 401) {
                    // Clear the local storage
                    localStorage.clear();
                    // Init token in the store
                    store.getState().core.login.token = null;
                    // Init permission in the store
                    store.getState().core.login.permissions = [];
                    // Init customers in the store
                    store.getState().core.login.customers = [];
                    // Init active customer in the store
                    store.getState().core.login.activeCustomer = [];
                    // Init logged user in the store
                    store.getState().core.login.user = null;
                } else if (status >= 500) {
                    // Reject 5XX server errors
                    return Promise.reject(error);
                }
                // Return the error, e.g. the 4XX client errors
                return error.response;
            }
        );
    }
    // Add axios instance to the instances array
    axiosInstances[name] = axiosInstance;
}

const getAxiosInstance = (name = defaultInstanceName) => {
    // return the axios instance
    return axiosInstances[name];
}


export { createInstance, getAxiosInstance };