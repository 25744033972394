import restCrudReducer from '../../../np-crud/store/restCrudReducer';

const [crudInitialState, crudReducer] = restCrudReducer({
    pluginName: 'USER_ACTIVITY',
    entityName: 'USER_ACTIVITY'
});

const initialState = {
    ...crudInitialState,
    deletedActivity: {},
    deleting: false,
    saving: false
};
const reducer = ( state = initialState, action) => {
    //Handle CRUD actions
    const newState = crudReducer (state, action);
    // If CRUD reducer handled the action
    if (newState !== null){
        return newState;
    }

    // Handle other actions
    switch (action.type) {
        case 'CORE|LOGOUT_SUCCESS':
        case 'CORE|CORE_SET_USER_ACTIVITY_SUCCESS':
            return initialState;
        default:
            return state;
    }
};

export default reducer;