import config_custom from './config_custom';

// Set basic properties
let config = {
    backend_port: config_custom.backend_port || 6530,
    backend_domain: config_custom.backend_domain || 'http://test.cloud.securaze.com',
    backend_static: config_custom.backend_static || `http://test.cloud.securaze.com:${6530}/static`
};

// Create calculated properties
config = {
    ...config,
    backend_url: config.backend_domain + ':' + config.backend_port
};

export default config;