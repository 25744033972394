import restCrudActions from '../../../np-crud/store/restCrudActions'
import { getAxiosInstance } from "../../../np-rest/rest";
import config from '../../../../config/config_default';
import { enqueueSnackbar } from "../../../np-dashboard/Notifier/store/notifierActions";

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: 'score-criteria',
    pluginName: 'SCORE-CRITERIA',
    entityName: 'SCORE-CRITERIA',
    fetchSuccessMessage: '',
    loadSuccessMessage: '',
    createFailedUseBackendMessage: true,
    deleteFailedUseBackendMessage: true,
    loadFailedUseBackendMessage: true,
    editFailedUseBackendMessage: true,
    bulkDeleteFailedUseBackendMessage: true
});

// Fetch ScoreCriteria action creators
export const fetchScoreCriterias = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
};

// Create DependentSetting  action creators
export const createScoreCriteriaInit = () => {
    return crudActions.createInit();
};
export const createScoreCriteriaStart = () => {
    return crudActions.createStart();
};
export const createScoreCriteriaSuccess = createdDependentSetting => {
    return crudActions.createSuccess(createdDependentSetting);
};
export const createScoreCriteriaFail = error => {
    return crudActions.createFail(error);
};

// Create ScoreCriteria  action creators
export const createScoreCriteria = ScoreCriteria => {
    return async dispatch => {
        await crudActions.create(dispatch, ScoreCriteria);
    };
};

// Edit ScoreCriteria  action creators
export const editScoreCriteria = setting => {
    return async dispatch => {
        await crudActions.edit(dispatch, setting);
    };
};

// Load ScoreCriteria action creators
export const loadScoreCriteria = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

// Delete ScoreCriteria  action creators
export const bulkDeleteScoreCriteria = scoreCriteriaIDs => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, scoreCriteriaIDs);
    };
};

// Delete ScoreCriteria  action creators
export const deleteScoreCriteria = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};

const listAllSites = data => {
    return {
        type: 'SCORE-CRITERIA|LIST',
        sites: data
    };
};

// Custom functions 
export const downloadFile = (type, customerID) => {
    let token = localStorage.getItem('CORE|AUTH|TOKEN');
    if (token) {
        token = JSON.parse(token).token;
    }
    return async dispatch => {
        return fetch(`${config.backend_domain}:${config.backend_port}/score-criteria/export?SiteKey=${type}&ActiveCustomerID=${customerID.ActiveCustomerID}&Token=${token}`, {
            method: "GET",
        }).then(async response => {
            if (response.status === 200) {
                return response.blob();
            }
            return null;
        });
    }
}

export const importFile = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(createScoreCriteriaInit());
        try {
            dispatch(createScoreCriteriaStart());
            let res = await rest.post('score-criteria/import', data);
            if (res.data && res.data.succeeded) {
                dispatch(createScoreCriteriaSuccess());
            }
            else {
                dispatch(createScoreCriteriaFail());

                dispatch(enqueueSnackbar({
                    message: `${res && res.data ? res.data.message : res && res.message ? res.message : "Cannot import file "}`,
                    options: {
                        variant: 'warning'
                    }
                }));
                return false;
            }

        } catch (error) {
            dispatch(createScoreCriteriaFail());
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return false;
        }
    }
}

export const fetchSites = (id) => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/sites/list', { forList: true, ActiveCustomerID: id });
            if (res.data && res.data.succeeded) {
                const sites = [];
                for (var site of res.data.result.data) {
                    sites.push({ value: site.SiteID, displayValue: `${site.Name} (${site.Key})` });
                }
                dispatch(listAllSites(sites));
                return sites;
            }
            else {
                dispatch(enqueueSnackbar({
                    message: `${res && res.data ? res.data.message : res && res.message ? res.message : "Cannot fetch sites "}`,
                    options: {
                        variant: 'warning'
                    }
                }));
                return [];
            }
        } catch (error) {
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return [];
        }
    };
}

export const fetchSitesForImportExport = (id) => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/sites/list', { forList: true, ActiveCustomerID: id });
            if (res.data && res.data.succeeded) {
                const sites = [];
                for (var site of res.data.result.data) {
                    sites.push({ value: site.Key, displayValue: `${site.Name} (${site.Key})` });
                }
                dispatch(listAllSites(sites));
                return sites;
            }
            else {
                dispatch(enqueueSnackbar({
                    message: `${res && res.data ? res.data.message : res && res.message ? res.message : "Cannot fetch sites for import and export "}`,
                    options: {
                        variant: 'warning'
                    }
                }));
                return [];
            }
        } catch (error) {
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return [];
        }
    };
}