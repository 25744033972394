import { Checkbox, Typography } from "@material-ui/core";
import React from "react";

const TableBody = props => {
    const { classes, rowData, options, columns, selected, classesBody } = props;

    if (rowData && rowData instanceof Array && rowData.length === 0) {
        return (
            <tbody>
                <tr className={classes.noMath_tr}>
                    <td className={classes.noMatch} colSpan={options.isSelectable ? columns.length + 1 : columns.length}>
                        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
                            {options.noMatch}
                        </Typography>
                    </td>
                </tr>
            </tbody>
        );
    }
    return (
        <tbody>
            {rowData.map(row => {
                let counter = 0;
                let rowID = row.id ? row.id.toString() : '0';
                let isSelected = selected.indexOf(rowID) !== -1;
                return (
                    <tr
                        key={`table_row_value_${row.index}_${row.id}_${parseInt(Math.random() * 99999)}`}
                        className={classesBody ? classesBody.tableBody_tr : classes.tableBody_tr}
                    >
                        {/** IS SELECTABLE SHOW CHECKBOX */}
                        {options.isSelectable && !row.disableSelecting ? (
                            <td
                                id={rowID}
                                onClick={
                                    options.isSelectable
                                        ? props.handleCheckBox
                                        : () => {
                                            return null;
                                        }
                                }
                                className={classes.checkboxTd}
                            >
                                <Checkbox checked={isSelected} id={rowID} color="primary" />
                            </td>
                        ) : row.disableSelecting === true && options.isSelectable ? (
                            <td />
                        ) : null}

                        {/**MAP OVER VALUES */}
                        {row.values.map(value => {
                            let visible = columns[counter].options.visible;
                            counter++;
                            return visible ? (
                                typeof value === "object" ? (
                                    <td
                                        key={`td1${row.index}_${rowID}_${parseInt(Math.random() * 99999999, 10)}`}
                                        id={rowID}
                                        className={classesBody ? classesBody.tableAction_td : classes.actionTd}
                                    >
                                        {value}
                                    </td>
                                ) : (
                                        <td
                                            key={`td2${row.index}_${rowID}_${parseInt(Math.random() * 99999999, 10)}`}
                                            id={rowID}
                                            className={classesBody ? classesBody.table_td : classes.table_td}
                                        >
                                            {value}
                                        </td>
                                    )
                            ) : null;
                        })}

                    </tr>
                );
            })}
            {/* {lazyLoading ? (
                <tr> <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td><p style={{ width: '10pc' }}>Load more:</p> </td>
                    <td> <Button style={{ width: '10pc' }} onClick={() => handleLazyLoadingButton(10)} >
                        +10
                </Button></td>
                    <td><Button style={{ width: '10pc' }} onClick={() => handleLazyLoadingButton(20)} >
                        +20
                </Button></td><td
                        key={`asdasdasdas`}
                        id={'asdasdasdsa'}
                        className={classesBody ? classesBody.table_td : classes.table_td}
                    >
                        <Button style={{ width: '10pc' }} onClick={() => handleLazyLoadingButton(50)} >
                            +50
                </Button>
                    </td></tr>)
                : null} */}
        </tbody>
    );
};
export default TableBody;
