import restCrudActions from '../../../np-crud/store/restCrudActions';

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: '/permissions',
    pluginName: 'CORE',
    entityName: 'Permissions',
    fetchSuccessMessage: '',
    // fetchFailedMessage = 'Cannot fetch ' + entityName,
    // createSuccesMessage = entityName + ' created successfully',
    // createFailedMessage = 'Cannot create ' + entityName,
    // deleteSuccessMessage = entityName + ' deleted successfully',
    // deleteFailedMessage = 'Cannot delete ' + entityName,
    loadSuccessMessage: ''
    // loadFailedMessage = 'Cannot load ' + entityName,
    // editSucessMessage = entityName + ' edited successfully',
    // editFailedMessage = 'Cannot edit ' + entityName,
    // bulkDeleteSuccessMessage = entityName + ' deleted successfully',
    // bulkDeleteFailedMessage = 'Cannot bulk delete ' + entityName
});

// Fetch permisses action creators
export const fetchPermissionsInit = () => {
    return crudActions.fetchInit();
};
export const fetchPermissionsStart = () => {
    return crudActions.fetchStart();
};
export const fetchPermissionsSuccess = () => {
    return crudActions.fetchSuccess();
};
export const fetchPermissionsFail = () => {
    return crudActions.fetchFail();
};
export const fetchPermissions = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
};

// Create Premissions action creators
export const createPermissionsInit = () => {
    return crudActions.createInit();
};
export const createPermissionsStart = () => {
    return crudActions.createStart();
};
export const createPermissionsSuccess = createdIssue => {
    return crudActions.createSuccess(createdIssue);
};
export const createPermissionsFail = error => {
    return crudActions.createFail(error);
};
export const createPermissions = permission => {
    return async dispatch => {
        await crudActions.create(dispatch, permission);
    };
};

// Delete Premissions action creators
export const deletePermissionsInit = () => {
    return crudActions.deleteInit();
};
export const deletePermissionsStart = () => {
    return crudActions.deleteStart();
};
export const deletePermissionsSuccess = deletedIssue => {
    return crudActions.deleteSuccess(deletedIssue);
};
export const deletePermissionsFail = error => {
    return crudActions.deleteFail(error);
};
export const deletePermissions = permission => {
    return async dispatch => {
        await crudActions.delete(dispatch, permission);
    };
};

// Load issue action creators
export const loadPermissionsInit = () => {
    return crudActions.loadInit();
};
export const loadPermissionsStart = () => {
    return crudActions.loadStart();
};
export const loadPermissionsSuccess = loadedPermission => {
    return crudActions.loadSuccess(loadedPermission);
};
export const loadPermissionsFail = error => {
    return crudActions.loadFail(error);
};
export const loadPermissions = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

// Edit Premissions action creators
export const editPermissionsInit = () => {
    return crudActions.editInit();
};
export const editPermissionsStart = () => {
    return crudActions.editStart();
};
export const editPermissionsSuccess = editedPermission => {
    return crudActions.editSuccess(editedPermission);
};
export const editPermissionsFail = error => {
    return crudActions.editFail(error);
};
export const editPermissions = permission => {
    return async dispatch => {
        await crudActions.edit(dispatch, permission);
    };
};

// Bulk delete Premissions action creators
export const bulkDeletePermissionsInit = () => {
    return crudActions.bulkDeleteInit();
};
export const bulkDeletePermissionsStart = () => {
    return crudActions.bulkDeleteStart();
};
export const bulkDeletePermissionsSuccess = bulkDeleted => {
    return crudActions.bulkDeleteSuccess(bulkDeleted);
};
export const bulkDeletePermissionsFail = error => {
    return crudActions.bulkDeleteFail(error);
};
export const bulkDeletePermissions = permissionIds => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, permissionIds);
    };
};
