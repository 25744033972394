import restCrudActions from '../../../np-crud/store/restCrudActions'
import { getAxiosInstance } from "../../../np-rest/rest";
import { enqueueSnackbar } from "../../../np-dashboard/Notifier/store/notifierActions";
// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: 'sites',
    pluginName: 'SITE',
    entityName: 'SITE',
    fetchSuccessMessage: '',
    loadSuccessMessage: '',
    createFailedUseBackendMessage: true,
    deleteFailedUseBackendMessage: true,
    loadFailedUseBackendMessage: true,
    editFailedUseBackendMessage: true,
    bulkDeleteFailedUseBackendMessage: true
});

export const fetchSites = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
}



// Fetch Site action creators
export const fetchSites2 = params => {
    const rest = getAxiosInstance();
    let token = localStorage.getItem('CORE|AUTH|TOKEN');
    if (token) {
        token = JSON.parse(token).token;
    }
    return async dispatch => {
        dispatch(crudActions.fetchStart());
        try {
            let resCount = await rest.post('/sites/count', { ...params, 'Token': token });
            if (resCount.data && resCount.data.succeeded) {
                let res = await rest.post('/sites/list', { ...params, 'Token': token });
                if (res.data && res.data.succeeded) {
                    dispatch(
                        enqueueSnackbar({
                            message: 'Sites loaded successfully!',
                            options: {
                                variant: 'success'
                            }
                        })
                    );
                    let retObj = {};

                    retObj.data = res.data.result.data;
                    retObj.count = resCount.data.result.data;

                    dispatch(crudActions.fetchSuccess(retObj.count, retObj.data));

                    return retObj;
                }
                else {
                    dispatch(crudActions.fetchFail());
                    dispatch(
                        enqueueSnackbar({
                            message: `${res.data ? res.data.message : res.message ? res.message : 'ERROR'}`,
                            options: {
                                variant: 'warning'
                            }
                        }));
                    return false;
                }
            } else {
                dispatch(crudActions.fetchFail());
                dispatch(
                    enqueueSnackbar({
                        message: `${resCount.data ? resCount.data.message : resCount.message ? resCount.message : 'ERROR'}`,
                        options: {
                            variant: 'warning'
                        }
                    }));
                return false;
            }
        } catch (error) {
            dispatch(crudActions.fetchFail());
            dispatch(
                enqueueSnackbar({
                    message: 'ERROR',
                    options: {
                        variant: 'warning'
                    }
                })
            );
            return false;
        }
    };
};

// Create Site  action creators
export const createSite = Site => {
    return async dispatch => {
        await crudActions.create(dispatch, Site);
    };
};

// Edit Site  action creators
export const editSite = setting => {
    return async dispatch => {
        await crudActions.edit(dispatch, setting);
    };
};

// Load Site action creators
export const loadSite = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

// Delete Site  action creators
export const bulkDeleteSite = siteIDs => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, siteIDs);
    };
};

// Delete Site  action creators
export const deleteSite = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};