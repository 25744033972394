import React, { useState } from 'react';
import { Dialog, withStyles } from '@material-ui/core';
import Form from '../../np-form/Form';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});
const PermissionEdit = props => {


    const { open, activeCustomer, translations, classes } = props;


    const form = {

        Name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                fullWidth: true,
                margin: 'dense',
                placeholder: 'Name'
            },
            value: '',

            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        Key: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                fullWidth: true,
                margin: 'dense',
                placeholder: 'Key'
            },
            value: '',

            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        Module: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                fullWidth: true,
                margin: 'dense',
                placeholder: 'Module'
            },
            value: '',

            validation: {
                required: true
            },
            valid: true,
            touched: false
        }
    }
    const [permissionsForm, setPermissionsForm] = useState(form);
    const [formIsValid, setFormIsValid] = useState(false);


    const submitHandler = event => {
        event.preventDefault();
        // Prepare the form data
        const formData = formUtility.getFormData(permissionsForm);
        props.createPermission({ ...formData, ActiveCustomerID: activeCustomer });
        props.handleOpen();
    };

    // Input change handler
    const inputChangedHandler = (event, inputIdentifier) => {
        // Get default new state based on the input change event
        const updatedState = formUtility.getNewStateOnInputChange(event, inputIdentifier, permissionsForm);
        setPermissionsForm(updatedState.myForm);
        setFormIsValid(updatedState.formIsValid);
    };

    const cancelHandler = () => {
        setPermissionsForm(form);
        setFormIsValid(false);
        props.handleOpen();
    };

    // Render form
    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            disableEnforceFocus={true}
            fullWidth={true}
            // onClose={cancelHandler}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    cancelHandler();
                }
            }}
        >
            <Form
                cancelHandler={cancelHandler}
                inputChangedHandler={inputChangedHandler}
                submitHandler={submitHandler}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                classes={classes}
                form={permissionsForm}
                formIsValid={formIsValid}
                formTitle={translations['create_permission'] || "Create permission"}
                buttonText={translations['save'] || "Save"}
                buttonTextCancel={translations['cancel'] || "Cancel"}
            />
        </Dialog>
    );
};


export default withStyles(styles)(PermissionEdit);
