import restCrudActions from '../../../np-crud/store/restCrudActions'
import { getAxiosInstance } from '../../../np-rest/rest';


// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath:'user-activity',
    pluginName: 'USER_ACTIVITY',
    entityName: 'USER_ACTIVITY',
    fetchSuccessMessage: '',
    loadSuccessMessage: '',
});

// Fetch UserActivity action creators
export const fetchUserActivityInit = () => {
    return crudActions.fetchInit();
};
export const fetchUserActivityStart = () => {
    return crudActions.fetchStart();
};
export const fetchUserActivitySuccess = (count , list) => {
    return crudActions.fetchSuccess(count , list);
};
export const fetchUserActivityFail = () => {
    return crudActions.fetchFail();
};

// Delete UserActivity action creators
export const deleteUserActivityInit = () => {
    return crudActions.deleteInit();
};
export const deleteUserActivityStart = () => {
    return crudActions.deleteStart();
};
export const deleteUserActivitySuccess = () => {
    return crudActions.deleteSuccess();
};
export const deleteUserActivityFail = () => {
    return crudActions.deleteFail();
};


export const fetchUserActivity = filterData => {
    const rest=getAxiosInstance();

    return async dispatch => {
        dispatch (fetchUserActivityStart());
        try {
                let countRes = await rest.post("users/sessions/count", filterData);
                if (countRes.data && countRes.data.succeeded){
                        let listRes = await rest.post("users/sessions/list", filterData);
                        if (listRes.data && listRes.data.succeeded) {
                            dispatch (fetchUserActivitySuccess(   countRes.data.result.data , listRes.data.result.data));
                            return { data: listRes.data.result.data, count: countRes.data.result.data }
                        }
                }
                dispatch(fetchUserActivityFail());
                return { data: [], count: 0 };
        } catch (error){
            dispatch (fetchUserActivityFail());
            return { data: [], count:0};
        }
    }
};


export const removeSession = queryParams => {
    const rest = getAxiosInstance();

    return async dispatch => {
        dispatch(deleteUserActivityStart());
        try{
            let res=await rest.post("/users/sessions/remove",queryParams);
            if (res.data && rest.data.succeeded){
                dispatch(deleteUserActivitySuccess({ deleted: {} }));
            }
            dispatch(deleteUserActivityFail());
            return { data:[], count: 0};
        } catch (error){
            dispatch (deleteUserActivityFail());
            return { data: [], count: 0 };
        }
    }
}
