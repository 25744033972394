import React from 'react';
import clsx from 'clsx'
import {Formik} from "formik"
import  * as Yup from "yup"
import { 
    TextField, withStyles, Button, FormHelperText, InputLabel, Select, MenuItem, IconButton, Input,
    InputAdornment, CircularProgress,  FormControl, Hidden
 } from "@material-ui/core";
import { VisibilityOff ,Visibility } from '@material-ui/icons';

// import VisibilityOff from '@material-ui/icons/VisibilityOff'
const styles = theme =>({
    submit:{
        width: "100%",
        color: theme.palette.buttonText.default,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.buttonText.default
        }
    },
    formControl:{
        width:"100%",
        marginBottom: '5px'
    }
});


const LoginForm = props => {
    const {classes, formSettings, initialValues, handleSubmit, appTranslations, translations, handleLanguageChange} = props;
    const [value, setValue]= React.useState(false);
    const handleClickShowPassword = () =>{
        setValue(!value);
    };
    const handleMouseDownPassword = (event) =>{
        event.preventDefault();
    };

    return (
       <Formik
            initialValues={initialValues}
            onSubmit={async(values, {setSubmitting}) =>{
                setSubmitting(true);
                await handleSubmit(values);
            }}
            validationSchema = {Yup.object().shape(formSettings.Validation)}
        >
        {formikProps =>{
            const {values, isValid, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, errors, touched} = formikProps;
            return (
                <form onSubmit={handleSubmit} autoComplete="off">
                    <TextField
                            label={translations['username'] || 'Username'}
                            name="Username"
                            fullWidth={true}
                            value={values.Username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            margin="dense"
                            error={!!(errors.Username && touched.Username)}
                    />
                    <FormControl className={clsx(classes.margin, classes.formControl)}>
                        <InputLabel htmlFor="in-category-1">{translations['password' ] || 'Password'}</InputLabel>
                        <Input
                            id="in-category-1"
                            type={value ? 'text' : 'password'}
                            name="Password"
                            value={values.Password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!(errors.Password && touched.Password)}
                            endAdornment ={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {value ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            />
                    </FormControl>
                    {formSettings.ButtonSubmit ? (
                        <Button
                            className={classes.submit}
                            variant="contained"
                            color={formSettings.ButtonSubmit.Color}
                            type="submit"
                            fullWidth={true}
                            disabled={isSubmitting || !(isValid && dirty)}
                            >
                                {isSubmitting ? <CircularProgress size={24}/> : formSettings.ButtonSubmit.Title}
                            </Button>
                    ) : null}
                    <Hidden xsUp>
                    <FormControl className={classes.formControl} hidden margin={"dense"}>
                        <FormHelperText>{translations['language'] || 'Select language'}</FormHelperText>
                        <Select
                        name="language"
                        label={translations['language'] || 'Select language'}
                        margin={"dense"}
                        value={values.language || 'en'}
                        onChange={handleChange}
                        onClick={handleLanguageChange}
                        >
                            {(appTranslations ? appTranslations : []).map(option =>(
                                <MenuItem key={option.code} value={option.code}>
                                    {option.displayName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    </Hidden>
                </form>
            );
        }}
       </Formik>
    );
};

export default withStyles (styles, {withTheme: true}) (LoginForm);

