import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route } from 'react-router';
import { Grid, Paper, withStyles } from '@material-ui/core';

import Form from '../../np-form/Form';
import * as formUtility from '../../np-form/utility';

import * as actions from './store/roleActions';
import * as permissionActions from '../Permissions/store/permissionActions';
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,

        padding: theme.spacing(2),
        display: 'flex',
        flexWrap: 'wrap'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center'
    }, form: {
        padding: theme.spacing(1)
    }
});
const setInitialData = (permissions, setRoleForm) => {
    const selectOptions = [];
    permissions.forEach(permission => {
        selectOptions.push({ label: permission.Name, value: permission.PermissionID });
    });

    setRoleForm({
        name: {
            elementType: 'input',
            elementConfig: {
                fullWidth: true,
                margin: 'dense',
                type: 'text',
                placeholder: 'Name',
                label: 'Name'
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        fancyName: {
            elementType: 'input',
            elementConfig: {
                fullWidth: true,
                margin: 'dense',
                type: 'text',
                placeholder: 'Fancy name',
                label: 'Fancy name'
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        type: {
            elementType: 'select',
            elementConfig: {
                options: [{ value: 'private', displayValue: 'Private' }, { value: 'public', displayValue: 'Public' }]
            },
            value: 'public',
            validation: {},
            valid: true,
            formHelperText: 'Please select role type'
        },
        locked: {
            elementType: 'checkbox',
            elementConfig: {
                type: 'checkbox',
                label: null,
                className: null,
                margin: 'dense',
                fullWidth: true,
                options: [{ ID: 'checkboxID', Checked: false, Name: 'Role locked' }]
            },
            value: false,
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        permissionIds: {
            elementType: 'multi-select',
            elementConfig: {
                fullWidth: true,
                margin: 'dense',
                isMulti: true,
                type: 'text',
                name: 'Permissions',
                customLabel: 'Choose permissions',
                options: selectOptions,
                selected: []
            },
            value: [],
            validation: {
                required: true
            },
            valid: true,
            touched: true
        }
    });
};


const RoleCreate = props => {
    const { hasEditPermission, createdRole, permissions, classes, onFetchPermissions, onInit } = props;

    useEffect(() => {
        if (permissions !== null) {
            setInitialData(permissions, setRoleForm);
        }
    }, [permissions]);

    //Setting up initial data for select

    // After unmount init the state in the redux store
    useEffect(() => {
        onFetchPermissions({});
        return () => {
            onInit();
        };
    }, [onFetchPermissions, onInit]);

    const [roleForm, setRoleForm] = useState({});
    const [formIsValid, setFormIsValid] = useState(false);

    const submitHandler = event => {
        event.preventDefault();

        // Prepare the form data
        const formData = formUtility.getFormData(roleForm);

        const role = {
            Name: formData.name,
            FancyName: formData.fancyName,
            PermissionIDs: formData.permissionIds,
            Type: formData.type,
            Locked: formData.locked
        };

        props.onCreateRole(role);
    };

    // Input change handler
    const inputChangedHandler = (event, inputIdentifier) => {
        // Get default new state based on the input change event
        const updatedState = formUtility.getNewStateOnInputChange(event, inputIdentifier, roleForm);
        setRoleForm(updatedState.myForm);
        setFormIsValid(updatedState.formIsValid);
    };
    const [redirect, setRedirect] = useState(false);
    const cancelHandler = () => {
        setRedirect(true);
    };
    // If there is a created issue let's redirect to the roles list
    if (createdRole !== null || redirect) {
        // Prepare redirect URL
        let path = props.match.url; // .../roles/create
        let pathArray = path.split('/');
        pathArray.pop(); // remove create
        path = pathArray.join('/'); // .../roles
        // Navigate to the ../roles
        return <Route path="*" element={<Navigate to={path} />} />;
    }

    const onMultiSelectChange = data => {
        const updatedState = formUtility.getNewStateOnMultiSelectChange(data, 'permissionIds', roleForm);
        // Set the new state
        setRoleForm(updatedState.myForm);
        setFormIsValid(updatedState.formIsValid);
    };

    // Checkbox change handler
    const serviceCheckboxChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnCheckboxChange(event, inputIdentifier, roleForm);
        setRoleForm(updateState.myForm);
    };

    //Render form
    return hasEditPermission ? (
        <div className={classes.root}>
            {' '}
            <Grid container spacing={10} justify="center" direction="row" alignItems="center">
                <Grid item lg={6} md={8} sm={10} xs={12}>
                    <Paper className={classes.paper}>
                        <Form
                            inputChangedHandler={inputChangedHandler}
                            submitHandler={submitHandler}
                            form={roleForm}
                            formIsValid={formIsValid}
                            buttonText="Save"
                            custom={classes.form}
                            cancelHandler={cancelHandler}
                            buttonTextCancel="Cancel"
                            formTitle="Enter data to create new role"
                            getMultiSelectData={onMultiSelectChange}
                            checkboxChangedHandler={serviceCheckboxChangedHandler}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    ) : null;
};

const mapStateToProps = state => {
    return {
        hasEditPermission: state.core.login.permissions.includes('*') || state.core.login.permissions.includes('core_role_edit'),
        createdRole: state.core.role.created,
        permissions: state.core.permission.list
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInit: () => dispatch(actions.createRoleInit()),
        onCreateRole: role => dispatch(actions.createRole(role)),
        onFetchPermissions: data => dispatch(permissionActions.fetchPermissions(data))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(RoleCreate));
