import * as actionTypes from './actionTypes';

// Initial state for fetching
const fetchInitialState = {
    count: 0,
    list: [],
    fetching: true
};

// Initial state for creating an
const createInitialState = {
    created: null,
    creating: false
};

// Initial state for loading an
const loadInitialState = {
    loaded: null,
    loading: false
};

// Initial state for editing an
const editInitialState = {
    edited: null,
    editing: false
};

// Initial state for deleting an
const deleteInitialState = {
    deleted: null,
    deleting: false
};

// Initial state for bulk deleting an
const bulkDeleteInitialState = {
    bulkDeleted: null,
    bulkDeleting: false
};

// The combinied initial state
const initialState = {
    ...fetchInitialState,
    ...createInitialState,
    ...loadInitialState,
    ...editInitialState,
    ...deleteInitialState,
    ...bulkDeleteInitialState
};

const fetchInit = state => {
    return {
        ...state,
        ...fetchInitialState
    };
};

const fetchStart = state => {
    return { ...state, fetching: true };
};

const fetchSuccess = (state, action) => {
    return {
        ...state,
        list: action.lazyLoad && !action.crudActions && action.filter && action.filter.offset > 0 ? [...state.list, ...action.list] : action.list,
        count: action.count,
        fetching: false
    };
};

const fetchFail = state => {
    return {
        ...state,
        fetching: false
    };
};

const createInit = state => {
    return {
        ...state,
        ...createInitialState
    };
};

const createStart = state => {
    return { ...state, creating: true };
};

const createSuccess = (state, action) => {
    return {
        ...state,
        created: action.created,
        creating: false
    };
};

const createFail = state => {
    return {
        ...state,
        created: null,
        creating: false
    };
};

const deleteInit = state => {
    return {
        ...state,
        ...deleteInitialState
    };
};
const deleteStart = state => {
    return { ...state, deleting: true };
};

const deleteSuccess = (state, action) => {
    return {
        ...state,
        deleted: action.deleted,
        deleting: false
    };
};
const deleteFail = (state, action) => {
    return {
        ...state,
        deleted: null,
        deleting: false
    };
};

const loadInit = state => {
    return {
        ...state,
        ...loadInitialState
    };
};
const loadStart = state => {
    return { ...state, loading: true };
};

const loadSuccess = (state, action) => {
    return {
        ...state,
        loaded: action.loaded,
        loading: false
    };
};

const loadFail = state => {
    return {
        ...state,
        loaded: null,
        loading: false
    };
};

const editInit = state => {
    return {
        ...state,
        ...editInitialState
    };
};

const editStart = state => {
    return { ...state, editing: true };
};

const editSuccess = (state, action) => {
    return {
        ...state,
        edited: action.edited,
        loaded: action.edited,
        editing: false
    };
};

const editFail = state => {
    return {
        ...state,
        edited: null,
        editing: false
    };
};

const bulkDeleteInit = state => {
    return {
        ...state,
        ...bulkDeleteInitialState
    };
};

const bulkDeleteStart = state => {
    return { ...state, deleting: true };
};

const bulkDeleteSuccess = (state, action) => {
    return {
        ...state,
        bulkDeleted: action.bulkDeleted,
        deleting: false
    };
};

const bulkDeleteFail = state => {
    return {
        ...state,
        deleting: false
    };
};

const restCrudReducer = props => {
    const { pluginName, entityName } = props;
    const prefix = pluginName + '|' + entityName + '_';

    const reducer = (state = initialState, action) => {
        switch (action.type) {
            // Fetching
            case prefix + actionTypes.FETCH_INIT:
                return fetchInit(state, action);
            case prefix + actionTypes.FETCH_START:
                return fetchStart(state, action);
            case prefix + actionTypes.FETCH_SUCCESS:
                return fetchSuccess(state, action);
            case prefix + actionTypes.FETCH_FAIL:
                return fetchFail(state, action);
            // Creating
            case prefix + actionTypes.CREATE_INIT:
                return createInit(state, action);
            case prefix + actionTypes.CREATE_START:
                return createStart(state, action);
            case prefix + actionTypes.CREATE_SUCCESS:
                return createSuccess(state, action);
            case prefix + actionTypes.CREATE_FAIL:
                return createFail(state, action);
            //Deleting
            case prefix + actionTypes.DELETE_INIT:
                return deleteInit(state, action);
            case prefix + actionTypes.DELETE_START:
                return deleteStart(state, action);
            case prefix + actionTypes.DELETE_SUCCESS:
                return deleteSuccess(state, action);
            case prefix + actionTypes.DELETE_FAIL:
                return deleteFail(state, action);
            // Loading
            case prefix + actionTypes.LOAD_INIT:
                return loadInit(state, action);
            case prefix + actionTypes.LOAD_START:
                return loadStart(state, action);
            case prefix + actionTypes.LOAD_SUCCESS:
                return loadSuccess(state, action);
            case prefix + actionTypes.LOAD_FAIL:
                return loadFail(state, action);
            // Editing
            case prefix + actionTypes.EDIT_INIT:
                return editInit(state, action);
            case prefix + actionTypes.EDIT_START:
                return editStart(state, action);
            case prefix + actionTypes.EDIT_SUCCESS:
                return editSuccess(state, action);
            case prefix + actionTypes.EDIT_FAIL:
                return editFail(state, action);
            //deleting bulk
            case prefix + actionTypes.BULK_DELETE_INIT:
                return bulkDeleteInit(state, action);
            case prefix + actionTypes.BULK_DELETE_START:
                return bulkDeleteStart(state, action);
            case prefix + actionTypes.BULK_DELETE_SUCCESS:
                return bulkDeleteSuccess(state, action);
            case prefix + actionTypes.BULK_DELETE_FAIL:
                return bulkDeleteFail(state, action);
            default:
                // !!! Do not return the state here
                return null;
        }
    };

    return [initialState, reducer];
};

export default restCrudReducer;
