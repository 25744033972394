import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Grid, Paper } from '@material-ui/core';

import UserGeneralEdit from './UserGeneralEdit';
import UserAccessEdit from './UserAccessEdit';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    }
});

const SimpleTabs = props => {
    const classes = props;

    const [value, setValue] = useState(0);
    const userID = props.match.params.id;

    const handleChange = (event, value) => {
        event.preventDefault();
        setValue(value);
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={10} justify="center" direction="row" alignItems="center">
                <Grid item lg={6} md={8} sm={10} xs={12}>
                    <Paper className={classes.paper}>
                        <AppBar position="static">
                            <Tabs value={value} onChange={handleChange}>
                                <Tab label="User General edit" />
                                <Tab label="User access edit" />
                            </Tabs>
                        </AppBar>
                        {value === 0 && <UserGeneralEdit userID={userID} page={props.location && props.location.state ? props.location.state.page : null} filter={props.location && props.location.state ? props.location.state.filter : null} />}
                        {value === 1 && <UserAccessEdit userID={userID} />}
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default withStyles(styles)(SimpleTabs);
