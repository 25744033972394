import React, { useState, useEffect } from 'react';

import Form from '../../np-form/Form';
import { Dialog, withStyles } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    },
    button: {
        color: 'white'
    }
});

const updateFormState = props => {
    const { classes, sites } = props;
    return {
        ActionGuid: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Action GUID',
                label: 'Action GUID',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                autoFocus: true
            },
            value: null,
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        Vitality: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Vitality',
                label: 'Vitality',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: null,
            validation: {
                isNumber: true,
                required: true
            },
            valid: false,
            touched: false
        },
        Memory: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Memory',
                label: 'Memory',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: null,
            validation: {
                isNumber: true,
                required: true
            },
            valid: false,
            touched: false
        },
        Attention: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Attention',
                label: 'Attention',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: null,
            validation: {
                isNumber: true,
                required: true
            },
            valid: false,
            touched: false
        },
        Logic: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Logic',
                label: 'Logic',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: null,
            validation: {
                isNumber: true,
                required: true
            },
            valid: false,
            touched: false
        },
        Speed: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Speed',
                label: 'Speed',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: null,
            validation: {
                isNumber: true,
                required: true
            },
            valid: false,
            touched: false
        },
        Accuracy: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Accuracy',
                label: 'Accuracy',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: null,
            validation: {
                isNumber: true,
                required: true
            },
            valid: false,
            touched: false
        },
        CorrectionPenalty: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Correction penalty',
                label: 'Correction penalty',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: null,
            validation: {
                isNumber: true,
                required: true
            },
            valid: false,
            touched: false
        },
        MaxCorrectionPenalty: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Max correction penalty',
                label: 'Max correction penalty',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: null,
            validation: {
                isNumber: true,
                required: true
            },
            valid: false,
            touched: false
        },
        SiteID: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Site',
                margin: "dense",
                fullWidth: true,
                options: sites ? [...sites] : []
            },
            formHelperText: "Please select site",
            value: sites && sites.length > 0 ? sites[0].value : '',
            validation: {
                required: true
            },
            valid: true,
            touched: false
        }
    };
};

const ScoreCriteriaCreate = props => {
    const { open, classes, loading, activeCustomer } = props;
    const { handleOpen, createScoreCriteria } = props;

    const [formIsValid, setFormIsValid] = useState(false);
    const [scoreCriteriaForm, setScoreCriteriaForm] = useState(updateFormState({ ...props }));

    useEffect(() => {
        setScoreCriteriaForm(updateFormState({ ...props }));
    }, [props]);


    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, scoreCriteriaForm);
        setScoreCriteriaForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const submitHandler = event => {
        event.preventDefault();
        const formData = formUtility.getFormData(scoreCriteriaForm);
        let scoreWeights = { ...formData };
        delete scoreWeights.ActionGuid;
        delete scoreWeights.CorrectionPenalty;
        delete scoreWeights.MaxCorrectionPenalty;
        delete scoreWeights.SiteID;

        formData['ScoreWeights'] = JSON.stringify(scoreWeights);
        createScoreCriteria({ ...formData, ActiveCustomerID: activeCustomer });
        handleOpen();
        setScoreCriteriaForm({});
        setFormIsValid(false);
    };

    const cancelHandler = () => {
        setScoreCriteriaForm({});
        setFormIsValid(false);
        handleOpen();
    };

    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            // onClose={handleOpen}
            fullWidth={true}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleOpen();
                }
            }}
        >
            <Form
                formIsValid={formIsValid}
                classes={classes}
                form={scoreCriteriaForm}
                formTitle="Create Score Criteria"
                buttonText="Save"
                buttonTextCancel="Cancel"
                submitHandler={submitHandler}
                cancelHandler={cancelHandler}
                inputChangedHandler={inputChangedHandler}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                submitButtonStyle={formIsValid && !loading ? { color: 'white' } : null}
                cancelButtonDisabled={loading}
                submitButtonDisabled={loading}
            />
        </Dialog>
    )
};

export default withStyles(styles)(ScoreCriteriaCreate);