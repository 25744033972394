import restCrudReducer from '../../../np-crud/store/restCrudReducer';

const [crudInitialState, crudReducer] = restCrudReducer({
    pluginName: 'IMPORT_EXPORT',
    entityName: 'BINARY_IMAGE',
});

const initialState = {
    ...crudInitialState,
    deleteBinaryImage: {},
    binaryImageTypes: [],
    downloadProgress: 0,
    deleting: false,
    saving: false,
    progress: null,
    currentDownloadData: {},
    snackBar: false,
    snackStart: false

};

const updateDownloadProgress = (state, action) => {
    return { ...state, snackBar: action.snackBar, downloadProgress: action.downloadProgress, currentDownloadData: { ...state.currentDownloadData, [action.data.ImageID]: { downloadProgress: action.downloadProgress, currentSizeDownload: action.currentSize, maxSizeDownload: action.maxSize, name: action.name } } };
};

const updateBinaryImageTypes = (state, action) => {
    return { ...state, binaryImageTypes: action.binaryImageTypes };
};

const reducer = (state = initialState, action) => {
    // Handle CRUD actions
    const newState = crudReducer(state, action);
    // If CRUD reducer handled the action
    if (newState !== null) {
        return newState;
    }

    // Handle other actions
    switch (action.type) {
        case 'CORE|LOGOUT_SUCCESS':
        case 'CORE|CORE_SET_ACTIVE_CUSTOMER_SUCCESS':
            return initialState;
        case 'CLEAR_STATE_CREATE':
            return { ...state, progress: null, maxSize: null, loadSize: null, snackStart: false };
        case 'SOFTWARE|PROGRESS':
            return { ...state, progress: action.progress, maxSize: action.maxSize, loadSize: action.loadSize, snackStart: action.snackStart };
        case 'SOFTWARE|RECOVER_BILLING_SUCCESS':
            return { ...state, sendMessage: !state.sendMessage };
        case 'SOFTWARE|RECOVER_BILLING_FAIL':
            return { ...state, sendMessage: !state.sendMessage };
        case 'CLEAR_STATE':
            return { ...state, loaded: null };
        case 'IMPORT_EXPORT|BINARY_IMAGE|DOWNLOAD_PROGRESS':
            return updateDownloadProgress(state, action);
        case 'IMPORT_EXPORT|BINARY_IMAGE_TYPE|LIST':
            return updateBinaryImageTypes(state, action);
        default:
            return state;
    }
};

export default reducer;
