import React, { useState, useEffect } from 'react';
import { withStyles, Dialog, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actions from './store/customerActions';
import * as action from '../../ms-billing/BillingAccount/store/billingAccountActions';
import CustomerList from './CustomerList';
import CustomerCreate from './CustomerCreate';
import { saveColumnsFilter } from '../../np-core/Auth/store/authActions';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
    progressBarr: {
        width: '100%',
        height: 5
    },
    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden"
    },
    logo: {
        width: '250px',
        margin: '10px'
    },
});

const Customer = props => {
    const { classes, customers, onFetchBillingAccountPlans, onListPlans, activeCustomer } = props;
    const [addModalState, setAddModalState] = useState(false);
    const [loading, setLoading] = useState(false);
    const fetching = customers.fetching;
    const [users, setUsers] = useState([]);
    const [plans, setPlans] = useState([]);
    const [customer, setCustomer] = useState([]);



    const handleOpen = async () => {
        setAddModalState(!addModalState);
        let userData = await props.onListUsers({ ActiveCustomerID: activeCustomer });
        let data = await props.onListCustomers({ ActiveCustomerID: activeCustomer });

        setUsers(userData);

        setCustomer(data)
    };


    useEffect(() => {
        const handlePlansEdit = async () => {
            let data = await onListPlans({ ActiveCustomerID: activeCustomer });
            setPlans(data);
        }
        handlePlansEdit()
        setLoading(customers.creating);
    }, [customers.creating, onFetchBillingAccountPlans, onListPlans, activeCustomer]);

    return (
        <div className={classes.root}>
            <div className={classes.progressBarr}>
                {fetching ? <LinearProgress color="primary" /> : null}
            </div>
            {props.hasPermission('customer_list') ? <CustomerList activeCustomer={activeCustomer} columnsState={props.columns} saveColumnsFilter={props.saveColumnsFilter} customers={customers} hasPermission={props.hasPermission} hasPermissions={props.hasPermissions} onFetchCustomers={props.onFetchCustomers} loadCustomer={props.onLoadCustomer} handleCreate={handleOpen} /> : null}
            {props.hasPermission('customer_create') ? <CustomerCreate activeCustomer={activeCustomer} customers={customer} currentUserID={props.currentUserID} users={users} handleOpen={handleOpen} open={addModalState} onCreateCustomer={props.onCreateCustomer} plans={plans} /> : null}

            <Dialog disableEscapeKeyDown={false} open={loading}
                    PaperProps={{
                        classes: {
                            root: classes.paper
                        }
                    }}
            >
                <img alt="App logo loading" className={classes.logo} src={process.env.PUBLIC_URL + '/assets/images/app-logo-loading.gif'} />
            </Dialog>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        currentUserID: state.core.login.user.UserID,
        customers: state.customer.customers,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        hasPermission: permission => { return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'); },
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCustomers: filter => dispatch(actions.fetchCustomers(filter)),
        onCreateCustomer: data => dispatch(actions.createCustomer(data)),
        onListPlans: (data) => dispatch(actions.loadPlans(data)),
        onListUsers: () => dispatch(action.listUsers()),
        onListCustomers: () => dispatch(action.listCustomers()),
        saveColumnsFilter: data => dispatch(saveColumnsFilter(data)),



    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Customer));