
const initialState = {
    downloadProgress: 0,
    progress: null,
    currentDownloadData: {},

};

const updateDownloadProgress = (state, action) => {
    return { ...state, snackBar: action.snackBar, downloadProgress: action.downloadProgress, currentDownloadData: { ...state.currentDownloadData, [action.data.ImageID]: { downloadProgress: action.downloadProgress, currentSizeDownload: action.currentSize, maxSizeDownload: action.maxSize, name: action.name, snackBar: action.snackBar } } };
};

const reducer = (state = initialState, action) => {
    // Handle CRUD actions
    // If CRUD reducer handled the action
    // Handle other actions
    switch (action.type) {
        case 'CORE|LOGOUT_SUCCESS':
        case 'CORE|CORE_SET_ACTIVE_CUSTOMER_SUCCESS':
            return initialState;
        case 'DOWNLOAD|DOWNLOAD_PROGRESS':
            return updateDownloadProgress(state, action);
        default:
            return state;
    }
};

export default reducer;
