import restCrudActions from '../../../np-crud/store/restCrudActions';
import { getAxiosInstance } from "../../../np-rest/rest";
import { enqueueSnackbar } from '../../../np-dashboard/Notifier/store/notifierActions';
import config from '../../../../config/config_default';


// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: 'permissions',
    pluginName: 'ADMIN',
    entityName: 'PERMISSION',
    fetchSuccessMessage: '',
    loadSuccessMessage: ''

});

// Fetch Permissions action creators
export const fetchPermissionsInit = () => { return crudActions.fetchInit(); }
export const fetchPermissionsStart = () => { return crudActions.fetchStart(); }
export const fetchPermissionsSuccess = () => { return crudActions.fetchSuccess(); }
export const fetchPermissionsFail = () => { return crudActions.fetchFail(); }
export const fetchPermissions = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    }

}// Create Permissions  action creators
export const createPermissionsInit = () => { return crudActions.createInit(); };
export const createPermissionsStart = () => { return crudActions.createStart(); };
export const createPermissionsSuccess = createdPermissions => { return crudActions.createSuccess(createdPermissions); };
export const createPermissionsFail = error => { return crudActions.createFail(error); };
export const createPermissions = permission => {
    return async dispatch => {
        await crudActions.create(dispatch, permission);
    };
};

// Delete Permissions  action creators

export const bulkDeletePermissionsInit = () => { return crudActions.bulkDeleteInit(); };
export const bulkDeletePermissionsStart = () => { return crudActions.bulkDeleteStart(); };
export const bulkDeletePermissionsSuccess = bulkDeleted => { return crudActions.bulkDeleteSuccess(bulkDeleted); };
export const bulkDeletePermissionsFail = error => { return crudActions.bulkDeleteFail(error); };
export const bulkDeletePermissions = permissionIDs => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, permissionIDs);
    };
};

// Delete Permissions  action creators
export const deletePermissionsInit = () => { return crudActions.deleteInit(); };
export const deletePermissionsStart = () => { return crudActions.deleteStart(); };
export const deletePermissionsSuccess = deletedPermissions => { return crudActions.deleteSuccess(deletedPermissions); };
export const deletePermissionsFail = error => { return crudActions.deleteFail(error); };
export const deletePermissions = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};


// Edit permission  action creators
export const editPermissionsInit = () => { return crudActions.editInit(); };
export const editPermissionsStart = () => { return crudActions.editStart(); };
export const editPermissionsSuccess = editedPermissions => { return crudActions.editSuccess(editedPermissions); };
export const editPermissionsFail = error => { return crudActions.editFail(error); };
export const editPermissions = permission => {
    return async dispatch => {
        await crudActions.edit(dispatch, permission);
    };
};

// Load permission action creators
export const loadPermissionsInit = () => { return crudActions.loadInit(); };
export const loadPermissionsStart = () => { return crudActions.loadStart(); };
export const loadPermissionsSuccess = loadedPermissions => { return crudActions.loadSuccess(loadedPermissions); };
export const loadPermissionsFail = error => { return crudActions.loadFail(error); };
export const loadPermission = id => {
    return async dispatch => {

        await crudActions.load(dispatch, id);
    };
};

export const exportPermission = (customerID) => {
    let token = localStorage.getItem('CORE|AUTH|TOKEN');
    if (token) {
        token = JSON.parse(token).token;
    }
    return async dispatch => {
        return fetch(`${config.backend_domain}:${config.backend_port}/permissions/export?ActiveCustomerID=${customerID.ActiveCustomerID}&Token=${token}`, {
            method: "GET",
        }).then(async response => {
            if (response.status === 200) {
                return response.blob();
            }
            return null;
        });
    }
}
export const uploadPermissionStart = () => {
    return {
        type: 'UPLOAD_PERMISSION_START',
    };
};
export const uploadPermissionSuccess = () => {
    return {
        type: 'UPLOAD_PERMISSION_SUCCESS',
    };
};
export const uploadPermissionFail = () => {
    return {
        type: 'UPLOAD_PERMISSION_FAIL',
    };
};


export const uploadPermissions = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            dispatch(uploadPermissionStart())
            let res = await rest.post('permissions/import', data);
            if (res.data.status === 200) {
                dispatch(uploadPermissionSuccess())
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data.message}`,
                        options: {
                            variant: 'success'
                        }
                    }))
                return res.data.status === 200;
            }
            else {
                dispatch(uploadPermissionFail());
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : res.message}`,
                        options: {
                            variant: 'warning'
                        }
                    }));
                return false;

            }
        } catch (error) {
            dispatch(uploadPermissionFail());
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return false;
        }
    }
}
