import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route } from 'react-router';

import * as actions from './store/userActions';
import Form from '../../np-form/Form';
import * as formUtility from '../../np-form/utility';

const UserGeneralEdit = props => {
    const { loaded, userID, onLoadUser, editedUser, onUserInit, onEditUser } = props;
    const [userForm, setUserForm] = useState({});
    const [formIsValid, setFormIsValid] = useState(false);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        onLoadUser({ UserID: userID });
    }, [onLoadUser, userID]);

    useEffect(() => {
        if (editedUser) {
            onUserInit();
            setRedirect(true);
        }

        return () => {
            onUserInit();
        };
    }, [editedUser, onUserInit]);

    useEffect(() => {
        if (loaded !== null) {
            setUserForm({
                firstName: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'First name',
                        label: 'First name',
                        fullWidth: true,
                        margin: 'dense'
                    },
                    value: loaded.FirstName,
                    validation: {
                        required: true
                    },
                    valid: true,
                    touched: false
                },
                lastName: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Last name',
                        label: 'Last name',
                        fullWidth: true,
                        margin: 'dense'
                    },
                    value: loaded.LastName,
                    validation: {
                        required: true
                    },
                    valid: true,
                    touched: false
                },
                username: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Username',
                        label: 'Username',
                        fullWidth: true,
                        margin: 'dense'
                    },
                    value: loaded.Username,
                    validation: {
                        required: true
                    },
                    valid: true,
                    touched: false
                },
                status: {
                    text: 'Status',
                    elementType: 'select',
                    elementConfig: {
                        options: [
                            { value: 'active', displayValue: 'Active' },
                            { value: 'inactive', displayValue: 'Inactive' },
                            { value: 'banned', displayValue: 'Banned' }
                        ]
                    },
                    value: loaded.Status === 0 ? 'inactive' : loaded.Status === 1 ? 'active' : 'banned',
                    validation: {},
                    valid: true
                },
                email: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'email',
                        placeholder: 'E-Mail',
                        label: 'E-Mail',
                        fullWidth: true,
                        margin: 'dense'
                    },
                    value: loaded.Email,
                    validation: {
                        required: true,
                        isEmail: true
                    },
                    valid: true,
                    touched: false
                }
            });

            setFormIsValid(false);
        }
    }, [loaded]);

    const submitHandler = async event => {
        event.preventDefault();
        // Prepare the form data
        const formData = formUtility.getFormData(userForm);

        const userData = {
            UserID: userID,
            FirstName: formData.firstName,
            LastName: formData.lastName,
            Username: formData.username,
            Email: formData.email,
            Status: formData.status === 'active' ? 1 : formData.status === 'inactive' ? 0 : -1,
        };

        await onEditUser(userData);
    };
    // Input change cancel handler
    const cancelHandler = () => {
        setRedirect(true);
    };

    // Input change handler
    const inputChangedHandler = (event, inputIdentifier) => {
        // Get default new state based on the input change event
        const updatedState = formUtility.getNewStateOnInputChange(event, inputIdentifier, userForm);
        // Set the new state
        setUserForm(updatedState.myForm);
        setFormIsValid(updatedState.formIsValid);
    };

    if (redirect) {
        return (
            <Route path="*" element={
                <Navigate
                    to={{
                        pathname: '/users',
                        state: {
                            page: props.page,
                            filter: props.filter,
                            update: false
                        }
                    }}
                />}
            />
        );
    }

    // Render form
    return (
        <div style={{ margin: '14px' }}>
            <Form
                inputChangedHandler={inputChangedHandler}
                submitHandler={submitHandler}
                form={userForm}
                formIsValid={formIsValid}
                buttonText={'Save'}
                buttonTextCancel={'Cancel'}
                cancelHandler={cancelHandler}
            />
        </div>
    );
};

const mapStateToProps = state => {
    return {
        loaded: state.core.user.loaded,
        editedUser: state.core.user.edited
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadUser: id => dispatch(actions.loadUser(id)),
        onEditUser: userData => dispatch(actions.editUser(userData)),
        onUserInit: () => dispatch(actions.editUserInit())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserGeneralEdit);
