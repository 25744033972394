import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { combineReducers } from 'redux';

// Reducers
import problemReportReducer from './ProblemReports/store/problemReportReducer';

// Components
import ProblemReports from './ProblemReports';

// Routes
export const routes = basePath =>
    (
        <Routes>
            <Route path={`${basePath}/*`} element={<ProblemReports/>} />
        </Routes>
    );

export const reducers = combineReducers({
    problemReports: problemReportReducer
});