export const ACCOUNT_RECOVER_START = 'BILLING_ACCOUNT|ACCOUNT_RECOVER_START';
export const ACCOUNT_RECOVER_SUCCESS = 'BILLING_ACCOUNT|ACCOUNT_RECOVER_SUCCESS';
export const ACCOUNT_RECOVER_FAIL = 'BILLING_ACCOUNT|ACCOUNT_RECOVER_FAIL';


export const ACCOUNT_SUSPEND_START = 'BILLING_ACCOUNT|ACCOUNT_SUSPEND_START';
export const ACCOUNT_SUSPEND_SUCCESS = 'BILLING_ACCOUNT|ACCOUNT_SUSPEND_SUCCESS';
export const ACCOUNT_SUSPEND_FAIL = 'BILLING_ACCOUNT|ACCOUNT_SUSPEND_FAIL';

export const CHARGING_DELIVERY_NOTE_START = 'CUSTOMER|DELIVERY_NOTE_CHARGING_START';
export const CHARGING_DELIVERY_NOTE_SUCCESS = 'CUSTOMER|DELIVERY_NOTE_CHARGING_SUCCESS';
export const CHARGING_DELIVERY_NOTE_FAIL = 'CUSTOMER|DELIVERY_NOTE_CHARGING_FAIL';
