import restCrudActions from '../../../np-crud/store/restCrudActions'

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: 'binary-image-type',
    pluginName: 'IMPORT_EXPORT',
    entityName: 'BINARY_IMAGE_TYPE',
    fetchSuccessMessage: '',
    loadSuccessMessage: '',
    createFailedUseBackendMessage: true,
    deleteFailedUseBackendMessage: true,
    editFailedUseBackendMessage: true,
    bulkDeleteFailedUseBackendMessage: true,
});

// Fetch binary Image action creators
export const fetchBinaryImageTypeInit = () => {
    return crudActions.fetchInit();
};
export const fetchBinaryImageTypeStart = () => {
    return crudActions.fetchStart();
};
export const fetchBinaryImageTypeSuccess = () => {
    return crudActions.fetchSuccess();
};
export const fetchBinaryImageTypeFail = () => {
    return crudActions.fetchFail();
};
export const fetchBinaryImageType = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
};

// Load binary Image action creators
export const loadBinaryImageTypeInit = () => {
    return crudActions.loadInit();
};
export const loadBinaryImageTypeStart = () => {
    return crudActions.loadStart();
};
export const loadBinaryImageTypeSuccess = loadedBinaryImageType => {
    return crudActions.loadSuccess(loadedBinaryImageType);
};
export const loadBinaryImageTypeFail = error => {
    return crudActions.loadFail(error);
};
export const loadBinaryImageType = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

// Create binary Image  action creators
export const createBinaryImageTypeInit = () => {
    return crudActions.createInit();
};
export const createBinaryImageTypeStart = () => {
    return crudActions.createStart();
};
export const createBinaryImageTypeSuccess = createdBinaryImageType => {
    return crudActions.createSuccess(createdBinaryImageType);
};
export const createBinaryImageTypeFail = error => {
    return crudActions.createFail(error);
};
export const createBinaryImageType = binaryImage => {
    return async dispatch => {
        await crudActions.create(dispatch, binaryImage);
    };
};

// Edit binary Image  action creators
export const editBinaryImageTypeInit = () => {
    return crudActions.editInit();
};
export const editBinaryImageTypeStart = () => {
    return crudActions.editStart();
};
export const editBinaryImageTypeSuccess = editedBinaryImageType => {
    return crudActions.editSuccess(editedBinaryImageType);
};
export const editBinaryImageTypeFail = error => {
    return crudActions.editFail(error);
};
export const editBinaryImageType = binaryImage => {
    return async dispatch => {
        await crudActions.edit(dispatch, binaryImage);
    };
};

// Bulk delete binary Image action creators
export const bulkDeleteBinaryImageTypesInit = () => {
    return crudActions.bulkDeleteInit();
};
export const bulkDeleteBinaryImageTypesStart = () => {
    return crudActions.bulkDeleteStart();
};
export const bulkDeleteBinaryImageTypesSuccess = bulkDeleted => {
    return crudActions.bulkDeleteSuccess(bulkDeleted);
};
export const bulkDeleteBinaryImageTypesFail = error => {
    return crudActions.bulkDeleteFail(error);
};
export const bulkDeleteBinaryImageTypes = binaryImageTypeID => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, binaryImageTypeID);
    };
};

// Delete binary Image  action creators
export const deleteBinaryImageTypeInit = () => {
    return crudActions.deleteInit();
};
export const deleteBinaryImageTypeStart = () => {
    return crudActions.deleteStart();
};
export const deleteBinaryImageTypeSuccess = deletedBinaryImageType => {
    return crudActions.deleteSuccess(deletedBinaryImageType);
};
export const deleteBinaryImageTypeFail = error => {
    return crudActions.deleteFail(error);
};
export const deleteBinaryImageType = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};