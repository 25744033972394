import config from "../../../config/config_default";
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';
import moment from 'moment';

const updateDownloadProgress = progress => {

    return {
        type: 'DOWNLOAD|DOWNLOAD_PROGRESS',
        downloadProgress: progress.downloadProgress,
        maxSize: progress.maxSize,
        currentSize: progress.currentSize,
        snackBar: progress.snackBar,
        data: progress,
        name: progress.name
    };
};

export const downloadFile = (ImageID, customerID, name) => {
    let token = localStorage.getItem('CORE|AUTH|TOKEN');
    if (token) {
        token = JSON.parse(token).token;
    }
    return async dispatch => {
        dispatch(
            enqueueSnackbar({
                message: `Download will start soon`,
                options: {
                    variant: 'info'
                }
            }));

        return fetch(`${config.backend_domain}:${config.backend_port}/binary-images/download?BinaryImageID=${ImageID}&ActiveCustomerID=${customerID.ActiveCustomerID}&Token=${token}`, {
            method: "GET",
        }).then(async response => {
            try {
                if (response.status === 200) {
                    const reader = response.body.getReader();
                    const contentLength = +response.headers.get('fileSize');
                    let receivedLength = 0;
                    let chunks = [];
                    let percentage = 0;
                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) {
                            dispatch(updateDownloadProgress({ downloadProgress: 0, maxSize: 0, currentSize: 0, snackBar: false, name: name !== undefined ? name : moment(), ImageID: ImageID }));

                            break;
                        }
                        chunks.push(value);

                        receivedLength += value.length;
                        if (percentage + 1 === Math.floor(receivedLength / contentLength * 100)) {
                            percentage += 1;
                            dispatch(updateDownloadProgress({ downloadProgress: Math.floor(receivedLength / contentLength * 100), maxSize: Math.floor(contentLength / 1048576), currentSize: Math.floor(receivedLength / 1048576), snackBar: true, name: name !== undefined ? name : moment(), ImageID: ImageID }));
                        }
                    }
                    dispatch(
                        enqueueSnackbar({
                            message: `Download success`,
                            options: {
                                variant: 'success'
                            }
                        }));
                    dispatch(updateDownloadProgress({ downloadProgress: 0, maxSize: 0, currentSize: 0, snackBar: false, name: name !== undefined ? name : moment(), ImageID: ImageID }));

                    return new Blob(chunks);
                }
                else {
                    dispatch(updateDownloadProgress({ downloadProgress: 0, maxSize: 0, currentSize: 0, snackBar: false, name: name !== undefined ? name : moment(), ImageID: ImageID }));
                    dispatch(
                        enqueueSnackbar({
                            message: `${response.data ? response.data.message : response.statusText}`,
                            options: {
                                variant: 'warning'
                            }
                        }));


                }
                return null;
            } catch (error) {
                dispatch(updateDownloadProgress({ downloadProgress: 0, maxSize: 0, currentSize: 0, snackBar: false, name: name !== undefined ? name : moment(), ImageID: ImageID }));
                dispatch(
                    enqueueSnackbar({
                        message: `${error && error.message ? error.message : 'Download failed '}`,
                        options: {
                            variant: 'error'
                        }
                    }));
            }
        });
    }
}
// Custom functions 
export const downloadFileTool = (ToolID, id, name) => {
    let token = localStorage.getItem('CORE|AUTH|TOKEN');
    if (token) {
        token = JSON.parse(token).token;
    }
    return async dispatch => {
        dispatch(
            enqueueSnackbar({
                message: `Download started`,
                options: {
                    variant: 'info'
                }
            }));
        return fetch(`${config.backend_domain}:${config.backend_port}/securaze-tools/download?SecurazeToolID=${ToolID}&ActiveCustomerID=${id}&Token=${token}`, {
            method: "GET",
        }).then(async response => {
            try {

                if (response.status === 200) {
                    const reader = response.body.getReader();

                    const contentLength = +response.headers.get('fileSize');
                    let receivedLength = 0;
                    let chunks = [];
                    while (true) {
                        const { done, value } = await reader.read();

                        if (done) {
                            break;
                        }
                        chunks.push(value);
                        receivedLength += value.length;

                        dispatch(updateDownloadProgress({ downloadProgress: Math.floor(receivedLength / contentLength * 100), maxSize: Math.floor(contentLength / 1048576), currentSize: Math.floor(receivedLength / 1048576), snackBar: true, name: name !== undefined ? name : moment(), ImageID: ToolID }));

                    }
                    dispatch(
                        enqueueSnackbar({
                            message: `Download success`,
                            options: {
                                variant: 'success'
                            }
                        }));
                    return new Blob(chunks);
                }
                return null;
            } catch (error) {
                dispatch(updateDownloadProgress({ downloadProgress: 0, maxSize: 0, currentSize: 0, snackBar: false, name: name !== undefined ? name : moment(), ImageID: ToolID }));
                dispatch(
                    enqueueSnackbar({
                        message: `${error && error.message ? error.message : 'Download failed '}`,
                        options: {
                            variant: 'error'
                        }
                    }));
            }
        });
    }
}