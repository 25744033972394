import React from 'react';
import { TextField, withStyles, Grow } from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';

const style = theme => ({
    main: {
        display: 'flex',
        flex: '1 0 auto'
    },
    searchIcon: {
        color: theme.palette.text.secondary,
        marginTop: '25px',
        marginRight: '8px'
    },
    clearIcon: {
        marginTop: '25px',
        marginRight: '8px',
        '&:hover': {
            color: theme.palette.error.main
        }
    }
});

const TableSearch = props => {
    const { handleTableSearch, handleSearchClose, classes } = props;

    const handleSearch = event => {
        handleTableSearch(event.target.value);
    };

    return (
        <Grow appear in={true} timeout={300}>
            <div className={classes.main}>
                <Search className={classes.searchIcon} />
                <TextField autoFocus={true} id="table-global-search" label="Search field" fullWidth onChange={handleSearch} className={classes.searchText} />
                <Clear className={classes.clearIcon} onClick={handleSearchClose} />
            </div>
        </Grow>
    );
};

export default withStyles(style)(TableSearch);
