import restCrudActions from '../../../np-crud/store/restCrudActions'

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: '/roles',
    pluginName: 'CORE',
    entityName: 'Role',
    fetchSuccessMessage: '',
    // fetchFailedMessage = 'Cannot fetch ' + entityName,
    // createSuccesMessage = entityName + ' created successfully',
    // createFailedMessage = 'Cannot create ' + entityName,
    // deleteSuccessMessage = entityName + ' deleted successfully',
    // deleteFailedMessage = 'Cannot delete ' + entityName,
    loadSuccessMessage: '',
    // loadFailedMessage = 'Cannot load ' + entityName,
    // editSucessMessage = entityName + ' edited successfully',
    // editFailedMessage = 'Cannot edit ' + entityName,
    // bulkDeleteSuccessMessage = entityName + ' deleted successfully',
    // bulkDeleteFailedMessage = 'Cannot bulk delete ' + entityName
});

// Fetch roles action creators
export const fetchRolesInit = () => { return crudActions.fetchInit(); }
export const fetchRolesStart = () => { return crudActions.fetchStart(); }
export const fetchRolesSuccess = () => { return crudActions.fetchSuccess(); }
export const fetchRolesFail = () => { return crudActions.fetchFail(); }
export const fetchRoles = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    }
}

// Create role action creators
export const createRoleInit = () => { return crudActions.createInit(); }
export const createRoleStart = () => { return crudActions.createStart(); }
export const createRoleSuccess = createdRole => { return crudActions.createSuccess(createdRole); }
export const createRoleFail = error => { return crudActions.createFail(error); }
export const createRole = role => {
    return async dispatch => {
        await crudActions.create(dispatch, role);
    }
}

// Delete role action creators
export const deleteRoleInit = () => { return crudActions.deleteInit(); }
export const deleteRoleStart = () => { return crudActions.deleteStart(); }
export const deleteRoleSuccess = deletedRole => { return crudActions.deleteSuccess(deletedRole); }
export const deleteRoleFail = error => { return crudActions.deleteFail(error); }
export const deleteRole = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    }
}

// Load role action creators
export const loadRoleInit = () => { return crudActions.loadInit(); }
export const loadRoleStart = () => { return crudActions.loadStart(); }
export const loadRoleSuccess = loadedRole => { return crudActions.loadSuccess(loadedRole); }
export const loadRoleFail = error => { return crudActions.loadFail(error); }
export const loadRole = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    }
}

// Edit role action creators
export const editRoleInit = () => { return crudActions.editInit(); }
export const editRoleStart = () => { return crudActions.editStart(); }
export const editRoleSuccess = editedRole => { return crudActions.editSuccess(editedRole); }
export const editRoleFail = error => { return crudActions.editFail(error); }
export const editRole = role => {
    return async dispatch => {
        await crudActions.edit(dispatch, role);
    }
}

// Bulk delete role action creators
export const bulkDeleteRolesInit = () => { return crudActions.bulkDeleteInit(); }
export const bulkDeleteRolesStart = () => { return crudActions.bulkDeleteStart(); }
export const bulkDeleteRolesSuccess = bulkDeleted => { return crudActions.bulkDeleteSuccess(bulkDeleted); }
export const bulkDeleteRolesFail = error => { return crudActions.bulkDeleteFail(error); }
export const bulkDeleteRoles = roleIds => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, roleIds);
    }
}