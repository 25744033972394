import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Paper, Tooltip, IconButton, Checkbox } from '@material-ui/core';
import { FindInPage, Delete } from '@material-ui/icons';
import useListFilter from '../../../np-components/Datatable/listFilter';
import NPDatatable from '../../../np-components/Datatable';
import { CustomChip } from '../../../ms-components';
import { isSuperUser } from '../../../np-utilities';
import { parseURL } from '../../../ms-utilities';

import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';


const styles = theme => ({
    userActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    userInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#d50000" : "#1b2a33",
        '&:hover': {
            backgroundColor: "#757575",
        },
    }
});
const updateFilterParams = props => {
    const { listFilter } = props;
    let searchParams = parseURL(window.location.search);
    return { ...listFilter, ...searchParams };
}

const UsersList = props => {
    const { classes, users, onFetchUsersList, columnsState, activeCustomer } = props;
    const usersCount = users.count;
    const usersList = users.list;
    let tableID = 'UserList';
    let visible = columnsState && columnsState[`${tableID}`];
    const navigate = useNavigate();


    const columns = [
        {
            displayName: 'Actions',
            backendName: 'Actions',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Actions'] : true,
            }
        },
        {
            displayName: 'First name',
            backendName: 'FirstName',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['FirstName'] : true,
            }
        },
        {
            displayName: 'Last name',
            backendName: 'LastName',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['LastName'] : true,
            }
        },
        {
            displayName: 'Username',
            backendName: 'Username',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Username'] : true,
            }
        },
        {
            displayName: 'Email',
            backendName: 'Email',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Email'] : true,
            }
        },
        {
            displayName: 'Role',
            backendName: 'Role',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Role'] : true,
            }
        },
        {
            displayName: 'Status',
            backendName: 'Status',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Status'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => { if (column.backendName !== 'Action') backendNames.push(column.backendName); return null; })
    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });
    if (!props.hasAtLeastOnePermission(['user_load', 'user_delete'])) {
        columns.splice(0, 1);
    }
    const [redirectOptions, setRedirectOptions] = useState({ redirect: false, productID: 0, redirectPath: '' });

    const detailsUser = async (userID) => {
        setRedirectOptions({ redirect: true, userID: userID, redirectPath: `/users/details?userID=${userID}` });
        navigate(redirectOptions.redirectPath)
    };
    const createActions = (userID) => {
        return <>
            {
                props.hasPermission('user_load') ?
                    <Tooltip title="Edit details ">
                        <IconButton value={userID} onClick={() => detailsUser(userID)} aria-label="Edit details"  >
                            <FindInPage />
                        </IconButton>
                    </Tooltip>
                    : null
            }
            {
                props.hasPermission('user_load') ?

                    <Tooltip title="Delete user ">
                        <IconButton value={userID} aria-label="Delete user" onClick={props.handleDelete} >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    : null
            }
        </>
    };
    const [userDeleted, setUserDeleted] = useState(false);

    useEffect(() => {
        // fetch calls
        if (listFilter.filter) {
            if (!listFilter.filter.q) {
                listFilter.filter.q = {};
            }
            if (!listFilter.filter.q.and) {
                listFilter.filter.q.and = {};
            }

            listFilter.filter.q = { ...listFilter.filter.q, and: updateFilterParams({ listFilter: listFilter.filter.q.and }) };
            onFetchUsersList({ ...listFilter.filter, showDeleted: userDeleted, ActiveCustomerID: activeCustomer });
        }
    }, [users.created, users.deleted, users.bulkDeleted, users.edited, onFetchUsersList, listFilter.filter, activeCustomer, userDeleted]);

    const customFilterToolbar = () => {
        return <>
            <Checkbox checked={userDeleted} value={'showDeleted'} onChange={() => setUserDeleted(!userDeleted)} /> {'Show deleted'}
        </>
    };

    const tableOptions = {
        isSelectable: true,
        tableSearch: false,
        tableID: tableID,
        saveColumn: true,
        page: 0,
        selectOptions: { printing: false, generatingReport: false, creating: props.hasPermission('user_create') ? true : false, deleting: props.hasPermission('user_delete') ? true : false },
        rowsPerPage: 20,
        count: usersCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'Users',
        noMatch: "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return props.handleBulkDelete(IDs);
        },
        onSaveFilter: (columns) => {
            let objToSendColumn = {}
            let objColumns = {}
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible
                return objColumns
            })
            objToSendColumn[`${tableID}`] = objColumns
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }

    };


    let counter = 0;
    let usersData = usersList.map(user => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = user.UserID
        returnObj.visible = true;
        returnObj.values = [
            createActions(user.UserID), user.FirstName, user.LastName, user.Username, user.Email, user.Roles && user.Roles[0] ? user.Roles[0].RoleName : '',
            <CustomChip key='userChipStatus' className={Number(user.Status) === 1 ? classes.userActive : classes.userInactive} value={Number(user.Status) === 1 ? 'Active' : 'Inactive'} />
        ]
        if (!props.hasAtLeastOnePermission(['user_load', 'user_delete'])) {
            returnObj.values.splice(0, 1);
        }
        counter++;
        return returnObj;
    });
    const handleCreate = () => {
        setRedirectOptions({ redirect: true, redirectPath: `/users/create` });
        navigate(redirectOptions.redirectPath);
    };
    if (redirectOptions.redirect) {
        return <Routes><Route path="*" element={<Navigate to={redirectOptions.redirectPath} />} /></Routes>;
    }
    return (
        <Paper>
            <NPDatatable customFilterToolbar={customFilterToolbar} handleCreate={handleCreate} customStyles={classes} data={usersData} columns={columns} options={tableOptions} />
        </Paper>
    );
}

export default (withStyles(styles)(UsersList));