import restCrudActions from '../../../np-crud/store/restCrudActions'
import { getAxiosInstance } from '../../../np-rest/rest';
import { enqueueSnackbar } from '../../../np-dashboard/Notifier/store/notifierActions';
import * as actionTypes from './actionTypes';

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: 'users',
    pluginName: 'USER',
    entityName: 'USER',
    fetchSuccessMessage: '',
    loadSuccessMessage: '',
    createFailedUseBackendMessage: true
});

// Fetch User action creators
export const fetchUserInit = () => { return crudActions.fetchInit(); }
export const fetchUserStart = () => { return crudActions.fetchStart(); }
export const fetchUserSuccess = () => { return crudActions.fetchSuccess(); }
export const fetchUserFail = () => { return crudActions.fetchFail(); }
export const fetchUsers = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    }

}// Create User  action creators
export const createUserInit = () => { return crudActions.createInit(); };
export const createUserStart = () => { return crudActions.createStart(); };
export const createUserSuccess = createdUser => { return crudActions.createSuccess(createdUser); };
export const createUserFail = error => { return crudActions.createFail(error); };
export const createUser = user => {
    return async dispatch => {
        await crudActions.create(dispatch, user);
    };
};

// Delete User  action creators

export const bulkDeleteUserInit = () => { return crudActions.bulkDeleteInit(); };
export const bulkDeleteUserStart = () => { return crudActions.bulkDeleteStart(); };
export const bulkDeleteUserSuccess = bulkDeleted => { return crudActions.bulkDeleteSuccess(bulkDeleted); };
export const bulkDeleteUserFail = error => { return crudActions.bulkDeleteFail(error); };
export const bulkDeleteUser = userIDs => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, userIDs);
    };
};

// Delete User  action creators
export const deleteUserInit = () => { return crudActions.deleteInit(); };
export const deleteUserStart = () => { return crudActions.deleteStart(); };
export const deleteUserSuccess = deletedUser => { return crudActions.deleteSuccess(deletedUser); };
export const deleteUserFail = error => { return crudActions.deleteFail(error); };
export const deleteUser = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};


// Edit user  action creators
export const editUserInit = () => { return crudActions.editInit(); };
export const editUserStart = () => { return crudActions.editStart(); };
export const editUserSuccess = editedUser => { return crudActions.editSuccess(editedUser); };
export const editUserFail = error => { return crudActions.editFail(error); };
export const editUser = user => {
    return async dispatch => {
        await crudActions.edit(dispatch, user);
    };
};

// Load user action creators
export const loadUserInit = () => { return crudActions.loadInit(); };
export const loadUserStart = () => { return crudActions.loadStart(); };
export const loadUserSuccess = loadedUser => { return crudActions.loadSuccess(loadedUser); };
export const loadUserFail = error => { return crudActions.loadFail(error); };
export const loadUser = id => {
    return async dispatch => {

        await crudActions.load(dispatch, id);
    };
};


export const loadUserReturnData = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('users/load', data);
            if (res.status === 200) {
                return res.data.result.data;
            }
            else {
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data ? res.data.message : res.message}`,
                        options: {
                            variant: 'warning'
                        }
                    }));
                return {};
            }
        } catch (error) {
            dispatch(
                enqueueSnackbar({
                    message: `${error && error.message ? error.message : 'Cannot load  user'}`,
                    options: {
                        variant: 'error'
                    }
                }));
            return {};
        }
    }
}


// custom functions
export const loadRoles = (data) => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/roles/list', { forList: true, ...data });
            if (res.data && res.data.succeeded) {
                const roles = [];
                for (var role of res.data.result.data) {
                    roles.push({ value: role.RoleID, displayValue: `${role.Name}` });
                }
                return roles;
            }
            else {

                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'error'
                    }
                }));
                return [];

            }
        } catch (error) {
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return [];
        }
    };
}

export const fetchPermission = (id) => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/permissions/list', { forList: true, ...id });
            if (res.data && res.data.succeeded) {
                let permissions = [];

                for (var permission of res.data.result.data) {
                    permissions.push({ value: permission.PermissionID, label: `${permission.Name}` });
                }
                return permissions;
            }
            else {

                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'error'
                    }
                }));
                return [];

            }
        } catch (error) {
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return [];
        }
    };
}

export const fetchCustomers = (id) => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/customers/list', { forList: true, ...id });
            if (res.data && res.data.succeeded) {
                let customers = [];

                for (var cs of res.data.result.data) {
                    customers.push({ value: cs.CustomerID, label: `${cs.Name}` });
                }
                return customers;
            }
            else {

                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'error'
                    }
                }));
                return [];

            }
        } catch (error) {
            dispatch(enqueueSnackbar({
                message: `${error.message}`,
                options: {
                    variant: 'error'
                }
            }));
            return [];
        }
    };
}


export const newPasswordStart = () => {
    return {
        type: 'USER|USER_PASSWORD_CHANGE_START',
    };
};
export const newPasswordSuccess = () => {
    return {
        type: 'USER|USER_PASSWORD_CHANGE_SUCCESS',
        password: null,
    };
};
export const newPasswordFail = () => {
    return {
        type: 'USER|USER_PASSWORD_CHANGE_FAIL'
    };
};


export const setNewPassword = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/users/change-password', data);
            dispatch(newPasswordStart())
            if (res.data.status === 200) {
                dispatch(newPasswordSuccess())
                dispatch(enqueueSnackbar({
                    message: `${res && res.data && res.data.message ? res.data.message : "User  change password"}`,
                    options: {
                        variant: 'success'
                    }

                }));
                return res.data.status === 200;
            } else {
                dispatch(newPasswordFail());

                dispatch(enqueueSnackbar({
                    message: `${res.data && res.data.message ? res.data.message : res.message ? res.message : 'Can`not set new password'}`,
                    options: {
                        variant: 'error'
                    }
                }));
                return false;

            }
        } catch (error) {
            dispatch(newPasswordFail());

            dispatch(enqueueSnackbar({
                message: `${error && error.message ? error.message : "User can't change password"}`,
                options: {
                    variant: 'error'
                }
            }));
            return false;
        }
    };
}

//Roles and permissions
const fetchUserRolesStart = () => {
    return {
        type: actionTypes.FETCH_USER_ROLES_START
    };
};

const fetchUserRolesSuccess = userRoles => {
    return {
        type: actionTypes.FETCH_USER_ROLES_SUCCESS,
        userRoles: userRoles
    };
};

const fetchUserRolesFail = error => {
    return {
        type: actionTypes.FETCH_USER_ROLES_FAIL,
        error: error
    };
};

//Bulk save user roles and permissions
const bulkSaveUserRolesStart = () => {
    return {
        type: actionTypes.BULK_SAVE_USER_ROLES_START
    };
};

const bulkSaveUserRolesSuccess = savedRoles => {
    return {
        type: actionTypes.BULK_SAVE_USER_ROLES_FAIL,
        savedRoles: savedRoles
    };
};

const bulkSaveUserRolesFail = error => {
    return {
        type: actionTypes.BULK_SAVE_USER_ROLES_FAIL,
        error: error
    };
};


export const fetchUserRoles = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(fetchUserRolesStart());
        try {
            let res = await rest.post('/users/roles/list', data);
            if (res.data.succeeded) {
                const userRoles = res.data.result.data;
                dispatch(fetchUserRolesSuccess(userRoles));
                return userRoles;
            } else {
                dispatch(fetchUserRolesFail(res.error));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.message ? res.message : ''}`,
                        options: {
                            variant: 'error'
                        }
                    })
                );
            }

        } catch (err) {
            dispatch(fetchUserRolesFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};

export const bulkSaveUserRoles = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(bulkSaveUserRolesStart());
        try {
            let res = await rest.post('/users/roles/bulk_save', data);
            if (res.data.succeeded) {
                const savedRoles = res.data.result.data;
                dispatch(bulkSaveUserRolesSuccess(savedRoles));
                dispatch(
                    enqueueSnackbar({
                        message: 'User roles saved successfully!',
                        options: {
                            variant: 'success'
                        }
                    })
                );
            } else {
                dispatch(bulkSaveUserRolesFail(res.error));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.message ? res.message : ''}`,
                        options: {
                            variant: 'error'
                        }
                    })
                );
            }

        } catch (err) {
            dispatch(bulkSaveUserRolesFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};


//Fetch included permissions
const fetchIncludedPermissionsStart = () => {
    return {
        type: actionTypes.FETCH_INCLUDED_PERMISSIONS_START
    };
};

const fetchIncludedPermissionsSuccess = includedPermissions => {
    return {
        type: actionTypes.FETCH_INCLUDED_PERMISSIONS_SUCCESS,
        includedPermissions: includedPermissions
    };
};

const fetchIncludedPermissionsFail = error => {
    return {
        type: actionTypes.FETCH_INCLUDED_PERMISSIONS_FAIL,
        error: error
    };
};

export const fetchIncludedPermissions = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(fetchIncludedPermissionsStart());
        try {
            let res = await rest.post('/users/included_permissions/list', data);
            if (res.data.succeeded) {
                const includedPermissions = res.data.result.data;
                dispatch(fetchIncludedPermissionsSuccess(includedPermissions));
            } else {
                dispatch(fetchIncludedPermissionsFail(res.error));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.message ? res.message : ''}`,
                        options: {
                            variant: 'error'
                        }
                    })
                );
            }

        } catch (err) {
            dispatch(fetchIncludedPermissionsFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};

//Saving included permissions
const bulkSaveIncludedPermissionsStart = () => {
    return {
        type: actionTypes.BULK_SAVE_INCLUDED_PERMISSIONS_START
    };
};

const bulkSaveIncludedPermissionsSuccess = savedIncluded => {
    return {
        type: actionTypes.BULK_SAVE_INCLUDED_PERMISSIONS_SUCCESS,
        savedIncluded: savedIncluded
    };
};

const bulkSaveIncludedPermissionsFail = error => {
    return {
        type: actionTypes.BULK_SAVE_INCLUDED_PERMISSIONS_FAIL,
        error: error
    };
};

export const saveIncludedPermissions = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(bulkSaveIncludedPermissionsStart());
        try {
            let res = await rest.post('/users/included_permissions/bulk_save', data);

            if (res.data.succeeded) {
                const savedIncluded = res.data.result.data;
                dispatch(bulkSaveIncludedPermissionsSuccess(savedIncluded));
                dispatch(
                    enqueueSnackbar({
                        message: 'User included permissions saved successfully!',
                        options: {
                            variant: 'success'
                        }
                    })
                );
            } else {
                dispatch(bulkSaveIncludedPermissionsFail(res.error));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.message ? res.message : ''}`,
                        options: {
                            variant: 'error'
                        }
                    })
                );
            }
        } catch (err) {
            dispatch(bulkSaveIncludedPermissionsFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};

//Fetch excluded permissions
const fetchExcludedPermissionsStart = () => {
    return {
        type: actionTypes.FETCH_EXCLUDED_PERMISSIONS_START
    };
};

const fetchExcludedPermissionsSuccess = excludedPermissions => {
    return {
        type: actionTypes.FETCH_EXCLUDED_PERMISSIONS_SUCCESS,
        excludedPermissions: excludedPermissions
    };
};

const fetchExcludedPermissionsFail = error => {
    return {
        type: actionTypes.FETCH_EXCLUDED_PERMISSIONS_FAIL,
        error: error
    };
};

export const fetchExcludedPermissions = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(fetchExcludedPermissionsStart());
        try {
            let res = await rest.post('/users/excluded_permissions/list', data);

            if (res.data.succeeded) {
                const excludedPermissions = res.data.result.data;
                dispatch(fetchExcludedPermissionsSuccess(excludedPermissions));
            } else {
                dispatch(fetchExcludedPermissionsFail(res.error));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.message ? res.message : ''}`,
                        options: {
                            variant: 'error'
                        }
                    })
                );
            }
        } catch (err) {
            dispatch(fetchExcludedPermissionsFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};

//Saving excluded permissions
const bulkSaveExcludedPermissionsStart = () => {
    return {
        type: actionTypes.BULK_SAVE_EXCLUDED_PERMISSIONS_START
    };
};

const bulkSaveExcludedPermissionsSuccess = savedIncluded => {
    return {
        type: actionTypes.BULK_SAVE_EXCLUDED_PERMISSIONS_SUCCESS,
        savedIncluded: savedIncluded
    };
};

const bulkSaveExcludedPermissionsFail = error => {
    return {
        type: actionTypes.BULK_SAVE_EXCLUDED_PERMISSIONS_FAIL,
        error: error
    };
};

export const saveExcludedPermissions = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(bulkSaveExcludedPermissionsStart());
        try {
            let res = await rest.post('/users/excluded_permissions/bulk_save', data);

            if (res.data.succeeded) {
                const savedExcluded = res.data.result.data;
                dispatch(bulkSaveExcludedPermissionsSuccess(savedExcluded));
                dispatch(
                    enqueueSnackbar({
                        message: 'User excluded permissions saved successfully!',
                        options: {
                            variant: 'success'
                        }
                    })
                );
            } else {
                dispatch(bulkSaveExcludedPermissionsFail(res.error));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.message ? res.message : ''}`,
                        options: {
                            variant: 'error'
                        }
                    })
                );
            }
        } catch (err) {
            dispatch(bulkSaveExcludedPermissionsFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};

//Fetch all user permissions
const fetchAllUserPermissionsStart = () => {
    return {
        type: actionTypes.FETCH_ALL_USER_PERMISSIONS_START
    };
};

const fetchAllUserPermissionsSuccess = allUserPermissions => {
    return {
        type: actionTypes.FETCH_ALL_USER_PERMISSIONS_SUCCESS,
        allUserPermissions: allUserPermissions
    };
};

const fetchAllUserPermissionsFail = error => {
    return {
        type: actionTypes.FETCH_ALL_USER_PERMISSIONS_FAIL,
        error: error
    };
};

export const fetchAllUserPermissions = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(fetchAllUserPermissionsStart());
        try {
            let res = await rest.post('/users/permissions/list', data);

            if (res.data.succeeded) {
                const allUserPermissions = res.data.result.data;
                dispatch(fetchAllUserPermissionsSuccess(allUserPermissions));
            } else {
                dispatch(fetchAllUserPermissionsFail(res.error));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.message ? res.message : ''}`,
                        options: {
                            variant: 'error'
                        }
                    })
                );
            }
        } catch (err) {
            dispatch(fetchAllUserPermissionsFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};


//Fetch all user permissions
const fetchUserSettingsStart = () => {
    return {
        type: actionTypes.FETCH_USER_SETTINGS_START
    };
};

const fetchUserSettingsSuccess = allUserPermissions => {
    return {
        type: actionTypes.FETCH_USER_SETTINGS_SUCCESS,
        allUserPermissions: allUserPermissions
    };
};

const fetchUserSettingsFail = error => {
    return {
        type: actionTypes.FETCH_USER_SETTINGS_FAIL,
        error: error
    };
};

export const fetchUserSettings = (id) => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(fetchUserSettingsStart());
        try {
            let res = await rest.post('users/load-settings', id);

            if (res.data.succeeded) {
                const userSetting = res.data.result.data;
                dispatch(fetchUserSettingsSuccess(userSetting));
            } else {
                dispatch(fetchUserSettingsFail(res.error));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.message ? res.message : ''}`,
                        options: {
                            variant: 'error'
                        }
                    })
                );
            }
        } catch (err) {
            dispatch(fetchUserSettingsFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};



export const userPermissionUpdate = () => {
    return {
        type: 'USER|USER_PERMISSION_CHANGE_START',
    };
};
export const userPermissionSuccess = () => {
    return {
        type: 'USER|USER_PERMISSION_CHANGE_SUCCESS',

    };
};
export const userPermissionFail = () => {
    return {
        type: 'USER|USER_PERMISSION_CHANGE_FAIL'
    };
};

export const updatePermission = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        try {
            let res = await rest.post('/users/update-permissions', data);
            dispatch(userPermissionUpdate())
            if (res.data.status === 200) {
                dispatch(userPermissionSuccess())
                dispatch(
                    enqueueSnackbar({
                        message: 'Update permissions saved successfully!',
                        options: {
                            variant: 'success'
                        }
                    }))
                return res.data.status === 200;
            }
            else {
                dispatch(userPermissionFail());
                dispatch(enqueueSnackbar({
                    message: `${res.data ? res.data.message : res.message}`,
                    options: {
                        variant: 'error'
                    }
                }));
                return false;
            }
        } catch (error) {
            dispatch(userPermissionFail());
            dispatch(enqueueSnackbar({
                message: `${error && error.message ? error.message : "User can't change permission "}`,
                options: {
                    variant: 'error'
                }
            }));
            return false;
        }
    };
}


//Saving included permissions
const saveCustomersStart = () => {
    return {
        type: "USER|CUSTOMERS_SAVE_START"
    };
};

const saveCustomersSuccess = savedIncluded => {
    return {
        type: "USER|CUSTOMERS_SAVE_SUCCESS",
    };
};

const saveCustomersFail = error => {
    return {
        type: "USER|CUSTOMERS_SAVE_FAIL"

    };
};

export const saveCustomers = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(saveCustomersStart());
        try {
            let res = await rest.post('/users/update-customers', data);

            if (res.data.succeeded) {
                const savedIncluded = res.data.result.data;
                dispatch(saveCustomersSuccess(savedIncluded));
                dispatch(
                    enqueueSnackbar({
                        message: 'User customers  saved successfully!',
                        options: {
                            variant: 'success'
                        }
                    })
                );
            } else {
                dispatch(saveCustomersFail(res.error));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.message ? res.message : ''}`,
                        options: {
                            variant: 'error'
                        }
                    })
                );
            }
        } catch (err) {
            dispatch(saveCustomersFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};