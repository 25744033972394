import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import NPDatatable from '../../np-components/Datatable';
import { Paper, Tooltip, IconButton, Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import useListFilter from '../../np-components/Datatable/listFilter';
import { Edit, Delete, CloudDownload, CloudUpload } from '@material-ui/icons';
import { isSuperUser } from '../../np-utilities';
import { parseURL, BackButton } from '../../ms-utilities';
import Form from '../../np-form/Form';
import * as formUtility from '../../np-form/utility';

const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#d50000" : "#1b2a33",
        '&:hover': {
            backgroundColor: "#757575",
        },
    },
    chipScoreCriteriaActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    chipScoreCriteriaInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    media: {
        width: 50,
        height: 50
    }
});

const updateFilterParams = props => {
    const { listFilter } = props;
    let searchParams = parseURL(window.location.search);
    return { ...listFilter, ...searchParams };
};

const exportForm = props => {
    const { sites } = props;

    const form = {
        SiteKey: {
            elementType: 'select_v2',
            elementConfig: {
                fullWidth: true,
                options: [...sites]
            },
            value: sites && sites[0] ? sites[0].value : null,
            validation: {},
            valid: true,
            formHelperText: 'Please select site'
        }
    };
    return form;
};

const updateFormState = props => {
    const { fileName, handleFileUpload, sites, siteID } = props;
    const form = {
        SiteKey: {
            elementType: 'select_v2',
            elementConfig: {
                fullWidth: true,
                options: [...sites]
            },
            value: siteID ? siteID : sites && sites[0] ? sites[0].value : null,
            validation: {},
            valid: true,
            formHelperText: 'Please select site'
        },
        ScoreCriteria: {
            elementType: 'file',
            elementConfig: {
                ID: "dependentSetting",
                accept: '.xlsx',
                style: {},
                type: 'file',
                handleUpload: handleFileUpload,
                fileName: fileName,
                text: 'Choose file'

            },
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
    }
    return form;
}

const ScoreCriteriaList = props => {
    const { classes, scoreCriterias, onFetchScoreCriterias, columnsState, activeCustomer, loadSites } = props;
    const scoreCriteriasCount = scoreCriterias.count;
    const scoreCriteriasList = scoreCriterias.list;

    let tableID = 'ScoreCriteria';
    let visible = columnsState && columnsState[tableID];

    const [uploadForm, setUploadForm] = useState(false);
    const [exportFormOpen, setExportForm] = useState(false);

    const [eForm, setEForm] = useState(exportForm({ sites: [] }));

    const handleUploadOpen = async () => {
        if (uploadForm === false) {
            let sites = await loadSites(activeCustomer);
            setForm(updateFormState({ handleFileUpload, fileName, sites: sites }));
        }
        setUploadForm(!uploadForm);
    }

    const handleDownloadOpen = async () => {
        if (exportFormOpen === false) {
            // read sites here
            let sites = await loadSites(activeCustomer);
            setEForm(exportForm({ sites: sites }));
        }
        setExportForm(!exportFormOpen);
    }

    const downloadInputChangeHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, eForm);
        setEForm(updateState.myForm);
    };

    const [fileToSend, setFileToSend] = useState({});
    const [fileName, setFilename] = useState('');

    const handleFileUpload = event => {
        event.preventDefault();
        let file = event.target.files[0];
        if (!file) {
            file = {};
        }
        setFileToSend(file);
        setFilename(file.name);
    }


    const customFilterToolbar = () => {
        return <>
            {props.hasPermission('score_criteria_export') ?
                <Tooltip title={"Download score criteria (XLSX)"}>
                    <IconButton aria-label={"Download score criteria (XLSX)"} value='xlsx'
                        onClick={handleDownloadOpen}>
                        <CloudDownload />
                    </IconButton>
                </Tooltip>
                : null}
            {props.hasPermission('score_criteria_import') ?
                <Tooltip title={"Upload score criteria"}>
                    <IconButton aria-label={"Upload score criteria"} value='xlsx' onClick={handleUploadOpen}>
                        <CloudUpload />
                    </IconButton>
                </Tooltip>
                : null}
        </>
    };

    const [form, setForm] = useState(updateFormState({ handleFileUpload, fileName, sites: [] }));
    const importInputChangeHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, form);
        setForm(updateState.myForm);
    };
    useEffect(() => {
        // read sites here
        const fetchData = async () => {
            const formData = formUtility.getFormData(form);

            let sites = await loadSites(activeCustomer);
            setForm(updateFormState({ handleFileUpload, fileName, sites: sites, siteID: formData.SiteKey }));
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileName]);

    const submitHandler = async event => {
        event.preventDefault();
        if (!fileName || !fileName.includes('.xlsx')) {
            return props.sendMessage('Please choose xlsx file.', 'warning')
        }
        setFileToSend({});
        setFilename('');

        const formData = formUtility.getFormData(form);

        const data = new FormData();
        data.append('File', fileToSend);
        data.append('Type', fileToSend.type);
        data.append('ActiveCustomerID', activeCustomer);
        data.append('SiteKey', formData.SiteKey);

        let token = localStorage.getItem('CORE|AUTH|TOKEN');
        if (token) {
            token = JSON.parse(token).token;
        }
        data.append('Token', token);
        await props.uploadScoreCriteria(data);
        await handleUploadOpen();
    }

    const downloadSubmitHandler = async event => {
        event.preventDefault();
        const formData = formUtility.getFormData(eForm);
        await props.handleDownload(formData.SiteKey);
        await handleDownloadOpen();
    }

    const columns = [
        {
            displayName: 'Action',
            backendName: 'Action',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: 'Action GUID',
            backendName: 'ActionGuid',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['ActionGuid'] : true,
            }
        },
        {
            displayName: 'Correction penalty',
            backendName: 'CorrectionPenalty',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['CorrectionPenalty'] : true,
            }
        },
        {
            displayName: 'Max Correction penalty',
            backendName: 'MaxCorrectionPenalty',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['MaxCorrectionPenalty'] : true,
            }
        },
        {
            displayName: 'Site',
            backendName: 'Site',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Site'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => {
        if (column.backendName !== 'Action') backendNames.push(column.backendName);
        return null;
    });
    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });

    if (!props.hasAtLeastOnePermission(['score_criteria_create', 'score_criteria_load', 'score_criteria_save', 'score_criteria_delete'])) {
        columns.splice(0, 1);
    }

    useEffect(() => {
        if (listFilter.filter) {
            if (!listFilter.filter.q) {
                listFilter.filter.q = {};
            }
            if (!listFilter.filter.q.and) {
                listFilter.filter.q.and = {};
            }
            listFilter.filter.q = {
                ...listFilter.filter.q,
                and: updateFilterParams({ listFilter: listFilter.filter.q.and })
            };
            onFetchScoreCriterias({ ...listFilter.filter, ActiveCustomerID: activeCustomer });
        }
    }, [scoreCriterias.created, activeCustomer, scoreCriterias.deleted, scoreCriterias.bulkDeleted, scoreCriterias.edited, listFilter.filter, onFetchScoreCriterias]);


    const createActions = scoreCriteriaID => {
        return <>
            {
                props.hasPermission('score_criteria_load') ? <Tooltip title="Edit Score Criteria">
                    <IconButton value={scoreCriteriaID} aria-label="Edit Score Criteria" onClick={props.handleEdit}>
                        <Edit />
                    </IconButton>
                </Tooltip> : null
            }
            {
                props.hasPermission('score_criteria_delete') ?
                    <Tooltip title="Delete Score Criteria">
                        <IconButton value={scoreCriteriaID} aria-label="Delete Score Criteria" onClick={props.handleDelete}>
                            <Delete />
                        </IconButton>
                    </Tooltip> : null
            }
        </>
    };


    const tableOptions = {
        isSelectable: props.hasPermission('score_criteria_delete'),
        tableSearch: false,
        tableID: tableID,
        saveColumn: true,
        selectOptions: {
            printing: false,
            generatingReport: false,
            deleting: props.hasPermission('score_criteria_delete'),
            creating: props.hasPermission('score_criteria_create')
        },
        rowsPerPage: 20,
        count: scoreCriteriasCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'Score criteria',
        noMatch: "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return props.handleBulkDelete(IDs);
        },
        onSaveFilter: (columns) => {
            let objToSendColumn = {};
            let objColumns = {};
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible;
                return objColumns
            });
            objToSendColumn[`${tableID}`] = objColumns;
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }
    };


    let counter = 0;
    let scoreCriteriasData = scoreCriteriasList.map(scoreCriteria => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = scoreCriteria.ScoreCriteriaID;
        returnObj.visible = true;
        returnObj.values = [
            createActions(scoreCriteria.ScoreCriteriaID),
            scoreCriteria.ActionGuid ? scoreCriteria.ActionGuid : '-',
            scoreCriteria.CorrectionPenalty,
            scoreCriteria.MaxCorrectionPenalty,
            scoreCriteria.Site ? `${scoreCriteria.Site.Name} (${scoreCriteria.Site.Key})` : ''
        ];
        if (!props.hasAtLeastOnePermission(['score_criteria_create', 'score_criteria_load', 'score_criteria_delete', 'score_criteria_delete'])) {
            returnObj.values.splice(0, 1);
        }
        counter++;
        return returnObj;
    });

    return (
        <Paper>
            {BackButton()}
            <NPDatatable handleCreate={props.handleCreate} customStyles={classes} data={scoreCriteriasData} columns={columns}
                customFilterToolbar={customFilterToolbar} options={tableOptions} />
            <Dialog
                open={uploadForm}
                // onClose={handleUploadOpen}
                aria-labelledby="uploadScoreCriteria"
                // disableBackdropClick={false}
                disableEscapeKeyDown={false}
                disableEnforceFocus={true}
                fullWidth={true}
                onClose={(_, reason) => {
                    if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        handleUploadOpen();
                    }
                }}
            >
                <DialogContent>
                    <DialogContentText>
                        {'Supported format is XLSX'}.<br />
                    </DialogContentText>
                    <Form
                        formIsValid={true}
                        classes={null}
                        inputChangedHandler={importInputChangeHandler}
                        form={form}
                        formTitle={"Import score criteria"}
                        buttonText={"Import"}
                        cancelHandler={handleUploadOpen}
                        submitHandler={submitHandler}
                        submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={exportFormOpen}
                // onClose={handleDownloadOpen}
                aria-labelledby="downloadScoreCriteria"
                // disableBackdropClick={false}
                disableEscapeKeyDown={false}
                disableEnforceFocus={true}
                fullWidth={true}
                onClose={(_, reason) => {
                    if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        handleDownloadOpen();
                    }
                }}
            >
                <DialogContent>
                    <Form
                        formIsValid={true}
                        classes={null}
                        form={eForm}
                        formTitle={"Choose site"}
                        buttonText={"Submit"}
                        cancelHandler={handleDownloadOpen}
                        submitHandler={downloadSubmitHandler}
                        submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                        inputChangedHandler={downloadInputChangeHandler}
                    />
                </DialogContent>
            </Dialog>
        </Paper>
    );
}

export default withStyles(styles)(ScoreCriteriaList);