import * as actionTypes from './actionTypes';
import { enqueueSnackbar } from '../../../np-dashboard/Notifier/store/notifierActions';
import { getAxiosInstance } from '../../../np-rest/rest';

import restCrudActions from '../../../np-crud/store/restCrudActions';

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: '/users',
    pluginName: 'CORE',
    entityName: 'User',
    fetchSuccessMessage: '',
    // fetchFailedMessage = 'Cannot fetch ' + entityName,
    // createSuccesMessage = entityName + ' created successfully',
    // createFailedMessage = 'Cannot create ' + entityName,
    // deleteSuccessMessage = entityName + ' deleted successfully',
    // deleteFailedMessage = 'Cannot delete ' + entityName,
    loadSuccessMessage: '',
    // loadFailedMessage = 'Cannot load ' + entityName,
    // editSucessMessage = entityName + ' edited successfully',
    // editFailedMessage = 'Cannot edit ' + entityName,
    // bulkDeleteSuccessMessage = entityName + ' deleted successfully',
    // bulkDeleteFailedMessage = 'Cannot bulk delete ' + entityName
    createFailedUseBackendMessage: true,
    editFailedUseBackendMessage: true
});

// Fetch users action creators
export const fetchUsersInit = () => {
    return crudActions.fetchInit();
};
export const fetchUsersStart = () => {
    return crudActions.fetchStart();
};
export const fetchUsersSuccess = () => {
    return crudActions.fetchSuccess();
};
export const fetchUsersFail = () => {
    return crudActions.fetchFail();
};
export const fetchUsers = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
};

// Load user action creators
export const loadUserInit = () => {
    return crudActions.loadInit();
};
export const loadUserStart = () => {
    return crudActions.loadStart();
};
export const loadUserSuccess = loadedUser => {
    return crudActions.loadSuccess(loadedUser);
};
export const loadUserFail = error => {
    return crudActions.loadFail(error);
};
export const loadUser = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

// Create user action creators
export const createUserInit = () => {
    return crudActions.createInit();
};
export const createUserStart = () => {
    return crudActions.createStart();
};
export const createUserSuccess = createdUser => {
    return crudActions.createSuccess(createdUser);
};
export const createUserFail = error => {
    return crudActions.createFail(error);
};
export const createUser = user => {
    return async dispatch => {
        await crudActions.create(dispatch, user);
    };
};

// Edit user action creators
export const editUserInit = () => {
    return crudActions.editInit();
};
export const editUserStart = () => {
    return crudActions.editStart();
};
export const editUserSuccess = editedUser => {
    return crudActions.editSuccess(editedUser);
};
export const editUserFail = error => {
    return crudActions.editFail(error);
};
export const editUser = user => {
    return async dispatch => {
        await crudActions.edit(dispatch, user);
    };
};

// Bulk delete user action creators
export const bulkDeleteUsersInit = () => {
    return crudActions.bulkDeleteInit();
};
export const bulkDeleteUsersStart = () => {
    return crudActions.bulkDeleteStart();
};
export const bulkDeleteUsersSuccess = bulkDeleted => {
    return crudActions.bulkDeleteSuccess(bulkDeleted);
};
export const bulkDeleteUsersFail = error => {
    return crudActions.bulkDeleteFail(error);
};
export const bulkDeleteUsers = userIds => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, userIds);
    };
};

// Delete user action creators
export const deleteUserInit = () => {
    return crudActions.deleteInit();
};
export const deleteUserStart = () => {
    return crudActions.deleteStart();
};
export const deleteUserSuccess = deletedUser => {
    return crudActions.deleteSuccess(deletedUser);
};
export const deleteUserFail = error => {
    return crudActions.deleteFail(error);
};
export const deleteUser = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};

//Roles and permissions
const fetchUserRolesStart = () => {
    return {
        type: actionTypes.FETCH_USER_ROLES_START
    };
};

const fetchUserRolesSuccess = userRoles => {
    return {
        type: actionTypes.FETCH_USER_ROLES_SUCCESS,
        userRoles: userRoles
    };
};

const fetchUserRolesFail = error => {
    return {
        type: actionTypes.FETCH_USER_ROLES_FAIL,
        error: error
    };
};

//Bulk save user roles and permissions
const bulkSaveUserRolesStart = () => {
    return {
        type: actionTypes.BULK_SAVE_USER_ROLES_START
    };
};

const bulkSaveUserRolesSuccess = savedRoles => {
    return {
        type: actionTypes.BULK_SAVE_USER_ROLES_FAIL,
        savedRoles: savedRoles
    };
};

const bulkSaveUserRolesFail = error => {
    return {
        type: actionTypes.BULK_SAVE_USER_ROLES_FAIL,
        error: error
    };
};

export const fetchUserRoles = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(fetchUserRolesStart());
        try {
            let res = await rest.post('/users/roles/list', data);
            if (res.data.succeeded) {
                if (res.data.succeeded) {
                    const userRoles = res.data.result.data;
                    dispatch(fetchUserRolesSuccess(userRoles));
                } else {
                    dispatch(fetchUsersFail(res.error));
                    dispatch(
                        enqueueSnackbar({
                            message: `${res.status}`,
                            options: {
                                variant: 'error'
                            }
                        })
                    );
                }
            } else {
                dispatch(fetchUserRolesFail(res.error));
            }
        } catch (err) {
            dispatch(fetchUserRolesFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};

export const bulkSaveUserRoles = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(bulkSaveUserRolesStart());
        try {
            let res = await rest.post('/users/roles/bulk_save', data);
            if (res.data.succeeded) {
                if (res.data.succeeded) {
                    const savedRoles = res.data.result.data;
                    dispatch(bulkSaveUserRolesSuccess(savedRoles));
                    dispatch(
                        enqueueSnackbar({
                            message: 'User roles saved successfully!',
                            options: {
                                variant: 'success'
                            }
                        })
                    );
                } else {
                    dispatch(bulkSaveUserRolesFail(res.error));
                    dispatch(
                        enqueueSnackbar({
                            message: `${res.status}`,
                            options: {
                                variant: 'error'
                            }
                        })
                    );
                }
            } else {
                dispatch(bulkSaveUserRolesFail(res.error));
            }
        } catch (err) {
            dispatch(bulkSaveUserRolesFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};

//Fetch included permissions
const fetchIncludedPermissionsStart = () => {
    return {
        type: actionTypes.FETCH_INCLUDED_PERMISSIONS_START
    };
};

const fetchIncludedPermissionsSuccess = includedPermissions => {
    return {
        type: actionTypes.FETCH_INCLUDED_PERMISSIONS_SUCCESS,
        includedPermissions: includedPermissions
    };
};

const fetchIncludedPermissionsFail = error => {
    return {
        type: actionTypes.FETCH_INCLUDED_PERMISSIONS_FAIL,
        error: error
    };
};

export const fetchIncludedPermissions = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(fetchIncludedPermissionsStart());
        try {
            let res = await rest.post('/users/included_permissions/list', data);
            if (res.data.succeeded) {
                if (res.data.succeeded) {
                    const includedPermissions = res.data.result.data;
                    dispatch(fetchIncludedPermissionsSuccess(includedPermissions));
                } else {
                    dispatch(fetchUsersFail(res.error));
                    dispatch(
                        enqueueSnackbar({
                            message: `${res.status}`,
                            options: {
                                variant: 'error'
                            }
                        })
                    );
                }
            } else {
                dispatch(fetchIncludedPermissionsFail(res.error));
            }
        } catch (err) {
            dispatch(fetchIncludedPermissionsFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};

//Saving included permissions
const bulkSaveIncludedPermissionsStart = () => {
    return {
        type: actionTypes.BULK_SAVE_INCLUDED_PERMISSIONS_START
    };
};

const bulkSaveIncludedPermissionsSuccess = savedIncluded => {
    return {
        type: actionTypes.BULK_SAVE_INCLUDED_PERMISSIONS_SUCCESS,
        savedIncluded: savedIncluded
    };
};

const bulkSaveIncludedPermissionsFail = error => {
    return {
        type: actionTypes.BULK_SAVE_INCLUDED_PERMISSIONS_FAIL,
        error: error
    };
};

export const saveIncludedPermissions = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(bulkSaveIncludedPermissionsStart());
        try {
            let res = await rest.post('/users/included_permissions/bulk_save', data);
            if (res.data.succeeded) {
                if (res.data.succeeded) {
                    const savedIncluded = res.data.result.data;
                    dispatch(bulkSaveIncludedPermissionsSuccess(savedIncluded));
                    dispatch(
                        enqueueSnackbar({
                            message: 'User included permissions saved successfully!',
                            options: {
                                variant: 'success'
                            }
                        })
                    );
                } else {
                    dispatch(fetchUsersFail(res.error));
                    dispatch(
                        enqueueSnackbar({
                            message: `${res.status}`,
                            options: {
                                variant: 'error'
                            }
                        })
                    );
                }
            } else {
                dispatch(bulkSaveIncludedPermissionsFail(res.error));
            }
        } catch (err) {
            dispatch(bulkSaveIncludedPermissionsFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};

//Fetch excluded permissions
const fetchExcludedPermissionsStart = () => {
    return {
        type: actionTypes.FETCH_EXCLUDED_PERMISSIONS_START
    };
};

const fetchExcludedPermissionsSuccess = excludedPermissions => {
    return {
        type: actionTypes.FETCH_EXCLUDED_PERMISSIONS_SUCCESS,
        excludedPermissions: excludedPermissions
    };
};

const fetchExcludedPermissionsFail = error => {
    return {
        type: actionTypes.FETCH_EXCLUDED_PERMISSIONS_FAIL,
        error: error
    };
};

export const fetchExcludedPermissions = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(fetchExcludedPermissionsStart());
        try {
            let res = await rest.post('/users/excluded_permissions/list', data);
            if (res.data.succeeded) {
                if (res.data.succeeded) {
                    const excludedPermissions = res.data.result.data;
                    dispatch(fetchExcludedPermissionsSuccess(excludedPermissions));
                } else {
                    dispatch(fetchUsersFail(res.error));
                    dispatch(
                        enqueueSnackbar({
                            message: `${res.status}`,
                            options: {
                                variant: 'error'
                            }
                        })
                    );
                }
            } else {
                dispatch(fetchExcludedPermissionsFail(res.error));
            }
        } catch (err) {
            dispatch(fetchExcludedPermissionsFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};

//Saving excluded permissions
const bulkSaveExcludedPermissionsStart = () => {
    return {
        type: actionTypes.BULK_SAVE_EXCLUDED_PERMISSIONS_START
    };
};

const bulkSaveExcludedPermissionsSuccess = savedIncluded => {
    return {
        type: actionTypes.BULK_SAVE_EXCLUDED_PERMISSIONS_SUCCESS,
        savedIncluded: savedIncluded
    };
};

const bulkSaveExcludedPermissionsFail = error => {
    return {
        type: actionTypes.BULK_SAVE_EXCLUDED_PERMISSIONS_FAIL,
        error: error
    };
};

export const saveExcludedPermissions = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(bulkSaveExcludedPermissionsStart());
        try {
            let res = await rest.post('/users/excluded_permissions/bulk_save', data);
            if (res.data.succeeded) {
                if (res.data.succeeded) {
                    const savedExcluded = res.data.result.data;
                    dispatch(bulkSaveExcludedPermissionsSuccess(savedExcluded));
                    dispatch(
                        enqueueSnackbar({
                            message: 'User excluded permissions saved successfully!',
                            options: {
                                variant: 'success'
                            }
                        })
                    );
                } else {
                    dispatch(fetchUsersFail(res.error));
                    dispatch(
                        enqueueSnackbar({
                            message: `${res.status}`,
                            options: {
                                variant: 'error'
                            }
                        })
                    );
                }
            } else {
                dispatch(bulkSaveExcludedPermissionsFail(res.error));
            }
        } catch (err) {
            dispatch(bulkSaveExcludedPermissionsFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};

//Fetch all user permissions
const fetchAllUserPermissionsStart = () => {
    return {
        type: actionTypes.FETCH_ALL_USER_PERMISSIONS_START
    };
};

const fetchAllUserPermissionsSuccess = allUserPermissions => {
    return {
        type: actionTypes.FETCH_ALL_USER_PERMISSIONS_SUCCESS,
        allUserPermissions: allUserPermissions
    };
};

const fetchAllUserPermissionsFail = error => {
    return {
        type: actionTypes.FETCH_ALL_USER_PERMISSIONS_FAIL,
        error: error
    };
};

export const fetchAllUserPermissions = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(fetchAllUserPermissionsStart());
        try {
            let res = await rest.post('/users/permissions/list', data);
            if (res.data.succeeded) {
                if (res.data.succeeded) {
                    const allUserPermissions = res.data.result.data;
                    dispatch(fetchAllUserPermissionsSuccess(allUserPermissions));
                } else {
                    dispatch(fetchUsersFail(res.error));
                    dispatch(
                        enqueueSnackbar({
                            message: `${res.status}`,
                            options: {
                                variant: 'error'
                            }
                        })
                    );
                }
            } else {
                dispatch(fetchAllUserPermissionsFail(res.error));
            }
        } catch (err) {
            dispatch(fetchAllUserPermissionsFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};

//Fetch all user permissions
const fetchUserSettingsStart = () => {
    return {
        type: actionTypes.FETCH_USER_SETTINGS_START
    };
};

const fetchUserSettingsSuccess = allUserPermissions => {
    return {
        type: actionTypes.FETCH_USER_SETTINGS_SUCCESS,
        allUserPermissions: allUserPermissions
    };
};

const fetchUserSettingsFail = error => {
    return {
        type: actionTypes.FETCH_USER_SETTINGS_FAIL,
        error: error
    };
};

export const fetchUserSettings = (id) => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(fetchUserSettingsStart());
        try {
            let res = await rest.post('users/load-settings', id);
            if (res.data.succeeded) {
                if (res.data.succeeded) {
                    const userSetting = res.data.result.data;
                    dispatch(fetchUserSettingsSuccess(userSetting));
                } else {
                    dispatch(fetchUsersFail(res.error));
                    dispatch(
                        enqueueSnackbar({
                            message: `${res.status}`,
                            options: {
                                variant: 'error'
                            }
                        })
                    );
                }
            } else {
                dispatch(fetchUserSettingsFail(res.error));
            }
        } catch (err) {
            dispatch(fetchUserSettingsFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
        }
    };
};
