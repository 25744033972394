import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import NPDatatable from '../../np-components/Datatable';
import { Tooltip, IconButton, LinearProgress, Paper, Dialog, DialogContentText, DialogContent, DialogActions, DialogTitle, Button } from '@material-ui/core';
import useListFilter from '../../np-components/Datatable/listFilter';
import { isSuperUser } from '../../np-utilities';
import { CloudDownload, Delete, Edit, SpeakerNotes, Notes } from '@material-ui/icons';
import { connect } from 'react-redux';
import { CustomChip } from '../../ms-components';
import * as actions from './store/binaryImageActions';
import * as action from '../../ms-downloadAll/store/downloadActions';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { downloadFile } from '../../ms-utilities';
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';
import BinaryImageDelete from './BinaryImageDelete';
import * as DOMPurify from 'dompurify';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
    paper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden",
        alignItems: "center"
    },
    progress: {
        width: `398px`,
        height: '400px',
    },
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#e62440" : "#7dc5e2",
        '&:hover': {
            backgroundColor: "#757575",
        },
    },
    progressBarr: {
        width: '100%',
        height: 5
    },
    chipCustomerActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    chipCustomerInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    }
});

const BinaryImages = props => {
    const { classes, images, onFetchBinaryImages, columnsState, activeCustomer, translations, download } = props;
    const imagesCount = images.count;
    const imagesList = images.list;
    const fetching = images.fetching;
    let tableID = 'BinaryImages';
    let visible = columnsState && columnsState[tableID];
    const [redirectOptions, setRedirectOptions] = useState({ redirect: false, productID: 0, redirectPath: '' });
    const navigate = useNavigate();

    const columns = [
        {
            displayName: translations["action"] || 'Action',
            backendName: 'Action',
            options: {
                align: 'left',
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: translations["major_version"] || 'Major version',
            backendName: 'MajorVersion',
            options: {
                align: 'left',
                visible: visible ? visible['MajorVersion'] : true,
            }
        },
        {
            displayName: translations["minor_version"] || 'Minor version',
            backendName: 'MinorVersion',
            options: {
                align: 'left',
                visible: visible ? visible['MinorVersion'] : true,
            }
        },
        {
            displayName: translations["build_number"] || 'Build number',
            backendName: 'BuildNumber',
            options: {
                align: 'left',
                visible: visible ? visible['BuildNumber'] : true,
            }
        },
        {
            displayName: translations["build_type"] || 'Build type',
            backendName: 'BuildType',
            options: {
                align: 'left',
                visible: visible ? visible['BuildType'] : true,
            }
        },
        {
            displayName: translations["name"] || 'Name',
            backendName: 'Name',
            options: {
                align: 'left',
                visible: visible ? visible['Name'] : true,
            }
        },
        {
            displayName: translations["image_type"] || 'Image type',
            backendName: 'ImageType',
            options: {
                align: 'left',
                visible: visible ? visible['ImageType'] : true,
            }
        },
        {
            displayName: translations["crc32 "] || 'CRC32',
            backendName: 'Crc',
            options: {
                align: 'left',
                visible: visible ? visible['Crc'] : true,
            }
        },
        {
            displayName: translations["public"] || 'Public',
            backendName: 'Public',
            options: {
                align: 'left',
                visible: visible ? visible['Public'] : true,
            }
        },
        {
            displayName: translations["active"] || 'Active',
            backendName: 'Active',
            options: {
                align: 'left',
                visible: visible ? visible['Active'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => { if (column.backendName !== 'Action') backendNames.push(column.backendName); return null; })
    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });
    if (!props.hasPermission('binary_image_load') && !props.hasPermission('binary_image_delete') && !props.hasPermission('binary_image_download')) {
        columns.splice(0, 1);
    }
    const [deleteModalState, setDeleteModalState] = useState({ open: false, imageID: null });


    const handleEditModal = id => {
        setRedirectOptions({ redirect: true, redirectPath: `/downloads/binary-images/edit?binaryImageID=${id}` });
        navigate(redirectOptions.redirectPath)

    }

    const [downloading, setDownloading] = useState({});


    const downloadImage = async (imageID, name) => {
        setDownloading({ [imageID]: true });
        let imageData = await props.onDownload(imageID, { ActiveCustomerID: activeCustomer }, `${name}`);
        if (imageData) {
            setDownloading({ [imageID]: false });
            return downloadFile(imageData, name);
        }
        setDownloading({ [imageID]: false });

    };

    const deleteImage = async event => {
        if (!event) {
            setDeleteModalState({ open: false, imageID: null });
            return;
        }
        let imageID = event.currentTarget.value;
        setDeleteModalState({ ...deleteModalState, open: true, imageID: imageID });
    }
    const [releaseModalState, setReleaseModalState] = useState({ open: false, imageID: null, name: '' });

    const showReleaseNotes = async (id, name) => {

        await props.onLoadBinaryImage({ BinaryImageID: id, ActiveCustomerID: activeCustomer })
        setReleaseModalState({ ...deleteModalState, open: true, imageID: id, name: name });
    }
    const sendReleaseNotes = async event => {
        let imageID = event.currentTarget.value;
        await props.onSendReleaseNotes({ BinaryImageID: imageID, ActiveCustomerID: activeCustomer });
    }



    const createActions = (imageID, name, fullName, ReleaseNotesSent) => {
        return <>
            {props.hasPermission('binary_image_load') ?
                <Tooltip title={translations['edit_binary_image'] || "Edit binary image binary"}>
                    <IconButton value={imageID} aria-label={translations['edit_binary_image'] || "Edit binary image binary"} onClick={() => handleEditModal(imageID)}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                : null}
            {props.hasPermission('binary_image_download') ?
                <Tooltip component="div" title={downloading[imageID] ? `${translations['loading'] || "Loading"}..` : translations['download_image'] || "Download binary image"}>
                    <IconButton disabled={download.currentDownloadData[`${imageID}`] && download.currentDownloadData[`${imageID}`].snackBar ? true : false} value={imageID} aria-label={translations['download_image'] || "Download binary image"} onClick={() => { if (downloading[imageID]) return null; else return downloadImage(imageID, `${name}`) }}>
                        <CloudDownload />
                    </IconButton>
                </Tooltip>
                : null}
            {props.hasPermission('binary_image_delete') ?
                <Tooltip title={translations['delete_image'] || "Delete image"}>
                    <IconButton value={imageID} aria-label={translations['delete_image'] || "Delete image"} onClick={deleteImage}>
                        <Delete />
                    </IconButton>
                </Tooltip>
                : null}
            {props.hasPermission('binary_image_release_notes') ?
                <Tooltip title={translations['show_release_notes'] || "Show release notes"}>
                    <IconButton value={imageID} aria-label={translations['show_release_notes'] || "Show release notes"} onClick={() => showReleaseNotes(imageID, fullName)}>
                        <SpeakerNotes />
                    </IconButton>
                </Tooltip>
                : null}
            {props.hasPermission('send_release_notes') && !ReleaseNotesSent ?
                <Tooltip title={translations['send_release_notes'] || "Send release notes"}>
                    <IconButton value={imageID} aria-label={translations['send_release_notes'] || "Send release notes"} onClick={sendReleaseNotes}>
                        <Notes />
                    </IconButton>
                </Tooltip>
                : null}
        </>

    };

    useEffect(() => {
        if (listFilter.filter) {
            onFetchBinaryImages({ ...listFilter.filter, ActiveCustomerID: activeCustomer });
        }
    }, [listFilter.filter, images.created, images.deleted, activeCustomer, images.edited, images.sendMessage, onFetchBinaryImages]);

    const tableOptions = {
        isSelectable: false,
        tableSearch: false,
        tableID: 'BinaryImages',
        saveColumn: true,
        selectOptions: { createButtonName: translations['create_new'] || 'Create new', printing: false, generatingReport: false, deleting: false, creating: props.hasPermission('binary_image_create') },
        rowsPerPage: 20,
        page: 0,
        count: imagesCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: translations["binary_image"] || 'Binary Images',
        noMatch: translations['table_no_match'] || "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onSaveFilter: (columns) => {
            let objToSendColumn = {}
            let objColumns = {}
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible
                return objColumns
            })
            objToSendColumn[`${tableID}`] = objColumns
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }
    };

    const formatBuildType = buildType => {
        switch (buildType) {
            case 'prod':
                return 'Production';
            case 'test':
                return 'Test';
            case 'beta':
                return 'Beta';
            default:
                return 'test';
        }
    }

    const toHex = d => {
        return ((Number(d).toString(16))).toUpperCase()
    }

    let counter = 0;
    let binaryImages = imagesList.map(image => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = image.BinaryImageID;
        returnObj.visible = true;
        returnObj.values = [
            createActions(image.BinaryImageID, image.OriginalFileName, image.Name, image.ReleaseNotesSent),
            image.MajorVersion,
            image.MinorVersion,
            image.BuildNumber,
            formatBuildType(image.BuildType),
            image.Name,
            image.BinaryImageType && image.BinaryImageType.Name ? image.BinaryImageType.Name : '',
            toHex(image.CRC),
            (<CustomChip customChipKey={image.BinaryImageID} className={classes[`chipCustomer${image.Public ? 'Active' : 'Inactive'}`]} value={image.Public ? translations['public'] || 'Public' : translations['private'] || 'Private'} />),
            (<CustomChip customChipKey={image.BinaryImageID} className={classes[`chipCustomer${image.Active ? 'Active' : 'Inactive'}`]} value={image.Active ? translations['active'] || 'Active' : translations['inactive'] || 'Inactive'} />)];

        if (!props.hasPermission('binary_image_load') && !props.hasPermission('binary_image_delete') && !props.hasPermission('binary_image_download')) {
            returnObj.values.splice(0, 1);
        }

        counter++;
        return returnObj;
    });

    const handleCreate = () => {
        setRedirectOptions({ redirect: true, redirectPath: `/downloads/binary-images/create` });
    };

    if (redirectOptions.redirect) {
        return <Routes><Route path="*" element={<Navigate to={redirectOptions.redirectPath} />} /></Routes>;
    }

    return (
        <div className={classes.root}>
            <div className={classes.progressBarr}>
                {fetching ? <LinearProgress color="secondary" /> : null}
            </div>
            <Paper>
                {props.hasPermission('binary_image_list') ? <NPDatatable translations={translations} customStyles={classes} handleCreate={handleCreate} data={binaryImages} columns={columns} options={tableOptions} /> : null}
            </Paper>

            {props.hasPermission('binary_image_load') ? <BinaryImageDelete handleDelete={deleteImage} entityName={'software'} deleteBinaryImage={props.deleteBinaryImage} translations={translations} activeCustomer={activeCustomer} hasPermission={props.hasPermission} deleteModalState={deleteModalState} /> : null}
            <Dialog
                open={releaseModalState.open}
                // onClose={() => setReleaseModalState({ ...releaseModalState, open: false, imageID: null })}
                aria-labelledby="form-dialog-title"
                // disableBackdropClick={true}
                disableEscapeKeyDown={false}
                fullWidth={true}
                onClose={(_, reason) => {
                    if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        setReleaseModalState({ ...releaseModalState, open: false, imageID: null });
                    }
                }}
            >
                <DialogTitle id="form-dialog-title">Release notes - {releaseModalState.name} </DialogTitle>
                <DialogContent>
                    <DialogContentText dangerouslySetInnerHTML={{ __html: images && images.loaded && images.loaded.ReleaseNotes ? DOMPurify.sanitize(images.loaded.ReleaseNotes) : '' }} />
                </DialogContent>
                <DialogActions>
                    <Button className={classes.buttonSafe} onClick={() => setReleaseModalState({ ...releaseModalState, open: false, imageID: null })} variant="contained" >
                        Close
                    </Button>

                </DialogActions>
            </Dialog>

        </div >
    );
}

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        translations: state.translate && state.translate.translations ? state.translate.translations.activeTranslations || {} : {},
        binaryImageType: state.exportImport.binaryImages ? state.exportImport.binaryImages.binaryImageTypes : {},
        hasPermission: permission => state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'),
        images: state.exportImport.binaryImages,
        download: state.download && state.download.download ? state.download.download : {},

    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchBinaryImages: filter => dispatch(actions.fetchBinaryImages(filter)),
        onFetchBinaryImageTypes: id => dispatch(actions.listBinaryImageTypes(id)),
        onLoadBinaryImage: filter => dispatch(actions.loadBinaryImage(filter)),
        onSendReleaseNotes: id => dispatch(actions.sendReleaseNotes(id)),
        createNewBinaryImage: data => dispatch(actions.createNewBinaryImage(data)),
        deleteBinaryImage: data => dispatch(actions.deleteBinaryImage(data)),
        onDownload: (data, customerID, name) => dispatch(action.downloadFile(data, customerID, name)),
        editBinaryImage: data => dispatch(actions.editBinaryImage(data)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(BinaryImages));