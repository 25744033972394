import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';

import NPDatatable from '../../np-components/Datatable';
import { Paper, Tooltip, IconButton } from '@material-ui/core';
import useListFilter from '../../np-components/Datatable/listFilter';
import { Edit, Delete } from '@material-ui/icons';
import { isSuperUser } from '../../np-utilities';
import { parseURL, BackButton } from '../../ms-utilities';

const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#d50000" : "#1b2a33",
        '&:hover': {
            backgroundColor: "#757575",
        },
    },
    chipTrainingActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    chipTrainingInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    media: {
        width: 50,
        height: 50
    }
});

const updateFilterParams = props => {
    const { listFilter } = props;
    let searchParams = parseURL(window.location.search);
    return { ...listFilter, ...searchParams };
};

const TrainingModulesList = props => {
    const { classes, trainingModules, onFetchTrainingModules, columnsState, activeCustomer } = props;
    const trainingModulesCount = trainingModules.count;
    const trainingModulesList = trainingModules.list;

    let tableID = 'TrainingModule';
    let visible = columnsState && columnsState[tableID];

    const columns = [
        {
            displayName: 'Action',
            backendName: 'Action',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: 'Module name',
            backendName: 'ModuleName',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['ModuleName'] : true,
            }
        },
        {
            displayName: 'Training name',
            backendName: 'TrainingName',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['TrainingName'] : true,
            }
        },
        {
            displayName: 'Site',
            backendName: 'SiteName',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['SiteName'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => {
        if (column.backendName !== 'Action') backendNames.push(column.backendName);
        return null;
    });
    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });

    if (!props.hasAtLeastOnePermission(['training_module_create', 'training_module_load', 'training_module_save', 'training_module_delete'])) {
        columns.splice(0, 1);
    }

    useEffect(() => {
        if (listFilter.filter) {
            if (!listFilter.filter.q) {
                listFilter.filter.q = {};
            }
            if (!listFilter.filter.q.and) {
                listFilter.filter.q.and = {};
            }
            listFilter.filter.q = {
                ...listFilter.filter.q,
                and: updateFilterParams({ listFilter: listFilter.filter.q.and })
            };
            onFetchTrainingModules({ ...listFilter.filter, ActiveCustomerID: activeCustomer });
        }
    }, [trainingModules.created, activeCustomer, trainingModules.deleted, trainingModules.bulkDeleted, trainingModules.edited, listFilter.filter, onFetchTrainingModules]);


    const createActions = trainingID => {
        return <>
            {
                props.hasPermission('training_module_load') ? <Tooltip title="Edit training module relation">
                    <IconButton value={trainingID} aria-label="Edit training" onClick={props.handleEdit}>
                        <Edit />
                    </IconButton>
                </Tooltip> : <div></div>
            }
            {
                props.hasPermission('training_module_delete') ?
                    <Tooltip title="Delete training module relation">
                        <IconButton value={trainingID} aria-label="Delete training module relation" onClick={props.handleDelete}>
                            <Delete />
                        </IconButton>
                    </Tooltip> : <div></div>
            }
        </>
    };


    const tableOptions = {
        isSelectable: props.hasPermission('training_module_delete'),
        tableSearch: false,
        tableID: tableID,
        saveColumn: true,
        selectOptions: {
            printing: false,
            generatingReport: false,
            deleting: props.hasPermission('training_module_delete'),
            creating: props.hasPermission('training_module_create')
        },
        rowsPerPage: 20,
        count: trainingModulesCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'Training Modules',
        noMatch: "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return props.handleBulkDelete(IDs);
        },
        onSaveFilter: (columns) => {
            let objToSendColumn = {};
            let objColumns = {};
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible;
                return objColumns
            });
            objToSendColumn[`${tableID}`] = objColumns;
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }
    };


    let counter = 0;
    let trainingModulesData = trainingModulesList.map(training => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = training.TrainingModulesID;
        returnObj.visible = true;
        returnObj.values = [
            createActions(training.TrainingModulesID),
            training.Module ? training.Module.Name : '-',
            training.Training ? training.Training.Name : '-',
            training.Site ? training.Site.Name : '-'
        ];
        if (!props.hasAtLeastOnePermission(['training_module_create', 'training_module_load', 'training_module_delete', 'training_module_delete'])) {
            returnObj.values.splice(0, 1);
        }
        counter++;
        return returnObj;
    });

    return (
        <Paper>
            {BackButton()}
            <NPDatatable handleCreate={props.handleCreate} customStyles={classes} data={trainingModulesData}
                columns={columns}
                options={tableOptions} />
        </Paper>
    );
};

export default withStyles(styles)(TrainingModulesList);