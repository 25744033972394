import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route } from 'react-router';
import { Grid, Paper, withStyles } from '@material-ui/core';

import Form from '../../np-form/Form';
import * as formUtility from '../../np-form/utility';
import * as actions from './store/permissionActions';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,

        padding: theme.spacing(2),
        display: 'flex',
        flexWrap: 'wrap'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center'
    }
});
const PermissionCreate = props => {
    const { hasEditPermission, createdPermission, classes, onInit } = props;

    // After unmount init the state in the redux store
    useEffect(() => {
        return () => {
            onInit();
        };
    }, [onInit]);

    const [permissionForm, setPermissionForm] = useState({
        name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                fullWidth: true ,
                margin: 'dense',
                placeholder: 'Name',
                label: 'Name'
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        }
    });
    const [formIsValid, setFormIsValid] = useState(false);

    const submitHandler = event => {
        event.preventDefault();

        // Prepare the form data
        const formData = formUtility.getFormData(permissionForm);

        const permission = {
            Name: formData.name
        };
        props.onCreatePermission(permission);
    };

    // Input change handler
    const inputChangedHandler = (event, inputIdentifier) => {
        // Get default new state based on the input change event
        const updatedState = formUtility.getNewStateOnInputChange(event, inputIdentifier, permissionForm);
        setPermissionForm(updatedState.myForm);
        setFormIsValid(updatedState.formIsValid);
    };
    const [redirect, setRedirect] = useState(false);
    const cancelHandler = () => {
        setRedirect(true);
    };
    // If there is a created issue let's redirect to the permission list
    if (createdPermission !== null || redirect) {
        // Prepare redirect URL
        let path = props.match.url; // .../permission/create
        let pathArray = path.split('/');
        pathArray.pop(); // remove create
        path = pathArray.join('/'); // .../permission
        // Navigate to the ../permission
        return <Route path="*" element={<Navigate to={path} />} />;
    }

    // Render form
    return hasEditPermission ? (
        <div className={classes.root}>
            <Grid container spacing={10} justify="center" direction="row" alignItems="center">
                <Grid item lg={6} md={8} sm={10} xs={12}>
                    <Paper className={classes.paper}>
                        <Form
                            inputChangedHandler={inputChangedHandler}
                            submitHandler={submitHandler}
                            form={permissionForm}
                            formIsValid={formIsValid}
                            buttonText="Save"
                            cancelHandler={cancelHandler}
                            buttonStyle={{ variant: 'contained', color: 'primary', fullWidth: true }}
                            buttonTextCancel="Cancel"
                            formTitle="Enter data to create new permission"
                        />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    ) : null;
};

const mapStateToProps = state => {
    return {
        hasEditPermission: state.core.login.permissions.includes('*') || state.core.login.permissions.includes('permissions_create'),
        createdPermission: state.core.permission.created
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInit: () => dispatch(actions.createPermissionsInit()),
        onCreatePermission: permission => dispatch(actions.createPermissions(permission))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(PermissionCreate));
