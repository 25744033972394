import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';

import NPDatatable from '../../np-components/Datatable';
import {Paper, Tooltip, IconButton} from '@material-ui/core';
import useListFilter from '../../np-components/Datatable/listFilter';
import {Edit, Delete, EditAttributes} from '@material-ui/icons';
import {isSuperUser} from '../../np-utilities';
import {parseURL, BackButton} from '../../ms-utilities';

const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#d50000" : "#1b2a33",
        '&:hover': {
            backgroundColor: "#757575",
        },
    },
    chipTrainingActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    chipTrainingInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    media: {
        width: 50,
        height: 50
    }
});

const updateFilterParams = props => {
    const {listFilter} = props;
    let searchParams = parseURL(window.location.search);
    return {...listFilter, ...searchParams};
};

const TrainingList = props => {
    const {classes, trainings, onFetchTrainings, columnsState, activeCustomer} = props;
    const trainingsCount = trainings.count;
    const trainingsList = trainings.list;

    let tableID = 'Training';
    let visible = columnsState && columnsState[tableID];

    const columns = [
        {
            displayName: 'Action',
            backendName: 'Action',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: 'Name',
            backendName: 'Name',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Name'] : true,
            }
        },
        {
            displayName: 'Key',
            backendName: 'Key',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Key'] : true,
            }
        },
        {
            displayName: 'Description',
            backendName: 'Description',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Description'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => {
        if (column.backendName !== 'Action') backendNames.push(column.backendName);
        return null;
    });
    const listFilter = useListFilter({rowsPerPage: 20, searchColumns: backendNames});

    if (!props.hasAtLeastOnePermission(['training_create', 'training_load', 'training_save', 'training_delete'])) {
        columns.splice(0, 1);
    }

    useEffect(() => {
        if (listFilter.filter) {
            if (!listFilter.filter.q) {
                listFilter.filter.q = {};
            }
            if (!listFilter.filter.q.and) {
                listFilter.filter.q.and = {};
            }
            listFilter.filter.q = {
                ...listFilter.filter.q,
                and: updateFilterParams({listFilter: listFilter.filter.q.and})
            };
            onFetchTrainings({...listFilter.filter, ActiveCustomerID: activeCustomer});
        }
    }, [trainings.created, activeCustomer, trainings.deleted, trainings.bulkDeleted, trainings.edited, listFilter.filter, onFetchTrainings]);


    const createActions = trainingID => {
        return <>
            {
                props.hasPermission('training_load') ? <Tooltip title="Edit training">
                    <IconButton value={trainingID} aria-label="Edit training" onClick={props.handleEdit}>
                        <Edit/>
                    </IconButton>
                </Tooltip> : null
            }
            {
                props.hasPermission('training_modify_modules') ? <Tooltip title="Modify modules">
                    <IconButton value={trainingID} aria-label="Modify modules" onClick={props.modifyModules}>
                        <EditAttributes/>
                    </IconButton>
                </Tooltip> : null
            }
            {
                props.hasPermission('training_delete') ?
                    <Tooltip title="Delete Training">
                        <IconButton value={trainingID} aria-label="Delete Training" onClick={props.handleDelete}>
                            <Delete/>
                        </IconButton>
                    </Tooltip> : null
            }
        </>
    };


    const tableOptions = {
        isSelectable: props.hasPermission('training_delete'),
        tableSearch: false,
        tableID: tableID,
        saveColumn: true,
        selectOptions: {
            printing: false,
            generatingReport: false,
            deleting: props.hasPermission('training_delete'),
            creating: props.hasPermission('training_create')
        },
        rowsPerPage: 20,
        count: trainingsCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'Trainings',
        noMatch: "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return props.handleBulkDelete(IDs);
        },
        onSaveFilter: (columns) => {
            let objToSendColumn = {};
            let objColumns = {};
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible;
                return objColumns
            });
            objToSendColumn[`${tableID}`] = objColumns;
            props.saveColumnsFilter({...objToSendColumn, ActiveCustomerID: activeCustomer})
        }
    };


    let counter = 0;
    let trainingsData = trainingsList.map(training => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = training.TrainingID;
        returnObj.visible = true;
        returnObj.values = [
            createActions(training.TrainingID),
            training.Name,
            training.Key,
            training.Description,
        ];
        if (!props.hasAtLeastOnePermission(['training_create', 'training_load', 'training_delete', 'training_delete'])) {
            returnObj.values.splice(0, 1);
        }
        counter++;
        return returnObj;
    });

    return (
        <Paper>
            {BackButton()}
            <NPDatatable handleCreate={props.handleCreate} customStyles={classes} data={trainingsData} columns={columns}
                         options={tableOptions}/>
        </Paper>
    );
}

export default withStyles(styles)(TrainingList);