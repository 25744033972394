import React, { useEffect, useState } from 'react';
import {
    withStyles,
    LinearProgress,
    Paper,
    Tooltip,
    IconButton, Checkbox
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons'
import useListFilter from '../../np-components/Datatable/listFilter';
import NPDatatable from '../../np-components/Datatable';
import { isSuperUser } from '../../np-utilities';
import { parseURL } from "../../ms-utilities";

const styles = theme => ({
    show: {
        display: 'block',
    },
    hide: {
        display: 'none',
    },
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#d50000" : "#1b2a33",
        '&:hover': {
            backgroundColor: "#757575",
        },
    }
});

const updateFilter = props => {
    const { listFilter } = props;
    let searchParams = parseURL(window.location.search);
    return { ...listFilter, ...searchParams };
};

const LicensePrototypeList = props => {
    const { classes, licensePrototypes, onFetchLicensePrototypeList, columnsState, handleEdit, handleDelete, handleCreate, activeCustomer } = props;
    const loading = licensePrototypes.fetching;
    const licensesPrototypesCount = licensePrototypes.count;
    const licensesPrototypesList = licensePrototypes.list;

    const [extraFilterInfo, setExtraFilterInfo] = useState({ showDeleted: false });
    let tableID = 'LicensePrototype';
    let visible = columnsState && columnsState[tableID];

    const columns = [
        {
            displayName: 'Actions',
            backendName: 'Actions',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: 'Name',
            backendName: 'Name',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Name'] : true,
            }
        },
        {
            displayName: 'Deleted',
            backendName: 'Deleted',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Deleted'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => {
        if (column.backendName !== 'Action') backendNames.push(column.backendName);
        return null;
    });

    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });
    if (!props.hasAtLeastOnePermission(['license_prototype_load', 'license_prototype_delete', 'license_prototype_cancel_or_restore'])) {
        columns.splice(0, 1);
    }

    // Fetch Calls if the filter was changed or after delete
    useEffect(() => {
        // fetch calls
        if (listFilter.filter) {
            if (!listFilter.filter.q) {
                listFilter.filter.q = {};
            }
            if (!listFilter.filter.q.and) {
                listFilter.filter.q.and = {};
            }
            listFilter.filter.q = {
                ...listFilter.filter.q, ...extraFilterInfo,
                and: updateFilter({ listFilter: listFilter.filter.q.and })
            };

            onFetchLicensePrototypeList({ ...listFilter.filter, ActiveCustomerID: activeCustomer });
        }
    }, [extraFilterInfo, listFilter.filter, licensePrototypes.deleted, licensePrototypes.creating, licensePrototypes.editing, onFetchLicensePrototypeList, activeCustomer]);

    const createActions = (licensePrototypeID) => {
        return <>
            {
                props.hasPermission('license_prototype_load') ?
                    <Tooltip title="Edit license prototype">
                        <IconButton value={licensePrototypeID} onClick={handleEdit} aria-label="Edit license prototype">
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    : null
            }
            {
                props.hasPermission('license_prototype_delete') ?
                    <Tooltip title="Delete license prototype">
                        <IconButton value={licensePrototypeID} aria-label="Delete license prototype"
                            onClick={handleDelete}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    : null

            }
        </>
    };
    const handleExtraFilterChange = event => {
        if (event.currentTarget.value === 'showDeleted') {
            setExtraFilterInfo({ ...extraFilterInfo, showDeleted: !extraFilterInfo.showDeleted });
        }
    };

    const customFilterToolbar = () => {
        return <>
            <Checkbox checked={extraFilterInfo.showDeleted}
                value={'showDeleted'}
                onChange={handleExtraFilterChange} /> Show deleted
        </>
    };

    const tableOptions = {
        isSelectable: false,
        tableSearch: false,
        tableID: tableID,
        saveColumn: true,
        page: 0,
        selectOptions: { creating: props.hasPermission('license_prototype_create') },
        rowsPerPage: 20,
        count: licensesPrototypesCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'License prototypes',
        noMatch: "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onSaveFilter: (columns) => {
            let objToSendColumn = {}
            let objColumns = {}
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible
                return objColumns
            })
            objToSendColumn[`${tableID}`] = objColumns
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }

    };


    let counter = 0;
    let licensePrototypeData = licensesPrototypesList.map(({ LicensePrototypeID, Name, Deleted }) => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = LicensePrototypeID;
        returnObj.visible = true;
        returnObj.values = [
            createActions(LicensePrototypeID),
            Name,
            Deleted === true ? 'Yes' : 'No'
        ];

        if (!props.hasAtLeastOnePermission(['license_prototype_load', 'license_prototype_delete'])) {
            returnObj.values.splice(0, 1);
        }

        counter++;
        return returnObj;
    });

    return (
        <>
            <LinearProgress className={loading ? classes.show : classes.hide} color="primary" />
            <Paper>
                <NPDatatable handleCreate={handleCreate}
                    customStyles={classes}
                    data={licensePrototypeData}
                    columns={columns}
                    options={tableOptions}
                    customFilterToolbar={customFilterToolbar}
                />
            </Paper>
        </>
    );
};

export default (withStyles(styles)(LicensePrototypeList));
