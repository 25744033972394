import React, { useState } from 'react';
import * as actions from './store/roleActions';
import { connect } from 'react-redux';
import { withStyles, LinearProgress } from '@material-ui/core';
import RoleList from './RoleList';
import RoleDelete from './RoleDelete';
import * as action from '../../np-core/Auth/store/authActions';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
});

const Role = (props) => {
    const { role, classes, activeCustomer, translations } = props;
    const fetching = role.fetching;
    const [deleteModalState, setDeleteModalState] = useState({ RoleID: null, RoleIDs: [], open: false, forBulk: false });
    const handleDelete = async (event) => {
        if (!event) {
            setDeleteModalState({ ...deleteModalState, open: false });
            return;
        }
        setDeleteModalState({ ...deleteModalState, RoleID: event.currentTarget.value, open: true });
    };

    return <>
        {props.hasPermission('role_list') ?
            <div className={classes.root} >
                <div className={classes.progressBarr}>
                    {fetching ? <LinearProgress color="secondary" /> : null}
                </div>
                <RoleList translations={translations} columnsState={props.columns} activeCustomer={activeCustomer} saveColumnsFilter={props.saveColumnsFilter} roles={props.role} fetchRoles={props.fetchRoles} hasPermission={props.hasPermission} hasAtLeastOnePermission={props.hasAtLeastOnePermission} handleDelete={handleDelete} />
            </div>
            : null}
        {props.hasPermission('role_delete') ? <RoleDelete translations={translations} activeCustomer={activeCustomer} entityName={'Role'} handleDelete={handleDelete} deleteModalState={deleteModalState} onDeleteRole={props.deleteRole} /> : null}
    </>
};

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        role: state.role.roles,
        translations: state.translate && state.translate.translations ? state.translate.translations.activeTranslations || {} : {},
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        hasPermission: permission => { return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'); },
        hasAtLeastOnePermission: permissions => { return state.core.login.permissions.some(permission => permissions.includes(permission)) || state.core.login.permissions.includes('*'); }
    };
};
const mapDispatchToProps = dispatch => {
    return {
        fetchRoles: filterParams => dispatch(actions.fetchRoles(filterParams)),
        loadRole: filterParams => dispatch(actions.loadRole(filterParams)),
        createRole: data => dispatch(actions.createRole(data)),
        editRole: data => dispatch((actions.editRole(data))),
        deleteRole: filterParams => dispatch((actions.deleteRole(filterParams))),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Role));
