
//Fetching roles, refactor later
export const FETCH_USER_ROLES_START = 'CORE|FETCH_USER_ROLES_START';
export const FETCH_USER_ROLES_SUCCESS = 'CORE|FETCH_USER_ROLES_SUCCESS';
export const FETCH_USER_ROLES_FAIL = 'CORE|FETCH_USER_ROLES_FAIL';

//Saving roles, refactor later
export const BULK_SAVE_USER_ROLES_START = 'CORE|BULK_SAVE_USER_ROLES_START';
export const BULK_SAVE_USER_ROLES_SUCCESS = 'CORE|BULK_SAVE_USER_ROLES_SUCCESS';
export const BULK_SAVE_USER_ROLES_FAIL = 'CORE|BULK_SAVE_USER_ROLES_FAIL';

//Fetch included permissions
export const FETCH_INCLUDED_PERMISSIONS_START = 'CORE|FETCH_INCLUDED_PERMISSIONS_START';
export const FETCH_INCLUDED_PERMISSIONS_SUCCESS = 'CORE|FETCH_INCLUDED_PERMISSIONS_SUCCESS';
export const FETCH_INCLUDED_PERMISSIONS_FAIL = 'CORE|FETCH_INCLUDED_PERMISSIONS_FAIL';

//Save included permissions
export const BULK_SAVE_INCLUDED_PERMISSIONS_START = 'CORE|BULK_SAVE_INCLUDED_PERMISSIONS_START';
export const BULK_SAVE_INCLUDED_PERMISSIONS_SUCCESS = 'CORE|BULK_SAVE_INCLUDED_PERMISSIONS_SUCCESS';
export const BULK_SAVE_INCLUDED_PERMISSIONS_FAIL = 'CORE|BULK_SAVE_INCLUDED_PERMISSIONS_FAIL';

//Fetch excluded permissions
export const FETCH_EXCLUDED_PERMISSIONS_START = 'CORE|FETCH_EXCLUDED_PERMISSIONS_START';
export const FETCH_EXCLUDED_PERMISSIONS_SUCCESS = 'CORE|FETCH_EXCLUDED_PERMISSIONS_SUCCESS';
export const FETCH_EXCLUDED_PERMISSIONS_FAIL = 'CORE|FETCH_EXCLUDED_PERMISSIONS_FAIL';


//Save excluded permissions
export const BULK_SAVE_EXCLUDED_PERMISSIONS_START = 'CORE|BULK_SAVE_EXCLUDED_PERMISSIONS_START';
export const BULK_SAVE_EXCLUDED_PERMISSIONS_SUCCESS = 'CORE|BULK_SAVE_EXCLUDED_PERMISSIONS_SUCCESS';
export const BULK_SAVE_EXCLUDED_PERMISSIONS_FAIL = 'CORE|BULK_SAVE_EXCLUDED_PERMISSIONS_FAIL';

//Fetch all user permissions
export const FETCH_ALL_USER_PERMISSIONS_START = 'CORE|FETCH_ALL_USER_PERMISSIONS_START';
export const FETCH_ALL_USER_PERMISSIONS_SUCCESS = 'CORE|FETCH_ALL_USER_PERMISSIONS_SUCCESS';
export const FETCH_ALL_USER_PERMISSIONS_FAIL = 'CORE|FETCH_ALL_USER_PERMISSIONS_FAIL';

//Fetch  user settings
export const FETCH_USER_SETTINGS_START = 'CORE|FETCH_USER_SETTINGS_START';
export const FETCH_USER_SETTINGS_SUCCESS = 'CORE|FETCH_USER_SETTINGS_SUCCESS';
export const FETCH_USER_SETTINGS_FAIL = 'CORE|FETCH_USER_SETTINGS_FAIL';