import React, { useState, useEffect } from 'react';

import Form from '../../np-form/Form';
import { Dialog, withStyles } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});

const updateFromState = props => {
    const { classes, scoreCriteria, sites } = props;
    const scoreWeights = scoreCriteria && scoreCriteria.ScoreWeights ? JSON.parse(scoreCriteria.ScoreWeights) : false;
    return {
        ActionGuid: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Action GUID',
                label: 'Action GUID',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                autoFocus: true
            },
            value: scoreCriteria ? scoreCriteria.ActionGuid : '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        Vitality: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Vitality',
                label: 'Vitality',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: scoreWeights ? scoreWeights.Vitality : '',
            validation: {
                isNumber: true,
                required: true
            },
            valid: true,
            touched: true
        },
        Memory: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Memory',
                label: 'Memory',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: scoreWeights ? scoreWeights.Memory : '',
            validation: {
                isNumber: true,
                required: true
            },
            valid: true,
            touched: true
        },
        Attention: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Attention',
                label: 'Attention',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: scoreWeights ? scoreWeights.Attention : '',
            validation: {
                isNumber: true,
                required: true
            },
            valid: true,
            touched: true
        },
        Logic: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Logic',
                label: 'Logic',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: scoreWeights ? scoreWeights.Logic : '',
            validation: {
                isNumber: true,
                required: true
            },
            valid: true,
            touched: true
        },
        Speed: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Speed',
                label: 'Speed',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: scoreWeights ? scoreWeights.Speed : '',
            validation: {
                isNumber: true,
                required: true
            },
            valid: true,
            touched: true
        },
        Accuracy: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Accuracy',
                label: 'Accuracy',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: scoreWeights ? scoreWeights.Accuracy : '',
            validation: {
                isNumber: true,
                required: true
            },
            valid: true,
            touched: true
        },
        CorrectionPenalty: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Correction penalty',
                label: 'Correction penalty',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: scoreCriteria ? scoreCriteria.CorrectionPenalty : '',
            validation: {
                isNumber: true,
                required: true
            },
            valid: true,
            touched: true
        },
        MaxCorrectionPenalty: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Max Correction penalty',
                label: 'Max Correction penalty',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
            },
            value: scoreCriteria ? scoreCriteria.MaxCorrectionPenalty : '',
            validation: {
                isNumber: true,
                required: true
            },
            valid: true,
            touched: true
        },
        SiteID: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Site',
                margin: "dense",
                fullWidth: true,
                options: sites ? [...sites] : []
            },
            formHelperText: "Please select site",
            value: scoreCriteria ? scoreCriteria.SiteID : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        }
    }
};

const ScoreCriteriaEdit = props => {
    const { open, classes, loading, scoreCriteria, activeCustomer } = props;

    const { editScoreCriteria, handleEdit } = props;
    const [scoreCriteriaForm, setScoreCriteriaForm] = useState(updateFromState({ ...props }));
    const [formIsValid, setFormIsValid] = useState(false);

    useEffect(() => {
        setScoreCriteriaForm(updateFromState({ ...props }));
    }, [props]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, scoreCriteriaForm);
        setScoreCriteriaForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const submitHandler = event => {
        event.preventDefault();
        const formData = formUtility.getFormData(scoreCriteriaForm);
        let scoreWeights = { ...formData };
        delete scoreWeights.ActionGuid;
        delete scoreWeights.CorrectionPenalty;
        delete scoreWeights.MaxCorrectionPenalty;
        delete scoreWeights.SiteID;
        delete scoreWeights.ScoreCriteriaID;

        formData['ScoreWeights'] = JSON.stringify(scoreWeights);

        editScoreCriteria({ ...formData, ActiveCustomerID: activeCustomer, ScoreCriteriaID: scoreCriteria.ScoreCriteriaID });
        handleEdit();
        setScoreCriteriaForm({});
        setFormIsValid(false);
    };

    const cancelHandler = () => {
        setScoreCriteriaForm({});
        setFormIsValid(false);
        handleEdit();
    };
    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            fullWidth={true}
            // onClose={cancelHandler}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    cancelHandler();
                }
            }}
        >
            <Form
                classes={classes}
                form={scoreCriteriaForm}
                formTitle={`Edit Score Criteria - ${scoreCriteria && scoreCriteria.ActionGuid ? `${scoreCriteria.ActionGuid}` : 'Default'}`}
                buttonText={props.hasPermission('score_criteria_save') ? "Save" : null}
                buttonTextCancel="Cancel"
                submitHandler={submitHandler}
                cancelHandler={cancelHandler}
                inputChangedHandler={inputChangedHandler}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                submitButtonStyle={formIsValid && !loading ? { color: 'white' } : null}
                cancelButtonDisabled={loading}
                formIsValid={formIsValid}
            />
        </Dialog>
    )
};

export default withStyles(styles)(ScoreCriteriaEdit);