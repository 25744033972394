import React, { useState } from 'react';
import { withStyles, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import * as action from '../../np-core/Auth/store/authActions';

import BillingAccountList from './BillingAccountList';
import * as actions from './store/billingAccountActions';
import BillingAccountCreate from './BillingAccountCreate';
import BillingAccountDelete from './BillingAccountDelete';
import BillingAccountPlansEdit from "./BillingAccountPlansEdit";

import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
    progressBarr: {
        width: '100%',
        height: 5
    }
});

const BillingAccount = props => {
    const { classes, billingAccounts, activeCustomer } = props;
    const fetching = billingAccounts.fetching;

    const [addModalState, setAddModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState({
        open: false,
        billingAccountID: null,
        billingAccountIDs: [],
        forBulk: false
    });

    const [modifyPlansState, setModifyPlansState] = useState({
        open: false,
        billingAccountID: null
    });

    const [customers, setCustomers] = useState([]);
    const [users, setUsers] = useState([]);
    const [plans, setPlans] = useState([]);

    const handleOpen = async () => {
        let data = await props.onListCustomers({ ActiveCustomerID: activeCustomer });
        let userData = await props.onListUsers({ ActiveCustomerID: activeCustomer });
        if (data.length === 0) {
            props.sendMessage('You need at least one Customer. Please create it.', 'warning');
            return;
        }
        setCustomers(data);
        setUsers(userData);
        setAddModalState(!addModalState);
    };

    const handleEdit = async event => {
        if (!event) {
            setEditModalState(!editModalState);
            return;
        }

        const billingAccountID = event.currentTarget.value;
        await props.onFetchBillingAccount({ AccountID: billingAccountID, ActiveCustomerID: activeCustomer });
        let data = await props.onListCustomers({ ActiveCustomerID: activeCustomer });
        let userData = await props.onListUsers({ ActiveCustomerID: activeCustomer });
        setCustomers(data);
        setUsers(userData);
        setEditModalState(!editModalState);
    };

    const handleDelete = async event => {
        if (!event) {
            setDeleteModalState({ open: false, billingAccountID: null, billingAccountIDs: [], forBulk: false });
            return;
        }
        const billingAccountID = event.currentTarget.value;
        setDeleteModalState({ ...deleteModalState, open: true, billingAccountID: billingAccountID });
    };

    const handleBulkDelete = billingAccountIDS => {
        setDeleteModalState({ ...deleteModalState, open: true, billingAccountIDs: billingAccountIDS, forBulk: true });
    }

    const handlePlansEdit = async event => {
        if (!event) {
            setModifyPlansState({ ...modifyPlansState, billingAccountID: null, open: false });
            return;
        }
        const billingAccountID = event.currentTarget.value;
        await props.onFetchBillingAccountPlans({ AccountID: billingAccountID, ActiveCustomerID: activeCustomer });
        let data = await props.onListPlans({ AccountID: billingAccountID, ActiveCustomerID: activeCustomer });
        setPlans(data);
        setModifyPlansState({ ...modifyPlansState, billingAccountID: billingAccountID, open: true });
    }

    return (
        <div className={classes.root}>
            <div className={classes.progressBarr}>
                {fetching ? <LinearProgress color="primary" /> : null}
            </div>
            {props.hasPermission('billing_account_list') ?
                <BillingAccountList activeCustomer={activeCustomer} hasAtLeastOnePermission={props.hasAtLeastOnePermission}
                    hasPermission={props.hasPermission} handleBulkDelete={handleBulkDelete}
                    billingAccounts={billingAccounts}
                    columnsState={props.columns}
                    saveColumnsFilter={props.saveColumnsFilter}
                    onFetchBillingAccounts={props.onFetchBillingAccounts} handleEdit={handleEdit}
                    handleDelete={handleDelete} handleCreate={handleOpen}
                    handlePlansEdit={handlePlansEdit} /> : null}
            {props.hasPermission('billing_account_create') ?
                <BillingAccountCreate activeCustomer={activeCustomer} loading={billingAccounts.creating} handleOpen={handleOpen} open={addModalState}
                    createBillingAccount={props.onCreateBillingAccount}
                    customers={customers}
                    users={users}
                    currentUserID={props.currentUserID}
                /> : null}
            {/*{props.hasPermission('billing_account_load') ?*/}
            {/*    <BillingAccountEdit handleEdit={handleEdit} open={editModalState} hasPermission={props.hasPermission}*/}
            {/*                        loading={billingAccounts.saving} billingAccount={billingAccounts.loaded}*/}
            {/*                        editBillingAccount={props.onEditBillingAccount} customers={customers}/> : null}*/}
            {props.hasPermission('billing_account_load') ?
                <BillingAccountPlansEdit activeCustomer={activeCustomer} handlePlansEdit={handlePlansEdit} billingAccount={billingAccounts}
                    plans={plans} open={modifyPlansState.open}
                    hasPermission={props.hasPermission}
                    assignPlans={props.onAssignPlans}
                    modifyPlansState={modifyPlansState}
                /> : null}
            {props.hasPermission('billing_account_delete') ?
                <BillingAccountDelete activeCustomer={activeCustomer} bulkDeleteBillingAccounts={props.onBulkDeleteBillingAccounts}
                    entityName={'Billing Account'} handleDelete={handleDelete}
                    deleteModalState={deleteModalState}
                    deleteBillingAccount={props.onDeleteBillingAccount} /> : null}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        billingAccounts: state.billing.accounts,
        currentUserID: state.core.login.user.UserID,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        hasPermission: permission => {
            return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*');
        },
        hasAtLeastOnePermission: permissions => {
            return state.core.login.permissions.some(permission => permissions.includes(permission)) || state.core.login.permissions.includes('*');
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchBillingAccounts: filter => dispatch(actions.fetchBillingAccount(filter)),
        onCreateBillingAccount: billingAccount => dispatch(actions.createBillingAccount(billingAccount)),
        onFetchBillingAccount: billingAccount => dispatch(actions.loadBillingAccount(billingAccount)),
        onFetchBillingAccountPlans: billingAccount => dispatch(actions.onAccountPlansList(billingAccount)),
        onEditBillingAccount: billingAccount => dispatch(actions.editBillingAccount(billingAccount)),
        onDeleteBillingAccount: billingAccount => dispatch(actions.deleteBillingAccount(billingAccount)),
        onBulkDeleteBillingAccounts: billingAccount => dispatch(actions.bulkDeleteBillingAccounts(billingAccount)),
        onAssignPlans: billingAccount => dispatch(actions.assignPlans(billingAccount)),
        onListPlans: (id) => dispatch(actions.listPlans(id)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),
        onListCustomers: (id) => dispatch(actions.listCustomers(id)),
        onListUsers: (id) => dispatch(actions.listUsers(id)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(BillingAccount));