import React, {useState, useEffect} from 'react';

import Form from '../../np-form/Form';
import {Dialog, withStyles} from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import {isSuperUser} from '../../np-utilities';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    },
    button: {
        color: 'white'
    }
});

const updateFormState = props => {
    const {classes} = props;
    return {
        Name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Name',
                label: 'Name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                autoFocus: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        Description: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Description',
                label: 'Description',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        }
    };
};

const EnvironmentCreate = props => {
    const {open, classes, loading, activeCustomer} = props;
    const {handleOpen, createEnvironment} = props;

    const [formIsValid, setFormIsValid] = useState(false);
    const [trainingForm, setEnvironmentForm] = useState(updateFormState({...props}));

    useEffect(() => {
        setEnvironmentForm(updateFormState({...props}));
    }, [props]);


    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, trainingForm);
        setEnvironmentForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const submitHandler = event => {
        event.preventDefault();
        const formData = formUtility.getFormData(trainingForm);

        createEnvironment({...formData, ActiveCustomerID: activeCustomer});
        handleOpen();
        setEnvironmentForm({});
        setFormIsValid(false);
    };

    const cancelHandler = () => {
        setEnvironmentForm({});
        setFormIsValid(false);
        handleOpen();
    };

    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            // onClose={handleOpen}
            fullWidth={true}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleOpen();
                }
            }}
        >
            <Form
                formIsValid={formIsValid}
                classes={classes}
                form={trainingForm}
                formTitle="Create environment"
                buttonText="Save"
                buttonTextCancel="Cancel"
                submitHandler={submitHandler}
                cancelHandler={cancelHandler}
                inputChangedHandler={inputChangedHandler}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                submitButtonStyle={formIsValid && !loading ? {color: 'white'} : null}
                cancelButtonDisabled={loading}
                submitButtonDisabled={loading}
            />
        </Dialog>
    )
};

export default withStyles(styles)(EnvironmentCreate);