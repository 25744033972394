import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';

import NPDatatable from '../../np-components/Datatable';
import { Paper, Tooltip, IconButton } from '@material-ui/core';
import useListFilter from '../../np-components/Datatable/listFilter';
import { Edit, Delete} from '@material-ui/icons';
import { isSuperUser } from '../../np-utilities';
import { parseURL, BackButton } from '../../ms-utilities';

const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#d50000" : "#1b2a33",
        '&:hover': {
            backgroundColor: "#757575",
        },
    },
    chipEnvironmentActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    chipEnvironmentInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    media: {
        width: 50,
        height: 50
    }
});

const updateFilterParams = props => {
    const { listFilter } = props;
    let searchParams = parseURL(window.location.search);
    return { ...listFilter, ...searchParams };
};

const EnvironmentList = props => {
    const { classes, environments, onFetchEnvironments, columnsState, activeCustomer } = props;
    const environmentsCount = environments.count;
    const environmentsList = environments.list;

    let tableID = 'Environment';
    let visible = columnsState && columnsState[tableID];

    const columns = [
        {
            displayName: 'Action',
            backendName: 'Action',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: 'Name',
            backendName: 'Name',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Name'] : true,
            }
        },
        {
            displayName: 'Description',
            backendName: 'Description',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Description'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => { if (column.backendName !== 'Action') backendNames.push(column.backendName); return null; });
    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });

    if (!props.hasAtLeastOnePermission(['environment_create', 'environment_load', 'environment_save', 'environment_delete'])) {
        columns.splice(0, 1);
    }

    useEffect(() => {
        if (listFilter.filter) {
            if (!listFilter.filter.q) {
                listFilter.filter.q = {};
            }
            if (!listFilter.filter.q.and) {
                listFilter.filter.q.and = {};
            }
            listFilter.filter.q = { ...listFilter.filter.q, and: updateFilterParams({ listFilter: listFilter.filter.q.and }) };
            onFetchEnvironments({ ...listFilter.filter, ActiveCustomerID: activeCustomer });
        }
    }, [environments.created, activeCustomer, environments.deleted, environments.bulkDeleted, environments.edited, listFilter.filter, onFetchEnvironments]);


    const createActions = environmentID => {
        return <>
            {
                props.hasPermission('environment_save') ? <Tooltip title="Edit environment">
                    <IconButton value={environmentID} aria-label="Edit environment" onClick={props.handleEdit}>
                        <Edit />
                    </IconButton>
                </Tooltip> : <div></div>
            }
            {
                props.hasPermission('environment_delete') ?
                    <Tooltip title="Delete environment">
                        <IconButton value={environmentID} aria-label="Delete environment" onClick={props.handleDelete}>
                            <Delete />
                        </IconButton>
                    </Tooltip> : <div></div>
            }
        </>
    };


    const tableOptions = {
        isSelectable: props.hasPermission('environment_delete'),
        tableSearch: false,
        tableID: tableID,
        saveColumn: true,
        selectOptions: { printing: false, generatingReport: false, deleting: props.hasPermission('environment_delete'), creating: props.hasPermission('environment_create') },
        rowsPerPage: 20,
        count: environmentsCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'Environments',
        noMatch: "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return props.handleBulkDelete(IDs);
        },
        onSaveFilter: (columns) => {
            let objToSendColumn = {};
            let objColumns = {};
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible;
                return objColumns
            });
            objToSendColumn[`${tableID}`] = objColumns;
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }
    };


    let counter = 0;
    let environmentsData = environmentsList.map(environment => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = environment.EnvironmentID;
        returnObj.visible = true;
        returnObj.values = [
            createActions(environment.EnvironmentID),
            environment.Name,
            environment.Description,
        ];
        if (!props.hasAtLeastOnePermission(['environment_create', 'environment_load', 'environment_delete', 'environment_delete'])) {
            returnObj.values.splice(0, 1);
        }
        counter++;
        return returnObj;
    });

    return (
        <Paper>
            {BackButton()}
            <NPDatatable handleCreate={props.handleCreate} customStyles={classes} data={environmentsData} columns={columns} options={tableOptions} />
        </Paper>
    );
}

export default withStyles(styles)(EnvironmentList);