import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    withStyles
} from '@material-ui/core';

const styles = () => ({
    buttonDanger: {
        color: '#eceff1',
        backgroundColor: '#f44336'
    },
    buttonSafe: {
        color: '#eceff1',
        backgroundColor: '#4caf50'
    }
});
const ModuleDelete = props => {
    let {classes, deleteModalState, entityName, activeCustomer} = props;

    const {handleDelete, bulkDeleteModules, deleteModule} = props;
    const handleCloseSafe = () => {
        handleDelete();
    };

    const handleCloseDanger = async () => {
        if (deleteModalState.forBulk) {
            await bulkDeleteModules({
                ModuleIDs: deleteModalState.moduleIDs,
                ActiveCustomerID: activeCustomer
            });
            handleDelete();
            return;
        }
        await deleteModule({ModuleID: deleteModalState.moduleID, ActiveCustomerID: activeCustomer});
        handleDelete();
    };

    return (
        <Dialog
            open={deleteModalState.open}
            // onClose={handleCloseSafe}
            aria-labelledby="form-dialog-title"
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            fullWidth={true}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleCloseSafe();
                }
            }}
        >
            <DialogTitle
                id="form-dialog-title">{deleteModalState.forBulk ? 'Bulk delete' : 'Delete'} {entityName}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Be careful when deleting something.<br/>
                    Are you sure you want to delete {deleteModalState.forBulk ? 'these' : 'this'} {entityName}{deleteModalState.forBulk ? `s'` : `'s`} data?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseSafe} variant="contained" className={classes.buttonSafe}>
                    No
                </Button>
                <Button onClick={handleCloseDanger} variant="contained" className={classes.buttonDanger}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(ModuleDelete);