import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Paper, Tooltip, IconButton } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import useListFilter from '../../np-components/Datatable/listFilter';
import NPDatatable from '../../np-components/Datatable';
import { isSuperUser } from '../../np-utilities';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

const styles = theme => ({
    userActive: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15
    },
    userInactive: {
        color: '#b71c1c',
        padding: 1,
        margin: 1,
        fontSize: 15
    },
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#e62440" : "#7dc5e2",
        '&:hover': {
            backgroundColor: "#757575",
        },
    }
});

const RoleList = props => {
    const { classes, roles, fetchRoles, columnsState, activeCustomer, translations } = props;
    const roleCount = roles.count;
    const roleList = roles.list;
    let tableID = 'Role';
    let visible = columnsState && columnsState[tableID];

    const navigate = useNavigate();


    const columns = [
        {
            displayName: translations["action"] || 'Action',
            backendName: 'Action',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: translations["name"] || 'Name',
            backendName: 'Name',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['Name'] : true,
            }
        },
        {
            displayName: translations["fancy_name"] || 'Fancy name',
            backendName: 'FancyName',
            options: {
                align: 'left',
                sortable: true,
                searchable: true,
                visible: visible ? visible['FancyName'] : true,
            }
        },
        {
            displayName: translations["type"] || 'Type',
            backendName: 'Type',
            options: {
                align: 'left',
                sortable: true,
                order: 'desc',
                searchable: true,
                visible: visible ? visible['Type'] : true,
            }
        },
        {
            displayName: translations["module"] || 'Module',
            backendName: 'Module',
            options: {
                align: 'left',
                sortable: true,
                order: 'desc',
                searchable: true,
                visible: visible ? visible['Module'] : true,
            }
        },

        {
            displayName: translations["locked"] || 'Locked',
            backendName: 'Locked',
            options: {
                align: 'left',
                sortable: true,
                order: 'desc',
                searchable: false,
                visible: visible ? visible['Locked'] : true,
            }
        },


        {
            displayName: translations["create_at"] || 'Create at',
            backendName: 'CreatedAt',
            options: {
                align: 'left',
                sortable: true,
                order: 'desc',
                searchable: false,
                visible: visible ? visible['CreatedAt'] : true,
            }
        }
    ];

    let backendNames = [];
    columns.map(column => { if (column.backendName !== 'Action') backendNames.push(column.backendName); return null; })
    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });
    if (!props.hasAtLeastOnePermission(['role_load', 'role_delete'])) {
        columns.splice(0, 1);
    }
    const [redirectOptions, setRedirectOptions] = useState({ redirect: false, RoleID: 0, redirectPath: '' });

    const createActions = (roleID, locked) => {
        return <>
            {
                props.hasPermission('role_load') ?
                    <Tooltip title="Edit role ">
                        <IconButton value={roleID} onClick={() => setRedirectOptions({ redirect: true, redirectPath: `/roles/${roleID}/edit` })} aria-label="Edit role"  >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    : null
            }
            {
                (props.hasPermission('role_delete') === true && locked !== false) || props.hasPermission('*') ?

                    <Tooltip title="Delete role ">
                        <IconButton value={roleID} aria-label="Delete role" onClick={props.handleDelete} >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    : null
            }
        </>
    };
    useEffect(() => {
        // fetch calls
        if (listFilter.filter) {
            fetchRoles({ ...listFilter.filter, ActiveCustomerID: activeCustomer });
        }
    }, [roles.created, roles.deleted, roles.bulkDeleted, activeCustomer, roles.edited, fetchRoles, listFilter.filter]);
    const tableOptions = {
        isSelectable: false,
        tableSearch: false,
        tableID: tableID,
        saveColumn: true,
        page: 0,
        selectOptions: { createButtonName: translations['create_new'] || 'Create new', printing: false, generatingReport: false, creating: props.hasPermission('role_create') ? true : false, deleting: false },
        rowsPerPage: 20,
        count: roleCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: translations['roles'] || 'Roles',
        noMatch: translations['table_no_match'] || "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onSaveFilter: (columns) => {
            let objToSendColumn = {}
            let objColumns = {}
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible
                return objColumns
            })
            objToSendColumn[`${tableID}`] = objColumns
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }

    };

    const customerHandlerCreate = () => {
        setRedirectOptions({ redirect: true, redirectPath: `/roles/create` });
        navigate(redirectOptions.redirectPath)
    };

    let counter = 0;
    let roleData = roleList.map(role => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = role.RoleID
        returnObj.visible = true;
        returnObj.values = [
            createActions(role.RoleID, role.Locked),
            role && role.Name ? role.Name : '',
            role && role.FancyName ? role.FancyName : '',
            role && role.Type ? role.Type : '',
            role && role.Module ? role.Module : '',
            role && role.Locked ? role.Locked ? translations['yes'] || 'Yes' : translations['no'] || 'No' : 'No',
            role && role.CreatedAt ? role.CreatedAt : '',
        ];
        if (!props.hasAtLeastOnePermission(['role_load', 'role_delete'])) {
            returnObj.values.splice(0, 1);
        }
        counter++;
        return returnObj;
    });

    if (redirectOptions.redirect) {
        return <Routes><Route path="*" element={<Navigate to={redirectOptions.redirectPath} />} /></Routes>;
    }

    return (
        <Paper>
            <NPDatatable handleCreate={() => customerHandlerCreate()} customStyles={classes} data={roleData} columns={columns} options={tableOptions} />
        </Paper>
    );
}

export default (withStyles(styles)(RoleList));