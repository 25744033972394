import * as actionTypes from './actionTypes';

const initialState = {
    // load
    plugins: [],
    logLevels: ['debug', 'info', 'warn', 'error', 'off'],
    loading: false,
    // save
    saving: false
};

const loadPluginsStart = state => {
    return { ...state, loading: true };
};

const loadPluginsSuccess = (state, action) => {
    return {
        ...state,
        plugins: action.plugins,
        loading: false
    };
};

const loadPluginsFail = state => {
    return {
        ...state,
        loading: false
    };
};

const savePluginLevelStart = state => {
    return { ...state, saving: true };
};

const savePluginLevelSuccess = (state, action) => {
    return {
        ...state,
        saving: false
    };
};

const savePluginLevelFail = (state, action) => {
    return {
        ...state,
        saving: false
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // Loading plugins
        case actionTypes.LOAD_PLUGINS_START:
            return loadPluginsStart(state, action);
        case actionTypes.LOAD_PLUGINS_SUCCESS:
            return loadPluginsSuccess(state, action);
        case actionTypes.LOAD_PLUGINS_FAIL:
            return loadPluginsFail(state, action);

        // Saving plugin level
        case actionTypes.SAVE_PLUGIN_LEVEL_START:
            return savePluginLevelStart(state, action);
        case actionTypes.SAVE_PLUGIN_LEVEL_SUCCESS:
            return savePluginLevelSuccess(state, action);
        case actionTypes.SAVE_PLUGIN_LEVEL_FAIL:
            return savePluginLevelFail(state, action);

        default:
            return state;
    }
};

export default reducer;
