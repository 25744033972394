import React, {useState , useEffect} from 'react';
import Form from '../np-form/Form';
import {withStyles} from '@material-ui/core';
import * as formUtility from '../np-form/utility';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
    }
});


const updateFromState = props => {
    const { classes, userInfo } = props;
    return {
        FirstName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'First name',
                label: 'First name',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: userInfo ? userInfo.FirstName : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        LastName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Last name',
                label: 'Last name',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: userInfo ? userInfo.LastName : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Username: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Username',
                label: 'Username',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true,
                disabled: true
            },
            value: userInfo ? userInfo.Username : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Email: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Email',
                label: 'Email',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true,
            },
            value: userInfo ? userInfo.Email : '',
            validation: {
                required: false,
                isEmail: true
            },
            valid: true,
            touched: true
        },
        PhoneNumber: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Phone number',
                className: classes && classes.inputs ? classes.inputs : null,
                label: 'Phone number',
                error: false,
                fullWidth: true
            },
            value: userInfo ? userInfo.PhoneNumber : '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        Password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Password',
                label: 'Password',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        RepeatPassword: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Repeat password ',
                label: 'Password repeat',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                error: false,
                fullWidth: true
            },
            value: '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        }
    }
};

const UserInformation = props => {
    const {userInfo, classes ,activeCustomer , setUser} = props;
    const [userForm, setUserForm] = useState(updateFromState(props));
    const [userFormValid, setUserFormValid] = useState(false);

    useEffect(() => {
        setUserForm(updateFromState({ ...props }));
    }, [props]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, userForm);
        setUserForm(updateState.myForm);
        if (inputIdentifier === 'Email') {
            setUserForm({
                ...updateState.myForm,
                Email: {
                    ...updateState.myForm.Email,
                    elementConfig: {
                        ...updateState.myForm.Email.elementConfig,
                        error: !updateState.myForm.Email.valid && updateState.myForm.Email.value.length > 0
                    }
                }
            })
        }

        if (updateState.myForm.RepeatPassword.value !== updateState.myForm.Password.value && (updateState.myForm.RepeatPassword.value !== '' || updateState.myForm.Password.value !== '')) {
            updateState.formIsValid = false;
        }

        if (inputIdentifier === 'Password' && updateState.myForm.RepeatPassword.value !== updateState.myForm.Password.value) {
            updateState.formIsValid = false;
        }

        if (inputIdentifier === 'RepeatPassword') {
            if (updateState.myForm.RepeatPassword.value !== updateState.myForm.Password.value) {
                updateState.formIsValid = false;
            }
            setUserForm({
                ...updateState.myForm,
                RepeatPassword: {
                    ...updateState.myForm.RepeatPassword,
                    elementConfig: {
                        ...updateState.myForm.RepeatPassword.elementConfig,
                        error: updateState.myForm.RepeatPassword.value !== updateState.myForm.Password.value
                    }
                }
            })
        }
        setUserFormValid(updateState.formIsValid);
    };

    const submitHandler = async event => {
        event.preventDefault();
        const formUser = formUtility.getFormData(userForm);
        if (!formUser.Password) {
            delete formUser.Password;
            delete formUser.RepeatPassword;
        }

        if (formUser.Password && formUser.Password !== formUser.RepeatPassword) {
            setUserForm(updateFromState(props));
            return props.sendMessage('Repeated password must be the same as the original password. Please enter it again.', 'warning');
        }
        delete formUser.Username;
        await props.saveUser({...formUser, UserID: userInfo.UserID , ActiveCustomerID:activeCustomer });
        let data = await props.loadUser({ ActiveCustomerID: activeCustomer , UserID:userInfo.UserID  });
        setUser(data)
        setUserForm(updateFromState(props));
        setUserFormValid(false);
        
    };
    return (
        
        <Form
            formIsValid={userFormValid}
            classes={classes}
            form={userForm}
            inputChangedHandler={inputChangedHandler}
            submitHandler={submitHandler}
            buttonText={'Save'}
            submitButtonStyle={userFormValid ? {color: 'white'} : null}

        />
    )
};

export default withStyles(styles, {withTheme: true})(UserInformation);