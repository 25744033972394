import React, {useState, useEffect} from 'react';
import Form from '../../np-form/Form';
import {Dialog, withStyles, Stepper, StepLabel, Step, Button, Tooltip} from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import {isSuperUser} from '../../np-utilities';
import Info from '@material-ui/icons/Info';
import moment from 'moment';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});

const updateFormStateOne = (customers, users, currentUserID) => {
    const billingModes = [{value: 'prepaid', displayValue: 'Prepaid'}, {value: 'postpaid', displayValue: 'Contract'}];
    const accountStatuses = [
        {value: 'active', displayValue: 'Active'},
        {value: 'prepared', displayValue: 'Prepared'},
        {value: 'inactive', displayValue: 'Inactive'},
        {value: 'expired', displayValue: 'Expired'}];
    const termsOfPayment = [
        {value: 'dor', displayValue: 'Due on Receipt'},
        {value: 'pia', displayValue: 'Payment in advance'},
        {value: 'eom', displayValue: 'End of Month'},
        {value: 'cod', displayValue: 'Cash on Delivery'},
        {value: 'net7', displayValue: '7 days after invoice date'},
        {value: 'net10', displayValue: '10 days after invoice date'},
        {value: 'net15', displayValue: '15 days after invoice date'},
        {value: 'net30', displayValue: '30 days after invoice date'},
        {value: 'net60', displayValue: '60 days after invoice date'},
    ];

    const languages = [
        {value: 'en', displayValue: 'English'},
        {value: 'de', displayValue: 'German'},
        {value: 'sr', displayValue: 'Serbian'}
    ];

    return {
        BillingMode: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Billing mode',
                margin: "dense",
                fullWidth: true,
                options: billingModes ? [...billingModes] : []
            },
            formHelperText: "Please select billing mode",
            value: billingModes && billingModes.length > 0 ? billingModes[0].value : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Status: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Billing account status',
                margin: "dense",
                fullWidth: true,
                options: accountStatuses ? [...accountStatuses] : []
            },
            formHelperText: "Please select billing account status",
            value: accountStatuses && accountStatuses.length > 0 ? accountStatuses[0].value : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        ActivationDate: {
            elementType: 'datePicker',
            elementConfig: {
                type: 'datePicker',
                label: 'Activation date',
                margin: "dense",
                fullWidth: true,
                placeholder: 'Please select the account activation day (default is today)',
                disablePast: true,
                clearable: true
            },
            value: moment.now(),
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        ExpiresAt: {
            elementType: 'datePicker',
            elementConfig: {
                type: 'datePicker',
                label: 'Expires at',
                margin: "dense",
                fullWidth: true,
                placeholder: 'Please select the expiration date',
                disablePast: true,
                clearable: true,
            },
            value: moment().add(1, 'years'),
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        BillingDay: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                label: 'Billing day',
                margin: "dense",
                fullWidth: true,
                placeholder: 'Please select the account billing day',
                inputProps: {min: "1", max: "28"}
            },
            value: 1,
            validation: {
                required: true,
                isNumeric: true,
                min: 1,
                max: 28
            },
            valid: true,
            touched: false
        },
        VAT: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'VAT Number',
                label: 'VAT Number',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        TaxClassification: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Tax Classification',
                label: 'Tax Classification',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        TermsOfPayment: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Terms Of Payment',
                margin: "dense",
                fullWidth: true,
                options: termsOfPayment ? [...termsOfPayment] : []
            },
            formHelperText: "Please select the terms of payment",
            value: termsOfPayment ? termsOfPayment[0].value : null,
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        Accountant: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'User',
                margin: "dense",
                fullWidth: true,
                options: users ? [...users] : []
            },
            formHelperText: "Please select accountant",
            value: currentUserID,
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        Language: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Communication language',
                margin: "dense",
                fullWidth: true,
                options: languages ? [...languages] : []
            },
            formHelperText: "Please select language",
            value: languages ? languages[0].value : '',
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        PhoneNumber1: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Phone number',
                label: 'Phone number',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Email: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Email',
                label: 'Email',
                margin: "dense",
                fullWidth: true,
            },
            value: '',
            validation: {
                required: false,
                isEmail: true
            },
            valid: true,
            touched: true
        },
        CustomerAddress: {
            elementType: 'checkbox',
            elementConfig: {
                type: 'checkbox',
                label: null,
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [{
                    ID: 'checkboxID',
                    Checked: false,
                    Name: 'Delivery address same as customer address? (Checked - Yes)'
                }]
            },
            value: false,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        StreetAddress: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Street Address',
                label: 'Street Address',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: customers && customers.length > 0 ? customers[0].StreetAddress : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        PostCode: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Post code',
                label: 'Post code',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: customers && customers.length > 0 ? customers[0].PostCode : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        City: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'City',
                label: 'City',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: customers && customers.length > 0 ? customers[0].City : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Country: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Country',
                label: 'Country',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: customers && customers.length > 0 ? customers[0].Country : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
    };
};

const CustomerCreate = props => {
    const {open, classes, customers, users, currentUserID, activeCustomer} = props;
    const steps = ['Create customer', 'Create account', 'Create user'];
    const [activeStep, setActiveStep] = useState(0);
    const [without, setWithout] = useState(false);
    const [skipped, setSkipped] = React.useState(new Set());

    const renderToolTip = (key = 'msqt_basic') => {
        let formHelperText = null;
        switch (key) {
            case 'msqt_basic':
                formHelperText = <div><p>Passwords must be at least 3 characters in length</p></div>;
                break;
            case 'msqt_standard':
                formHelperText =
                    <div><p>Passwords must be at least 5 characters in length</p><p>Passwords must have at least one
                        upper case</p><p>Passwords must have at least one lower case</p><p>Passwords must have at least
                        one numeric</p></div>;
                break;
            case 'microsoft':
                formHelperText =
                    <div><p>Passwords must be at least 8 characters in length</p><p>Passwords must have at least one
                        upper case</p><p>Passwords must have at least one lower case</p><p>Passwords must have at least
                        one numeric</p><p>Passwords must have at least one upper case</p><p>Passwords must have at least
                        one lower special character</p><p>Password should not contain user or customer name</p></div>;
                break;
            case 'nist':
                formHelperText =
                    <div><p>Passwords must be at least 8 characters in length</p><p>Passwords must have at least one
                        upper case</p><p>Passwords must have at least one lower case</p><p>Passwords must have at least
                        one numeric</p><p>Passwords must have at least one upper case</p><p>Passwords must have at least
                        one lower special character</p><p>Password should not contain user or customer name</p>
                        <p>Passwords must not contain repetitive characters</p></div>;
                break;
            default:
                formHelperText = <div><p>Passwords must be at least 3 characters in length</p></div>;
                break;
        }
        return <Tooltip title={formHelperText} placement='right-start'>
            <Info/>
        </Tooltip>
    };

    // First form
    const firstForm = {
        Name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Name',
                label: 'Name',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true,
                autoFocus: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        StreetAddress: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Street Address',
                label: 'Street Address',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        PostCode: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Post code',
                label: 'Post code',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        City: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'City',
                label: 'City',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        Country: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Country',
                label: 'Country',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        Status: {
            elementType: 'select_v2',
            inputLabel: 'Status',
            elementConfig: {
                type: 'select',
                label: 'Status',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true,
                options: [{value: 'active', displayValue: 'Active'}, {value: 'inactive', displayValue: 'Inactive'}]
            },
            formHelperText: "Please select customer status",
            value: 'active',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        PasswordComplexity: {
            elementType: 'select_v2',
            inputLabel: 'Password complexity',
            elementConfig: {
                type: 'select',
                label: 'PasswordComplexity',
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [{value: 'msqt_basic', displayValue: 'MSQT basic'}, {
                    value: 'msqt_standard',
                    displayValue: 'MSQT standard'
                }, {value: 'microsoft', displayValue: 'Microsoft'}, {
                    value: 'nist',
                    displayValue: 'National Institute of Standards and Technology'
                }]
            },
            value: 'msqt_basic',
            formHelperText: renderToolTip(),
            validation: {
                required: true
            },
            valid: true,
            touched: true
        }
    };

    const [customerForm, setCustomerForm] = useState(firstForm);
    const [customerFormValid, setCustomerFormValid] = useState(false);

    const customerFormInputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, customerForm);
        setCustomerForm({
            ...updateState.myForm,
            PasswordComplexity: {
                ...updateState.myForm.PasswordComplexity,
                formHelperText: renderToolTip(updateState.myForm.PasswordComplexity.value)
            }
        });
        setCustomerFormValid(updateState.formIsValid)
    };

    // Second form
    const secondForm = {
        FirstName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Name',
                label: 'Name',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true,
                autoFocus: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        LastName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Last name',
                label: 'Last name',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        Username: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Username',
                label: 'Username',
                className: classes.inputs,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: true
        },
        Email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: 'E-Mail',
                className: classes.inputs,
                label: 'E-Mail',
                error: false,
                fullWidth: true
            },
            value: '',
            validation: {
                required: true,
                isEmail: true
            },
            valid: true,
            touched: true

        },
        PhoneNumber: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Phone number',
                className: classes && classes.inputs ? classes.inputs : null,
                label: 'Phone number',
                error: false,
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Password',
                label: 'Password',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        RepeatPassword: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Repeat password ',
                label: 'Password repeat',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                error: false,
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        UserStatus: {
            elementType: 'select_v2',
            elementConfig: {
                fullWidth: true,
                options: [{value: 'active', displayValue: 'Active'}, {value: 'inactive', displayValue: 'Inactive'}]
            },
            value: 'active',
            validation: {},
            valid: true,
            formHelperText: 'Please select status'
        }
    };

    const [userForm, setUserForm] = useState(secondForm);
    const [userFormValid, setUserFormValid] = useState(false);
    const [validMail, setValidMail] = useState(false);

    const userInfoFormInputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, userForm);
        setUserForm(updateState.myForm);
        if (inputIdentifier === 'Email') {
            setUserForm({
                ...updateState.myForm,
                Email: {
                    ...updateState.myForm.Email,
                    elementConfig: {...updateState.myForm.Email.elementConfig, error: !updateState.myForm.Email.valid}
                }
            });
            setUserForm({
                ...updateState.myForm,
                Email: {
                    ...updateState.myForm.Email,
                    elementConfig: {
                        ...updateState.myForm.Email.elementConfig,
                        error: !updateState.myForm.Email.valid && updateState.myForm.Email.value.length > 0
                    }
                }
            })
            setValidMail(true)
        }
        setUserFormValid(updateState.formIsValid);
        if (validMail && updateState.myForm.Email.value.length === 0) {
            setUserFormValid(true);
        }
        if (updateState.myForm.Password.value.length === 0 || updateState.myForm.FirstName.value.length === 0 || updateState.myForm.Username.value.length === 0 || updateState.myForm.LastName.value.length === 0) {
            setUserFormValid(false);
        }
    };

    // third form

    const [billingAccountForm, setBillingAccountForm] = useState(updateFormStateOne(customers, users, currentUserID));
    const [billingAccountFormValid, setBillingAccountFormValid] = useState(false);

    useEffect(() => {
        setBillingAccountForm(updateFormStateOne(customers, users, currentUserID))
    }, [customers, users, currentUserID]);

    const inputChangedHandlerBillingForm = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, billingAccountForm);
        setBillingAccountForm(updateState.myForm);

        setBillingAccountFormValid(updateState.formIsValid);
        if (inputIdentifier === 'Email') {
            setBillingAccountForm({
                ...updateState.myForm,
                Email: {
                    ...updateState.myForm.Email,
                    elementConfig: {...updateState.myForm.Email.elementConfig, error: !updateState.myForm.Email.valid}
                }
            });
            setBillingAccountForm({
                ...updateState.myForm,
                Email: {
                    ...updateState.myForm.Email,
                    elementConfig: {
                        ...updateState.myForm.Email.elementConfig,
                        error: !updateState.myForm.Email.valid && updateState.myForm.Email.value.length > 0
                    }
                }
            })
        }
        if (updateState.myForm.Email.value.length === 0) {
            setBillingAccountFormValid(true);
        }
        setBillingAccountFormValid(updateState.formIsValid);

    };

    const checkboxChangedHandler = (event, inputIdentifier) => {
        let updateState = formUtility.getNewStateOnCheckboxChange(event, inputIdentifier, billingAccountForm);
        const formData = formUtility.getFormData(updateState.myForm);
        const address = formUtility.getFormData(customerForm);
        if (formData.CustomerAddress) {
            updateState.myForm.City['value'] = address.City;
            updateState.myForm.PostCode['value'] = address.PostCode;
            updateState.myForm.StreetAddress['value'] = address.StreetAddress;
            updateState.myForm.Country['value'] = address.Country;
            updateState.myForm.City.elementConfig['disabled'] = true;
            updateState.myForm.PostCode.elementConfig['disabled'] = true;
            updateState.myForm.StreetAddress.elementConfig['disabled'] = true;
            updateState.myForm.Country.elementConfig['disabled'] = true;
        } else {
            updateState.myForm.City.elementConfig['disabled'] = false;
            updateState.myForm.PostCode.elementConfig['disabled'] = false;
            updateState.myForm.StreetAddress.elementConfig['disabled'] = false;
            updateState.myForm.Country.elementConfig['disabled'] = false;
        }
        setBillingAccountForm(updateState.myForm);
    };

    const dateChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnDateChange(event, inputIdentifier, billingAccountForm);
        setBillingAccountForm(updateState.myForm);
    };

    //Submit all form
    const submitHandler = async event => {
        event.preventDefault();
        const formCustomer = formUtility.getFormData(customerForm);
        const formUser = formUtility.getFormData(userForm);
        const formBilling = formUtility.getFormData(billingAccountForm);

        delete formCustomer['Assign'];
        const Address = {
            StreetAddress: formCustomer.StreetAddress,
            City: formCustomer.City,
            PostCode: formCustomer.PostCode,
            Country: formCustomer.Country
        };
        const AddressAccount = {
            StreetAddress: formBilling.StreetAddress,
            City: formBilling.City,
            PostCode: formBilling.PostCode,
            Country: formBilling.Country
        };
        formBilling['Address'] = AddressAccount

        formCustomer.Status = formCustomer.Status === 'active' ? 1 : 0;
        formCustomer['Address'] = JSON.stringify(Address);

        await props.onCreateCustomer({
            ...formCustomer, ...formUser,
            Account: {...formBilling},
            'CreateUser': true,
            ActiveCustomerID: activeCustomer
        });

        if (props.customers.created === null) {
            return;
        }

        setCustomerForm(firstForm);
        setCustomerFormValid(false);
        setUserForm(secondForm);
        setUserFormValid(false);
        setBillingAccountForm(updateFormStateOne(customers, users, currentUserID));
        setActiveStep(0);
        props.handleOpen();
        setWithout(false)
    };

    //Submit only customer 

    const skipSaveHandler = async event => {
        event.preventDefault();
        const formCustomer = formUtility.getFormData(customerForm);
        const Address = {
            StreetAddress: formCustomer.StreetAddress,
            City: formCustomer.City,
            PostCode: formCustomer.PostCode,
            Country: formCustomer.Country
        };
        formCustomer.Status = formCustomer.Status === 'active' ? 1 : 0;
        formCustomer['Address'] = JSON.stringify(Address);

        await props.onCreateCustomer({...formCustomer, 'CreateUser': false});
        setCustomerForm(firstForm);
        setCustomerFormValid(false);
        setUserForm(secondForm);
        setUserFormValid(false);
        setBillingAccountForm(updateFormStateOne(customers, users, currentUserID));
        setActiveStep(0);
        props.handleOpen();
        setWithout(false)
    };

    //Submit  customer  and account
    const skipWithoutUserHandler = async event => {
        event.preventDefault();
        let formCustomer = formUtility.getFormData(customerForm);
        let formAccount = formUtility.getFormData(billingAccountForm);

        const Address = {
            StreetAddress: formCustomer.StreetAddress,
            City: formCustomer.City,
            PostCode: formCustomer.PostCode,
            Country: formCustomer.Country
        };

        formCustomer.Status = formCustomer.Status === 'active' ? 1 : 0;
        formCustomer['Address'] = JSON.stringify(Address);

        delete formCustomer.StreetAddress;
        delete formCustomer.PostCode;
        delete formCustomer.Country;
        delete formCustomer.City;

        await props.onCreateCustomer({...formCustomer, Account: {...formAccount}, 'CreateUser': false});
        setActiveStep(0);
        setCustomerForm(firstForm);
        setCustomerFormValid(false);
        setUserForm(secondForm);
        setUserFormValid(false);
        setBillingAccountForm(updateFormStateOne(customers, users, currentUserID));
        props.handleOpen();
        setWithout(false)

    };

    //Submit  customer  and user
    const skipWithoutHandler = async event => {
        event.preventDefault();
        setActiveStep(0);
        const formUser = formUtility.getFormData(userForm);
        let formCustomer = formUtility.getFormData(customerForm);

        const Address = {
            StreetAddress: formCustomer.StreetAddress,
            City: formCustomer.City,
            PostCode: formCustomer.PostCode,
            Country: formCustomer.Country
        };
        formCustomer.Status = formCustomer.Status === 'active' ? 1 : 0;
        formCustomer['Address'] = JSON.stringify(Address);

        delete formCustomer.StreetAddress;
        delete formCustomer.PostCode;
        delete formCustomer.Country;
        delete formCustomer.City;

        await props.onCreateCustomer({...formCustomer, ...formUser, 'CreateUser': true});

        setCustomerForm(firstForm);
        setCustomerFormValid(false);
        setUserForm(secondForm);
        setUserFormValid(false);
        props.handleOpen();
        setBillingAccountForm(updateFormStateOne(customers, users, currentUserID));
        setWithout(false)

    };

    //skipped step

    const isStepSkipped = step => {
        return skipped.has(step);
    };

    const isStepOptional = step => {
        return step === 1;
    };

    const skipHandler = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("");
        }
        setWithout(true)
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(prevSkipped => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    // step content
    const getStepContent = stepIndex => {

        switch (stepIndex) {
            case 0:
                return (
                    <Form
                        formIsValid={customerFormValid}
                        classes={classes}
                        form={customerForm}
                        inputChangedHandler={customerFormInputChangedHandler}

                    />);
            case 1:
                return (<Form
                    formIsValid={billingAccountFormValid}
                    classes={classes}
                    form={billingAccountForm}
                    inputChangedHandler={inputChangedHandlerBillingForm}
                    dateChangedHandler={dateChangedHandler}
                    checkboxChangedHandler={checkboxChangedHandler}


                />);
            case 2:
                return (<Form
                    formIsValid={userFormValid}
                    classes={classes}
                    form={userForm}
                    inputChangedHandler={userInfoFormInputChangedHandler}
                />);
            default:
                return 'Unknown stepIndex';
        }
    };

    const handleBack = () => {
        setActiveStep(!without ? activeStep - 1 : 0);
        setWithout(false)
    };

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const cancelHandler = () => {
        setCustomerForm(firstForm);
        setCustomerFormValid(false);
        setUserForm(secondForm);
        setUserFormValid(false);
        setActiveStep(0);
        props.handleOpen();
    };


    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            disableEnforceFocus={true}
            fullWidth={true}
            // onClose={props.handleOpen}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    props.handleOpen();
                }
            }}
        >
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {
                    const stepProps = {};
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                {getStepContent(activeStep)}
                <div style={{float: 'right'}}>
                    <Button style={{width: activeStep === 1 ? '95px' : '120px', margin: 10}}
                            variant='contained' onClick={cancelHandler}>Cancel</Button>

                    <Button
                        style={{
                            width: activeStep === 1 ? '95px' : '120px',
                            margin: 10,
                            display: activeStep === 0 ? 'none' : null
                        }}
                        variant='contained'
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        color={isSuperUser() ? 'secondary' : 'primary'}>
                        Back
                    </Button>

                    <Button style={{
                        width: activeStep === 1 ? '95px' : '120px',
                        margin: 10,
                        display: activeStep !== 1 ? 'none' : null
                    }}
                            color={isSuperUser() ? 'secondary' : 'primary'}
                            variant='contained' onClick={skipHandler}>Skip</Button>

                    <Button color={isSuperUser() ? 'secondary' : 'primary'}
                            disabled={activeStep === 0 ? !customerFormValid : !billingAccountFormValid}
                            variant="contained" onClick={handleNext}
                            style={{
                                width: activeStep === 1 ? '95px' : '120px',
                                margin: 10,
                                display: activeStep === 2 ? 'none' : null
                            }}
                    >
                        {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                    </Button>
                    <Button
                        style={{width: activeStep === 1 ? '95px' : '120px', margin: 10}}
                        variant='contained'
                        disabled={activeStep === 0 ? !customerFormValid : activeStep === 1 ? !billingAccountFormValid : !userFormValid}
                        onClick={activeStep === 0 ? skipSaveHandler : activeStep === 1 ? skipWithoutUserHandler : without ? skipWithoutHandler : submitHandler}
                        color={isSuperUser() ? 'secondary' : 'primary'}>
                        {activeStep === 0 ? 'Finish' : 'Finish'}
                    </Button>
                </div>
            </div>
        </Dialog>
    )
};

export default withStyles(styles)(CustomerCreate);

