import { getAxiosInstance } from '../../../np-rest/rest';
import * as actionTypes from '../../../np-core/Auth/store/actionTypes';

const setActiveCustomerStart = () => {
    return {
        type: actionTypes.CORE_SET_ACTIVE_CUSTOMER_START
    };
};

const setActiveCustomerSuccess = result => {
    return {
        type: actionTypes.CORE_SET_ACTIVE_CUSTOMER_SUCCESS,
        activeCustomer: result.customer,
        permissions: result.permissions,
        activeExternalServiceAvailable: result.activeExternalServiceAvailable
    };
};

const setActiveCustomerFail = error => {
    return {
        type: actionTypes.CORE_SET_ACTIVE_CUSTOMER_FAIL,
        error: error
    };
};

// Fetch Pendrive action creators
export const changeActiveCustomer = customerID => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(setActiveCustomerStart());
        try {
            const data = { ActiveCustomerID: customerID, CustomerID: customerID };
            let res = await rest.post('/users/set_active_customer/', data);
            if (res.data.succeeded) {
                const result = res.data.result.data;
                dispatch(setActiveCustomerSuccess(result));
                window.top.location = '/login';
            } else {
                dispatch(setActiveCustomerFail(res.error));
            }
        } catch (err) {
            dispatch(setActiveCustomerFail(err));
        }
    };
};

export const loadCustomers = (id) => {
    const rest = getAxiosInstance();
    return async () => {
        try {
            let res = await rest.post('users/list_associated_customers', { forList: true, ...id, listInactive: true });
            if (res.data && res.data.succeeded) {
                const customers = [];
                for (var customer of res.data.result.data.Customers) {
                    customers.push({ CustomerID: customer.CustomerID, Name: `${customer.Name}`, Selectable: customer.Selectable, Reason: customer.Reason });
                }
                return customers;
            }
            return [];
        } catch (error) {
            return [];
        }
    };
}