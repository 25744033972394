import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import TablePagination from "./TablePagination";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import TableToolbar from "./TableToolbar";
import { createOrAppendUrl } from "./datatable.utils";
import { parseURL } from "../../np-utilities";

const styles = theme => ({
    root: {
        width: "100%",
        overflow: "auto"
    },
    table: {
        width: "100%",
        borderCollapse: "collapse"
    },
    fab: {
        textTransform: "none",
        color: "white",
        marginLeft: theme.spacing(),
        backgroundColor: "#e62440",
        "&:hover": {
            backgroundColor: "#757575"
        }
    },
    noMatch: {
        width: "100%",
        textAlign: "center"
    },
    noMath_tr: {
        width: "100%",
        border: "1px solid #ddd"
    },
    tableBody_tr: {
        borderBottom: "1px solid #ddd",
        padding: 5,
        textAlign: "left"
    },
    tableBody_td: {
        fontWeight: "normal",
        borderBottom: "1px solid #ddd",
        margin: 5,
        padding: 5,
        minWidth: "150px",
        maxWidth: "150px",
        overflow: "hidden",
        textAlign: "left"
    },
    table_td: {

    },
    checkboxTd: {
        maxWidth: "30px",
        width: "30px",
        padding: 5,
        paddingRight: 0
    },
    actionTd: {
        padding: 5,
        minWidth: "150px",
        maxWidth: "150px",
        width: "150px"
    },
    actionTh: {
        minWidth: "150px",
        maxWidth: "150px",
        width: "150px",
        textAlign: "left",
        fontWeight: "normal",
        background: "white",
        position: "sticky",
        top: 0,
        zIndex: 100,
        padding: 5
    },
    headerTh: {
        minWidth: "150px",
        width: "150px",
        textAlign: "left",
        fontWeight: "normal",
        background: "white",
        position: "sticky",
        top: 0,
        zIndex: 100,
        padding: 5
    },
    checkboxTh: {
        maxWidth: "30px",
        width: "30px",
        textAlign: "left",
        fontWeight: "normal",
        background: "white",
        position: "sticky",
        top: 0,
        zIndex: 100,
        padding: 5
    }
});

let scrollOnBottom = false;
let pageStateColum=10;

const Table = props => {
    const { customStyles, classes, columns, data, options, classesBody, translations } = props;
    const [rowsPerPage, setRowsPerPage] = useState(
        options.rowsPerPage && options.rowsPerPageOptions.includes(options.rowsPerPage)
            ? options.rowsPerPage
            : options.rowsPerPageOptions && options.rowsPerPageOptions[0]
                ? options.rowsPerPageOptions[0]
                : 0
    );
    const [pageState, setPageState] = useState(0);
    const [numSelected, setNumSelected] = useState(0);
    const [columnState, setColumnState] = useState(columns);
    let pageStateLazy = 1;

    const [selected, setSelected] = useState([]);
    const [selectableData, setSelectableData] = useState([]);
    const [tableData, setTableData] = useState(data);

    useEffect(() => {
        if (options.useLocalData) {
            let newData = [...data];
            setTableData(newData.splice(pageState, rowsPerPage));
        } else {
            setTableData(data);
        }

        return () => { };
    }, [data, options.useLocalData, pageState, rowsPerPage]);

    useEffect(() => {
        let url = parseURL(decodeURIComponent(window.location.href));
        for (var column of columnState) {
            if (url && url[column.backendName] && document.getElementById(`search_${column.backendName}_${options.tableID}`)) {
                document.getElementById(`search_${column.backendName}_${options.tableID}`).value = url[column.backendName];
            }
        }
        return () => { };
    }, [columnState, options.tableID, tableData]);

    useEffect(() => {
        setSelectableData(tableData.filter(row => row.disableSelecting !== true));
        setSelected([]);
        setNumSelected(0);
        return () => { };
    }, [tableData]);

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            let newSelected = [];
            for (var row of tableData) {
                if (row.disableSelecting !== true) {
                    newSelected.push(row.id.toString());
                }
            }
            setSelected(newSelected);
            setNumSelected(newSelected.length);
            return;
        }
        setSelected([]);
        setNumSelected(0);
    };

    const handleCheckBoxClick = event => {
        let checked = event.target.id;
        let newSelected = [];
        if (selected.indexOf(checked) === -1) {
            newSelected = [...selected, checked];
        } else {
            selected.splice(selected.indexOf(checked), 1);
            newSelected = [...selected];
        }

        setSelected(newSelected);
        setNumSelected(newSelected.length);
    };

    const handleChangePage = page => {
        if (options.useLocalData) {
            let newData = [...data];
            setTableData(newData.splice(page === 0 ? 0 : page * rowsPerPage, rowsPerPage));
        } else {
            options.onTableChange("changePage", { page: page });
        }
        setPageState(page);
    };

    const handleChangeRowsPerPage = rows => {
        if (options.useLocalData) {
            let newData = [...data];
            if (rows !== -1 && rows < options.count) {
                setTableData(newData.splice(pageState, rows));
            }
        } else {
            options.onTableChange("changeRowsPerPage", { page: pageState, rowsPerPage: rows });
        }
        if (rows === -1 || rows >= options.count) {
            setPageState(0);
            setTableData(data);
        }
        setRowsPerPage(rows);
    };

    const handleFilterCheckboxClick = event => {
        let newColumnState = columnState.filter(column => {
            if (column.backendName === event.target.value) column.options.visible = !column.options.visible;
            return column;
        });
        setColumnState(newColumnState);
    };

    let columnSearchTimeout = null;
    const handleColumnSearch = async event => {
        if (columnSearchTimeout) {
            clearTimeout(columnSearchTimeout);
        }
        let searchData = {}; // Structure backendName:value
        for (var column of columns) {
            if (column.options.searchable) {
                searchData[column.backendName] = document.getElementById(`search_${column.backendName}_${options.tableID}`).value;
            }
        }

        columnSearchTimeout = setTimeout(async () => {
            await createOrAppendUrl(searchData);
            options.onTableChange("columnSearch", { searchColumn: searchData });
        }, 300);
    };

    let tableSearchTimeout = null;

    const handleTableSearch = text => {
        if (tableSearchTimeout) {
            clearTimeout(tableSearchTimeout);
        }

        tableSearchTimeout = setTimeout(() => {
            options.onTableChange("search", { searchText: text });
        }, 300);
    };

    const handleSort = event => {
        let columnName = event.currentTarget.id.split("_")[1];
        let orderData = {}; // Structure backendName:direction
        for (var column of columns) {
            if (column.options.sortable) {
                if (columnName === column.backendName) {
                    orderData[column.backendName] = event.currentTarget.id.split("_")[2] === "desc" ? "ascending" : "descending";
                }
            }
        }
        options.onTableChange("sortColumn", { column: columnName, order: orderData[columnName] });

        let newColumnState = columnState.filter(column => {
            if (column.backendName === columnName) {
                column.options.order = typeof column.options.order === "string" && column.options.order.toLowerCase() === "desc" ? "asc" : "desc";
            } else {
                column.options.order = "asc";
            }
            return column;
        });
        setColumnState(newColumnState);
    };

    const bulkExport = () => {
        return options.onExport(selected);
    };

    const bulkDelete = () => {
        return options.onBulkDelete(selected);
    };

    const bulkCompare = () => {
        return options.onBulkCompare(selected);
    };

    const bulkDownload = () => {
        return options.onBulkDownload(selected);
    };

    const bulkArchive = () => {
        return options.onBulkArchive(selected);
    };

    const bulkPrintLabel = () => {
        return options.onBulkPrintLabel(selected);
    };

    const bulkGenerateReport = () => {
        return options.onBulkGenerateReport(selected);
    };

    const [tableHeight, setTableHeight] = useState(window.innerHeight - (props.innerHeight ? props.innerHeight : 255));

    const handleResize = () => {
        setTableHeight(window.innerHeight - 255);
    };
    const [button, setButton] = useState(options.button)

    const handleLazyLoadingButton = data => {
        if (options.useLocalData) {
            let newData = [...data];
            setTableData(newData.splice(data === 0 ? 0 : data * rowsPerPage, rowsPerPage));
        } else  if (pageStateColum <=  tableData.length) {
            
            options.onTableChange("lazyLoadButton", { data: data, tableData: tableData.length });
            pageStateColum += button
        }
    };

    const handleLazyLoading = (event) => {
        event.preventDefault();
        let myElement = document.getElementById('my_test_scroll');
        let currentScrollPosition = myElement.scrollTop;
        let endOfDiv = myElement.scrollHeight - myElement.offsetHeight;
        if (endOfDiv <= currentScrollPosition) {
           if (pageStateColum <=  tableData.length) {
                if ((options.count !== rowsPerPage) || rowsPerPage >= options.count || rowsPerPage === -1) {     
                    if (scrollOnBottom === false) {
                        scrollOnBottom = true;
                        options.onTableChange("lazyLoad", { page: pageStateLazy });
                        setPageState(pageState + 1);
                        pageStateLazy += 1
                        pageStateColum += button
                    }
                }
            }
        } else {
            scrollOnBottom = false;
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        let myElement = null;

        if (options.lazyLoading) {
            myElement = document.getElementById('my_test_scroll');
            if (myElement) {
                myElement.addEventListener('scroll', handleLazyLoading);
            }
        }
        return () => {
            window.removeEventListener("resize", handleResize)
            if (myElement) {
                myElement.removeEventListener('scroll', handleLazyLoading);
            }  
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollOnBottom , tableData]);

    useEffect(() => {
        return () => {
            pageStateColum = 10
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{ width: "100%", margin: 0, padding: 0 }}>
            {options.disableToolbar ? null : (
                <TableToolbar
                    customStyles={customStyles ? customStyles : classes}
                    handleCreate={props.handleCreate ? props.handleCreate : null}
                    bulkDelete={bulkDelete}
                    bulkExport={bulkExport}
                    bulkCompare={bulkCompare}
                    bulkPrintLabel={bulkPrintLabel}
                    translations={translations}
                    bulkGenerateReport={bulkGenerateReport}
                    tableSearch={options.tableSearch}
                    columns={columnState}
                    tableName={options.tableTitle}
                    options={options}
                    numSelected={numSelected}
                    handleFilterCheckboxClick={handleFilterCheckboxClick}
                    handleTableSearch={handleTableSearch}
                    bulkArchive={bulkArchive}
                    onSaveFilter={props.onSaveFilter}
                    bulkDownload={bulkDownload}
                />
            )}
            {props.customFilterToolbar ? props.customFilterToolbar() : null}
            <div id="my_test_scroll" style={{ height: "auto", maxHeight: tableHeight, minHeight: "200px", overflow: "auto", width: "100%" }}>
                <table className={classes.table}>
                    <TableHead
                        classes={classes}
                        columns={columnState}
                        numSelected={numSelected}
                        rowCount={selectableData.length}
                        translations={translations}
                        options={options}
                        onSelectAllClick={handleSelectAllClick}
                        handleColumnSearch={handleColumnSearch}
                        handleSort={handleSort}
                    />
                    <TableBody
                        classes={classes}
                        rowData={tableData}
                        options={options}
                        selected={selected}
                        lazyLoading={options.lazyLoading ? options.lazyLoading : false}
                        handleCheckBox={handleCheckBoxClick}
                        columns={columnState}
                        classesBody={classesBody}
                    />
                </table>
            </div>
            {options.disablePagination ? null : (
                <TablePagination
                    style={{ position: "sticky", zIndex: 10, bottom: 0 }}
                    rowsPerPageOptions={options.rowsPerPageOptions}
                    options={options}
                    rowsPerPage={rowsPerPage}
                    tableData={pageStateColum <=  tableData.length}
                    lazyLoading={options.lazyLoading ? options.lazyLoading : false}
                    handleLazyLoadingButton={handleLazyLoadingButton}
                    setButton={setButton}
                    button={button}
                    page={pageState}
                    translations={translations}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
        </div>
    );
};
export default withStyles(styles, { withTheme: true })(Table);
