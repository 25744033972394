import * as actionTypes from "./actionTypes";
import { getAxiosInstance } from "../../../np-rest/rest";
import { enqueueSnackbarOnLogin } from "../../../np-dashboard/Notifier/store/notifierActions";
import { enqueueSnackbar } from "../../../np-dashboard/Notifier/store/notifierActions";
import ssfRest from "../../../np-rest/ssfRest";

const loginStart = () => {
    return {
        type: actionTypes.CORE_LOGIN_START
    };
};

export const loginSuccess = (response, reload) => {
    //Clear cache and hard reload after login
    if (reload) {
        window.location.reload(true);
    }

    return {
        type: actionTypes.CORE_LOGIN_SUCCESS,
        token: response.token,
        permissions: response.permissions ? response.permissions : [],
        customers: response.customers ? response.customers : [],
        activeCustomer: response.activeCustomer ? response.activeCustomer : {},
        user: response.user ? response.user : response,
        expiresAt: response.expiresAt,
        activeExternalServiceAvailable: response.activeExternalServiceAvailable,
        userSettings: response.UserSettings,
        passwordComplexity: response.PasswordComplexity,
        passwordExpired: response.PasswordExpired
    };
};

export const loginFail = error => {
    return {
        type: actionTypes.CORE_LOGIN_FAIL,
        error: error
    };
};

export const setDefaultLanguage = userSettings => {
    return {
        type: "TRANSLATE|SET_DEFAULT_TRANSLATION",
        DefaultLanguage: userSettings.DefaultLanguage
    };
};

export const login = (loginData, reload) => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(loginStart());
        try {
            let res = await rest.post("/users/login", loginData);
            if (res.data && res.data.succeeded) {
                const login = res.data.result.data;
                // if (login && login.UserSettings) dispatch(setDefaultLanguage(login.UserSettings));
                dispatch(loginSuccess(login, reload));
            } else {
                if (res.data.status === 401) {
                    dispatch(loginFail(res.data.message));
                    dispatch(
                        enqueueSnackbarOnLogin({
                            //First checking if there is message in response
                            message: res.data.message || "Invalid username or password. Please try to login again.",
                            options: {
                                variant: "error"
                            }
                        })
                    );
                } else {
                    dispatch(loginFail(res.data.message));
                    dispatch(
                        enqueueSnackbarOnLogin({
                            message: res.data.message,
                            options: {
                                variant: "error"
                            }
                        })
                    );
                }
            }
        } catch (error) {
            dispatch(loginFail(error));
            dispatch(
                enqueueSnackbarOnLogin({
                    message: error.message,
                    options: {
                        variant: "error"
                    }
                })
            );
        }
    };
};

/**
 * LOGOUT SECTION
 */
export const logoutStart = () => {
    return {
        type: actionTypes.CORE_LOGOUT_START
    };
};

export const logoutSuccess = () => {
    return {
        type: actionTypes.CORE_LOGOUT_SUCCESS
    };
};

export const logoutFailed = error => {
    return {
        type: actionTypes.CORE_LOGOUT_FAIL,
        error: error
    };
};

export const logout = (data = null, activeCustomerID = null) => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(logoutStart());
        try {
            let res = await rest.post("/users/logout", { ActiveCustomerID: activeCustomerID });
            if (res.data && res.data.succeeded) {
                dispatch(logoutSuccess());
                //If the user is logged in with crosslogin, redirecting to given url
                if (data) {
                    return window.location.replace(data);
                }
            } else {
                dispatch(logoutSuccess());
            }
        } catch (error) {
            dispatch(logoutSuccess());
        }
    };
};

//Begin Support session
const supportSessionStart = () => {
    return {
        type: actionTypes.BEGIN_SUPPORT_SESSION_START
    };
};

const supportSessionSuccess = response => {
    return {
        type: actionTypes.BEGIN_SUPPORT_SESSION_SUCCESS,
        permissions: response.permissions,
        customers: response.customers ? response.customers : [],
        user: response.user
    };
};

const supportSessionFail = error => {
    return {
        type: actionTypes.BEGIN_SUPPORT_SESSION_FAIL,
        error: error
    };
};

export const startSupportSession = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(supportSessionStart());
        try {
            let res = await rest.post("users/support_session/start", data);

            if (res.data.succeeded) {
                if (res.data.succeeded) {
                    const supportSession = res.data.result.data;
                    dispatch(supportSessionSuccess(supportSession));
                    dispatch(
                        enqueueSnackbar({
                            message: `Support session started`,
                            options: {
                                variant: "success"
                            }
                        })
                    );
                } else {
                    dispatch(supportSessionFail(res.error));
                    dispatch(
                        enqueueSnackbar({
                            message: `${res.status}`,
                            options: {
                                variant: "error"
                            }
                        })
                    );
                }
            } else {
                dispatch(supportSessionFail(res.error));
            }
        } catch (err) {
            dispatch(supportSessionFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: "error"
                    }
                })
            );
        }
    };
};

//End support session
const endSupportSessionStart = () => {
    return {
        type: actionTypes.END_SUPPORT_SESSION_START
    };
};

const endSupportSessionSuccess = response => {
    return {
        type: actionTypes.END_SUPPORT_SESSION_SUCCESS,
        permissions: response.permissions,
        customers: response.customers ? response.customers : [],
        user: response.user
    };
};

const endSupportSessionFail = error => {
    return {
        type: actionTypes.END_SUPPORT_SESSION_FAIL,
        error: error
    };
};

export const endSupportSession = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(endSupportSessionStart());
        try {
            let res = await rest.post("users/support_session/end", data);

            if (res.data.succeeded) {
                if (res.data.succeeded) {
                    const supportSession = res.data.result.data;
                    dispatch(endSupportSessionSuccess(supportSession));
                    dispatch(
                        enqueueSnackbar({
                            message: `Support session stopped`,
                            options: {
                                variant: "success"
                            }
                        })
                    );
                } else {
                    dispatch(endSupportSessionFail(res.error));
                    dispatch(
                        enqueueSnackbar({
                            message: `${res.status}`,
                            options: {
                                variant: "error"
                            }
                        })
                    );
                }
            } else {
                dispatch(endSupportSessionFail(res.error));
            }
        } catch (err) {
            dispatch(endSupportSessionFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: "error"
                    }
                })
            );
        }
    };
};

const extendSessionStart = () => {
    return {
        type: actionTypes.EXTEND_SESSION_START
    };
};

const extendSessionSuccess = reports => {
    return {
        type: actionTypes.EXTEND_SESSION_SUCCESS,
        reports: reports
    };
};

const extendSessionFailed = error => {
    return {
        type: actionTypes.EXTEND_SESSION_FAIL,
        error: error
    };
};

export const extendSession = message => {
    const [extend] = ssfRest({
        endpoint: "/users/extend_session",
        start: extendSessionStart,
        success: extendSessionSuccess,
        failed: extendSessionFailed,
        successMessage: message ? message : "",
        failedMessage: ""
    });

    return async dispatch => {
        await extend(dispatch);
    };
};

const saveColumnsFilterStart = () => {
    return {
        type: actionTypes.CORE_AUTH_SAVE_FILTER_START
    };
};

const saveColumnsFilterSuccess = (result, name) => {
    return {
        type: actionTypes.CORE_AUTH_SAVE_FILTER_SUCCESS,
        columns: result
    };
};

const saveColumnsFilterFail = error => {
    return {
        type: actionTypes.CORE_AUTH_SAVE_FILTER_FAIL,
        error: error
    };
};

// Fetch Pendrive action creators

export const saveColumnsFilters = data => {
    return async dispatch => {
        dispatch(saveColumnsFilterSuccess(data));
    };
};
export const saveColumnsFilter = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(saveColumnsFilterStart());
        try {
            let res = await rest.post("/users/update-settings", {
                type: "TableState",
                Columns: data.Statistic,
                ActiveCustomerID: data.ActiveCustomerID
            });
            if (res.data.succeeded) {
                if (res.data.succeeded) {
                    dispatch(saveColumnsFilterSuccess(data));
                    dispatch(
                        enqueueSnackbar({
                            message: `${res.data.message}`,
                            options: {
                                variant: "success"
                            }
                        })
                    );
                } else {
                    dispatch(saveColumnsFilterFail(res.error));
                    dispatch(
                        enqueueSnackbar({
                            message: `${res.data.message}`,
                            options: {
                                variant: "error"
                            }
                        })
                    );
                }
            } else {
                dispatch(saveColumnsFilterFail(res.error));
            }
        } catch (err) {
            dispatch(saveColumnsFilterFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err}`,
                    options: {
                        variant: "error"
                    }
                })
            );
        }
    };
};
