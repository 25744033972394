import { getAxiosInstance } from "../../np-rest/rest";
import { enqueueSnackbar } from "../../np-dashboard/Notifier/store/notifierActions";

const changeLoggedUserStart = () => {
    return {
        type: 'CORE|CHANGE_LOGGED_USER_INFORMATION_START'
    };
};

const changeLoggedUserSuccess = result => {
    return {
        type: 'CORE|CHANGE_LOGGED_USER_INFORMATION_SUCCESS',
        user: result,
    };
};

const changeLoggedUserFail = error => {
    return {
        type: 'CORE|CHANGE_LOGGED_USER_INFORMATION_FAIL',
        error: error
    };
};

export const saveUser = data => {
    const rest = getAxiosInstance();
    return async dispatch => {
        dispatch(changeLoggedUserStart());
        try {
            let res = await rest.post("/users/my-profile/save", data);
            if (res.data.succeeded) {
                const result = res.data.result.data;
                dispatch(changeLoggedUserSuccess(result));
                dispatch(
                    enqueueSnackbar({
                        message: `${res.data.message}`,
                        options: {
                            variant: "success"
                        }
                    })
                );
            }
            else {
                dispatch(changeLoggedUserFail(res.error));

                dispatch(enqueueSnackbar({
                    message: `${res && res.data ? res.data.message : res && res.message ? res.message : "Cannot list delivery notes  "}`,
                    options: {
                        variant: 'warning'
                    }
                }));
                return [];
            }
        } catch (err) {
            dispatch(changeLoggedUserFail(err));
            dispatch(
                enqueueSnackbar({
                    message: `${err.message}`,
                    options: {
                        variant: "error"
                    }
                })
            );
        }
    };
};
