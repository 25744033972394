import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { combineReducers } from 'redux';

// Components
import User from './User';
import UserDetails from './User/UserDetails';
import UserCreate from './User/UserCreate';

// Reducers
import userReducer from './User/store/userReducer';

// Routes
export const routes = (basePath, permissions) =>
    <Routes>
        <Route path={basePath} element={<User/>} />
        {<Route path={'/users/details'} element={<UserDetails/>} />}
        {<Route path={'/users/create'} element={<UserCreate/>} />}
    </Routes>;


export const reducers = combineReducers({
    users: userReducer
});