import restCrudActions from '../../../np-crud/store/restCrudActions'
import config from "../../../../config/config_default";
import { enqueueSnackbar } from '../../../np-dashboard/Notifier/store/notifierActions';
import moment from 'moment';

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: '/problem-report',
    pluginName: 'PROBLEM|REPORT',
    entityName: 'PROBLEM|REPORT',
    fetchSuccessMessage: '',
    loadSuccessMessage: '',
    createFailedUseBackendMessage: true,
    createSuccessUseBackendMessage: true
});

// Fetch ProblemReport action creators
export const fetchProblemReportInit = () => {
    return crudActions.fetchInit();
};
export const fetchProblemReportStart = () => {
    return crudActions.fetchStart();
};
export const fetchProblemReportSuccess = () => {
    return crudActions.fetchSuccess();
};
export const fetchProblemReportFail = () => {
    return crudActions.fetchFail();
};
export const fetchProblemReports = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
};

// Create ProblemReport  action creators
export const createProblemReportInit = () => {
    return crudActions.createInit();
};
export const createProblemReportStart = () => {
    return crudActions.createStart();
};
export const createProblemReportSuccess = createdProblemReport => {
    return crudActions.createSuccess(createdProblemReport);
};
export const createProblemReportFail = error => {
    return crudActions.createFail(error);
};
export const createProblemReport = ProblemReport => {
    return async dispatch => {
        await crudActions.create(dispatch, ProblemReport);
    };
};

// Delete ProblemReport  action creators

export const bulkDeleteProblemReportInit = () => {
    return crudActions.bulkDeleteInit();
};
export const bulkDeleteProblemReportStart = () => {
    return crudActions.bulkDeleteStart();
};
export const bulkDeleteProblemReportSuccess = bulkDeleted => {
    return crudActions.bulkDeleteSuccess(bulkDeleted);
};
export const bulkDeleteProblemReportFail = error => {
    return crudActions.bulkDeleteFail(error);
};
export const bulkDeleteProblemReport = ProblemReportIds => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, ProblemReportIds);
    };
};

// Delete ProblemReport  action creators
export const deleteProblemReportInit = () => {
    return crudActions.deleteInit();
};
export const deleteProblemReportStart = () => {
    return crudActions.deleteStart();
};
export const deleteProblemReportSuccess = deletedProblemReport => {
    return crudActions.deleteSuccess(deletedProblemReport);
};
export const deleteProblemReportFail = error => {
    return crudActions.deleteFail(error);
};
export const deleteProblemReport = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};


// Edit ProblemReport  action creators
export const editProblemReportInit = () => {
    return crudActions.editInit();
};
export const editProblemReportStart = () => {
    return crudActions.editStart();
};
export const editProblemReportSuccess = editedProblemReport => {
    return crudActions.editSuccess(editedProblemReport);
};
export const editProblemReportFail = error => {
    return crudActions.editFail(error);
};
export const editProblemReport = setting => {
    return async dispatch => {
        await crudActions.edit(dispatch, setting);
    };
};

// Load ProblemReport action creators
export const loadProblemReportInit = () => {
    return crudActions.loadInit();
};
export const loadProblemReportStart = () => {
    return crudActions.loadStart();
};
export const loadProblemReportSuccess = loadedProblemReport => {
    return crudActions.loadSuccess(loadedProblemReport);
};
export const loadProblemReportFail = error => {
    return crudActions.loadFail(error);
};
export const loadProblemReport = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};


// const updateDownloadProgress = progress => {

//     return {
//         type: 'DOWNLOAD|DOWNLOAD_PROGRESS',
//         downloadProgress: progress.downloadProgress,
//         maxSize: progress.maxSize,
//         currentSize: progress.currentSize,
//         snackBar: progress.snackBar,
//         data: progress,
//         name: progress.name
//     };
// };

// Custom functions 
// export const downloadFile = (ProblemReportID, customerID, name) => {
//     let token = localStorage.getItem('CORE|AUTH|TOKEN');
//     if (token) {
//         token = JSON.parse(token).token;
//     }
//     return async dispatch => {
//         return fetch(`${config.backend_domain}:${config.backend_port}/problem-report/download?ProblemReportID=${ProblemReportID}&ActiveCustomerID=${customerID.ActiveCustomerID}&Token=${token}`, {
//             method: "GET",
//         }).then(async response => {
//             try {
//                 if (response.status === 200) {
//                     const reader = response.body.getReader();
//                     const contentLength = +response.headers.get('fileSize');
//                     let receivedLength = 0;
//                     let chunks = [];
//                     let percentage = 0;
//                     while (true) {
//                         const { done, value } = await reader.read();
//                         if (done) {
//                             dispatch(updateDownloadProgress({ downloadProgress: 0, maxSize: 0, currentSize: 0, snackBar: false }));

//                             break;
//                         }
//                         chunks.push(value);

//                         receivedLength += value.length;
//                         if (percentage + 1 === Math.floor(receivedLength / contentLength * 100)) {
//                             percentage += 1;
//                             dispatch(updateDownloadProgress({ downloadProgress: Math.floor(receivedLength / contentLength * 100), maxSize: Math.floor(contentLength / 1048576), currentSize: Math.floor(receivedLength / 1048576), snackBar: true, name: name, ProblemReportID: ProblemReportID }));
//                         }
//                     }

//                     return new Blob(chunks);
//                 }
//                 return null;
//             } catch (error) {
//                 dispatch(updateDownloadProgress({ downloadProgress: 0, maxSize: 0, currentSize: 0, snackBar: false, name: name, ProblemReportID: ProblemReportID }));
//                 dispatch(
//                     enqueueSnackbar({
//                         message: `${error && error.message ? error.message : 'Download failed '}`,
//                         options: {
//                             variant: 'error'
//                         }
//                     }));
//             }
//         });
//     }
// }
//Custom functions 
export const downloadReportProblemStart = () => {
    return {
        type: 'REPORT|PROBLEM|PROBLEM_DOWNLOAD_FAIL',
    };
};
export const downloadReportProblemSuccess = () => {
    return {
        type: 'REPORT|PROBLEM|PROBLEM_DOWNLOAD_FAIL'
    };
};
export const downloadReportProblemFail = () => {
    return {
        type: 'REPORT|PROBLEM|PROBLEM_DOWNLOAD_FAIL'
    };
};

export const downloadFile = (ProblemReportID, customerID) => {
    let token = localStorage.getItem('CORE|AUTH|TOKEN');
    if (token) {
        token = JSON.parse(token).token;
    }
    return async dispatch => {

        return fetch(`${config.backend_domain}:${config.backend_port}/problem-report/download?ProblemReportID=${ProblemReportID}&ActiveCustomerID=${customerID.ActiveCustomerID}&Token=${token}`, {
            method: "GET",
        }).then(async response => {
            dispatch(downloadReportProblemStart())

            let fileName = `print_result_${moment().valueOf()}.txt`;
            response.headers.forEach((value, key) => {
                if (key === 'filename') {
                    fileName = value;
                }
            })
            if (response.status === 200) {
                dispatch(downloadReportProblemSuccess())
                return { blob: await response.blob(), fileName: fileName }
            }
            dispatch(downloadReportProblemFail());
            dispatch(enqueueSnackbar({
                message: `${response && response.data && response.data.message ? response.data.message : "Problem can't be downloaded"}`,
                options: {
                    variant: 'error'
                }
            }));
            return null;
        });
    }
}