import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { combineReducers } from 'redux';

// Reducers
import authReducer from './Auth/store/authReducer';
import userReducer from './User/store/userReducer';
import notifierReducer from '../np-dashboard/Notifier/store/notifierReducer';
import permissionReducer from './Permissions/store/permissionReducer';
import roleReducer from './Roles/store/roleReducer';
import customerReducer from './Customer/store/customerReducer';
import loggersReducers from '../np-components/LogLevel/store/loggersReducer';

//User components
import UserList from './User/UserList/UserList';
import UserCreate from './User/UserCreate';
import UserEdit from './User/UserEdit';
import UserEditPassword from './User/UserEditPassword';

//Customer components
import Permission from './Permissions/PermissionsList';
import PermissionEdit from './Permissions/PermissionEdit';
import PermissionCreate from './Permissions/PermissionCreate';

//Roles components
import RoleList from './Roles/RolesList';
import RoleCreate from './Roles/RoleCreate';
import RoleEdit from './Roles/RoleEdit';

//Customer components
import CustomerList from './Customer/CustomerList';
import CustomerCreate from './Customer/CustomerCreate';
import CustomerEdit from './Customer/CustomerEdit';

//Log levels component
import LogLevels from '../np-components/LogLevel/LogLevel';

const hasPermission = (permissions, permission) => {
    return permissions.includes('*') || permissions.includes(permission);
};

// Routes
export const routes = (basePath, permissions) =>
    hasPermission(permissions, 'core') ? (
        <Routes>
            {hasPermission(permissions, 'core_user_view') && <Route path={basePath + '/users'} exact element={UserList} />}
            {hasPermission(permissions, 'core_user_edit') && <Route path={basePath + '/users/create'} element={UserCreate} />}
            {hasPermission(permissions, 'core_user_edit') && <Route path={basePath + '/users/:id/edit'} exact element={UserEdit} />}
            {hasPermission(permissions, 'core_user_edit') && <Route path={basePath + '/users/:id/password/edit'} exact element={UserEditPassword} />}
            {hasPermission(permissions, 'core_role_view') && <Route path={basePath + '/roles'} exact element={RoleList} />}
            {hasPermission(permissions, 'core_role_edit') && <Route path={basePath + '/roles/create'} element={RoleCreate} />}
            {hasPermission(permissions, 'core_role_edit') && <Route path={basePath + '/roles/:id/edit'} element={RoleEdit} />}
            {hasPermission(permissions, 'core_permission_view') && <Route path={basePath + '/permissions'} exact element={Permission} />}{' '}
            {hasPermission(permissions, 'core_permission_edit') && <Route path={basePath + '/permissions/create'} element={PermissionCreate} />}
            {hasPermission(permissions, 'core_permission_edit') && <Route path={basePath + '/permissions/:id/edit'} element={PermissionEdit} />}
            {hasPermission(permissions, 'core_customer_view') && <Route path={basePath + '/customers'} exact element={CustomerList} />}
            {hasPermission(permissions, 'core_customer_edit') && <Route path={basePath + '/customers/create'} element={CustomerCreate} />}
            {hasPermission(permissions, 'core_customer_edit') && <Route path={basePath + '/customers/:id/edit'} exact element={CustomerEdit} />}
            {hasPermission(permissions, 'core_loggers_view') && <Route path={basePath + '/loggers'} exact element={LogLevels} />}
        </Routes>
    ) : null;

export const subComponents = [
    { name: 'Users', path: '/users', permissions: ['core_user_view'] },
    { name: 'Permissions', path: '/permissions', permissions: ['core_permission_view'] },
    { name: 'Roles', path: '/roles', permissions: ['core_role_view'] },
    { name: 'Customers', path: '/customers', permissions: ['core_customer_view'] },
    { name: 'Log levels', path: '/loggers', permissions: ['core_loggers_view'] }
];

export const reducers = combineReducers({
    login: authReducer,
    user: userReducer,
    notifier: notifierReducer,
    permission: permissionReducer,
    role: roleReducer,
    customer: customerReducer,
    loggers: loggersReducers
});
