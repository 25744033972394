import React, { useState } from 'react';
import { withStyles, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import SiteList from './SiteList'
import * as actions from './store/siteActions';
import SiteCreate from './SiteCreate';
import SiteEdit from './SiteEdit';
import SiteDelete from './SiteDelete';
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';
import * as action from '../../np-core/Auth/store/authActions';
import { Tooltip , IconButton ,  } from '@material-ui/core';
import { Edit, Delete , Add , Refresh} from '@material-ui/icons';
const styles = theme => ({
    root: {
        width: '98.9%',
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
    progressBarr: {
        width: '100%',
        height: 5
    }
});

const Site = props => {
    const { classes, sites, activeCustomer ,columnsState } = props;
    const [fetching, setFetching] = useState(sites.fetching);
    const tableRef = React.createRef();

    const [addModalState, setAddModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState({
        open: false,
        siteID: null,
        siteIDs: [],
        forBulk: false
    });

    const handleOpen = () => {
        setAddModalState(!addModalState);
    };

    const handleEdit = async event => {
        if (!event) {
            setEditModalState(!editModalState);
            return;
        }

        const siteID = event.currentTarget.value;
        await props.onFetchSite({ SiteID: siteID, ActiveCustomerID: activeCustomer });
        setEditModalState(!editModalState);
    };

    const handleEditNew = async(event, rowData) => {
        if (!event) {
            setEditModalState(!editModalState);
            return;
        }

        let siteID = rowData['SiteID'];

        if (siteID === undefined){
            rowData = rowData[0];
            siteID = rowData['SiteID'];
        }
        await props.onFetchSite({ SiteID: siteID, ActiveCustomerID: activeCustomer });
        setEditModalState(!editModalState);

    };

    const handleDelete = async event => {
        if (!event) {
            setDeleteModalState({ open: false, siteID: null, siteIDs: [], forBulk: false });
            return;
        }
        const siteID = event.currentTarget.value;
        setDeleteModalState({ ...deleteModalState, open: true, siteID: siteID });
    };

    const handleDeleteNew = async(event, rowData) => {
        if (!event) {
            setDeleteModalState({ open: false, siteID: null, siteIDs: [], forBulk: false });
            return;
        }
        if(rowData instanceof Array){
            const siteIDs = rowData.map(row => row.SiteID);
            setDeleteModalState({ open: true, siteID: null, siteIDs: siteIDs, forBulk: true });
        }
        else{
        const siteID = rowData['SiteID'];
        setDeleteModalState({ ...deleteModalState, open: true, siteID: siteID });
        }
    };
    let tableID = 'Site';
    let visible = columnsState && columnsState[tableID];
    const getVisibility = id => {
        if (visible) {
            return visible[id]
        }
        return true;
    }

    let tableColumns = [
        {
            title:  "Action", field: 'Action',
            hidden: visible ? visible['SiteID'] : false,
            cellStyle: { minWidth: 200, maxWidth: 200 },
            filtering: false,
            render: rowData => {
              return (
                  <>
                        <Tooltip key={`${rowData.SiteID}Edit`} title={'Edit Site'}>
                            <IconButton
                                component='div'
                                onClick={(event) => handleEditNew(event, rowData)}
                            >
                             <Edit/>
                            </IconButton>
                        </Tooltip>
                         <Tooltip key={`${rowData.SiteID}Delete`} title={'Delete Site'}>
                         <IconButton
                             component='div'
                             onClick={(event) => handleDeleteNew(event, rowData)}
                         >
                          <Delete></Delete>
                         </IconButton>
                     </Tooltip>
                     </>
                    )
            },
        },
        {
            title: 'Name',
            field: 'Name',
            cellStyle: {
                align: 'left'
            },
            options: {
                hidden: getVisibility('Name'),
                sortable: true,
                searchable: true,

            }
        },
        {
            title: 'Key',
            field: 'Key',
            cellStyle: {
                align: 'left'
            },
            options: {
                hidden: getVisibility('Key'),
                sortable: true,
                searchable: true,

            }
        },
        {
            title: 'Default Language',
            field: 'DefaultLanguage',
            cellStyle: {
                align: 'left'
            },
            options: {
                hidden: getVisibility('DefaultLanguage'),
                sortable: true,
                searchable: true,
            }
        },
        {
            title: 'Pause',
            field: 'Pause',
            cellStyle: {
                align: 'left'
            },
            options: {
                hidden: getVisibility('Pause'),
                sortable: true,
                searchable: true,
            }
        },
        {
            title: 'Sounds',
            field: 'Sounds',
            cellStyle: {
                align: 'left'
            },
            options: {
                hidden: getVisibility('Sounds'),
                sortable: true,
                searchable: true,
            }
        },
        {
            title: 'Contact Person',
            field: 'ContactPerson',
            cellStyle: {
                align: 'left'
            },
            options: {
                hidden: getVisibility('ContactPerson'),
                sortable: true,
                searchable: true,
            }
        },
        {
            title: 'Users who failed assessment anonymize timeout',
            field: 'UserDeleteTimeout',
            cellStyle: {
                align: 'left'
            },
            options: {
                hidden: getVisibility('UserDeleteTimeout'),
                sortable: true,
                searchable: true,
            }
        },
        {
            title: 'Users who passed assessment anonymize timeout',
            field: 'ActiveUserDeleteTimeout',
            cellStyle: {
                align: 'left'
            },
            options: {
                hidden: getVisibility('ActiveUserDeleteTimeout'),
                sortable: true,
                searchable: true,
            }
        },
        {
            title: 'Score Threshold',
            field: 'ScoreThreshold',
            filtering: false,
            sorting: false,
            render: rowData => {
                if(rowData.ScoreThreshold){ 
                    const thresh = JSON.parse(rowData.ScoreThreshold); 
                    return(
                        <div>
                            <p>Vit: <b>{ thresh.Vitality }</b>; Acc: <b>{ thresh.Accuracy }</b></p>
                            <p>Att: <b>{ thresh.Attention }</b>; Spd: <b>{ thresh.Speed}</b></p>
                            <p>Mem: <b>{ thresh.Memory }</b>; Log: <b>{ thresh.Logic }</b></p>

                        </div>
                        )};
                    return (
                        <div>
                            <p>Vit: <b>7.5</b>; Acc: <b>8.0</b></p>
                            <p>Att: <b>8.0</b>; Spd: <b>6.0</b></p>
                            <p>Mem: <b>8.0</b>; Log: <b>8.5</b></p>

                        </div>
            )},
            cellStyle: {
                align: 'left',
                minWidth: 140},
            options: {
                hidden: getVisibility('ScoreThreshold'),
                sortable: false,
                searchable: false,
                searching: false
            }
        }
    ];
    const handleBulkDelete = siteIDS => {
        setDeleteModalState({ ...deleteModalState, open: true, siteIDs: siteIDS, forBulk: true });
    };

    const startFetching = () =>{
        setFetching(true);
    }

    const endFetching = () =>{
        setFetching(false);
    }
    const actions = [
        {
            icon: Delete,
            tooltip: 'Delete Site',
            onClick: (event, rowData) => handleDeleteNew(event, rowData),
            disabled: !(props.hasPermission('site_delete')),
            hidden: !(props.hasPermission('site_delete'))
        },
        {
            icon: Add,
            tooltip: 'Add New Site',
            onClick: (event) => handleOpen(event),
            disabled: !(props.hasPermission('site_create')),
            isFreeAction: true
        },
        {
            icon: Refresh,
            tooltip: 'Refresh site data',
            onClick: (event) => {

                tableRef.current && tableRef.current.onQueryChange()
            },
            isFreeAction: true
        }

    ]


    return (
        <div className={classes.root}>
            <div className={classes.progressBarr}>
                {fetching ? <LinearProgress color="primary" /> : null}
            </div>
            {props.hasPermission('site_list') ?            
            <SiteList activeCustomer={activeCustomer} columnsState={props.columns}
            saveColumnsFilter={props.saveColumnsFilter}actions={actions}
            activeExternalServiceAvailable={props.activeExternalServiceAvailable}
            hasAtLeastOnePermission={props.hasAtLeastOnePermission}tableRef={tableRef} tableColumns={tableColumns}
            hasPermission={props.hasPermission} handleBulkDelete={handleBulkDelete}
            sites={sites} onFetchSites={props.onFetchSites2} handleEdit={handleEditNew}
            handleDelete={handleDeleteNew} handleCreate={handleOpen} startFetching={startFetching} endFetching={endFetching} /> : null}

            {props.hasPermission('site_create') ?
                <SiteCreate activeCustomer={activeCustomer} loading={sites.creating} handleOpen={handleOpen}
                    open={addModalState} createSite={props.onCreateSite} tableRef={tableRef}/> : null}
            {props.hasPermission('site_load') ?
                <SiteEdit activeCustomer={activeCustomer} handleEdit={handleEdit} open={editModalState}
                    hasPermission={props.hasPermission} loading={sites.saving}  tableRef={tableRef} site={sites.loaded}
                    editSite={props.onEditSite} /> : null}
            {props.hasPermission('site_delete') ?
                <SiteDelete activeCustomer={activeCustomer} tableRef={tableRef} entityName={'Site'} handleDelete={handleDelete}
                    deleteModalState={deleteModalState} deleteSite={props.onDeleteSite}
                    bulkDeleteSites={props.onBulkDeleteSites} /> : null}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        sites: state.site.sites,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        hasPermission: permission => {
            return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*');
        },
        hasAtLeastOnePermission: permissions => {
            return state.core.login.permissions.some(permission => permissions.includes(permission)) || state.core.login.permissions.includes('*');
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchSites: filter => dispatch(actions.fetchSites(filter)),
        onFetchSites2: params => dispatch(actions.fetchSites2(params)),
        onCreateSite: site => dispatch(actions.createSite(site)),
        onFetchSite: site => dispatch(actions.loadSite(site)),
        onEditSite: site => dispatch(actions.editSite(site)),
        onDeleteSite: site => dispatch(actions.deleteSite(site)),
        onBulkDeleteSites: site => dispatch(actions.bulkDeleteSite(site)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Site));