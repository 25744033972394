import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import NPDatatable from '../../np-components/Datatable';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid, withStyles, Paper, LinearProgress } from '@material-ui/core';
import { Navigate, Route } from 'react-router';
import EditIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import { NavLink } from 'react-router-dom';

import queryParser from 'query-string-parser';
import useListFilter from '../../np-crud/listFilter';
import ConfirmationDialog from '../../np-components/ConfirmationDialog/ConfirmationDialog';

import * as actions from './store/roleActions';

const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        '&:hover': {
            backgroundColor: '#757575'
        }
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },
    iconHover: {
        '&:hover': {
            color: '#99ccff'
        }
    }
});

// Role List Component
const RoleList = props => {
    // Get style classes and redux mapped props
    const {
        classes,
        hasEditPermission,
        roleCount,
        roleList,
        loading,
        onFetchInit,
        onFetchRoles,
        history,
        onDeleteRole,
        onDeleteInit,
        onBulkDeleteRoles,
        onBulkDeleteInit
    } = props;

    // List Filter
    const urlOptions = queryParser.fromQuery(props.location.search);
    const listFilter = useListFilter({ searchColumns: ['Name', 'FancyName'], ...urlOptions });
    // Delete dialog state
    const [deleteDialog, setDeleteDialog] = useState({ open: false, confirmed: false });
    // Data to be deleted
    const [deleteData, setDeleteData] = useState({});
    // Bulk delete dialog state
    const [bulkDeleteDialog, setBulkDeleteDialog] = useState({ open: false, confirmed: false });
    // Bulk data to be deleted
    const [bulkDeleteData, setBulkDeleteData] = useState({});

    const [redirectCreate, setRedirectCreate] = useState({ redirect: false, RoleID: 0, redirectPath: '' });

    // Init the redux store on mount and on unmount
    useEffect(() => {
        onFetchInit();
        return () => {
            //Cleanup redux store
            onFetchInit();
        };
    }, [onFetchInit]);

    // Fetch Roles if the filter was changed or after delete
    useEffect(() => {
        if (listFilter.filter) {
            onFetchRoles(listFilter.filter);
        }
    }, [listFilter.filter, onFetchRoles]);

    useEffect(() => {
        const query = queryParser.toQuery(listFilter.options);
        history.push('?' + query);
    }, [listFilter.options, history]);

    // Delete issue if dialog confirmed
    useEffect(() => {
        const deleteRole = async () => {
            if (deleteData !== {}) {
                // Run delete
                await onDeleteRole(deleteData);
                // Init state
                setDeleteDialog({ open: false, confirmed: false });
                // Fetch issues
                await onFetchRoles(listFilter.filter);
                return setDeleteData({});
            }
        };
        if (deleteDialog.confirmed) {
            deleteRole();
            return () => {
                // Cleanup redux store
                onDeleteInit();
            };
        }
    }, [deleteDialog.confirmed, onFetchRoles, deleteData, listFilter.filter, onDeleteInit, onDeleteRole]);

    // Bulk delete issues

    // Bulk delete issues if dialog confirmed
    useEffect(() => {
        const bulkDeleteRoles = async () => {
            if (bulkDeleteData !== {}) {
                // Run bulk delete
                await onBulkDeleteRoles(bulkDeleteData);
                // Init bulk delete state
                // Hide dialog
                setBulkDeleteDialog({ open: false, confirmed: false });
                // Fetch issues
                await onFetchRoles(listFilter.filter);
                return setBulkDeleteData({});
            }
        };
        if (bulkDeleteDialog.confirmed) {
            // Bulk delete issues
            bulkDeleteRoles();
            return () => {
                // Cleanup redux store
                onBulkDeleteInit();
            };
        }
    }, [bulkDeleteDialog.confirmed, onBulkDeleteRoles, onFetchRoles, onBulkDeleteInit, bulkDeleteData, listFilter.filter]);

    // Delete handler
    const handeDelete = RoleID => {
        // Set data to be deleted
        setDeleteData({ RoleID: parseInt(RoleID) });
        // Show delete dialog
        setDeleteDialog({ open: true, confirmed: false });
    };

    // Bulk delete handler
    const handleBulkDelete = ids => {
        // Set data to be deleted
        setBulkDeleteData({ RoleIDs: ids });
        // Show bulk delete dialog
        setBulkDeleteDialog({ open: true, confirmed: false });
    };

    const createActions = role => {
        return (
            <>
                {hasEditPermission ? (
                    <div>
                        <Tooltip title={'Edit'}>
                            <IconButton>
                                <NavLink to={props.match.path + '/' + role.RoleID + '/edit'}>
                                    <EditIcon variant="contained" color="primary" fontSize="small" />
                                </NavLink>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={'Delete'}>
                            <IconButton onClick={() => handeDelete(role.RoleID)} variant="contained" fontSize="small" color="secondary">
                                <DeleteIcon style={{ color: '#D71C1C' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                ) : null}
            </>
        );
    };

    let counter = 0;
    let roleListData = roleList.map(role => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = role.RoleID;
        returnObj.visible = true;
        returnObj.values = [createActions(role), role.Name, role.FancyName];
        counter++;
        return returnObj;
    });

    const columns = [
        {
            displayName: 'Actions',
            backendName: 'Actions',
            options: {
                align: 'left',
                visible: true
            }
        },
        {
            displayName: 'Name',
            backendName: 'Name',
            options: {
                align: 'right',
                sortable: true,
                visible: true
            }
        },
        {
            displayName: 'Fancy name',
            backendName: 'FancyName',
            options: {
                align: 'right',
                sortable: true,
                visible: true
            }
        }
    ];

    const tableOptions = {
        isSelectable: hasEditPermission,
        tableSearch: true,
        selectOptions: {
            printing: false,
            generatingReport: false,
            deleting: hasEditPermission,
            creating: hasEditPermission
        },
        rowsPerPage: 10,
        count: roleCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'Role list',
        noMatch: 'Sorry, no matching records found',
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return handleBulkDelete(IDs);
        }
    };

    if (redirectCreate.redirect) {
        return <Route path="*" element={<Navigate to={redirectCreate.redirectPath} />} />;
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={10} justify="center" direction="row" alignItems="center">
                <Grid className={classes.item} item xs={12} md={11} sm={12} lg={10}>
                    {loading ? <LinearProgress color="secondary" /> : null}
                    <Paper>
                        <NPDatatable
                            handleCreate={() => {
                                setRedirectCreate({ redirect: true, redirectPath: `/roles/create` });
                            }}
                            customStyles={classes}
                            data={roleListData}
                            columns={columns}
                            options={tableOptions}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <ConfirmationDialog
                isOpen={deleteDialog.open}
                title="Delete role"
                contentText="Are you sure to delete role?"
                onYes={() => setDeleteDialog({ open: false, confirmed: true })}
                textYes="Delete"
                onCancel={() => setDeleteDialog({ open: false, confirmed: false })}
            />
            <ConfirmationDialog
                isOpen={bulkDeleteDialog.open}
                title="Delete all roles"
                contentText="Are you sure to delete all selected roles?"
                onYes={() => setBulkDeleteDialog({ open: false, confirmed: true })}
                onCancel={() => setBulkDeleteDialog({ open: false, confirmed: false })}
                textYes="Delete"
            />
        </div>
    );
};

const mapStateToProps = state => {
    return {
        hasEditPermission: state.core.login.permissions.includes('*') || state.core.login.permissions.includes('core_role_edit'),
        roleCount: state.core.role.count,
        roleList: state.core.role.list,
        loading: state.core.role.fetching
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchInit: () => dispatch(actions.fetchRolesInit()),
        onFetchRoles: filter => dispatch(actions.fetchRoles(filter)),
        onDeleteInit: () => dispatch(actions.deleteRoleInit()),
        onBulkDeleteInit: () => dispatch(actions.bulkDeleteRolesInit()),
        onDeleteRole: roleId => dispatch(actions.deleteRole(roleId)),
        onBulkDeleteRoles: roleIds => dispatch(actions.bulkDeleteRoles(roleIds))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(RoleList));
