import { Checkbox, Dialog, DialogTitle, List, Typography, Button } from "@material-ui/core";
import React from 'react';


const FilterDialog = props => {
    const { columns, open, saveColumn, translations, classes, closeFilter } = props;

    let counter = 0;
    return (
        <Dialog onClose={props.handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{translations ? translations['show_hide'] || 'Show/hide columns' : 'Show/hide columns'}</DialogTitle>
            <div>
                <List>
                    {columns.map(column => (
                        <Typography key={`show_hide_list_${column.backendName}_${counter++}`}>
                            <Checkbox color={'primary'} checked={column.options.visible} onClick={props.handleFilterCheckboxClick} value={column.backendName} />
                            {column.displayName}
                        </Typography>
                    ))}
                </List>
                {saveColumn ? <>
                    <Button
                        className={classes.button}

                        variant='contained'
                        onClick={() => props.onSaveFilter(columns)}
                    >
                        {translations ? translations['save'] || 'SAVE' : 'Save'} </Button>  </> : null}


                {closeFilter ? <>
                    <Button
                        style={{ width: '60%', marginLeft: '20%', marginBottom: '5px', backgroundColor: "#7dc5e2", color: "white" }}
                        variant='contained'
                        onClick={() => props.handleClose()}
                    >
                        {'Close'} </Button>  </> : null}
            </div>
        </Dialog>
    );
}


export default FilterDialog;
