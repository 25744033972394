import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, withStyles } from '@material-ui/core';

const styles = theme => ({
    buttonDanger: {
        color: '#eceff1',
        backgroundColor: '#f44336'
    },
    buttonSafe: {
        color: '#eceff1',
        backgroundColor: '#4caf50'
    }
});
const SuspendAndRecoverAccount = props => {
    let { classes, accountModalState, activeCustomer } = props;

    const handleCloseSafe = () => {
        props.handleOpen();
    }

    const handleCloseDanger = async () => {
        if (accountModalState.recover) {
            await props.onRecover({ AccountID: accountModalState.AccountID, ActiveCustomerID: activeCustomer });
            props.handleOpen();
            return;
        }
        await props.onSuspend({ AccountID: accountModalState.AccountID, ActiveCustomerID: activeCustomer });
        props.handleOpen();
    }

    return (
        <Dialog
            open={accountModalState.open}
            // onClose={handleCloseSafe}
            aria-labelledby="form-dialog-title"
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            fullWidth={true}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleCloseSafe();
                }
            }}
        >
            <DialogTitle id="form-dialog-title">{accountModalState.recover ? 'Recover' : 'Suspend'} account</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Be careful when {accountModalState.recover ? 'recover' : 'suspend'} something.<br />
                    Are you sure you want to {accountModalState.recover ? 'recover' : 'suspend'} this account data?
          </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseSafe} variant="contained" className={classes.buttonSafe}>
                    No
          </Button>
                <Button onClick={handleCloseDanger} variant="contained" className={classes.buttonDanger}>
                    Yes
          </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withStyles(styles)(SuspendAndRecoverAccount);