import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {combineReducers} from 'redux';

// Reducers
import userActivityReducer from './UserActivity/store/activityReducer';

// Components
import UserActivityList from './UserActivity/index';

// Routes
export const routes = basePath =>
    (
        <Routes>
            <Route path={basePath} element={<UserActivityList/>}/>
        </Routes>
    );

export const reducers = combineReducers({
    userActivity: userActivityReducer,
});