import moment from 'moment';

export const isSuperUser = () => {
    let permissions = localStorage.getItem('CORE|AUTH|PERMISSIONS');
    permissions = permissions ? JSON.parse(permissions).permissions : [];
    return permissions.includes('*');
}

export const hasExternalSettingsAvailable = () => {
    let externalSettings = localStorage.getItem('CORE|AUTH|EXTERNAL|SETTINGS');
    externalSettings = externalSettings ? JSON.parse(externalSettings).activeExternalServiceAvailable : false;
    return externalSettings;
}

export const formatDate = date => {
    if (date) {
        return moment(date).format('DD.MM.YYYY HH:mm:ss');
    }
    return '-';
}

export const copyToClipBoard = (text) => {
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute('value', text);
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
}

export const formatMessage = message => {
    let formattedMessage = '';
    let words = message.split('_');
    for (var word of words) {
        word = word.toLowerCase();
        formattedMessage += capitalize(word);
        if (words.indexOf(word) !== words.length - 1) {
            formattedMessage += ' ';
        }
    }
    return formattedMessage;
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}


export const parseURL = url => {
    url = decodeURIComponent(url);
    let query = url.split('?');
    if (query.length === 1) {
        return null;
    }
    let queryData = query[1];
    if (queryData.includes('&')) {
        queryData = [...queryData.split('&')];
    } else {
        queryData = [queryData]
    }

    let obj = {};
    for (var data of queryData) {
        let splitByEqual = data.split('=');
        obj[splitByEqual[0]] = splitByEqual[1];
    }
    return obj;
}

export const downloadFile = (data, fileName) => {
    const objectURL = URL.createObjectURL(data);
    const downloadElement = document.createElement('a');
    downloadElement.href = objectURL;
    downloadElement.download = `${fileName}`;
    return downloadElement.click();
}

export const updateURL = async newTabValue => {
    let newUrl = window.location.href.split('#')[0];
    window.history.replaceState({ path: `${newUrl}#${newTabValue}` }, '', `${newUrl}#${newTabValue}`);
}