import restCrudReducer from '../../../np-crud/store/restCrudReducer';

const [crudInitialState, crudReducer] = restCrudReducer({
    pluginName: 'SCORE-CRITERIA',
    entityName: 'SCORE-CRITERIA',
});

const initialState = {
    ...crudInitialState,
    deletedScoreCriteria: {},
    scoreCriteriaSites: [],
    deleting: false,
    saving: false
};

const listAllSites = (state, action) => {
    return { ...state, scoreCriteriaSites: action.sites };
};

const reducer = (state = initialState, action) => {
    // Handle CRUD actions
    const newState = crudReducer(state, action);
    // If CRUD reducer handled the action
    if (newState !== null) {
        return newState;
    }

    // Handle other actions
    switch (action.type) {
        case 'CORE|LOGOUT_SUCCESS':
        case 'CORE|CORE_SET_ACTIVE_CUSTOMER_SUCCESS':
            return initialState;
        case 'SCORE-CRITERIA|LIST':
            return listAllSites(state, action);
        default:
            return state;
    }
};

export default reducer;
