import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions'
import * as actions from './store/licensePrototypeActions';
import { connect } from 'react-redux';
import LicensePrototypeEdit from './LicensePrototypeEdit'
import LicensePrototypeList from './LicensePrototypeList';
import LicensePrototypeCreate from './LicensePrototypeCreate';
import LicensePrototypeDelete from './LicensePrototypeDelete';
import * as action from '../../np-core/Auth/store/authActions';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
});

const LicensePrototype = props => {
    const { classes, activeCustomer } = props;
    const [addModalState, setAddModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState({ open: false, licensePrototypeID: null, });
    const [permissions, setPermissions] = useState([]);

    const handleDelete = async event => {
        if (!event) {
            setDeleteModalState({ open: false, licensePrototypeID: null, });
            return;
        }
        const licensePrototypeID = event.currentTarget.value;
        setDeleteModalState({ ...deleteModalState, open: true, licensePrototypeID: licensePrototypeID, ActiveCustomerID: activeCustomer });
    };

    const handleEdit = async event => {
        if (!event) {
            setEditModalState(!editModalState);
            return;
        }
        const licensePrototypeID = event.currentTarget.value;
        await props.onFetchLicensePrototype({ LicensePrototypeID: licensePrototypeID, ActiveCustomerID: activeCustomer });
        let data = await props.listPermissions({ ActiveCustomerID: activeCustomer });
        setEditModalState(!editModalState);
        setPermissions(data);
    };

    const handleOpen = async event => {
        if (!event) {
            setAddModalState(!addModalState);
            return;
        }
        let data = await props.listPermissions({ ActiveCustomerID: activeCustomer });
        setPermissions(data);
        setAddModalState(!addModalState);
    };

    return (
        <div className={classes.root}>
            {props.hasPermission('license_prototype_list') ?
                <LicensePrototypeList activeCustomer={activeCustomer} licensePrototypes={props.licensePrototypes}
                    columnsState={props.columns}
                    saveColumnsFilter={props.saveColumnsFilter}
                    hasPermission={props.hasPermission}
                    hasAtLeastOnePermission={props.hasAtLeastOnePermission}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    onFetchLicensePrototypeList={props.onFetchLicensePrototypeList}
                    onSnack={props.onSnack}
                    handleCreate={handleOpen} /> : null}
            {props.hasPermission('license_prototype_delete') ?
                <LicensePrototypeDelete activeCustomer={activeCustomer} entityName={'License prototype'} handleDelete={handleDelete}
                    deleteModalState={deleteModalState}
                    onDeleteLicensePrototype={props.onDeleteLicensePrototype} /> : null}
            {props.hasPermission('license_prototype_create') ?
                <LicensePrototypeCreate activeCustomer={activeCustomer} handleOpen={handleOpen} open={addModalState}
                    loading={props.licensePrototypes.creating}
                    onCreateLicensePrototype={props.onCreateLicensePrototype}
                    hasPermission={props.hasPermission}
                    permissions={permissions}
                /> : null}
            {props.hasPermission('license_prototype_load') ?
                <LicensePrototypeEdit activeCustomer={activeCustomer} handleEdit={handleEdit} hasPermission={props.hasPermission} open={editModalState}
                    loading={props.licensePrototypes.saving}
                    licensePrototype={props.licensePrototypes.loaded}
                    onEditLicensePrototype={props.onEditLicensePrototype}
                    permissions={permissions}
                /> : null}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        licensePrototypes: state.license.licensePrototypes,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        hasPermission: permission => {
            return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*');
        },
        hasAtLeastOnePermission: permissions => {
            return state.core.login.permissions.some(permission => permissions.includes(permission)) || state.core.login.permissions.includes('*');
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchLicensePrototype: licensePrototype => dispatch(actions.loadLicensePrototype(licensePrototype)),
        onCreateLicensePrototype: licensePrototype => dispatch(actions.createLicensePrototype(licensePrototype)),
        onFetchLicensePrototypeList: filter => dispatch(actions.fetchLicensePrototype(filter)),
        onEditLicensePrototype: licensePrototype => dispatch(actions.editLicensePrototype(licensePrototype)),
        onDeleteLicensePrototype: licensePrototype => dispatch(actions.deleteLicensePrototype(licensePrototype)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),

        listPermissions: (id) => dispatch(actions.listPermissions(id)),
        onSnack: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(LicensePrototype));