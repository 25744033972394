import React, { useState, useEffect } from 'react';
import Form from '../../../../np-form/Form';
import { withStyles } from '@material-ui/core';
import * as formUtility from '../../../../np-form/utility';
import makeAnimated from 'react-select/animated';
import { isSuperUser } from '../../../../np-utilities';

const animatedComponents = makeAnimated();
const styles = theme => ({
    root: {

        padding: '6px',
        width: '91%',
        paddingLeft: '5%'
    }
});

const updateSecondFormState = props => {
    const { classes, customer, selectedCustomer, translations } = props;
    const secondForm = {
        CustomerIDs: {
            elementType: 'multi-select',
            elementConfig: {
                isMulti: true,
                closeMenuOnSelect: false,
                components: animatedComponents,
                type: 'multi-select',
                placeholder: translations['customers'] || ' Customers',
                label: translations['customers'] || ' Customers',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true,
                options: customer ? customer : [],
                selected: selectedCustomer && selectedCustomer.length > 0 ? selectedCustomer.map(p => p) : []
            },
            value: selectedCustomer && selectedCustomer.length > 0 ? selectedCustomer.map(p => p) : [],
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
    };
    return secondForm;
}

const ChoseCustomer = props => {
    const { classes, translations, setActiveStep, customer, setCustomersSelect } = props;
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [customersForm, setCustomerForm] = useState(updateSecondFormState({ translations, customer, selectedCustomer }));

    useEffect(() => {

        setCustomerForm(updateSecondFormState({ translations, customer, selectedCustomer }));
    }, [customer, selectedCustomer, translations, classes]);


    const getMultiSelectData = data => {
        const updateState = formUtility.getNewStateOnMultiSelectChange(data, 'CustomerIDs', customersForm);
        setCustomerForm(updateState.myForm);
        if (data.length === 0) {
            setButtonDisabled(true)
        }
        else {
            setButtonDisabled(false)
        }
        setSelectedCustomer(data)
    }
    const submitHandler = event => {
        event.preventDefault();
        const formUser = formUtility.getFormData(customersForm);
        setCustomersSelect(formUser['CustomerIDs'])
        setActiveStep(2)
    };

    return (
        <Form
            formIsValid={!buttonDisabled}
            classes={classes}
            form={customersForm}
            getMultiSelectData={getMultiSelectData}
            submitHandler={submitHandler}
            submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
            buttonText={translations["next"] || 'next'}
            submitButtonStyle={buttonDisabled ? { color: 'white' } : null}
        />

    )
}

export default withStyles(styles)(ChoseCustomer);




