import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {combineReducers} from 'redux';

// Reducers
import applicantReducer from './Applicant/store/applicantReducer';

// Components
import ApplicantList from './Applicant';
import ApplicantDetails from './Applicant/Details';

// Routes
export const routes = basePath =>
    (
        <Routes>
            <Route path={basePath} element={<ApplicantList/>}/>
            <Route path={basePath + '/details'} element={<ApplicantDetails/>}/>
        </Routes>
    );

export const reducers = combineReducers({
    applicants: applicantReducer
});