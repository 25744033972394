import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route } from 'react-router';
import { Grid, Paper, withStyles } from '@material-ui/core';

import Form from '../../np-form/Form';
import * as formUtility from '../../np-form/utility';

import * as actions from './store/roleActions';
import * as permissionActions from '../Permissions/store/permissionActions';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,

        padding: theme.spacing(2),
        display: 'flex',
        flexWrap: 'wrap'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center'
    }, form: {
        padding: theme.spacing(1)
    }
});
const setInitialData = (loadedPermissions, loadedSelectedPermissions, loadedRole, setRoleForm) => {
    const permissionOptions = [];
    const selectedPermissions = [];

    loadedPermissions.forEach(permission => {
        permissionOptions.push({ label: permission.Name, value: permission.PermissionID });
    });
    loadedSelectedPermissions.forEach(sel => {
        selectedPermissions.push(sel.PermissionID);
    });

    const roleForm = {
        name: {
            elementType: 'input',
            elementConfig: {
                fullWidth: true,
                margin: 'dense',
                type: 'text',
                placeholder: 'Name',
                label: 'Name'
            },
            value: loadedRole.Name,
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        fancyName: {
            elementType: 'input',
            elementConfig: {
                fullWidth: true,
                margin: 'dense',
                type: 'text',
                placeholder: 'Fancy name',
                label: 'Fancy name'
            },
            value: loadedRole.FancyName,
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        permissionIds: {
            elementType: 'multi-select',
            elementConfig: {
                fullWidth: true,
                margin: 'dense',
                isMulti: true,
                type: 'text',
                name: 'Permissions',
                customLabel: 'Choose permissions',
                options: permissionOptions,
                selected: selectedPermissions
            },
            value: selectedPermissions,
            validation: {
                required: true
            },
            valid: true,
            touched: true
        }
    };
    setRoleForm(roleForm);
};

const RoleEdit = props => {
    const { hasEditPermission, loadedRole, editedRole, permissions, classes, onFetchPermissions, onLoadRole, onLoadInit, onEditInit } = props;

    const [roleForm, setRoleForm] = useState({});
    const [formIsValid, setFormIsValid] = useState(false);
    const roleId = props.match.params.id;

    useEffect(() => {
        //loadePermissions
        onFetchPermissions({});
        // Load the role
        onLoadRole({ RoleID: roleId });
        // Init the redux store on cleanup
        return () => {
            onLoadInit();
            onEditInit();
        };
    }, [onFetchPermissions, onLoadRole, roleId, onLoadInit, onEditInit]);

    useEffect(() => {
        if (loadedRole !== null) {
            setInitialData(permissions, loadedRole.Permissions, loadedRole, setRoleForm);
            setFormIsValid(true);
        }
    }, [loadedRole, permissions]);

    //Setting up initial data for select NEEDS REFACTORING AFTER SELECT FORM INTEGRATION


    const submitHandler = event => {
        event.preventDefault();

        // Prepare the form data
        const formData = formUtility.getFormData(roleForm);

        const roleId = props.match.params.id;
        const roleData = {
            RoleID: roleId,
            Name: formData.name,
            FancyName: formData.fancyName,
            PermissionIDs: formData.permissionIds
        };

        props.onEditRole(roleData);
    };

    // Input change handler
    const inputChangedHandler = (event, inputIdentifier) => {
        // Get default new state based on the input change event
        const updatedState = formUtility.getNewStateOnInputChange(event, inputIdentifier, roleForm);
        setRoleForm(updatedState.myForm);
        setFormIsValid(updatedState.formIsValid);
    };
    const [redirect, setRedirect] = useState(false);
    const cancelHandler = () => {
        setRedirect(true);
    };

    if (editedRole !== null || redirect) {
        // Prepare redirect URL
        let path = props.match.url; // .../roles/:id/edit
        let pathArray = path.split('/');
        pathArray.pop(); // remove edit
        pathArray.pop(); // remove :id
        path = pathArray.join('/'); // .../roles
        // Navigate to the ../roles
        return <Route path="*" element={<Navigate to={path} />} />;
    }

    const onMultiSelectChange = data => {
        const updatedState = formUtility.getNewStateOnMultiSelectChange(data, 'permissionIds', roleForm);
        // Set the new state
        setRoleForm(updatedState.myForm);
        setFormIsValid(updatedState.formIsValid);
    };

    // Render form
    //TODO change the form after select form integration
    return hasEditPermission ? (
        <div className={classes.root}>
            <Grid container spacing={10} justify="center" direction="row" alignItems="center">
                <Grid item lg={6} md={8} sm={10} xs={12}>
                    <Paper className={classes.paper}>
                        <Form
                            inputChangedHandler={inputChangedHandler}
                            submitHandler={submitHandler}
                            form={roleForm}
                            formIsValid={formIsValid}
                            buttonText="Save"
                            custom={classes.form}
                            formTitle="Edit Role"
                            cancelHandler={cancelHandler}
                            buttonTextCancel="Cancel"
                            getMultiSelectData={onMultiSelectChange}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    ) : null;
};

const mapStateToProps = state => {
    return {
        hasEditPermission: state.core.login.permissions.includes('*') || state.core.login.permissions.includes('core_role_edit'),
        loadedRole: state.core.role.loaded,
        editedRole: state.core.role.edited,
        permissions: state.core.permission.list
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadInit: () => dispatch(actions.loadRoleInit()),
        onEditInit: () => dispatch(actions.editRoleInit()),
        onLoadRole: id => dispatch(actions.loadRole(id)),
        onEditRole: role => dispatch(actions.editRole(role)),
        onFetchPermissions: data => dispatch(permissionActions.fetchPermissions(data))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(RoleEdit));
