import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Form from '../../np-form/Form';
import { Dialog, withStyles } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});

const billingModes = [{ value: 'prepaid', displayValue: 'Prepaid' }, { value: 'postpaid', displayValue: 'Contract' }];
const accountStatuses = [{ value: 'active', displayValue: 'Active' }, {
    value: 'prepared',
    displayValue: 'Prepared'
}, { value: 'inactive', displayValue: 'Inactive' }, { value: 'expired', displayValue: 'Expired' }];

const termsOfPayment = [
    { value: 'dor', displayValue: 'Due on Receipt' },
    { value: 'pia', displayValue: 'Payment in advance' },
    { value: 'eom', displayValue: 'End of Month' },
    { value: 'cod', displayValue: 'Cash on Delivery' },
    { value: 'net7', displayValue: '7 days after invoice date' },
    { value: 'net10', displayValue: '10 days after invoice date' },
    { value: 'net15', displayValue: '15 days after invoice date' },
    { value: 'net30', displayValue: '30 days after invoice date' },
    { value: 'net60', displayValue: '60 days after invoice date' },
];

const languages = [
    { value: 'en', displayValue: 'English' },
    { value: 'de', displayValue: 'German' },
    { value: 'sr', displayValue: 'Serbian' }
];

const updateFormState = props => {
    const { customers, users, currentUserID } = props;
    const accountValue = currentUserID && users ? users.filter(displayValue => { return currentUserID === displayValue.value })[0] : null

    return {
        BillingMode: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Billing mode',
                margin: "dense",
                fullWidth: true,
                options: billingModes ? [...billingModes] : []
            },
            formHelperText: "Please select billing mode",
            value: billingModes && billingModes.length > 0 ? billingModes[0].value : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Status: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Billing account status',
                margin: "dense",
                fullWidth: true,
                options: accountStatuses ? [...accountStatuses] : []
            },
            formHelperText: "Please select billing account status",
            value: accountStatuses && accountStatuses.length > 0 ? accountStatuses[0].value : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        ActivationDate: {
            elementType: 'datePicker',
            elementConfig: {
                type: 'datePicker',
                label: 'Activation date',
                margin: "dense",
                fullWidth: true,
                placeholder: 'Please select the account activation day (default is today)',
                disablePast: true,
                clearable: true
            },
            value: moment.now(),
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        ExpiresAt: {
            elementType: 'datePicker',
            elementConfig: {
                type: 'datePicker',
                label: 'Expires at',
                margin: "dense",
                fullWidth: true,
                placeholder: 'Please select the expiration day',
                disablePast: true,
                clearable: true,
            },
            value: moment().add(1, 'years'),
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        BillingDay: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                label: 'Billing day',
                margin: "dense",
                fullWidth: true,
                placeholder: 'Please select the account billing day',
                inputProps: { min: "1", max: "28" }
            },
            value: 1,
            validation: {
                required: true,
                isNumeric: true,
                min: 1,
                max: 28
            },
            valid: true,
            touched: false
        },
        CustomerOrder: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Customer order',
                label: 'Customer order',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        VAT: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'VAT Number',
                label: 'VAT Number',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        TaxClassification: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Tax Classification',
                label: 'Tax Classification',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        TermsOfPayment: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Terms Of Payment',
                margin: "dense",
                fullWidth: true,
                options: termsOfPayment ? [...termsOfPayment] : []
            },
            formHelperText: "Please select terms of payment",
            value: termsOfPayment ? termsOfPayment[0].value : null,
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        CustomerID: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Customer',
                margin: "dense",
                fullWidth: true,
                options: customers ? [...customers] : []
            },
            formHelperText: "Please select customer",
            value: customers && customers.length > 0 ? customers[0].value : '',
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        Accountant: {
            elementType: 'autocomplete',
            elementConfig: {
                type: 'select',
                label: 'Accountant',
                margin: "dense",
                options: users ? [...users] : []
            },
            formHelperText: "Please select accountant",
            value: accountValue ? accountValue : '',
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        Language: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: 'Communication language',
                margin: "dense",
                fullWidth: true,
                options: languages ? [...languages] : []
            },
            formHelperText: "Please select language",
            value: languages ? languages[0].value : '',
            validation: {
                required: true
            },
            valid: true,
            touched: false
        },
        PhoneNumber1: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Phone number',
                label: 'Phone number',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: 'E-Mail',
                className: null,
                label: 'E-Mail',
                error: false,
                fullWidth: true
            },
            value: '',
            validation: {
                required: false,
                isEmail: true
            },
            valid: true,
            touched: true
        },
        CustomerAddress: {
            elementType: 'checkbox',
            elementConfig: {
                type: 'checkbox',
                label: null,
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [{
                    ID: 'checkboxID',
                    Checked: false,
                    Name: 'Delivery address same as customer address? (Checked - Yes)'
                }]
            },
            value: false,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        StreetAddress: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Street Address',
                label: 'Street Address',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: customers && customers.length > 0 ? customers[0].StreetAddress : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        PostCode: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Post code',
                label: 'Post code',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: customers && customers.length > 0 ? customers[0].PostCode : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        City: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'City',
                label: 'City',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: customers && customers.length > 0 ? customers[0].City : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
        Country: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Country',
                label: 'Country',
                className: null,
                margin: "dense",
                fullWidth: true
            },
            value: customers && customers.length > 0 ? customers[0].Country : '',
            validation: {
                required: true
            },
            valid: true,
            touched: true
        },
    };
};

const BillingAccountCreate = props => {
    const { open, customers, users, loading, classes, currentUserID, activeCustomer } = props;
    const { createBillingAccount, handleOpen } = props;
    const [billingAccountForm, setBillingAccountForm] = useState(updateFormState({ customers, users, currentUserID }));

    useEffect(() => {
        setBillingAccountForm(updateFormState({ customers, users, currentUserID }));
    }, [currentUserID, customers, users]);

    const [formIsValid, setFormIsValid] = useState(true);

    const inputChangedHandler = (event, inputIdentifier) => {
        let updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, billingAccountForm);
        if (inputIdentifier === 'Email') {
            if (!updateState.myForm.Email.valid) {
                updateState.myForm.Email.elementConfig.error = !updateState.myForm.Email.valid
            } else {
                updateState.myForm.Email.elementConfig.error = !updateState.myForm.Email.valid

            }

        }

        if (inputIdentifier === 'CustomerID') {
            const formData = formUtility.getFormData(updateState.myForm);
            for (var customer of customers) {
                if (customer.value === formData.CustomerID) {
                    updateState.myForm.City.value = customer.City;
                    updateState.myForm.PostCode.value = customer.PostCode;
                    updateState.myForm.Country.value = customer.Country;
                    updateState.myForm.StreetAddress.value = customer.StreetAddress;

                    if (customer.City && customer.PostCode && customer.Country && customer.StreetAddress) {
                        updateState.formIsValid = true;
                    }
                }
            }
        }

        setBillingAccountForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };

    const checkboxChangedHandler = (event, inputIdentifier) => {
        let updateState = formUtility.getNewStateOnCheckboxChange(event, inputIdentifier, billingAccountForm);

        const formData = formUtility.getFormData(updateState.myForm);
        if (formData.CustomerAddress) {
            updateState.myForm.City.elementConfig['disabled'] = true;
            updateState.myForm.PostCode.elementConfig['disabled'] = true;
            updateState.myForm.StreetAddress.elementConfig['disabled'] = true;
            updateState.myForm.Country.elementConfig['disabled'] = true;
        } else {
            updateState.myForm.City.elementConfig['disabled'] = false;
            updateState.myForm.PostCode.elementConfig['disabled'] = false;
            updateState.myForm.StreetAddress.elementConfig['disabled'] = false;
            updateState.myForm.Country.elementConfig['disabled'] = false;
        }
        setBillingAccountForm(updateState.myForm);
    };

    const dateChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnDateChange(event, inputIdentifier, billingAccountForm);
        setBillingAccountForm(updateState.myForm);
    };

    const autoChangedHandler = (event, inputIdentifier) => {
        let updateState = formUtility.getNewStateOnAutoCompleteChange(event, inputIdentifier, billingAccountForm);
        setBillingAccountForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    };
    const submitHandler = async event => {
        event.preventDefault();
        const formData = formUtility.getFormData(billingAccountForm);
        if (props.CustomerID) {
            formData['CustomerID'] = props.CustomerID
        }

        const Address = {
            StreetAddress: formData.StreetAddress,
            City: formData.City,
            PostCode: formData.PostCode,
            Country: formData.Country
        };

        const Communication = {
            Language: formData.Language,
            PhoneNumber1: formData.PhoneNumber1,
            Email: formData.Email
        };
        formData['Accountant'] = formData['Accountant'] ? formData['Accountant'].value : null

        formData['Address'] = JSON.stringify(Address);
        formData['Communication'] = JSON.stringify(Communication);

        await createBillingAccount({ ...formData, ActiveCustomerID: activeCustomer });
        setBillingAccountForm(updateFormState({}));
        setFormIsValid(false);
        handleOpen();
    };
    if (props.CustomerID) {
        delete billingAccountForm['CustomerID']
    }
    const cancelHandler = () => {
        setBillingAccountForm(updateFormState({}));
        setFormIsValid(false);
        handleOpen();
    };

    return (
        <Dialog
            open={open}
            // disableBackdropClick={true}
            disableEscapeKeyDown={false}
            fullWidth={true}
            // onClose={cancelHandler}
            onClose={(_, reason) => {
                if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    cancelHandler();
                }
            }}
        >
            <Form
                formIsValid={formIsValid}
                classes={classes}
                form={billingAccountForm}
                formTitle="Create billing account"
                buttonText={"Save"}
                buttonTextCancel={"Cancel"}
                submitHandler={submitHandler}
                autoChangedHandler={autoChangedHandler}
                cancelHandler={cancelHandler}
                inputChangedHandler={inputChangedHandler}
                dateChangedHandler={dateChangedHandler}
                checkboxChangedHandler={checkboxChangedHandler}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                cancelButtonDisabled={loading}
                submitButtonDisabled={loading}
                submitButtonStyle={formIsValid && !loading ? { color: 'white' } : null}
            />
        </Dialog>
    )
};

export default withStyles(styles)(BillingAccountCreate);