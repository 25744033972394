import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { combineReducers } from 'redux';

// Reducers
import customerReducer from './Customer/store/customerReducer';

// Components
import CustomerList from './Customer';
import CustomerEdit from './Customer/Details';
import CustomerCreate from './Customer/CustomerCreate/index';


// Routes
export const routes = basePath =>
    (
        <Routes>
            <Route path={basePath} exact element={<CustomerList/>} />
            <Route path={basePath + '/details'} element={<CustomerEdit/>} />
            <Route path={basePath + '/create'} element={<CustomerCreate/>} />

        </Routes>
    );

export const reducers = combineReducers({
    customers: customerReducer
});