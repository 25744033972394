import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {combineReducers} from 'redux';

// Reducers
import languageReducer from './Language/store/languageReducer';

// Components
import LanguageList from './Language';

// Routes
export const routes = basePath =>
    (
        <Routes>
            <Route path={`${basePath}/*`} element={<LanguageList/>}/>
        </Routes>
    );

export const reducers = combineReducers({
    languages: languageReducer
});