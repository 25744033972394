import React, { useState } from 'react';
import { withStyles, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';

import LanguageList from './LanguageList';
import * as actions from './store/languageActions';
import LanguageCreate from './LanguageCreate';
import LanguageEdit from './LanguageEdit';
import LanguageDelete from './LanguageDelete';
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';
import * as action from '../../np-core/Auth/store/authActions';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
    progressBarr: {
        width: '100%',
        height: 5
    }
});

const Language = props => {
    const { classes, languages, activeCustomer } = props;
    const fetching = languages.fetching;

    const [addModalState, setAddModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState({ open: false, languageID: null, languageIDs: [], forBulk: false });

    const handleOpen = () => {
        setAddModalState(!addModalState);
    };

    const handleEdit = async event => {
        if (!event) {
            setEditModalState(!editModalState);
            return;
        }

        const languageID = event.currentTarget.value;
        await props.onFetchLanguage({ LanguageID: languageID, ActiveCustomerID: activeCustomer });
        setEditModalState(!editModalState);
    };

    const handleDelete = async event => {
        if (!event) {
            setDeleteModalState({ open: false, languageID: null, languageIDs: [], forBulk: false });
            return;
        }
        const languageID = event.currentTarget.value;
        setDeleteModalState({ ...deleteModalState, open: true, languageID: languageID });
    };

    const handleBulkDelete = languageIDS => {
        setDeleteModalState({ ...deleteModalState, open: true, languageIDs: languageIDS, forBulk: true });
    };

    return (
        <div className={classes.root}>
            <div className={classes.progressBarr}>
                {fetching ? <LinearProgress color="primary" /> : null}
            </div>
            {props.hasPermission('language_list') ? <LanguageList activeCustomer={activeCustomer} columnsState={props.columns} saveColumnsFilter={props.saveColumnsFilter} activeExternalServiceAvailable={props.activeExternalServiceAvailable} hasAtLeastOnePermission={props.hasAtLeastOnePermission} hasPermission={props.hasPermission} handleBulkDelete={handleBulkDelete} languages={languages} onFetchLanguages={props.onFetchLanguages} handleEdit={handleEdit} handleDelete={handleDelete} handleCreate={handleOpen} /> : null}
            {props.hasPermission('language_create') ? <LanguageCreate activeCustomer={activeCustomer} loading={languages.creating} handleOpen={handleOpen} open={addModalState} createLanguage={props.onCreateLanguage} /> : null}
            {props.hasPermission('language_load') ? <LanguageEdit activeCustomer={activeCustomer} handleEdit={handleEdit} open={editModalState} hasPermission={props.hasPermission} loading={languages.saving} language={languages.loaded} editLanguage={props.onEditLanguage} /> : null}
            {props.hasPermission('language_delete') ? <LanguageDelete activeCustomer={activeCustomer} entityName={'Language'} handleDelete={handleDelete} deleteModalState={deleteModalState} deleteLanguage={props.onDeleteLanguage} bulkDeleteLanguages={props.onBulkDeleteLanguages} /> : null}
        </div >
    );
};

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        languages: state.language.languages,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        hasPermission: permission => { return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'); },
        hasAtLeastOnePermission: permissions => { return state.core.login.permissions.some(permission => permissions.includes(permission)) || state.core.login.permissions.includes('*'); }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchLanguages: filter => dispatch(actions.fetchLanguages(filter)),
        onCreateLanguage: language => dispatch(actions.createLanguage(language)),
        onFetchLanguage: language => dispatch(actions.loadLanguage(language)),
        onEditLanguage: language => dispatch(actions.editLanguage(language)),
        onDeleteLanguage: language => dispatch(actions.deleteLanguage(language)),
        onBulkDeleteLanguages: language => dispatch(actions.bulkDeleteLanguage(language)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Language));