import React, { useState } from 'react';
import { withStyles, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';

import ScoreCriteriaList from './ScoreCriteriaList';
import * as actions from './store/scoreCriteriaActions';
import ScoreCriteriaCreate from './ScoreCriteriaCreate';
import ScoreCriteriaEdit from './ScoreCriteriaEdit';
import ScoreCriteriaDelete from './ScoreCriteriaDelete';
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';
import * as action from '../../np-core/Auth/store/authActions';
import { downloadFile } from '../../np-utilities';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
    progressBarr: {
        width: '100%',
        height: 5
    }
});

const ScoreCriteria = props => {
    const { classes, scoreCriterias, activeCustomer } = props;
    const fetching = scoreCriterias.fetching;

    const [addModalState, setAddModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState({
        open: false,
        scoreCriteriaID: null,
        scoreCriteriaIDs: [],
        forBulk: false
    });

    const handleOpen = async () => {
        if (addModalState === false) {
            await props.onFetchSites(activeCustomer);
        }
        setAddModalState(!addModalState);
    };

    const handleEdit = async event => {
        if (!event) {
            setEditModalState(!editModalState);
            return;
        }

        const scoreCriteriaID = event.currentTarget.value;
        await props.onFetchSites(activeCustomer);
        await props.onFetchScoreCriteria({ ScoreCriteriaID: scoreCriteriaID, ActiveCustomerID: activeCustomer });
        setEditModalState(!editModalState);
    };

    const handleDelete = async event => {
        if (!event) {
            setDeleteModalState({ open: false, scoreCriteriaID: null, scoreCriteriaIDs: [], forBulk: false });
            return;
        }
        const scoreCriteriaID = event.currentTarget.value;
        setDeleteModalState({ ...deleteModalState, open: true, scoreCriteriaID: scoreCriteriaID });
    };

    const handleBulkDelete = scoreCriteriaIDS => {
        setDeleteModalState({ ...deleteModalState, open: true, scoreCriteriaIDs: scoreCriteriaIDS, forBulk: true });
    };

    const handleDownload = async SiteKey => {
        let scoreCriteria = await props.onDownload(SiteKey, { ActiveCustomerID: activeCustomer });
        if (scoreCriteria) {
            return downloadFile(scoreCriteria, `score_criteria_${SiteKey}.xlsx`, { ActiveCustomerID: activeCustomer });
        }
        props.sendMessage('Could not find score criteria!', 'error');
    }

    return (
        <div className={classes.root}>
            <div className={classes.progressBarr}>
                {fetching ? <LinearProgress color="primary" /> : null}
            </div>
            {props.hasPermission('score_criteria_list') ?
                <ScoreCriteriaList activeCustomer={activeCustomer} columnsState={props.columns}
                    saveColumnsFilter={props.saveColumnsFilter}
                    activeExternalServiceAvailable={props.activeExternalServiceAvailable}
                    hasAtLeastOnePermission={props.hasAtLeastOnePermission}
                    hasPermission={props.hasPermission} handleBulkDelete={handleBulkDelete}
                    scoreCriterias={scoreCriterias} onFetchScoreCriterias={props.onFetchScoreCriterias} handleEdit={handleEdit}
                    handleDelete={handleDelete} handleCreate={handleOpen}
                    handleDownload={handleDownload}
                    uploadScoreCriteria={props.onImport}
                    loadSites={props.onFetchSitesForImportExport}
                    sendMessage={props.sendMessage}
                /> : null}

            {props.hasPermission('score_criteria_create') ?
                <ScoreCriteriaCreate sites={props.sites} activeCustomer={activeCustomer} loading={scoreCriterias.creating} handleOpen={handleOpen}
                    open={addModalState} createScoreCriteria={props.onCreateScoreCriteria} /> : null}
            {props.hasPermission('score_criteria_load') ?
                <ScoreCriteriaEdit sites={props.sites} activeCustomer={activeCustomer} handleEdit={handleEdit} open={editModalState}
                    hasPermission={props.hasPermission} loading={scoreCriterias.saving} scoreCriteria={scoreCriterias.loaded}
                    editScoreCriteria={props.onEditScoreCriteria} /> : null}
            {props.hasPermission('score_criteria_delete') ?
                <ScoreCriteriaDelete activeCustomer={activeCustomer} entityName={'Score Criteria'} handleDelete={handleDelete}
                    deleteModalState={deleteModalState} deleteScoreCriteria={props.onDeleteScoreCriteria}
                    bulkDeleteScoreCriterias={props.onBulkDeleteScoreCriterias} /> : null}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        scoreCriterias: state.scoreCriteria.scoreCriterias,
        sites: state.scoreCriteria.scoreCriterias ? state.scoreCriteria.scoreCriterias.scoreCriteriaSites : [],
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        hasPermission: permission => {
            return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*');
        },
        hasAtLeastOnePermission: permissions => {
            return state.core.login.permissions.some(permission => permissions.includes(permission)) || state.core.login.permissions.includes('*');
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchScoreCriterias: filter => dispatch(actions.fetchScoreCriterias(filter)),
        onCreateScoreCriteria: scoreCriteria => dispatch(actions.createScoreCriteria(scoreCriteria)),
        onFetchScoreCriteria: scoreCriteria => dispatch(actions.loadScoreCriteria(scoreCriteria)),
        onEditScoreCriteria: scoreCriteria => dispatch(actions.editScoreCriteria(scoreCriteria)),
        onDeleteScoreCriteria: scoreCriteria => dispatch(actions.deleteScoreCriteria(scoreCriteria)),
        onBulkDeleteScoreCriterias: scoreCriteria => dispatch(actions.bulkDeleteScoreCriteria(scoreCriteria)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data)),
        onFetchSites: id => dispatch(actions.fetchSites(id)),
        onFetchSitesForImportExport: id => dispatch(actions.fetchSitesForImportExport(id)),
        onImport: data => dispatch(actions.importFile(data)),
        onDownload: (data, customerID) => dispatch(actions.downloadFile(data, customerID)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ScoreCriteria));