import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';

import NPDatatable from '../../np-components/Datatable';
import { Paper, Tooltip, IconButton } from '@material-ui/core';
import useListFilter from '../../np-components/Datatable/listFilter';
import { CloudDownload, Delete } from '@material-ui/icons';
import { isSuperUser } from '../../np-utilities';
import { parseURL, BackButton, downloadFile } from '../../ms-utilities';


const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        backgroundColor: isSuperUser() ? "#d50000" : "#1b2a33",
        '&:hover': {
            backgroundColor: "#757575",
        },
    },
    media: {
        width: 50,
        height: 50
    }
});

const updateFilterParams = props => {
    const { listFilter } = props;
    let searchParams = parseURL(window.location.search);
    return { ...listFilter, ...searchParams };
};

const ProblemReportList = props => {
    const { classes, problemReports, onFetchProblemReports, columnsState, activeCustomer } = props;
    const problemReportsCount = problemReports.count;
    const problemReportsList = problemReports.list;

    let tableID = 'ProblemReport';
    let visible = columnsState && columnsState[tableID];

    const columns = [
        {
            displayName: 'Action',
            backendName: 'Action',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Action'] : true,
            }
        },
        {
            displayName: 'Message',
            backendName: 'Message',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['Message'] : true,
            }
        },
        {
            displayName: 'Customer',
            backendName: 'CustomerID',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['CustomerID'] : true,
            }
        },
        {
            displayName: 'Created by',
            backendName: 'CreatedBy',
            options: {
                align: 'left',
                sortable: true,
                searchable: false,
                visible: visible ? visible['CreatedBy'] : true,
            }
        },
        {
            displayName: 'Created at',
            backendName: 'CreatedAt',
            options: {
                align: 'left',
                sortable: false,
                searchable: false,
                visible: visible ? visible['CreatedAt'] : true,
            }
        }

    ];

    let backendNames = [];
    columns.map(column => {
        if (column.backendName !== 'Action') backendNames.push(column.backendName);
        return null;
    });
    const listFilter = useListFilter({ rowsPerPage: 20, searchColumns: backendNames });

    if (!props.hasAtLeastOnePermission(['problem_report_create', 'problem_report_load', 'problem_report_save', 'problem_report_delete'])) {
        columns.splice(0, 1);
    }

    useEffect(() => {
        if (listFilter.filter) {
            if (!listFilter.filter.q) {
                listFilter.filter.q = {};
            }
            if (!listFilter.filter.q.and) {
                listFilter.filter.q.and = {};
            }
            listFilter.filter.q = {
                ...listFilter.filter.q,
                and: updateFilterParams({ listFilter: listFilter.filter.q.and })
            };
            onFetchProblemReports({ ...listFilter.filter, ActiveCustomerID: activeCustomer });
        }
    }, [problemReports.created, activeCustomer, problemReports.deleted, problemReports.bulkDeleted, listFilter.filter, onFetchProblemReports]);

    const downloadImage = async (problemReportID, name) => {
        let problemReportData = await props.onDownload(problemReportID, { ActiveCustomerID: activeCustomer }, `${name}`);
        if (problemReportData) {
            return downloadFile(problemReportData.blob, problemReportData.fileName);
        }
    };

    const createActions = problemReportID => {
        return <>
            {
                props.hasPermission('problem_report_download') ? <Tooltip title="Download problem report">
                    <IconButton value={problemReportID} aria-label="Download problem report" onClick={() => downloadImage(problemReportID, `${problemReportID}`)}>
                        <CloudDownload />
                    </IconButton>
                </Tooltip> : null
            }
            {
                props.hasPermission('problem_report_delete') ?
                    <Tooltip title="Delete problem report">
                        <IconButton value={problemReportID} aria-label="Delete problem report" onClick={props.handleDelete}>
                            <Delete />
                        </IconButton>
                    </Tooltip> : null
            }
        </>
    };


    const tableOptions = {
        isSelectable: props.hasPermission('problem_report_delete'),
        tableSearch: false,
        tableID: tableID,
        saveColumn: true,
        selectOptions: {
            printing: false,
            generatingReport: false,
            deleting: props.hasPermission('problem_report_delete'),
            creating: false
        },
        rowsPerPage: 20,
        count: problemReportsCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'Problem reports',
        noMatch: "Sorry, no matching records found",
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return props.handleBulkDelete(IDs);
        },
        onSaveFilter: (columns) => {
            let objToSendColumn = {};
            let objColumns = {};
            columns.map(column => {
                objColumns[`${column.backendName}`] = column.options.visible;
                return objColumns
            });
            objToSendColumn[`${tableID}`] = objColumns;
            props.saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        }
    };


    let counter = 0;
    let problemReportsData = problemReportsList.map(problem => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = problem.ProblemReportID;
        returnObj.visible = true;
        returnObj.values = [
            createActions(problem.ProblemReportID),
            problem.Message,
            problem.Customer && problem.Customer.Name ? problem.Customer.Name : '-',
            problem.CreatedBy ? problem.CreatedBy : '-',
            problem.CreatedAt ? problem.CreatedAt : '-'
        ];
        if (!props.hasAtLeastOnePermission(['problem_report_create', 'problem_report_load', 'problem_report_delete', 'problem_report_delete'])) {
            returnObj.values.splice(0, 1);
        }
        counter++;
        return returnObj;
    });

    return (
        <Paper>
            {BackButton()}
            <NPDatatable handleCreate={props.handleCreate} customStyles={classes} data={problemReportsData} columns={columns}
                options={tableOptions} />
        </Paper>
    );
}

export default withStyles(styles)(ProblemReportList);