import * as actionTypes from './actionTypes';

import fetchRequest from '../fetchRequest'
import restCreate from '../restCreate'
import restEdit from '../restEdit'
import restDelete from '../restDelete'
import restLoad from '../restLoad'
import restBulkDelete from '../restBulkDelete'

export const restCrudActions = props => {

    const {
        endpointBasePath = '',
        pluginName,
        entityName,
        fetchSuccessMessage = entityName + ' fetched successfully',
        fetchFailedMessage = 'Cannot fetch ' + entityName,
        createSuccessMessage = entityName + ' created successfully',
        createFailedMessage = 'Cannot create ' + entityName,
        deleteSuccessMessage = entityName + ' deleted successfully',
        deleteFailedMessage = 'Cannot delete ' + entityName,
        loadSuccessMessage = entityName + ' loaded successfully',
        loadFailedMessage = 'Cannot load ' + entityName,
        editSuccessMessage = entityName + ' edited successfully',
        editFailedMessage = 'Cannot edit ' + entityName,
        bulkDeleteSuccessMessage = entityName + ' deleted successfully',
        bulkDeleteFailedMessage = 'Cannot bulk delete ' + entityName,
        fetchSuccessUseBackendMessage = false,
        fetchFailedUseBackendMessage = false,
        createSuccessUseBackendMessage = false,
        createFailedUseBackendMessage = false,
        deleteSuccessUseBackendMessage = false,
        deleteFailedUseBackendMessage = false,
        loadSuccessUseBackendMessage = false,
        loadFailedUseBackendMessage = false,
        editSuccessUseBackendMessage = false,
        editFailedUseBackendMessage = false,
        bulkDeleteSuccessUseBackendMessage = false,
        bulkDeleteFailedUseBackendMessage = false,
        lazyLoad = false
    } = props;

    const actionTypePrefix = pluginName + '|' + entityName + '_';

    const fetchInit = () => {
        return {
            type: actionTypePrefix + actionTypes.FETCH_INIT
        };
    };

    const fetchStart = () => {
        return {
            type: actionTypePrefix + actionTypes.FETCH_START
        };
    };

    const fetchSuccess = (count, list, lazyLoad, crudActions, filter) => {
        return {
            type: actionTypePrefix + actionTypes.FETCH_SUCCESS,
            list: list,
            count: count,
            lazyLoad: lazyLoad,
            crudActions: crudActions,
            filter: filter
        };
    };

    const fetchFail = error => {
        return {
            type: actionTypePrefix + actionTypes.FETCH_FAIL,
            error: error
        };
    };

    const fetch = async (dispatch, filter) => {
        const [fetch] = fetchRequest({
            endpoint: endpointBasePath,
            filter: filter,
            start: fetchStart,
            success: fetchSuccess,
            failed: fetchFail,
            successMessage: fetchSuccessMessage,
            failedMessage: fetchFailedMessage,
            readSuccessMessageFromBackend: fetchSuccessUseBackendMessage,
            fetchFailedUseBackendMessage: fetchFailedUseBackendMessage,
            lazyLoad: lazyLoad,
            crudActions: filter.crudActions
        })

        await fetch(dispatch);
    };

    const createInit = () => {
        return {
            type: actionTypePrefix + actionTypes.CREATE_INIT
        }
    }

    const createStart = () => {
        return {
            type: actionTypePrefix + actionTypes.CREATE_START
        };
    };

    const createSuccess = created => {
        return {
            type: actionTypePrefix + actionTypes.CREATE_SUCCESS,
            created: created
        };
    };

    const createFail = error => {
        return {
            type: actionTypePrefix + actionTypes.CREATE_FAIL,
            error: error
        };
    };

    const create = async (dispatch, item) => {
        const [create] = restCreate({
            endpoint: endpointBasePath + '/create',
            start: createStart,
            success: createSuccess,
            failed: createFail,
            successMessage: createSuccessMessage,
            failedMessage: createFailedMessage,
            readSuccessMessageFromBackend: createSuccessUseBackendMessage,
            readFailMessageFromBackend: createFailedUseBackendMessage
        })

        await create(dispatch, item);
    };

    const deleteInit = () => {
        return {
            type: actionTypePrefix + actionTypes.DELETE_INIT
        };
    };

    const deleteStart = () => {
        return {
            type: actionTypePrefix + actionTypes.DELETE_START
        };
    };

    const deleteSuccess = deleted => {
        return {
            type: actionTypePrefix + actionTypes.DELETE_SUCCESS,
            deleted: deleted
        };
    };

    const deleteFail = error => {
        return {
            type: actionTypePrefix + actionTypes.DELETE_FAIL,
            error: error
        };
    };

    const deleteItem = async (dispatch, item) => {

        const [deleteItem] = restDelete({
            endpoint: endpointBasePath + '/delete',
            start: deleteStart,
            success: deleteSuccess,
            failed: deleteFail,
            successMessage: deleteSuccessMessage,
            failedMessage: deleteFailedMessage,
            readSuccessMessageFromBackend: deleteSuccessUseBackendMessage,
            readFailMessageFromBackend: deleteFailedUseBackendMessage
        })

        await deleteItem(dispatch, item);
    };

    const loadInit = () => {
        return {
            type: actionTypePrefix + actionTypes.LOAD_INIT
        };
    };

    const loadStart = () => {
        return {
            type: actionTypePrefix + actionTypes.LOAD_START
        };
    };

    const loadSuccess = loaded => {
        return {
            type: actionTypePrefix + actionTypes.LOAD_SUCCESS,
            loaded: loaded
        };
    };

    const loadFail = error => {
        return {
            type: actionTypePrefix + actionTypes.LOAD_FAIL,
            error: error
        };
    };

    const load = async (dispatch, id) => {
        const [load] = restLoad({
            endpoint: endpointBasePath + '/load',
            start: loadStart,
            success: loadSuccess,
            failed: loadFail,
            successMessage: loadSuccessMessage,
            failedMessage: loadFailedMessage,
            readSuccessMessageFromBackend: loadSuccessUseBackendMessage,
            readFailMessageFromBackend: loadFailedUseBackendMessage
        })

        await load(dispatch, id);
    };

    const editInit = () => {
        return {
            type: actionTypePrefix + actionTypes.EDIT_INIT
        };
    };

    const editStart = () => {
        return {
            type: actionTypePrefix + actionTypes.EDIT_START
        };
    };

    const editSuccess = edited => {
        return {
            type: actionTypePrefix + actionTypes.EDIT_SUCCESS,
            edited: edited
        };
    };

    const edit = async (dispatch, item) => {
        const [edit] = restEdit({
            endpoint: endpointBasePath + '/save',
            start: editStart,
            success: editSuccess,
            failed: editFail,
            successMessage: editSuccessMessage,
            failedMessage: editFailedMessage,
            readSuccessMessageFromBackend: editSuccessUseBackendMessage,
            readFailMessageFromBackend: editFailedUseBackendMessage
        })

        await edit(dispatch, item);
    };

    const editFail = error => {
        return {
            type: actionTypePrefix + actionTypes.EDIT_FAIL,
            error: error
        };
    };

    const bulkDeleteInit = () => {
        return {
            type: actionTypePrefix + actionTypes.BULK_DELETE_INIT
        };
    };

    const bulkDeleteStart = () => {
        return {
            type: actionTypePrefix + actionTypes.BULK_DELETE_START
        };
    };

    const bulkDeleteSuccess = bulkDeleted => {
        return {
            type: actionTypePrefix + actionTypes.BULK_DELETE_SUCCESS,
            bulkDeleted: bulkDeleted
        };
    };

    const bulkDeleteFail = error => {
        return {
            type: actionTypePrefix + actionTypes.BULK_DELETE_FAIL,
            error: error
        };
    };

    const bulkDelete = async (dispatch, Ids) => {

        const [bulkDelete] = restBulkDelete({
            endpoint: endpointBasePath + '/bulk_delete',
            start: bulkDeleteStart,
            success: bulkDeleteSuccess,
            failed: bulkDeleteFail,
            successMessage: bulkDeleteSuccessMessage,
            failedMessage: bulkDeleteFailedMessage,
            readSuccessMessageFromBackend: bulkDeleteSuccessUseBackendMessage,
            readFailMessageFromBackend: bulkDeleteFailedUseBackendMessage
        })

        await bulkDelete(dispatch, Ids);
    };

    // Return CRUD action creators
    return {
        // Fetch action creators
        fetchInit: fetchInit,
        fetchStart: fetchStart,
        fetchSuccess: fetchSuccess,
        fetchFail: fetchFail,
        fetch: fetch,
        // Create action creators
        createInit: createInit,
        createStart: createStart,
        createSuccess: createSuccess,
        createFail: createFail,
        create: create,
        // Load action creators
        loadInit: loadInit,
        loadStart: loadStart,
        loadSuccess: loadSuccess,
        loadFail: loadFail,
        load: load,
        // Edit action creators
        editInit: editInit,
        editStart: editStart,
        editSuccess: editSuccess,
        editFail: editFail,
        edit: edit,
        // Delete action creators
        deleteInit: deleteInit,
        deleteStart: deleteStart,
        deleteSuccess: deleteSuccess,
        deleteFail: deleteFail,
        delete: deleteItem,
        // Bulk delete action creators
        bulkDeleteInit: bulkDeleteInit,
        bulkDeleteStart: bulkDeleteStart,
        bulkDeleteSuccess: bulkDeleteSuccess,
        bulkDeleteFail: bulkDeleteFail,
        bulkDelete: bulkDelete
    }
}

export default restCrudActions;
