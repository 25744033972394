import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route } from 'react-router';
import { Grid, Paper, withStyles } from '@material-ui/core';

import * as actions from './store/userActions';
import Form from '../../np-form/Form';
import * as formUtility from '../../np-form/utility';
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,

        padding: theme.spacing(2),
        display: 'flex',
        flexWrap: 'wrap'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center'
    },
    form: {
        padding: theme.spacing(1)
    }
});
const UserCreate = props => {
    const [userForm, setUserForm] = useState({
        firstName: {
            elementType: 'input',
            elementConfig: {
                fullWidth: true,
                margin: 'dense',
                type: 'text',
                placeholder: 'First name',
                label: 'First name'
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        lastName: {
            elementType: 'input',
            elementConfig: {
                fullWidth: true,
                margin: 'dense',
                type: 'text',
                placeholder: 'Last name',
                label: 'Last name'
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        username: {
            elementType: 'input',
            elementConfig: {
                fullWidth: true,
                margin: 'dense',
                type: 'text',
                placeholder: 'Username',
                label: 'Username'
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        newPassword: {
            elementType: 'input',
            elementConfig: {
                fullWidth: true,
                margin: 'dense',
                type: 'password',
                placeholder: 'New Password',
                label: 'New password'
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        confirmPassword: {
            elementType: 'input',
            elementConfig: {
                fullWidth: true,
                margin: 'dense',
                type: 'password',
                placeholder: 'Confirm Password',
                label: 'Confirm password'
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        status: {
            text: 'Status',
            elementType: 'select',
            elementConfig: {
                fullWidth: true,
                margin: 'dense',
                options: [
                    { value: 'active', displayValue: 'Active' },
                    { value: 'inactive', displayValue: 'Inactive' },
                    { value: 'banned', displayValue: 'Banned' }
                ]
            },
            value: 'active',
            validation: {},
            valid: true
        },
        email: {
            elementType: 'input',
            elementConfig: {
                fullWidth: true,
                margin: 'dense',
                type: 'email',
                placeholder: 'E-Mail',
                label: 'E-Mail'
            },
            value: '',
            validation: {
                required: true,
                isEmail: true
            },
            valid: false,
            touched: false
        }
    });

    const [formIsValid, setFormIsValid] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const { classes, createdUser, onUserInit, onCreateUser } = props;
    const [matching, setMatching] = useState(true);

    useEffect(() => {
        if (createdUser) {
            onUserInit();
            setRedirect(true);
        }

        return () => {
            onUserInit();
        };
    }, [createdUser, onUserInit]);

    const submitHandler = async event => {
        event.preventDefault();

        // Prepare the form data
        const formData = formUtility.getFormData(userForm);

        const userData = {
            FirstName: formData.firstName,
            LastName: formData.lastName,
            Username: formData.username,
            Password: formData.newPassword,
            Email: formData.email,
            Status: formData.status === 'active' ? 1 : formData.status === 'inactive' ? 0 : -1,
            CreatedBy: 1,
            LastModifiedBy: 1,
            redirect: false
        };

        await onCreateUser(userData);
    };

    // Input change handler
    const inputChangedHandler = (event, inputIdentifier) => {
        // Get default new state based on the input change event
        const updatedState = formUtility.getNewStateOnInputChange(event, inputIdentifier, userForm);
        const matching = updatedState.myForm.newPassword.value === updatedState.myForm.confirmPassword.value
        setMatching(matching)
        setUserForm(updatedState.myForm);
        setFormIsValid(updatedState.formIsValid);
    };

    const cancelHandler = () => {
        setRedirect(true);
    };
    if (redirect) {
        return <Route path="*" element={<Navigate to="/users" />} />;
    }

    // Render form
    return (
        <div className={classes.root}>
            <Grid container spacing={10} justify="center" direction="row" alignItems="center">
                <Grid item lg={6} md={8} sm={10} xs={12}>
                    <Paper className={classes.paper}>
                        <Form
                            validationMessage={matching ? '' : 'Passwords not matching!'}

                            custom={classes.form}
                            inputChangedHandler={inputChangedHandler}
                            submitHandler={submitHandler}
                            form={userForm}
                            formIsValid={formIsValid && matching}
                            buttonText={'Save'}
                            cancelHandler={cancelHandler}
                            buttonTextCancel="Cancel"
                            formTitle={'Enter data to create new user'}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        createdUser: state.core.user.created,
        creating: state.core.user.creating
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCreateUser: userData => dispatch(actions.createUser(userData)),
        onUserInit: () => dispatch(actions.createUserInit())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(UserCreate));
