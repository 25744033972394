import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { IconButton, Tooltip, withStyles } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import * as actions from './store/activityActions';
import * as action from '../../np-core/Auth/store/authActions';
import { isSuperUser } from '../../np-utilities';
import { connect } from 'react-redux';
import UserActivityRemoveSession from './UserActivityRemoveSession'

const styles = theme => ({
    "@global": {
        "[class*='buttonsCustomer']": {
            margin: theme.spacing(),
            fontSize: '13px',
            width: '100%',
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.buttonText.default
            },
        },
        "[class*='paletteButtons']": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.buttonText.default,
            margin: theme.spacing(),
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
            },
        },
    }
});
let pageSize = 5

const UserActivityList = props => {
    const { classes, translations, handleRemoveSession, saveColumnsFilter, activeCustomer, tableColumns, usersActivities, localization, fetchUserActivityData, tableActions, removeSession, userAgentDialog } = props;
    const tableRef = React.createRef();

    const fetching = usersActivities.fetching;
    const [columnObject] = useState(tableColumns);
    const [tableOptions, setTableOptions] = useState({
        filtering: true,
        sorting: false,
        exportFileName: 'UserActivity',
        filterCellStyle: { maxWidth: '200px' },
        search: false,
        minBodyHeight: window.innerHeight - 205 < 300 ? 300 : window.innerHeight - 205,
        maxBodyHeight: window.innerHeight - 205,
        pageSizeOptions: [5, 20, 50, 100],
        exportButton: true,
        columnsButton: true,
        pageSize: pageSize ? pageSize : 5,
        emptyRowsWhenPaging: true
    });

    const handleResize = () => {
        setTableOptions({ ...tableOptions,pageSize: pageSize,
            maxBodyHeight: window.innerHeight - 205, minBodyHeight: window.innerHeight - 205 < 300 ? 300 : window.innerHeight - 205 });
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize)
            pageSize=5
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classes]);

    const handleCloseDanger = async () => {
        tableRef.current && tableRef.current.onQueryChange()
        await props.removeSession({ SessionID: userAgentDialog.SessionID, ActiveCustomerID: activeCustomer })

        props.handleRemoveSession();
    }

    const filterQuery = filters => {
        let obj = {};
        for (var filter of filters) {
            obj[filter.column.field] = filter.value;
        }
        return obj;
    }

    async function fetchTableData(query) {
        let q = filterQuery(query.filters);
        query['ActiveCustomerID'] = activeCustomer;
        query['q'] = q;
        let result = await fetchUserActivityData(query);
        setTableOptions({ ...tableOptions, pageSize: query.pageSize });
        return { data: result.data, page: query.page, pageSize: query.pageSize, totalCount: result.count };
    }

    const columnHidden = newColumnState => {
        let objToSendColumn = {}
        let objColumns = {}
        columnObject.map(column => {
            objColumns[`${column.field}`] = column.hidden
            return objColumns
        })
        objToSendColumn[`UserActivity`] = objColumns
        saveColumnsFilter({ ...objToSendColumn, ActiveCustomerID: activeCustomer })
        return newColumnState
    }

    const tableTitle = () => {
        return <div>
            {translations["users_activities"] || 'Users activities'}
            <Tooltip title={translations["refresh"] || "Refresh"}>
                <IconButton aria-label={translations["refresh"] || "Refresh"} component="div" disabled={fetching} onClick={() => tableRef.current && tableRef.current.onQueryChange()}>
                    <Refresh />
                </IconButton>
            </Tooltip>
        </div>
    }

    return (<><MaterialTable
        tableRef={tableRef}
        title={tableTitle()}
        columns={columnObject}
        data={fetchTableData}
        options={tableOptions}
        onChangeRowsPerPage={(size) => pageSize = size}
        isLoading={fetching}
        localization={localization}
        onChangeColumnHidden={columnHidden}
        actions={isSuperUser() ? tableActions : null}
    />
        {userAgentDialog.sessionDialog ?
            <UserActivityRemoveSession handleCloseDanger={handleCloseDanger} removeSession={removeSession} tableRef={tableRef} fetchTableData={fetchTableData} handleRemoveSession={handleRemoveSession} translations={translations} activeCustomer={activeCustomer} userActivityModalState={userAgentDialog}></UserActivityRemoveSession>
            : null}
    </>)
}

const mapDispatchToProps = dispatch => {
    return {
        fetchUserActivityData: filter => dispatch(actions.fetchUserActivity(filter)),
        removeSession: queryParams => dispatch(actions.removeSession(queryParams)),
        saveColumnsFilter: data => dispatch(action.saveColumnsFilter(data))
    };
};

const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        usersActivities: state.userActivity.userActivity ?state.userActivity.userActivity : {}
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(UserActivityList));