import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route } from 'react-router';
import Form from '../../np-form/Form';
import * as formUtility from '../../np-form/utility';
import { Grid, Paper, withStyles } from '@material-ui/core';

import * as actions from './store/customerActions';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,

        padding: theme.spacing(2),
        display: 'flex',
        flexWrap: 'wrap'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center'
    }
});

const CustomerEdit = props => {
    const { loaded, classes, hasEditPermission, onLoadCustomer } = props;
    const [customerForm, setCustomerForm] = useState({});
    const [formIsValid, setFormIsValid] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const customerId = props.match.params.id;

    useEffect(() => {
        onLoadCustomer({ CustomerID: customerId });
    }, [onLoadCustomer, customerId]);

    useEffect(() => {
        if (loaded !== null) {
            setCustomerForm({
                name: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        fullWidth: true ,
                        margin: 'dense',
                        placeholder: 'Customer name',
                        label: 'Customer name'
                    },
                    value: loaded.Name,
                    validation: {
                        required: true
                    },
                    valid: true,
                    touched: false
                }
            });

            setFormIsValid(false);
        }
    }, [loaded]);

    const submitHandler = event => {
        event.preventDefault();
        // Prepare the form data
        const formData = formUtility.getFormData(customerForm);

        const customerId = props.match.params.id;
        const customerData = {
            CustomerID: customerId,
            Name: formData.name
        };

        props.onEditCustomer(customerData);
        setRedirect(true);
    };
    // Input change cancel handler
    const cancelHandler = () => {
        setRedirect(true);
    };

    // Input change handler
    const inputChangedHandler = (event, inputIdentifier) => {
        // Get default new state based on the input change event
        const updatedState = formUtility.getNewStateOnInputChange(event, inputIdentifier, customerForm);
        // Set the new state
        setCustomerForm(updatedState.myForm);
        setFormIsValid(updatedState.formIsValid);
    };

    if (redirect) {
        return (
            <Route path="*" element={
                <Navigate
                    to={{
                        pathname: '/customers',
                        state: {
                            page: props.page,
                            filter: props.filter,
                            update: false
                        }
                    }}
                />}
            />
        );
    }

    // Render form
    return hasEditPermission ? (
        <div className={classes.root}>
            <Grid container spacing={10} justify="center" direction="row" alignItems="center">
                <Grid item lg={6} md={8} sm={10} xs={12}>
                    <Paper className={classes.paper}>
                        <Form
                            inputChangedHandler={inputChangedHandler}
                            submitHandler={submitHandler}
                            form={customerForm}
                            formIsValid={formIsValid}
                            buttonText={'Save'}
                            buttonTextCancel={'Cancel'}
                            cancelHandler={cancelHandler}
                            formTitle={'Enter data to edit customer'}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    ) : null;
};

const mapStateToProps = state => {
    return {
        hasEditPermission: state.core.login.permissions.includes('*') || state.core.login.permissions.includes('core_customer_edit'),
        loaded: state.core.customer.loaded
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadCustomer: id => dispatch(actions.loadCustomer(id)),
        onEditCustomer: customerData => dispatch(actions.editCustomer(customerData))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(CustomerEdit));
