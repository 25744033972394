import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {combineReducers} from 'redux';

// Reducers
import moduleReducer from './Module/store/moduleReducer';

// Components
import ModuleList from './Module';

// Routes
export const routes = basePath =>
    (
        <Routes>
            <Route path={`${basePath}/*`} element={<ModuleList/>}/>
        </Routes>
    );

export const reducers = combineReducers({
    modules: moduleReducer
});