import React, { useState, useEffect } from 'react';

import Form from '../../np-form/Form';
import { withStyles, Paper, CircularProgress, Typography } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';
import { connect } from 'react-redux';
import * as actions from './store/binaryImageActions';
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';
import { parseURL, BackButton } from '../../ms-utilities';
import { useNavigate } from 'react-router-dom';
import { exampleValues } from '../../ms-utilities';

const styles = theme => ({
    root: {
        width: `98.9%`,
        height: 'auto',
        paddingTop: theme.spacing(),
        paddingLeft: theme.spacing(),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            width: '100%'
        }
    },
});

const updateFormState = props => {
    const { classes, binaryImageType, translations, binaryImage, releaseNote } = props;
    const form = {
        MajorVersion: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: translations['major_version'] || 'Major version',
                label: translations['major_version'] || 'Major version',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: binaryImage ? binaryImage.MajorVersion : '',
            validation: {
                required: false,
                isNumber: true
            },
            valid: true,
            touched: true
        },
        MinorVersion: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: translations['minor_version'] || 'Minor version',
                label: translations['minor_version'] || 'Minor version',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: binaryImage ? binaryImage.MinorVersion : '',
            validation: {
                required: false,
                isNumber: true
            },
            valid: true,
            touched: true
        },
        BuildNumber: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: translations['build_number'] || 'Build number',
                label: translations['build_number'] || 'Build number',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: binaryImage ? binaryImage.BuildNumber : '',
            validation: {
                required: false,
                isNumber: true
            },
            valid: true,
            touched: true
        },
        BinaryImageTypeID: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: translations['binary_image_type'] || 'Binary Image Type',
                className: null,
                margin: "dense",
                fullWidth: true,
                options: binaryImageType ? [...binaryImageType] : []
            },
            inputLabel: translations['binary_image_type_select'] || 'Please select binary image type',
            value: binaryImage ? binaryImage.BinaryImageTypeID : '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        Name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['name'] || 'Name',
                label: translations['name'] || 'Name',
                className: classes && classes.inputs ? classes.inputs : null,
                margin: "dense",
                fullWidth: true
            },
            value: binaryImage ? binaryImage.Name : '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        BuildType: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: translations['build_type'] || 'Build type',
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [
                    { value: 'beta', displayValue: translations['beta'] || 'Beta' },
                    { value: 'prod', displayValue: translations['production'] || 'Production' }
                ]
            },
            inputLabel: translations['build_type_form_text_helper'] || "Please choose build type:",
            value: binaryImage ? binaryImage.BuildType : '',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        Public: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: translations['public'] || 'Public',
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [
                    { value: "yes", displayValue: translations['yes'] || 'Yes' },
                    { value: 'no', displayValue: translations['no'] || 'No' }
                ]
            },
            inputLabel: translations['is_this_public_image'] || "Is this public binary image?",
            value: binaryImage && binaryImage.Public ? "yes" : 'no',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        Active: {
            elementType: 'select_v2',
            elementConfig: {
                type: 'select',
                label: translations['active'] || 'Active',
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [
                    { value: 'active', displayValue: translations['active'] || 'Active' },
                    { value: 'inactive', displayValue: translations['inactive'] || 'Inactive' }
                ]
            },
            inputLabel: translations['is_this_active_image'] || "Is this active binary image?",
            value: binaryImage && binaryImage.Active ? "active" : 'inactive',
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        ReleaseNotes: {
            elementType: 'editor',
            elementConfig: {
                type: 'text',
                placeholder: 'Release notes',
                label: 'Release notes',
                editorToolbarHidden: binaryImage && binaryImage.ReleaseNotesSent ? binaryImage.ReleaseNotesSent : false,
                editorToolbarHiddenTextarea: true,
                exampleValues: exampleValues
            },
            disabled: binaryImage && binaryImage.ReleaseNotesSent ? binaryImage.ReleaseNotesSent : false,
            formHelperText: ' Release notes',
            value: releaseNote,
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
    };
    return form;
}

const BinaryImageEdit = props => {
    const { classes, binaryImage, onClearState, onLoadBinaryImage, activeCustomer, translations, onFetchBinaryImageTypes, } = props;
    let releaseNote = binaryImage && binaryImage.ReleaseNotes ? binaryImage.ReleaseNotes : ''
    const [importForm, setImportForm] = useState(releaseNote !== '' || (binaryImage && binaryImage.ReleaseNotes === '') ? updateFormState({ ...props, releaseNote }) : null);
    const [formIsValid, setFormIsValid] = useState(false);
    const searchParams = parseURL(window.location.search);
    const BinaryImageID = searchParams?.binaryImageID;
    
    const [redirectOptions, setRedirectOptions] = useState({ redirect: false, redirectPath: '' });

    const navigate = useNavigate();

    useEffect(() => {
        const fetchBinaryImageTypes = async () => {
            await onFetchBinaryImageTypes(activeCustomer);
            await onLoadBinaryImage({ BinaryImageID: BinaryImageID, ActiveCustomerID: activeCustomer });
        }
        fetchBinaryImageTypes()
        return () => {
            onClearState()
        };

    }, [onFetchBinaryImageTypes, activeCustomer, onLoadBinaryImage, onClearState, BinaryImageID])

    useEffect(() => {
        setImportForm(releaseNote !== '' || (binaryImage && binaryImage.ReleaseNotes === '') ? updateFormState({ ...props, releaseNote }) : null);
    }, [binaryImage, props, releaseNote]);

    const getData = data => {
        const updatedState = formUtility.getNewStateOnEditorChange(data, 'ReleaseNotes', importForm);
        // Set the new state
        setImportForm(updatedState.myForm);
        setFormIsValid(updatedState.formIsValid);
    };
    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, importForm);
        setImportForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    }

    const checkboxChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnCheckboxChange(event, inputIdentifier, importForm);
        setImportForm(updateState.myForm);
        setFormIsValid(updateState.formIsValid);
    }

    const submitHandler = async  event => {
        event.preventDefault();
        const formData = formUtility.getFormData(importForm);
        formData['Public'] = formData['Public'] === 'yes' ? true : false
        formData['Active'] = formData['Active'] === 'active' ? true : false
        formData['BinaryImageID'] = binaryImage.BinaryImageID;
        await props.editBinaryImage({ ...formData, ActiveCustomerID: activeCustomer });
        setImportForm(updateFormState({ classes, translations, releaseNote }));
        setRedirectOptions({ redirect: true, redirectPath: `/downloads/binary-images` });
        navigate(-1);
    }

    const cancelHandler = () => {
        setImportForm(updateFormState({ classes, translations, releaseNote }));
        setRedirectOptions({ redirect: true, redirectPath: `/downloads/binary-images` });
        navigate(-1);
    }

    return (
        <div className={classes.root}>
            <Paper >
                {BackButton(translations)}
                {releaseNote !== '' || (binaryImage && binaryImage.ReleaseNotes === '') ?
                    <Form
                        formIsValid={formIsValid}
                        classes={classes}
                        getData={getData}
                        buttonsStyle={{
                            paddingBottom: '5px',
                            marginTop: "2px"
                        }}
                        form={importForm}
                        formTitle={translations['edit_binary_image'] || "Edit binary image"}
                        buttonText={props.hasPermission('binary_image_save') ? translations['save'] || "Save" : false}
                        buttonTextCancel={translations['cancel'] || "Cancel"}
                        cancelHandler={cancelHandler}
                        submitHandler={submitHandler}
                        inputChangedHandler={inputChangedHandler}
                        submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                        checkboxChangedHandler={checkboxChangedHandler}
                    /> :
                    <div>
                        <Typography variant={"h6"} >{translations['edit_binary_image'] || "Edit binary image"}</Typography>
                        <CircularProgress size={48} />
                    </div>
                }
            </Paper>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        binaryImage: state.exportImport.binaryImages && state.exportImport.binaryImages.loaded ? state.exportImport.binaryImages.loaded : {},
        binaryImageType: state.exportImport.binaryImages ? state.exportImport.binaryImages.binaryImageTypes : {},
        isSuperUser: state.core.login.permissions.includes('*'),
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        translations: state.translate && state.translate.translations ? state.translate.translations.activeTranslations || {} : {},
        hasPermission: permission => { return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*'); },
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchBinaryImageTypes: id => dispatch(actions.listBinaryImageTypes(id)),
        onLoadBinaryImage: filter => dispatch(actions.loadBinaryImage(filter)),
        editBinaryImage: data => dispatch(actions.editBinaryImage(data)),
        onClearState: () => dispatch(actions.ClearState()),

        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant
                    }
                })
            );
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(BinaryImageEdit));



