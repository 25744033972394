import restCrudActions from '../../../np-crud/store/restCrudActions'

// Create CRUD action creator
const crudActions = restCrudActions({
    endpointBasePath: 'languages',
    pluginName: 'LANGUAGE',
    entityName: 'LANGUAGE',
    fetchSuccessMessage: '',
    loadSuccessMessage: '',
});

// Fetch Language action creators
export const fetchLanguages = filter => {
    return async dispatch => {
        await crudActions.fetch(dispatch, filter);
    };
};

// Create Language  action creators
export const createLanguage = Language => {
    return async dispatch => {
        await crudActions.create(dispatch, Language);
    };
};

// Edit Language  action creators
export const editLanguage = setting => {
    return async dispatch => {
        await crudActions.edit(dispatch, setting);
    };
};

// Load Language action creators
export const loadLanguage = id => {
    return async dispatch => {
        await crudActions.load(dispatch, id);
    };
};

// Delete Language  action creators
export const bulkDeleteLanguage = languageIDs => {
    return async dispatch => {
        await crudActions.bulkDelete(dispatch, languageIDs);
    };
};

// Delete Language  action creators
export const deleteLanguage = deleteData => {
    return async dispatch => {
        await crudActions.delete(dispatch, deleteData);
    };
};