import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { reducers as customerReducer } from './modules/ms-customer';
import { reducers as userReducer } from './modules/ms-users';
import { reducers as adminReducer } from './modules/ms-admin';
import { reducers as licenseReducer } from './modules/ms-licensePrototype';
import { reducers as billingReducer } from './modules/ms-billing';
import { reducers as trainingReducer } from './modules/ms-training';
import { reducers as moduleReducer } from './modules/ms-module';
import { reducers as environmentReducer } from './modules/ms-environment';
import { reducers as languageReducer } from './modules/ms-language';
import { reducers as trainingModulesReducer } from './modules/ms-trainingModules';
import { reducers as applicantReducer } from './modules/ms-applicant';
import { reducers as siteReducer } from './modules/ms-site';
import { reducers as scoreCriteriaReducer } from './modules/ms-scoreCriteria';
import { reducers as rolesReducer } from "./modules/ms-roles";
import { reducers as exportImportReducer } from "./modules/ms-download";
import { reducers as problemReportReducer } from "./modules/ms-problemReports";
import { reducers as activityReducer} from "./modules/ms-userActivity";

import { reducers as downloadReducer } from "./modules/ms-downloadAll";

// Import custom module reducers
import { reducers as coreReducers } from './modules/np-core'
// Compose Enhancers
const composeEnhancers =
    process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

// Root Reducer
const rootReducer = combineReducers({
    core: coreReducers,
    license: licenseReducer,
    customer: customerReducer,
    training: trainingReducer,
    site: siteReducer,
    scoreCriteria: scoreCriteriaReducer,
    trainingModule: trainingModulesReducer,
    module: moduleReducer,
    environment: environmentReducer,
    language: languageReducer,
    admin: adminReducer,
    user: userReducer,
    applicant: applicantReducer,
    billing: billingReducer,
    role: rolesReducer,
    exportImport: exportImportReducer,
    problemReports: problemReportReducer,
    download: downloadReducer,
    userActivity: activityReducer

});

// Create store
const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

export default store;