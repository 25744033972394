import React, { useEffect, useState } from 'react';
import { Paper, withStyles, Button, Dialog } from '@material-ui/core';
import { BackButton, formatDateWithSite, parseURL } from '../../ms-utilities';
import { Radar } from 'react-chartjs-2';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { connect } from 'react-redux';
import * as actions from './store/applicantActions';
import { downloadFile } from '../../np-utilities';
import { enqueueSnackbar } from '../../np-dashboard/Notifier/store/notifierActions';
import * as formUtility from '../../np-form/utility';
import Form from '../../np-form/Form';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const styles = (theme) => ({
    root: {
        width: 'auto',
        height: 'auto',
        overflow: 'hidden',
        padding: theme.spacing(2),
        paddingRight: theme.spacing(6),
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            overflowY: 'auto',
        },
    },
    squareRoot: {
        display: 'flex',
        '& > *': {
            marginRight: theme.spacing(1),
        },
    },
    paper: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'auto',
    },
    logo: {
        width: '250px',
        margin: '10px',
    },
    chipPassed: {
        color: '#4caf50',
        marginTop: 8.5,
        fontSize: 15,
    },
    chipNotPassed: {
        color: '#b71c1c',
        marginTop: 8.5,
        fontSize: 15,
    },
    radarStyle: {
        margin: '0 auto',
        maxWidth: 'auto',
        maxHeight: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'auto',
            maxHeight: 'auto',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    square: {
        color: 'white',
        backgroundColor: '#1b2a33',
    },
});

const updateFirstFormState = (props) => {
    let { manualEnteredValues } = props;

    manualEnteredValues =
        typeof manualEnteredValues === 'string' ? JSON.parse(manualEnteredValues) : manualEnteredValues;

    return {
        Vitality: {
            elementType: 'select_v2',
            elementConfig: {
                fullWidth: true,
                options: [
                    { value: 'passed', displayValue: 'Passed' },
                    { value: 'notPassed', displayValue: 'Not passed' },
                ],
            },
            value: manualEnteredValues ? manualEnteredValues['Vitality'] : null,
            validation: {},
            valid: true,
            formHelperText: 'Please select your decision for Vitality',
        },
        Accuracy: {
            elementType: 'select_v2',
            elementConfig: {
                fullWidth: true,
                options: [
                    { value: 'passed', displayValue: 'Passed' },
                    { value: 'notPassed', displayValue: 'Not passed' },
                ],
            },
            value: manualEnteredValues ? manualEnteredValues['Accuracy'] : null,
            validation: {},
            valid: true,
            formHelperText: 'Please select your decision for Accuracy',
        },
        Attention: {
            elementType: 'select_v2',
            elementConfig: {
                fullWidth: true,
                options: [
                    { value: 'passed', displayValue: 'Passed' },
                    { value: 'notPassed', displayValue: 'Not passed' },
                ],
            },
            value: manualEnteredValues ? manualEnteredValues['Attention'] : null,
            validation: {},
            valid: true,
            formHelperText: 'Please select your decision for Attention',
        },
        Speed: {
            elementType: 'select_v2',
            elementConfig: {
                fullWidth: true,
                options: [
                    { value: 'passed', displayValue: 'Passed' },
                    { value: 'notPassed', displayValue: 'Not passed' },
                ],
            },
            value: manualEnteredValues ? manualEnteredValues['Speed'] : null,
            validation: {},
            valid: true,
            formHelperText: 'Please select your decision for Speed',
        },
        Memory: {
            elementType: 'select_v2',
            elementConfig: {
                fullWidth: true,
                options: [
                    { value: 'passed', displayValue: 'Passed' },
                    { value: 'notPassed', displayValue: 'Not passed' },
                ],
            },
            value: manualEnteredValues ? manualEnteredValues['Memory'] : null,
            validation: {},
            valid: true,
            formHelperText: 'Please select your decision for Memory',
        },
        Logic: {
            elementType: 'select_v2',
            elementConfig: {
                fullWidth: true,
                options: [
                    { value: 'passed', displayValue: 'Passed' },
                    { value: 'notPassed', displayValue: 'Not passed' },
                ],
            },
            value: manualEnteredValues ? manualEnteredValues['Logic'] : null,
            validation: {},
            valid: true,
            formHelperText: 'Please select your decision for Logic',
        },
    };
};

const ApplicantDetails = (props) => {
    const { classes, onLoadApplicant, activeCustomer, onApplicantDatasetsCalculate, saveManualResults } = props;

    const { applicant } = props;

    const { loaded, loading } = applicant;
    let searchParams = parseURL(window.location.search);
    const { TrainingSessions } = loaded ? loaded : {};

    const [userForm, setUserForm] = useState(
        updateFirstFormState({
            classes: classes,
            manualEnteredValues:
                TrainingSessions && TrainingSessions[0] ? TrainingSessions[0].ManualEnteredResults : null,
        })
    );
    const [userFormValid, setUserFormValid] = useState(false);

    const [applicantLoaded, setApplicantLoaded] = useState(false);

    let scoreThreshold = {
        Vitality: 7.5,
        Accuracy: 8.0,
        Attention: 8.0,
        Speed: 6.0,
        Memory: 8.0,
        Logic: 8.5,
    };

    const getTrainingReport = async () => {
        let data = await props.getApplicantTrainingReport(searchParams.applicantID, activeCustomer);
        if (data) {
            return downloadFile(data.blob, decodeURIComponent(data.fileName));
        }
        props.sendMessage('Could not find training session for applicant.', 'warning');
    };

    const getRawData = async () => {
        let data = await props.getRawData(searchParams.applicantID, activeCustomer);
        if (data) {
            return downloadFile(data.blob, decodeURIComponent(data.fileName));
        }
        props.sendMessage('Could not find training session for applicant.', 'warning');
    };

    useEffect(() => {
        const loadApplicant = async (params) => {
            await onLoadApplicant(params);
            setApplicantLoaded(true);
        };
        loadApplicant({ ApplicantID: searchParams.applicantID, ActiveCustomerID: activeCustomer });
    }, [activeCustomer, applicant.edited, onLoadApplicant, searchParams.applicantID]);

    useEffect(() => {
        if (applicantLoaded && TrainingSessions && TrainingSessions[0]) {
            onApplicantDatasetsCalculate({
                ApplicantID: searchParams.applicantID,
                ActiveCustomerID: activeCustomer,
                TrainingSessionID:
                    TrainingSessions && TrainingSessions[0] ? TrainingSessions[0].TrainingSessionID : null,
            });

            setUserForm(updateFirstFormState({ manualEnteredValues: TrainingSessions[0].ManualEnteredResults }));
        }
    }, [activeCustomer, TrainingSessions, onApplicantDatasetsCalculate, searchParams.applicantID, applicantLoaded]);

    const inputChangeHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, userForm);
        setUserForm(updateState.myForm);
        setUserFormValid(updateState.formIsValid);
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        const formUser = formUtility.getFormData(userForm);
        await saveManualResults({
            ApplicantID: searchParams.applicantID,
            ActiveCustomerID: activeCustomer,
            TrainingSessionID: TrainingSessions && TrainingSessions[0] ? TrainingSessions[0].TrainingSessionID : null,
            ManualEnteredValues: JSON.stringify(formUser),
        });
    };
    const newDatasets = applicant.datasets.map((dataset) => (!dataset || dataset < 0 ? 0.0 : dataset.toFixed(1)));
    scoreThreshold =
        applicant.loaded && applicant.loaded.Site.ScoreThreshold
            ? JSON.parse(applicant.loaded.Site.ScoreThreshold)
            : {
                  Vitality: 7.5,
                  Accuracy: 8.0,
                  Attention: 8.0,
                  Speed: 6.0,
                  Memory: 8.0,
                  Logic: 8.5,
              };

    const scoreThreshArray = [
        scoreThreshold.Vitality,
        scoreThreshold.Accuracy,
        scoreThreshold.Attention,
        scoreThreshold.Speed,
        scoreThreshold.Memory,
        scoreThreshold.Logic,
    ];

    const labels = ['Vitality', 'Accuracy', 'Attention', 'Speed', 'Memory', 'Logic'];
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Applicant score',
                backgroundColor: 'rgba(179,181,198,0.2)',
                borderColor: 'rgba(179,181,198,1)',
                pointBackgroundColor: 'rgba(179,181,198,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(179,181,198,1)',
                data: applicant ? newDatasets : [],
            },
            {
                label: `Minimal score`,
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                pointBackgroundColor: 'rgba(255,99,132,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(255,99,132,1)',
                // data: [7.5, 8.0, 8.0, 6.0, 8.0, 8.5]
                data: scoreThreshArray,
            },
        ],
    };

    // let minValues = [7.5, 8.0, 8.0, 6.0, 8.0, 8.5];
    let minValues = scoreThreshArray;

    return (
        <Paper className={classes.root}>
            <Grid container spacing={0}>
                <Grid item sm={3} xs={3}>
                    {BackButton()}
                    <Typography variant={'h4'}>
                        <b>{loaded ? `${loaded.FirstName} ${loaded.LastName}` : ''}</b>
                    </Typography>
                    <Typography variant={'caption'}>
                        {' '}
                        {loaded ? `Created at ${formatDateWithSite(loaded.CreatedAt, loaded['Site.Key'])}` : ''}
                    </Typography>
                    <br />
                    <div className={classes.squareRoot}>
                        {loaded
                            ? loaded.Password.split('|').map((pattern) => (
                                  <Avatar variant="square" className={classes.square}>
                                      {pattern}
                                  </Avatar>
                              ))
                            : null}
                    </div>
                    <Typography variant={'caption'}>
                        {' '}
                        {loaded ? `Is from Rotax? - ${loaded.IsFromRotax ? 'Yes' : 'No'}` : ''}
                    </Typography>
                    <br />
                    <Typography variant={'caption'}>
                        {' '}
                        {loaded ? `Has experience? - ${loaded.HasExperience ? 'Yes' : 'No'}` : ''}
                    </Typography>
                    <br />
                    <Typography variant={'caption'}>
                        {' '}
                        {loaded ? `Save training session data? - ${loaded.SaveData ? 'Yes' : 'No'}` : ''}
                    </Typography>
                    <br />
                    {loaded && TrainingSessions && TrainingSessions[0] ? (
                        <div>
                            <Button
                                key={'download_action_button'}
                                variant="contained"
                                color="primary"
                                onClick={getTrainingReport}
                                style={{ float: 'left' }}
                            >
                                Download Report
                            </Button>
                            <br />
                            <br />
                            <br />
                        </div>
                    ) : null}
                    {loaded && TrainingSessions && TrainingSessions[0] ? (
                        <div>
                            <Button
                                key={'applicant_get_raw_data'}
                                variant="contained"
                                color="primary"
                                onClick={getRawData}
                                style={{ float: 'left' }}
                            >
                                Download raw data
                            </Button>
                            <br />
                            <br />
                            <br />
                        </div>
                    ) : null}
                    {loaded && TrainingSessions && TrainingSessions[0] && TrainingSessions[0].NegativeScores ? (
                        <div>
                            <Typography variant={'caption'}>Negative scores:</Typography>
                            <ul>
                                {JSON.parse(TrainingSessions[0].NegativeScores).map((negativeScore) => {
                                    return (
                                        <li>
                                            <Typography variant={'caption'}>{`${
                                                negativeScore.message
                                            } (${negativeScore.result.toFixed(1)})`}</Typography>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    ) : null}
                    {TrainingSessions && TrainingSessions[0] ? (
                        <Typography key={'system_decision'} variant={'subtitle1'}>
                            <b key={'system_decision_b'}>System decision:</b>
                        </Typography>
                    ) : null}
                    <br key={'system_decision_br'} />
                    {TrainingSessions && TrainingSessions[0]
                        ? labels.map((label, index) => {
                              let passed =
                                  applicant &&
                                  applicant.datasets[index] &&
                                  applicant.datasets[index] >= minValues[index];
                              return (
                                  <>
                                      {`${label}: `}
                                      <span className={passed ? classes.chipPassed : classes.chipNotPassed}>
                                          {passed ? 'Passed' : 'Not passed'}
                                      </span>
                                      <br />
                                  </>
                              );
                          })
                        : null}
                    <br />
                    {TrainingSessions && TrainingSessions[0] ? (
                        <Typography key={'manual_decision'} variant={'subtitle1'}>
                            <b key={'manual_decision_b'}>Manual decision:</b>
                        </Typography>
                    ) : null}
                    {TrainingSessions && TrainingSessions[0] ? (
                        <Form
                            formIsValid={userFormValid}
                            form={userForm}
                            inputChangedHandler={inputChangeHandler}
                            submitHandler={submitHandler}
                            buttonText={
                                props.hasPermission('training_save_manual_results') ? 'Save manual results' : null
                            }
                            submitButtonStyle={{ width: '100%' }}
                        />
                    ) : null}
                </Grid>
                <Grid item sm={6} xs={6}>
                    {TrainingSessions && TrainingSessions[0] ? (
                        <Radar data={data} />
                    ) : (
                        <Typography variant={'subtitle1'}>No training session available</Typography>
                    )}
                </Grid>
            </Grid>
            <Dialog
                disableEscapeKeyDown={false}
                open={loading}
                PaperProps={{
                    classes: {
                        root: classes.paper,
                    },
                }}
            >
                <img
                    alt="App logo loading"
                    className={classes.logo}
                    src={process.env.PUBLIC_URL + '/assets/images/app-logo-loading.gif'}
                />
            </Dialog>
        </Paper>
    );
};

const mapStateToProps = (state) => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        columns: state.core.login.userSettings ? state.core.login.userSettings.Columns : null,
        applicant: state.applicant.applicants,
        hasPermission: (permission) => {
            return state.core.login.permissions.includes(permission) || state.core.login.permissions.includes('*');
        },
        hasAtLeastOnePermission: (permissions) => {
            return (
                state.core.login.permissions.some((permission) => permissions.includes(permission)) ||
                state.core.login.permissions.includes('*')
            );
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLoadApplicant: (applicantID) => dispatch(actions.loadApplicant(applicantID)),
        onApplicantDatasetsCalculate: (applicantInfo) => dispatch(actions.onApplicantDatasetsCalculate(applicantInfo)),
        saveManualResults: (manualEnteredResults) => dispatch(actions.saveManualEnteredResults(manualEnteredResults)),
        getApplicantTrainingReport: (applicantID, customerID) =>
            dispatch(actions.getApplicantTrainingReport(applicantID, customerID)),
        getRawData: (applicantID, customerID) => dispatch(actions.getRawData(applicantID, customerID)),
        sendMessage: (message, variant) => {
            dispatch(
                enqueueSnackbar({
                    message: message,
                    options: {
                        variant: variant,
                    },
                })
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ApplicantDetails));
