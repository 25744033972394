import React, { useState, useEffect } from 'react';
import Form from '../../np-form/Form';
import { Paper, withStyles } from '@material-ui/core';
import * as formUtility from '../../np-form/utility';
import { isSuperUser } from '../../np-utilities';
import makeAnimated from 'react-select/animated';
import * as actions from './store/roleActions';
import { connect } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';

const animatedComponents = makeAnimated();

const styles = theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }
});

const updateFirstFormState = props => {
    const { classes, translations, role, permissions } = props;
    const { permissionList, loading } = permissions;

    // First form
    const firstForm = {
        Name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['name'] || 'Name',
                label: translations['name'] || 'Name',
                className: classes && classes.inputs ? classes.inputs : '',
                margin: "dense",
                fullWidth: true
            },
            value: role ? role.Name : '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        FancyName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['fancy_name'] || 'Fancy name',
                label: translations['fancy_name'] || 'Fancy name',
                className: classes && classes.inputs ? classes.inputs : '',
                margin: "dense",
                fullWidth: true
            },
            value: role ? role.FancyName : '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        Type: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['type'] || 'Type',
                label: translations['type'] || 'Type',
                className: classes && classes.inputs ? classes.inputs : '',
                margin: "dense",
                fullWidth: true
            },
            value: role ? role.Type : '',
            validation: {
                required: true,
                isNumeric: true
            },
            valid: false,
            touched: false
        },
        Module: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: translations['module'] || 'Module',
                label: translations['module'] || 'Module',
                className: classes && classes.inputs ? classes.inputs : '',
                margin: "dense",
                fullWidth: true
            },
            value: role ? role.Module : '',
            validation: {
                required: true,
                isNumeric: true
            },
            valid: false,
            touched: false
        },
        Locked: {
            elementType: 'checkbox',
            elementConfig: {
                type: 'checkbox',
                label: null,
                className: null,
                margin: "dense",
                fullWidth: true,
                options: [{ ID: 'checkboxID', Checked: role && role.Locked ? true : false, Name: translations['locked'] || 'Locked' }]
            },
            value: false,
            validation: {
                required: false
            },
            valid: true,
            touched: true
        },
        Permissions: {
            elementType: 'multi-select',
            elementConfig: {
                isMulti: true,
                closeMenuOnSelect: false,
                isLoading: loading,
                components: animatedComponents,
                type: 'multi-select',
                placeholder: translations['permission'] || 'Permission',
                label: translations['choose_permissions'] || 'Choose permissions',
                margin: "dense",
                fullWidth: true,
                options: permissionList ? permissionList : [],
                selected: role && role.Permissions ? role.Permissions.map(permission => permission.PermissionID) : []
            },
            formHelperText: translations['choose_permission_role'] || 'Please select permissions for role',
            value: role && role.Permissions ? role.Permissions.map(permission => permission.PermissionID) : [],
            validation: {
                required: true
            },
            valid: true,
            touched: false
        }
    };

    return firstForm;
}

const RoleEdit = props => {
    const { classes, activeCustomer, role, translations, loadRole, onLoadPermission } = props;
    const [permissions, setPermissions] = useState({ permissionList: [], loading: true });
    const [redirect, setRedirect] = useState(false);
    const [roleForm, setRoleForm] = useState(updateFirstFormState({ ...props, permissions }));
    const [roleFormValid, setRoleFormValid] = useState(false);

    const navigate = useNavigate();

    const {pathname} = useLocation();
    const roleId = pathname?.split("/")?.[2];

    useEffect(() => {
        const loadRoles = async () => {
            // const roleId = match?.params?.id;
            await loadRole({ RoleID: roleId, ActiveCustomerID: activeCustomer });
            let data = await onLoadPermission({ ActiveCustomerID: activeCustomer });
            setPermissions({ permissionList: data, loading: false });
        }
        loadRoles();
    }, [activeCustomer, roleId, onLoadPermission, loadRole]);

    const getMultiSelectData = (data) => {
        const updateState = formUtility.getNewStateOnMultiSelectChange(data, 'Permissions', roleForm);
        setRoleForm(updateState.myForm);
        setRoleFormValid(true);
    }

    useEffect(() => {
        setRoleForm(updateFirstFormState({ ...props, permissions }));
    }, [props, permissions]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnInputChange(event, inputIdentifier, roleForm);
        setRoleForm(updateState.myForm);
        setRoleFormValid(true);
    };

    const checkboxChangedHandler = (event, inputIdentifier) => {
        const updateState = formUtility.getNewStateOnCheckboxChange(event, inputIdentifier, roleForm);
        setRoleForm(updateState.myForm);
        setRoleFormValid(updateState.formIsValid);

    }

    const submitHandler = event => {
        event.preventDefault();
        const formRole = formUtility.getFormData(roleForm);
        formRole['RoleID'] = role ? role.RoleID : null;
        formRole['ActiveCustomerID'] = activeCustomer
        formRole['Locked'] = formRole['Locked'] ? 1 : 0
        if (isSuperUser()) {
            delete formRole['ActiveCustomerID']
        }
        props.editRole({ ...formRole });
        setRoleForm(updateFirstFormState({ translations, permissions }));
        setRoleFormValid(false);
        setRedirect(true)
    };

    if (redirect) {
        return (
            <Routes>
                <Route path="*" element={
                    <Navigate
                        to={{
                            pathname: '/roles',
                            state: {
                                page: props.page,
                                filter: props.filter,
                                update: false
                            }
                        }}
                    />}
                />
            </Routes>
        );
    }

    const cancelHandler = () => {
        navigate(-1);
    }

    return (
        <Paper        >
            <Form
                cancelHandler={cancelHandler}
                formIsValid={roleFormValid}
                classes={classes}
                checkboxChangedHandler={checkboxChangedHandler}
                form={roleForm}
                inputChangedHandler={inputChangedHandler}
                formTitle={translations['edit_role'] || 'Edit Role'}
                buttonText={role && !role.Locked ? translations['save'] || "Save" : isSuperUser() ? translations['save'] || "Save" : null}
                submitButtonColor={isSuperUser() ? 'secondary' : 'primary'}
                buttonTextCancel={translations['cancel'] || "Cancel"}
                submitHandler={submitHandler}
                getMultiSelectData={getMultiSelectData}

            />
        </Paper>
    )
}
const mapStateToProps = state => {
    return {
        activeCustomer: state.core.login.activeCustomer.CustomerID,
        hasEditPermission: state.core.login.permissions.includes('*') || state.core.login.permissions.includes('core_products_edit'),
        role: state.role.roles.loaded,
        translations: state.translate && state.translate.translations ? state.translate.translations.activeTranslations || {} : {}
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadRole: filterParams => dispatch(actions.loadRole(filterParams)),
        editRole: data => dispatch((actions.editRole(data))),
        onLoadPermission: (id) => dispatch(actions.loadPermission(id))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(RoleEdit));
