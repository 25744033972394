import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import NPDatatable from '../../np-components/Datatable';
import { Navigate, Route } from 'react-router';
import ConfirmationDialog from '../../np-components/ConfirmationDialog/ConfirmationDialog';

import useListFilter from '../../np-crud/listFilter';
import { Grid, withStyles, Paper } from '@material-ui/core';

import * as actions from './store/customerActions';

const styles = theme => ({
    fab: {
        textTransform: 'none',
        color: 'white',
        marginLeft: theme.spacing(),
        '&:hover': {
            backgroundColor: '#757575'
        }
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing()
    },
    iconHover: {
        '&:hover': {
            color: '#99ccff'
        }
    }
});

// Customer List Component
const CustomerList = props => {
    const { customerList, customerCount, classes, hasEditPermission, onFetchCustomers, onDeleteCustomer, onBulkDelete } = props;

    // List Filter
    const listFilter = useListFilter({ searchColumns: ['Name'] });

    // Delete dialog state
    const [deleteDialog, setDeleteDialog] = useState({ open: false, confirmed: false });
    // Data to be deleted
    const [deleteData, setDeleteData] = useState({});
    // Bulk delete dialog state
    const [bulkDeleteDialog, setBulkDeleteDialog] = useState({ open: false, confirmed: false });
    // Bulk data to be deleted
    const [bulkDeleteData, setBulkDeleteData] = useState({});

    const [redirectCreate, setRedirectCreate] = useState({ redirect: false, UserID: 0, redirectPath: '' });

    useEffect(() => {
        if (listFilter.filter) {
            onFetchCustomers(listFilter.filter);
        }
    }, [listFilter.filter, onFetchCustomers]);

    // Delete customer if dialog confirmed
    useEffect(() => {
        const deleteCustomer = async () => {
            // Run delete
            if (deleteData !== {}) {
                await onDeleteCustomer(deleteData);
                // Init state
                setDeleteDialog({ open: false, confirmed: false });
                // Fetch customers
                await onFetchCustomers(listFilter.filter);
                return setDeleteData({});
            };
        }
        if (deleteDialog.confirmed) {
            deleteCustomer();
        }
    }, [deleteDialog.confirmed, onFetchCustomers, onDeleteCustomer, deleteData, listFilter.filter]);


    // Bulk delete customers if dialog confirmed
    useEffect(() => {
        const bulkDeleteCustomers = async () => {
            if (bulkDeleteData !== {}) {

                // Run bulk delete
                await onBulkDelete(bulkDeleteData);
                // Init bulk delete state
                // Hide dialog
                setBulkDeleteDialog({ open: false, confirmed: false });
                // Fetch issues
                await onFetchCustomers(listFilter.filter);
                return setBulkDeleteData({});
            };
        };

        if (bulkDeleteDialog.confirmed) {
            // Bulk delete customers
            bulkDeleteCustomers();
        }
    }, [bulkDeleteDialog.confirmed, onBulkDelete, onFetchCustomers, bulkDeleteData, listFilter.filter]);

    // Bulk delete customers


    // Delete handler
    const handleDelete = CustomerID => {
        // Set data to be deleted
        setDeleteData({ CustomerID: parseInt(CustomerID) });
        // Show delete dialog
        setDeleteDialog({ open: true, confirmed: false });
    };

    // Bulk delete handler
    const handleBulkDelete = ids => {
        // Set data to be deleted
        setBulkDeleteData({ CustomerIDs: ids });
        // Show bulk delete dialog
        setBulkDeleteDialog({ open: true, confirmed: false });
    };

    const createActions = customer => {
        return (
            <>
                {hasEditPermission ? (
                    <div>
                        <Tooltip title={'Edit'}>
                            <NavLink
                                className="navlinkStyle"
                                to={{
                                    pathname: props.match.path + '/' + customer.CustomerID + '/edit',
                                    state: {
                                        page: listFilter.options.page,
                                        filter: listFilter.filter
                                    }
                                }}
                            >
                                <IconButton>
                                    <EditIcon variant="contained" color="primary" fontSize="small" />
                                </IconButton>
                            </NavLink>
                        </Tooltip>
                        <Tooltip title={'Delete'}>
                            <IconButton
                                onClick={() => handleDelete(customer.CustomerID)}
                                style={{ color: '#D71C1C' }}
                                fontSize="small"
                                variant="contained"
                                color="secondary"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                ) : null}
            </>
        );
    };

    let counter = 0;
    let customerListData = customerList.map(customer => {
        let returnObj = {};
        returnObj.index = counter;
        returnObj.selected = false;
        returnObj.id = customer.CustomerID;
        returnObj.visible = true;
        returnObj.values = [createActions(customer), customer.Name, customer.Status === 1 ? 'Active' : 'Inactive', customer.Country];
        counter++;
        return returnObj;
    });

    const columns = [
        {
            displayName: 'Actions',
            backendName: 'Actions',
            options: {
                align: 'left',
                visible: true
            }
        },
        {
            displayName: 'Name',
            backendName: 'Name',
            options: {
                align: 'left',
                sortable: true,
                visible: true
            }
        },
        {
            displayName: 'Status',
            backendName: 'Status',
            options: {
                align: 'left',
                sortable: true,
                visible: true
            }
        },
        {
            displayName: 'Country',
            backendName: 'Country',
            options: {
                align: 'left',
                sortable: true,
                visible: true
            }
        }
    ];

    const tableOptions = {
        isSelectable: hasEditPermission,
        tableSearch: true,
        selectOptions: {
            printing: false,
            generatingReport: false,
            deleting: hasEditPermission,
            creating: hasEditPermission
        },
        rowsPerPage: 10,
        count: customerCount,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100, 'All'],
        tableTitle: 'Customer list',
        noMatch: 'Sorry, no matching records found',
        onTableChange: listFilter.onTableChange,
        onBulkDelete: IDs => {
            return handleBulkDelete(IDs);
        }
    };

    if (redirectCreate.redirect) {
        return <Route path="*" element={<Navigate to={redirectCreate.redirectPath} />} />;
    }

    // TODO handle loading and show some animation
    // !!! But be carefule: do not hide the datatable during loading, because it generates a rerender
    // and we would loose for example the current searchText

    // Render
    return (
        <div className={classes.root}>
            <Grid container spacing={10} justify="center" direction="row" alignItems="center">
                <Grid className={classes.item} item xs={12} md={11} sm={12} lg={10}>
                    <Paper>
                        <NPDatatable
                            handleCreate={() => {
                                setRedirectCreate({ redirect: true, redirectPath: `/customers/create` });
                            }}
                            customStyles={classes}
                            data={customerListData}
                            columns={columns}
                            options={tableOptions}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <ConfirmationDialog
                isOpen={deleteDialog.open}
                title="Delete role"
                contentText="Are you sure to delete this customer?"
                textYes="Delete"
                onYes={() => setDeleteDialog({ open: false, confirmed: true })}
                onCancel={() => setDeleteDialog({ open: false, confirmed: false })}
            />
            <ConfirmationDialog
                isOpen={bulkDeleteDialog.open}
                title="Delete all customers"
                contentText="Are you sure to delete all selected customers?"
                textYes="Delete"
                onYes={() => setBulkDeleteDialog({ open: false, confirmed: true })}
                onCancel={() => setBulkDeleteDialog({ open: false, confirmed: false })}
            />
        </div>
    );
};

const mapStateToProps = state => {
    return {
        hasEditPermission: state.core.login.permissions.includes('*') || state.core.login.permissions.includes('core_customer_edit'),
        customerCount: state.core.customer.count,
        customerList: state.core.customer.list,
        loading: state.core.customer.loading,
        snackbar: state.snackbar
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCustomers: filter => dispatch(actions.fetchCustomers(filter)),
        onDeleteCustomer: data => dispatch(actions.deleteCustomer(data)),
        onBulkDelete: data => dispatch(actions.bulkDeleteCustomers(data))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(CustomerList));
